import React from 'react';
import Chart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
export default function Overview2({ statistics }) {
	const { lineChart } = statistics;
	const time = lineChart.time;
	const data = lineChart.data;

	const chartSparklinesLarge2AOptions = {
		chart: {
			toolbar: {
				show: true
			},
			sparkline: {
				enabled: false
			}
		},
		dataLabels: {
			enabled: false
		},
		colors: ['#046eb8'],
		stroke: {
			color: '#4191ff',
			curve: 'smooth',
			width: 2
		},
		xaxis: {
			crosshairs: {
				width: 1
			},
			categories: time
		},
		yaxis: {
			// min: 43000,
			tickAmount: 4,
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Helvetica, Arial, sans-serif',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: 0,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => {
					return Math.ceil(value)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				}
			}
		},
		legend: {
			show: false
		},
		markers: {
			size: 4
		}
	};
	const chartSparklinesLarge2AData = [
		{
			name: 'Employees',
			data
		}
	];

	return (
		<>
			{lineChart.data.length > 0 && (
				<div className="mb-spacing-6-x2">
					<Grid container spacing={6}>
						<Grid item xl={12}>
							<Card className="card-box">
								<div className="card-header-alt d-flex justify-content-between p-4">
									<div>
										<h6 className="font-weight-bold font-size-lg mb-1 text-black">
											Headcount Trend
										</h6>
										<p className="text-black-50 mb-0">
											Reports for amount of employees trending in 24 months{' '}
										</p>
									</div>
									<div className="d-flex align-items-center">
										<Button className="btn-link d-40 btn-icon p-0 font-size-xl">
											<FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
										</Button>
									</div>
								</div>
								<div className="divider" />
								<div className="px-3 px-lg-5 py-2 m-0 bg-secondary">
									<Chart
										options={chartSparklinesLarge2AOptions}
										series={chartSparklinesLarge2AData}
										type="line"
										height={250}
									/>
								</div>
								<div className="divider" />
								<div className="p-4 text-center">
									<Grid container spacing={6}>
										<Grid item md={4}>
											<div
												className="mb-1 font-weight-bold font-size-xl"
												style={{ color: 'black' }}>
												{`${parseInt(((data[23] - data[0]) / data[23]) * 100)}%`}
											</div>
											{/*<LinearProgress variant="determinate" className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-danger" value={53}/>*/}
											<div className="text-black-50 pt-2">24 months</div>
										</Grid>
										<Grid item md={4}>
											<div
												className="mb-1 font-weight-bold font-size-xl"
												style={{ color: 'black' }}>
												{`${parseInt(((data[11] - data[0]) / data[11]) * 100)}%`}
											</div>
											{/*<LinearProgress variant="determinate" className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-info" value={25}/>*/}
											<div className="text-black-50 pt-2">12 months</div>
										</Grid>
										<Grid item md={4}>
											<div
												className="mb-1 font-weight-bold font-size-xl"
												style={{ color: 'black' }}>
												{`${parseInt(((data[5] - data[0]) / data[5]) * 100)}%`}
											</div>
											{/*<LinearProgress variant="determinate" className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-warning" value={9}/>*/}
											<div className="text-black-50 pt-2">6 months</div>
										</Grid>
									</Grid>
								</div>
							</Card>
						</Grid>
					</Grid>
				</div>
			)}
		</>
	);
}
