import React from 'react';
import { useCookies, withCookies } from 'react-cookie';
import { Redirect, Route } from 'react-router-dom';
import memory from './memory';

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');

	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

const PrivateRoute = ({ component: Component, ...rest }) => {
	//const [cookies, setCookie] = useCookies(['token']);
	const { user } = memory;

	const cookies = getCookie('token');
	console.log('token: ', cookies);
	return (
		<Route
			{...rest}
			render={(props) =>
				user && user.id ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
};

export default withCookies(PrivateRoute);
