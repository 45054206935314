import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, grey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Portal from '@material-ui/core/Portal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import FolderIcon from '@material-ui/icons/Folder';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import clsx from 'clsx';
import https from 'https';
import generateHash from 'random-hash';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Link as RouteLink, NavLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import sessionstorage from 'sessionstorage';
import styled from 'styled-components';
import {
	actions as projectsActions,
	getProjectsList
} from '../../../../redux/modules/projectsPage';
import { actions as recruitActions } from '../../../../redux/modules/recruitPage';
import LoadingIndicator from '../../../../theme/loadingIndicator';
import { removeHtmlTag } from '../../../../utils/emailTemplateUtil';
import memory from '../../../../utils/memory';
import {
	checkScheduleTime,
	handleScheduleTime,
	transformDateToLocalTZ,
	skillInterpretation,
	toBooleanSkills,
	fromBooleanSkills,
	skillGenerateSQL,
	addSkill,
	removeSkill,
	regx,
	specialCharactersHelperWordToLabel,
	reverseSkillGenerateSQL,
	reverseSkillInterpretation,
	checkValid,
	transformFilter
} from '../../../../utils/recruitUtil';
import { del, get, post } from '../../../../utils/request';
import storage from '../../../../utils/storage';
import url from '../../../../utils/url';
import AutoConnectTemplate from '../../../Recruit/components/AutoConnectTemplate';
import { BsSlashCircleFill } from 'react-icons/bs';
import { IoMdCreate } from 'react-icons/io';
import { IoMdAddCircle } from 'react-icons/io';
import Chip from '@material-ui/core/Chip';
import Slider from '@material-ui/core/Slider';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	achievement: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap'
	},
	premium: {
		backgroundColor: '#0795fe',
		borderRadius: '0.29rem !important'
	},
	radioGroup: {
		padding: '0.8px'
	},
	addSkillOperatorOn: {
		fontSize: '1.7em',
		color: '#3E96F6'
	},
	addSkillOperatorOff: {
		fontSize: '1.7em',
		color: '#D9D9D9'
	},
	orSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	orSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	},
	editSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	editSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	},
	chipRoot: {
		'& .MuiChip-icon': {
			order: 1, // the label has a default order of 0, so this icon goes after the label
			marginRight: '10px', // add some space between icon and delete icon
			cursor: 'pointer'
		},
		'& .MuiChip-deleteIcon': {
			order: 2 // since this is greater than an order of 1, it goes after the icon
		}
	}
}));

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const TITLEIMG = styled.img`
	width: 30px;
	height: auto;
`;

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ListTalentsInProject(props) {
	const { projects, tagId, projectName } = props;
	const { loadProjects, addProject } = props.projectsAction;
	const classes = useStyles();
	const { promiseInProgress } = usePromiseTracker();
	const history = useHistory();

	const [uploadFile, setUploadFile] = useState({
		uploadFileName: null,
		uploadFilePath: null,
		uploadFileSize: 0,
		uploadFileFullName: null
	});

	const [search, setSearch] = useState({
		currentCompany: [],
		location: [],
		openToOpportunities: [],
		achievement: [],
		stacks: [],
		yearsOfWorkingExperiences: [0, 100],
		entrepreneurship: [],
		seniority: [],
		currTenure: [],
		avgTenure: [],
		companies: { type: 'OR', options: [] },
		degree: [],
		preferredLocation: [],
		department_2: [],
		institute: [],
		diversity: [],
		language: [],
		skill: '',
		industry: { type: 'OR', options: [] },
		positionType: []
	});

	const [newTabSearch, setNewTabSearch] = useState({
		currentCompany: [],
		location: [],
		openToOpportunities: [],
		achievement: [],
		stacks: [],
		yearsOfWorkingExperiences: [0, 100],
		entrepreneurship: [],
		seniority: [],
		currTenure: [],
		avgTenure: [],
		companies: { type: 'OR', options: [] },
		degree: [],
		preferredLocation: [],
		department_2: [],
		institute: [],
		diversity: [],
		language: [],
		skill: '',
		industry: { type: 'OR', options: [] },
		positionType: []
	});

	const [updatedTalents, setUpdatedTalents] = useState({ newTalents: [], oldTalents: [] });

	const [state, setState] = React.useState({
		company: '',
		location: '',
		approach: 'false',
		department_1: '',
		department_2: ''
	});

	const [mainData, setMainData] = useState(null);

	//filter options
	const [filterSelects, setFilterSelect] = useState({
		Area: [],
		Company: [],
		Department: [],
		Seniority: [],
		Stack: [],
		YOE: [],
		Language: [],
		Attitude: [],
		Achievement: [],
		Entrepreneurship: [],
		CurrentTenure: [],
		AvgTenure: [],
		PreferredLocation: [],
		Diversity: [],
		Skill: [],
		Institute: [],
		Degree: [],
		Industry: [],
		PositionType: []
	});

	//const premium = memory.customer.premium;
	const premium = storage.getCustomer().premium;
	const [isPremiumOpen, setPremiumOpen] = useState(false);
	const [compDepMapping, setCompDepartMapping] = useState(null);
	const [positionTypeStackMapping, setPositionTypeStackMapping] = useState(null);
	const [skills, setSkills] = useState([]); //['Java','Javascript','Djongo']
	const [booleanSkills, setBooleanSkills] = useState([]);

	const [resultData, setResultData] = useState([]);
	const [activeActionTab, setActiveActionTab] = useState('4');
	const [open, setOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);

	//talent pool tabs
	const [activeTab, setActiveTab] = useState(0);

	//for existing tabs
	const [numberOfTabs, setNumberOfTabs] = useState(0); //define the number of filter in table currently
	const [projectFilters, setProjectFilters] = useState([]);
	const [tabs, setTabs] = useState([]); //used to record those tabs already open, [idx1, idx2]
	const [filterSearchResult, setFilterSearchResult] = useState([]); //{filterId_1:[res], filterId_2:[res]}

	//for new tabs
	const [numberOfNewTabs, setNumberOfNewTabs] = useState(0);
	const [newTabs, setNewTabs] = useState([]); //N1, N2
	const [newTabFilters, setNewTabFilters] = useState([]); //[{field1:{},field2:{}},{}]
	const [newTabFilterSearchResult, setNewTabFilterSearchResult] = useState([]); // [[res1, totalCount1, pageCount1, "NEW | filterId"],[]]
	const [isDeptTab, setDeptTab] = useState(false);
	// const [isDupFilter, setDupFilter] = useState(false);

	const [newTab, setNewTab] = useState({
		isNewTalentPool: false,
		isFetching: false,
		filterDisabled: true,
		filterId: null,
		isDupFilter: false
	});

	const [isAllTalentsFetch, setIsAllTalentsFetch] = useState(false);
	const [talentsSelectedEachPool, setTalentsSelectedEachPool] = useState(null);
	const [talentsPool, setTalentsPool] = useState(null);
	const [save, setSave] = useState({
		isSaveOpen: false,
		loading: false
	});
	const [isOverTabsOpen, setOverTabsOpen] = useState(false);

	const [tabDelete, setTabDelete] = useState({
		isDeleteOpen: false,
		tabIndex: 0,
		loading: false
	});

	const [checkers, setCheckers] = useState({});
	const [numberOfSelected, setNumberOfSelected] = useState(0);
	const [checkersData, setCheckersData] = useState([]);
	const [allTalentIds, setAllTalentIds] = useState([]);
	const [checkersPerTabPage, setCheckersPerTabPage] = useState([]);
	const [checkboxPerTabPage, setCheckboxPerTabPage] = useState([]);

	const [folders, setFolders] = useState([]);
	const [folderSelection, SetFolderSelection] = useState(null);
	const [isFolderUpdate, setFolderUpdate] = useState(false);

	const [insertArray, setInsertArray] = useState([]);
	const [subtractArray, setSubtractArray] = useState([]);

	const [isLoading, setLoading] = useState(false);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const fileRef = useRef(); //used to get updated file value in useCallback function
	const [file, setFile] = useState({
		filename: [],
		path: []
	});
	fileRef.current = file;

	const [isOppSendDisabled, setOppSendDisabled] = useState(true);
	const [isFilterSearchDisabled, setFilterSearchDisabled] = useState(true);

	//Filter
	const [companyFilterType, setCompanyFilterType] = useState('OR');
	const [skillFilterType, setSkillFilterType] = useState('AND');
	const [industryFilterType, setIndustryFilterType] = useState('OR');
	const [searchOpen, setSearchOpen] = useState(false);

	//Pagination
	const [pageCount, setPageCount] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const rowCount = 25;

	//Template
	const [templates, setTemplates] = useState(null);
	const [templateId, setTemplateId] = useState(null);
	const [isTemplateExpand, setTemplateExpand] = useState(false);
	const [isContextEmpty, setContextEmpty] = useState(true);
	const [templateName, setTemplateName] = useState('');
	const [templateNameMsg, setTemplateNameMsg] = useState('');
	const [snackOpen, setSnackOpen] = useState(false);
	const [isTemplateConfirm, setTemplateConfirm] = useState(false);
	const [isTemplateDuplicate, setTemplateDuplicate] = useState(false);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [templateType, setTemplateType] = useState('');

	// upload file
	const [myFiles, setMyFiles] = useState([]); //used to remove file
	const [autoConnectUploadFile, setAutoConnectUploadFile] = useState([]);
	const [autoUploadFile, setAutoUploadFile] = useState({
		filename: [],
		path: []
	});
	// Boolean KW editing control
	const [isKwEditing, setIsKwEditing] = useState(false);

	const handleEditButtonClick = () => {
		setIsKwEditing(!isKwEditing);
	};

	const bytesToMegaBytes = (bytes, roundTo) =>
		roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);

	const removeFile = (f) => () => {
		const newFiles = [...myFiles];
		const index = newFiles.indexOf(f);

		newFiles.splice(index, 1);
		setMyFiles(newFiles);

		file.filename.splice(index, 1);
		file.path.splice(index, 1);

		const newSize = Number(sessionstorage.getItem('totalSize')) - f.size;
		sessionstorage.setItem('totalSize', newSize);

		setFile({
			//related send email
			filename: file.filename,
			path: file.path
		});

		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
		} else {
			fileRejections.splice(0, fileRejections.length);
		}
		forceUpdate();
	};

	const accumulate = (file) => {
		console.log('file size', file.size);
		const MBformat = bytesToMegaBytes(file.size, 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			return {
				message: `Single file size cannot be larger than ${limit} MB`
			};
		}

		if (!sessionstorage.getItem('totalSize')) {
			sessionstorage.setItem('totalSize', file.size);
		} else {
			const newSize = Number(sessionstorage.getItem('totalSize')) + file.size;
			sessionstorage.setItem('totalSize', newSize);
		}

		return null;
	};

	const onDropAccepted = useCallback(
		(acceptedFiles) => {
			console.log(acceptedFiles);
			setLoading(true);

			let attachment = new FormData();

			let date = new Date();
			let user = memory.user.name;
			let email = memory.user.email.trim();

			let files = [];
			for (let i = 0; i < acceptedFiles.length; i++) {
				attachment.append('uploadFiles', acceptedFiles[i]);
				files.push(`${email}/${acceptedFiles[i].name}`);
			}

			const options = {
				params: {
					Keys: files,
					ContentType: 'application/x-www-form-urlencoded'
				},
				headers: {
					ContentType: 'application/x-www-form-urlencoded'
				}
			};

			//get preSign URL
			// const urls = await axios
			// 	.post(url.fetchPresignedURL(), attachment, options)
			// 	.then((res, rej) => {
			// 		console.log('fetch Presigned url', res);
			// 		return res.data.data;
			// 	})
			// 	.catch((err) => {
			// 		console.log('fetchPresignedURL error', err);
			// 	});
			// const urls = await axios
			// 	.post(
			// 		url.fetchPresignedURL(),
			// 		{
			// 			filesName: files
			// 		},
			// 		{ withCredentials: true, contentType: 'application/json' }
			// 	)
			// 	.then((res) => {
			// 		console.log('fetch Presigned url', res);
			// 		return res.data.data;
			// 	})
			// 	.catch((e) => console.log('fetchPresignedURL error', e));

			// await axios
			// 	.put(urls[0], acceptedFiles[0], options)
			// 	.then(() => {
			// 		let filenames = [];
			// 		let paths = [];
			// 		acceptedFiles.forEach((file) => {
			// 			filenames.push(file.name);
			// 			paths.push(`${email}/${file.name}`);
			// 		});

			// 		const newFileName = [...fileRef.current.filename, filenames];
			// 		const newPath = [...fileRef.current.path, paths];

			// 		acceptedFiles.forEach((file) => {
			// 			myFiles.push(file);
			// 		});
			// 		setMyFiles(myFiles);

			// 		setFile({
			// 			filename: newFileName.flat(),
			// 			path: newPath.flat()
			// 		});

			// 		setLoading(false);
			// 	})
			// 	.catch((e) => console.log(e));

			axios //TODO original way to upload files
				.post(url.uploadAttachment(), attachment, options)
				.then(() => {
					let filenames = [];
					let paths = [];
					acceptedFiles.forEach((file) => {
						filenames.push(file.name);
						paths.push(`${email}/${file.name}`);
					});

					const newFileName = [...fileRef.current.filename, ...filenames];
					const newPath = [...fileRef.current.path, ...paths];

					setMyFiles((prevState) => [...prevState, ...acceptedFiles]);

					setFile({
						filename: newFileName,
						path: newPath
					});

					setLoading(false);
				})
				.catch((e) => console.log(e));
		},
		[myFiles, file]
	);

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		fileRejections,
		acceptedFiles,
		getRootProps,
		getInputProps
	} = useDropzone({
		onDropAccepted,
		accept: ['.pdf', '.docx', '.doc', '.rtf', '.pptx', '.ppt'],
		validator: accumulate
	});

	let fileRejectionItems = fileRejections.map(({ errors }) => (
		<>
			{errors.map((e) => (
				<Alert severity="warning" className="text-center mb-3">
					{e.message}
				</Alert>
			))}
		</>
	));

	const validateFiles = () => {
		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
			forceUpdate();
			return false;
		}

		if (myFiles.length > 5) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Maximum number of files upload: 5` }] });
			forceUpdate();
			return false;
		}

		return true;
	};

	let files = myFiles.map((file) => (
		<ListItem
			className="font-size-sm px-3 py-2 text-primary d-flex justify-content-start align-items-center"
			key={file.path}>
			<span>
				<span
					className="badge badge-pill bg-neutral-danger text-primary mr-2"
					onClick={removeFile(file)}>
					X
				</span>
				{file.path}
			</span>
			<span className="badge badge-pill bg-neutral-warning text-primary">
				{bytesToMegaBytes(file.size, 2)} MB
			</span>
		</ListItem>
	));

	const handleSaveTalents = async () => {
		const assignTalentsFolderUpdate = { add: new Set(), delete: new Set() };
		// checkers represent all talents selected
		const talentIds = new Set();
		for (const [talentId, talent] of Object.entries(checkers)) {
			if (talent === undefined) {
				continue;
			} else {
				talentIds.add(parseInt(talentId));
			}
		}

		//update existing pool
		for (const filterId of Object.keys(talentsSelectedEachPool)) {
			//{filterId:{talentId_1:N, talentId_2:Y}, filterId:{}}
			for (const talentId of Object.keys(talentsSelectedEachPool[filterId])) {
				if (talentIds.has(parseInt(talentId))) {
					talentsSelectedEachPool[filterId][talentId] = 'Y';
				} else {
					talentsSelectedEachPool[filterId][talentId] = 'N';
				}
			}
		}

		const data = {};
		let idx = 1;
		if (Object.keys(talentsPool).length > 0) {
			for (const filterId of Object.keys(talentsPool)) {
				const talentIds = talentsPool[filterId];
				const set = new Set([...talentIds]); //already selected talents

				data['filter' + idx] = {
					filterId: filterId,
					type: 'EXIST',
					data: {
						delete: [],
						add: []
					}
				};

				for (const [talentId, selected] of Object.entries(talentsSelectedEachPool[filterId])) {
					if (set.has(parseInt(talentId))) {
						if (selected === 'N') {
							data['filter' + idx].data.delete.push({
								tagId: tagId,
								filterId: filterId,
								talentId: parseInt(talentId)
							});
							assignTalentsFolderUpdate.delete.add(parseInt(talentId));
						}
					} else {
						if (selected === 'Y') {
							data['filter' + idx].data.add.push({
								tagId: tagId,
								filterId: filterId,
								talentId: parseInt(talentId)
							});
							if (!allTalentIds.includes(parseInt(talentId))) {
								assignTalentsFolderUpdate.add.add(parseInt(talentId));
							}
						}
					}
				}

				idx++;
			}
		} else {
			for (const [filterId, talents] of Object.entries(talentsSelectedEachPool)) {
				data['filter' + idx] = {
					filterId: filterId,
					type: 'EXIST',
					data: {
						delete: [],
						add: []
					}
				};

				for (const [talentId, selected] of Object.entries(talents)) {
					if (selected === 'Y') {
						data['filter' + idx].data.add.push({
							tagId: tagId,
							filterId: filterId,
							talentId: parseInt(talentId)
						});
						assignTalentsFolderUpdate.add.add(parseInt(talentId));
					}
				}

				idx++;
			}
		}

		//update new talent pools
		for (let i = 0; i < newTabFilterSearchResult.length; i++) {
			const info = newTabFilterSearchResult[i];

			if (info[3] !== 'NEW') continue;

			const resultData = info[0].flat();
			const newFilterId = generateHash({ length: 20 });
			const newTabSearch = newTabFilters[i];
			const newFilter = { tagId: tagId, filterId: newFilterId, filter: newTabSearch };
			data['filter' + idx] = {
				filterId: newFilterId,
				type: 'NEW',
				data: {
					delete: [],
					add: []
				},
				filter: newFilter
			};

			for (const talent of resultData) {
				if (talentIds.has(talent.id)) {
					data['filter' + idx].data.add.push({
						tagId: tagId,
						filterId: newFilterId,
						talentId: talent.id
					});
					if (!allTalentIds.includes(talent.id)) {
						assignTalentsFolderUpdate.add.add(talent.id);
					}
				}
			}

			idx++;
		}

		for (const key of Object.keys(assignTalentsFolderUpdate)) {
			assignTalentsFolderUpdate[key] = [...assignTalentsFolderUpdate[key]];
		}

		//pass to the server
		setSave((prevState) => ({ ...prevState, isLoading: true }));
		await axios
			.post(
				url.updateTalentsToCurrentProject(),
				{
					dataUpdate: data,
					assignTalentsFolderUpdate: assignTalentsFolderUpdate,
					tagId: tagId
				},
				config
			)
			.then(
				(res) => {
					//window.location.reload();
					console.log(res.data.message);
				},
				(error) => {
					console.log('updateTalentsToCurrentProject error', error);
				}
			)
			.catch((e) => {
				console.log('updateTalentsToCurrentProject error catch', e.message);
				return Promise.reject(e.message);
			});

		setSave((prevState) => ({ ...prevState, isLoading: false }));
		history.push('/savedprojects');
	};

	const sortingResult = (resultData) => {
		const talentCheckedIds = Object.entries(checkers)
			.filter(([_, value]) => value !== undefined)
			.map(([talentId, _]) => parseInt(talentId));
		const set = new Set([...talentCheckedIds]);

		const filterTalentsData = resultData.filter((each) => {
			return !(
				updatedTalents.newTalents.includes(each.id) || updatedTalents.oldTalents.includes(each.id)
			);
		});
		for (const talent of filterTalentsData) {
			if (set.has(talent.id)) {
				talent.selected = 1;
			} else {
				talent.selected = 0;
			}
		}
		filterTalentsData.sort((a, b) => {
			if (a.selected < b.selected) return 1;
			else if (a.selected > b.selected) return -1;
			return 0;
		});

		//update - new talents
		let count = 0;
		const newTalentsData = resultData.filter((each) => {
			if (updatedTalents.newTalents.includes(each.id)) {
				return true;
			}
			count++;
			// console.log(count);
			return false;
		});
		for (const talent of newTalentsData) {
			if (set.has(talent.id)) {
				talent.selected = 1;
			} else {
				talent.selected = 0;
			}
		}

		//update - old talents
		const oldTalentsData = resultData.filter((each) => {
			return updatedTalents.oldTalents.includes(each.id);
		});
		for (const talent of oldTalentsData) {
			if (set.has(talent.id)) {
				talent.selected = 1;
			} else {
				talent.selected = 0;
			}
		}
		oldTalentsData.sort((a, b) => {
			if (a.selected < b.selected) return 1;
			else if (a.selected > b.selected) return -1;
			return 0;
		});

		const finalData = [...newTalentsData, ...filterTalentsData, ...oldTalentsData];

		setResultData(finalData);
		const local = finalData;
		setMainData(local.slice(0, rowCount));

		return finalData;
	};

	const handleUpdateProjects = async () => {
		const talentId = selectedRow.id;
		setAutoLoading(true);

		if (subtractArray.length > 0) {
			post(url.cancelAssignProjects(), subtractArray).then((res) => console.log(res));
		}

		if (insertArray.length > 0) {
			let filterId = undefined; //if undefined -> old tab
			if (typeof activeTab === 'string') {
				const index = parseInt(activeTab.substring(1)) - 1;
				filterId = newTabFilterSearchResult[index][3];
			}

			await axios
				.post(
					url.addTalentToTalentPools(),
					{
						tagIds: insertArray,
						talentId: talentId,
						currFilter: search,
						newFilterId: filterId,
						currTagId: tagId
					},
					config
				)
				.then((res) => {
					console.log(res.data.message);
					const data = res.data.data;

					if (data.length > 0) {
						const filterId = data[0].filterId;
						const index = parseInt(activeTab.substring(1)) - 1;
						const copyNewTabFilterSearchResult = Array.from(newTabFilterSearchResult);
						copyNewTabFilterSearchResult[index][3] = filterId; // [result, totalcount, pagecount, filterId]
						setNewTabFilterSearchResult(copyNewTabFilterSearchResult);
					}
				})
				.catch((e) => console.error('Change folders for the given talent has error', e));
		}

		const set = new Set([...folders.map((folder) => folder.id)]);
		if (set.has(tagId)) {
			setCheckers((prevState) => ({ ...prevState, [talentId]: selectedRow }));
		} else {
			setCheckers((prevState) => ({ ...prevState, [talentId]: undefined }));
		}

		if (insertArray.length > 0 || subtractArray.length > 0) {
			await axios
				.get(url.fetchTalentsSelectedByProject(tagId))
				.then(
					(res) => {
						console.log(res.data.message);
						const data = res.data.data;
						const talentsPool = data.talentsPool; //category by filter eg: filterId:[talentId1, talentId2], used to save project
						const talentsSelected = data.allTalents; //[id]:each talent

						setTalentsPool(talentsPool);
						setAllTalentIds(Object.keys(talentsSelected).map((talentId) => parseInt(talentId)));
					},
					(error) => {
						console.error('update projects fetchTalentsSelectedByProject error', error);
					}
				)
				.catch((e) => {
					console.error('update projects fetchTalentsSelectedByProject error catch', e.message);
					return Promise.reject(e.message);
				});

			await axios
				.get(url.fetchFiltersByProject(tagId))
				.then(
					async (res) => {
						const data = res.data.data;
						const talentsSelected = data.talentsSelected; //{filterId:{talentId_1:N, talentId_2:Y}, filterId:{}}

						setTalentsSelectedEachPool(talentsSelected);
					},
					(error) => {
						console.error('update projects fetchFiltersByProject error', error);
					}
				)
				.catch((e) => {
					console.error('update projects fetchFiltersByProject error catch', e.message);
					return Promise.reject(e.message);
				});
		}

		handleClose();
		setAutoLoading(false);
	};

	const handleSearch = () => {
		let cnt = 0;
		for (const [field, values] of Object.entries(search)) {
			if (field === 'companies' || field === 'industry') {
				if (values.options.length > 0) cnt++;
			} else {
				if (values && values !== undefined && values.length > 0) cnt++;
			}
		}

		setResultData([]);
		setMainData([]);
		setPage(1);
		setTotalCount(0);
		setPageCount(0);
		setNumberOfSelected(0);

		if (cnt > 0) {
			setSearchOpen(false);
			goSearchTalents(search, 1);
		} else {
			setSearchOpen(true);
		}
	};
	const handleDuplicateFilter = () => {
		console.log('Button clicked');
		console.log(search);

		setNewTab({ ...newTab, isNewTalentPool: true, filterDisabled: false, isDupFilter: true });
		setBooleanSkills([]);

		// const filter = { ...search }; f
		// setSearch({ ...search, ...filter }); f
		setDeptTab(true);
		setFilterSearchDisabled(false);
		toggle('N'.concat(numberOfNewTabs + 1));
		setNumberOfNewTabs((prevState) => prevState + 1);
	};

	const handleDuplicateFilterAsNew = () => {
		const currentFilters = {
			filterSelect: projectFilters
		};
		const filterToSave = currentFilters.filterSelect[0].filter;

		console.log('Duplicating filters as new search:', filterToSave);

		localStorage.setItem('savedFilters', JSON.stringify(filterToSave));

		history.push('/recruit');
	};

	const handleExclusive = (name, item) => {
		if (name === 'stacks') {
			const selectedItems = JSON.parse(JSON.stringify(search[name]));
			for (let i = 0; i < selectedItems.length; i++) {
				const selectedItem = selectedItems[i];
				if (selectedItem.id === item.id) {
					if (typeof selectedItem.exclusive === 'undefined' || !selectedItem.exclusive) {
						selectedItem.exclusive = true;
					} else {
						selectedItem.exclusive = false;
					}
				}
			}

			setSearch({
				...search,
				[name]: selectedItems
			});
		} else if (name === 'companies') {
			const inclusiveItems = [];
			const selectedItems = JSON.parse(JSON.stringify(search[name].options));
			for (let i = 0; i < selectedItems.length; i++) {
				const selectedItem = selectedItems[i];
				if (selectedItem.id === item.id) {
					if (typeof selectedItem.exclusive === 'undefined' || !selectedItem.exclusive) {
						selectedItem.exclusive = true;
					} else {
						selectedItem.exclusive = false;
						inclusiveItems.push(selectedItem);
					}
				} else {
					if (!selectedItem.exclusive) {
						inclusiveItems.push(selectedItem);
					}
				}
			}

			setSearch({
				...search,
				[name]: { type: companyFilterType, options: selectedItems },
				department_2: []
			});

			//dynamic load department
			let departmentList = [];
			for (const company of inclusiveItems) {
				const key = company.key;

				if (compDepMapping[key] && typeof compDepMapping[key] !== 'undefined') {
					const list = compDepMapping[key];
					departmentList = [...departmentList, ...list];
				}
			}
			setFilterSelect({ ...filterSelects, Department: departmentList });
		}
	};

	/******************************************************* Search Start *********************************************/
	// first time to search
	const goSearchTalents = async (filters, page) => {
		console.log('checkers', checkers);
		console.log('enter go search talents test....');

		const talentCheckedIds = Object.entries(checkers)
			.filter(([_, value]) => value !== undefined)
			.map(([talentId, _]) => parseInt(talentId));

		trackPromise(
			axios
				.post(
					url.recruitAvailable(),
					{
						data: {
							offset: page * rowCount - rowCount,
							rowCount: rowCount,
							nickQuery: memory.user.email === 'nick.bieler@ocbang.com' ? true : false,
							...filters
						},
						projectEdit: true,
						projectId: tagId
					},
					config
				)
				.then(
					async (res) => {
						console.log('fetch recruit data', res.data);
						const result = res.data.data.result;
						const pageCount = res.data.data.pageCount;
						const totalCount = res.data.data.totalCount;

						if (isAllTalentsFetch) {
							// default True
							console.log(checkers);

							const set = new Set([...talentCheckedIds]);
							let cnt = 0;
							for (const talent of result) {
								if (set.has(talent.id)) {
									cnt++;
								}
							}

							//info [result, totalCnt, pageCnt, isNewTab?, isDepartment?]
							//TODO still need to evaludate activeTab as existing or new
							if (typeof activeTab === 'string') {
								if (!newTabs.includes(activeTab)) {
									setNewTabs((prevState) => [...prevState, activeTab]);
									const info = [[result], totalCount, pageCount, 'NEW'];
									setNewTabFilterSearchResult([...newTabFilterSearchResult, info]);
									setNewTabFilters([...newTabFilters, filters]);
								} else {
									const index = parseInt(activeTab.substring(1)) - 1;
									const copyNewTabFilterSearchResult = newTabFilterSearchResult.slice();
									const info = [[result], totalCount, pageCount, 'NEW'];
									copyNewTabFilterSearchResult.splice(index, 1, info);
									setNewTabFilterSearchResult(copyNewTabFilterSearchResult);

									const copyNewTabFilters = newTabFilters.slice();
									copyNewTabFilters.splice(index, 1, filters);
									setNewTabFilters(copyNewTabFilters);
								}
							} else if (typeof activeTab === 'number') {
								// if it's old TP, get this filter's info.
								const filterId = projectFilters[activeTab].filterId;

								const copyTabs = tabs.slice();
								copyTabs.splice(activeTab, 1, filterId);
								setTabs(copyTabs); // setTabs is for storing filterid

								const copyFilterSearchResult = filterSearchResult.slice();
								const info = [[result], totalCount, pageCount];
								copyFilterSearchResult.splice(activeTab, 1, info);
								setFilterSearchResult(copyFilterSearchResult);
							}

							setNumberOfSelected(cnt);
							setTotalCount(totalCount);
							setPageCount(pageCount);
							setPage(1);

							//initialize number of page for current tab
							const init = [...Array(pageCount)];
							init.fill({});
							const checkboxInit = [...Array(pageCount)];
							checkboxInit.fill(false);
							if (typeof activeTab === 'string') {
								const newCheckersPerTabPage = [...checkersPerTabPage, init];
								const newCheckboxPerTabPage = [...checkboxPerTabPage, checkboxInit];

								//sorting
								const sorting_resultData = sortingResult(result);

								initializeChecboxAndCheckers(
									sorting_resultData,
									newCheckersPerTabPage,
									newCheckboxPerTabPage
								);
							} else if (typeof activeTab === 'number') {
								const copyTabs = Array.from(checkersPerTabPage);
								copyTabs[activeTab] = init;
								//setCheckersPerTabPage(copyTabs);

								const copyCheckboxTabs = Array.from(checkboxPerTabPage);
								copyCheckboxTabs[activeTab] = checkboxInit;
								//setCheckboxPerTabPage(copyCheckboxTabs);

								//sorting
								const sorting_resultData = sortingResult(result);

								initializeChecboxAndCheckers(sorting_resultData, copyTabs, copyCheckboxTabs);
							}
						}

						return res;
					},
					(error) => console.log('fetch recruit data error', error)
				)
				.catch((e) => console.log('fetch recruit data error catch', e.message))
		);
	};

	const handleAdvancedSearch = (e, value, name) => {
		console.log('handle search....');

		if (name === 'companies') {
			setSearch({
				...search,
				[name]: { type: companyFilterType, options: value },
				department_2: []
			});
			const filters = {
				...search,
				[name]: { type: companyFilterType, options: value },
				department_2: []
			};
			setNewTabSearch({ ...newTabSearch, ...filters });

			//dynamic load department
			let departmentList = [];
			for (const company of value) {
				const key = company.key;

				if (company.exclusive) {
					continue;
				}

				if (compDepMapping[key] && typeof compDepMapping[key] !== 'undefined') {
					const list = compDepMapping[key];
					departmentList = [...departmentList, ...list];
				}
			}
			setFilterSelect({ ...filterSelects, Department: departmentList });
		} else if (name === 'skill') {
			setSearch({
				...search,
				[name]: value && typeof value !== 'undefined' ? value.trim() : ''
			});
			const filters = {
				...search,
				[name]: value && typeof value !== 'undefined' ? value.trim() : ''
			};
			setNewTabSearch({ ...newTabSearch, ...filters });
		} else if (name === 'industry') {
			setSearch({
				...search,
				[name]: { type: industryFilterType, options: value }
			});
			const filters = { ...search, [name]: { type: industryFilterType, options: value } };
			setNewTabSearch({ ...newTabSearch, ...filters });
		} else if (name === 'positionType') {
			setSearch({
				...search,
				[name]: value
			});
			const filters = {
				...search,
				[name]: value,
				stacks: []
			};
			setNewTabSearch({ ...newTabSearch, ...filters });

			//dynamic load stack
			let stackList = [];
			for (const position of value) {
				const key = position.key;
				const list = positionTypeStackMapping[key];
				stackList = [...stackList, ...list];
			}
			setFilterSelect({ ...filterSelects, Stack: stackList });
		} else {
			setSearch({
				...search,
				[name]: value
			});
			const filters = { ...search, [name]: value };
			setNewTabSearch({ ...newTabSearch, ...filters });
		}
	};

	const handleFullTextSearch = async (e, value, filter) => {
		console.log('handle full text', value);

		let path;
		if (filter === 'skill') {
			path = url.fetchSkills();
		} else if (filter === 'institute') {
			path = url.fetchInstitutes();
		}

		await axios
			.get(path, {
				params: {
					word: value
				}
			})
			.then((res) => {
				console.log('handleFullTextSearch', res.data);
				const name = filter === 'skill' ? 'Skill' : 'Institute';
				setFilterSelect({ ...filterSelects, [name]: res.data.data });
			})
			.catch((e) => {
				console.error('handleFullTextSearch error catch', e.message);
			});
	};

	const initializeChecboxAndCheckers = (result, checkersObj, checkboxObj) => {
		const copyCheckersPerTabPage = JSON.parse(JSON.stringify(checkersObj));
		const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxObj));
		let start = 0,
			end = start + rowCount;
		let page = 1;
		while (start < result.length) {
			const talentsPerPage = result.slice(start, end);

			const index =
				typeof activeTab === 'number'
					? activeTab
					: numberOfTabs + parseInt(activeTab.substring(1)) - 1;
			const checkersPerPage = copyCheckersPerTabPage[index][page - 1];
			let checkboxPerTab = copyCheckboxPerTabPage[index];

			let count = 0;
			for (const each of talentsPerPage) {
				if (checkers[each.id] !== undefined) {
					checkersPerPage[each.id] = each;
					count++;
				}
			}
			start = end;
			end += rowCount;

			if (talentsPerPage.length === count) {
				checkboxPerTab[page - 1] = true;
			} else {
				checkboxPerTab[page - 1] = false;
			}

			page++;
		}

		setCheckboxPerTabPage(copyCheckboxPerTabPage);
		setCheckersPerTabPage(copyCheckersPerTabPage);
	};

	/******************************************************* Search End *********************************************/

	const handleChange = (event) => {
		const name = event.target.name;
		console.log(event.target);
		setState({
			...state,
			[name]: event.target.value
		});
	};

	const [page, setPage] = useState(1);
	const handleChangePage = (e, value) => {
		setPage(value);
		const local = resultData;
		setMainData(local.slice(value * rowCount - rowCount, value * rowCount));
	};

	const handleDeleteTab = async () => {
		setTabDelete({ ...tabDelete, loading: true });

		if (typeof tabDelete.tabIndex === 'number') {
			const tagPool = projectFilters[tabDelete.tabIndex];
			const filterId = tagPool.filterId;
			const filter = tagPool.filter;

			await del(url.deleteProjectTab(), { filterId: filterId }).then((res) => {
				console.log(res.message);
				const isAllTalentsDeleted = res.data;
				if (isAllTalentsDeleted && numberOfNewTabs === 0) {
					history.push('/savedprojects');
					return;
				}

				//turn to first tab if exist
				//Case1 only one tab, and deleted

				//Case2 general
				setNumberOfTabs((prevState) => prevState - 1);

				const copyProjectFilters = projectFilters.slice();
				copyProjectFilters.splice(tabDelete.tabIndex, 1);
				setProjectFilters(copyProjectFilters);

				const copyTalentPools = { ...talentsPool };
				delete copyTalentPools[filterId];
				setTalentsPool(copyTalentPools);

				const copyTalentsSelectedEachPool = { ...talentsSelectedEachPool };
				delete copyTalentsSelectedEachPool[filterId];
				setTalentsSelectedEachPool(copyTalentsSelectedEachPool);

				const copyCheckersPerTabPage = checkersPerTabPage.slice();
				copyCheckersPerTabPage.splice(tabDelete.tabIndex, 1);
				setCheckersPerTabPage(copyCheckersPerTabPage);

				const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
				copyCheckboxPerTabPage.splice(tabDelete.tabIndex, 1);
				setCheckboxPerTabPage(copyCheckboxPerTabPage);

				if (tabs.includes(filterId)) {
					const copyTabs = tabs.slice();
					copyTabs.splice(tabDelete.tabIndex, 1);
					setTabs(copyTabs);

					const copyFilterSearchResult = filterSearchResult.slice();
					const index3 = tabDelete.tabIndex;
					copyFilterSearchResult.splice(index3, 1);
					setFilterSearchResult(copyFilterSearchResult);
				}

				toggle(0);
			});
		} else if (typeof tabDelete.tabIndex === 'string') {
			//N1, N2

			setNumberOfNewTabs((prevState) => prevState - 1);
			if (numberOfNewTabs === 1) {
				setNewTab({ ...newTab, isNewTalentPool: false, isFetching: false, filterDisabled: true });

				const filter = {};
				for (const key of Object.keys(search)) {
					if (key === 'skill' || key === 'companies' || key === 'industry') {
						filter[key] = { type: 'OR', options: [] };
					} else if (key === 'skill') {
						filter[key] = '';
					} else if (key === 'yearsOfWorkingExperiences') {
						filter[key] = [0, 100];
					} else {
						filter[key] = [];
					}
				}
				setSearch({ ...search, ...filter });
			}

			if (newTabs.includes(tabDelete.tabIndex)) {
				//N1, N2 created after search
				const copyNewTabs = newTabs.slice(); //N1,N2
				const index1 = parseInt(tabDelete.tabIndex.substring(1)) - 1;
				const len = copyNewTabs.length - index1 - 1;
				copyNewTabs.splice(index1);
				const initialLen = copyNewTabs.length;
				const finalNewTabs = [
					...copyNewTabs,
					...[...Array(len)].map((el, idx) => 'N'.concat(idx + initialLen + 1))
				];
				setNewTabs(finalNewTabs);

				const copyNewTabFilters = newTabFilters.slice();
				const index2 = parseInt(tabDelete.tabIndex.substring(1)) - 1;
				copyNewTabFilters.splice(index2, 1);
				setNewTabFilters(copyNewTabFilters);

				const index3 = parseInt(tabDelete.tabIndex.substring(1)) - 1;
				//delete talentsPool & talentsSelectedEachPool
				const info = newTabFilterSearchResult[index3]; // [result, totalcount, pagecount, filterId]
				console.log(info);
				const filterId = info[3];
				console.log(filterId);
				if (filterId && filterId !== 'NEW') {
					//individual change project
					delete talentsPool[filterId];
					delete talentsSelectedEachPool[filterId];

					await del(url.deleteProjectTab(), { filterId: filterId }).then((res) => {
						console.log(res.message);
					});
				}

				const copyNewTabFilterSearchResult = newTabFilterSearchResult.slice();
				copyNewTabFilterSearchResult.splice(index3, 1);
				setNewTabFilterSearchResult(copyNewTabFilterSearchResult);

				const copyCheckersPerTabPage = checkersPerTabPage.slice();
				const index4 = numberOfTabs + parseInt(tabDelete.tabIndex.substring(1)) - 1;
				copyCheckersPerTabPage.splice(index4, 1);
				setCheckersPerTabPage(copyCheckersPerTabPage);

				const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
				const index5 = numberOfTabs + parseInt(tabDelete.tabIndex.substring(1)) - 1;
				copyCheckboxPerTabPage.splice(index5, 1);
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			}

			if (numberOfTabs > 0) {
				toggle(0);
			} else {
				const idx = 0;
				toggle('N'.concat(numberOfTabs + idx + 1));
			}
		}

		setTabDelete({ ...tabDelete, isDeleteOpen: false, loading: false });
	};

	const createNewTab = () => {
		if (numberOfNewTabs + numberOfTabs === 10) {
			setOverTabsOpen(true);
			return;
		}

		setNewTab({ ...newTab, isNewTalentPool: true, filterDisabled: false });

		const info =
			newTabFilterSearchResult[numberOfNewTabs > 0 ? numberOfNewTabs - 1 : Number.MAX_SAFE_INTEGER];
		const result = info ? info[0].flat() : [];
		if (numberOfNewTabs === 0 || (result && result.length > 0)) {
			//the tab in front of newone has data
			const filter = {};
			for (const key of Object.keys(search)) {
				if (key === 'companies' || key === 'industry') {
					filter[key] = { type: 'OR', options: [] };
				} else if (key === 'skill') {
					filter[key] = '';
				} else if (key === 'yearsOfWorkingExperiences') {
					filter[key] = [0, 100];
				} else {
					filter[key] = [];
				}
			}
			setSearch({ ...search, ...filter });
			toggle('N'.concat(numberOfNewTabs + 1));
			setNumberOfNewTabs((prevState) => prevState + 1);
			setBooleanSkills([]);
			setDeptTab(false);
		}
	};

	// pass existing filters to the new searching page (**IMPORTANT for dup filters**)
	const openDepartTab = async (selectedRow) => {
		console.log('Open Department Tab......');
		/**
		 *  location, seniority, companies, c2
		 */
		// copying selected filter info. below to the new tab
		const area = selectedRow.area; //"GB"
		const companies = selectedRow.companies; //list [{},{}]
		const depart2 = selectedRow.department_2; //{}
		const seniority = selectedRow.seniority; //011_ddddd

		const company = [
			...new Set(
				companies
					.filter((comp) => comp.isCurrent === 1 && comp.flagExp !== 0)
					.map((item) => item.flagExp)
			)
		];

		const fs = await axios
			.post(
				url.fetchDeptFilters(),
				{
					area: area,
					flagExp: company[0],
					seniority: seniority
				},
				config
			)
			.then((res) => {
				console.log(res.data);
				return res.data.data;
			})
			.catch((e) => {
				console.error('fetchDeptFilters has error', e);
			});

		if (numberOfNewTabs + numberOfTabs === 10) {
			setOverTabsOpen(true);
			return;
		}

		setNewTab({ ...newTab, isNewTalentPool: true, filterDisabled: false });

		const info =
			newTabFilterSearchResult[numberOfNewTabs > 0 ? numberOfNewTabs - 1 : Number.MAX_SAFE_INTEGER];
		const result = info ? info[0].flat() : [];
		// 判断前一个tab中有没有搜索结果，if Ture: 可以新建tab, else: 不能新建tab
		if (numberOfNewTabs === 0 || (result && result.length > 0)) {
			//the tab in front of newone has data
			const filter = { ...search };
			for (const key of Object.keys(filter)) {
				switch (key) {
					case 'companies':
						filter[key] = { type: 'OR', options: fs[1] ? [fs[1]] : [] };
						break;
					case 'department_2':
						filter[key] = depart2 ? [depart2] : [];
						break;
					case 'stacks':
						filter[key] = [];
						break;
					default:
						console.log('department action default value');
				}
			}

			//dynamic load department
			const compFilter = fs[1];
			let departmentList = [];
			const key = compFilter.key;
			const list = compDepMapping[key];
			departmentList = [...departmentList, ...list];
			setFilterSelect({ ...filterSelects, Department: departmentList });

			setSearch({ ...search, ...filter });
			setDeptTab(true);
			setFilterSearchDisabled(false);
			toggle('N'.concat(numberOfNewTabs + 1));
			setNumberOfNewTabs((prevState) => prevState + 1);
		}
	};

	const handleFolderChange = async (event, values) => {
		console.log(selectedRow.tags); //new existing project
		const talentId = selectedRow.id;
		const selectedProjects = values;
		const assignedProjects = selectedRow.tags;
		const insert = [];
		const subtract = [];

		//Add project to the given talent
		for (const selectedProject of selectedProjects) {
			const assignedProjects = selectedRow.tags;
			const assignedProjectsIds = assignedProjects.map((item) => item.id);

			if (!assignedProjectsIds.includes(selectedProject.id)) {
				const project = folderSelection.find((project) => project.id === selectedProject.id);
				insert.push(project.id);
			}
		}

		//Remove project from the given talent
		assignedProjects.forEach((each) => {
			if (!selectedProjects.includes(each)) {
				const project = folderSelection.find((project) => project.name === each.name);
				subtract.push({
					tagId: project.id,
					talentId: talentId
				});
			}
		});
		if (subtract.length > 0) {
			setSubtractArray(subtract);
		}

		if (insert.length > 0) {
			setInsertArray(insert);
		}

		setFolders(values);
		setFolderUpdate(true);
	};

	const handleClickAction = async (e, data, tabIndex) => {
		e.preventDefault();
		console.log('talent select', data);
		const row = e.target.parentNode.parentNode.parentNode.parentNode;
		row.focus();

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then(
				(res) => {
					const result = res.data;
					console.log('fetchTalentDetail', result.message);
					setSelectedRow(result.data);
					setFolders(result.data.tags);
					setActiveActionTab(tabIndex);
					setLetter((prevState) => ({
						...prevState,
						candidateEmailMapping: [data.id]
					}));
					setOpen(true);
				},
				(error) => {
					console.error('fetchTalentDetail error', error);
				}
			)
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
			});
	};

	const handleCheckboxAction = async (e, id, each) => {
		if (e.target.checked) {
			setCheckers((prevState) => ({ ...prevState, [id]: each }));
			setNumberOfSelected((prevState) => prevState + 1);

			if (typeof activeTab === 'number') {
				const checkersPerTab = checkersPerTabPage[activeTab]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}
				checkersPerTab[page - 1] = { ...currentPage, [id]: each };
				setCheckersPerTabPage(checkersPerTabPage);

				// check checkbox all
				let count = 0;
				for (const each of mainData) {
					if (checkersPerTab[page - 1][each.id] !== undefined) count++;
				}
				if (count === mainData.length) {
					const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
					const index = activeTab;
					copyCheckboxPerTabPage[index][page - 1] = true;
					setCheckboxPerTabPage(copyCheckboxPerTabPage);
				}
			} else if (typeof activeTab === 'string') {
				const index = parseInt(activeTab.substring(1)) - 1;
				const finalIndex = numberOfTabs + index;
				const checkersPerTab = checkersPerTabPage[finalIndex]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}
				checkersPerTab[page - 1] = { ...currentPage, [id]: each };
				setCheckersPerTabPage(checkersPerTabPage);

				// check checkbox all
				let count = 0;
				for (const each of mainData) {
					if (checkersPerTab[page - 1][each.id] !== undefined) count++;
				}
				if (count === mainData.length) {
					const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
					copyCheckboxPerTabPage[finalIndex][page - 1] = true;
					setCheckboxPerTabPage(copyCheckboxPerTabPage);
				}
			}

			//TODO checkersData concern
			setCheckersData([...checkersData, each]);
		} else {
			setCheckers((prevState) => ({ ...prevState, [id]: undefined }));
			setNumberOfSelected((prevState) => prevState - 1);

			if (typeof activeTab === 'number') {
				const checkersPerTab = checkersPerTabPage[activeTab]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}
				checkersPerTab[page - 1] = { ...currentPage, [id]: undefined };
				setCheckersPerTabPage(checkersPerTabPage);

				const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
				const index = activeTab;
				copyCheckboxPerTabPage[index][page - 1] = false;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			} else if (typeof activeTab === 'string') {
				const index = parseInt(activeTab.substring(1)) - 1;
				const finalIndex = numberOfTabs + index;
				const checkersPerTab = checkersPerTabPage[finalIndex]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}
				checkersPerTab[page - 1] = { ...currentPage, [id]: undefined };
				setCheckersPerTabPage(checkersPerTabPage);

				const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));
				copyCheckboxPerTabPage[finalIndex][page - 1] = false;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			}

			const index = checkersData.findIndex((item) => item === each);
			checkersData.splice(index, 1);
			setCheckersData(checkersData);
		}
	};

	const handleCheckAllAction = async () => {
		const copyCheckboxPerTabPage = JSON.parse(JSON.stringify(checkboxPerTabPage));

		const talentCheckedIds = Object.entries(checkers)
			.filter(([_, value]) => value !== undefined)
			.map(([talentId, _]) => parseInt(talentId));

		const set = new Set([...talentCheckedIds]);
		let cnt = 0;
		for (const talent of mainData) {
			if (set.has(talent.id)) {
				cnt++;
			}
		}
		const otherPageNumberOfSelected = numberOfSelected - cnt < 0 ? 0 : numberOfSelected - cnt;

		if (isEmpty(checkersPerTabPage)) {
			const copyCheckers = { ...checkers };
			for (const talent of mainData) {
				copyCheckers[talent.id] = talent;
			}
			setCheckers(copyCheckers);

			//checkers per page
			if (typeof activeTab === 'number') {
				const checkersPerTab = checkersPerTabPage[activeTab]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}

				const selectedTalents = {};
				for (const talent of mainData) {
					selectedTalents[talent.id] = talent;
				}
				checkersPerTab[page - 1] = { ...currentPage, ...selectedTalents };

				setCheckersPerTabPage(checkersPerTabPage);

				copyCheckboxPerTabPage[activeTab][page - 1] = true;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			} else if (typeof activeTab === 'string') {
				const index = parseInt(activeTab.substring(1)) - 1;
				const finalIndex = numberOfTabs + index;
				const checkersPerTab = checkersPerTabPage[finalIndex]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}

				const selectedTalents = {};
				for (const talent of mainData) {
					selectedTalents[talent.id] = talent;
				}
				checkersPerTab[page - 1] = { ...currentPage, ...selectedTalents };
				setCheckersPerTabPage(checkersPerTabPage);

				copyCheckboxPerTabPage[finalIndex][page - 1] = true;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			}

			const totalNumberOfSelected = otherPageNumberOfSelected + mainData.length;
			setNumberOfSelected(totalNumberOfSelected);
		} else {
			const copyCheckers = { ...checkers };
			const talentIds = mainData.map((talent) => talent.id);
			const set = new Set([...talentIds]);
			for (const talentId of Object.keys(copyCheckers)) {
				if (set.has(parseInt(talentId))) {
					copyCheckers[talentId] = undefined;
				}
			}
			setCheckers(copyCheckers);

			//checkers per page
			if (typeof activeTab === 'number') {
				const checkersPerTab = checkersPerTabPage[activeTab]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}

				const selectedTalents = {};
				for (const talent of mainData) {
					selectedTalents[talent.id] = undefined;
				}
				checkersPerTab[page - 1] = { ...currentPage, ...selectedTalents };
				setCheckersPerTabPage(checkersPerTabPage);

				copyCheckboxPerTabPage[activeTab][page - 1] = false;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			} else if (typeof activeTab === 'string') {
				const index = parseInt(activeTab.substring(1)) - 1;
				const finalIndex = numberOfTabs + index;
				const checkersPerTab = checkersPerTabPage[finalIndex]; //[{},{}]
				const currentPage = checkersPerTab[page - 1]; //{[id]:each}

				const selectedTalents = {};
				for (const talent of mainData) {
					selectedTalents[talent.id] = undefined;
				}
				checkersPerTab[page - 1] = { ...currentPage, ...selectedTalents };
				setCheckersPerTabPage(checkersPerTabPage);

				copyCheckboxPerTabPage[finalIndex][page - 1] = false;
				setCheckboxPerTabPage(copyCheckboxPerTabPage);
			}

			const totalNumberOfSelected = numberOfSelected - mainData.length;
			setNumberOfSelected(totalNumberOfSelected);
		}
	};

	const handleClose = () => {
		setOpen(false); //dialog open

		setLetterSubject('');
		setLetter({
			candidateEmailMapping: [],
			htmlBody: ''
		});
		setTemplateType('');
		setTemplateId(null);

		setTemplateName('');
		setContextEmpty(true);
		setTemplateExpand(false);
		setTemplateConfirm(false);

		if (uploadFile.uploadFileName) {
			const myFile = {
				path: uploadFile.uploadFileFullName,
				name: uploadFile.uploadFileFullName,
				size: uploadFile.uploadFileSize
			};
			setMyFiles([myFile]);
			setFile({ filename: [myFile.name], path: [myFile.path] });
			sessionstorage.setItem('totalSize', myFile.size);
		} else {
			setMyFiles([]);
			setFile({
				filename: [],
				path: []
			});
			sessionstorage.setItem('totalSize', 0);
		}

		setState({
			...state,
			approach: 'false'
		});

		fileRejections.splice(0, fileRejections.length);

		//AutoConnect
		if (ref_initial) {
			ref_initial.current.handleClose();
		}

		if (ref_unread_1) {
			ref_unread_1.current.handleClose();
		}
		setUnread_1_time(initial_unread_1_time);
		setTimestamp1(initial_unread_1_timestamp);
		setError_unread_1_time(false);

		if (isExtraEmail && ref_unread_2.current) {
			ref_unread_2.current.handleClose();
			setUnread_2_time(initial_unread_2_time);
			setTimestamp2(initial_unread_2_timestamp);
			setError_unread_2_time(false);
		}

		//Update Project
		setFolderUpdate(false);
	};

	const handleCheckBox = (e) => {
		console.log('target name', e.target.name);
		console.log('target checked', e.target.checked);
		// setState({ ...state, [e.target.name]: e.target.checked });
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const toggleActionTab = (tab) => {
		if (activeActionTab !== tab) setActiveActionTab(tab);
	};

	const renderStars = (number) => {
		let container = [];

		for (let i = 0; i < number; i++) {
			container.push(<StarIcon />);
		}
		for (let i = 0; i < 3 - number; i++) {
			container.push(<StarOutlineIcon />);
		}

		return container.map((each) => each);
	};

	const handleCompanyType = (e) => {
		setCompanyFilterType(e.target.value);
		setSearch({
			...search,
			companies: { ...search.companies, type: e.target.value }
		});
		const filters = { ...search, companies: { ...search.companies, type: e.target.value } };
		setNewTabSearch({ ...newTabSearch, ...filters });
	};

	const handleSkillType = (operator) => {
		setSkillFilterType(operator);
	};

	const handleIndustryType = (e) => {
		setIndustryFilterType(e.target.value);
		setSearch({
			...search,
			industry: { ...search.industry, type: e.target.value }
		});
		const filters = { ...search, industry: { ...search.industry, type: e.target.value } };
		setNewTabSearch({ ...newTabSearch, ...filters });
	};

	/****************************************** Functionality Template Start *******************************************/

	const handleTemplate = (e) => {
		const selectedIndex = e.target.getAttribute('data-option-index');
		if (selectedIndex) {
			const selectedTemplate = templates[selectedIndex];
			handleLetterBody(selectedTemplate.htmlBody);
			setLetterSubject(selectedTemplate.subject);
			setTemplateType(selectedTemplate.type);
			setTemplateId(selectedTemplate.templateId);
			console.log('selectTemplate', selectedTemplate);
		} else {
			handleLetterBody('');
			setLetterSubject('');
			setTemplateType('');
			setTemplateId(null);
		}
		setTemplateDuplicate(false);
		setTemplateConfirm(false);
	};

	const handleNameFieldInput = (e) => {
		if (e.target.value.length > 0) {
			setTemplateName(e.target.value);
		} else {
			setTemplateName('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (letterSubject.length > 0 && filterHtmlBody.length > 0 && e.target.value.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	const handleLetterSubject = (e) => {
		if (e.target.value.length > 0) {
			setLetterSubject(e.target.value);
		} else {
			setLetterSubject('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (filterHtmlBody.length > 0 && e.target.value.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSubmit = () => {
		if (templateOptions.find((option) => option.name === templateName) !== undefined) {
			setTemplateConfirm(false);
			setTemplateDuplicate(true);
		} else {
			post(url.addEmailTemplate(), {
				subject: letterSubject,
				htmlBody: letter.htmlBody,
				name: templateName
			}).then((res) => {
				console.log('after save template ', res.data);

				templates.push(res.data);
				setTemplates(templates);

				templateOptions.push({ type: res.data.type, name: res.data.name });
				setTemplateOptions(templateOptions);

				setTemplateConfirm(true);
				setTemplateDuplicate(false);
			});
		}
	};

	/****************************************** Functionality Template End *******************************************/

	/*************************************************** AutoConnect Start *******************************************/
	const [isAutoDisabled_initial, setAutoDisabled_initial] = useState(true);
	const [isAutoDisabled_unread_1, setAutoDisabled_unread_1] = useState(true);
	const [isAutoDisabled_unread_2, setAutoDisabled_unread_2] = useState(true);
	const [isAutoDisabled, setAutoDisabled] = useState(true);

	const [autoLoading, setAutoLoading] = useState(false);
	const timer = useRef();

	const [isExtraEmail, setExtraEmail] = useState(false);
	const [isAddEmailDisabled, setAddEmailDisabled] = useState(false);
	const [isDeleteEmailDisabled, setDeleteEmailDisabled] = useState(true);
	const ref_initial = useRef();
	const ref_unread_1 = useRef();
	const ref_unread_2 = useRef();
	const initial_time = { hour: 24, min: 37 };
	const initial_timestamp = '24:37:00';

	const initial_unread_1_time = initial_time;
	const initial_unread_1_timestamp = initial_timestamp;
	const [unread_1_time, setUnread_1_time] = useState(initial_unread_1_time);
	const [timestamp1, setTimestamp1] = useState(initial_unread_1_timestamp);
	const [error_unread_1_time, setError_unread_1_time] = useState(false);

	const initial_unread_2_time = initial_time;
	const initial_unread_2_timestamp = initial_timestamp;
	const [unread_2_time, setUnread_2_time] = useState(initial_unread_2_time);
	const [timestamp2, setTimestamp2] = useState(initial_unread_2_timestamp);
	const [error_unread_2_time, setError_unread_2_time] = useState(false);

	const [strategyId, setStrategyId] = useState(1);

	const handleEmailAdd = useCallback(() => {
		console.log('handleEmailAdd.....');
		setAddEmailDisabled(true);
		setDeleteEmailDisabled(false);
		setExtraEmail(true);
	}, [isExtraEmail, isAddEmailDisabled]);

	const handleEmailDelete = useCallback(() => {
		console.log('handleEmailDelete.....');
		setAddEmailDisabled(false);
		setDeleteEmailDisabled(true);
		setExtraEmail(false);
	}, [isExtraEmail, isDeleteEmailDisabled]);

	const handleAutoConnect = async () => {
		console.log('handle auto connect....');

		if (memory.user.remainingEmailCredits - (isExtraEmail ? 3 : 2) < 0) {
			setEmailCreditOpen((prevState) => ({ ...prevState, shortageEmailCreditOpen: true }));
			return;
		}

		//Check File size
		const flag1 = ref_initial.current.validateFiles();
		const flag2 = ref_unread_1.current.validateFiles();
		let flag3 = true;
		if (isExtraEmail && ref_unread_2.current) {
			flag3 = ref_unread_2.current.validateFiles();
		}
		if (!(flag1 && flag2 && flag3)) return;

		//Check scheduler settings
		if (!checkScheduleTime(unread_1_time)) {
			setError_unread_1_time(true);
			return;
		} else {
			setError_unread_1_time(false);
		}
		if (isExtraEmail && !checkScheduleTime(unread_2_time)) {
			setError_unread_2_time(true);
			return;
		} else {
			setError_unread_2_time(false);
		}

		if (!autoLoading) {
			setAutoLoading(true);
			timer.current = window.setTimeout(() => {
				setAutoLoading(false);
			}, 5000);
		}

		//Send Email
		// include findCandidates, maxStrategyId, findCandidates have strategyId, taskId Info
		const info = await ref_initial.current.sendEmail();

		//Scheduler
		const connectActions = [];
		//connectActions.push(ref_initial.current.setSchedule());
		connectActions.push(ref_unread_1.current.setSchedule());
		if (isExtraEmail && ref_unread_2.current) {
			connectActions.push(ref_unread_2.current.setSchedule());
		}

		await post(url.handleAutoConnect(), {
			connectActions,
			candidateEmailMapping: letter.candidateEmailMapping,
			findCandidates: info.findCandidates
		})
			.then((res) => {
				console.log('after single handleAutoConnect autoConnect', res);

				memory.user.remainingEmailCredits =
					memory.user.remainingEmailCredits - (isExtraEmail ? 3 : 2);
				storage.saveUser({
					...storage.getUser(),
					credits: memory.user.credits,
					remainingEmailCredits: memory.user.remainingEmailCredits
				});
				console.log('remaining email credits', memory.user.remainingEmailCredits);
				setStrategyId(strategyId + 1);

				if (memory.user.remainingEmailCredits === 0) {
					setEmailCreditOpen((prevState) => ({ ...prevState, noEmailCreditOpen: true }));
				} else {
					const totalEmailCredits = memory.customer.totalEmailCredits;
					if (memory.user.remainingEmailCredits <= totalEmailCredits * 0.2) {
						setEmailCreditOpen((prevState) => ({ ...prevState, closeEmailCreditOpen: true }));
					}
				}
			})
			.catch((e) => {
				console.error('Send Auto Connection fail', e);
			});

		handleClose();
	};

	/*************************************************** AutoConnect End *******************************************/

	/******************************************** Multiple Selected Talent Start *************************/

	const isEmpty = (checkersPerTabPage) => {
		let obj;
		if (typeof activeTab === 'number') {
			const checkersPerTab = checkersPerTabPage[activeTab]; //[{},{}]
			obj = checkersPerTab[page - 1]; //{[id]:each}
		} else if (typeof activeTab === 'string') {
			const index = parseInt(activeTab.substring(1)) - 1;
			const finalIndex = numberOfTabs + index;
			const checkersPerTab = checkersPerTabPage[finalIndex]; //[{},{}]
			obj = checkersPerTab[page - 1]; //{[id]:each}
		}

		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		let cnt = 0;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent && talent !== undefined) {
				cnt++;
			}
		}
		flag2 = cnt < mainData.length ? true : false;

		return flag1 || flag2;
	};

	/******************************************** Multiple Selected Talent End *************************/
	function valuetext(value) {
		return `${value}°C`;
	}
	/******************************************** Set Skills *******************************************/
	const updateSkill = (filter) => {
		const SQL = filter['skill'];

		const skills = SQL.split(' ').filter((skill) => skill.length > 0); // +Java +(C/plusplus)

		let newSkillList = [];
		for (const skill of skills) {
			if (skill.includes('(') && skill.includes(')')) {
				const specialCharacters = skill.replace(regx, '').trim();
				const newSkills = specialCharacters
					.split(' ')
					.map((each) => storage.getSkillMapping(each.trim()))
					.filter((each) => !each || each === undefined);
				newSkillList.push(newSkills);
			} else {
				const specialCharacter = skill.replace(regx, '').trim();
				const newSkill = storage.getSkillMapping(specialCharacter);
				if (newSkill && newSkill === undefined) {
					newSkillList.push(newSkill);
				}
			}
		}
		setSkills(newSkillList);
	};

	const updateSkill_test = (SQL) => {
		const skills = SQL.split(' ').filter((skill) => skill.length > 0); // +Java +(C/plusplus)

		let newSkillList = [];
		for (const skill of skills) {
			if (skill.includes('(') && skill.includes(')')) {
				const specialCharacters = skill.replace(regx, '').trim();
				const newSkills = specialCharacters
					.split(' ')
					.map((each) => storage.getSkillMapping(each.trim()))
					.filter((each) => !each || each === undefined);
				newSkillList.push(newSkills);
			} else {
				const specialCharacter = skill.replace(regx, '').trim();
				const newSkill = storage.getSkillMapping(specialCharacter);
				if (newSkill && newSkill !== undefined) {
					newSkillList.push(newSkill);
				}
			}
		}

		//return newSkillList;
		setSkills(newSkillList);
	};

	/**************************************** React Life Cycle Start *************************************************************/

	// show tab searching result
	useEffect(() => {
		if (!newTab.isNewTalentPool || (newTab.isNewTalentPool && newTab.isFetching)) {
			if (typeof activeTab === 'string') {
				// 判断新老tab (N for newtab)
				let cnt = 0;
				for (const [field, values] of Object.entries(search)) {
					if (field === 'companies' || field === 'industry') {
						if (values.options.length > 0) cnt++;
					} else {
						if (values && values !== undefined && values.length > 0) cnt++;
					}
				}

				if (cnt > 0) {
					setFilterSearchDisabled(false);
				} else {
					setFilterSearchDisabled(true);
				}
			}

			//existing tab: filterId, new: N1, N2
			const key = typeof activeTab === 'number' ? activeTab : parseInt(activeTab.substring(1)) - 1;
			// after searching for the 1st time, result has value
			const result =
				typeof activeTab === 'number'
					? filterSearchResult[key]
						? filterSearchResult[key][0].flat()
						: []
					: newTabFilterSearchResult[key]
					? newTabFilterSearchResult[key][0].flat()
					: [];
			// if result has value, DO NOT search again, else do search
			if (!result || result === undefined || result.length === 0) {
				//not yet fetch data
				if (typeof activeTab === 'number') goSearchTalents(search, 1);
			}
		} else if (newTab.isNewTalentPool && !newTab.isFetching) {
			setNewTab({ ...newTab, isFetching: true });
		}
	}, [search, newTab.isFetching]);

	useEffect(() => {
		SetFolderSelection(projects);
		const project = projects.find((project) => project.id === tagId);

		if (project && project.path) {
			const path = project.path;
			const email = memory.user.email.trim();
			const idx = path.indexOf(email);

			if (idx > -1) {
				const fileFullName = path.substring(idx + email.length + 1);

				setUploadFile({
					...uploadFile,
					uploadFileName: project.filename, //xxx
					uploadFilePath: path, //email/xxx.pdf
					uploadFileSize: project.fileSize,
					uploadFileFullName: fileFullName //xxx.pdf
				});

				axios
					.post(
						url.copyJDFileToAttachment(),
						{},
						{
							...config,
							params: {
								path: path
							}
						}
					)
					.then((res) => {
						console.log(res.data.data);
						const myFile = {
							path: fileFullName,
							name: fileFullName,
							size: project.fileSize
						};
						setMyFiles([myFile]);
						setFile({ filename: [fileFullName], path: [path] });
						//fullFullName: xxx.pdf, path: email/xxx.pdf

						setAutoConnectUploadFile([myFile]);
						setAutoUploadFile({ filename: [fileFullName], path: [path] });

						sessionstorage.setItem('totalSize', myFile.size);
					});
			}
		}
	}, [projects]);

	useEffect(() => {
		loadProjects();
	}, []);

	// useEffect(() => {
	// 	trackPromise(
	// 		axios
	// 			.get(url.fetchNewTalents(), {
	// 				params: {
	// 					tagId: tagId
	// 				}
	// 			})
	// 			.then((res) => {
	// 				const result = res.data;
	// 				console.log('fetchNewTalents', result.message);

	// 				if (result.data) {
	// 					const string_newTalentIds = result.data.newTalentIds;
	// 					const string_oldTalentIds = result.data.oldTalentIds;

	// 					const newTalentIds =
	// 						string_newTalentIds && string_newTalentIds.length > 0
	// 							? string_newTalentIds.split(',').map((talentId) => parseInt(talentId.trim()))
	// 							: [];

	// 					const oldTalentIds =
	// 						string_oldTalentIds && string_oldTalentIds.length > 0
	// 							? string_oldTalentIds.split(',').map((talentId) => parseInt(talentId.trim()))
	// 							: [];

	// 					setUpdatedTalents({ newTalents: newTalentIds, oldTalents: oldTalentIds });
	// 				}

	// 				return res;
	// 			})
	// 			.catch((e) => {
	// 				console.error('fetchNewTalents error catch', e.message);
	// 			})
	// 	);
	// }, []);

	useEffect(() => {
		if (!isTemplateConfirm) {
			setTemplateNameMsg(templateName);
		}
	}, [templateName]);

	useEffect(() => {
		console.log('template loading.....');
		get(url.fetchEmailTemplate()).then((res) => {
			console.log(res.data);
			setTemplates(res.data);

			const templateOptions = res.data.map((item) => {
				const type = item.type;
				return {
					type,
					name: item.name
				};
			});
			setTemplateOptions(templateOptions);
		});
	}, []);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(unread_1_time);
		console.log('time: ', time);
		setTimestamp1(time);
	}, [unread_1_time.hour, unread_1_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(unread_2_time);
		console.log('time: ', time);
		setTimestamp2(time);
	}, [unread_2_time.hour, unread_2_time.min]);

	useEffect(() => {
		get(url.fetchMaxStrategyByUser()).then((res) => {
			console.log('Max Strategy', res.data.maxStrategyId);
			if (res.data.maxStrategyId !== null) {
				setStrategyId(res.data.maxStrategyId + 1);
			}
		});
	}, []);

	useEffect(() => {
		if (!isAutoDisabled_initial && !isAutoDisabled_unread_1) {
			if (isExtraEmail) {
				if (!isAutoDisabled_unread_2) {
					setAutoDisabled(false);
				} else {
					setAutoDisabled(true);
				}
			} else {
				setAutoDisabled(false);
			}
		} else {
			setAutoDisabled(true);
		}
	}, [isAutoDisabled_initial, isAutoDisabled_unread_1, isAutoDisabled_unread_2, isExtraEmail]);

	// Filter control
	useEffect(() => {
		// get dropdown list options
		get(url.fetchFilters()).then((res) => {
			console.log('filters data', res.data);
			setFilterSelect(res.data);
		});
		// get company c-1 and c-2 layer relationships
		get(url.fetchCompDeptMapping()).then((res) => {
			setCompDepartMapping(res.data);
		});
		// get stacks and type relationships
		get(url.fetchPositionTypeStackMapping()).then((res) => {
			setPositionTypeStackMapping(res.data);
		});
	}, []);

	// get saved projects' info. (filters, tabs, cdds. selections, read to seach cdds. for first filter tab)
	useEffect(() => {
		trackPromise(
			axios
				.get(url.fetchFiltersByProject(tagId))
				.then((res) => {
					const data = res.data.data;
					const filters = data.tagPoolsData;
					const talentsSelected = data.talentsSelected; //{filterId:{talentId_1:N, talentId_2:Y}, filterId:{}}

					setNumberOfTabs(filters.length); // affect renderTabs
					setProjectFilters(filters);
					setTalentsSelectedEachPool(talentsSelected); //ignore

					setTabs([...Array(filters.length)]); //undefined
					setFilterSearchResult([...Array(filters.length)]);
					// here search the first filter tab cdds.
					if (filters.length > 0) {
						const filterTransform = transformFilter(filters[0].filter);
						setSearch({ ...search, ...filterTransform });
						//updateSkill_test(filters[0].filter.skill); //TODO setSkills
					}

					return res;
				})
				.catch((e) => {
					console.error('fetchFiltersByProject error catch', e.message);
				})
		);
	}, []);

	useEffect(() => {
		trackPromise(
			axios
				.get(url.fetchTalentsSelectedByProject(tagId))
				.then((res) => {
					console.log(res.data.message);
					const data = res.data.data; //all talent selected eg: talentId: each
					const talentsSelected = data.allTalents; //for sorting
					const talentsPool = data.talentsPool; //category by filter eg: filterId:[talentId1,talentId2], used to save project

					setTalentsPool(talentsPool);
					setCheckers({ ...checkers, ...talentsSelected });
					setAllTalentIds(Object.keys(talentsSelected).map((talentId) => parseInt(talentId)));
					setIsAllTalentsFetch(true);

					const tabCount = talentsPool ? Object.keys(talentsPool).length : 0;
					const arrayInit = [...Array(tabCount)];
					arrayInit.fill([]);
					setCheckersPerTabPage(arrayInit);

					const checkboxInit = [...Array(tabCount)];
					checkboxInit.fill([]);
					setCheckboxPerTabPage(checkboxInit);

					return res;
				})
				.catch((e) => {
					console.error('fetchTalentsSelectedByProject error catch', e.message);
				})
		);
	}, []);

	useEffect(() => {
		const talentCheckedIds = Object.entries(checkers)
			.filter(([_, value]) => value !== undefined)
			.map(([talentId, _]) => parseInt(talentId));
		const set = new Set([...talentCheckedIds]);

		if (projectFilters.length > 0 && typeof activeTab === 'number') {
			const filterId = projectFilters[activeTab].filterId;
			if (tabs.includes(filterId)) {
				const index = activeTab;
				const info = filterSearchResult[index];
				const totalCount = info[1],
					pageCount = info[2];
				let result = info[0].flat();
				result = result && result.length > 0 ? result : [];
				result = sortingResult(result);

				let cnt = 0;
				for (const talent of result) {
					if (set.has(talent.id)) {
						cnt++;
					}
				}

				setNumberOfSelected(cnt);
				setTotalCount(totalCount);
				setPageCount(pageCount);
				setPage(1);

				setSearch({ ...search, ...projectFilters[activeTab].filter });

				initializeChecboxAndCheckers(result, checkersPerTabPage, checkboxPerTabPage);
			} else {
				setSearch({ ...search, ...projectFilters[activeTab].filter });
			}

			setNewTab({ ...newTab, filterDisabled: true }); //activeTab = new, why projectFilters len > 0

			// set skills react hook
			updateSkill_test(projectFilters[activeTab].filter.skill);
		} else if (typeof activeTab === 'string' && activeTab.substring(0, 1) === 'N') {
			console.log('CK 1');
			if (newTabs.includes(activeTab)) {
				console.log('CK 2');
				const index = parseInt(activeTab.substring(1)) - 1;
				const info = newTabFilterSearchResult[index];
				const totalCount = info[1],
					pageCount = info[2];
				let result = info[0].flat();
				result = result && result.length > 0 ? result : [];
				result = sortingResult(result);

				let cnt = 0;
				for (const talent of result) {
					if (set.has(talent.id)) {
						cnt++;
					}
				}

				setNumberOfSelected(cnt);
				setTotalCount(totalCount);
				setPageCount(pageCount);
				setPage(1);

				setSearch({ ...search, ...newTabFilters[index] });
				updateSkill_test(newTabFilters[index].skill);

				initializeChecboxAndCheckers(result, checkersPerTabPage, checkboxPerTabPage);
			} else {
				console.log('CK 3');
				if (!isDeptTab) {
					console.log('CK 4');
					const filter = {};
					for (const key of Object.keys(search)) {
						if (key === 'companies' || key === 'industry') {
							filter[key] = { type: 'OR', options: [] };
						} else if (key === 'skill') {
							filter[key] = '';
						} else if (key === 'yearsOfWorkingExperiences') {
							filter[key] = [0, 100];
						} else {
							filter[key] = [];
						}
					}
					setSearch({ ...search, ...filter });
				}
				console.log('CK 5');

				setResultData([]);
				setMainData([]);
				setPage(1);
				setTotalCount(0);
				setPageCount(0);
				setNumberOfSelected(0);
				// setSkills([]);
			}
			console.log('CK 6');
			setNewTab({ ...newTab, filterDisabled: false });
		}
	}, [activeTab]);

	const FileUploader = () => {
		const downloadPDF = async () => {
			await axios
				.get(url.fetchJDFile(), {
					params: {
						path: uploadFile.uploadFilePath
					}
				})
				.then((res) => {
					console.log(res.data.data);
					const url = res.data.data;
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.download = uploadFile.uploadFilePath;

					document.body.appendChild(link);
					// // Dispatch click event on the link
					// // This is necessary as link.click() does not work on the latest firefox
					link.dispatchEvent(
						new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						})
					);

					// // Remove link from body
					document.body.removeChild(link);
				});
		};

		return (
			<div className="card-header--title d-flex flex-row justify-content-start align-items-center">
				{uploadFile.uploadFileName && (
					<>
						<div className="pr-1">
							<TITLEIMG src={require('../../../../assets/images/apps/attachment.png')} />
						</div>
						<div className="pr-1 nav-link" onClick={downloadPDF}>
							<b>{uploadFile.uploadFileName}</b>
						</div>
					</>
				)}
			</div>
		);
	};
	/**************************************** React Life Cycle End *************************************************************/

	/**************************************** Render Records & Render Talent Tabs Start ****************************************/
	const renderRows = (data) => {
		return data ? (
			data.map((each) => {
				const {
					id,
					name,
					email,
					linkedin,
					position,
					companies,
					yearsOfWorkingExperiences,
					location,
					stacks,
					isNew,
					institutions,
					department_2,
					openToOpportunities,
					area,
					seniority
				} = each;

				return (
					<>
						<tr key={id} tabIndex={id}>
							<td>
								{updatedTalents.newTalents.includes(id) ? <FiberNewIcon color={'error'} /> : ''}
							</td>
							<td className="text-center">
								<Checkbox
									checked={checkers[id] !== undefined}
									onChange={(e) => handleCheckboxAction(e, id, each)}
								/>
							</td>
							<td style={{ width: '10%' }}>
								<div className="d-flex align-items-center">
									<div>
										<a
											onClick={(e) => handleClickAction(e, each, '4')}
											className="talent font-weight-bold text-black">
											{name}
										</a>
										<Tooltip title={position ? position : ''} arrow placement="top">
											<span className="text-black-50 d-block">
												{position && position.length > 17
													? position.slice(0, 17) + '...'
													: position}
											</span>
										</Tooltip>
									</div>
								</div>
							</td>
							<td style={{ width: '12%' }} className="text-left text-break-wrap">
								{location.includes('United States')
									? location.split(',').length > 2
										? location.split(',')[0].concat(',', location.split(',')[1])
										: location
									: location}
							</td>
							<td style={{ width: '8%' }} className="text-left text-break-wrap">
								{stacks}
							</td>
							<td className="text-center">{yearsOfWorkingExperiences}</td>
							<td className="text-left pl-3">
								<div className="d-flex flex-column justify-content-center align-items-start">
									{[
										...new Set(
											companies.filter((comp) => comp.isCurrent === 1).map((item) => item.company)
										)
									].map((company) => (
										<div>{company}</div>
									))}
								</div>
							</td>
							<td className="text-left pl-3">
								<a className="department" onClick={() => openDepartTab(each)}>
									{department_2 &&
										department_2.displayname.substring(
											department_2.displayname.indexOf(']', 1) + 2
										)}
								</a>
							</td>
							<td className="text-left pr-3 pl-3">
								{[
									...new Set(
										institutions.filter((inst) => inst.isHighest === 1).map((item) => item.degree)
									)
								]}
							</td>
							<td className="text-left text-break-wrap">
								{[
									...new Set(
										institutions
											.filter((inst) => inst.isHighest === 1)
											.map((item) => {
												return item.specialty + ' at ' + item.institution;
											})
									)
								].join(';')}
							</td>
							<td style={{ width: '100px' }} className="text-left">
								{renderStars(openToOpportunities)}
							</td>
							<td className="text-center">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<img
										className="img-20"
										src={require(`../../../../assets/images/apps/${
											email ? 'email_verified' : 'email_unverified'
										}.png`)}
										onClick={(e) => handleClickAction(e, each, '4')}
									/>
									<a style={{ cursor: 'pointer' }} href={`${linkedin}`} target="_blank">
										<img
											className="img-20"
											src={require('../../../../assets/images/apps/linkedin.png')}
										/>
									</a>
								</div>
								{/* <div>
									<Button
										size="small"
										onClick={(e) => handleClickAction(e, each, '4')}
										className="btn-neutral-first d-30 btn-pill p-0 btn-icon">
										<FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
									</Button>
								</div> */}
							</td>
						</tr>
					</>
				);
			})
		) : (
			<></>
		);
	};

	const renderTabs = (loopNumber) => {
		return [...Array(loopNumber)].map((el, idx) => {
			return (
				<>
					<div className="d-flex align-items-center align-content-start">
						<ListItem
							button
							disableRipple
							selected={activeTab === idx}
							onClick={() => {
								toggle(idx);
							}}>
							<span className={'tp'}>TP {idx + 1}</span>
							<div className="divider" />
						</ListItem>
						<IconButton
							onClick={() => setTabDelete({ ...tabDelete, isDeleteOpen: true, tabIndex: idx })}
							size="small"
							edge="start"
							aria-label="delete"
							className={'tp'}>
							X
						</IconButton>
					</div>
				</>
			);
		});
	};

	const renderNewTabs = (loopNumber) => {
		return [...Array(loopNumber)].map((el, idx) => {
			return (
				<>
					<div className="d-flex align-items-center align-content-start">
						<ListItem
							button
							disableRipple
							selected={activeTab === 'N'.concat(idx + 1)}
							onClick={() => {
								toggle('N'.concat(idx + 1));
							}}>
							<span className="mr-4">TP {numberOfTabs + idx + 1}</span>
							<div className="divider" />
						</ListItem>
						<IconButton
							onClick={() =>
								setTabDelete({ ...tabDelete, isDeleteOpen: true, tabIndex: 'N'.concat(idx + 1) })
							}
							size="small"
							edge="start"
							aria-label="delete">
							X
						</IconButton>
					</div>
				</>
			);
		});
	};
	/**************************************** Render Records & Render Talent Tabs End ****************************************/

	/***********************************************************************************************************************
	 * 												SEND EMAIL            														                                       *
	 * *********************************************************************************************************************/

	const [letterSubject, setLetterSubject] = useState('');
	const [creditOpen, setCreditOpen] = useState(false);
	const [emailCreditOpen, setEmailCreditOpen] = useState({
		noEmailCreditOpen: false,
		closeEmailCreditOpen: false,
		shortageEmailCreditOpen: false
	});
	const [letter, setLetter] = useState({
		candidateEmailMapping: [],
		htmlBody: ''
	});

	const handleLetterBody = (html) => {
		const filterHtmlBody = removeHtmlTag(html);

		if (filterHtmlBody.length > 0) {
			setLetter((prevState) => ({ ...prevState, htmlBody: html }));
		} else {
			setLetter((prevState) => ({ ...prevState, htmlBody: '' }));
		}

		if (letterSubject.length > 0 && filterHtmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSendEmail = async () => {
		if (
			// Feature 360
			state.approach !== 'false' &&
			memory.user.credits - letter.candidateEmailMapping.length < 0
		) {
			setCreditOpen(true);
			return;
		}

		if (memory.user.remainingEmailCredits === 0) {
			setEmailCreditOpen((prevState) => ({ ...prevState, noEmailCreditOpen: true }));
			return;
		}

		if (!validateFiles()) {
			return;
		}

		await axios
			.post(
				url.sendEmail(),
				{
					subject: letterSubject,
					...letter,
					approach: state.approach === 'true',
					filename: file.filename,
					itemname: file.path,
					strategyId,
					templateId,
					opportunity: true,
					tagId
				},
				config
			)
			.then((res) => {
				if (state.approach !== 'false') {
					memory.user.credits = memory.user.credits - letter.candidateEmailMapping.length;
				}

				memory.user.remainingEmailCredits =
					memory.user.remainingEmailCredits - letter.candidateEmailMapping.length;
				storage.saveUser({
					...storage.getUser(),
					credits: memory.user.credits,
					remainingEmailCredits: memory.user.remainingEmailCredits
				});
				console.log('after sendEmail', res.data);
				console.log('remaining email credits', memory.user.remainingEmailCredits);
				setStrategyId(strategyId + 1);

				if (memory.user.remainingEmailCredits === 0) {
					setEmailCreditOpen((prevState) => ({ ...prevState, noEmailCreditOpen: true }));
				} else {
					const totalEmailCredits = memory.customer.totalEmailCredits;
					if (memory.user.remainingEmailCredits <= totalEmailCredits * 0.2) {
						setEmailCreditOpen((prevState) => ({ ...prevState, closeEmailCreditOpen: true }));
					}
				}
			})
			.catch((e) => console.error('Send email has error', e));

		handleClose();
	};

	//TODO simplfied the change of state
	useEffect(() => {
		if (letterSubject.length > 0 && letter.htmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	}, [letterSubject, letter.htmlBody]);

	// handle keyword input
	const handleKwInputChange = (event) => {
		if (event.key === 'Enter') {
			let localSkill = [];
			localSkill = [event.target.value];
			console.log('localSkill:', localSkill);

			let booleanSkills = fromBooleanSkills(localSkill);
			setBooleanSkills(localSkill);

			console.log('booleanSkills:', booleanSkills);
			setSkills(booleanSkills);
			console.log(skills);
			let interpretedSkills = skillInterpretation(booleanSkills);
			let skillSQL = skillGenerateSQL(interpretedSkills);
			handleAdvancedSearch(event, skillSQL, 'skill');

			setIsKwEditing(false);
		}
	};

	return (
		<>
			<Card className="card-box mb-spacing-6-x2">
				<div className="card-header pr-2">
					<div className="card-header--title d-flex justify-content-start align-items-center">
						<span className="pr-2">
							<b>{projectName}</b>
						</span>
						<FileUploader />
					</div>

					<div className="card-header--actions mx-2">
						<NavLink activeClassName="active" className="nav-link-simple" to={`/recruit`}>
							<Button
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue">
								<span className="btn-wrapper--icon">
									<SearchIcon />
								</span>
								<span className="btn-wrapper--label">Search Talents</span>
							</Button>
						</NavLink>
					</div>
					<div className="card-header--actions">
						<NavLink
							activeClassName="active"
							// className="nav-link-simple"
							className=" nav-link-simple"
							to={`/savedprojects`}>
							<Button
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue active">
								<span className="btn-wrapper--icon">
									<FolderIcon />
								</span>
								<span className="btn-wrapper--label">View Projects</span>
							</Button>
						</NavLink>
					</div>
				</div>
				<div className="card-header pr-2 w-100">
					<Card className="shadow-xxl px-4 py-5 w-100">
						<Grid container spacing={5}>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Locations</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Area}
										getOptionLabel={(option) => option.displayname}
										value={search.location}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'location')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Talent current geographic locations"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Seniority</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Seniority}
										getOptionLabel={(option) => option.displayname.substring(4)}
										value={search.seniority}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'seniority')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Title levels and team management responsibility"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Achievement</b>
										<Box
											onClick={() => {
												if (!premium) setPremiumOpen(true);
											}}
											className={classes.premium}
											bgcolor={!premium ? 'text.secondary' : 'primary.main'}
											color="background.paper"
											py={0}
											px={2}>
											Premium
										</Box>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled || !premium}
										multiple
										size="small"
										options={filterSelects.Achievement}
										getOptionLabel={(option) => option.displayname}
										value={search.achievement}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'achievement')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Awards, patents or publication authors"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Position Types</b>
									<Autocomplete
										disabled={newTab.filterDisabled || !premium}
										multiple
										size="small"
										options={filterSelects.PositionType}
										getOptionLabel={(option) => option.displayname}
										value={search.positionType}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'positionType')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Business divisions of the position"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Years of Experience</b>
									<Slider
										className="mt-4"
										disabled={newTab.filterDisabled}
										getAriaLabel={() => 'Years of Working Experience'}
										value={search.yearsOfWorkingExperiences}
										onChange={(e, value) =>
											handleAdvancedSearch(e, value, 'yearsOfWorkingExperiences')
										}
										valueLabelDisplay="on"
										color="secondary"
										getAriaValueText={valuetext}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Entrepreneurship</b>
										<Box
											onClick={() => {
												if (!premium) setPremiumOpen(true);
											}}
											className={classes.premium}
											bgcolor={!premium ? 'text.secondary' : 'primary.main'}
											color="background.paper"
											py={0}
											px={2}>
											Premium
										</Box>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled || !premium}
										multiple
										size="small"
										options={filterSelects.Entrepreneurship.filter((each) => each.key !== 'EM')}
										getOptionLabel={(option) => option.displayname}
										value={search.entrepreneurship}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'entrepreneurship')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Talents who have cofounded or worked at startups"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Stacks</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										filterSelectedOptions
										getOptionSelected={(option, value) => option.key === value.key}
										options={filterSelects.Stack}
										getOptionLabel={(option) => option.displayname}
										value={search.stacks}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'stacks')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Telent technical divisions"
												fullWidth
											/>
										)}
										renderTags={(tagValue, getTagProps) => {
											return tagValue.map((option, index) => (
												<div key={index}>
													<Chip
														style={{ backgroundColor: option.exclusive ? '#dc004e' : '#0795fe' }}
														classes={{
															root: classes.chipRoot
														}}
														label={option.displayname}
														size="small"
														{...getTagProps({ index })}
														icon={
															<NotInterestedRoundedIcon
																onClick={() => handleExclusive('stacks', option)}
																style={{ color: grey[50] }}
															/>
														}
													/>
												</div>
											));
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Current Tenure</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.CurrentTenure}
										getOptionLabel={(option) => option.displayname}
										value={search.currTenure}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'currTenure')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Months at current company"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Average Tenure</b>
										<Box
											onClick={() => {
												if (!premium) setPremiumOpen(true);
											}}
											className={classes.premium}
											bgcolor={!premium ? 'text.secondary' : 'primary.main'}
											color="background.paper"
											py={0}
											px={2}>
											Premium
										</Box>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled || !premium}
										multiple
										size="small"
										options={filterSelects.AvgTenure}
										getOptionLabel={(option) => option.displayname}
										value={search.avgTenure}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'avgTenure')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="How often do talents switch companies"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Industries</b>
										<RadioGroup
											aria-label="gender"
											name="gender"
											value={industryFilterType}
											onChange={handleIndustryType}
											row>
											<FormControlLabel
												value="PAST"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="PAST"
											/>
											<FormControlLabel
												value="OR"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="OR"
											/>
											<FormControlLabel
												value="PRESENT"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="PRESENT"
											/>
										</RadioGroup>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Industry}
										getOptionLabel={(option) => option.displayname}
										value={search.industry.options}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'industry')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Talent current or past Industries"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Highest Degree</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Degree}
										getOptionLabel={(option) => option.displayname}
										value={search.degree}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'degree')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="The highest degree of talents"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Preferred Locations</b>
										<Box
											onClick={() => {
												if (!premium) setPremiumOpen(true);
											}}
											className={classes.premium}
											bgcolor={!premium ? 'text.secondary' : 'primary.main'}
											color="background.paper"
											py={0}
											px={2}>
											Premium
										</Box>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled || !premium}
										multiple
										size="small"
										options={filterSelects.PreferredLocation}
										getOptionLabel={(option) => option.displayname}
										value={search.preferredLocation}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'preferredLocation')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="The locations talents prefer to work at"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Companies</b>
										<RadioGroup
											aria-label="gender"
											name="gender"
											value={search.companies.type}
											onChange={handleCompanyType}
											row>
											<FormControlLabel
												value="PAST"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="PAST"
											/>
											<FormControlLabel
												value="OR"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="OR"
											/>
											<FormControlLabel
												value="PRESENT"
												control={
													<Radio className={classes.radioGroup} disabled={newTab.filterDisabled} />
												}
												label="PRESENT"
											/>
										</RadioGroup>
									</div>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										filterSelectedOptions
										size="small"
										options={filterSelects.Company}
										getOptionLabel={(option) => option.displayname}
										getOptionSelected={(option, value) => option.key === value.key}
										value={search.companies.options}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'companies')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Talent current or past companies"
												fullWidth
											/>
										)}
										renderTags={(tagValue, getTagProps) => {
											return tagValue.map((option, index) => (
												<div key={index}>
													<Chip
														style={{ backgroundColor: option.exclusive ? '#dc004e' : '#0795fe' }}
														classes={{
															root: classes.chipRoot
														}}
														label={option.displayname}
														size="small"
														{...getTagProps({ index })}
														icon={
															<NotInterestedRoundedIcon
																onClick={() => handleExclusive('companies', option)}
																style={{ color: grey[50] }}
															/>
														}
													/>
												</div>
											));
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Schools</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Institute}
										getOptionLabel={(option) => option.institute}
										value={search.institute}
										onInputChange={(e, value) => handleFullTextSearch(e, value, 'institute')}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'institute')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Schools attended"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>O2O Index</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Attitude}
										getOptionLabel={(option) => option.displayname}
										value={search.openToOpportunities}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'openToOpportunities')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Talents who are open to opportunities"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>C-2 Teams</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Department}
										getOptionLabel={(option) => option.displayname}
										value={search.department_2}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'department_2')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="C-2 Team in current company"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<div className="d-flex justify-content-between">
										<b>Keywords</b>
										<div>
											<IoMdAddCircle
												className={
													skillFilterType === 'AND'
														? classes.addSkillOperatorOn
														: classes.addSkillOperatorOff
												}
												onClick={(e) => {
													if (!newTab.filterDisabled) {
														handleSkillType('AND');
													}
												}}
											/>
											<BsSlashCircleFill
												className={
													skillFilterType === 'OR'
														? classes.orSkillOperatorOn
														: classes.orSkillOperatorOff
												}
												onClick={(e) => {
													if (!newTab.filterDisabled) {
														handleSkillType('OR');
													}
												}}
											/>
											<IoMdCreate
												className={
													isKwEditing ? classes.editSkillOperatorOn : classes.editSkillOperatorOff
												}
												onClick={(e) => {
													if (!newTab.filterDisabled) {
														handleEditButtonClick(e);
													}
												}}
											/>
										</div>
									</div>
									<div>
										{isKwEditing && (
											<TextField
												fullWidth
												id="outlined-basic"
												label="Enter a Boolean"
												variant="outlined"
												defaultValue={booleanSkills}
												onKeyDown={handleKwInputChange}
											/>
										)}
									</div>
									{/* {(newTab.isDupFilter || newTab.filterDisabled || newTabs.includes(activeTab)) && ( */}
									{(newTab.filterDisabled || newTabs.includes(activeTab)) && (
										<Autocomplete
											disabled={newTab.filterDisabled}
											freeSolo
											multiple
											size="small"
											options={filterSelects.Skill}
											getOptionLabel={(option) => option.skill}
											value={search.skill}
											filterSelectedOptions
											getOptionDisabled={(option) => true}
											onChange={(e, value, reason, details) => {
												console.log('value: ', value, 'reason: ', reason, 'details: ', details);

												let localSkill = [];
												//add
												if (reason === 'create-option') {
													localSkill = addSkill(skills, details['option'], skillFilterType);
													setSkills(localSkill);
												}
												//delete
												if (reason === 'remove-option') {
													localSkill = removeSkill(skills, details['option']);
													setSkills(localSkill);
												}
												if (reason === 'clear') {
													localSkill = [];
													setSkills(localSkill);
												}
												let interpretedSkills = skillInterpretation(localSkill);
												let skillSQL = skillGenerateSQL(interpretedSkills);
												handleAdvancedSearch(e, skillSQL, 'skill');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Profile keywords or boolean"
													fullWidth
												/>
											)}
											renderTags={(tagValue, getTagProps) => {
												console.log(
													'line 3632 old: ',
													'skills tags: ',
													skills,
													'tagValue: ',
													tagValue
												);
												let interpretedSkills = reverseSkillGenerateSQL(tagValue);
												// let updatedBooleanSkills;

												// updatedBooleanSkills = toBooleanSkills(interpretedSkills);
												// setBooleanSkills(updatedBooleanSkills);

												return interpretedSkills.map((option, index) => (
													<div key={index}>
														{option[1] === '+' ? (
															<Chip
																label={option[0]}
																size="small"
																{...getTagProps({ index })}
																style={{ backgroundColor: '#8674F4' }}
															/>
														) : (
															<Chip
																label={option[0]}
																size="small"
																{...getTagProps({ index })}
																style={{ backgroundColor: '#3E96F6' }}
															/>
														)}

														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '+' && <h7>+</h7>}
														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '*/' && <h7>+</h7>}
														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '/' && <h7>/</h7>}
													</div>
												));
											}}
										/>
									)}
									{!newTab.filterDisabled && !newTabs.includes(activeTab) && (
										<Autocomplete
											freeSolo
											multiple
											size="small"
											options={filterSelects.Skill}
											getOptionLabel={(option) => option.skill}
											value={search.skill}
											filterSelectedOptions
											getOptionDisabled={(option) => true}
											onChange={(e, value, reason, details) => {
												console.log('value: ', value, 'reason: ', reason, 'details: ', details);

												let localSkill = [];
												//add
												if (reason === 'create-option') {
													localSkill = addSkill(skills, details['option'], skillFilterType);
													setSkills(localSkill);
												}
												//delete
												if (reason === 'remove-option') {
													localSkill = removeSkill(skills, details['option']);
													setSkills(localSkill);
												}
												if (reason === 'clear') {
													localSkill = [];
													setSkills(localSkill);
												}
												console.log('correct localSkills', localSkill);
												let interpretedSkills = skillInterpretation(localSkill);
												let updatedBooleanSkills;

												updatedBooleanSkills = toBooleanSkills(interpretedSkills);
												setBooleanSkills(updatedBooleanSkills);
												let skillSQL = skillGenerateSQL(interpretedSkills);
												handleAdvancedSearch(e, skillSQL, 'skill');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Profile keywords or boolean"
													fullWidth
												/>
											)}
											renderTags={(tagValue, getTagProps) => {
												console.log('!skills tags: ', skills, '!tagValue: ', tagValue);
												let interpretedSkills;
												if (newTab.isDupFilter) {
													interpretedSkills = reverseSkillGenerateSQL(tagValue);
													console.log(interpretedSkills);
													let updatedBooleanSkills;

													updatedBooleanSkills = toBooleanSkills(interpretedSkills);
													setBooleanSkills(updatedBooleanSkills);
													let localSkill = [];
													localSkill = reverseSkillInterpretation(interpretedSkills);
													console.log(localSkill);
													setSkills(localSkill);
													setNewTab({ ...newTab, filterDisabled: false, isDupFilter: false });
												} else {
													interpretedSkills = skillInterpretation(skills);
												}

												// let updatedBooleanSkills;

												// updatedBooleanSkills = toBooleanSkills(interpretedSkills);
												// setBooleanSkills(updatedBooleanSkills);
												return interpretedSkills.map((option, index) => {
													return (
														<div key={index}>
															{option[1] === '+' ? (
																<Chip
																	label={option[0]}
																	size="small"
																	{...getTagProps({ index })}
																	style={{ backgroundColor: '#8674F4' }}
																/>
															) : (
																<Chip
																	label={option[0]}
																	size="small"
																	{...getTagProps({ index })}
																	style={{ backgroundColor: '#3E96F6' }}
																/>
															)}

															{index != Object.keys(interpretedSkills).length - 1 &&
																option[1] === '+' && <span>+</span>}
															{index != Object.keys(interpretedSkills).length - 1 &&
																option[1] === '*/' && <span>+</span>}
															{index != Object.keys(interpretedSkills).length - 1 &&
																option[1] === '/' && <span>/</span>}
														</div>
													);
												});
											}}
										/>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<Grid item xs={12}>
									<b>Languages</b>
									<Autocomplete
										disabled={newTab.filterDisabled}
										multiple
										size="small"
										options={filterSelects.Language}
										getOptionLabel={(option) => option.displayname}
										value={search.language}
										onChange={(e, value) => handleAdvancedSearch(e, value, 'language')}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Languages of talents"
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<Grid item xs={12}>
									{typeof activeTab === 'string' && !isFilterSearchDisabled && (
										<img
											className={'img-search'}
											src={require('../../../../assets/images/apps/Search_Button_Active.png')}
											onClick={handleSearch}
										/>
									)}
									{typeof activeTab === 'string' && isFilterSearchDisabled && (
										<img
											className={'img-search'}
											src={require('../../../../assets/images/apps/Search_Button.png')}
										/>
									)}
									{typeof activeTab === 'number' && (
										<img
											className={'img-search'}
											src={require('../../../../assets/images/apps/Search_Button.png')}
										/>
									)}
								</Grid>
							</Grid>
							{/* <Grid item xs={12} md={4} lg={4}>
								<Grid
									item
									xs={12}
									style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
									<Button className={classes.containedPrimary} onClick={handleDuplicateFilter}>
										Dup. Current Filters
									</Button>
								</Grid>
							</Grid> */}
						</Grid>
					</Card>
				</div>

				<Card className="shadow-xxl p-3">
					<div className={classes.root}>
						<Grid container spacing={1}>
							<Grid item xs={2}>
								<Button
									disabled={false}
									className={classes.containedPrimary}
									size="small"
									fullWidth
									onClick={() => createNewTab()}>
									Create a new Talent Pool
								</Button>
							</Grid>
							<Grid
								container
								item
								xs={2}
								alignItems="center"
								justifyContent="flex-start"
								className="py-1">
								<div>
									{totalCount ? totalCount : 0} Total, {numberOfSelected} Selected
								</div>
							</Grid>
							{/* <Grid item xs >
								<div
									className={clsx('search-wrapper search-wrapper--grow text-right pr-2', {
										'is-active': false
									})}>
									<TextField
										className="project-edit-search"
										variant="outlined"
										margin="dense"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchTwoToneIcon style={{ color: '#0795fe' }} />
												</InputAdornment>
											),
											type: 'search'
										}}
									/>
								</div>
							</Grid> */}
							<Grid item xs />
							<Grid
								item
								xs={3}
								style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
								<Button className={classes.containedPrimary} onClick={handleDuplicateFilter}>
									Dup. Current Filters
								</Button>
							</Grid>
							<Grid
								item
								xs={3}
								style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
								<Button className={classes.containedPrimary} onClick={handleDuplicateFilterAsNew}>
									Dup. Current Filters As New Search
								</Button>
							</Grid>
							<Grid
								item
								xs={1.5}
								style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
								<Button
									className={classes.containedPrimary}
									// size="small"
									// fullWidth
									onClick={() => setSave((prevState) => ({ ...prevState, isSaveOpen: true }))}>
									Save & Back to View Projects
								</Button>
							</Grid>
						</Grid>
					</div>
					<List component="div" className="nav-line d-flex nav-tabs-primary">
						{renderTabs(numberOfTabs)}
						{newTab.isNewTalentPool && renderNewTabs(numberOfNewTabs === 0 ? 1 : numberOfNewTabs)}
						{/* {newTab.isNewTalentPool && (
							<div className="d-flex align-items-center align-content-start">
								<ListItem
									button
									disableRipple
									selected={activeTab === 'NEW'}
									onClick={() => {
										toggle('NEW');
									}}>
									<span className="mr-4">TP {numberOfTabs + 1}</span>
									<div className="divider" />
								</ListItem>
								<IconButton
									onClick={() => {
										if (newTab.filterId) {
											setTabDelete({ ...tabDelete, isDeleteOpen: true });
										} else {
											setTabDelete({ ...tabDelete, isDeleteOpen: true, tabIndex: 'NEW' });
										}
									}}
									size="small"
									edge="start"
									aria-label="delete">
									X
								</IconButton>
							</div>
						)} */}
						{/* <ListItem
							button
							disableRipple
							selected={activeTab === 'REC'}
							onClick={() => {
								toggle('REC');
							}}>
							<span className="mr-4">Recommendation</span>
							<div className="divider" />
						</ListItem> */}
					</List>

					<div
						className={clsx('tab-item-wrapper', {
							active:
								[...Array(numberOfTabs).keys()].includes(activeTab) ||
								(typeof activeTab === 'string' &&
									[...Array(numberOfNewTabs).keys()]
										.map((i) => i + 1)
										.includes(parseInt(activeTab.substring(1)))) ||
								activeTab === 'REC'
						})}
						index={0}>
						<div className="my-2">
							<CardContent className="p-0">
								<div className="table-responsive-sm">
									<Table
										size={'small'}
										className="text-wrap mb-0 table-hover table-borderless font-size-sm">
										<thead className="thead">
											<tr className={'text-left'}>
												<th />
												<th className="text-center">
													<Checkbox
														checked={
															typeof activeTab === 'number'
																? checkboxPerTabPage[activeTab]
																	? checkboxPerTabPage[activeTab][page - 1] === true
																	: false
																: checkboxPerTabPage[
																		numberOfTabs + parseInt(activeTab.substring(1)) - 1
																  ]
																? checkboxPerTabPage[
																		numberOfTabs + parseInt(activeTab.substring(1)) - 1
																  ][page - 1] === true
																: false
														}
														onChange={handleCheckAllAction}
														disabled={!mainData || mainData.length === 0}
													/>
												</th>
												<th>Candidates</th>
												<th>Location</th>
												<th>Tech Stacks</th>
												<th className="text-center">Experience(Yrs)</th>
												<th className="pl-3">Company</th>
												<th className="pl-3">
													<span>C-2 Teams</span>
													<Tooltip
														title={
															<span>
																Create a new talent pool with talents in the same department,
																location and seniority
															</span>
														}
														arrow
														placement="top">
														<HelpOutlineIcon fontSize={'small'} />
													</Tooltip>
												</th>
												<th className="pl-3 pr-3">Degree</th>
												<th>Schools &amp; Specialty</th>
												<th style={{ width: '100px' }}>
													<span>O2O Index</span>
													<Tooltip
														title={
															<>
																<div>★★★: Actively look for new opportunities.</div>
																<div>
																	★★: Considering new opportunities but not actively looking for.
																</div>
																<div>★: Probably open to new opportunities. </div>
																<div>☆: Probably not open to new opportunities. </div>
															</>
														}
														arrow
														placement="top">
														<HelpOutlineIcon fontSize={'small'} />
													</Tooltip>
												</th>
												<th className="text-center">Connect</th>
											</tr>
										</thead>
										{promiseInProgress ? <></> : <tbody>{renderRows(mainData)}</tbody>}
									</Table>
									{promiseInProgress && <LoadingIndicator />}
								</div>
								<div className="divider" />
								<div className="divider" />
								<div className="p-3 d-flex justify-content-center">
									<Pagination
										page={page}
										onChange={handleChangePage}
										className="pagination-first"
										size="small"
										count={pageCount}
									/>
								</div>
							</CardContent>
						</div>
					</div>
				</Card>
			</Card>

			{selectedRow && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					maxWidth={'md'}
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						<Grid container spacing={1}>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">{selectedRow.name}</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.position)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">Curr/Ex Company</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.company)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-1 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_1}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-2 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_2}
									</span>
								</Grid>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent className={'p-4'}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									<List component="div" className="nav-line d-flex nav-tabs-primary">
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '4'}
											onClick={() => {
												toggleActionTab('4');
											}}>
											<span>Information</span>
										</ListItem>
										{selectedRow.email && (
											<ListItem
												button
												disableRipple
												selected={activeActionTab === '0'}
												onClick={() => {
													toggleActionTab('0');
												}}>
												<span>Connect</span>
												<div className="divider" />
											</ListItem>
										)}
										{selectedRow.email && (
											<ListItem
												button
												disableRipple
												selected={activeActionTab === '3'}
												onClick={() => {
													toggleActionTab('3');
												}}>
												<span>AutoConnect</span>
											</ListItem>
										)}
										{selectedRow.email && (
											<ListItem
												button
												disableRipple
												selected={activeActionTab === '1'}
												onClick={() => {
													toggleActionTab('1');
												}}>
												<span>History</span>
											</ListItem>
										)}
									</List>
									<hr />
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '0' })}
										index={0}>
										<DialogContentText>
											<Autocomplete
												id="combo-box-demo"
												size="small"
												options={templateOptions}
												groupBy={(option) => option.type}
												getOptionLabel={(option) => option.name}
												onChange={handleTemplate}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														placeholder="Search for a template"
														fullWidth
													/>
												)}
											/>
										</DialogContentText>
										<DialogContentText>
											<TextField
												onChange={handleLetterSubject}
												value={letterSubject}
												variant="outlined"
												fullWidth
												name={'subject'}
												id="standard-textarea"
												label="Subject"
												placeholder="[Company] [Opportunity] [Date]"
											/>

											<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
											<ReactQuill
												className={'quillContainer'}
												theme="snow"
												value={letter.htmlBody}
												onChange={handleLetterBody}
												placeholder="Write about your opportunity..."
											/>
										</DialogContentText>
										<DialogContentText className={'d-flex justify-content-between'}>
											{templateType === 'Base' ? (
												<div className="font-size-xs text-dark">
													* If you use this template, please change the text in all square brackets
													before sending
												</div>
											) : (
												<div className="font-size-xs text-dark"></div>
											)}
											<Link
												className={classes.button}
												component="button"
												underline="none"
												onClick={() => {
													setTemplateExpand((v) => !v);
												}}>
												Save as new template
											</Link>
										</DialogContentText>
										{isTemplateExpand && (
											<>
												<hr />
												<DialogContentText className="mb-0 textField-btn">
													<div style={{ flexGrow: 1 }}>
														<Grid container spacing={8}>
															<Grid item xs={8}>
																<TextField
																	className={'templatename-field'}
																	fullWidth
																	variant="outlined"
																	placeholder="Name of the template"
																	onChange={handleNameFieldInput}
																	value={templateName}
																/>
															</Grid>
															<Grid item xs>
																{!isContextEmpty ? (
																	<Button
																		className={classes.containedPrimary}
																		size="small"
																		variant="contained"
																		color="primary"
																		onClick={handleSubmit}>
																		Save
																	</Button>
																) : (
																	<Button size="small" variant="outlined" disabled>
																		Save
																	</Button>
																)}
																<Button
																	style={{ left: '10px' }}
																	size="small"
																	variant="outlined"
																	onClick={() => {
																		setTemplateExpand(false);
																		setTemplateName('');
																		setTemplateConfirm(false);
																		setTemplateDuplicate(false);
																	}}>
																	Cancel
																</Button>
															</Grid>
														</Grid>
													</div>
												</DialogContentText>
												{isTemplateConfirm && (
													<div className="font-size-sm text-success mt-3">
														Save template <strong>{templateNameMsg}</strong> successfully
													</div>
												)}
												{isTemplateDuplicate && (
													<div className="font-size-sm text-danger mt-3">
														There was an error saving your template. Please try another name.
													</div>
												)}
											</>
										)}
										<hr />
										<Grid container direction={'row'}>
											<Grid item md={12}>
												<div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
													<input {...getInputProps()} />
													<div className="dropzone">
														{isDragAccept && (
															<div>
																<IMG
																	src={require('../../../../assets/images/apps/attachment.png')}
																/>
																<div className="font-size-sm text-success">
																	All files will be uploaded!
																</div>
															</div>
														)}
														{isDragReject && (
															<div>
																<IMG
																	src={require('../../../../assets/images/apps/attachment.png')}
																/>
																<div className="font-size-sm text-danger">
																	Some files will be rejected!
																</div>
															</div>
														)}
														{!isDragActive && (
															<IMG src={require('../../../../assets/images/apps/attachment.png')} />
														)}
														<div style={{ width: '2px' }} />
														<div className="font-weight-bold text-uppercase text-dark font-size-sm">
															Uploaded Files
														</div>
														<div style={{ width: '2px' }} />
														<div className="font-size-xs text-dark">
															* A file name cannot contain commas
														</div>
													</div>
												</div>
											</Grid>
											<Grid item md={12}>
												<div>
													{fileRejectionItems}
													{isLoading && files.length == 0 ? (
														<div className={classes.spinner}>
															<CircularProgress />
														</div>
													) : (
														<List component="div" className="font-size-sm">
															{files}
														</List>
													)}
												</div>
											</Grid>
										</Grid>
										<hr />
										<div className={'font-size-sm mt-4'}>
											<div>{memory.user.name}</div>
											<div>
												{memory.user.position} | {memory.user.customername}
											</div>
											<div>{memory.user.email}</div>
											<div>{memory.user.phone}</div>
											<div>{memory.user.location}</div>
											<a href={`${memory.user.linkedin}`}>LinkedIn</a>
										</div>
										<hr />
										<DialogContentText className="mb-0">
											<FormControl component="fieldset">
												<RadioGroup
													aria-label="approach"
													name="approach"
													value={state.approach}
													onChange={handleChange}>
													<FormControlLabel
														value={'false'}
														control={<Radio />}
														label="Verified email "
													/>
													<FormControlLabel
														value={'true'}
														control={<Radio />}
														label="360&#176; Connect"
													/>
												</RadioGroup>
											</FormControl>
										</DialogContentText>

										{state.approach === 'true' && (
											<DialogContentText className="mb-0">
												<hr />
												<Alert className="alerts-alternate mb-4" severity="#046eb8">
													<div className="d-flex align-items-center align-content-start">
														<span>
															Our recruiters will reach out to candidates via all approaches (Email,
															Phone, LinkedIn and social medias) and notify you about their status.
														</span>
													</div>
													<div>Credits Left: {memory.user.credits}</div>
												</Alert>
												<FormGroup row>
													<FormControlLabel
														control={<Checkbox checked onChange={handleCheckBox} name="email" />}
														label="Email"
													/>
													<FormControlLabel
														control={<Checkbox checked onChange={handleCheckBox} name="inmail" />}
														label="InMail"
													/>
													<FormControlLabel
														disabled={!selectedRow.twitter}
														control={<Checkbox checked onChange={handleCheckBox} name="twitter" />}
														label="Twitter"
													/>
													<FormControlLabel
														disabled={!selectedRow.github}
														control={<Checkbox checked onChange={handleCheckBox} name="github" />}
														label="Github"
													/>
													<FormControlLabel
														disabled={selectedRow.phone === '0'}
														control={<Checkbox checked onChange={handleCheckBox} name="text" />}
														label="Text"
													/>
													<FormControlLabel
														disabled={selectedRow.phone === '0'}
														control={<Checkbox checked onChange={handleCheckBox} name="call" />}
														label="Call"
													/>
													<FormControlLabel
														disabled={!selectedRow.facebook}
														control={<Checkbox checked name="facebook" />}
														label="Facebook"
													/>
													<FormControlLabel
														disabled={!selectedRow.instagram}
														control={
															<Checkbox checked onChange={handleCheckBox} name="instagram" />
														}
														label="Instagram"
													/>
												</FormGroup>
											</DialogContentText>
										)}
									</div>

									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '3' })}
										index={0}>
										<AutoConnectTemplate
											templateOptions={templateOptions}
											templates={templates}
											classes={classes}
											emailType="Email_Initial"
											ref={ref_initial}
											candidateEmailMapping={letter.candidateEmailMapping}
											strategyId={strategyId}
											sequenceId={1}
											handleSendDisabled={setAutoDisabled_initial}
											tagId={tagId}
											uploadFiles={autoConnectUploadFile}
											uploadFileTags={autoUploadFile}
										/>
										<hr />
										<b>Unread Actions</b>
										<p>
											Unread in
											<input
												style={{ width: '30px' }}
												value={unread_1_time.hour}
												onChange={(e) => {
													e.persist();
													setUnread_1_time((prevState) => ({ ...prevState, hour: e.target.value }));
												}}
											/>
											hours{' '}
											<input
												style={{ width: '30px' }}
												value={unread_1_time.min}
												onChange={(e) => {
													e.persist();
													setUnread_1_time((prevState) => ({ ...prevState, min: e.target.value }));
												}}
											/>
											minutes, then send the next email
										</p>
										{error_unread_1_time && (
											<>
												<div className={'d-flex'}>
													<div className="font-size-sm text-danger font-italic">X Error:&nbsp;</div>
													<div className="font-size-sm font-italic">
														Can not be blank and can not be less than 24
													</div>
												</div>
												<div className={'d-flex mb-2'}>
													<div className="font-size-sm text-danger font-italic">X Error:&nbsp;</div>
													<div className="font-size-sm font-italic">
														Can not be blank and can not be a number outside 00 - 59
													</div>
												</div>
											</>
										)}
										<AutoConnectTemplate
											templateOptions={templateOptions}
											templates={templates}
											classes={classes}
											emailType="Email_Unread_1"
											ref={ref_unread_1}
											candidateEmailMapping={letter.candidateEmailMapping}
											strategyId={strategyId}
											sequenceId={2}
											interval={timestamp1}
											scheduler={unread_1_time}
											handleSendDisabled={setAutoDisabled_unread_1}
											tagId={tagId}
											uploadFiles={autoConnectUploadFile}
											uploadFileTags={autoUploadFile}
										/>
										<hr />
										<div className={'d-flex flex-column'}>
											<Link
												className={classes.emailButton}
												component="button"
												underline="none"
												disabled={isAddEmailDisabled}
												onClick={handleEmailAdd}>
												+ additional emails
											</Link>
											<Link
												className={classes.emailButton}
												component="button"
												underline="none"
												disabled={isDeleteEmailDisabled}
												onClick={handleEmailDelete}>
												- additional emails
											</Link>
										</div>
										{isExtraEmail && (
											<>
												<p>
													Unread in
													<input
														style={{ width: '30px' }}
														value={unread_2_time.hour}
														onChange={(e) => {
															e.persist();
															setUnread_2_time((prevState) => ({
																...prevState,
																hour: e.target.value
															}));
														}}
													/>
													hours{' '}
													<input
														style={{ width: '30px' }}
														value={unread_2_time.min}
														onChange={(e) => {
															e.persist();
															setUnread_2_time((prevState) => ({
																...prevState,
																min: e.target.value
															}));
														}}
													/>
													minutes, then send the next email
												</p>
												{error_unread_2_time && (
													<>
														<div className={'d-flex'}>
															<div className="font-size-sm text-danger font-italic">
																X Error:&nbsp;
															</div>
															<div className="font-size-sm font-italic">
																Can not be blank and can not be less than 24
															</div>
														</div>
														<div className={'d-flex mb-2'}>
															<div className="font-size-sm text-danger font-italic">
																X Error:&nbsp;
															</div>
															<div className="font-size-sm font-italic">
																Can not be blank and can not be a number outside 00 - 59
															</div>
														</div>
													</>
												)}
												<AutoConnectTemplate
													templateOptions={templateOptions}
													templates={templates}
													classes={classes}
													emailType="Email_Unread_2"
													ref={ref_unread_2}
													candidateEmailMapping={letter.candidateEmailMapping}
													strategyId={strategyId}
													sequenceId={3}
													interval={timestamp2}
													scheduler={unread_2_time}
													handleSendDisabled={setAutoDisabled_unread_2}
													tagId={tagId}
													uploadFiles={autoConnectUploadFile}
													uploadFileTags={autoUploadFile}
												/>
											</>
										)}
									</div>
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '1' })}
										index={1}>
										{selectedRow && selectedRow.history.length === 0 && (
											<div className="text-center my-5">
												<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
													<FontAwesomeIcon
														icon={['far', 'user']}
														className="d-flex align-self-center font-size-xxl"
													/>
												</div>
												<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
													History
												</h6>
												<p className="text-black-50 font-size-lg mb-0">
													You have not offered a job opportunity to this candidate.
												</p>
											</div>
										)}
										{selectedRow &&
											selectedRow.history.length > 0 &&
											selectedRow.history.map((each, i) => (
												<Alert className="alerts-alternate mb-4" severity="#046eb8">
													<div key={i} className="d-flex align-items-center align-content-start">
														<span>
															<strong className="d-block">
																{transformDateToLocalTZ(each.sent_time)}
															</strong>
															{each.bounce === 0 && each.open === 1 && (
																<p>
																	The candidate has checked your email already.
																	<DoneAllIcon fontSize={'small'} color={'primary'} />
																</p>
															)}
															{each.bounce === 0 && each.open === 0 && each.sent === 1 && (
																<p>
																	This email has been sent to the candidate successfully.
																	<DoneIcon fontSize={'small'} color={'primary'} />
																</p>
															)}
															{each.bounce === 0 && each.open === 0 && each.sent === 0 && (
																<p>
																	This email will be sent to the candidate.
																	<TIMERIMG
																		src={require('../../../../assets/images/apps/timer.png')}
																	/>
																</p>
															)}
															{each.bounce === 1 && each.open === 0 && each.sent === 1 && (
																<p>
																	This email will be sent to the candidate.
																	<TIMERIMG
																		src={require('../../../../assets/images/apps/timer.png')}
																	/>
																</p>
															)}
														</span>
													</div>
												</Alert>
											))}
										{/* {selectedRow && activeTab === 2 && selectedRow.talentStatus.length === 0 && (
											<div className="text-center my-5">
												<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
													<FontAwesomeIcon
														icon={['far', 'user']}
														className="d-flex align-self-center font-size-xxl"
													/>
												</div>
												<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
													History
												</h6>
												<p className="text-black-50 font-size-lg mb-0">
													The application is in progress
												</p>
											</div>
										)}
										{selectedRow &&
											activeTab === 2 &&
											selectedRow.talentStatus.length > 0 &&
											selectedRow.talentStatus.reverse().map((each, i) => {
												return each.status === '1' ? (
													<div className="text-center my-5">
														<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
															<FontAwesomeIcon
																icon={['far', 'user']}
																className="d-flex align-self-center font-size-xxl"
															/>
														</div>
														<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
															History
														</h6>
														<p className="text-black-50 font-size-lg mb-0">
															Candidate has not responded yet.
														</p>
													</div>
												) : (
													<Alert className="alerts-alternate mb-4" severity="#046eb8">
														<div key={i} className="d-flex align-items-center align-content-start">
															<span>
																<strong className="d-block">
																	{moment(each.createdAt).format('dddd, MMMM Do YYYY, h:mm a')}
																</strong>
																{each.status === '3' && (
																	<p>
																		This candidate is <b>interested</b> in the opportunity.
																	</p>
																)}
																{each.status === '2' && (
																	<p>
																		This candidate is <b>not interested</b> in the opportunity.
																	</p>
																)}
															</span>
														</div>
													</Alert>
												);
											})} */}
									</div>
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '4' })}
										index={2}>
										<div className="mt-4 mb-5 p-3 bg-secondary rounded-sm">
											<span className="font-weight-bold text-black font-size-lg">Summary</span>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Location:</span>
													<span>{selectedRow.location}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Tech Stack:</span>
													<span>{selectedRow.stacks}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Seniority:</span>
													<span>{selectedRow.seniority.substring(4)}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Years of Experience:</span>
													<span>{selectedRow.yearsOfWorkingExperiences}</span>
													<span>&nbsp;yrs</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<span className="pr-1">
													O2O Index
													<Tooltip
														title={
															<>
																<div>★★★: Actively look for new opportunities.</div>
																<div>
																	★★: Considering new opportunities but not actively looking for.
																</div>
																<div>★: Probably open to new opportunities. </div>
																<div>☆: Probably not open to new opportunities. </div>
															</>
														}
														arrow
														placement="top">
														<HelpOutlineIcon fontSize={'small'} />
													</Tooltip>
												</span>
												<span>{renderStars(selectedRow.openToOpportunities)}</span>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Contact Info:</span>
													<span>
														<a target="_blank" href={`${selectedRow.linkedin}`}>
															LinkedIn, &nbsp;
														</a>
														<RouteLink
															target="_blank"
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Email, &nbsp;
														</RouteLink>
														<RouteLink
															target="_blank"
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Phone
														</RouteLink>
													</span>
												</div>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Experience</span>
											{selectedRow.companies.length > 0 &&
												selectedRow.companies.map((comp) => {
													const duration = comp.period ? ', ' + comp.period + 'yrs' : '';
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.position} at {comp.company}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.startDate && comp.endDate && (
																		<>
																			{comp.startDate + ' - ' + comp.endDate} {duration}{' '}
																		</>
																	)}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Education</span>
											{selectedRow.institutions.length > 0 &&
												selectedRow.institutions.map((ins) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ins.degree}'s Degree {ins.specialty && ' in ' + ins.specialty} at{' '}
																	{ins.institution}
																</span>
															</div>
															{ins.startYear && ins.endYear && (
																<div className="text-black font-weight-light font-size-sm">
																	<span>
																		{ins.startYear}-{ins.endYear}
																	</span>
																</div>
															)}
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Skills</span>
											<div className={classes.chips}>
												{selectedRow.skills.length > 0 &&
													selectedRow.skills.map((skill) => {
														return <span className={classes.chip}>{skill}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Language(s)</span>
											<div className={classes.chips}>
												{selectedRow.languages.length > 0 &&
													selectedRow.languages.map((lang) => {
														return <span className={classes.chip}>{lang.language}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Achievement</span>
											<div className={classes.chips}>
												<Tooltip
													title={
														selectedRow.honors.length > 0 &&
														selectedRow.honors.map((honor) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{honor.title}</div>
																	<div>Issuer:{honor.issuer}</div>
																	<div>IssueDate:{honor.issueDate}</div>
																	<div>
																		Description:
																		{honor.description && honor.description.length > 150
																			? honor.description.slice(0, 150) + '...'
																			: honor.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.honors.length} Honors</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.patents.length > 0 &&
														selectedRow.patents.map((patent) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{patent.title}</div>
																	<div>Issuer:{patent.patentNo}</div>
																	<div>IssueDate:{patent.issueDate}</div>
																	<div>
																		Description:
																		{patent.description && patent.description.length > 150
																			? patent.description.slice(0, 150) + '...'
																			: patent.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.patents.length} Patents</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.publications.length > 0 &&
														selectedRow.publications.map((publication) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{publication.title}</div>
																	<div>Publisher:{publication.publisher}</div>
																	<div>PublicDate:{publication.publicationDate}</div>
																	<div>
																		Description:
																		{publication.description && publication.description.length > 150
																			? publication.description.slice(0, 150) + '...'
																			: publication.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.publications.length} Publications</Button>
												</Tooltip>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">
												Entrepreneurship
											</span>
											{selectedRow.entrepreneurship.length > 0 &&
												selectedRow.entrepreneurship.map((ent) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.role} at {ent.company}{' '}
																	{ent.round && <>({ent.round.split('-')[1].trim()})</>}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.startDate}-{ent.endDate}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Projects</span>
											{folderSelection && (
												<div className="d-flex justify-content-between">
													<FormControl className={classes.formControl}>
														<Autocomplete
															multiple
															size="small"
															options={folderSelection.filter(
																(folder) =>
																	!(
																		new Set([...selectedRow.tags.map((tag) => tag.id)]).has(
																			folder.id
																		) && folder.id === tagId
																	)
															)}
															getOptionLabel={(option) => option.name}
															onChange={handleFolderChange}
															value={folders}
															getOptionSelected={(option, value) => option.id === value.id}
															renderInput={(params) => (
																<TextField {...params} variant="standard" label="Projects" />
															)}
														/>
													</FormControl>
												</div>
											)}
										</div>
									</div>
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="p-4">
						{activeActionTab === '0' && (
							<>
								<Button onClick={handleClose} color="primary" variant="outlined">
									Cancel
								</Button>
								<Button
									disabled={isOppSendDisabled}
									onClick={handleSendEmail}
									className={classes.containedPrimary}>
									Send
								</Button>
							</>
						)}
						{activeActionTab === '3' && (
							<>
								<Button onClick={handleClose} color="primary" variant="outlined">
									Cancel
								</Button>
								{!isAutoDisabled ? (
									<Button
										onClick={handleAutoConnect}
										className={classes.containedPrimary}
										disabled={autoLoading}>
										{autoLoading && (
											<CircularProgress size={24} className={classes.buttonProgress} />
										)}
										Send
									</Button>
								) : (
									<Button variant="contained" disabled>
										Send
									</Button>
								)}
							</>
						)}
						{activeActionTab === '4' &&
							(isFolderUpdate ? (
								<>
									<Button onClick={handleClose} color="primary" variant="outlined">
										Cancel
									</Button>
									<Button onClick={handleUpdateProjects} className={classes.containedPrimary}>
										{autoLoading && (
											<CircularProgress size={24} className={classes.buttonProgress} />
										)}
										Update Projects
									</Button>
								</>
							) : !checkers[selectedRow.id] || checkers[selectedRow.id] === undefined ? (
								<>
									<Button onClick={handleClose} color="primary" variant="outlined">
										Cancel
									</Button>
									<Button
										onClick={() => {
											setCheckers((prevState) => ({ ...prevState, [selectedRow.id]: selectedRow }));
											setNumberOfSelected(numberOfSelected + 1);
										}}
										className={classes.containedPrimary}>
										Select
									</Button>
								</>
							) : (
								<>
									<Button onClick={handleClose} color="primary" variant="outlined">
										Cancel
									</Button>
									<Button
										onClick={() => {
											setCheckers((prevState) => ({ ...prevState, [selectedRow.id]: undefined }));
											setNumberOfSelected(numberOfSelected - 1);
										}}
										className={classes.containedPrimary}>
										Deselect
									</Button>
								</>
							))}
					</DialogActions>
				</Dialog>
			)}

			<Dialog
				open={creditOpen}
				onClose={() => setCreditOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You do not have enough credits, please contact OCBang Representative to refill credits.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCreditOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={save.isSaveOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setSave((prevState) => ({ ...prevState, isSaveOpen: false }))}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to save selected talent(s)?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setSave((prevState) => ({ ...prevState, isSaveOpen: false }))}
						color="primary"
						variant="outlined">
						Cancel
					</Button>
					<Button
						onClick={handleSaveTalents}
						className={classes.containedPrimary}
						color="primary"
						variant="contained">
						{save.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
						Save and continue
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Please choose at least one filter for search
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setSearchOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isOverTabsOpen}
				onClose={() => setOverTabsOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						10 talent pools are the maximum. If you need to add more, please contact our account
						manager.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOverTabsOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={tabDelete.isDeleteOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setTabDelete({ ...tabDelete, isDeleteOpen: false })}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete this tab? It is going to be removed.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setTabDelete({ ...tabDelete, isDeleteOpen: false })}
						color="primary"
						variant="outlined">
						Cancel
					</Button>
					<Button
						disabled={tabDelete.loading}
						className={classes.containedPrimary}
						onClick={handleDeleteTab}
						color="primary"
						variant="contained">
						{tabDelete.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						Sure
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isPremiumOpen}
				onClose={() => setPremiumOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="form-dialog-title">Upgrade to Premium</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<li>5 Premium Filters for Talent Sourcing</li>
						<li>360 Sourcing Partner (8 Credits per month)</li>
						<li>7x24 Account Manager Support</li>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.containedPrimary}
						variant="contained"
						color="primary"
						href="https://www.ocinsights.ai/asp-products/premium"
						target="_blank">
						Yes
					</Button>
					<Button
						className={classes.containedPrimary}
						variant="contained"
						color="primary"
						href="https://www.ocinsights.ai/request-a-demo"
						target="_blank">
						Request a Demo
					</Button>
					<Button color="primary" variant="outlined" onClick={() => setPremiumOpen(false)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={snackOpen}
				autoHideDuration={3000}
				onClose={() => {
					setSnackOpen(false);
				}}>
				<Alert
					onClose={() => {
						setSnackOpen(false);
					}}
					severity="error">
					There was an error saving your template. Please try another name.
				</Alert>
			</Snackbar>

			<Portal>
				<Snackbar
					className="Snackbar-center"
					open={
						emailCreditOpen.noEmailCreditOpen ||
						emailCreditOpen.closeEmailCreditOpen ||
						emailCreditOpen.shortageEmailCreditOpen
					}
					onClose={() =>
						setEmailCreditOpen({
							noEmailCreditOpen: false,
							closeEmailCreditOpen: false,
							shortageEmailCreditOpen: false
						})
					}>
					<Alert
						onClose={() =>
							setEmailCreditOpen({
								noEmailCreditOpen: false,
								closeEmailCreditOpen: false,
								shortageEmailCreditOpen: false
							})
						}
						severity="warning">
						{emailCreditOpen.noEmailCreditOpen && (
							<span>
								All your email sending quote is used out. If you need more daily quota, please
								contact our account manager to upgrade your service.
							</span>
						)}
						{emailCreditOpen.closeEmailCreditOpen && (
							<span>
								The email sending limit is close. You have only {memory.user.remainingEmailCredits}{' '}
								email(s) quota left for today. Please pay attention to your email usage.
							</span>
						)}
						{emailCreditOpen.shortageEmailCreditOpen && (
							<span>
								You have only {memory.user.remainingEmailCredits} email(s) quota left for today.
								Please adjust or remove the talents you are going to connect with in this email.
							</span>
						)}
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state, props) => {
	const project = decodeURIComponent(props.match.params.projectName);
	const lastIndex = project.lastIndexOf('_');
	const projectName = project.substring(0, lastIndex);
	const tagId = project.substring(lastIndex + 1);
	const projectList = getProjectsList(state);
	console.log('pages savedProjects mapStateToProps projectList...', projectList);
	return {
		projects: getProjectsList(state),
		tagId: parseInt(tagId),
		projectName: projectName
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		recruitActions: bindActionCreators(recruitActions, dispatch),
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTalentsInProject);
