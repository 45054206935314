import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { BsSlashCircleFill } from 'react-icons/bs';
import { IoMdAddCircle } from 'react-icons/io';
import {
	addSkill,
	removeSkill,
	skillGenerateSQL,
	skillInterpretation
} from '../../../utils/recruitUtil';

type RecruitFilterType = {
	filterSelects: any;
	search: any;
	handleAdvancedSearch: (event: any, value: any, name: string) => void;
	premium: boolean;
	updatePremiumOpen: React.Dispatch<React.SetStateAction<boolean>>;
	industryFilterType: string;
	handleIndustryType: (prop: any) => void;
	companyFilterType: string;
	handleCompanyType: (prop: any) => void;
	handleFullTextSearch: (event: any, value: string, filter: string) => void;
	skillFilterType: string;
	handleSkillType: (prop: any) => void;
	skills: any[];
	updateSkills: React.Dispatch<React.SetStateAction<any[]>>;
	isFilterSearchDisabled: boolean;
	handleSearch: () => void;
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	uploadButtonProgress: {
		color: '#0795fe'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	achievement: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap'
	},
	premium: {
		backgroundColor: '#0795fe',
		borderRadius: '0.29rem !important'
	},
	radioGroup: {
		padding: '0.8px'
	},
	addSkillOperatorOn: {
		fontSize: '1.7em',
		color: '#3E96F6'
	},
	addSkillOperatorOff: {
		fontSize: '1.7em',
		color: '#D9D9D9'
	},
	orSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	orSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	}
}));

export default function RecruitFilter(props: RecruitFilterType) {
	const {
		filterSelects,
		search,
		handleAdvancedSearch,
		premium,
		updatePremiumOpen,
		industryFilterType,
		handleIndustryType,
		companyFilterType,
		handleCompanyType,
		handleFullTextSearch,
		skillFilterType,
		handleSkillType,
		skills,
		updateSkills,
		isFilterSearchDisabled,
		handleSearch
	} = props;

	const classes = useStyles();

	return (
		<div className="card-header w-100">
			<Card className="shadow-xxl px-4 py-5 w-100">
				<Grid container spacing={5}>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Locations</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Area}
								getOptionLabel={(option) => option.displayname}
								value={search.location}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'location')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talent current geographic locations"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>O2O Index</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Attitude}
								getOptionLabel={(option) => option.displayname}
								value={search.openToOpportunities}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'openToOpportunities')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talents who are open to opportunities"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Achievement</b>
								<Box
									onClick={() => {
										if (!premium) updatePremiumOpen(true);
									}}
									className={classes.premium}
									bgcolor={!premium ? 'text.secondary' : 'primary.main'}
									color="background.paper"
									py={0}
									px={2}>
									Premium
								</Box>
							</div>
							<Autocomplete
								disabled={!premium}
								multiple
								size="small"
								options={filterSelects.Achievement}
								getOptionLabel={(option) => option.displayname}
								value={search.achievement}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'achievement')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Awards, patents or publication authors"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Tech Stacks</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Stack}
								getOptionLabel={(option) => option.displayname}
								value={search.stacks}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'stacks')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talent technical divisions"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Years of Experience</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.YOE}
								getOptionLabel={(option) => option.displayname}
								value={search.yearsOfWorkingExperiences}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'yearsOfWorkingExperiences')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Years of working experience"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Entrepreneurship</b>
								<Box
									onClick={() => {
										if (!premium) updatePremiumOpen(true);
									}}
									className={classes.premium}
									bgcolor={!premium ? 'text.secondary' : 'primary.main'}
									color="background.paper"
									py={0}
									px={2}>
									Premium
								</Box>
							</div>
							<Autocomplete
								disabled={!premium}
								multiple
								size="small"
								options={filterSelects.Entrepreneurship.filter((each: any) => each.key !== 'EM')}
								getOptionLabel={(option) => option.displayname}
								value={search.entrepreneurship}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'entrepreneurship')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talents who have cofounded or worked at startups"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Seniority</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Seniority}
								getOptionLabel={(option) => option.displayname.substring(4)}
								value={search.seniority}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'seniority')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Title levels and team management responsibility"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Current Tenure</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.CurrentTenure}
								getOptionLabel={(option) => option.displayname}
								value={search.currTenure}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'currTenure')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Months at current company"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Average Tenure</b>
								<Box
									onClick={() => {
										if (!premium) updatePremiumOpen(true);
									}}
									className={classes.premium}
									bgcolor={!premium ? 'text.secondary' : 'primary.main'}
									color="background.paper"
									py={0}
									px={2}>
									Premium
								</Box>
							</div>
							<Autocomplete
								disabled={!premium}
								multiple
								size="small"
								options={filterSelects.AvgTenure}
								getOptionLabel={(option) => option.displayname}
								value={search.avgTenure}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'avgTenure')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="How often do talents switch companies"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Industries</b>
								<RadioGroup
									aria-label="gender"
									name="gender"
									value={industryFilterType}
									onChange={handleIndustryType}
									row>
									<FormControlLabel
										value="PAST"
										control={<Radio className={classes.radioGroup} />}
										label="PAST"
									/>
									<FormControlLabel
										value="OR"
										control={<Radio className={classes.radioGroup} />}
										label="OR"
									/>
									<FormControlLabel
										value="PRESENT"
										control={<Radio className={classes.radioGroup} />}
										label="PRESENT"
									/>
								</RadioGroup>
							</div>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Industry}
								getOptionLabel={(option) => option.displayname}
								value={search.industry.options}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'industry')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talent current or past Industries"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Highest Degree</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Degree}
								getOptionLabel={(option) => option.displayname}
								value={search.degree}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'degree')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="The highest degree of talents"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Preferred Locations</b>
								<Box
									onClick={() => {
										if (!premium) updatePremiumOpen(true);
									}}
									className={classes.premium}
									bgcolor={!premium ? 'text.secondary' : 'primary.main'}
									color="background.paper"
									py={0}
									px={2}>
									Premium
								</Box>
							</div>
							<Autocomplete
								disabled={!premium}
								multiple
								size="small"
								options={filterSelects.PreferredLocation}
								getOptionLabel={(option) => option.displayname}
								value={search.preferredLocation}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'preferredLocation')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="The locations talents prefer to work at"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Companies</b>
								<RadioGroup
									aria-label="gender"
									name="gender"
									value={companyFilterType}
									onChange={handleCompanyType}
									row>
									<FormControlLabel
										value="PAST"
										control={<Radio className={classes.radioGroup} />}
										label="PAST"
									/>
									<FormControlLabel
										value="OR"
										control={<Radio className={classes.radioGroup} />}
										label="OR"
									/>
									<FormControlLabel
										value="PRESENT"
										control={<Radio className={classes.radioGroup} />}
										label="PRESENT"
									/>
								</RadioGroup>
							</div>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Company}
								getOptionLabel={(option) => option.displayname}
								value={search.companies.options}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'companies')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Talent current or past companies"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Schools</b>
							<Autocomplete
								freeSolo
								multiple
								size="small"
								options={filterSelects.Institute}
								getOptionLabel={(option) => option.institute}
								value={search.institute}
								onInputChange={(e, value) => handleFullTextSearch(e, value, 'institute')}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'institute')}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Schools attended" fullWidth />
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Diversity</b>
								<Box
									onClick={() => {
										if (!premium) updatePremiumOpen(true);
									}}
									className={classes.premium}
									bgcolor={!premium ? 'text.secondary' : 'primary.main'}
									color="background.paper"
									py={0}
									px={2}>
									Premium
								</Box>
							</div>
							<Autocomplete
								disabled={!premium}
								multiple
								size="small"
								options={filterSelects.Diversity}
								getOptionLabel={(option) => option.displayname}
								value={search.diversity}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'diversity')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Diversity and inclusion hiring"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>C-2 Teams</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Department}
								getOptionLabel={(option) => option.displayname}
								value={search.department_2}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'department_2')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="C-2 Team in current company"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<div className="d-flex justify-content-between">
								<b>Skills</b>
								<div>
									<>
										<IoMdAddCircle
											className={
												skillFilterType === 'AND'
													? classes.addSkillOperatorOn
													: classes.addSkillOperatorOff
											}
											onClick={(e) => {
												handleSkillType('AND');
											}}
										/>
										<BsSlashCircleFill
											className={
												skillFilterType === 'OR'
													? classes.orSkillOperatorOn
													: classes.orSkillOperatorOff
											}
											onClick={(e) => {
												handleSkillType('OR');
											}}
										/>
									</>
								</div>
							</div>
							<Autocomplete
								freeSolo
								multiple
								size="small"
								options={filterSelects.Skill}
								getOptionLabel={(option) => option.skill}
								value={search.skill.options}
								filterSelectedOptions
								onInputChange={(e, value) => handleFullTextSearch(e, value, 'skill')}
								onChange={(e, value, reason, details) => {
									let localSkill = [] as any[];
									//add
									if (reason === 'select-option') {
										if (typeof details !== 'undefined') {
											localSkill = addSkill(skills, details['option']['skill'], skillFilterType);
											updateSkills(localSkill);
										}
									}
									//delete
									if (reason === 'remove-option') {
										if (typeof details !== 'undefined') {
											localSkill = removeSkill(skills, details['option']['skill']);
											updateSkills(localSkill);
										}
									}
									if (reason === 'clear') {
										if (typeof details !== 'undefined') {
											localSkill = [] as any[];
											updateSkills(localSkill);
										}
									}
									let interpretedSkills = skillInterpretation(localSkill);
									let skillSQL = skillGenerateSQL(interpretedSkills);
									handleAdvancedSearch(e, skillSQL, 'skill');

									console.log('local', localSkill);
									console.log('interperted', interpretedSkills);
									console.log('SQL', skillSQL);
								}}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Mandatory skills" fullWidth />
								)}
								renderTags={(tagValue, getTagProps) => {
									let interpretedSkills = skillInterpretation(skills);
									return interpretedSkills.map((option, index) => {
										return (
											<>
												{option[1] === '+' ? (
													<Chip
														label={option[0]}
														size="small"
														{...getTagProps({ index })}
														style={{ backgroundColor: '#8674F4' }}
													/>
												) : (
													<Chip
														label={option[0]}
														size="small"
														{...getTagProps({ index })}
														style={{ backgroundColor: '#3E96F6' }}
													/>
												)}

												{index != Object.keys(interpretedSkills).length - 1 &&
													option[1] === '+' && <h6>+</h6>}
												{index != Object.keys(interpretedSkills).length - 1 &&
													option[1] === '*/' && <h6>+</h6>}
												{index != Object.keys(interpretedSkills).length - 1 &&
													option[1] === '/' && <h6>/</h6>}
											</>
										);
									});
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							<b>Languages</b>
							<Autocomplete
								multiple
								size="small"
								options={filterSelects.Language}
								getOptionLabel={(option) => option.displayname}
								value={search.language}
								onChange={(e, value) => handleAdvancedSearch(e, value, 'language')}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label="Languages of talents"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Grid item xs={12}>
							{isFilterSearchDisabled ? (
								<img
									className={'img-search'}
									src={require('../../../assets/images/apps/Search_Button.png')}
								/>
							) : (
								<img
									className={'img-search'}
									src={require('../../../assets/images/apps/Search_Button_Active.png')}
									onClick={handleSearch}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</div>
	);
}
