import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import memory from '../../../utils/memory';
import { usageTraceLabels } from '../../../utils/message';
import { config, updateUsageActivity } from '../../../utils/recruitUtil';
import url from '../../../utils/url';

type RecruitHeaderType = {
	updateSearch: React.Dispatch<React.SetStateAction<any>>;
	updateFilterSearchDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	search: any;
	compDepMapping: any;
	updateFilterSelect: React.Dispatch<React.SetStateAction<boolean>>;
	filterSelects: any;
	updateUploadFile: React.Dispatch<React.SetStateAction<any>>;
	uploadFile: any;
	updateAdvancedSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
	advancedSearchOpen: boolean;
};

const TITLEIMG = styled.img`
	width: 30px;
	height: auto;
`;

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	uploadButtonProgress: {
		color: '#0795fe'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	achievement: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap'
	},
	premium: {
		backgroundColor: '#0795fe',
		borderRadius: '0.29rem !important'
	},
	radioGroup: {
		padding: '0.8px'
	},
	addSkillOperatorOn: {
		fontSize: '1.7em',
		color: '#3E96F6'
	},
	addSkillOperatorOff: {
		fontSize: '1.7em',
		color: '#D9D9D9'
	},
	orSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	orSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	}
}));

export default function RecruitHeader(props: RecruitHeaderType) {
	const {
		updateSearch,
		updateFilterSearchDisabled,
		search,
		compDepMapping,
		updateFilterSelect,
		filterSelects,
		updateUploadFile,
		uploadFile,
		updateAdvancedSearchOpen,
		advancedSearchOpen
	} = props;

	const classes = useStyles();

	const bytesToMegaBytes = (bytes: number, roundTo: number): number =>
		roundTo ? parseFloat((bytes / (1024 * 1024)).toFixed(roundTo)) : bytes / (1024 * 1024);

	const FileUploader = () => {
		const message =
			'Single file size cannot be larger than 5 MB. File type must be one of .pdf, .docx, .rtf, .pptx, .ppt';
		const hiddenFileInput = useRef<HTMLInputElement>(null);
		const [isFileValid, setFileValid] = useState(true);
		const [isLoading, setLoading] = useState(false);
		const [isFileDeleteOpen, setFileDeleteOpen] = useState(false);

		const handleClick = () => {
			hiddenFileInput.current?.click();
		};

		const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
			const fileUploaded = event.target.files ? event.target.files[0] : new File([''], 'temp');
			console.log('file size', fileUploaded.size);
			const MBformat = bytesToMegaBytes(fileUploaded.size, 2);
			const limit = 5;

			// validate file size
			if (MBformat > limit) {
				console.log('over file size 5 MB');
				setFileValid(false);
			} else {
				setFileValid(true);
				setLoading(true);
				//upload to S3
				//pass file to backend parsing api, return filter json
				const email = memory.user.email.trim();
				const path = `${email}/${fileUploaded.name}`;
				const fileFullName = fileUploaded.name;
				const idx = fileFullName.lastIndexOf('.');
				const fileName = fileUploaded.name.substring(0, idx);
				const fileType = fileUploaded.name.substring(idx + 1);

				const config = {
					params: {
						path: path,
						contentType: fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data'
					},
					headers: {
						'Content-Type': fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data',
						'Access-Control-Allow-Origin': '*'
					}
				};

				const attachment = new FormData();
				attachment.append('JDFile', fileUploaded);

				await axios
					.post(url.parsingJDandUploadFile(), attachment, config)
					.then((res) => {
						const filter = res.data.data;
						updateSearch({ ...search, ...filter });
						updateFilterSearchDisabled(false);

						//dynamic load department
						let departmentList = [] as any[];
						for (const company of filter.companies.options) {
							const key = company.key;
							const list = compDepMapping[key];
							departmentList = [...departmentList, ...list];
						}
						updateFilterSelect({ ...filterSelects, Department: departmentList });
						updateUploadFile((prevState: any) => ({
							...prevState,
							uploadFileName: fileName, //xxx.pdf
							uploadFilePath: path, //email/xxx.pdf
							uploadFileSize: fileUploaded.size,
							uploadFileFullName: fileFullName
						}));
						setLoading(false);
					})
					.catch((e) => console.error('parsing JD has error', e));
			}
		};

		const handleDeleteJDFile = async () => {
			const path = uploadFile.uploadFilePath;
			await axios
				.delete(url.deleteJDFile(), {
					...config,
					params: {
						path: path
					}
				})
				.then(() => {
					updateUploadFile({
						uploadFileName: null,
						uploadFilePath: null,
						uploadFileSize: 0,
						uploadFileFullName: null
					});
					setFileDeleteOpen(false);
				});
		};

		return (
			<>
				<div className="card-header--title d-flex flex-row justify-content-start align-items-center">
					{uploadFile.uploadFileName ? (
						<>
							<div className="pr-1">
								<TITLEIMG src={require('../../../assets/images/apps/attachment.png')} />
							</div>
							<div className="pr-1">
								<b>{uploadFile.uploadFileName}</b>
							</div>
							<div>
								<IMG
									src={require('../../../assets/images/apps/remove_icon.png')}
									onClick={() => setFileDeleteOpen(true)}
								/>
							</div>
						</>
					) : (
						<>
							<div className="pr-1 nav-link">
								<TITLEIMG
									src={require('../../../assets/images/apps/upload_icon.png')}
									onClick={handleClick}
								/>
								<input
									type="file"
									ref={hiddenFileInput}
									onChange={handleChange}
									style={{ display: 'none' }}
									accept=".pdf,.docx,.doc,.rtf,.pptx,.ppt"
								/>
							</div>
							<div className="pr-1 nav-link" onClick={handleClick}>
								<b>Upload a Job Description</b>
							</div>
							{isLoading && (
								<div className="text-center">
									<CircularProgress size={26} className={classes.uploadButtonProgress} />
								</div>
							)}
							{!isFileValid && (
								<Alert severity="warning" className="text-center">
									{message}
								</Alert>
							)}
						</>
					)}
				</div>
				<Dialog open={isFileDeleteOpen} keepMounted onClose={() => setFileDeleteOpen(false)}>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{`Are you sure you want to delete this file ${uploadFile.uploadFileName} ?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setFileDeleteOpen(false)} color="primary" variant="outlined">
							Cancel
						</Button>
						<Button
							className={classes.containedPrimary}
							color="primary"
							variant="contained"
							onClick={handleDeleteJDFile}>
							Sure
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	return (
		<div className="card-header pr-2">
			{/* <FileUploader /> TODO Remove File upload temparary*/}
			<div className="card-header--title d-flex flex-row justify-content-start align-items-center"></div>
			<div className="card-header--actions mx-2">
				<Button
					onClick={() => {
						updateAdvancedSearchOpen(!advancedSearchOpen);
						updateUsageActivity(usageTraceLabels.searchTalents);
					}}
					fullWidth
					className={`d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue ${
						advancedSearchOpen && 'active'
					}`}>
					<span className="btn-wrapper--icon">
						<SearchIcon />
					</span>
					<span className="btn-wrapper--label">Search Talents</span>
				</Button>
			</div>
			<div className="card-header--actions">
				<NavLink activeClassName="active" className="nav-link-simple" to={`/savedprojects`}>
					<Button
						fullWidth
						className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue"
						onClick={() => {
							updateUsageActivity(usageTraceLabels.viewProject);
						}}>
						<span className="btn-wrapper--icon">
							<FolderIcon />
						</span>
						<span className="btn-wrapper--label">View Projects</span>
					</Button>
				</NavLink>
			</div>
		</div>
	);
}

/**
 * TODO Dialog transitionComponent not work
 */
