import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blueGrey, green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import Portal from '@material-ui/core/Portal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FolderIcon from '@material-ui/icons/Folder';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { Tabs, Tab, Box } from '@material-ui/core';

import axios from 'axios';
import clsx from 'clsx';
import https from 'https';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import sessionstorage from 'sessionstorage';
import styled from 'styled-components';
import MessageConfirmDialog from '../../../../pages/Common/MessageConfirmDialog';
import {
	actions as projectsActions,
	getProjectsList
} from '../../../../redux/modules/projectsPage';
import { actions as recruitActions } from '../../../../redux/modules/recruitPage';
import LoadingIndicator from '../../../../theme/loadingIndicator';
import { removeHtmlTag } from '../../../../utils/emailTemplateUtil';
import memory from '../../../../utils/memory';
import {
	ActionLabel,
	ActionType,
	EmailSequenceType,
	talentDuplicateProcessType
} from '../../../../utils/message';
import {
	checkAutoNoteParams,
	checkScheduleTime,
	checkValid,
	enumEmailType,
	enumIRSType,
	enumLinkedinDuplicateType,
	handleScheduleTime,
	transformDateToLocalTZ
} from '../../../../utils/recruitUtil';
import { get, post } from '../../../../utils/request';
import url from '../../../../utils/url';
import AutoConnectTemplate from '../../../Recruit/components/AutoConnectTemplate';

import Drawer from '@material-ui/core/Drawer';
import TalentDialog from '../../../Common/TalentDialog';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	autoNoteBtnProgress: {
		color: blueGrey[500],
		position: 'absolute'
	}
}));

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
	margin: 0.25rem !important;
`;

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const TITLEIMG = styled.img`
	width: 30px;
	height: auto;
`;

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const defaultHtmlText = '<p>Hi {firstName}, Good evening! Hope you are doing well.</p>';
const defaultPlaceHolder = 'Hi {firstName}, Good evening! Hope you are doing well.';

const _status = enumEmailType();
const _linkedinDuplicateStatus = enumLinkedinDuplicateType();
const _irsstatus = enumIRSType();

function ListRecommendTalents(props) {
	let { projects, tagId, projectName, projectType, larkActiveTab } = props;
	const { loadProjects } = props.projectsAction;
	const { promiseInProgress } = usePromiseTracker();
	const classes = useStyles();
	const [uploadFile, setUploadFile] = useState({
		uploadFileName: null,
		uploadFilePath: null,
		uploadFileSize: 0,
		uploadFileFullName: null
	});

	const [search, setSearch] = useState({
		status: []
	});
	const [searchWord, setSearchWord] = useState(null);
	const [dataByFilters, setDataByFilters] = useState([]);
	const [dataBySearch, setDataBySearch] = useState([]);

	const [state, setState] = React.useState({
		company: '',
		location: '',
		approach: 'false',
		department_1: '',
		department_2: ''
	});

	const [resultData, setResultData] = useState([]);
	const [filterData, setFilterData] = useState(null);
	const [mainData, setMainData] = useState([]);

	const [isStandardRecommendation, setStandardRecommendation] = useState(true);
	const [activeTab, setActiveTab] = useState(
		larkActiveTab && typeof larkActiveTab !== 'undefined' && larkActiveTab !== 0 ? larkActiveTab : 0
	);
	const [larkFlag, setLarkFlag] = useState(
		larkActiveTab && typeof larkActiveTab !== 'undefined' && larkActiveTab !== 0 ? true : false
	);
	const [activeActionTab, setActiveActionTab] = useState('0');
	const [open, setOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [ithRow, setIthRow] = useState(0);
	const [filterCheckers, setFilterCheckers] = useState({});
	const [checkersPerPage, setCheckersPerPage] = useState([]); //used to check if checked talents in current page
	const [numberOfSelected, setNumberOfSelected] = useState(0);
	const [sendAll, setSendAll] = useState(false);
	const [emailSendLoading, setEmailSendLoading] = useState(false);

	const [folders, setFolders] = useState([]);
	const [folderSelection, SetFolderSelection] = useState(null);
	const [isFolderUpdateDisabled, setFolderUpdateDisabled] = useState(true);
	const [isFolderUpdate, setFolderUpdate] = useState(false);
	const [insertArray, setInsertArray] = useState([]);
	const [subtractArray, setSubtractArray] = useState([]);

	const [isLoading, setLoading] = useState(false);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const fileRef = useRef(); //used to get updated file value in useCallback function
	const [isOppSendDisabled, setOppSendDisabled] = useState(true);

	/************** Group Email Dialog **************************/
	const [groupEmailOpen, setGroupEmailOpen] = useState(false);

	/**************  Duplicate Check *******************/
	const [groupDuplicateOpen, setGroupDuplicateOpen] = useState(false);
	const [linkedinDuplicateStatus, setLinkedinDuplicateStatus] = useState({});
	const [duplicateCheckers, setDuplicateCheckers] = useState({});
	const [duplicateTotalNum, setDuplicateTotalNum] = useState(0);
	const [duplicateSelectedNum, setDuplicateSelectedNum] = useState(0);
	const [duplicateNum, setDuplicateNum] = useState(0);

	/*************** Contact talents ********************/
	const [contactTalents, setContactTalents] = useState([]);
	const [contactCheckers, setContactCheckers] = useState({});
	const [contactTotalNum, setContactTotalNum] = useState(0);
	const [contactSelectedNum, setContactSelectedNum] = useState(0);

	/************** Confirm Message *******************************/
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [dialogMessageConfirmOpen, setDialogMessageConfirmOpen] = useState(false);

	/************** Delete Confirm ********************************/
	const [deleteConfirmLoading, setDeleteConfirmLoading] = useState(false);
	const [deleteDialogMessageConfirmOpen, setDeleteDialogMessageConfirmOpen] = useState(false);

	/************** duplicate check & email sent status pages ************/
	const [duplicateTab1Open, setDuplicateTab1Open] = useState(false);
	const [duplicateTab2Open, setDuplicateTab2Open] = useState(false);

	//Template
	const [templates, setTemplates] = useState(null);
	const [templateId, setTemplateId] = useState(null);
	const [isTemplateExpand, setTemplateExpand] = useState(false);
	const [isContextEmpty, setContextEmpty] = useState(true);
	const [templateName, setTemplateName] = useState('');
	const [templateNameMsg, setTemplateNameMsg] = useState('');
	const [isTemplateConfirm, setTemplateConfirm] = useState(false);
	const [isTemplateDuplicate, setTemplateDuplicate] = useState(false);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [templateType, setTemplateType] = useState('');

	const [currentTab, setCurrentTab] = useState('rmr'); // 假设默认选中的是rmr tab
	// save new project
	const [newProject, setNewProject] = useState({ open: false, projectName: '' });

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	// upload file
	const [myFiles, setMyFiles] = useState([]); //used to remove file
	const [file, setFile] = useState({
		filename: [],
		path: []
	});
	fileRef.current = file;

	const [autoConnectUploadFile, setAutoConnectUploadFile] = useState([]);
	const [autoUploadFile, setAutoUploadFile] = useState({
		filename: [],
		path: []
	});

	const bytesToMegaBytes = (bytes, roundTo) =>
		roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);

	const removeFile = (f) => () => {
		const newFiles = [...myFiles];
		const index = newFiles.indexOf(f);

		newFiles.splice(index, 1);
		setMyFiles(newFiles);

		file.filename.splice(index, 1);
		file.path.splice(index, 1);

		const newSize = Number(sessionstorage.getItem('totalSize')) - f.size;
		sessionstorage.setItem('totalSize', newSize);

		setFile({
			//related send email
			filename: file.filename,
			path: file.path
		});

		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
		} else {
			fileRejections.splice(0, fileRejections.length);
		}
		forceUpdate();
	};

	const accumulate = (file) => {
		console.log('file size', file.size);
		const MBformat = bytesToMegaBytes(file.size, 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			return {
				message: `Single file size cannot be larger than ${limit} MB`
			};
		}

		if (!sessionstorage.getItem('totalSize')) {
			sessionstorage.setItem('totalSize', file.size);
		} else {
			const newSize = Number(sessionstorage.getItem('totalSize')) + file.size;
			sessionstorage.setItem('totalSize', newSize);
		}

		return null;
	};

	const onDropAccepted = useCallback(
		(acceptedFiles) => {
			console.log('acceptFiles', acceptedFiles);
			setLoading(true);
			let attachment = new FormData();
			let email = memory.user.email.trim();

			let files = [];
			for (let i = 0; i < acceptedFiles.length; i++) {
				attachment.append('uploadFiles', acceptedFiles[i]);
				files.push(`${email}/${acceptedFiles[i].name}`);
			}

			const options = {
				params: {
					Keys: files,
					ContentType: 'application/x-www-form-urlencoded'
				},
				headers: {
					ContentType: 'application/x-www-form-urlencoded'
				}
			};

			axios
				.post(url.uploadAttachment(), attachment, options)
				.then(() => {
					let filenames = [];
					let paths = [];
					acceptedFiles.forEach((file) => {
						filenames.push(file.name);
						paths.push(`${email}/${file.name}`);
					});

					const newFileName = [...fileRef.current.filename, ...filenames];
					const newPath = [...fileRef.current.path, ...paths];

					setMyFiles((prevState) => [...prevState, ...acceptedFiles]);

					setFile({
						filename: newFileName,
						path: newPath
					});

					setLoading(false);
				})
				.catch((e) => console.log(e));
		},
		[myFiles, file]
	);

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		fileRejections,
		acceptedFiles,
		getRootProps,
		getInputProps
	} = useDropzone({
		onDropAccepted,
		accept: ['.pdf', '.docx', '.doc', '.rtf', '.pptx', '.ppt'],
		validator: accumulate
	});

	let fileRejectionItems = fileRejections.map(({ errors }) => (
		<>
			{errors.map((e) => (
				<Alert severity="warning" className="text-center mb-3">
					{e.message}
				</Alert>
			))}
		</>
	));

	//Pagination
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const rowCount = 25;
	const handleChangePage = (e, value) => {
		setPage(value);
		const local = filterData && filterData !== undefined ? filterData : resultData;
		setMainData(local.slice(value * rowCount - rowCount, value * rowCount));
	};

	const validateFiles = () => {
		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			//setErrorMsg(`Total files size is larger than ${limit} MB`);
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
			forceUpdate();
			return false;
		}

		if (myFiles.length > 5) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Maximum number of files upload: 5` }] });
			forceUpdate();
			return false;
		}

		return true;
	};

	let files = myFiles.map((file, index) => (
		<ListItem
			className="font-size-sm px-3 py-2 text-primary d-flex justify-content-start align-items-center"
			key={index}>
			<span>
				<span
					className="badge badge-pill bg-neutral-danger text-primary mr-2"
					onClick={removeFile(file)}>
					X
				</span>
				{file.path}
			</span>
			<span className="badge badge-pill bg-neutral-warning text-primary">
				{bytesToMegaBytes(file.size, 2)} MB
			</span>
		</ListItem>
	));

	/*************************************************** Search Start *****************************************/
	const goSearchTalents = () => {
		const option = filterSearch.status;
		const optionKeys = option.map((each) => each.key);

		const validParams = {}; //only irsstatus
		for (const [key, value] of Object.entries(filterSearch)) {
			if (value.length !== 0) {
				validParams[key] = value;
			}
		}

		let filteredTalents = [];
		if (Object.keys(validParams).length > 1) {
			// status, irsstatus applied
			//filter "irsstatus"
			if (Object.keys(validParams).includes('status')) delete validParams['status'];

			for (const [_, talent] of Object.entries(searchWord ? dataBySearch : resultData)) {
				//fetch all talents data keys include
				const array = Object.keys(validParams).filter((param) => {
					return !validParams[param].includes(talent[param]);
				});

				if (array.length === 0) {
					filteredTalents.push(talent);
				}
			}

			//filter "status"
			const {
				_talentsWithoutEmails,
				_talentsWithoutActions,
				_talentsWithoutSentEmails,
				_talentsWithSentEmails,
				_talentsWithOpenEmails,
				_talentsWithRepliedEmails
			} = handleFilterOptions(filteredTalents);

			const category = {
				4: _talentsWithoutEmails,
				0: _talentsWithoutActions, //NO ACTION
				1: _talentsWithoutSentEmails, //PENDING
				2: _talentsWithSentEmails, //SENT
				3: _talentsWithOpenEmails, //OPEN
				5: _talentsWithRepliedEmails //REPLIED
			};

			let finalData = [];
			if (optionKeys.length > 0) {
				for (const key of optionKeys) {
					const talentIds = finalData.map((each) => each.id);
					const pendingTalents = category[key];
					const finalTalents = pendingTalents.filter((each) => !talentIds.includes(each.id));
					finalData = [...finalData, ...finalTalents];
				}
			} else {
				finalData = [...finalData, ...filteredTalents];
			}

			//organize react state
			if (finalData.length > 0) {
				const local = finalData;
				setMainData(local.slice(0, rowCount));
				setFilterData(finalData);
				setPage(1);

				//number of selected
				const filteredCheckedTalents = Object.entries(filterCheckers)
					.filter(([_, value]) => value !== undefined)
					.filter(([_, value]) => finalData.includes(value));
				setNumberOfSelected(filteredCheckedTalents.length);
				setTotalCount(finalData.length);
			} else {
				setMainData([]);
				setFilterData([]);
				setPage(1);

				//number of selected
				setNumberOfSelected(0);
				setTotalCount(0);
			}
		} else if (Object.keys(validParams).length === 1) {
			if (
				Object.keys(validParams).includes('irsstatus') ||
				Object.keys(validParams).includes('linkedinDuplicateStatus')
			) {
				for (const [_, talent] of Object.entries(searchWord ? dataBySearch : resultData)) {
					//fetch all talents data keys include
					const array = Object.keys(validParams).filter((param) => {
						if (!talent[param]) return true; //if has null value, cannot match
						return !validParams[param].includes(talent[param]);
					});

					if (array.length === 0) {
						filteredTalents.push(talent);
					}
				}
			} else if (Object.keys(validParams).includes('status')) {
				const {
					_talentsWithoutEmails,
					_talentsWithoutActions,
					_talentsWithoutSentEmails,
					_talentsWithSentEmails,
					_talentsWithOpenEmails,
					_talentsWithRepliedEmails
				} = handleFilterOptions(searchWord ? dataBySearch : resultData);

				const category = {
					4: _talentsWithoutEmails,
					0: _talentsWithoutActions, //NO ACTION
					1: _talentsWithoutSentEmails, //PENDING
					2: _talentsWithSentEmails, //SENT
					3: _talentsWithOpenEmails, //OPEN
					5: _talentsWithRepliedEmails //REPLIED
				};

				if (optionKeys.length > 0) {
					for (const key of optionKeys) {
						const talentIds = filteredTalents.map((each) => each.id);
						const pendingTalents = category[key];
						const finalTalents = pendingTalents.filter((each) => !talentIds.includes(each.id));
						filteredTalents = [...filteredTalents, ...finalTalents];
					}
				} else {
					const local = searchWord ? dataBySearch : resultData;
					filteredTalents = [...filteredTalents, ...local];
				}
			}

			//organize react state
			if (filteredTalents.length > 0) {
				const local = filteredTalents;
				setMainData(local.slice(0, rowCount));
				setFilterData(filteredTalents);
				setPage(1);

				//number of selected
				const filteredCheckedTalents = Object.entries(filterCheckers)
					.filter(([_, value]) => value !== undefined)
					.filter(([_, value]) => filteredTalents.includes(value));
				setNumberOfSelected(filteredCheckedTalents.length);
				setTotalCount(filteredTalents.length);
			} else {
				setMainData([]);
				setFilterData([]);
				setPage(1);

				//number of selected
				setNumberOfSelected(0);
				setTotalCount(0);
			}
		} else {
			const local = searchWord ? dataBySearch : resultData;
			setMainData(local.slice(0, rowCount));
			setFilterData(searchWord ? dataBySearch : null);
			setPage(1);

			//number of selected
			const filteredCheckedTalents = Object.entries(filterCheckers).filter(
				([_, value]) => value !== undefined
			);
			setNumberOfSelected(filteredCheckedTalents.length);
			setTotalCount(local.length);
		}

		/************  Search data only by filters, use for search field ************/
		let _dataByFilters = [];

		const dataByFilter_irsstatus = [];
		if (Object.keys(validParams).length > 1) {
			// status, irsstatus applied
			//filter "irsstatus"
			if (Object.keys(validParams).includes('status')) delete validParams['status'];

			for (const [_, talent] of Object.entries(resultData)) {
				//fetch all talents data keys include
				const array = Object.keys(validParams).filter((param) => {
					if (!talent[param]) return true; //if has null value, cannot match
					return !validParams[param].includes(talent[param]);
				});

				if (array.length === 0) {
					dataByFilter_irsstatus.push(talent);
				}
			}

			//filter "status"
			const {
				_talentsWithoutEmails,
				_talentsWithoutActions,
				_talentsWithoutSentEmails,
				_talentsWithSentEmails,
				_talentsWithOpenEmails,
				_talentsWithRepliedEmails
			} = handleFilterOptions(dataByFilter_irsstatus);

			const category = {
				4: _talentsWithoutEmails,
				0: _talentsWithoutActions, //NO ACTION
				1: _talentsWithoutSentEmails, //PENDING
				2: _talentsWithSentEmails, //SENT
				3: _talentsWithOpenEmails, //OPEN
				5: _talentsWithRepliedEmails //REPLIED
			};

			if (optionKeys.length > 0) {
				for (const key of optionKeys) {
					const talentIds = _dataByFilters.map((each) => each.id);
					const pendingTalents = category[key];
					const finalTalents = pendingTalents.filter((each) => !talentIds.includes(each.id));
					_dataByFilters = [..._dataByFilters, ...finalTalents];
				}
			} else {
				_dataByFilters = [..._dataByFilters, ...dataByFilter_irsstatus];
			}

			setDataByFilters(_dataByFilters);
		} else if (Object.keys(validParams).length === 1) {
			if (
				Object.keys(validParams).includes('irsstatus') ||
				Object.keys(validParams).includes('linkedinDuplicateStatus')
			) {
				for (const [_, talent] of Object.entries(resultData)) {
					//fetch all talents data keys include
					const array = Object.keys(validParams).filter((param) => {
						if (!talent[param]) return true; //if has null value, cannot match
						return !validParams[param].includes(talent[param]);
					});

					if (array.length === 0) {
						_dataByFilters.push(talent);
					}
				}

				setDataByFilters(_dataByFilters);
			} else if (Object.keys(validParams).includes('status')) {
				const {
					_talentsWithoutEmails,
					_talentsWithoutActions,
					_talentsWithoutSentEmails,
					_talentsWithSentEmails,
					_talentsWithOpenEmails,
					_talentsWithRepliedEmails
				} = handleFilterOptions(resultData);

				const category = {
					4: _talentsWithoutEmails,
					0: _talentsWithoutActions, //NO ACTION
					1: _talentsWithoutSentEmails, //PENDING
					2: _talentsWithSentEmails, //SENT
					3: _talentsWithOpenEmails, //OPEN
					5: _talentsWithRepliedEmails //REPLIED
				};

				if (optionKeys.length > 0) {
					for (const key of optionKeys) {
						const talentIds = _dataByFilters.map((each) => each.id);
						const pendingTalents = category[key];
						const finalTalents = pendingTalents.filter((each) => !talentIds.includes(each.id));
						_dataByFilters = [..._dataByFilters, ...finalTalents];
					}
				} else {
					const local = searchWord ? dataBySearch : resultData;
					filteredTalents = [...filteredTalents, ...local];

					_dataByFilters = [..._dataByFilters, ...resultData];
				}

				setDataByFilters(_dataByFilters);
			}
		}
	};

	const handleDeleteTalents = async () => {
		if (activeTab === 0) {
			//Talents

			const data = dataConnect();
			const talentIds = data.map((each) => each.id);

			setDeleteConfirmLoading(true);
			await post(url.deleteProjectCandidates(), {
				tagId,
				talentIds
			}).then(() => {
				setTimeout(() => {
					setDeleteConfirmLoading(false);
					setDeleteDialogMessageConfirmOpen(false);
					setActiveTab(0);
					loadProjectTalents();
				}, 3000);
			});
		} else if (activeTab === 2) {
			//AutoNote Talents

			const talents = [];
			if (filterData && filterData !== undefined && Array.isArray(filterData)) {
				const set = new Set(filterData.map((each) => each.id));
				for (const [talentId, talent] of Object.entries(filterCheckers)) {
					if (set.has(parseInt(talentId))) {
						talents.push(talent);
					}
				}
			} else if (resultData && resultData !== undefined && Array.isArray(resultData)) {
				const set = new Set(resultData.map((each) => each.id));
				for (const [talentId, talent] of Object.entries(filterCheckers)) {
					if (set.has(parseInt(talentId))) {
						talents.push(talent);
					}
				}
			}

			const talentIds = talents.map((each) => each.id);

			setDeleteConfirmLoading(true);
			await post(url.deleteProjectCandidates(), {
				tagId,
				talentIds
			}).then(() => {
				setTimeout(() => {
					setDeleteConfirmLoading(false);
					setDeleteDialogMessageConfirmOpen(false);
					setActiveTab(2);
					loadProjectTalents();
				}, 3000);
			});
		}
	};

	const handleFilterOptions = (data) => {
		const talentsWithoutEmails = [];
		const talentsWithOpenEmails = [];
		const talentsWithSentEmails = [],
			talentsWithoutSentEmails = [],
			talentsWithoutActions = [];
		const talentsWithRepliedEmails = [];

		for (const [_, talent] of Object.entries(data)) {
			if (!talent.email || talent.email === undefined || talent.email.trim().length === 0) {
				talentsWithoutEmails.push(talent);
			}
			const history = talent.history;
			if (Array.isArray(history) && history.length > 0) {
				if (history.some((email) => email.open === 1 && email.reply === 1)) {
					talentsWithRepliedEmails.push(talent);
				} else if (history.some((email) => email.open === 1)) {
					talentsWithOpenEmails.push(talent);
				}

				if (history.some((email) => email.sent === 1)) {
					if (history.some((email) => email.open === 1)) {
						continue;
					} else if (history.some((email) => email.sent === 1 && email.bounce === 0)) {
						talentsWithSentEmails.push(talent);
					} else if (history.some((email) => email.sent === 1 && email.bounce === 1)) {
						talentsWithoutSentEmails.push(talent);
					}
				} else if (history.some((email) => email.sent === 0)) {
					talentsWithoutSentEmails.push(talent);
				}
			} else if (Array.isArray(history) && history.length === 0) {
				talentsWithoutActions.push(talent);
			}
		}

		return {
			_talentsWithoutEmails: talentsWithoutEmails,
			_talentsWithOpenEmails: talentsWithOpenEmails,
			_talentsWithSentEmails: talentsWithSentEmails,
			_talentsWithoutSentEmails: talentsWithoutSentEmails,
			_talentsWithoutActions: talentsWithoutActions,
			_talentsWithRepliedEmails: talentsWithRepliedEmails
		};
	};

	const handleAdvancedSearch = (e, value, name) => {
		setSearch({
			...search,
			[name]: value
		});
	};

	const searchHandler = (event) => {
		const hasFiltersSelected =
			filterSearch.status.length > 0 ||
			filterSearch.irsstatus.length > 0 ||
			filterSearch.linkedinDuplicateStatus.length > 0;

		const searchResult = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(hasFiltersSelected ? dataByFilters : resultData)) {
				const name = value.name ? value.name.toLowerCase() : '';
				const position =
					value.companies.length > 0
						? [
								...new Set(
									value.companies
										.filter((comp) => comp.isCurrent === 1)
										.map((item) =>
											item.position && item.position.length > 0 ? item.position.toLowerCase() : ''
										)
								)
						  ].join()
						: '';
				const location = value.location
					? (value.location.includes('United States')
							? value.location.split(',').length > 2
								? value.location
										.split(',')[0]
										.concat(',', value.location.split(',')[1])
										.toLowerCase()
								: value.location.toLowerCase()
							: value.location
					  ).toLowerCase()
					: '';
				const stacks = value.stacks ? value.stacks.toLowerCase() : '';
				const yearsOfWorkingExperiences = value.yearsOfWorkingExperiences
					? value.yearsOfWorkingExperiences.toString()
					: '';
				const companies =
					value.companies.length > 0
						? [
								...new Set(
									value.companies
										.filter((comp) => comp.isCurrent === 1)
										.map((item) =>
											item.company && item.company.length > 0 ? item.company.toLowerCase() : ''
										)
								)
						  ].join()
						: '';
				const department_2 = value.department_2.toLowerCase();
				const degree =
					value.institutions.length > 0
						? [
								...new Set(
									value.institutions
										.filter((inst) => inst.isHighest === 1)
										.map((item) =>
											item.degree && item.degree.length > 0 ? item.degree.toLowerCase() : ''
										)
								)
						  ].join()
						: '';

				if (
					name.includes(event.target.value.toLowerCase()) ||
					position.includes(event.target.value.toLowerCase()) ||
					location.includes(event.target.value.toLowerCase()) ||
					stacks.includes(event.target.value.toLowerCase()) ||
					yearsOfWorkingExperiences.includes(event.target.value.toLowerCase()) ||
					department_2.includes(event.target.value.toLowerCase()) ||
					companies.includes(event.target.value.toLowerCase()) ||
					degree.includes(event.target.value.toLowerCase())
				) {
					searchResult.push(value);
				}
			}
			setSearchWord(event.target.value);
		} else {
			setSearchWord(null);
		}

		if (searchResult.length > 0) {
			console.log('search result: ', searchResult);
			setFilterData(searchResult);
			const local = searchResult;
			setMainData(local.slice(0, rowCount));

			//number of selected
			const filteredCheckedTalents = Object.entries(filterCheckers)
				.filter(([_, value]) => value !== undefined)
				.filter(([_, value]) => searchResult.includes(value));

			setNumberOfSelected(filteredCheckedTalents.length);
			setTotalCount(searchResult.length);
		} else {
			if (event.target.value.length === 0) {
				const data = hasFiltersSelected ? dataByFilters : resultData;
				setMainData(data.slice(0, rowCount));
				setFilterData(hasFiltersSelected ? dataByFilters : null);

				//number of selected
				const filteredCheckedTalents = Object.entries(filterCheckers)
					.filter(([_, value]) => value !== undefined)
					.filter(([_, value]) => data.includes(value));

				setNumberOfSelected(filteredCheckedTalents.length);
				setTotalCount(data.length);
			} else {
				setMainData([]);
				setFilterData([]);
				setTotalCount(0);
				setNumberOfSelected(0);
			}
		}
		setPage(1);

		/*** search data only by search field for filters use ***/
		const searchResultByWord = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(resultData)) {
				const name = value.name ? value.name.toLowerCase() : '';
				const position =
					value.companies.length > 0
						? [
								...new Set(
									value.companies
										.filter((comp) => comp.isCurrent === 1)
										.map((item) =>
											item.position && item.position.length > 0 ? item.position.toLowerCase() : ''
										)
								)
						  ].join()
						: '';
				const location = value.location
					? (value.location.includes('United States')
							? value.location.split(',').length > 2
								? value.location
										.split(',')[0]
										.concat(',', value.location.split(',')[1])
										.toLowerCase()
								: value.location.toLowerCase()
							: value.location
					  ).toLowerCase()
					: '';
				const stacks = value.stacks ? value.stacks.toLowerCase() : '';
				const yearsOfWorkingExperiences = value.yearsOfWorkingExperiences
					? value.yearsOfWorkingExperiences.toString()
					: '';
				const companies =
					value.companies.length > 0
						? [
								...new Set(
									value.companies
										.filter((comp) => comp.isCurrent === 1)
										.map((item) =>
											item.company && item.company.length > 0 ? item.company.toLowerCase() : ''
										)
								)
						  ].join()
						: '';
				const department_2 = value.department_2.toLowerCase();
				const degree =
					value.institutions.length > 0
						? [
								...new Set(
									value.institutions
										.filter((inst) => inst.isHighest === 1)
										.map((item) =>
											item.degree && item.degree.length > 0 ? item.degree.toLowerCase() : ''
										)
								)
						  ].join()
						: '';

				if (
					name.includes(event.target.value.toLowerCase()) ||
					position.includes(event.target.value.toLowerCase()) ||
					location.includes(event.target.value.toLowerCase()) ||
					stacks.includes(event.target.value.toLowerCase()) ||
					yearsOfWorkingExperiences.includes(event.target.value.toLowerCase()) ||
					department_2.includes(event.target.value.toLowerCase()) ||
					companies.includes(event.target.value.toLowerCase()) ||
					degree.includes(event.target.value.toLowerCase())
				) {
					searchResultByWord.push(value);
				}
			}
		}

		setDataBySearch(searchResultByWord);
	};

	/************************************************ Search End ***********************************************/

	/******************************************** Filter Option Start ****************************************/
	const [anchorElStatus, setAnchorElStatus] = useState(null);
	const [anchorElIRS, setAnchorElIRS] = useState(null);
	const [anchorElLinkedinDuplicate, setAnchorElLinkedinDuplicate] = useState(null);
	const [filterOptions, setFilterOptions] = useState({
		status: [],
		irsstatus: [],
		linkedinDuplicateStatus: []
	});
	const [filterSearch, setFilterSearch] = useState({
		status: [],
		irsstatus: [],
		linkedinDuplicateStatus: []
	});

	//Status
	const handleStatusClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElStatus(event.currentTarget);
	};
	const handleStatusClose = () => {
		setAnchorElStatus(null);
	};
	const openStatus = Boolean(anchorElStatus);

	//IRS Status
	const handleIRSClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElIRS(event.currentTarget);
	};
	const handleIRSClose = () => {
		setAnchorElIRS(null);
	};
	const openIRS = Boolean(anchorElIRS);

	//Linkedin Duplicate Status
	const handleLinkedinDuplicateStatus = (event) => {
		console.log(event.currentTarget);
		setAnchorElLinkedinDuplicate(event.currentTarget);
	};
	const handleLinkedinDuplicateClose = () => {
		setAnchorElLinkedinDuplicate(null);
	};
	const openLinkedinDuplicate = Boolean(anchorElLinkedinDuplicate);
	const [inputPage, setInputPage] = useState(page);

	function searchResultOptions(resultData) {
		let status = [];
		let irsstatus = [];
		let linkedinDuplicateStatus = [];

		//Status options
		const {
			_talentsWithoutEmails,
			_talentsWithoutActions,
			_talentsWithoutSentEmails,
			_talentsWithSentEmails,
			_talentsWithOpenEmails,
			_talentsWithRepliedEmails
		} = handleFilterOptions(resultData);

		if (_talentsWithoutEmails.length > 0) {
			status.push({ key: 4, value: _status.NO_EMAIL });
		}
		if (_talentsWithoutActions.length > 0) {
			status.push({ key: 0, value: _status.NO_ACTION });
		}
		if (_talentsWithoutSentEmails.length > 0) {
			status.push({ key: 1, value: _status.PENDING });
		}
		if (_talentsWithSentEmails.length > 0) {
			status.push({ key: 2, value: _status.SENT });
		}
		if (_talentsWithOpenEmails.length > 0) {
			status.push({ key: 3, value: _status.OPEN });
		}
		if (_talentsWithRepliedEmails.length > 0) {
			status.push({ key: 5, value: _status.REPLIED });
		}
		//status.push(_status.HAS_EMAIL);

		//IRS Status options, Linkedin Duplicate status options
		for (const talent of resultData) {
			if (!irsstatus.includes(talent['irsstatus']) && talent['irsstatus']) {
				irsstatus.push(talent['irsstatus']);
			}

			if (
				talent['linkedinDuplicateStatus'] === _linkedinDuplicateStatus.YES &&
				!linkedinDuplicateStatus.includes(_linkedinDuplicateStatus.YES)
			) {
				linkedinDuplicateStatus.push(_linkedinDuplicateStatus.YES);
			} else if (
				talent['linkedinDuplicateStatus'] === _linkedinDuplicateStatus.NO &&
				!linkedinDuplicateStatus.includes(_linkedinDuplicateStatus.NO)
			) {
				linkedinDuplicateStatus.push(_linkedinDuplicateStatus.NO);
			}
		}

		return {
			status,
			irsstatus,
			linkedinDuplicateStatus
		};
	}

	function handleFilterSearch(e, value, name) {
		setFilterSearch({
			...filterSearch,
			[name]: value
		});
	}

	/******************************************** Filter Option End ****************************************/

	const handleChange = (event) => {
		const name = event.target.name;
		console.log(event.target);
		setState({
			...state,
			[name]: event.target.value
		});
	};

	const handleFolderChange = async (event, values) => {
		console.log(selectedRow.tags); //new existing project
		const talentId = selectedRow.id;
		const selectedProjects = values;
		const assignedProjects = selectedRow.tags;
		const insert = [];
		const subtract = [];

		//Add project to the given talent
		//const set = new Set();
		let talentsProjectMapping = {};
		talentsProjectMapping[talentId] = [];
		for (const selectedProject of selectedProjects) {
			const assignedProjects = selectedRow.tags;
			const assignedProjectsIds = assignedProjects.map((item) => item.id);

			if (!assignedProjectsIds.includes(selectedProject.id)) {
				const project = folderSelection.find((project) => project.id === selectedProject.id);
				insert.push(project.id);
				talentsProjectMapping[talentId].push(selectedProject);
			}

			//set.add(selectedProject.id);
		}

		//Remove project from the given talent
		assignedProjects.forEach((each) => {
			if (!selectedProjects.includes(each)) {
				const project = folderSelection.find((project) => project.name === each.name);
				subtract.push({
					tagId: project.id,
					talentId: talentId
				});
			}
		});
		if (subtract.length > 0) {
			//post(url.cancelAssignProjects(), subtract).then((res) => console.log(res));
			setSubtractArray(subtract);
		}

		if (insert.length > 0) {
			setInsertArray(insert);
		}

		setFolders(values);
		if (activeTab === 1) {
			setFolderUpdateDisabled(false);
			setFolderUpdate(false);
		} else if (activeTab === 0) {
			setFolderUpdateDisabled(true);
			setFolderUpdate(true);
		}
	};

	const handleUpdateProjects = async () => {
		const talentId = selectedRow.id;
		setAutoLoading(true);

		if (subtractArray.length > 0) {
			await post(url.cancelAssignProjects(), subtractArray).then((res) => console.log(res));
		}

		if (insertArray.length > 0) {
			await axios
				.post(
					url.addProjectsInRecommendation(),
					{
						tagIds: insertArray,
						talentId: talentId,
						currTagId: tagId,
						selectedTalent: selectedRow
					},
					config
				)
				.then((res) => {
					console.log(res.data.message);
				})
				.catch((e) => console.error('Change folders for the given talent has error', e));
		}

		handleClose();
		setAutoLoading(false);

		if (activeTab === 1) {
			trackPromise(
				axios
					.get(url.fetchRecommendationsForProjectView(tagId))
					.then(
						(res) => {
							console.log(res.data.message);
							const result = res.data.data;
							setMainData(result.length > 0 ? result : []);
							setTotalCount(result.length);
							return res;
						},
						(error) => {
							console.log('fetchRecommendationsForProjectView error', error);
						}
					)
					.catch((e) => {
						console.log('fetchRecommendationsForProjectView error catch', e.message);
					})
			);
		} else if (activeTab === 0) {
			trackPromise(
				axios
					.post(url.fetchTalentsByProject(), {
						tagId: tagId,
						isTalentView: true
					})
					.then((res) => {
						console.log(res.data.message);
						const result = res.data.data;
						setPage(1);
						const local = result;
						setResultData(result);
						setMainData(local.slice(rowCount - rowCount, rowCount));
						setTotalCount(result.length);

						if (subtractArray.length > 0) {
							for (const each of subtractArray) {
								setFilterCheckers((prevState) => ({ ...prevState, [each.talentId]: undefined }));
							}
						}
						return res;
					})
					.catch((e) => {
						console.error('fetchTalentsByProject error catch', e.message);
					})
			);
		}
	};

	const toggleDrawer = (event, open) => {
		if (
			event &&
			typeof event !== 'undefined' &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setOpen(open);
	};

	const handleClickAction = async (e, data, tabIndex, ith) => {
		console.log('talent select', data);

		if (e && typeof e !== 'undefined') {
			const row = e.target.parentNode.parentNode.parentNode.parentNode;
			row.focus();
		}

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then(
				(res) => {
					const result = res.data;
					console.log('fetchTalentDetail', result.message);

					//calculate ith talent overall
					const ithTalent = (page - 1) * rowCount + ith;
					setIthRow(ithTalent);

					setSelectedRow(result.data);
					setFolders(result.data.tags);
					setActiveActionTab(tabIndex);
					setLetter((prevState) => ({
						...prevState,
						candidateEmailMapping: [data.id]
					}));

					toggleDrawer(e, true);
				},
				(error) => {
					console.error('fetchTalentDetail error', error);
				}
			)
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
				return Promise.reject(e.message);
			});
	};

	const handleClose = () => {
		setOpen(false);
		setSendAll(false);
		setGroupEmailOpen(false);

		setLetterSubject('');
		setLetter({
			candidateEmailMapping: [],
			htmlBody: ''
		});
		setTemplateType('');
		setTemplateId(null);

		//Folder
		setSubtractArray([]);
		setInsertArray([]);

		setTemplateName('');
		setContextEmpty(true);
		setTemplateExpand(false);
		setTemplateConfirm(false);

		if (uploadFile.uploadFileName) {
			const myFile = {
				path: uploadFile.uploadFileFullName,
				name: uploadFile.uploadFileFullName,
				size: uploadFile.uploadFileSize
			};
			setMyFiles([myFile]);
			setFile({ filename: [myFile.name], path: [myFile.path] });
			sessionstorage.setItem('totalSize', myFile.size);
		} else {
			setMyFiles([]);
			setFile({
				filename: [],
				path: []
			});
			sessionstorage.setItem('totalSize', 0);
		}

		setMessage('');
		setState({
			...state,
			approach: 'false'
		});

		fileRejections.splice(0, fileRejections.length);

		//AutoConnect
		console.log('ref_initial: ', ref_initial.current);
		if (ref_initial.current) {
			ref_initial.current.handleClose();
		}

		//first email
		console.log('ref_action_firstEmail: ', ref_action_firstEmail.current);
		if (ref_action_firstEmail.current) {
			ref_action_firstEmail.current.handleClose();
		}
		setAction_firstEmail_time(initial_action_firstEmail_time);
		setTimestamp_action_firstEmail(initial_action_firstEmail_timestamp);
		setError_action_firstEmail_time(false);
		setActionFirstEmail(true);

		//extra email
		console.log('ref_extraEmail: ', ref_action_extraEmail.current);
		if (ref_action_extraEmail.current) {
			ref_action_extraEmail.current.handleClose();
		}
		setExtraEmail_time(initial_extraEmail_time);
		setTimestamp_extraEmail(initial_extraEmail_timestamp);
		setError_extraEmail_time(false);
		setExtraEmail(true);

		//reminder autonote for AutoEmail
		setAction_lastEmail_time(initial_action_lastEmail_time);
		setTimestamp_lastEmail(initial_action_lastEmail_timestamp);
		setError_action_lastEmail_time(false);
		setActionLastEmail(true);

		setAction(initial_action);
		setActionTypeValues(initial_actionTypeValues);

		//reminder autonote for SingleEmail
		setAction_singleEmail_time(initial_action_singleEmail_time);
		setTimestamp_singleEmail(initial_action_singleEmail_timestamp);
		setError_action_singleEmail_time(false);
		setActionSingleEmail(true);

		//initialize group email send button expand
		setFollowUpEmailActionDisabled(true);
		setExtraEmailActionDisabled(true);
		setLastEmailActionDisabled(true);

		//update projectså
		setFolderUpdateDisabled(true);
		setFolderUpdate(false);
	};

	const handleCheckBox = (e) => {
		console.log('target name', e.target.name);
		console.log('target checked', e.target.checked);
		// setState({ ...state, [e.target.name]: e.target.checked });
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const toggleActionTab = (tab) => {
		if (activeActionTab !== tab) setActiveActionTab(tab);
	};

	const toggleAutoNoteTab = (tab) => {
		if (autoNoteTab !== tab) setAutoNoteTab(tab);
	};

	const renderStars = (number) => {
		let container = [];

		let idx = 0;
		for (let i = 0; i < number; i++) {
			container.push(<StarIcon key={idx++} />);
		}
		for (let i = 0; i < 3 - number; i++) {
			container.push(<StarOutlineIcon key={idx++} />);
		}

		return container.map((each) => each);
	};

	const handleCheckTalent = () => {
		setFilterCheckers((prevState) => ({
			...prevState,
			[selectedRow.id]: selectedRow
		}));
		setNumberOfSelected(numberOfSelected + 1);
	};

	const handleUnCheckTalent = () => {
		setFilterCheckers((prevState) => ({
			...prevState,
			[selectedRow.id]: undefined
		}));
		setNumberOfSelected(numberOfSelected - 1);
	};

	/******************************************** Auto Note *********************************/
	const limit = 300;
	const [isLoginOpen, setLoginOpen] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [autoNoteTab, setAutoNoteTab] = useState(0);
	const [loginStatus, setLoginStatus] = useState([]);
	const [is2FA, set2FA] = useState({
		is2FAShow: false,
		is2FASendBtnDisabled: false,
		vfCode: null,
		errorMsgShow: false
	});
	const [autoNoteSendActive, setAutoNoteSendActive] = useState(false);
	const [autoNoteParamsValid, setAutoNoteParamsValid] = useState(true);
	const [sleepTime, setSleepTime] = useState(3);
	const [withDrawDays, setWithDrawDays] = useState(14);
	const [note, setNote] = useState({ htmlText: null, numberLeft: limit });
	const [accountLogin, setAccountLogin] = useState(null);
	const [account2FA, setAccount2FA] = useState({
		index: -1,
		account: null
	});
	const [noteLoading, setNoteLoading] = useState(false);
	const [loginLoading, setLoginLoading] = useState([]);
	const [vfLoading, setVfLoading] = useState(false);
	const [isAutoNoteConfirmOpen, setAutoNoteConfirmOpen] = useState(false);
	const [numberOfTalentsProcessing, setNumberOfTalentsProcessing] = useState(0);
	const [isWindowAlertOpen, setWindowAlertOpen] = useState(false);

	const [windowsIds, setWindowsIds] = useState(null);

	const ref_quill = useRef();

	const alarm = {
		remind: function () {
			this.timeoutID = undefined;

			const ary = [...Array(loginStatus.length)];
			ary.fill(false);
			setLoginStatus(ary);
			setAutoNoteSendActive(false);
		},

		setup: function () {
			if (typeof this.timeoutID === 'number') {
				this.cancel();
			}

			this.timeoutID = setTimeout(
				function (msg) {
					this.remind();
				}.bind(this),
				5 * 60 * 1000
			);
		},

		cancel: function () {
			clearTimeout(this.timeoutID);
		}
	};

	const handleLogin = async (index, account) => {
		const copyLoginLoading = [...loginLoading];
		copyLoginLoading[index] = true;
		setLoginLoading(copyLoginLoading);

		const windowsId = Date.now().toString();
		const email = account.account;
		setWindowsIds({ ...windowsIds, [email]: windowsId });
		const copyAccount = { ...account, windowsId: windowsId };

		await axios
			.post(url.loginLinkedin(), copyAccount, config)
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					const data = res.data.data;
					if (data.status === 'SUCCESS') {
						const temp = [...loginStatus];
						for (let i = 0; i < loginStatus.length; i++) {
							if (i !== index) {
								temp[i] = false;
							} else {
								temp[i] = true;
							}
						}

						setLoginStatus(temp);
						setAutoNoteSendActive(true);
						setAccountLogin(copyAccount);

						set2FA({
							...is2FA,
							is2FAShow: false,
							is2FASendBtnDisabled: false,
							vfCode: null,
							errorMsgShow: false
						});
						setAccount2FA({ index: -1, account: null });

						alarm.setup();
					} else if (data.status === '2FA') {
						set2FA({ ...is2FA, is2FAShow: true });
						setAccount2FA({ index: index, account: account });
						setAutoNoteSendActive(false);

						const temp = [...loginStatus];
						// for (let i = 0; i < loginStatus.length; i++) {
						// 	const each = accounts[i];
						// 	if (each.remainingCredits < getNumberOfChecked(filterCheckers)) {
						// 		temp[i] = true;
						// 	} else {
						// 		temp[i] = false;
						// 	}
						// }
						setLoginStatus(temp);
					}
				}
			})
			.catch((e) => {
				console.log('loginLinkedin error catch', e.message);
			});

		const recoverLoading = [...loginLoading];
		setLoginLoading(recoverLoading);
	};

	const handleSend2FA = async () => {
		const acct = account2FA.account;
		const index = account2FA.index;

		const email = acct.account;
		const copyAccount = { ...acct, windowsId: windowsIds[email], vfCode: is2FA.vfCode.trim() };
		setVfLoading(true);

		await axios
			.post(url.verify2FA(), copyAccount, config)
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					const data = res.data.data;
					if (data.status === 'SUCCESS') {
						const temp = [...loginStatus];
						for (let i = 0; i < loginStatus.length; i++) {
							if (i !== index) {
								temp[i] = false;
							} else {
								temp[i] = true;
							}
						}

						setLoginStatus(temp);
						setAutoNoteSendActive(true);
						set2FA({ ...is2FA, errorMsgShow: false });
						setAccountLogin(copyAccount);

						alarm.setup();
					} else if (data.status === 'FAILED') {
						set2FA({ ...is2FA, errorMsgShow: true });
					}
				}
			})
			.catch((e) => {
				console.log('handleSend2FA error catch', e.message);
			});

		setVfLoading(false);
	};

	const handleAutoNoteBody = (html, delta, source, editor) => {
		console.log('text', editor.getText());
		console.log('length', editor.getLength()); //minimum is 1\

		if (!ref_quill.current || typeof ref_quill.current === 'undefined') {
			return;
		}

		const length = editor.getLength() - 1;
		const left = limit - length;

		if (left >= 0) {
			setNote({ ...note, numberLeft: left, htmlText: html });
		}

		const quill = ref_quill.current.getEditor();
		quill.on('text-change', function (delta, old, source) {
			if (quill.getLength() > limit) {
				quill.deleteText(limit, quill.getLength());
			}
		});
	};

	const getNumberOfChecked = (selectedCheckers) => {
		let count = 0;
		for (const [key, value] of Object.entries(selectedCheckers)) {
			if (value !== undefined) {
				count++;
			}
		}
		return count;
	};

	const handleSleepTimeInput = (e) => {
		if (!checkAutoNoteParams(e.target.value, withDrawDays)) {
			setSleepTime(e.target.value);
			setAutoNoteParamsValid(false);
			return;
		}
		setSleepTime(parseInt(e.target.value));

		setAutoNoteParamsValid(true);
	};

	const handleWithDrawDaysInput = (e) => {
		if (!checkAutoNoteParams(sleepTime, e.target.value)) {
			setWithDrawDays(e.target.value);
			setAutoNoteParamsValid(false);
			return;
		}
		setWithDrawDays(parseInt(e.target.value));

		setAutoNoteParamsValid(true);
	};

	const handleSendAutoNote = async () => {
		const numberOfWindows = await axios
			.get(url.fetchNumberOfWindows())
			.then(
				(res) => {
					console.log(res.data.message);
					const result = res.data.data;
					return result;
				},
				(error) => {
					console.log('fetchNumberOfWindows error', error);
				}
			)
			.catch((e) => {
				console.log('fetchNumberOfWindows error catch', e.message);
			});

		if (numberOfWindows && numberOfWindows > 14) {
			setWindowAlertOpen(true);
			return;
		}

		const quill = ref_quill.current.getEditor();
		const text = quill.getText();
		console.log('note text', text);

		const talents = [];
		if (filterData && filterData !== undefined && Array.isArray(filterData)) {
			const set = new Set(filterData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					talents.push(talent);
				}
			}
		} else if (resultData && resultData !== undefined && Array.isArray(resultData)) {
			const set = new Set(resultData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					talents.push(talent);
				}
			}
		}

		const project_note_mapping = {
			account: accountLogin.id,
			type: accountLogin.type,
			tagId: tagId,
			sleepTime: sleepTime,
			withDrawDays: withDrawDays,
			note: text,
			accountName: accountLogin.accountName,
			company: accountLogin.company,
			windowsId: accountLogin.windowsId
		};

		setNoteLoading(true);
		setTimeout(() => {
			setNoteLoading(false);
		}, 3000);

		axios
			.post(
				url.sendAutoNote(),
				{
					project_note_mapping,
					talents
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				console.log(res.data.data);
				const count = res.data.data;

				setNumberOfTalentsProcessing(count);
				setAutoNoteConfirmOpen(true);

				alarm.setup();
			})
			.catch((e) => {
				console.log('handleSendAutoNote error catch', e.message);
			});

		//const copyAccounts = Array.from(accounts);
		// for (const each of copyAccounts) {
		// 	if (each.id === accountLogin.id) {
		// 		each.remainingCredits -= talents.length;
		// 		break;
		// 	}
		// }
		//setAccounts(copyAccounts);
		// setAccountLogin((prevState) => ({
		// 	...prevState,
		// 	remainingCredits: prevState.remainingCredits - talents.length
		// }));
		handleAutoNoteClose();

		//open Autonote Send confirmation popup
		setAutoNoteConfirmOpen(true);
	};

	const handleAutoNoteClose = () => {
		setNote({ htmlText: defaultHtmlText, numberLeft: limit });
		setSleepTime(3);
		setWithDrawDays(14);
		setLoginOpen(false);
		toggleAutoNoteTab(0);
	};

	/******************************************** GoodFit & BadFit *********************************/

	const handleGoodFit = (index, selectedRow) => {
		axios
			.post(
				url.setTalentGoodFit(),
				{
					tagId: tagId,
					talentId: selectedRow.id
				},
				config
			)
			.then(
				(res) => {
					console.log(res.data.message);
				},
				(error) => console.log('setTalentGoodFit error', error)
			)
			.catch((e) => {
				console.log('setTalentGoodFit error catch', e.message);
			});

		const copyMainData = mainData.slice();
		copyMainData.splice(index, 1);
		setMainData(copyMainData);
	};

	const handleBadFit = (index, selectedRow) => {
		axios
			.post(
				url.setTalentBadFit(),
				{
					tagId: tagId,
					talentId: selectedRow.id
				},
				config
			)
			.then(
				(res) => {
					console.log(res.data.message);
				},
				(error) => console.log('setTalentBadFit error', error)
			)
			.catch((e) => {
				console.log('setTalentBadFit error catch', e.message);
			});

		const copyMainData = mainData.slice();
		copyMainData.splice(index, 1);
		setMainData(copyMainData);
	};

	const handleRefreshRecommendations = async () => {
		trackPromise(
			axios
				.get(url.refreshRecommendationsForProjectView(tagId))
				.then(
					(res) => {
						console.log(res.data.message);
						const result = res.data.data;
						setMainData(result);
						return res;
					},
					(error) => {
						console.log('refreshRecommendationsForProjectView error', error);
					}
				)
				.catch((e) => {
					console.log('refreshRecommendationsForProjectView error catch', e.message);
				})
		);
	};

	/******************************************** Multiple Selected Talent *************************/
	const isEmpty_emailTalents = (_filterCheckers) => {
		//const flag1 = Object.keys(_filterCheckers).length === 0;

		const filteredCheckedTalents = Object.entries(_filterCheckers)
			.filter(([_, value]) => value !== undefined)
			.filter(([_, value]) =>
				filterData && filterData !== undefined
					? filterData.includes(value)
					: resultData.includes(value)
			);
		const flag2 = filteredCheckedTalents.length === 0;

		return flag2;
	};

	const handleEmailTalentsCheckAll = () => {
		const data = filterData && filterData !== undefined ? filterData : resultData;
		if (isEmpty_emailTalents(filterCheckers)) {
			let local = {};
			data.map((each) => (local[each.id] = each));
			setFilterCheckers({ ...filterCheckers, ...local });

			setNumberOfSelected(data.length);
		} else {
			let local = {};
			data.map((each) => (local[each.id] = undefined));
			setFilterCheckers({ ...filterCheckers, ...local });

			setNumberOfSelected(0);
		}
	};

	const isEmpty_autoNotes = (checkersPerPage) => {
		const _filterCheckersPerPage = checkersPerPage[page - 1];

		let flag1 = false;
		let cnt = 0;
		for (const [_, talent] of Object.entries(_filterCheckersPerPage)) {
			if (mainData.includes(talent)) {
				cnt++;
			}
		}
		flag1 = cnt < mainData.length ? true : false;

		return flag1;
	};

	const handleAutoNotesCheckAll = () => {
		const talentCheckedIds = Object.entries(filterCheckers)
			.filter(([_, value]) => value !== undefined)
			.map(([talentId, _]) => parseInt(talentId));

		const set = new Set(talentCheckedIds);
		let cnt = 0;
		for (const talent of mainData) {
			if (set.has(talent.id)) {
				cnt++;
			}
		}

		// calculate other page number selected
		// const data = filterData && filterData !== undefined ? filterData : resultData;
		// let _numberOfSelected = 0;
		// for (const each of data) {
		// 	if (set.has(each.id)) {
		// 		_numberOfSelected++;
		// 	}
		// }
		const otherPageNumberOfSelected = numberOfSelected - cnt < 0 ? 0 : numberOfSelected - cnt;

		if (isEmpty_autoNotes(checkersPerPage)) {
			let local = {};
			mainData.map((each) => (local[each.id] = each));
			setFilterCheckers({ ...filterCheckers, ...local });

			//filterCheckers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = each));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = otherPageNumberOfSelected + mainData.length;
			setNumberOfSelected(totalNumberOfSelected);
		} else {
			let local = {};
			mainData.map((each) => (local[each.id] = undefined));
			setFilterCheckers({ ...filterCheckers, ...local });

			//filterCheckers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = undefined));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = numberOfSelected - mainData.length;
			setNumberOfSelected(totalNumberOfSelected);
		}
	};

	const handleEmailTalentsCheckbox = (e, id, each) => {
		if (e.target.checked) {
			setFilterCheckers((prevState) => ({ ...prevState, [id]: each }));
			setNumberOfSelected(numberOfSelected + 1);
		} else {
			setFilterCheckers((prevState) => ({ ...prevState, [id]: undefined }));
			setNumberOfSelected(numberOfSelected - 1);
		}
	};

	const handleAutoNotesCheckbox = (e, id, each) => {
		if (e.target.checked) {
			setFilterCheckers((prevState) => ({ ...prevState, [id]: each }));

			//filterCheckers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			copyCurrCheckers[id] = each;
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			setNumberOfSelected(numberOfSelected + 1);
		} else {
			setFilterCheckers((prevState) => ({ ...prevState, [id]: undefined }));

			//filterCheckers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			copyCurrCheckers[id] = undefined;
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			setNumberOfSelected(numberOfSelected - 1);
		}
	};

	const sendBtnVisible = useCallback(() => {
		//filter filterCheckers, the result is final according to the filter data

		let obj = filterData && filterData !== undefined ? {} : filterCheckers;
		if (filterData && filterData.length > 0) {
			const set = new Set(filterData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					obj[talentId] = talent;
				}
			}
		}

		const flag1 = Object.keys(obj).length !== 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent !== undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 && flag2;
	}, [filterCheckers, filterData]);

	const renderSendAllDetail = (resultData) => {
		let sentTalents = [];
		resultData.forEach((talent) => {
			if (talent.history.length > 0) {
				sentTalents.push(talent);
			}
		});

		if (sentTalents.length > 0) {
			sentTalents.sort((p1, p2) => {
				const history1 = p1.history.find((record) => record.sequence === 1);
				const history2 = p2.history.find((record) => record.sequence === 1);

				const time1 = new Date(history1.sent_time);
				const time2 = new Date(history2.sent_time);

				if (time1.getTime() < time2.getTime()) {
					return 1;
				} else if (time1.getTime() > time2.getTime()) {
					return -1;
				}
				return 0;
			});
		}

		return sentTalents.map((talent) => (
			<div className="badge badge-primary badge-pill m-1 font-size-md">{talent.name}</div>
		));
	};

	/****************************************** Functionality Template Start ******************************************/
	const handleAutoNoteTemplate = (e) => {
		const selectedIndex = e.target.getAttribute('data-option-index');
		if (selectedIndex) {
			const selectedTemplate = templates[selectedIndex];
			const htmlBodyContent = selectedTemplate.htmlBody;
			const content = removeHtmlTag(htmlBodyContent);
			const left = content.length <= limit ? limit - content.length : 0;
			setNote({ ...note, numberLeft: left, htmlText: htmlBodyContent });
		} else {
			setNote({ ...note, numberLeft: limit, htmlText: '' });
		}
	};

	const handleTemplate = (e) => {
		const selectedIndex = e.target.getAttribute('data-option-index');
		if (selectedIndex) {
			const filterTemplates = templates.filter(
				(each) => each.irsType === 'autoemails_firstEmail_nonFirst' || each.irsType.length === 0
			);
			const selectedTemplate = filterTemplates[selectedIndex];
			handleLetterBody(selectedTemplate.htmlBody);
			setLetterSubject(selectedTemplate.subject);
			setTemplateType(selectedTemplate.type);
			setTemplateId(selectedTemplate.templateId);
			console.log('selectTemplate', selectedTemplate);
		} else {
			handleLetterBody('');
			setLetterSubject('');
			setTemplateType('');
			setTemplateId(null);
		}
		setTemplateDuplicate(false);
		setTemplateConfirm(false);
	};

	const handleNameFieldInput = (e) => {
		if (e.target.value.length > 0) {
			setTemplateName(e.target.value);
		} else {
			setTemplateName('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (letterSubject.length > 0 && filterHtmlBody.length > 0 && e.target.value.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	const handleLetterSubject = (e) => {
		if (e.target.value.length > 0) {
			setLetterSubject(e.target.value);
		} else {
			setLetterSubject('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (filterHtmlBody.length > 0 && e.target.value.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSubmit = () => {
		if (templateOptions.find((option) => option.name === templateName) !== undefined) {
			//setSnackOpen(true);
			setTemplateConfirm(false);
			setTemplateDuplicate(true);
		} else {
			//setSnackOpen(false);
			post(url.addEmailTemplate(), {
				subject: letterSubject,
				htmlBody: letter.htmlBody,
				name: templateName
			}).then((res) => {
				console.log('after save template', res.data);

				templates.push(res.data);
				setTemplates(templates);

				templateOptions.push({ type: res.data.type, name: res.data.name, irsType: '' });
				setTemplateOptions(templateOptions);

				setTemplateConfirm(true);
				setTemplateDuplicate(false);
			});
		}
	};

	/****************************************** Functionality Template End ******************************************/

	/*************************************************** AutoConnect Start *******************************************/
	const [initialEmail, setInitialEmail] = useState({ subject: '', htmlBody: '' });
	const [followEmail, setFollowEmail] = useState({ subject: '', htmlBody: '' });
	const [noteNewconn, setNoteNewConn] = useState(null);

	const [isAutoDisabled_initial, setAutoDisabled_initial] = useState(true);
	const [isAutoDisabled_action_firstEmail, setAutoDisabled_action_firstEmail] = useState(true);
	const [isAutoDisabled_action_extraEmail, setAutoDisabled_action_extraEmail] = useState(true);
	const [isAutoDisabled_action_last_email, setAutoDisabled_action_lastEmail] = useState(false);
	const [isAutoDisabled_action_single_email, setAutoDisabled_action_singleEmail] = useState(false);
	const [isAutoDisabled, setAutoDisabled] = useState(true);

	const [isFollowUpEmailActionValid, setFollowUpEmailActionDisabled] = useState(true);
	const [isExtraEmailActionValid, setExtraEmailActionDisabled] = useState(true);
	const [isLastEmailActionValid, setLastEmailActionDisabled] = useState(true);
	const [isSingleEmailActionValid, setSingleEmailActionDisabled] = useState(true);

	const [autoLoading, setAutoLoading] = useState(false);
	const timer = useRef();

	const ref_initial = useRef();
	const ref_action_firstEmail = useRef(); //first_action_email
	const ref_action_extraEmail = useRef(); // extra email
	const initial_time = { hour: 24, min: 37 };
	const initial_timestamp = '24:37:00';
	const initial_action = {
		[EmailSequenceType.ACTION_FIRST_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_LAST_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		}
	};
	const [action, setAction] = useState(initial_action);

	const actionTypeOptions = [
		{ type: ActionType.NOREPLY, value: 'Opened w/o reply' },
		{ type: ActionType.UNREAD, value: 'Unopened' }
	];

	const initial_actionTypeValues = {
		[EmailSequenceType.ACTION_FIRST_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_EXTRA_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_LAST_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_SINGLE_EMAIL]: [actionTypeOptions[0]]
	};
	const [actionTypeValues, setActionTypeValues] = useState(initial_actionTypeValues);

	const initial_action_firstEmail_time = initial_time;
	const initial_action_firstEmail_timestamp = initial_timestamp;
	const [action_firstEmail_time, setAction_firstEmail_time] = useState(
		initial_action_firstEmail_time
	);
	const [timestamp_action_firstEmail, setTimestamp_action_firstEmail] = useState(
		initial_action_firstEmail_timestamp
	);
	const [error_action_firstEmail_time, setError_action_firstEmail_time] = useState(false);
	const [action_firstEmail, setActionFirstEmail] = useState(true);

	const initial_extraEmail_time = initial_time;
	const initial_extraEmail_timestamp = initial_timestamp;
	const [extraEmail_time, setExtraEmail_time] = useState(initial_extraEmail_time);
	const [timestamp_extraEmail, setTimestamp_extraEmail] = useState(initial_extraEmail_timestamp);
	const [error_extraEmail_time, setError_extraEmail_time] = useState(false);
	const [isExtraEmail, setExtraEmail] = useState(true);

	const initial_action_lastEmail_time = initial_time;
	const initial_action_lastEmail_timestamp = initial_timestamp;
	const [action_lastEmail_time, setAction_lastEmail_time] = useState(initial_action_lastEmail_time);
	const [timestamp_lastEmail, setTimestamp_lastEmail] = useState(
		initial_action_lastEmail_timestamp
	);
	const [error_action_lastEmail_time, setError_action_lastEmail_time] = useState(false);
	const [action_lastEmail, setActionLastEmail] = useState(true);

	const initial_action_singleEmail_time = initial_time;
	const initial_action_singleEmail_timestamp = initial_timestamp;
	const [action_singleEmail_time, setAction_singleEmail_time] = useState(
		initial_action_singleEmail_time
	);
	const [timestamp_singleEmail, setTimestamp_singleEmail] = useState(
		initial_action_singleEmail_timestamp
	);
	const [error_action_singleEmail_time, setError_action_singleEmail_time] = useState(false);
	const [action_singleEmail, setActionSingleEmail] = useState(true);

	const [strategyId, setStrategyId] = useState(1);

	const handleFollowUpEmailAction = () => {
		setFollowUpEmailActionDisabled(!isFollowUpEmailActionValid);
		setActionFirstEmail(!action_firstEmail);

		if (!action_firstEmail) {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_FIRST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_FIRST_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_FIRST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_FIRST_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleExtraEmailAction = () => {
		setExtraEmailActionDisabled(!isExtraEmailActionValid);
		setExtraEmail(!isExtraEmail);

		if (!isExtraEmail) {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_EXTRA_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_EXTRA_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleLastEmailAction = () => {
		setLastEmailActionDisabled(!isLastEmailActionValid);
		setActionLastEmail(!action_lastEmail);

		if (!action_lastEmail) {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_LAST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_LAST_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_LAST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_LAST_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleSingleEmailAction = () => {
		setSingleEmailActionDisabled(!isSingleEmailActionValid);
		setActionSingleEmail(!action_singleEmail);

		if (!action_singleEmail) {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_SINGLE_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			setAction((prevState) => ({
				...prevState,
				[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_SINGLE_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleChangeActionType = (emailSequence, values) => {
		setAction((prevState) => ({
			...prevState,
			[emailSequence]: {
				...prevState[emailSequence],
				[ActionLabel.ACTION_TYPE]: values.map((each) => each.type)
			}
		}));
		setActionTypeValues((prevState) => ({
			...prevState,
			[emailSequence]: values
		}));
	};

	const handleAutoConnect = async () => {
		console.log('handle auto connect....');
		console.log('action ', action);

		let obj = (filterData && filterData !== undefined) > 0 ? {} : filterCheckers;
		if (filterData && filterData.length > 0) {
			const set = new Set(filterData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					obj[talentId] = talent;
				}
			}
		}

		//Remove duplicate talents
		let finalObj = {};
		const selectedTalents = Object.values(duplicateCheckers).filter(
			(each) => typeof each !== 'undefined'
		);
		if (selectedTalents.length > 0) {
			const selectedTalentsId = selectedTalents.map((each) => each.id);
			for (const [talentId, talent] of Object.entries(obj)) {
				const talentId_integer = parseInt(talentId);
				if (!selectedTalentsId.includes(talentId_integer)) {
					finalObj[talentId] = talent;
				}
			}
		} else {
			finalObj = obj;
		}

		//Send Email
		for (const [key, value] of Object.entries(finalObj)) {
			if (value !== undefined && !letter.candidateEmailMapping.includes(parseInt(key))) {
				letter.candidateEmailMapping.push(parseInt(key));
			}
		}

		//Check File size
		const flag1 = ref_initial.current.validateFiles();
		let flag2 = true;
		if (action_firstEmail && ref_action_firstEmail.current) {
			flag2 = ref_action_firstEmail.current.validateFiles();
		}
		let flag3 = true;
		if (isExtraEmail && ref_action_extraEmail.current) {
			flag3 = ref_action_extraEmail.current.validateFiles();
		}
		if (!(flag1 && flag2 && flag3)) return;

		//Check scheduler settings
		if (action_firstEmail && !checkScheduleTime(action_firstEmail_time)) {
			setError_action_firstEmail_time(true);
			return;
		} else {
			setError_action_firstEmail_time(false);
		}
		if (isExtraEmail && !checkScheduleTime(extraEmail_time)) {
			setError_extraEmail_time(true);
			return;
		} else {
			setError_extraEmail_time(false);
		}
		if (action_lastEmail && !checkScheduleTime(action_lastEmail_time)) {
			setError_action_lastEmail_time(true);
			return;
		} else {
			setError_action_lastEmail_time(false);
		}

		if (!autoLoading) {
			setAutoLoading(true);
		}

		const groupInfo = ref_initial.current.fetchGroupEmail();
		//include findCandidates, maxStrategyId, maxBatchSeqId ,(findCandidates have strategyId, taskId Info)
		const info = await post(url.sendEmail(), {
			...groupInfo,
			candidateEmailMapping: letter.candidateEmailMapping,
			tagId: tagId,
			action: action,
			emailMethod: 'EMAIL_SEQUENCE'
		})
			.then((res) => {
				return res.data; //return findCandidates, maxStrategyId
			})
			.then((res) => {
				console.log('after handleAutoConnect sendemail', res);
				return res;
			})
			.catch((e) => console.error('Send email has error', e));

		//Scheduler
		const connectActions = [];
		if (action.ACTION_FIRST_EMAIL.active && ref_action_firstEmail.current) {
			const data1 = ref_action_firstEmail.current.setSchedule();
			connectActions.push(data1);
		}
		if (action.ACTION_EXTRA_EMAIL.active && ref_action_extraEmail.current) {
			const data2 = ref_action_extraEmail.current.setSchedule();
			connectActions.push(data2);
		}
		let actionLastEmail = {};
		if (action.ACTION_LAST_EMAIL.active) {
			actionLastEmail = {
				scheduler: action_lastEmail_time,
				interval: timestamp_lastEmail,
				emailType: EmailSequenceType.ACTION_LAST_EMAIL
			};
		}

		if (
			action.ACTION_FIRST_EMAIL.active ||
			action.ACTION_EXTRA_EMAIL.active ||
			action.ACTION_LAST_EMAIL.active
		) {
			post(url.handleAutoConnect(), {
				connectActions,
				findCandidates: info.findCandidates,
				actionLastEmail: actionLastEmail,
				action: action,
				batchSeqId: info.maxBatchSeqId,
				tagId: tagId
			})
				.then((res) => {
					//return the last strategyId inserted + 1
					console.log('after handleAutoConnect autoConnect', res);
					setStrategyId(res.data);
				})
				.catch((e) => {
					console.error('Send Auto Connection fail', e);
				});
		}

		setAutoLoading(false);
		handleClose();
	};

	/*************************************************** AutoConnect End *******************************************/

	/*************************************  Duplicate Check Data & Email Sent Candidate Data *********************/
	const dataConnect = () => {
		let obj = filterData && filterData !== undefined ? {} : filterCheckers;
		if (filterData && filterData.length > 0) {
			const set = new Set(filterData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					obj[talentId] = talent;
				}
			}
		}
		const data = Object.values(obj).filter((each) => typeof each !== 'undefined');
		return data;
	};

	/*************************************************** Duplicate Check ************************************/
	const isEmptyDuplicateTalents = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent === undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 || flag2;
	};

	const isEmptyTalentsRemove = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent === undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 || flag2;
	};

	const handleAutoCheck = () => {
		const selectedTalents = Object.values(duplicateCheckers).filter(
			(each) => typeof each !== 'undefined'
		);

		setConfirmLoading(true);

		const data = dataConnect();
		if (selectedTalents.length < data.length) {
			setTimeout(() => {
				setConfirmLoading(false);
				setDialogMessageConfirmOpen(false);

				//close tab1
				setDuplicateTab1Open(false);
				//close duplicate popup
				setGroupDuplicateOpen(false);

				//open email send page
				setGroupEmailOpen(true);
				setSendAll(true);
				setActiveActionTab('0');
			}, 3000);
		} else {
			setTimeout(() => {
				setConfirmLoading(false);
				handleDuplicateClose();
			}, 3000);
		}
	};

	const handleReadySendEmails = () => {
		//alert("Ready send emails....");
		setGroupEmailOpen(true);
		setSendAll(true);
		setActiveActionTab('0');

		//close duplicate popup
		handleDuplicateClose();
	};

	const [dupData, setDupData] = useState([]); // 添加一个状态来存储从后端获取的数据
	const [isCheckingDup, setIsCheckingDup] = useState(false); // API调用的检查状态
	const [isOutOfQuota, setIsOutOfQuota] = useState(true);
	const [remainingQuota, setRemainingQuota] = useState(0);
	useEffect(() => {
		if (groupDuplicateOpen && duplicateTab1Open) {
			const postData = async () => {
				const originalData = dataConnect(); // 获取数据
				const userId = memory.user.id;
				// console.log('1fasdfsadfasfasdfasfsdfasfasdf', userId)
				const dataToSend = {
					userId: userId,
					candidates: originalData.map((item) => ({
						id: item.id,
						linkedin: item.linkedin,
						email: item.email
					}))
				};
				console.log('发送数据： ', dataToSend);
				// setIsCheckingDup(true);
				try {
					const response = await axios.post(url.checkDupTag(), dataToSend, {
						headers: {
							'Content-Type': 'application/json'
						},
						timeout: 300000
					});

					if (response.status === 200) {
						const { data: responseData, message, status } = response.data;

						const actualDataArray = response.data.data.data;
						const isOutOfQuotaResult = response.data.data.isOutOfQuotaResult;
						setIsOutOfQuota(isOutOfQuotaResult);
						const remainingEmailQuota = response.data.data.remainingEmailQuota;
						setRemainingQuota(remainingEmailQuota);
						const updatedData = originalData.map((item) => {
							// 现在，我们使用 actualDataArray 来查找更新项
							const updatedItem = actualDataArray.find((updated) => updated.id === item.id);
							// 如果找到了更新项，合并 dupTag；如果没有找到，保留原始项的 dupTag 或默认为空数组
							return { ...item, dupTag: updatedItem ? updatedItem.dupTag : item.dupTag || [] };
						});
						// console.log('after merge: ', updatedData);
						setDupData(updatedData); // 使用更新后的数据更新状态

						const dupCount = updatedData.filter((item) => item.dupTag && item.dupTag.length > 0)
							.length;
						setDuplicateNum(dupCount);

						const newCheckers = {};
						updatedData.forEach((item) => {
							if (item.dupTag && item.dupTag.length > 0) {
								// 只选择标记过dupTag的数据
								newCheckers[item.id] = item;
							}
						});
						setDuplicateCheckers(newCheckers);
						setDuplicateSelectedNum(Object.keys(newCheckers).length);
					}
				} catch (error) {
					console.error('Error posting data: ', error);
				} finally {
					setIsCheckingDup(false); // 完成检查，设置为false
				}
			};

			postData();
		}
	}, [groupDuplicateOpen, duplicateTab1Open]); // 当这两个状态变化时触发

	// jimmy测试，渲染不同dup标签里的数据
	const renderRmrRows = () => {
		const data = dataConnect();
		console.log('这是该project中所有的数据: ', data);
		console.log('这是标记过dupTag后的数据: ', dupData);

		// const duplicateData = dummyData;
		// const DupCount = dupData
		// 	.filter(item => item.dupTag && item.dupTag.length > 0).length
		// setDuplicateNum(5);

		const filteredData = dupData
			.filter((item) => item.dupTag && item.dupTag.length > 0) // 确保dupTag存在且不为空
			.filter((item) => item.dupTag.includes(currentTab));
		console.log('这是过滤后的数据: ', filteredData);

		return filteredData.map((each, index) => {
			const { id, name, companies, seniority, linkedin } = each;
			const isDisabled = each.dupTag.includes('compCdd') || each.dupTag.includes('rmr'); // 检查是否包含compCdd

			return (
				<tr key={id}>
					<td className="text-center">
						<Checkbox
							// defaultChecked
							checked={typeof duplicateCheckers[id] !== 'undefined'}
							disabled={isDisabled}
							color="primary"
							className="align-self-start"
							onChange={(e) => {
								if (e.target.checked) {
									setDuplicateCheckers((prevState) => ({ ...prevState, [each.id]: each }));
									setDuplicateSelectedNum((prevState) => prevState + 1);
								} else if (!isDisabled) {
									// 只有当不是禁用状态时才允许更改
									setDuplicateCheckers((prevState) => ({ ...prevState, [each.id]: undefined }));
									setDuplicateSelectedNum((prevState) => prevState - 1);
								}
							}}
						/>
					</td>
					<td>{name}</td>
					<td>
						{companies
							.filter((comp) => comp.isCurrent === 1)
							.map((item, index) => (
								<div key={index}>{item.company}</div>
							))}
					</td>
					<td>
						{companies
							.filter((comp) => comp.isCurrent === 1)
							.map((item, index) => (
								<div key={index}>{item.position}</div>
							))}
					</td>
					<td>{seniority ? seniority.substring(4) : ''}</td>
					<td>
						<a href={`${linkedin}`} target="_blank" rel="noopener noreferrer">
							{linkedin}
						</a>
					</td>
				</tr>
			);
		});
	};

	const renderDuplicateRows = () => {
		//filterData is the part of resultData; filterCheckers is all checkers of all data

		//if duplicate check in progress
		if (
			!linkedinDuplicateStatus ||
			typeof linkedinDuplicateStatus === 'undefined' ||
			Object.keys(linkedinDuplicateStatus).length === 0
		) {
			return (
				<tr key={0}>
					<td className="text-center" colSpan={7}>
						<div className="w-100 mt-5" style={{ height: 100 }}>
							Duplicate check is in progress, it estimates about{' '}
							{Math.ceil((resultData.length * 0.7) / 60)} minutes to complete.
						</div>
					</td>
				</tr>
			);
		}

		//if duplicate check completed
		const data = dataConnect();
		console.log('所有候选人：', data);
		data.sort((p1, p2) => {
			//const linkedin1 = p1.linkedin;
			//const bytedance1 = linkedinDuplicateStatus[linkedin1].bytedance;
			//const irs1 = linkedinDuplicateStatus[linkedin1].irs;

			//const linkedin2 = p2.linkedin;
			//const bytedance2 = linkedinDuplicateStatus[linkedin2].bytedance;
			//const irs2 = linkedinDuplicateStatus[linkedin2].irs;
			const linkedin1 = p1.linkedin;
			const bytedance1 = linkedinDuplicateStatus[linkedin1]?.bytedance || false;
			const irs1 = linkedinDuplicateStatus[linkedin1]?.irs || false;

			const linkedin2 = p2.linkedin;
			const bytedance2 = linkedinDuplicateStatus[linkedin2]?.bytedance || false;
			const irs2 = linkedinDuplicateStatus[linkedin2]?.irs || false;
			if (bytedance1 || irs1) {
				return -1;
			} else if (bytedance2 || irs2) {
				return 1;
			}

			return 0;
		});

		// const duplicateData = data.filter(
		// 	(each) =>
		// 		linkedinDuplicateStatus[each.linkedin].bytedance ||
		// 		linkedinDuplicateStatus[each.linkedin].irs
		// );

		const duplicateData = data.filter((each) => {
			const status = linkedinDuplicateStatus[each.linkedin] ?? { irs: false, bytedance: false };
			return status.bytedance || status.irs;
		});
		console.log('duplicated候选人：', duplicateData);

		// setDuplicateNum(duplicatedNumber); d

		return duplicateData ? (
			duplicateData.length > 0 ? (
				duplicateData.map((each, index) => {
					const { id, name, companies, seniority, irsstatus, linkedin } = each;

					return (
						<tr key={id} tabIndex={id}>
							<td className="text-center">
								<Checkbox
									checked={typeof duplicateCheckers[id] !== 'undefined'}
									color="primary"
									className="align-self-start"
									onChange={(e) => {
										if (e.target.checked) {
											setDuplicateCheckers((prevState) => ({ ...prevState, [each.id]: each }));
											setDuplicateSelectedNum((prevState) => prevState + 1);
										} else {
											setDuplicateCheckers((prevState) => ({ ...prevState, [each.id]: undefined }));
											setDuplicateSelectedNum((prevState) => prevState - 1);
										}
									}}
								/>
							</td>
							<td className="text-left pl-3">
								<div>{name}</div>
							</td>
							<td className="text-left pl-2">
								<div className="d-flex flex-column justify-content-center align-items-start">
									{[
										...new Set(
											companies.filter((comp) => comp.isCurrent === 1).map((item) => item.company)
										)
									].map((company) => (
										<div>{company}</div>
									))}
								</div>
							</td>
							<td className="text-left pl-2">
								<div>{seniority ? seniority.substring(4) : ''}</div>
							</td>
							<td className="text-left pl-2">
								<div>{irsstatus ? irsstatus : ''}</div>
							</td>
							<td className="text-left pl-2">
								{linkedinDuplicateStatus[linkedin].irs && (
									<div>
										<img src={require('../../../../assets/images/apps/duplicate.svg')} />
									</div>
								)}
							</td>
							<td className="text-center pl-2">
								{linkedinDuplicateStatus[linkedin].bytedance && (
									<div>
										<img src={require('../../../../assets/images/apps/duplicate.svg')} />
									</div>
								)}
							</td>
						</tr>
					);
				})
			) : (
				<tr key={0}>
					<td className="text-center" colSpan={7}>
						<div className="w-100 mt-5" style={{ height: 100 }}>
							There is no duplicate candidate.
						</div>
					</td>
				</tr>
			)
		) : (
			<></>
		);
	};
	/// save as a new project popup
	const [positions, setPositions] = useState([]); // 定义 positions 状态

	useEffect(() => {
		// 调用 API 获取 positions 数据
		axios
			.get(url.fetchPositionList())
			.then((response) => {
				const data = response.data.data; // 访问嵌套的 data 数组
				console.log('Fetched positions data:', data); // 打印数据进行调试
				// 确保数据是数组类型
				if (Array.isArray(data)) {
					setPositions(data);
				} else {
					console.error('Positions data is not an array:', data);
					setPositions([]); // 设置为空数组以避免错误
				}
			})
			.catch((error) => {
				console.error('Error fetching positions:', error);
			});
	}, []);

	const handleSaveNewProject = async (redirect) => {
		// 这里是保存新项目的逻辑，可以根据需要添加
		const data = dataConnect();
		console.log('这是该project中所有的数据: ', data);

		const talentCheckedIds = data.map((talent) => talent.id);
		const selectedTalents = data;
		const defaultFilters = {
			currentCompany: [],
			location: [],
			openToOpportunities: [],
			achievement: [],
			stacks: [],
			yearsOfWorkingExperiences: [0, 100],
			entrepreneurship: [],
			seniority: [],
			currTenure: [],
			avgTenure: [],
			companies: { type: 'OR', options: [] },
			degree: [],
			preferredLocation: [],
			department_2: [],
			institute: [],
			diversity: [],
			language: [],
			skill: '',
			industry: { type: 'OR', options: [] },
			positionType: []
		};
		const defaultPosition = {
			positionId: selectedPosition?.positionId || 0 // 确保 positionId 存在
		};

		await axios
			.post(
				url.saveNewProject(),
				{
					projectName: newProject.projectName,
					talentCheckedIds: talentCheckedIds,
					selectedTalentsData: selectedTalents,
					filters: defaultFilters, // 替换为空内容
					position: selectedPosition,
					uploadFile: {} // 替换为空内容
				},
				config // 使用包含令牌的配置对象
			)
			.then((res) => {
				console.log(res.data.message);

				// 关闭对话框
				setNewProject({ open: false, projectName: null });

				// // 直接跳转到 /savedprojects 页面
				// history.push('/savedprojects');
			})
			.catch((e) => console.error('Save new project has error', e));
	};
	console.log('see see potition dta', positions);

	const handleNewProjectTextInput = (e) => {
		setNewProject({ ...newProject, projectName: e.target.value });
	};
	const [selectedPosition, setSelectedPosition] = useState(null);

	const dummyPositions = [
		{ positionId: 1, name: 'Software Engineer', clientCompany: 'Company A' },
		{ positionId: 2, name: 'Data Scientist', clientCompany: 'Company B' },
		{ positionId: 3, name: 'Product Manager', clientCompany: 'Company A' }
	];

	const renderContactedRows = () => {
		return contactTalents ? (
			contactTalents.map((each, index) => {
				const { id, name, companies, seniority, history, irsstatus } = each;

				//const sent_localTZ = transformDateToLocalTZ(sent_time);

				return (
					<tr key={id} tabIndex={id}>
						<td className="text-center">
							<Checkbox
								checked={typeof contactCheckers[id] !== 'undefined'}
								color="primary"
								className="align-self-start"
								onChange={(e) => {
									if (e.target.checked) {
										setContactCheckers((prevState) => ({ ...prevState, [each.id]: each }));
										setContactSelectedNum((prevState) => prevState + 1);
									} else {
										setContactCheckers((prevState) => ({ ...prevState, [each.id]: undefined }));
										setContactSelectedNum((prevState) => prevState - 1);
									}
								}}
							/>
						</td>
						<td className="text-left pl-3">
							<div>{name}</div>
						</td>
						<td className="text-left pl-2">
							<div className="d-flex flex-column justify-content-center align-items-start">
								{[
									...new Set(
										companies.filter((comp) => comp.isCurrent === 1).map((item) => item.company)
									)
								].map((company) => (
									<div>{company}</div>
								))}
							</div>
						</td>
						<td className="text-left pl-2">
							<div>{seniority ? seniority.substring(4) : ''}</div>
						</td>
						<td className="text-left pl-2">
							{Array.isArray(history) &&
								history.some((each) => each.bounce === 0 && each.sent === 1) && (
									<DoneIcon fontSize="small" color="primary" />
								)}
						</td>
						<td className="text-left pl-2">
							{Array.isArray(history) &&
								history.some((each) => each.bounce === 0 && each.open === 1 && each.open_time) && (
									<div>
										{transformDateToLocalTZ(
											history.filter((each) => each.bounce === 0 && each.open === 1)[0].open_time
										)}
									</div>
								)}
						</td>
						<td className="text-left pl-2">
							<div>{irsstatus ? irsstatus : ''}</div>
						</td>
					</tr>
				);
			})
		) : (
			<></>
		);
	};

	const handleDuplicateClose = () => {
		setGroupDuplicateOpen(false);
		setDuplicateTab1Open(false);
		setDuplicateTab2Open(false);

		setDuplicateCheckers({});
		setDuplicateNum(0);
		setDuplicateSelectedNum(0);
		setDuplicateTotalNum(0);

		setContactCheckers({});
		setContactTotalNum(0);
		setContactTalents([]);
		setContactSelectedNum(0);

		setDialogMessageConfirmOpen(false);
	};

	/****************************************** React Life Cycle ******************************************/
	const loadProjectTalents = () => {
		if (activeTab === 0 || activeTab === 2) {
			const a = async function () {
				trackPromise(
					axios
						.post(url.fetchTalentsByProject(), {
							tagId: tagId,
							isTalentView: activeTab === 0
						})
						.then((res) => {
							console.log(res.data.message);
							const result = res.data.data;

							setPage(1);
							const local = result;
							setResultData(result);
							setFilterData(null);
							setMainData(local.slice(rowCount - rowCount, rowCount));
							setFilterCheckers({});
							setNumberOfSelected(0);
							setTotalCount(result.length);

							setDataByFilters([]);
							setDataBySearch([]);
							setSearchWord(null);
							setFilterSearch({ status: [], irsstatus: [], linkedinDuplicateStatus: [] });

							const pageCount = Math.ceil(result.length / rowCount);
							const arrayInit = [...Array(pageCount)];
							arrayInit.fill({});
							setCheckersPerPage(arrayInit);

							setFilterOptions(searchResultOptions(result));

							if (
								larkFlag &&
								activeTab === 2 &&
								larkActiveTab &&
								typeof larkActiveTab !== 'undefined' &&
								larkActiveTab === 2
							) {
								handleFilterSearch(
									null,
									[
										{ key: 4, value: _status.NO_EMAIL },
										{ key: 2, value: _status.SENT },
										{ key: 0, value: _status.NO_ACTION },
										{ key: 3, value: _status.OPEN }
									],
									'status'
								);
								setLarkFlag(false);
							}

							return res;
						})
						.catch((e) => {
							console.error('fetchTalentsByProject error catch', e.message);
							return Promise.reject(e.message);
						})
				);
			};

			a();
		} else if (activeTab === 1) {
			const recommend = async function () {
				const check = await axios
					.get(url.checkRecommendationStandard(tagId))
					.then(
						(res) => {
							console.log(res.data.message);
							const result = res.data.data;
							return result;
						},
						(error) => {
							console.log('checkRecommendationStandard error', error);
						}
					)
					.catch((e) => {
						console.log('checkRecommendationStandard error catch', e.message);
					});

				if (!check) {
					setStandardRecommendation(check);
					setMainData([]);
					return;
				}

				trackPromise(
					axios
						.get(url.refreshRecommendationsForProjectView(tagId))
						.then(
							(res) => {
								console.log(res.data.message);
								const result = res.data.data;
								setMainData(result.length > 0 ? result : []);
								return res;
							},
							(error) => {
								console.error('refreshRecommendationsForProjectView error', error);
							}
						)
						.catch((e) => {
							console.error('refreshRecommendationsForProjectView error catch', e.message);
							return Promise.reject(e.message);
						})
				);
			};

			recommend();
		}
	};

	useEffect(() => {
		if (!projects || typeof projects === 'undefined') return;

		SetFolderSelection(projects);
		const project = projects.find((project) => project.id === tagId);

		if (project && project.path) {
			const path = project.path;
			const email = memory.user.email.trim();
			const idx = path.indexOf(email);

			if (idx > -1) {
				const fileFullName = path.substring(idx + email.length + 1);

				setUploadFile({
					...uploadFile,
					uploadFileName: project.filename, //xxx
					uploadFilePath: path, //email/xxx.pdf
					uploadFileSize: project.fileSize,
					uploadFileFullName: fileFullName //xxx.pdf
				});

				axios
					.post(
						url.copyJDFileToAttachment(),
						{},
						{
							...config,
							params: {
								path: path
							}
						}
					)
					.then(() => {
						const myFile = {
							path: fileFullName,
							name: fileFullName,
							size: project.fileSize
						};
						setMyFiles([myFile]);
						setFile({ filename: [fileFullName], path: [path] });
						//fullFullName: xxx.pdf, path: email/xxx.pdf

						setAutoConnectUploadFile([myFile]);
						setAutoUploadFile({ filename: [fileFullName], path: [path] });

						sessionstorage.setItem('totalSize', myFile.size);
					});
			}
		}
	}, [projects]);

	useEffect(() => {
		loadProjects();
	}, []);

	useEffect(() => {
		loadProjectTalents();
	}, [activeTab]);

	useEffect(() => {
		get(url.fetchCredentialAccounts()).then((res) => {
			console.log(res.data);
			setAccounts(res.data);

			let logins = [...Array(res.data.length)];
			logins.fill(false);
			setLoginLoading(logins);

			const ary = [...Array(res.data.length)];
			ary.fill(false);
			setLoginStatus(ary);
		});
	}, []);

	useEffect(() => {
		const numberLeft = limit - defaultPlaceHolder.length;
		setNote({ ...note, htmlText: defaultHtmlText, numberLeft: numberLeft });
	}, []);

	useEffect(() => {
		goSearchTalents();
	}, [filterSearch]);

	useEffect(() => {
		if (!isTemplateConfirm) {
			setTemplateNameMsg(templateName);
		}
	}, [templateName]);

	useEffect(() => {
		console.log('template loading.....');
		get(url.fetchEmailTemplate()).then((res) => {
			console.log(res.data);
			setTemplates(res.data);

			const templateOptions = res.data.map((item) => {
				const irsType = item.irsType;
				return {
					type: item.type,
					name: item.name,
					irsType: checkValid(irsType) ? irsType : ''
				};
			});
			setTemplateOptions(templateOptions);
		});
	}, []);

	useEffect(() => {
		get(url.fetchMaxStrategyByUser()).then((res) => {
			console.log('Max Strategy', res.data.maxStrategyId);
			if (res.data.maxStrategyId !== null) {
				setStrategyId(res.data.maxStrategyId + 1);
			}
		});
	}, []);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(action_firstEmail_time);
		console.log('time: ', time);
		setTimestamp_action_firstEmail(time);
	}, [action_firstEmail_time.hour, action_firstEmail_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(extraEmail_time);
		console.log('time: ', time);
		setTimestamp_extraEmail(time);
	}, [extraEmail_time.hour, extraEmail_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(action_lastEmail_time);
		console.log('time: ', time);
		setTimestamp_lastEmail(time);
	}, [action_lastEmail_time.hour, action_lastEmail_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(action_singleEmail_time);
		console.log('time: ', time);
		setTimestamp_singleEmail(time);
	}, [action_singleEmail_time.hour, action_singleEmail_time.min]);

	useEffect(() => {
		if (!isAutoDisabled_initial) {
			if (action_firstEmail && isAutoDisabled_action_firstEmail) {
				setAutoDisabled(true);
				return;
			}

			if (isExtraEmail && isAutoDisabled_action_extraEmail) {
				setAutoDisabled(true);
				return;
			}

			if (action_lastEmail && isAutoDisabled_action_last_email) {
				setAutoDisabled(true);
				return;
			}

			setAutoDisabled(false);
		} else {
			setAutoDisabled(true);
		}
	}, [
		isAutoDisabled_initial,
		isAutoDisabled_action_firstEmail,
		isAutoDisabled_action_extraEmail,
		isAutoDisabled_action_last_email,
		action_firstEmail,
		isExtraEmail,
		action_lastEmail
	]);

	useEffect(() => {
		const fn = async function () {
			const projectUrl = window.location.href;
			//const domain = projectUrl.split("#")[0];
			const domain = 'https://app.ocinsights.ai/';
			const _url = projectUrl.split('#')[1];
			const newProjectUrl = domain.concat('#').concat(_url);
			//const linkedins = resultData.map(each => each.linkedin);

			const talents = [];
			for (const each of resultData) {
				const talent = {};
				talent['name'] = each.name;
				talent['email'] = each.email;
				talent['firstName'] = each.fileName;
				talent['linkedin'] = each.linkedin;

				const _companies = each.companies.filter((comp) => comp.isCurrent === 1);
				talent['company'] = [...new Set(_companies.map((item) => item.company))].map(
					(company) => company
				)[0];
				talent['position'] = [...new Set(_companies.map((item) => item.position))].map(
					(position) => position
				)[0];
				talent['connection'] = each.connection;
				talents.push(talent);
			}

			post(url.duplicateCheckTalents(), {
				projectUrl: newProjectUrl,
				talents,
				tagId
			}).then((res) => {
				const message = res.message;
				//const statusData = res.data.data;
				console.log(message);

				//setLinkedinDuplicateStatus(statusData);
			});
		};

		// const func = useCallback(fn, [resultData]);

		if (resultData && resultData.length > 0) {
			if (!(linkedinDuplicateStatus && Object.keys(linkedinDuplicateStatus).length > 0)) {
				post(url.fetchTalentsDuplicateStatus(), {
					tagId: tagId
				}).then((res) => {
					const result = res.data;
					if (!result || typeof result === 'undefined' || result.projectChange) {
						fn();
					}
				});
			}
		}
	}, [resultData]);

	useEffect(() => {
		post(url.fetchPositionEmailTemplate(), {
			tagId: tagId
		}).then((res) => {
			console.log('result data', res.data);

			const result = res.data;
			if (Array.isArray(result) && result.length > 0) {
				//const filterResult = result.filter((each) => each.template_type !== 'autonotes_newConn');

				//single email
				//autoEmail
				//autonote

				for (const each of result) {
					const templateType = each.template_type;
					const subject = each.subject;
					const htmlBodyContent = each.htmlBody;

					if (templateType === 'autoemails_firstEmail_nonFirst') {
						setLetterSubject(subject);
						setLetter((prevState) => ({ ...prevState, htmlBody: htmlBodyContent }));
						setInitialEmail({ subject: subject, htmlBody: htmlBodyContent });
					} else if (templateType === 'autoemails_followEmail_nonFirst') {
						setFollowEmail({ subject: subject, htmlBody: htmlBodyContent });
					} else if (templateType === 'autonotes_newConn') {
						//setNoteNewConn(htmlBodyContent);
						const content = removeHtmlTag(htmlBodyContent);
						const left = content.length <= limit ? limit - content.length : 0;
						setNote({ ...note, numberLeft: left, htmlText: htmlBodyContent });
					}
				}
			}
		});
	}, []);

	/**************************************** React Life Cycle End *************************************************************/

	const renderRows = (data) => {
		return data ? (
			data.map((each, index) => {
				const {
					id,
					email,
					name,
					companies,
					yearsOfWorkingExperiences,
					location,
					stacks,
					institutions,
					department_2,
					irsstatus,
					history,
					linkedinDuplicateStatus
				} = each;

				return (
					<>
						<tr key={id} tabIndex={id}>
							{activeTab === 0 && (
								<td className="text-center">
									<Checkbox
										checked={filterCheckers[id] !== undefined}
										onChange={(e) => handleEmailTalentsCheckbox(e, id, each)}
									/>
								</td>
							)}
							{activeTab === 2 && (
								<td className="text-center">
									<Checkbox
										checked={filterCheckers[id] !== undefined}
										onChange={(e) => handleAutoNotesCheckbox(e, id, each)}
									/>
								</td>
							)}
							<td style={{ width: '10%' }}>
								<div className="d-flex">
									<a
										onClick={(e) => handleClickAction(e, each, '0', index)}
										className="talent font-weight-bold text-black">
										<div>{name}</div>
									</a>
								</div>
							</td>
							<td className="text-left">
								{[
									...new Set(
										companies.filter((comp) => comp.isCurrent === 1).map((item) => item.position)
									)
								].map((position) => (
									<div className="text-break-wrap">{position}</div>
								))}
							</td>
							<td style={{ width: '12%' }} className="text-left text-break-wrap">
								{location.includes('United States')
									? location.split(',').length > 2
										? location.split(',')[0].concat(',', location.split(',')[1])
										: location
									: location}
							</td>
							<td style={{ width: '8%' }} className="text-left text-break-wrap">
								{stacks}
							</td>
							<td className="text-center">{yearsOfWorkingExperiences}</td>
							<td className="text-left pl-3">
								<div className="d-flex flex-column justify-content-center align-items-start">
									{[
										...new Set(
											companies.filter((comp) => comp.isCurrent === 1).map((item) => item.company)
										)
									].map((company) => (
										<div>{company}</div>
									))}
								</div>
							</td>
							<td className="text-left pl-2 text-break-wrap">{department_2}</td>
							<td className="text-left pr-3 pl-2">
								{[
									...new Set(
										institutions.filter((inst) => inst.isHighest === 1).map((item) => item.degree)
									)
								]}
							</td>
							{/* <td className="text-left">{irsstatus}</td> */}
							<td className="text-center">{linkedinDuplicateStatus}</td>
							<td style={{ width: '100px' }} className="text-center">
								{history.some(
									(each) => each.bounce === 0 && each.open === 1 && each.reply === 1
								) ? (
									<div className="d-flex align-items-center justify-content-start">
										<img
											className="img-20 mr-3"
											src={require(`../../../../assets/images/apps/${
												email ? 'email_verified' : 'email_unverified'
											}.png`)}
											onClick={(e) => handleClickAction(e, each, '0', index)}
										/>
										<img
											className="img-20"
											src={require(`../../../../assets/images/apps/replied.png`)}
										/>
									</div>
								) : history.some((each) => each.bounce === 0 && each.open === 1) ? (
									<div className="d-flex align-items-center justify-content-start">
										<img
											className="img-20 mr-3"
											src={require(`../../../../assets/images/apps/${
												email ? 'email_verified' : 'email_unverified'
											}.png`)}
											onClick={(e) => handleClickAction(e, each, '0', index)}
										/>
										<DoneAllIcon fontSize={'small'} color={'primary'} />
									</div>
								) : history.some((each) => each.bounce === 0 && each.sent === 1) ? (
									<div className="d-flex align-items-center justify-content-start">
										<img
											className="img-20 mr-3"
											src={require(`../../../../assets/images/apps/${
												email && email.length > 0 ? 'email_verified' : 'email_unverified'
											}.png`)}
											onClick={(e) => handleClickAction(e, each, '0', index)}
										/>
										<DoneIcon fontSize={'small'} color={'primary'} />
									</div>
								) : history.some(
										(each) =>
											(each.bounce === 0 && each.sent === 0) ||
											(each.bounce === 1 && each.sent === 1)
								  ) ? (
									<div className="d-flex align-items-center justify-content-start">
										<img
											className="img-20 mr-3"
											src={require(`../../../../assets/images/apps/${
												email && email.length > 0 ? 'email_verified' : 'email_unverified'
											}.png`)}
											onClick={(e) => handleClickAction(e, each, '0', index)}
										/>
										<TIMERIMG src={require('../../../../assets/images/apps/timer.png')} />
									</div>
								) : (
									<div className="d-flex align-items-center justify-content-start">
										<img
											className="img-20"
											src={require(`../../../../assets/images/apps/${
												email && email.length > 0 ? 'email_verified' : 'email_unverified'
											}.png`)}
											onClick={(e) => handleClickAction(e, each, '0', index)}
										/>
										<div></div>
									</div>
								)}
							</td>
							<td className="text-left">{email}</td>
						</tr>
					</>
				);
			})
		) : (
			<></>
		);
	};

	/***********************************************************************************************************************
	 * 												SEND EMAIL            														                                       *
	 * *********************************************************************************************************************/

	const [, setMessage] = useState('');
	const [letterSubject, setLetterSubject] = useState('');
	const [creditOpen, setCreditOpen] = useState(false);
	const [emailCreditOpen, setEmailCreditOpen] = useState({
		noEmailCreditOpen: false,
		closeEmailCreditOpen: false,
		shortageEmailCreditOpen: false
	});
	const [letter, setLetter] = useState({
		candidateEmailMapping: [],
		htmlBody: ''
	});

	const handleLetterBody = (html, delta, source, editor) => {
		const filterHtmlBody = removeHtmlTag(html);

		if (filterHtmlBody.length > 0) {
			setLetter((prevState) => ({ ...prevState, htmlBody: html }));
		} else {
			setLetter((prevState) => ({ ...prevState, htmlBody: '' }));
		}

		if (letterSubject.length > 0 && filterHtmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSendEmail = async () => {
		let obj = filterData && filterData !== undefined ? {} : filterCheckers;
		if (filterData && filterData.length > 0) {
			const set = new Set(filterData.map((each) => each.id));
			for (const [talentId, talent] of Object.entries(filterCheckers)) {
				if (set.has(parseInt(talentId))) {
					obj[talentId] = talent;
				}
			}
		}

		//Remove duplicate talents
		let finalObj = {};
		const selectedTalents = Object.values(duplicateCheckers).filter(
			(each) => typeof each !== 'undefined'
		);
		if (selectedTalents.length > 0) {
			const selectedTalentsId = selectedTalents.map((each) => each.id);
			for (const [talentId, talent] of Object.entries(obj)) {
				const talentId_integer = parseInt(talentId);
				if (!selectedTalentsId.includes(talentId_integer)) {
					finalObj[talentId] = talent;
				}
			}
		} else {
			finalObj = obj;
		}

		//add final talents to candidateEmailMapping
		const { candidateEmailMapping, htmlBody } = letter;
		for (const [key, value] of Object.entries(finalObj)) {
			if (value !== undefined && !candidateEmailMapping.includes(parseInt(key))) {
				candidateEmailMapping.push(parseInt(key));
			}
		}

		if (!validateFiles()) {
			return;
		}

		//Check scheduler settings
		if (action_singleEmail && !checkScheduleTime(action_singleEmail_time)) {
			setError_action_singleEmail_time(true);
			return;
		} else {
			setError_action_singleEmail_time(false);
		}
		let actionSingleEmail = {};
		if (action.ACTION_SINGLE_EMAIL.active) {
			actionSingleEmail = {
				scheduler: action_singleEmail_time,
				interval: timestamp_singleEmail,
				emailType: EmailSequenceType.ACTION_SINGLE_EMAIL
			};
		}

		setEmailSendLoading(true);
		await axios
			.post(
				url.sendEmail(),
				{
					subject: letterSubject,
					htmlBody,
					candidateEmailMapping,
					approach: state.approach === 'true',
					filename: file.filename,
					itemname: file.path,
					strategyId,
					templateId,
					opportunity: true,
					tagId,
					actionSingleEmail: actionSingleEmail,
					action,
					emailMethod: 'SINGLE_EMAIL'
				},
				{ withCredentials: true, contentType: 'application/json' }
			)
			.then((res) => {
				const maxStrategyId = res.data.data.maxStrategyId;
				setStrategyId(maxStrategyId);
				setEmailSendLoading(false);
			})
			.catch((e) => console.error('Send email has error', e));

		handleClose();
	};

	//TODO send group email romove comment, send autonote remove comment
	const handleSendGroupEmail = async () => {
		if (!validateFiles()) {
			return;
		}

		//Check scheduler settings
		if (action_singleEmail && !checkScheduleTime(action_singleEmail_time)) {
			setError_action_singleEmail_time(true);
			return;
		} else {
			setError_action_singleEmail_time(false);
		}
		let actionSingleEmail = {};
		if (action.ACTION_SINGLE_EMAIL.active) {
			actionSingleEmail = {
				scheduler: action_singleEmail_time,
				interval: timestamp_singleEmail,
				emailType: EmailSequenceType.ACTION_SINGLE_EMAIL
			};
		}

		//collect receivers
		const { candidateEmailMapping, htmlBody } = letter;
		const selectedTalents = Object.values(duplicateCheckers).filter(
			(each) => typeof each !== 'undefined'
		);
		const linkedins = selectedTalents.map((each) => each.linkedin);
		const data = dataConnect();
		const notAutoCheckTalents = data.filter((each) => !linkedins.includes(each.linkedin));
		for (const each of notAutoCheckTalents) {
			candidateEmailMapping.push(each.id);
		}

		axios
			.post(
				url.sendEmail(),
				{
					subject: letterSubject,
					htmlBody,
					candidateEmailMapping,
					approach: state.approach === 'true',
					filename: file.filename,
					itemname: file.path,
					strategyId,
					templateId,
					opportunity: true,
					tagId,
					actionSingleEmail: actionSingleEmail,
					action,
					emailMethod: 'SINGLE_EMAIL'
				},
				{ withCredentials: true, contentType: 'application/json' }
			)
			.then((res) => {
				const maxStrategyId = res.data.data.maxStrategyId;
				setStrategyId(maxStrategyId);
			})
			.catch((e) => console.error('Send emails has error', e));

		setEmailSendLoading(true);
		timer.current = window.setTimeout(() => {
			setEmailSendLoading(false);
			handleClose();
			handleDuplicateClose();
		}, 5000);
	};

	useEffect(() => {
		if (letterSubject.length > 0 && letter.htmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	}, [letterSubject, letter.htmlBody]);

	const reactQuillRef = useRef(); // Initialize
	const handleCompanyButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{company}');
		quill.setSelection(position + '{company}'.length);
	};
	const handleTitleButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{title}');
		quill.setSelection(position + '{title}'.length);
	};

	const FileUploader = () => {
		const message =
			'Single file size cannot be larger than 5 MB. File type must be one of .pdf, .docx, .rtf, .pptx, .ppt';
		const hiddenFileInput = useRef();
		const [isFileValid, setFileValid] = useState(true);
		const [isLoading, setLoading] = useState(false);
		const [isFileDeleteOpen, setFileDeleteOpen] = useState(false);

		const handleClick = () => {
			hiddenFileInput.current.click();
		};

		const downloadPDF = async () => {
			await axios
				.get(url.fetchJDFile(), {
					params: {
						path: uploadFile.uploadFilePath
					}
				})
				.then((res) => {
					console.log(res.data.data);
					const url = res.data.data;
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.download = uploadFile.uploadFilePath;

					document.body.appendChild(link);
					// // Dispatch click event on the link
					// // This is necessary as link.click() does not work on the latest firefox
					link.dispatchEvent(
						new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						})
					);

					// // Remove link from body
					document.body.removeChild(link);
				});
		};

		const handleChange = async (event) => {
			const fileUploaded = event.target.files[0];
			console.log('file size', fileUploaded.size);
			const MBformat = bytesToMegaBytes(fileUploaded.size, 2);
			const limit = 5;

			// validate file size
			if (MBformat > limit) {
				console.log('over file size 5 MB');
				setFileValid(false);
			} else {
				setFileValid(true);
				setLoading(true);
				//upload to S3
				//pass file to backend parsing api, return filter json
				const email = memory.user.email.trim();
				const path = `${email}/${fileUploaded.name}`;
				const fileFullName = fileUploaded.name;
				const idx = fileFullName.lastIndexOf('.');
				const fileName = fileUploaded.name.substring(0, idx);
				const fileType = fileUploaded.name.substring(idx + 1);

				const uploadConfig = {
					params: {
						path: path,
						contentType: fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data',
						filename: fileName,
						tagId: tagId,
						fileSize: fileUploaded.size
					},
					headers: {
						'Content-Type': fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data',
						'Access-Control-Allow-Origin': '*'
					}
				};

				const attachment = new FormData();
				attachment.append('JDFile', fileUploaded);

				await axios
					.post(url.uploadProjectViewJD(), attachment, uploadConfig)
					.then(() => {
						setUploadFile((prevState) => ({
							...prevState,
							uploadFileName: fileName,
							uploadFilePath: path,
							uploadFileSize: fileUploaded.size,
							uploadFileFullName: fileFullName
						}));
					})
					.catch((e) => console.error('parsing JD has error', e));

				await axios
					.post(
						url.copyJDFileToAttachment(),
						{},
						{
							...config,
							params: {
								path: path
							}
						}
					)
					.then(() => {
						const myFile = {
							path: fileFullName,
							name: fileFullName,
							size: fileUploaded.size
						};
						setMyFiles((prevState) => [...prevState, myFile]);
						setFile({ filename: [...file.filename, fileFullName], path: [...file.path, path] });
						//fullFullName: xxx.pdf, path: email/xxx.pdf

						setAutoConnectUploadFile([myFile]);
						setAutoUploadFile({ filename: [fileFullName], path: [path] });

						sessionstorage.setItem('totalSize', fileUploaded.size);
					});
			}
		};

		const handleDeleteJDFile = async () => {
			const path = uploadFile.uploadFilePath;

			await axios
				.delete(url.deleteJDFile(), {
					...config,
					params: {
						path: path,
						tagId: tagId
					}
				})
				.then(() => {
					setUploadFile({
						uploadFileName: null,
						uploadFilePath: null,
						uploadFileSize: 0,
						uploadFileFullName: null
					});
					setMyFiles([]);
					setFile({ filename: [], path: [] });
					setAutoConnectUploadFile([]);
					setAutoUploadFile({ filename: [], path: [] });
					sessionstorage.setItem('totalSize', 0);

					setFileDeleteOpen(false);
				});
		};

		return (
			<>
				<div className="card-header--title d-flex flex-row justify-content-start align-items-center">
					{uploadFile.uploadFileName ? (
						<>
							<div className="pr-1">
								<TITLEIMG src={require('../../../../assets/images/apps/attachment.png')} />
							</div>
							<div className="pr-1 nav-link" onClick={downloadPDF}>
								<b>{uploadFile.uploadFileName}</b>
							</div>
							<div>
								<IMG
									src={require('../../../../assets/images/apps/remove_icon.png')}
									onClick={() => setFileDeleteOpen(true)}
								/>
							</div>
						</>
					) : (
						<>
							<div className="pr-1 nav-link">
								<TITLEIMG
									src={require('../../../../assets/images/apps/upload_icon.png')}
									onClick={handleClick}
								/>
								<input
									type="file"
									ref={hiddenFileInput}
									onChange={handleChange}
									style={{ display: 'none' }}
									accept=".pdf,.docx,.doc,.rtf,.pptx,.ppt"
								/>
							</div>
							<div className="pr-1 nav-link" onClick={handleClick}>
								<b>Upload a Job Description</b>
							</div>
							{isLoading && (
								<div className="text-center">
									<CircularProgress size={26} className={classes.uploadButtonProgress} />
								</div>
							)}
							{!isFileValid && (
								<Alert severity="warning" className="text-center">
									{message}
								</Alert>
							)}
						</>
					)}
				</div>
				<Dialog
					open={isFileDeleteOpen}
					onClose={() => setFileDeleteOpen(false)}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description">
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{`Are you sure you want to delete this file ${uploadFile.uploadFileName} ?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setFileDeleteOpen(false)} color="primary" variant="outlined">
							Cancel
						</Button>
						<Button
							className={classes.containedPrimary}
							color="primary"
							variant="contained"
							onClick={handleDeleteJDFile}>
							Sure
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	return (
		<>
			<Card className="card-box mb-spacing-6-x2">
				<div className="card-header pr-2">
					<div className="card-header--title d-flex justify-content-start align-items-center">
						<span className="pr-2">
							<b>{projectName}</b>
							{/* <b>type: {projectType}</b> */}
						</span>
						<FileUploader />
					</div>

					<div className="card-header--actions mx-2">
						<NavLink activeClassName="active" className="nav-link-simple" to={`/recruit`}>
							<Button
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue">
								<span className="btn-wrapper--icon">
									<SearchIcon />
								</span>
								<span className="btn-wrapper--label">Search Talents</span>
							</Button>
						</NavLink>
					</div>
					<div className="card-header--actions">
						<NavLink activeClassName="active" className="nav-link-simple" to={`/savedprojects`}>
							<Button
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue active">
								<span className="btn-wrapper--icon">
									<FolderIcon />
								</span>
								<span className="btn-wrapper--label">View Projects</span>
							</Button>
						</NavLink>
					</div>
				</div>

				<Card className="shadow-xxl p-3">
					<List component="div" className="nav-line d-flex nav-tabs-primary">
						<ListItem button disableRipple selected={activeTab === 0} onClick={() => toggle(0)}>
							<span className="text-nowrap">Talents for AutoEmail</span>
							<div className="divider" />
						</ListItem>
						<ListItem button disableRipple selected={activeTab === 2} onClick={() => toggle(2)}>
							<span className="text-nowrap">Talents for AutoNotes</span>
							<div className="divider" />
						</ListItem>
						{/* <ListItem
							className="ml-3"
							button
							disableRipple
							selected={activeTab === 1}
							onClick={() => toggle(1)}>
							<span>Recommendations</span>
							<div className="divider" />
						</ListItem> */}
						{/* {activeTab === 1 && (
							<Grid container spacing={1}>
								<Grid item xs />
								<Grid
									item
									xs={1}
									container
									justifyContent="center"
									alignItems="center"
									alignContent="center">
									{mainData.length === 0 && isStandardRecommendation && (
										<IMG
											src={require('../../../../assets/images/apps/refresh.png')}
											onClick={handleRefreshRecommendations}
										/>
									)}
									{mainData.length === 0 && !isStandardRecommendation && (
										<Tooltip
											title="2 talent pools and 30 candidates are required to get a good-fit recommendation list for your project"
											placement="right-end"
											arrow>
											<IMG src={require('../../../../assets/images/apps/refreshgrey.png')} />
										</Tooltip>
									)}
									{mainData.length > 0 && (
										<Tooltip
											title="After evaluating all the candidates below, Click 'Refresh' to get more recommendation"
											placement="right-end"
											arrow>
											<IMG src={require('../../../../assets/images/apps/refreshgrey.png')} />
										</Tooltip>
									)}
								</Grid>
							</Grid>
						)} */}
						{activeTab === 0 && (
							<>
								<div style={{ width: '80%' }}></div>
								<div
									className={clsx('search-wrapper search-wrapper--grow text-right pr-2 mb-1', {
										'is-active': false
									})}>
									<TextField
										variant="outlined"
										margin="dense"
										onChange={searchHandler}
										value={searchWord}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchTwoToneIcon style={{ color: '#0795fe' }} />
												</InputAdornment>
											),
											type: 'search'
										}}
									/>
								</div>
							</>
						)}
					</List>

					<div className={clsx('tab-item-wrapper', { active: true })} index={0}>
						<div className="my-2">
							{(activeTab === 0 || activeTab === 2) && (
								<Grid container item xs={2} justifyContent="flex-start" className="py-1">
									<div>
										{totalCount ? totalCount : 0} Total, {numberOfSelected} Selected
									</div>
								</Grid>
							)}
							<CardContent className="p-0">
								<div className="table-responsive-sm">
									<Table
										size={'small'}
										className="text-wrap mb-0 table-hover table-borderless font-size-sm">
										<thead className="thead">
											<tr className={'text-left'}>
												{activeTab === 0 && (
													<th className="text-center">
														<Button
															onClick={handleEmailTalentsCheckAll}
															className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark">
															All
														</Button>
													</th>
												)}
												{activeTab === 2 && (
													<th className="text-center">
														<Checkbox
															checked={
																mainData && mainData.length > 0
																	? Object.values(filterCheckers).filter(
																			(each) =>
																				each !== undefined &&
																				mainData.map((each) => each.id).includes(each.id)
																	  ).length === mainData.length
																	: false
															}
															onChange={handleAutoNotesCheckAll}
															disabled={!mainData || mainData.length === 0}
														/>
													</th>
												)}
												<th>Talents</th>
												<th style={{ width: 200 }}>Job Title</th>
												<th>Location</th>
												<th>Tech Stacks</th>
												<th className="text-center">Experience(Yrs)</th>
												<th style={{ width: 100 }} className="pl-3">
													Company
												</th>
												<th style={{ width: 200 }} className="pl-2">
													C-2 Teams
												</th>
												<th className="pl-2">Degree</th>
												{/* <th className="text-left">
													<span>IRS Status</span>
													{filterSearch.irsstatus.length === 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter.png')}
															onClick={(e) => handleIRSClick(e)}
														/>
													) : (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
															onClick={(e) => handleIRSClick(e)}
														/>
													)}
													<Popover
														open={openIRS}
														anchorEl={anchorElIRS}
														onClose={handleIRSClose}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'center'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'center'
														}}>
														<Autocomplete
															className={'filterHeader'}
															multiple
															size="small"
															options={filterOptions.irsstatus}
															getOptionLabel={(option) => option}
															value={filterSearch.irsstatus}
															onChange={(e, value) => handleFilterSearch(e, value, 'irsstatus')}
															renderInput={(params) => <TextField {...params} variant="standard" />}
														/>
													</Popover>
												</th> */}
												<th className="text-center">
													<img
														className="img-20"
														src={require(`../../../../assets/images/apps/linkedin.png`)}
													/>
													<span>SOP</span>
													{filterSearch.linkedinDuplicateStatus.length === 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter.png')}
															onClick={(e) => handleLinkedinDuplicateStatus(e)}
														/>
													) : (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
															onClick={(e) => handleLinkedinDuplicateStatus(e)}
														/>
													)}
													<Popover
														open={openLinkedinDuplicate}
														anchorEl={anchorElLinkedinDuplicate}
														onClose={handleLinkedinDuplicateClose}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'center'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'center'
														}}>
														<Autocomplete
															className={'filterHeader'}
															multiple
															size="small"
															options={filterOptions.linkedinDuplicateStatus}
															getOptionLabel={(option) => option}
															value={filterSearch.linkedinDuplicateStatus}
															onChange={(e, value) =>
																handleFilterSearch(e, value, 'linkedinDuplicateStatus')
															}
															renderInput={(params) => <TextField {...params} variant="standard" />}
														/>
													</Popover>
												</th>
												<th className="text-left">
													<span>Status</span>
													{filterSearch.status.length === 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter.png')}
															onClick={(e) => handleStatusClick(e)}
														/>
													) : (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
															onClick={(e) => handleStatusClick(e)}
														/>
													)}
													<Popover
														open={openStatus}
														anchorEl={anchorElStatus}
														onClose={handleStatusClose}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'center'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'center'
														}}>
														<Autocomplete
															className={'filterHeader'}
															multiple
															size="small"
															options={filterOptions.status}
															getOptionLabel={(option) => option.value}
															value={filterSearch.status}
															onChange={(e, value) => handleFilterSearch(e, value, 'status')}
															renderInput={(params) => <TextField {...params} variant="standard" />}
														/>
													</Popover>
												</th>
												<th className="text-left">
													<span>Email</span>
												</th>
												{/* <th className="text-left">Linkedin</th> */}
											</tr>
										</thead>
										{promiseInProgress ? <></> : <tbody>{renderRows(mainData)}</tbody>}
									</Table>
									{promiseInProgress && <LoadingIndicator />}
								</div>
								<div className="divider" />
								<div className="divider" />
								{(activeTab === 0 || activeTab === 2) && (
									<div className="p-3 d-flex justify-content-center">
										<Pagination
											page={page}
											onChange={handleChangePage}
											className="pagination-first"
											size="small"
											count={
												filterData
													? Math.ceil(filterData.length / rowCount)
													: Math.ceil(resultData.length / rowCount)
											}
										/>
										<TextField
											type="number"
											value={inputPage}
											onChange={(e) => setInputPage(Number(e.target.value))}
											inputProps={{
												min: 1,
												max: filterData
													? Math.ceil(filterData.length / rowCount)
													: Math.ceil(resultData.length / rowCount)
											}}
											style={{ marginLeft: '10px', width: '70px' }}
											size="small"
										/>
										<Button
											variant="contained"
											onClick={() => handleChangePage(null, inputPage)}
											style={{ marginLeft: '10px' }}
											className={classes.containedPrimary}>
											go!
										</Button>
									</div>
								)}
							</CardContent>
						</div>
					</div>
				</Card>
				{sendBtnVisible() && (activeTab === 0 || activeTab === 2) && (
					<div className={'w-100'}>
						{projectType === 'REC_CAN' ? (
							<Button
								disabled={activeTab === 0 ? false : !(accounts && accounts.length > 0)}
								onClick={() => setNewProject({ ...newProject, open: true })}
								className="my-2 mx-1 btn action">
								Save as a New Project
							</Button>
						) : (
							<Button
								disabled={activeTab === 0 ? false : !(accounts && accounts.length > 0)}
								onClick={() => {
									if (activeTab === 0) {
										setGroupDuplicateOpen(true);

										const data = dataConnect();
										setDuplicateTotalNum(data.length);
										setDuplicateTab1Open(true);

										post(url.fetchTalentsDuplicateStatus(), {
											tagId
										}).then((res) => {
											const result = res.data;

											if (!result || typeof result === 'undefined') {
												console.log('duplicate check in progress...');
											} else {
												if (
													result.status === talentDuplicateProcessType.PENDING ||
													result.projectChange
												) {
													console.log('duplicate check in progress...');
													return;
												}
												const talents_status = result.talentsStatus;

												setLinkedinDuplicateStatus(talents_status);
												const duplicatedNumber = data.filter((each) => {
													const status = talents_status[each.linkedin] ?? {
														irs: false,
														bytedance: false
													};
													return status.bytedance || status.irs;
												}).length;
												setDuplicateNum(duplicatedNumber);
											}
										});
									} else if (activeTab === 2) {
										setLoginOpen(true);
									}
								}}
								className="my-2 mx-1 btn action">
								Connect
							</Button>
						)}
						<Button
							className="my-2 mx-1 btn action"
							onClick={() => setDeleteDialogMessageConfirmOpen(true)}>
							Delete
						</Button>
					</div>
				)}
				<Dialog fullWidth maxWidth="sm" open={newProject.open} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Project Name</DialogTitle>
					<DialogContent>
						<TextField
							margin="dense"
							fullWidth
							variant="outlined"
							placeholder="Create a project"
							value={newProject.projectName}
							onChange={handleNewProjectTextInput}
						/>
						<div style={{ height: 8 }} />
						<Autocomplete
							size="small"
							options={positions}
							groupBy={(position) => position.clientCompany}
							getOptionLabel={(position) => position.name}
							getOptionSelected={(option, value) => option.positionId === value.positionId}
							onChange={(event, value) => setSelectedPosition(value)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									label="Choose a position on IRS"
									fullWidth
								/>
							)}
						/>
						<div style={{ height: 8 }} />
						<Button
							disabled={
								!(
									newProject.projectName &&
									typeof newProject.projectName !== 'undefined' &&
									newProject.projectName.length > 0 &&
									selectedPosition &&
									typeof selectedPosition !== 'undefined' &&
									Object.keys(selectedPosition).length > 0
								)
							}
							fullWidth
							className={classes.containedPrimary}
							onClick={() => handleSaveNewProject(true)}>
							Save and view projects
						</Button>
						<div style={{ height: 8 }} />
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setNewProject({ open: false, projectName: null })}
							color="primary"
							variant="outlined">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</Card>

			{/*Dialog for single click*/}
			{selectedRow && (
				<Drawer anchor="right" open={open} onClose={(e) => toggleDrawer(e, false)}>
					<div style={{ width: 1400 }} className="h-100">
						<TalentDialog
							handleClose={handleClose}
							selectedRow={selectedRow}
							activeActionTab={activeActionTab}
							toggleActionTab={toggleActionTab}
							templateOptions={templateOptions}
							handleTemplate={handleTemplate}
							handleLetterSubject={handleLetterSubject}
							letterSubject={letterSubject}
							letter={letter}
							handleLetterBody={handleLetterBody}
							templateType={templateType}
							updateTemplateExpand={setTemplateExpand}
							isTemplateExpand={isTemplateExpand}
							handleNameFieldInput={handleNameFieldInput}
							templateName={templateName}
							isContextEmpty={isContextEmpty}
							handleSubmit={handleSubmit}
							updateTemplateName={setTemplateName}
							updateTemplateConfirm={setTemplateConfirm}
							updateTemplateDuplicate={setTemplateDuplicate}
							isTemplateConfirm={isTemplateConfirm}
							templateNameMsg={templateNameMsg}
							isTemplateDuplicate={isTemplateDuplicate}
							getRootProps={getRootProps}
							getInputProps={getInputProps}
							isDragAccept={isDragAccept}
							isDragReject={isDragReject}
							isDragActive={isDragActive}
							fileRejectionItems={fileRejectionItems}
							isLoading={isLoading}
							files={files}
							state={state}
							handleChange={handleChange}
							templates={templates}
							ref_initial={ref_initial} //initial email
							strategyId={strategyId} //start strategyId
							updateAutoDisabled_initial={setAutoDisabled_initial} //send button disabled by initial
							action_firstEmail={action_firstEmail}
							action_firstEmail_time={action_firstEmail_time} //action for first email
							updateAction_firstEmail_time={setAction_firstEmail_time} //action for first email
							error_action_firstEmail_time={error_action_firstEmail_time} //action for first email
							ref_action_firstEmail={ref_action_firstEmail} //action for first email
							timestamp_action_firstEmail={timestamp_action_firstEmail} //action for first email
							updateAutoDisabled_action_firstEmail={setAutoDisabled_action_firstEmail} //send button disabled by action first email
							updateActionFirstEmail={setActionFirstEmail} //action for first email
							isExtraEmail={isExtraEmail} // extra email
							extraEmail_time={extraEmail_time} // extra email
							updateExtraEmail_time={setExtraEmail_time} //extra email
							ref_extraEmail={ref_action_extraEmail} //extra email
							error_extraEmail_time={error_extraEmail_time} //extra email
							timestamp_extraEmail={timestamp_extraEmail} //extra email
							updateAutoDisabled_extraEmail={setAutoDisabled_action_extraEmail} //send button disabed by extra email
							updateExtraEmail={setExtraEmail} //extra email
							action_lastEmail={action_lastEmail} //action for last email
							action_lastEmail_time={action_lastEmail_time} //action for last email
							updateAction_lastEmail_time={setAction_lastEmail_time} //action for last email
							updateActionLastEmail={setActionLastEmail} //action for last email
							error_action_lastEmail_time={error_action_lastEmail_time} //action for last email
							updateAutoDisabled_last_email={setAutoDisabled_action_lastEmail} //send button last email disabled
							action_singleEmail={action_singleEmail} //action for single email
							action_singleEmail_time={action_singleEmail_time} //action for single email
							updateAction_singleEmail_time={setAction_singleEmail_time} //action for single email
							updateActionSingleEmail={setActionSingleEmail} //action for single email
							error_action_singleEmail_time={error_action_singleEmail_time} //action for single email
							updateAutoDisabled_single_email={setAutoDisabled_action_singleEmail} //send button single email disabled
							activeTab={activeTab}
							folderSelection={folderSelection}
							handleFolderChange={handleFolderChange}
							folders={folders}
							isOppSendDisabled={isOppSendDisabled}
							handleSendEmail={handleSendEmail}
							isAutoDisabled={isAutoDisabled}
							handleAutoConnect={handleAutoConnect}
							autoLoading={autoLoading}
							isFolderUpdate={isFolderUpdate}
							handleUpdateProjects={handleUpdateProjects}
							checkers={filterCheckers}
							handleCheckTalent={handleCheckTalent}
							handleUnCheckTalent={handleUnCheckTalent}
							resultData={resultData}
							ithRow={ithRow}
							handleClickAction={handleClickAction}
							toggleDrawer={toggleDrawer}
							autoConnectUploadFile={autoConnectUploadFile}
							autoUploadFile={autoUploadFile}
							tagId={tagId}
							updateAction={setAction}
							action={action}
							initialEmail={initialEmail}
							followEmail={followEmail}
						/>
					</div>
				</Drawer>
			)}

			{/*Dialog for multiple selected send email*/}
			{groupEmailOpen && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					maxWidth={'md'}
					open={sendAll}
					onClose={handleClose}
					aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						<Grid container style={{ width: '800px', maxWidth: '100%' }}>
							<Grid item xs={12}>
								<span className="text-black font-weight-bold font-size-md w-100">Send All</span>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent className={'p-4'}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									<List component="div" className="nav-line d-flex nav-tabs-primary">
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '0'}
											onClick={() => {
												toggleActionTab('0');
											}}>
											<span>Email</span>
											<div className="divider" />
										</ListItem>
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '1'}
											onClick={() => {
												toggleActionTab('1');
											}}>
											<span>AutoEmail</span>
										</ListItem>
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '2'}
											onClick={() => {
												toggleActionTab('2');
											}}>
											<span>Sent To</span>
										</ListItem>
									</List>

									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '0' })}
										index={0}>
										<DialogContentText>
											<Autocomplete
												id="combo-box-demo"
												size="small"
												options={templateOptions}
												groupBy={(option) => option.type}
												getOptionLabel={(option) => option.name}
												filterOptions={(options, state) =>
													options.filter(
														(each) =>
															each.irsType === 'autoemails_firstEmail_nonFirst' ||
															each.irsType.length === 0
													)
												}
												onChange={handleTemplate}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														placeholder="Search for a template"
														fullWidth
													/>
												)}
											/>
										</DialogContentText>
										<DialogContentText>
											<hr />
											<TextField
												onChange={handleLetterSubject}
												value={letterSubject}
												variant="outlined"
												fullWidth
												name={'subject'}
												id="standard-textarea"
												label="Subject"
												placeholder="[Company] [Opportunity] [Date]"
											/>
											<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
											<ReactQuill
												className={'quillContainer'}
												theme="snow"
												value={letter.htmlBody}
												onChange={handleLetterBody}
												placeholder="Write about your opportunity..."
												ref={reactQuillRef}
											/>
										</DialogContentText>
										<DialogContentText className={'d-flex'}>
											<div className={'ml-auto'}>
												<span style={{ verticalAlign: 'middle', marginRight: '6px' }}>Insert</span>

												<div style={{ display: 'inline-block', marginRight: '0px' }}>
													<Button
														size="small"
														style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
														onClick={handleCompanyButtonClick}>{`{company}`}</Button>
												</div>

												<div
													style={{
														borderLeft: '1px solid #000',
														height: '15px',
														width: '1.5px',
														display: 'inline-block',
														verticalAlign: 'middle',
														margin: '0 5px'
													}}
												/>

												<div style={{ display: 'inline-block', marginLeft: '0px' }}>
													<Button
														size="small"
														style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
														onClick={handleTitleButtonClick}>
														{'{title}'}
													</Button>
												</div>
											</div>
										</DialogContentText>
										<DialogContentText className={'d-flex justify-content-between'}>
											{templateType === 'Base' ? (
												<div className="font-size-xs text-dark">
													* If you use this template, please change the text in all square brackets
													before sending
												</div>
											) : (
												<div className="font-size-xs text-dark"></div>
											)}
											<Link
												className={classes.button}
												component="button"
												underline="none"
												onClick={() => {
													setTemplateExpand((v) => !v);
												}}>
												Save as new template
											</Link>
										</DialogContentText>
										{isTemplateExpand && (
											<>
												<hr />
												<DialogContentText className="mb-0 textField-btn">
													<div style={{ flexGrow: 1 }}>
														<Grid container spacing={6}>
															<Grid item xs={8}>
																<TextField
																	className={'templatename-field'}
																	fullWidth
																	variant="outlined"
																	placeholder="Name of the template"
																	onChange={handleNameFieldInput}
																	value={templateName}
																/>
															</Grid>
															<Grid item xs>
																{!isContextEmpty ? (
																	<Button
																		className={classes.containedPrimary}
																		size="small"
																		variant="contained"
																		color="primary"
																		onClick={handleSubmit}>
																		Save
																	</Button>
																) : (
																	<Button size="small" variant="outlined" disabled>
																		Save
																	</Button>
																)}
																<Button
																	style={{ left: '10px' }}
																	size="small"
																	variant="outlined"
																	onClick={() => {
																		setTemplateExpand(false);
																		setTemplateName('');
																		setTemplateConfirm(false);
																	}}>
																	Cancel
																</Button>
															</Grid>
														</Grid>
													</div>
												</DialogContentText>
												{isTemplateConfirm && (
													<div className="font-size-sm text-success mt-3">
														Save template <strong>{templateNameMsg}</strong> successfully
													</div>
												)}
												{isTemplateDuplicate && (
													<div className="font-size-sm text-danger mt-3">
														There was an error saving your template. Please try another name.
													</div>
												)}
											</>
										)}
										<hr />
										<Grid container direction={'row'}>
											<Grid item md={12}>
												<div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
													<input {...getInputProps()} />
													<div className="dropzone">
														{isDragAccept && (
															<div>
																<IMG
																	src={require('../../../../assets/images/apps/attachment.png')}
																/>
																<div className="font-size-sm text-success">
																	All files will be uploaded!
																</div>
															</div>
														)}
														{isDragReject && (
															<div>
																<IMG
																	src={require('../../../../assets/images/apps/attachment.png')}
																/>
																<div className="font-size-sm text-danger">
																	Some files will be rejected!
																</div>
															</div>
														)}
														{!isDragActive && (
															<IMG src={require('../../../../assets/images/apps/attachment.png')} />
														)}
														<div style={{ width: '2px' }} />
														<div className="font-weight-bold text-uppercase text-dark font-size-sm">
															Uploaded Files
														</div>
														<div style={{ width: '2px' }} />
														<div className="font-size-xs text-dark">
															* A file name cannot contain commas
														</div>
													</div>
												</div>
											</Grid>
											<Grid item md={12}>
												<div>
													{fileRejectionItems}
													{isLoading && files.length == 0 ? (
														<div className={classes.spinner}>
															<CircularProgress />
														</div>
													) : (
														<List component="div" className="font-size-sm">
															{files}
														</List>
													)}
												</div>
											</Grid>
										</Grid>
										<hr />
										<div className={'font-size-sm mt-4'}>
											<div>{memory.user.name}</div>
											<div>
												{memory.user.position} | {memory.user.customername}
											</div>
											<div>{memory.user.email}</div>
											<div>{memory.user.phone}</div>
											<div>{memory.user.location}</div>
											<a href={`${memory.user.linkedin}`}>LinkedIn</a>
										</div>
										<hr />
										<div className="d-flex flex-row justify-content-between">
											<div style={{ width: '90%' }}>
												<b>Actions for email</b>
											</div>
											<div style={{ width: '10%' }}>
												<div className="d-flex flex-row justify-content-end">
													{!isSingleEmailActionValid && (
														<Link
															component="button"
															underline="none"
															onClick={handleSingleEmailAction}>
															<AddIcon />
														</Link>
													)}
													{isSingleEmailActionValid && (
														<Link
															component="button"
															underline="none"
															onClick={handleSingleEmailAction}>
															<RemoveIcon />
														</Link>
													)}
												</div>
											</div>
										</div>
										{action_singleEmail && (
											<>
												<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
													<div>
														<Autocomplete
															size="small"
															multiple
															options={actionTypeOptions}
															getOptionLabel={(option) => option.value}
															style={{ width: 220 }}
															value={actionTypeValues[EmailSequenceType.ACTION_SINGLE_EMAIL]}
															getOptionSelected={(option, value) => option.type === value.type}
															onChange={(e, values) =>
																handleChangeActionType(
																	EmailSequenceType.ACTION_SINGLE_EMAIL,
																	values
																)
															}
															renderInput={(params) => <TextField {...params} variant="outlined" />}
														/>
													</div>
													<div className="ml-1 mr-1">in</div>
													<div>
														<input
															style={{ width: '30px' }}
															value={action_singleEmail_time.hour}
															onChange={(e) => {
																e.persist();
																setAction_singleEmail_time((prevState) => ({
																	...prevState,
																	hour: e.target.value
																}));
															}}
														/>
													</div>
													<div className="ml-1 mr-1">hours </div>
													<div>
														<input
															style={{ width: '30px' }}
															value={action_singleEmail_time.min}
															onChange={(e) => {
																e.persist();
																setAction_singleEmail_time((prevState) => ({
																	...prevState,
																	min: e.target.value
																}));
															}}
														/>
													</div>
													<div>minutes,</div>
												</div>
												<div>then a lark notification will be sent for AutoNotes</div>
												{error_action_singleEmail_time && (
													<>
														<div className={'d-flex'}>
															<div className="font-size-sm text-danger font-italic">
																X Error:&nbsp;
															</div>
															<div className="font-size-sm font-italic">
																Can not be blank and can not be less than 24
															</div>
														</div>
														<div className={'d-flex mb-2'}>
															<div className="font-size-sm text-danger font-italic">
																X Error:&nbsp;
															</div>
															<div className="font-size-sm font-italic">
																Can not be blank and can not be a number outside 00 - 59
															</div>
														</div>
													</>
												)}
											</>
										)}
										{state.approach === 'true' && (
											<DialogContentText className="mb-0">
												<hr />
												<Alert className="alerts-alternate mb-4" severity="#046eb8">
													<div className="d-flex align-items-center align-content-start">
														<span>
															Our recruiters will reach out to candidates via all approaches (Email,
															Phone, LinkedIn and social media) and notify you about their status.
														</span>
													</div>
													<div>Credits Left: {memory.user.credits}</div>
												</Alert>
											</DialogContentText>
										)}
									</div>
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '1' })}
										index={1}>
										<div style={{ maxHeight: 665, overflowY: 'auto' }} className="p-4">
											<AutoConnectTemplate
												templateOptions={templateOptions}
												templates={templates}
												classes={classes}
												emailType={EmailSequenceType.EMAIL_INITIAL}
												ref={ref_initial}
												candidateEmailMapping={letter.candidateEmailMapping}
												interval={undefined}
												scheduler={undefined}
												tagId={tagId}
												uploadFiles={autoConnectUploadFile}
												uploadFileTags={autoUploadFile}
												strategyId={strategyId}
												sequenceId={1}
												handleSendDisabled={setAutoDisabled_initial}
												defaultEmail={initialEmail}
											/>
											<hr />
											<div className="d-flex flex-row justify-content-between">
												<div style={{ width: '90%' }}>
													<b>Actions for the first email</b>
												</div>
												<div style={{ width: '10%' }}>
													<div className="d-flex flex-row justify-content-end">
														{!isFollowUpEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleFollowUpEmailAction}>
																<AddIcon />
															</Link>
														)}
														{isFollowUpEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleFollowUpEmailAction}>
																<RemoveIcon />
															</Link>
														)}
													</div>
												</div>
											</div>
											{action_firstEmail && (
												<>
													<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
														<div>
															<Autocomplete
																size="small"
																multiple
																options={actionTypeOptions}
																getOptionLabel={(option) => option.value}
																style={{ width: 220 }}
																value={actionTypeValues[EmailSequenceType.ACTION_FIRST_EMAIL]}
																getOptionSelected={(option, value) => option.type === value.type}
																onChange={(e, values) =>
																	handleChangeActionType(
																		EmailSequenceType.ACTION_FIRST_EMAIL,
																		values
																	)
																}
																renderInput={(params) => (
																	<TextField {...params} variant="outlined" />
																)}
															/>
														</div>
														<div className="ml-1 mr-1">in</div>
														<div>
															<input
																style={{ width: '30px' }}
																value={action_firstEmail_time.hour}
																onChange={(e) => {
																	e.persist();
																	setAction_firstEmail_time((prevState) => ({
																		...prevState,
																		hour: e.target.value
																	}));
																}}
															/>
														</div>
														<div className="ml-1 mr-1">hours </div>
														<div>
															<input
																style={{ width: '30px' }}
																value={action_firstEmail_time.min}
																onChange={(e) => {
																	e.persist();
																	setAction_firstEmail_time((prevState) => ({
																		...prevState,
																		min: e.target.value
																	}));
																}}
															/>
														</div>
														<div className="ml-1">minutes, then send the next email</div>
													</div>
													{error_action_firstEmail_time && (
														<>
															<div className={'d-flex'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be less than 24
																</div>
															</div>
															<div className={'d-flex mb-2'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be a number outside 00 - 59
																</div>
															</div>
														</>
													)}
													<AutoConnectTemplate
														templateOptions={templateOptions}
														templates={templates}
														classes={classes}
														emailType={EmailSequenceType.ACTION_FIRST_EMAIL}
														ref={ref_action_firstEmail}
														candidateEmailMapping={letter.candidateEmailMapping}
														tagId={tagId}
														uploadFiles={autoConnectUploadFile}
														uploadFileTags={autoUploadFile}
														strategyId={strategyId}
														sequenceId={2}
														interval={timestamp_action_firstEmail}
														scheduler={action_firstEmail_time}
														handleSendDisabled={setAutoDisabled_action_firstEmail}
														defaultEmail={followEmail}
													/>
												</>
											)}
											<hr />
											<div className="d-flex flex-row justify-content-between">
												<div style={{ width: '90%' }}>
													<b>Actions for the second email (optional)</b>
												</div>
												<div style={{ width: '10%' }}>
													<div className="d-flex flex-row justify-content-end">
														{!isExtraEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleExtraEmailAction}>
																<AddIcon />
															</Link>
														)}
														{isExtraEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleExtraEmailAction}>
																<RemoveIcon />
															</Link>
														)}
													</div>
												</div>
											</div>
											{isExtraEmail && (
												<>
													<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
														<div>
															<Autocomplete
																size="small"
																multiple
																options={actionTypeOptions}
																getOptionLabel={(option) => option.value}
																style={{ width: 220 }}
																value={actionTypeValues[EmailSequenceType.ACTION_EXTRA_EMAIL]}
																getOptionSelected={(option, value) => option.type === value.type}
																onChange={(e, values) =>
																	handleChangeActionType(
																		EmailSequenceType.ACTION_EXTRA_EMAIL,
																		values
																	)
																}
																renderInput={(params) => (
																	<TextField {...params} variant="outlined" />
																)}
															/>
														</div>
														<div className="ml-1 mr-1">in</div>
														<div>
															<input
																style={{ width: '30px' }}
																value={extraEmail_time.hour}
																onChange={(e) => {
																	e.persist();
																	setExtraEmail_time((prevState) => ({
																		...prevState,
																		hour: e.target.value
																	}));
																}}
															/>
														</div>
														<div className="ml-1 mr-1">hours </div>
														<div>
															<input
																style={{ width: '30px' }}
																value={extraEmail_time.min}
																onChange={(e) => {
																	e.persist();
																	setExtraEmail_time((prevState) => ({
																		...prevState,
																		min: e.target.value
																	}));
																}}
															/>
														</div>
														<div className="ml-1">minutes, then send the next email</div>
													</div>
													{error_extraEmail_time && (
														<>
															<div className={'d-flex'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be less than 24
																</div>
															</div>
															<div className={'d-flex mb-2'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be a number outside 00 - 59
																</div>
															</div>
														</>
													)}
													<AutoConnectTemplate
														templateOptions={templateOptions}
														templates={templates}
														classes={classes}
														emailType={EmailSequenceType.ACTION_EXTRA_EMAIL}
														ref={ref_action_extraEmail}
														candidateEmailMapping={letter.candidateEmailMapping}
														tagId={tagId}
														uploadFiles={autoConnectUploadFile}
														uploadFileTags={autoUploadFile}
														strategyId={strategyId}
														sequenceId={3}
														interval={timestamp_extraEmail}
														scheduler={extraEmail_time}
														handleSendDisabled={setAutoDisabled_action_extraEmail}
													/>
												</>
											)}
											<hr />
											<div className="d-flex flex-row justify-content-between">
												<div style={{ width: '90%' }}>
													<b>Actions for the last email</b>
												</div>
												<div style={{ width: '10%' }}>
													<div className="d-flex flex-row justify-content-end">
														{!isLastEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleLastEmailAction}>
																<AddIcon />
															</Link>
														)}
														{isLastEmailActionValid && (
															<Link
																component="button"
																underline="none"
																onClick={handleLastEmailAction}>
																<RemoveIcon />
															</Link>
														)}
													</div>
												</div>
											</div>
											{action_lastEmail && (
												<>
													<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
														<div>
															<Autocomplete
																size="small"
																multiple
																options={actionTypeOptions}
																getOptionLabel={(option) => option.value}
																style={{ width: 220 }}
																value={actionTypeValues[EmailSequenceType.ACTION_LAST_EMAIL]}
																getOptionSelected={(option, value) => option.type === value.type}
																onChange={(e, values) =>
																	handleChangeActionType(
																		EmailSequenceType.ACTION_LAST_EMAIL,
																		values
																	)
																}
																renderInput={(params) => (
																	<TextField {...params} variant="outlined" />
																)}
															/>
														</div>
														<div className="ml-1 mr-1">in</div>
														<div>
															<input
																style={{ width: '30px' }}
																value={action_lastEmail_time.hour}
																onChange={(e) => {
																	e.persist();
																	setAction_lastEmail_time((prevState) => ({
																		...prevState,
																		hour: e.target.value
																	}));
																}}
															/>
														</div>
														<div className="ml-1 mr-1">hours </div>
														<div>
															<input
																style={{ width: '30px' }}
																value={action_lastEmail_time.min}
																onChange={(e) => {
																	e.persist();
																	setAction_lastEmail_time((prevState) => ({
																		...prevState,
																		min: e.target.value
																	}));
																}}
															/>
														</div>
														<div>minutes,</div>
													</div>
													<div>then a lark notification will be sent for AutoNotes</div>
													{error_action_lastEmail_time && (
														<>
															<div className={'d-flex'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be less than 24
																</div>
															</div>
															<div className={'d-flex mb-2'}>
																<div className="font-size-sm text-danger font-italic">
																	X Error:&nbsp;
																</div>
																<div className="font-size-sm font-italic">
																	Can not be blank and can not be a number outside 00 - 59
																</div>
															</div>
														</>
													)}
												</>
											)}
										</div>
									</div>
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '2' })}
										index={2}>
										<hr />
										{renderSendAllDetail(resultData)}
									</div>
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="p-4">
						{activeActionTab === '0' && (
							<>
								<Button onClick={handleClose} color="primary" variant="outlined">
									Cancel
								</Button>
								<Button
									onClick={handleSendGroupEmail}
									className={classes.containedPrimary}
									disabled={isOppSendDisabled || emailSendLoading}>
									{emailSendLoading && (
										<CircularProgress size={24} className={classes.buttonProgress} />
									)}
									Send
								</Button>
							</>
						)}
						{activeActionTab === '1' && (
							<>
								<Button onClick={handleClose} color="primary" variant="outlined">
									Cancel
								</Button>
								{!isAutoDisabled ? (
									<Button
										onClick={handleAutoConnect}
										className={classes.containedPrimary}
										disabled={autoLoading}>
										{autoLoading && (
											<CircularProgress size={24} className={classes.buttonProgress} />
										)}
										Send
									</Button>
								) : (
									<Button variant="contained" disabled>
										Send
									</Button>
								)}
							</>
						)}
					</DialogActions>
				</Dialog>
			)}

			{/* Duplicate Check & contacted */}
			{sendBtnVisible() && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					maxWidth="lg"
					open={groupDuplicateOpen}>
					<DialogTitle>
						<Grid container style={{ width: 800, maxWidth: '100%' }}>
							<Grid item xs={12}>
								<span className="text-black font-weight-bold font-size-md w-100">
									Remove Duplicated Candidates
								</span>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent className="p-4">
						<Grid container direction="column" spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									{duplicateTab2Open && (
										<div>
											{contactTotalNum} Total, {contactSelectedNum} Selected
										</div>
									)}
									{duplicateTab1Open && (
										<div>
											{duplicateSelectedNum} Selected, {duplicateNum} Duplicate Candidates found |{' '}
											{duplicateTotalNum} in Total
										</div>
									)}

									<Tabs
										value={currentTab}
										onChange={handleTabChange}
										aria-label="duplicate candidates tabs">
										<Tooltip title="These candidates were reached out by multiple recruiters in the past 2 weeks">
											<Tab label="RMRs in P2Ws" value="rmr" />
										</Tooltip>

										<Tooltip title="These candidates were uploaded into the IRS in the past 4 weeks">
											<Tab label="Uploaded in P4Ws" value="uploaded" />
										</Tooltip>

										<Tooltip title="These candidates are active candidates in the IRS">
											<Tab label="Activate Cdds in IRS" value="activateCdd" />
										</Tooltip>

										<Tooltip title="These candidates are complaining or having invalid email address ">
											<Tab label="Comp Cdds in P6Ms" value="compCdd" />
										</Tooltip>
										<Tooltip title="These candidates have been disqulified more then twice in the past ">
											<Tab label="Disqulified" value="disqualified" />
										</Tooltip>
									</Tabs>

									<Table
										size="medium"
										className="text-wrap mb-0 table-hover table-borderless font-size-sm">
										<thead className="thead">
											<tr>
												<th className="text-left">
													{/* <Button
														disabled={
															!linkedinDuplicateStatus ||
															typeof linkedinDuplicateStatus === 'undefined' ||
															Object.keys(linkedinDuplicateStatus).length === 0
														}
														className="btn-transition-none border-0 shadow-none btn-neutral-dark"
														onClick={() => {
															if (duplicateTab1Open) {
																const data = dataConnect();
																const duplicateData = data.filter(
																	(each) =>
																		linkedinDuplicateStatus[each.linkedin].bytedance ||
																		linkedinDuplicateStatus[each.linkedin].irs
																);
																if (isEmptyDuplicateTalents(duplicateCheckers)) {
																	let local = {};
																	duplicateData.map((each) => (local[each.id] = each));
																	setDuplicateCheckers(local);
																	setDuplicateSelectedNum(duplicateData.length);
																} else {
																	let local = {};
																	duplicateData.map((each) => (local[each.id] = undefined));
																	setDuplicateCheckers(local);
																	setDuplicateSelectedNum(0);
																}
															} else if (duplicateTab2Open) {
																if (isEmptyTalentsRemove(contactCheckers)) {
																	let local = {};
																	contactTalents.map((each) => (local[each.id] = each));
																	setContactCheckers(local);
																	setContactSelectedNum(contactTalents.length);
																} else {
																	let local = {};
																	contactTalents.map((each) => (local[each.id] = undefined));
																	setContactCheckers(local);
																	setContactSelectedNum(0);
																}
															}
														}}>
														All
													</Button> */}
													<Button
														disabled={!dupData || dupData.length === 0 || currentTab === 'compCdd'}
														className="btn-transition-none border-0 shadow-none btn-neutral-dark"
														onClick={() => {
															// 重新计算 filteredData，确保仅作用于当前标签下的数据
															const filteredData = dupData
																.filter((item) => item.dupTag && item.dupTag.length > 0)
																.filter((item) => item.dupTag.includes(currentTab));

															let allSelected = filteredData.every(
																(item) => duplicateCheckers[item.id]
															);
															let newCheckers = { ...duplicateCheckers };

															if (allSelected) {
																// 如果当前全部选中，则取消选中所有
																filteredData.forEach((item) => {
																	if (!item.dupTag.includes('compCdd')) {
																		newCheckers[item.id] = undefined;
																	}
																});
															} else {
																// 如果当前有未选中的，则选中所有
																filteredData.forEach((item) => {
																	if (!item.dupTag.includes('compCdd')) {
																		newCheckers[item.id] = item; // 或者只保存 ID，取决于你的逻辑
																	}
																});
															}

															setDuplicateCheckers(newCheckers);
															// 更新选中数量状态，基于新的选中状态
															setDuplicateSelectedNum(
																Object.values(newCheckers).filter((v) => v !== undefined).length
															);
														}}>
														All
													</Button>
												</th>
												<th className="text-left pl-3">Candidates</th>
												<th className="text-left pl-2">Company</th>
												<th className="text-left pl-2">Position</th>
												{duplicateTab2Open && (
													<>
														<th className="text-left pl-2">Sent</th>
														<th className="text-left pl-2">Opened</th>
													</>
												)}
												<th
													className={`text-left pl-2 ${
														!duplicateTab1Open && duplicateTab2Open && 'pr-3'
													}`}>
													Seniority
												</th>
												{duplicateTab1Open && (
													<>
														<th className="text-left pl-2">LinkedIn Url</th>
														{/* <th className="text-left pl-2 pr-3">ByteDance</th> */}
													</>
												)}
											</tr>
										</thead>
										{/* {talentsLoading ? <></> : <tbody>{renderTalentRows(talentsData)}</tbody>} */}
										<tbody>
											{groupDuplicateOpen && duplicateTab1Open && renderRmrRows()}
											{groupDuplicateOpen && duplicateTab2Open && renderContactedRows()}
										</tbody>
									</Table>
									{/* {talentsLoading && <LoadingIndicator />} */}
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
					{/* <DialogActions className="p-4">
						<>
							<Button
								className={classes.containedPrimary}
								onClick={() => setDialogMessageConfirmOpen(true)}>
								{duplicateTab1Open && 'Remove & AutoCheck & Continue'}
								{duplicateTab2Open && 'Remove & Continue'}
							</Button>
							<Button onClick={handleDuplicateClose} color="primary" variant="outlined">
								Cancel
							</Button>
						</>
					</DialogActions> */}
					<DialogActions className="p-4">
						{isCheckingDup ? (
							<p>Duplication check in progress...</p>
						) : (
							<>
								<span className="email-quota-remaining">
									Daily email quota remaining: {remainingQuota}
								</span>

								<Button
									className={classes.containedPrimary}
									onClick={() => setDialogMessageConfirmOpen(true)}
									disabled={isCheckingDup || isOutOfQuota}>
									{isOutOfQuota
										? 'Insufficient Quota Left'
										: duplicateTab1Open
										? 'Remove & AutoCheck & Continue'
										: duplicateTab2Open
										? 'Remove & Continue'
										: ''}
								</Button>
								<Button onClick={handleDuplicateClose} color="primary" variant="outlined">
									Cancel
								</Button>
							</>
						)}
					</DialogActions>
				</Dialog>
			)}

			{/*Dialog for available credits*/}
			<Dialog
				open={creditOpen}
				onClose={() => setCreditOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You do not have enough credits, please contact OCBang Representative to refill credits.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCreditOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				fullWidth
				maxWidth={'md'}
				open={isLoginOpen}
				onClose={() => setLoginOpen(false)}
				aria-labelledby="form-dialog-title">
				<DialogContent className={'p-4'}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card className="shadow-xxl p-3">
								<List component="div" className="nav-line d-flex nav-tabs-primary">
									<ListItem
										button
										disableRipple
										selected={autoNoteTab === 0}
										onClick={() => toggleAutoNoteTab(0)}>
										<span>Sign in</span>
										<div className="divider" />
									</ListItem>
									<ListItem
										button
										disableRipple
										selected={autoNoteTab === 1}
										onClick={() => toggleAutoNoteTab(1)}>
										<span>AutoNotes</span>
										<div className="divider" />
									</ListItem>
								</List>
								<div className={clsx('tab-item-wrapper', { select: autoNoteTab === 0 })}>
									<div className="mt-2 mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">
											Linkedin sign in email or phone
										</span>
										{/* <Tooltip
											title={
												<div>
													The total number of AutoNotes sent can not exceed 100 every week for a
													free LinkedIn account and 200 for a paid LinkedIn account.
												</div>
											}
											arrow
											placement="top">
											<HelpOutlineIcon fontSize={'small'} />
										</Tooltip> */}
									</div>
									{accounts.map((each, index) => (
										<div key={index} className="mb-3">
											<Grid
												container
												alignItems="center"
												direction="row"
												justifyContent="flex-start">
												<Grid item xs={5}>
													<span className="text-black font-weight-light font-size-md">
														{each.account}
													</span>
												</Grid>
												<Grid item xs={5}>
													<span className="pl-3 text-primary font-weight-light font-size-md">
														({each.remainingCredits} sendings left for AutoNotes)
													</span>
												</Grid>
												<Grid item xs={2}>
													<Button
														// disabled={
														// 	each.remainingCredits < getNumberOfChecked(filterCheckers) ||
														// 	loginStatus[index]
														// }
														disabled={loginStatus[index]}
														className={`${classes.containedPrimary}`}
														onClick={() => handleLogin(index, each)}>
														{loginLoading[index] && (
															<CircularProgress size={24} className={classes.autoNoteBtnProgress} />
														)}
														Log in
													</Button>
												</Grid>
											</Grid>
										</div>
									))}
									{is2FA.is2FAShow && (
										<>
											<div className="font-weight-bold text-black font-size-md mb-1">
												Enter the code you see on your phone, email or authenticator app
											</div>
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={4}>
													<input
														className="w-100"
														value={is2FA.vfCode}
														onChange={(e) => {
															e.persist();
															if (e.target.value.length > 0) {
																set2FA((prevState) => ({
																	...prevState,
																	vfCode: e.target.value.trim(),
																	is2FASendBtnDisabled: false
																}));
															} else {
																set2FA((prevState) => ({
																	...prevState,
																	vfCode: null,
																	is2FASendBtnDisabled: true
																}));
															}
														}}
													/>
												</Grid>
												<Grid item xs={4}>
													<Button
														disabled={autoNoteSendActive || is2FA.is2FASendBtnDisabled}
														style={{ height: '30px' }}
														className={classes.containedPrimary}
														onClick={handleSend2FA}>
														{vfLoading && (
															<CircularProgress size={24} className={classes.autoNoteBtnProgress} />
														)}
														Send
													</Button>
												</Grid>
												<Grid item xs={2} />
											</Grid>
										</>
									)}
									{is2FA.errorMsgShow && (
										<div className="font-size-sm text-danger mt-2">
											Please enter the verification code again.
										</div>
									)}
									{autoNoteSendActive && (
										<div className="mt-2">
											<span className="text-primary">
												All SET! Your AutoNotes is ready to implement.
											</span>
										</div>
									)}
								</div>
								<div className={clsx('tab-item-wrapper', { select: autoNoteTab === 1 })}>
									<DialogContent>
										<Autocomplete
											id="combo-box-demo"
											className="mb-1"
											size="small"
											options={templateOptions}
											groupBy={(option) => option.type}
											getOptionLabel={(option) => option.name}
											filterOptions={(options, state) =>
												options.filter((each) => each.irsType === 'autonotes_newConn')
											}
											onChange={handleAutoNoteTemplate}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													placeholder="Search for a template"
												/>
											)}
										/>
										<ReactQuill
											className="quillContainer"
											theme="snow"
											// ref={ref_quill}
											ref={reactQuillRef}
											modules={{ toolbar: false }}
											value={note.htmlText}
											onChange={handleAutoNoteBody}
										/>
										<DialogContentText className={'d-flex'}>
											<div className={'ml-auto'}>
												<span style={{ verticalAlign: 'middle', marginRight: '6px' }}>Insert</span>

												<div style={{ display: 'inline-block', marginRight: '0px' }}>
													<Button
														size="small"
														style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
														onClick={handleCompanyButtonClick}>{`{company}`}</Button>
												</div>

												<div
													style={{
														borderLeft: '1px solid #000',
														height: '15px',
														width: '1.5px',
														display: 'inline-block',
														verticalAlign: 'middle',
														margin: '0 5px'
													}}
												/>

												<div style={{ display: 'inline-block', marginLeft: '0px' }}>
													<Button
														size="small"
														style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
														onClick={handleTitleButtonClick}>
														{'{title}'}
													</Button>
												</div>
											</div>
										</DialogContentText>
										<div className="mt-2 text-right">
											<span className="font-size-sm font-italic">
												{note.numberLeft} characters left
											</span>
										</div>
										<div className="mt-4 font-size-sm text-left">
											<span className="font-weight-bold">Sleep Time:</span>
											<input
												className="ml-1"
												style={{ width: '40px' }}
												value={sleepTime}
												onChange={handleSleepTimeInput}
											/>
											<span className="ml-1">Second(s)</span>
											<span className="ml-1 font-italic">(a minimum of 2 seconds is required)</span>
										</div>
										<div className="mt-4 font-size-sm text-left">
											<span className="font-weight-bold">Withdraw connection requests in:</span>
											<input
												className="ml-1"
												style={{ width: '40px' }}
												value={withDrawDays}
												onChange={handleWithDrawDaysInput}
											/>
											<span className="ml-1 font-weight-bold">
												day(s) if recipients do not connect
											</span>
										</div>
										<div className="mt-2 text-left">
											<span className="font-size-sm font-italic">
												(a minimum of 7 days is required)
											</span>
										</div>
									</DialogContent>
								</div>
							</Card>
						</Grid>
					</Grid>
				</DialogContent>
				{autoNoteTab === 1 && (
					<DialogActions>
						<Button color="primary" variant="outlined" onClick={handleAutoNoteClose}>
							Cancel
						</Button>
						<Button
							className={classes.containedPrimary}
							color="primary"
							variant="contained"
							disabled={noteLoading || !autoNoteSendActive || !autoNoteParamsValid}
							onClick={handleSendAutoNote}>
							{noteLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
							Send
						</Button>
					</DialogActions>
				)}
			</Dialog>

			<Dialog open={isAutoNoteConfirmOpen} onClose={() => setAutoNoteConfirmOpen(false)}>
				<DialogContent>
					<DialogContentText>
						It takes about{' '}
						{Math.ceil(
							(40 * numberOfTalentsProcessing + sleepTime * (numberOfTalentsProcessing - 1)) / 60
						)}{' '}
						minutes to run all AutoNotes projects. Please check the status of this project in
						"AutoNotes History" after{' '}
						{Math.ceil(
							(40 * numberOfTalentsProcessing + sleepTime * (numberOfTalentsProcessing - 1)) / 60
						)}{' '}
						minutes.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className="btn-neutral-dark-blue active"
						onClick={() => setAutoNoteConfirmOpen(false)}
						color="primary"
						variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={isWindowAlertOpen} onClose={() => setWindowAlertOpen(false)}>
				<DialogContent>
					<DialogContentText>The system is busy. Please try again later.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className="btn-neutral-dark-blue active"
						onClick={() => setWindowAlertOpen(false)}
						color="primary"
						variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Portal>
				<Snackbar
					className="Snackbar-center"
					open={
						emailCreditOpen.noEmailCreditOpen ||
						emailCreditOpen.closeEmailCreditOpen ||
						emailCreditOpen.shortageEmailCreditOpen
					}
					onClose={() =>
						setEmailCreditOpen({
							noEmailCreditOpen: false,
							closeEmailCreditOpen: false,
							shortageEmailCreditOpen: false
						})
					}>
					<Alert
						onClose={() =>
							setEmailCreditOpen({
								noEmailCreditOpen: false,
								closeEmailCreditOpen: false,
								shortageEmailCreditOpen: false
							})
						}
						severity="warning">
						{emailCreditOpen.noEmailCreditOpen && (
							<span>
								All your email sending quote is used out. If you need more daily quota, please
								contact our account manager to upgrade your service.
							</span>
						)}
						{emailCreditOpen.closeEmailCreditOpen && (
							<span>
								The email sending limit is close. You have only {memory.user.remainingEmailCredits}{' '}
								email(s) quota left for today. Please pay attention to your email usage.
							</span>
						)}
						{emailCreditOpen.shortageEmailCreditOpen && (
							<span>
								You have only {memory.user.remainingEmailCredits} email(s) quota left for today.
								Please adjust or remove the talents you are going to connect with in this email.
							</span>
						)}
					</Alert>
				</Snackbar>
			</Portal>

			<MessageConfirmDialog
				confirmLoading={confirmLoading}
				dialogMessageOpen={dialogMessageConfirmOpen}
				handleDialogMessageClose={() => setDialogMessageConfirmOpen(false)}
				handleDialogMessageConfirm={duplicateTab1Open ? handleAutoCheck : handleReadySendEmails}>
				{duplicateTab1Open && (
					<span>
						Are you sure you want to remove and auto check selected candidates, and send emails to
						unselected candidates?
					</span>
				)}
				{duplicateTab2Open && (
					<span>
						Are you sure you want to remove selected candidates, and send emails to unselected
						candidates?
					</span>
				)}
			</MessageConfirmDialog>

			<MessageConfirmDialog
				confirmLoading={deleteConfirmLoading}
				dialogMessageOpen={deleteDialogMessageConfirmOpen}
				handleDialogMessageClose={() => setDeleteDialogMessageConfirmOpen(false)}
				handleDialogMessageConfirm={handleDeleteTalents}>
				<span>Are you sure you want to delete these candidates?</span>
			</MessageConfirmDialog>
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state, props) => {
	// const queryString = window.location.search;
	// console.log("queryString",queryString);

	let urlParams;
	let activeTab = 0;
	const queryString = props.location.search;
	if (queryString && queryString.length > 0) {
		urlParams = new URLSearchParams(queryString);
		const string_activeTab = urlParams.get('activeTab');
		activeTab =
			string_activeTab && typeof string_activeTab !== 'undefined' ? parseInt(string_activeTab) : 0;
	}

	const project = decodeURIComponent(props.match.params.projectName);
	console.log('Decoded project:', project);
	const lastIndex = project.lastIndexOf('_');
	const projectName = project.substring(0, lastIndex);
	const tagId = project.substring(lastIndex + 1);
	const projectList = getProjectsList(state);

	console.log('Parsed projectName:', projectName); // 打印解析后的projectName
	console.log('Parsed tagId:', tagId); // 打印解析后的tagId
	console.log('pages savedProjects mapStateToProps projectList...', projectList);

	// 查找对应项目并获取 type 属性
	const currentProject = projectList
		? projectList.find((proj) => proj.id === parseInt(tagId))
		: null;
	const projectType = currentProject ? currentProject.type : 'REC_CAN';
	console.log(projectType);

	return {
		projects: getProjectsList(state),
		tagId: parseInt(tagId),
		projectName: projectName,
		projectType: projectType,
		larkActiveTab: activeTab
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		recruitActions: bindActionCreators(recruitActions, dispatch),
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRecommendTalents);
