import { makeStyles, Theme } from '@material-ui/core/styles';

type categoryBodyMap = {
	[key: string]: string | Array<string>;
};

export const categoryBodyMapping: categoryBodyMap = {
	email_replied: 'replied your emails.',
	email_opened: 'opened your emails.',
	linkedin_messaged: 'messaged on linkedin.',
	linkedin_connected: 'connect you on Linkedin.',
	new_talents: 'newly uploaded talents satisfying current filters. See this talents in',
	talents_recommended: ['A new talent pool with', 'talents is recommended.'],
	autonotes_send_status: [
		'Autonotes sending completed.',
		'are sent successfully.',
		'failed to send talents is/are'
	],
	autonotes_withdraw_status: 'autoNotes were withdrawn. See the list in',
	o2o: 'is/are considering new opportunities but not actively looking for.'
};

export enum categoryType {
	EMAIL_REPLIED = 'email_replied',
	EMAIL_OPENED = 'email_opened',
	LINKEDIN_MESSAGED = 'linkedin_messaged',
	LINKEDIN_CONNECTED = 'linkedin_connected',
	NEW_TALENTS = 'new_talents',
	TALENTS_RECOMMENDED = 'talents_recommended',
	AUTONOTES_SEND_STATUS = 'autonotes_send_status',
	AUTONOTES_WITHDRAW_STATUS = 'autonotes_withdraw_status',
	O2O = 'o2o'
}

export enum switchType {
	REPORT = 'REPORT',
	TALENTS_MATCH = 'TALENTS_MATCH',
	TASK_EXECUTION = 'TASK_EXECUTION',
	O2O = 'O2O',
	EMAIL = 'EMAIL'
}

/************************************************************ Switch ***************************************************/

export const useStyles_talents_match = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#07e4fe',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#07e4fe',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

export const useStyles_task_execution = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#00aeef',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#00aeef',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

export const useStyles_o2o = makeStyles((theme: Theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#5161b3',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

export const useStyles_report = makeStyles((theme: Theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#0795fe',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

export const useStyles_emails = makeStyles((theme: Theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#1bc943',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));
