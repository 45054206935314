import React, { useEffect } from 'react';

import { PageTitle } from '../../components';
import OrgTabs from './components/OrgTabs';
import {
	actions as articleTabActions,
	getArticle,
	getArticlesListByOrgId
} from '../../redux/modules/articleTab';
import { actions as overviewTabActions, getOverviewById } from '../../redux/modules/overviewTab';
import {
	actions as orgChartActions,
	getFollowStatus,
	getLevel,
	getOrgChart,
	getTopLeader,
	subscribeOrgChart
} from '../../redux/modules/orgChartTab';
import {
	actions as custOrgChartActions,
	getCustFollowStatus,
	getCustLevel,
	getCustOrgChart,
	getCustTopLeader,
	subscribeCustOrgChart
} from '../../redux/modules/custOrgChartTab';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

function OrgDetail(props) {
	const {
		match,
		overview,
		overviewTabActions,
		articleTabActions,
		orgChartActions,
		custOrgChartActions,
		articlesList,
		article,
		orgChart,
		orgCustOrgChart,
		subscribe,
		subscribeCust,
		getTopLeader,
		getCustTopLeader,
		getLevel,
		getCustLevel,
		getFollowStatus,
		getCustFollowStatus
	} = props;
	const orgId = match.params.orgId;

	console.log(props);

	useEffect(() => {
		overviewTabActions.loadOverviewTab(orgId);
	}, []);

	useEffect(() => {
		orgChartActions.loadOrgChartTab(orgId);
	}, []);

	useEffect(() => {
		articleTabActions.loadArticlesList(orgId);
	}, []);

	useEffect(() => {
		custOrgChartActions.loadCustOrgChartTab(orgId);
	}, []);

	return (
		<>
			{!overview && (
				<>
					<Card>
						<CardHeader
							avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
							title={
								<>
									<Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 6 }} />
									<Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 6 }} />
								</>
							}
							subheader={<Skeleton animation="wave" height={10} width="40%" />}
						/>
						<CardContent>
							<Skeleton animation="wave" variant="rect" height={500} />
						</CardContent>
					</Card>
				</>
			)}
			{overview && (
				<>
					<PageTitle
						logo={overview.logo}
						titleHeading={overview.name}
						titleDescription={overview.description}
					/>
					<OrgTabs
						orgId={match.params.orgId}
						orgChartInfo={overview.orgChartInfo}
						custOrgChartInfo={overview.customized_orgChartInfo}
						jump={props.history.location.jump ? props.history.location.jump : null}
						tabIndex={props.tabIndex}
						articlesList={articlesList}
						article={article}
						orgChart={orgChart}
						orgCustOrgChart={orgCustOrgChart}
						statistics={overview.statistics}
						setActiveArticle={articleTabActions.setActiveArticle}
						cancelActiveArticle={articleTabActions.cancelActiveArticle}
						subscribe={subscribe}
						subscribeCust={subscribeCust}
						getTopLeader={getTopLeader}
						getCustTopLeader={getCustTopLeader}
						getLevel={getLevel}
						getCustLevel={getCustLevel}
						followDepartment={orgChartActions.followDepartment}
						followCustDepartment={custOrgChartActions.followCustDepartment}
						getFollowStatus={getFollowStatus}
						getCustFollowStatus={getCustFollowStatus}
					/>
				</>
			)}
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/
const mapStateToProps = (state, props) => {
	const orgId = props.match.params.orgId;
	return {
		articlesList: getArticlesListByOrgId(state, orgId),
		overview: getOverviewById(state),
		article: getArticle(state),
		orgChart: getOrgChart(state),
		orgCustOrgChart: getCustOrgChart(state),
		subscribe: (id) => subscribeOrgChart(state, id),
		subscribeCust: (id) => subscribeCustOrgChart(state, id),
		getTopLeader: getTopLeader(state),
		getCustTopLeader: getCustTopLeader(state),
		getLevel: (id, level) => getLevel(state, id, level),
		getCustLevel: (id, level) => getCustLevel(state, id, level),
		getFollowStatus: getFollowStatus(state),
		getCustFollowStatus: getCustFollowStatus(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		articleTabActions: bindActionCreators(articleTabActions, dispatch),
		overviewTabActions: bindActionCreators(overviewTabActions, dispatch),
		orgChartActions: bindActionCreators(orgChartActions, dispatch),
		custOrgChartActions: bindActionCreators(custOrgChartActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetail);
