import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { useEffect, useState, MouseEventHandler } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import styled from 'styled-components';
import LoadingIndicator from '../../../../theme/loadingIndicator';
import { transformDateToLocalTZ, searchOptions } from '../../../../utils/recruitUtil';
import { get } from '../../../../utils/request';
import url from '../../../../utils/url';
import Popover from '@material-ui/core/Popover';
import * as Antd from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	tabActive: {
		backgroundColor: '#ffffff',
		zIndex: 10,
		position: 'absolute'
	},
	tabInActive: {
		backgroundColor: 'transparent',
		zIndex: 10,
		position: 'absolute'
	},
	content: {
		zIndex: -2,
		position: 'relative'
	},
	frame: {
		zIndex: -1,
		position: 'relative'
	}
}));

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

type EmailHistoryProject = {
	tagId: number;
	project: string;
	strategy: number;
	batch_seq: number;
	sent_time: Date;
	sent_num: number;
	open_num: number;
	replied_num: number;
	subject: string;
	sender?: string;
};

type EmailHistoryProjectDetail = {
	id: number;
	name: string;
	linkedin: string;
	position: string;
	company: string;
	seniority: string;
	subject: string;
	project: string;
	sent_time: Date;
	sent: number;
	open: number;
	reply: number;
	open_time: Date;
	bounce: number;
	email: number;
};

type SearchType = {
	company: string[];
	seniority: string[];
	project: string[];
};

type validParamType = {
	[key: string]: string[];
};

enum historyViewType {
	PROJECT = 'PROJECT',
	CANDIDATE = 'CANDIDATE'
}

export default function EmailHistoryChrome() {
	const [activeTab, setActiveTab] = useState(0);
	const classes = useStyles();

	const recordPerPage = 25;
	const [page, setPage] = useState<number>(1);

	const [search, setSearch] = useState<SearchType>({
		company: [],
		seniority: [],
		project: []
	});

	const [filterOptions, setFilterOptions] = useState<SearchType>({
		company: [],
		seniority: [],
		project: []
	});

	const [selectedRow, setSelectedRow] = useState(null);
	const [open, setOpen] = useState(false);
	const [folders, setFolders] = useState([]);

	const [projectData, setProjectData] = useState<EmailHistoryProject[]>([]);
	const [filteredProjectData, setFilteredProjectData] = useState<EmailHistoryProject[] | null>(
		null
	); //for render use
	const [dataByFilteredProject, setDataByFilteredProject] = useState<EmailHistoryProject[]>([]); //for search use

	const [candidateData, setCandidateData] = useState<EmailHistoryProjectDetail[]>([]);
	const [filteredCandidateData, setFilteredCandidateData] = useState<
		EmailHistoryProjectDetail[] | null
	>(null); //for render use
	const [dataByFilteredCandidate, setDataByFilteredCandidate] = useState<
		EmailHistoryProjectDetail[]
	>([]); //for search use

	const [selectedProject, setSelectedProject] = useState<EmailHistoryProjectDetail[]>([]);

	const [projectDetail, setProjectDetail] = useState({
		open: false,
		loading: false,
		projectName: ''
	});

	const { promiseInProgress } = usePromiseTracker();

	const [viewType, setViewType] = useState<string>('PROJECT');

	const toggle = (activeTab: number) => {
		setActiveTab(activeTab);
	};

	const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	/********************************************************************  Handle With Filter Start ******************************************/
	const [searchWord, setSearchWord] = useState<string | null>(null);
	const [dataBySearch, setDataBySearch] = useState<
		EmailHistoryProjectDetail[] | EmailHistoryProject[]
	>([]);

	const { RangePicker } = Antd.DatePicker;
	const [dateRange, setDateRange] = useState<any>([]);

	const [anchorElCompany, setAnchorElCompany] = useState<
		HTMLButtonElement | HTMLImageElement | null
	>(null);
	const [anchorElSeniority, setAnchorElSeniority] = useState<
		HTMLButtonElement | HTMLImageElement | null
	>(null);
	const [anchorElProject, setAnchorElProject] = useState<
		HTMLButtonElement | HTMLImageElement | null
	>(null);

	const handleCompanyClick = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
		console.log(event.currentTarget);
		setAnchorElCompany(event.currentTarget);
	};
	const handleCompanyClose = () => {
		setAnchorElCompany(null);
	};
	const openCompany = Boolean(anchorElCompany);

	const handleProjectClick = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
		console.log(event.currentTarget);
		setAnchorElProject(event.currentTarget);
	};
	const handleProjectClose = () => {
		setAnchorElProject(null);
	};
	const openProject = Boolean(anchorElProject);

	const handleSeniorityClick = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
		console.log(event.currentTarget);
		setAnchorElSeniority(event.currentTarget);
	};
	const handleSeniorityClose = () => {
		setAnchorElSeniority(null);
	};
	const openSeniority = Boolean(anchorElSeniority);

	const handleRangePicker = (dates: any, dateStrings: any) => {
		console.log('dates', dates, 'dateStrings', dateStrings);
		if (!dates) {
			setDateRange([]);
			return;
		}

		setDateRange(dates);
	};

	/********************************************************************  Handle With Filter End ******************************************/

	const handleClickAction = (
		e: React.MouseEvent<HTMLElement>,
		batchSeq: number,
		projectName: string
	) => {
		e.preventDefault();
		console.log('batch select', batchSeq);

		setProjectDetail({ ...projectDetail, loading: true });

		if (activeTab === 0) {
			axios
				.get(url.fetchEmailHistoryProjectDetail(), {
					params: {
						batchSeq: batchSeq
					}
				})
				.then(
					(res) => {
						const result = res.data;
						console.log('fetchEmailHistoryProjectDetail', result.message);
						//const finalData = { ...result.data, ...data };

						setSelectedProject(result.data);
						//setFolders(result.data.tags);
						//setActiveActionTab(tabIndex);

						setProjectDetail({
							...projectDetail,
							loading: false,
							open: true,
							projectName: projectName
						});
					},
					(error) => {
						console.error('fetchEmailHistoryProjectDetail error', error);
					}
				)
				.catch((e) => {
					console.error('fetchEmailHistoryProjectDetail error catch', e.message);
				});
		} else if (activeTab === 1) {
			axios
				.get(url.fetchEmailHistoryProjectDetailExtension(), {
					params: {
						batchSeq: batchSeq
					}
				})
				.then(
					(res) => {
						const result = res.data;
						console.log('fetchEmailHistoryProjectDetailExtension', result.message);

						setSelectedProject(result.data);

						setProjectDetail({
							...projectDetail,
							loading: false,
							open: true,
							projectName: projectName
						});
					},
					(error) => {
						console.error('fetchEmailHistoryProjectDetailExtension error', error);
					}
				)
				.catch((e) => {
					console.error('fetchEmailHistoryProjectDetailExtension error catch', e.message);
				});
		}
	};

	const handleViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
		const type = e.target.value;
		setViewType(type);
		handleSwitch(type);
	};

	const handleAdvancedSearch = (e: React.ChangeEvent<{}>, value: string[], name: string) => {
		setSearch({
			...search,
			[name]: value
		});
	};

	const renderRows = (data: EmailHistoryProjectDetail[]) => {
		return data
			? data.map((each) => {
					const {
						id,
						name,
						linkedin,
						position,
						company,
						seniority,
						subject,
						project,
						sent_time,
						open_time,
						sent,
						open,
						reply,
						bounce,
						email
					} = each;

					return (
						<TableRow key={id}>
							<TableCell component="th" scope="row">
								<a href={linkedin} target="_blank">
									{name}
								</a>
							</TableCell>
							{/* <TableCell align="left">{company}</TableCell>
							{activeTab === 0 && (
								<TableCell align="left">{seniority ? seniority.substring(4) : ''}</TableCell>
							)} */}
							<TableCell align="left">{email}</TableCell>
							<TableCell align="left">
								{bounce === 0 && sent === 1 && <DoneIcon fontSize={'small'} color={'primary'} />}
								{bounce === 1 && sent === 1 && (
									<TIMERIMG src={require('../../../../assets/images/apps/timer.png')} />
								)}
								{bounce === 0 && sent === 0 && (
									<TIMERIMG src={require('../../../../assets/images/apps/timer.png')} />
								)}
							</TableCell>
							<TableCell>
								{bounce === 0 && sent === 1 && open === 1 && (
									<DoneAllIcon fontSize={'small'} color={'primary'} />
								)}
							</TableCell>
							<TableCell>
								{bounce === 0 && sent === 1 && open === 1 && reply === 1 && (
									<img
										className="img-20"
										src={require(`../../../../assets/images/apps/replied.png`)}
									/>
								)}
							</TableCell>
						</TableRow>
					);
			  })
			: '';
	};

	const handleSwitch = (viewType: string) => {
		setSearch({
			project: [],
			company: [],
			seniority: []
		});
		setDataBySearch([]);
		setDateRange([]);
		setPage(1);

		if (activeTab === 0) {
			if (viewType === historyViewType.PROJECT) {
				trackPromise(
					get(url.fetchEmailHistoryProject()).then((res) => {
						console.log('history data', res.data);
						setProjectData(res.data);
						setFilterOptions(searchOptions(res.data));
						return res.data;
					})
				);
			} else if (viewType === historyViewType.CANDIDATE) {
				trackPromise(
					get(url.fetchEmailHistory()).then((res) => {
						console.log('history data', res.data);
						setCandidateData(res.data);
						setFilterOptions(searchOptions(res.data));
						return res.data;
					})
				);
			}
		} else if (activeTab === 1) {
			if (viewType === historyViewType.PROJECT) {
				trackPromise(
					get(url.fetchEmailHistoryProjectExtension()).then((res) => {
						console.log('history data', res.data);
						setProjectData(res.data);
						setFilterOptions(searchOptions(res.data));
						return res.data;
					})
				);
			} else if (viewType === historyViewType.CANDIDATE) {
				trackPromise(
					get(url.fetchEmailHistoryProjectDetailExtension()).then((res) => {
						console.log('history data', res.data);
						setCandidateData(res.data);
						setFilterOptions(searchOptions(res.data));
						return res.data;
					})
				);
			}
		}
	};

	const searchHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let validParams: validParamType = {};
		for (const [key, value] of Object.entries(search)) {
			if (value.length !== 0) validParams[key] = value;
		}
		const hasFiltersSelected = Object.keys(validParams).length > 0 || dateRange.length > 0;

		if (hasFiltersSelected) {
			const data =
				viewType === historyViewType.PROJECT ? dataByFilteredProject : dataByFilteredCandidate;
			setFilterOptions(searchOptions(data));
		} else {
			const data = viewType === historyViewType.PROJECT ? projectData : candidateData;
			setFilterOptions(searchOptions(data));
		}

		const searchResult = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(
				hasFiltersSelected
					? viewType === historyViewType.PROJECT
						? dataByFilteredProject
						: dataByFilteredCandidate
					: viewType === historyViewType.PROJECT
					? projectData
					: candidateData
			)) {
				if (viewType === historyViewType.PROJECT) {
					const projectName = value.project ? value.project.toLowerCase() : '';
					const subject = value.subject ? value.subject.toLowerCase() : '';
					const sent_localTZ = transformDateToLocalTZ(value.sent_time);

					if (
						projectName.includes(event.target.value.toLowerCase()) ||
						subject.includes(event.target.value.toLowerCase()) ||
						sent_localTZ.includes(event.target.value.toLowerCase())
					) {
						searchResult.push(value);
					}
				} else if (viewType === historyViewType.CANDIDATE) {
					const name = value.name ? value.name.toLowerCase() : '';
					const subject = value.subject ? value.subject.toLowerCase() : '';
					const company = value.company ? value.company.toLowerCase() : '';
					const project = value.project ? value.project.toLowerCase() : '';
					const email = value.email ? value.email.toLowerCase() : '';
					const sent_localTZ = transformDateToLocalTZ(value.sent_time);
					let seniority = '';
					if (activeTab === 0) {
						seniority = value.seniority ? value.seniority.toLowerCase() : '';
					}

					if (
						name.includes(event.target.value.toLowerCase()) ||
						company.includes(event.target.value.toLowerCase()) ||
						subject.includes(event.target.value.toLowerCase()) ||
						project.includes(event.target.value.toLowerCase()) ||
						email.includes(event.target.value.toLowerCase()) ||
						sent_localTZ.includes(event.target.value.toLowerCase())
					) {
						searchResult.push(value);
					} else if (activeTab === 0) {
						if (seniority.includes(event.target.value.toLowerCase())) {
							searchResult.push(value);
						}
					}
				}
			}

			setSearchWord(event.target.value);
		} else {
			setSearchWord(null);
		}

		if (searchResult.length > 0) {
			console.log('search result: ', searchResult);
			if (viewType === historyViewType.PROJECT) {
				setFilteredProjectData(searchResult);
				setFilteredCandidateData(null);
				setFilterOptions(searchOptions(searchResult));
			} else if (viewType === historyViewType.CANDIDATE) {
				setFilteredCandidateData(searchResult);
				setFilteredProjectData(null);
				setFilterOptions(searchOptions(searchResult));
			}
		} else {
			if (event.target.value.length === 0) {
				if (viewType === historyViewType.PROJECT) {
					setFilteredProjectData(hasFiltersSelected ? dataByFilteredProject : projectData);
					setFilteredCandidateData(null);
					setFilterOptions(searchOptions(hasFiltersSelected ? dataByFilteredProject : projectData));
				} else if (viewType === historyViewType.CANDIDATE) {
					setFilteredCandidateData(hasFiltersSelected ? dataByFilteredCandidate : candidateData);
					setFilteredProjectData(null);
					setFilterOptions(
						searchOptions(hasFiltersSelected ? dataByFilteredCandidate : candidateData)
					);
				}
			} else {
				setFilteredCandidateData([]);
				if (viewType === historyViewType.PROJECT) {
					setFilteredProjectData([]);
					setFilteredCandidateData(null);
				} else if (viewType === historyViewType.CANDIDATE) {
					setFilteredCandidateData([]);
					setFilteredProjectData(null);
				}
				setFilterOptions({ company: [], seniority: [], project: [] });
			}
		}
		setPage(1);

		//search data only by search field for filters use
		const searchResultByWord = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(
				viewType === historyViewType.PROJECT ? projectData : candidateData
			)) {
				if (viewType === historyViewType.PROJECT) {
					const projectName = value.project ? value.project.toLowerCase() : '';
					const subject = value.subject ? value.subject.toLowerCase() : '';
					const sent_localTZ = transformDateToLocalTZ(value.sent_time);

					if (
						projectName.includes(event.target.value.toLowerCase()) ||
						subject.includes(event.target.value.toLowerCase()) ||
						sent_localTZ.includes(event.target.value.toLowerCase())
					) {
						searchResultByWord.push(value);
					}
				} else if (viewType === historyViewType.CANDIDATE) {
					const name = value.name ? value.name.toLowerCase() : '';
					const subject = value.subject ? value.subject.toLowerCase() : '';
					const company = value.company ? value.company.toLowerCase() : '';
					const project = value.project ? value.project.toLowerCase() : '';
					const email = value.email ? value.email.toLowerCase() : '';
					const sent_localTZ = transformDateToLocalTZ(value.sent_time);
					let seniority = '';
					if (activeTab === 0) {
						seniority = value.seniority ? value.seniority.toLowerCase() : '';
					}

					if (
						name.includes(event.target.value.toLowerCase()) ||
						company.includes(event.target.value.toLowerCase()) ||
						subject.includes(event.target.value.toLowerCase()) ||
						project.includes(event.target.value.toLowerCase()) ||
						email.includes(event.target.value.toLowerCase()) ||
						sent_localTZ.includes(event.target.value.toLowerCase())
					) {
						searchResultByWord.push(value);
					} else if (activeTab === 0) {
						if (seniority.includes(event.target.value.toLowerCase())) {
							searchResultByWord.push(value);
						}
					}
				}
			}
		}

		setDataBySearch(searchResultByWord);
	};

	const applySearchHandler = (
		projectData: EmailHistoryProject[],
		candidateData: EmailHistoryProjectDetail[]
	): any[] => {
		const searchResult: any[] = [];
		if (!searchWord || searchWord === undefined)
			return projectData && projectData.length > 0 ? projectData : candidateData;

		for (const [key, value] of Object.entries(
			viewType === historyViewType.PROJECT ? projectData : candidateData
		)) {
			if (viewType === historyViewType.PROJECT) {
				const projectName = value.project ? value.project.toLowerCase() : '';
				const subject = value.subject ? value.subject.toLowerCase() : '';
				const sent_localTZ = transformDateToLocalTZ(value.sent_time);

				if (
					projectName.includes(searchWord.toLowerCase()) ||
					subject.includes(searchWord.toLowerCase()) ||
					sent_localTZ.includes(searchWord.toLowerCase())
				) {
					searchResult.push(value);
				}
			} else if (viewType === historyViewType.CANDIDATE) {
				const name = value.name ? value.name.toLowerCase() : '';
				const subject = value.subject ? value.subject.toLowerCase() : '';
				const company = value.company ? value.company.toLowerCase() : '';
				const sent_localTZ = transformDateToLocalTZ(value.sent_time);
				let seniority = '';
				if (activeTab === 0) {
					seniority = value.seniority ? value.seniority.toLowerCase() : '';
				}

				if (
					name.includes(searchWord.toLowerCase()) ||
					company.includes(searchWord.toLowerCase()) ||
					subject.includes(searchWord.toLowerCase()) ||
					sent_localTZ.includes(searchWord.toLowerCase())
				) {
					searchResult.push(value);
				} else if (activeTab === 0) {
					if (seniority.includes(searchWord.toLowerCase())) {
						searchResult.push(value);
					}
				}
			}
		}

		return searchResult;
	};

	const handleCandidateProfile = async (
		e: React.MouseEvent<HTMLAnchorElement>,
		data: EmailHistoryProjectDetail
	) => {
		e.preventDefault();
		console.log('talent select', data);

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then(
				(res) => {
					const result = res.data;
					console.log('fetchTalentDetail', result.message);
					const finalData = { ...result.data, ...data };
					setSelectedRow(finalData);
					setFolders(result.data.tags);
					setOpen(true);
				},
				(error) => {
					console.error('fetchTalentDetail error', error);
				}
			)
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
			});
	};

	const goSearchTalents = () => {
		let validParams: validParamType = {};
		for (const [key, value] of Object.entries(search)) {
			if (value.length !== 0) validParams[key] = value;
		}

		let filteredTalentsAfterFilter = [];
		if (Object.keys(validParams).length > 0) {
			for (const [key, value] of Object.entries(
				searchWord && searchWord.length > 0
					? dataBySearch
					: viewType === historyViewType.PROJECT
					? projectData
					: candidateData
			)) {
				//fetch all talents data
				const array = Object.keys(validParams).filter((param) => {
					if (param === 'project' && value[param] === null) {
						value[param] = 'None';
					}
					//search parameter
					if (!value[param]) return true; //if has null value, cannot match

					return !validParams[param].includes(value[param]);
				});

				if (array.length === 0) {
					filteredTalentsAfterFilter.push(value);
				}
			}
		}

		//search data by dates
		let filteredTalentsAfterDates = [];
		if (Object.keys(validParams).length > 0) {
			if (filteredTalentsAfterFilter.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of filteredTalentsAfterFilter) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
							filteredTalentsAfterDates.push(item);
						}
					}
				} else {
					filteredTalentsAfterDates = [...filteredTalentsAfterFilter];
				}
			}
		} else {
			const data = searchWord
				? dataBySearch
				: viewType === historyViewType.PROJECT
				? projectData
				: candidateData;
			if (data.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of data) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
							filteredTalentsAfterDates.push(item);
						}
					}
				} else {
					filteredTalentsAfterDates = [...data];
				}
			} else {
				if (viewType === historyViewType.PROJECT) {
					const result = applySearchHandler(projectData, [] as EmailHistoryProjectDetail[]);
					filteredTalentsAfterDates = [...result];
					setDataBySearch(result);
					setFilterOptions(searchOptions(result));
				} else if (viewType === historyViewType.CANDIDATE) {
					const result = applySearchHandler([] as EmailHistoryProject[], candidateData);
					filteredTalentsAfterDates = [...result];
					setDataBySearch(result);
					setFilterOptions(searchOptions(result));
				}
			}
		}

		if (viewType === historyViewType.PROJECT) {
			setFilteredProjectData(filteredTalentsAfterDates);
			setFilteredCandidateData(null);
		} else if (viewType === historyViewType.CANDIDATE) {
			setFilteredCandidateData(filteredTalentsAfterDates);
			setFilteredProjectData(null);
		}
		setPage(1);

		/************  Search data only by filters, use for search field ************/
		//search data by filters
		const filteredResultDataAfterFilter: any[] = [];
		if (Object.keys(validParams).length > 0) {
			for (const [key, value] of Object.entries(
				viewType === historyViewType.PROJECT ? projectData : candidateData
			)) {
				//fetch all talents data
				const array = Object.keys(validParams).filter((param) => {
					if (param === 'project' && value[param] === null) {
						value[param] = 'None';
					}
					//search parameter
					if (!value[param]) return true; //if has null value, cannot match

					return !validParams[param].includes(value[param]);
				});

				if (array.length === 0) {
					filteredResultDataAfterFilter.push(value);
				}
			}
		}

		//search data by dates
		let filteredResultDataAfterDates: any[] = [];
		if (Object.keys(validParams).length > 0) {
			if (filteredResultDataAfterFilter.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of filteredResultDataAfterFilter) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
							filteredResultDataAfterDates.push(item);
						}
					}
				} else {
					filteredResultDataAfterDates = [...filteredResultDataAfterFilter];
				}
			}
		} else {
			if (
				viewType === historyViewType.PROJECT ? projectData.length > 0 : candidateData.length > 0
			) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of viewType === historyViewType.PROJECT ? projectData : candidateData) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
							filteredResultDataAfterDates.push(item);
						}
					}
				} else {
					filteredResultDataAfterDates =
						viewType === historyViewType.PROJECT ? [...projectData] : [...candidateData];
				}
			}
		}

		if (viewType === historyViewType.PROJECT) {
			setDataByFilteredProject(filteredResultDataAfterDates);
			setDataByFilteredCandidate([]);
		} else if (viewType === historyViewType.CANDIDATE) {
			setDataByFilteredProject([]);
			setDataByFilteredCandidate(filteredResultDataAfterDates);
		}
	};

	useEffect(() => {
		handleSwitch(viewType);
	}, [activeTab]);

	useEffect(() => {
		goSearchTalents();
	}, [search, dateRange, projectData, candidateData]); //search word,  date, project, candidate

	return (
		<>
			<Card className={`card-box mb-spacing-6-x2`}>
				<div className="card-header pr-2 bw-1">
					<div className="card-header--title">
						<b>Email History</b>
					</div>
					<div className="search-wrapper search-wrapper--grow text-right pr-2 mb-1">
						<TextField
							variant="outlined"
							margin="dense"
							onChange={(e) => searchHandler(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchTwoToneIcon style={{ color: '#0795fe' }} />
									</InputAdornment>
								),
								type: 'search'
							}}
						/>
					</div>
				</div>
				<List
					component="div"
					className="nav-line d-flex nav-tabs-primary pb-0 justify-content-between">
					<div className="d-flex">
						<ListItem
							className="ml-3"
							style={{ backgroundColor: activeTab === 0 ? '#ffffff' : 'transparent' }}
							button
							disableRipple={false}
							selected={activeTab === 0}
							onClick={() => toggle(0)}>
							<strong className="text-nowrap">OCInsights</strong>
						</ListItem>
						<ListItem
							style={{ backgroundColor: activeTab === 1 ? '#ffffff' : 'transparent' }}
							button
							disableRipple={false}
							selected={activeTab === 1}
							onClick={() => toggle(1)}>
							<strong className="text-nowrap">Extension</strong>
						</ListItem>
					</div>
					<div>
						<FormControl component="fieldset">
							<RadioGroup
								defaultValue="PROJECT"
								aria-label="view_type"
								value={viewType}
								onChange={handleViewType}
								row>
								<FormControlLabel value="PROJECT" control={<Radio />} label="Project" />
								<FormControlLabel value="CANDIDATE" control={<Radio />} label="Candidate" />
							</RadioGroup>
						</FormControl>
					</div>
				</List>
				<div className={`shadow-xxl-down p-3`}>
					<div className="table-responsive-sm">
						<TableContainer component={Paper}>
							{viewType === historyViewType.PROJECT && (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<span>Project</span>
												{search.project.length > 0 ? (
													<IMG
														src={require('../../../../assets/images/apps/filter_selected.png')}
														onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) =>
															handleProjectClick(e)
														}
													/>
												) : (
													<IMG
														src={require('../../../../assets/images/apps/filter.png')}
														onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) =>
															handleProjectClick(e)
														}
													/>
												)}
												<Popover
													open={openProject}
													anchorEl={anchorElProject}
													onClose={handleProjectClose}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center'
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'center'
													}}>
													<Autocomplete
														className={'filterHeader'}
														multiple
														size="small"
														options={filterOptions.project}
														getOptionLabel={(option: string) => option}
														value={search.project}
														onChange={(e, value) => handleAdvancedSearch(e, value, 'project')}
														renderInput={(params) => <TextField {...params} variant="standard" />}
													/>
												</Popover>
											</TableCell>
											<TableCell align="left">Email Subject</TableCell>
											<TableCell align="left">
												<span>Time</span>
												<Antd.Popover
													content={
														<RangePicker
															format="YYYY-MM-DD"
															value={dateRange}
															onChange={handleRangePicker}
														/>
													}
													trigger="click">
													{dateRange.length > 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
														/>
													) : (
														<IMG src={require('../../../../assets/images/apps/filter.png')} />
													)}
												</Antd.Popover>
											</TableCell>
											<TableCell align="left">Sent</TableCell>
											<TableCell align="left">Open</TableCell>
											<TableCell align="left">Replied</TableCell>
											{activeTab === 1 && <TableCell align="left">Sender</TableCell>}
										</TableRow>
									</TableHead>
									{promiseInProgress ? (
										<></>
									) : (
										<TableBody>
											{(filteredProjectData ? filteredProjectData : projectData)
												.slice(page * recordPerPage - recordPerPage, page * recordPerPage)
												.map((each, index) => (
													<TableRow key={index}>
														<TableCell component="th" scope="row">
															<a
																href="#/"
																onClick={(e) => handleClickAction(e, each.batch_seq, each.project)}>
																{each.project}
															</a>
														</TableCell>
														<TableCell align="left">{each.subject}</TableCell>
														<TableCell align="left">
															{transformDateToLocalTZ(each.sent_time)}
														</TableCell>
														<TableCell align="left">{each.sent_num}</TableCell>
														<TableCell align="left">{each.open_num}</TableCell>
														<TableCell align="left">{each.replied_num}</TableCell>
														{activeTab === 1 && <TableCell align="left">{each.sender}</TableCell>}
													</TableRow>
												))}
										</TableBody>
									)}
								</Table>
							)}
							{viewType === historyViewType.CANDIDATE && (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell align="left">Candidates</TableCell>
											<TableCell align="left">Project</TableCell>
											<TableCell align="left">Email</TableCell>
											{/* <TableCell align="left">
												<span>Company</span>
												{search.company.length > 0 ? (
													<IMG
														src={require('../../../../assets/images/apps/filter_selected.png')}
														onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) =>
															handleCompanyClick(e)
														}
													/>
												) : (
													<IMG
														src={require('../../../../assets/images/apps/filter.png')}
														onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) =>
															handleCompanyClick(e)
														}
													/>
												)}
												<Popover
													open={openCompany}
													anchorEl={anchorElCompany}
													onClose={handleCompanyClose}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center'
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'center'
													}}>
													<Autocomplete
														className={'filterHeader'}
														multiple
														size="small"
														options={filterOptions.company}
														getOptionLabel={(option: string) => option}
														value={search.company}
														onChange={(e, value) => handleAdvancedSearch(e, value, 'company')}
														renderInput={(params) => <TextField {...params} variant="standard" />}
													/>
												</Popover>
											</TableCell> */}
											{/* {activeTab === 0 && (
												<TableCell align="left">
													<span>Seniority</span>
													{search.seniority.length > 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
															onClick={(
																e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>
															) => handleSeniorityClick(e)}
														/>
													) : (
														<IMG
															src={require('../../../../assets/images/apps/filter.png')}
															onClick={(
																e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>
															) => handleSeniorityClick(e)}
														/>
													)}
													<Popover
														open={openSeniority}
														anchorEl={anchorElSeniority}
														onClose={handleSeniorityClose}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'center'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'center'
														}}>
														<Autocomplete
															className={'filterHeader'}
															multiple
															size="small"
															options={filterOptions.seniority}
															getOptionLabel={(option: string) => option}
															value={search.seniority}
															onChange={(e, value) => handleAdvancedSearch(e, value, 'seniority')}
															renderInput={(params) => <TextField {...params} variant="standard" />}
														/>
													</Popover>
												</TableCell>
											)} */}
											<TableCell align="left">Email Subject</TableCell>
											<TableCell align="left">
												<span>Time</span>
												<Antd.Popover
													content={
														<RangePicker
															format="YYYY-MM-DD"
															value={dateRange}
															onChange={handleRangePicker}
														/>
													}
													trigger="click">
													{dateRange.length > 0 ? (
														<IMG
															src={require('../../../../assets/images/apps/filter_selected.png')}
														/>
													) : (
														<IMG src={require('../../../../assets/images/apps/filter.png')} />
													)}
												</Antd.Popover>
											</TableCell>
											<TableCell align="left">Sent</TableCell>
											<TableCell align="left">Open</TableCell>
											<TableCell align="left">Replied</TableCell>
										</TableRow>
									</TableHead>
									{promiseInProgress ? (
										<></>
									) : (
										<TableBody>
											{(filteredCandidateData ? filteredCandidateData : candidateData)
												.slice(page * recordPerPage - recordPerPage, page * recordPerPage)
												.map((each, index) => {
													const {
														id,
														name,
														linkedin,
														position,
														company,
														seniority,
														subject,
														project,
														sent_time,
														open_time,
														sent,
														open,
														reply,
														bounce,
														email
													} = each;

													return (
														<TableRow key={index}>
															<TableCell component="th" scope="row">
																{activeTab === 0 && (
																	<a href={linkedin} target="_blank">
																		{name}
																	</a>
																)}
																{activeTab === 1 && (
																	<a href={linkedin} target="_blank">
																		{name}
																	</a>
																)}
															</TableCell>
															<TableCell align="left">{project}</TableCell>
															<TableCell align="left">{email}</TableCell>
															{/* <TableCell align="left">{company}</TableCell>
															{activeTab === 0 && (
																<TableCell align="left">
																	{seniority ? seniority.substring(4) : ''}
																</TableCell>
															)} */}
															<TableCell align="left">{subject}</TableCell>
															<TableCell align="left">
																{transformDateToLocalTZ(sent_time)}
															</TableCell>
															<TableCell align="left">
																{bounce === 0 && sent === 1 && (
																	<DoneIcon fontSize={'small'} color={'primary'} />
																)}
																{bounce === 1 && sent === 1 && (
																	<TIMERIMG
																		src={require('../../../../assets/images/apps/timer.png')}
																	/>
																)}
																{bounce === 0 && sent === 0 && (
																	<TIMERIMG
																		src={require('../../../../assets/images/apps/timer.png')}
																	/>
																)}
															</TableCell>
															<TableCell>
																{bounce === 0 && sent === 1 && open === 1 && (
																	<DoneAllIcon fontSize={'small'} color={'primary'} />
																)}
															</TableCell>
															<TableCell>
																{bounce === 0 && sent === 1 && open === 1 && reply === 1 && (
																	<img
																		className="img-20"
																		src={require(`../../../../assets/images/apps/replied.png`)}
																	/>
																)}
															</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									)}
								</Table>
							)}
							{promiseInProgress && <LoadingIndicator />}
						</TableContainer>
						<div className="divider" />
						<div className="divider" />
						<div className="p-3 d-flex justify-content-center">
							<Pagination
								page={page}
								onChange={handleChangePage}
								className="pagination-first"
								size="small"
								count={
									viewType === historyViewType.PROJECT
										? filteredProjectData
											? Math.ceil(filteredProjectData.length / recordPerPage)
											: Math.ceil(projectData.length / recordPerPage)
										: filteredCandidateData
										? Math.ceil(filteredCandidateData.length / recordPerPage)
										: Math.ceil(candidateData.length / recordPerPage)
								}
							/>
						</div>
					</div>
				</div>
			</Card>

			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				fullWidth
				maxWidth="md"
				open={projectDetail.open}
				onClose={() => setProjectDetail({ ...projectDetail, open: false })}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					<span>{projectDetail.projectName}</span>
				</DialogTitle>
				<DialogContent className={'p-4'}>
					<Card className="shadow-xxl p-3">
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell align="left">Candidates</TableCell>
									{/* <TableCell align="left">Company</TableCell>
									{activeTab === 0 && <TableCell align="left">Seniority</TableCell>} */}
									<TableCell align="left">Email</TableCell>
									<TableCell align="left">Sent</TableCell>
									<TableCell align="left">Open</TableCell>
									<TableCell align="left">Replied</TableCell>
								</TableRow>
							</TableHead>
							{projectDetail.loading ? <></> : <TableBody>{renderRows(selectedProject)}</TableBody>}
						</Table>
						{projectDetail.loading && <LoadingIndicator />}
					</Card>
				</DialogContent>
				<DialogActions className="p-4">
					<Button
						color="primary"
						variant="contained"
						onClick={() => setProjectDetail({ ...projectDetail, open: false })}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
