import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { NavLink } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

function TransitionUp(props) {
	return <Slide {...props} direction="up" style={{ backgroundColor: 'grey' }} />;
}

export default function Companies({ orgs, setSubscribe, getOrgs }) {
	const [open, setOpen] = useState(false);
	const [transition, setTransition] = useState(undefined);

	const handleClose = () => {
		setOpen(false);
	};

	const [flag, setFlag] = useState([true, false, false]);

	const handleFlag = (event, key) => {
		event.preventDefault();
		let temp = flag;
		temp[key] = !temp[key];
		setFlag([...temp]);
		if (temp[key]) {
			setTransition(() => TransitionUp);
			setOpen(true);
		}
	};

	const handleFollowCompany = (companyId, subscribed) => {
		setSubscribe({ companyId, subscribed });
		if (subscribed === 0) {
			setTransition(() => TransitionUp);
			setOpen(true);
		}
	};

	console.log('orgs: ', orgs);

	const displayOrgs = (orgs) =>
		orgs.map((org) => {
			const { id, name, logo, available } = org;

			return (
				<Grid key={id} item md={4} lg={12} xl={4}>
					<Card className="card-box card-box-hover">
						<div className="card-tr-actions">
							{/* <Button
								onClick={() => available && handleFollowCompany(id, org.subscribed)}
								className="p-0 d-40 btn-transition-none border-0 btn-pill btn-animated-icon-sm btn-outline-first"
								variant="outlined">
								<span className="btn-wrapper--icon d-flex justify-content-center align-items-center">
									{org.subscribed === 1 ? (
										<FontAwesomeIcon icon={['fas', 'heart']} color={'red'} />
									) : (
										<FontAwesomeIcon icon={['far', 'heart']} />
									)}
								</span>
							</Button> */}
						</div>
						<div className="text-center py-3">
							<div className="d-70 rounded-lg border-0 my-3 shadow-xxl btn-icon p-2 bg-white card-icon-wrapper mx-auto">
								<img
									className="d-40 rounded-lg"
									alt="logo"
									src={require(`../../../../assets/images/stock-logos/${logo}`)}
								/>
							</div>
							<div className="font-size-xl font-weight-bold pt-2 text-black">{name}</div>
							<div className="divider mx-auto my-3" />

							{available ? (
								<div className="text-center px-3">
									<NavLink
										activeClassName="active"
										// className="nav-link-simple"
										className=" nav-link-simple"
										to={`/OrgDetail/${id}`}>
										<Button
											fullWidth
											className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark">
											<span className="btn-wrapper--icon">
												<FontAwesomeIcon icon={['far', 'user-circle']} />
											</span>
											<span className="btn-wrapper--label">View Details</span>
										</Button>
									</NavLink>
								</div>
							) : (
								<div className="text-center px-3">
									<Button
										fullWidth
										className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark">
										<span className="btn-wrapper--icon">
											<FontAwesomeIcon icon={['fas', 'lock']} />
										</span>
										<span className="btn-wrapper--label">View Detail</span>
									</Button>
								</div>
							)}
						</div>
					</Card>
				</Grid>
			);
		});

	return (
		<>
			<Grid container spacing={6}>
				{displayOrgs(orgs)}
			</Grid>
			<div className="d-flex align-items-center justify-content-center flex-wrap">
				<Snackbar
					open={open}
					className="text-white"
					onClose={handleClose}
					TransitionComponent={transition}
					message="Subscribed successfully! You will be notified when updates of this company are available via emails and notifications."
				/>
			</div>
		</>
	);
}
