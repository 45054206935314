import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import clsx from 'clsx';
import https from 'https';
import React, { useEffect, useState, useRef } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../theme/loadingIndicator';
import { get } from '../../utils/request';
import url from '../../utils/url';
import Terms from './components/terms';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	}
}));

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

export default function AutoNote() {
	const classes = useStyles();
	const { promiseInProgress } = usePromiseTracker();
	const [mainData, setMainData] = useState([]);
	const [checkers, setCheckers] = useState({});
	const [numberOfSelected, setNumberOfSelected] = useState(0);
	const [totalNumber, setTotalNumber] = useState(0);
	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const [isNewAccountOpen, setNewAccountOpen] = useState(false);
	const [account, setAccount] = useState({
		account: null,
		password: null,
		accountName: null,
		type: 1,
		weeklyQuota: 0
	});
	const [termChecked, setTermChecked] = useState(false);
	const [isValidAccountName, setValidAccountName] = useState(true);
	const [isSendDisabled, setSendDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [accountsPwdOpen, setAccountsPwdOpen] = useState([]);
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const [isTermsOpen, setTermsOpen] = useState(false);
	const [scroll, setScroll] = useState('paper');

	const [linkedinInfo, setLinkedinInfo] = useState([]);
	const [selectedLinkedinAccount, setLinkedinAccount] = useState({
		email: '',
		username: '',
		weeklyQuota: '',
		password: ''
	});

	const isEmpty = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent === undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 || flag2;
	};

	const isCheckboxAllEmpty = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = true;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent !== undefined) {
				flag2 = false;
				break;
			}
		}

		return flag1 || flag2;
	};

	const handleClose = () => {
		setNewAccountOpen(false);
		setAccount({
			account: null,
			password: null,
			accountName: null,
			type: 1,
			weeklyQuota: 0
		});
		setValidAccountName(true);
		setLinkedinAccount({ email: '', username: '', weeklyQuota: '', password: '' });
	};

	const handleCreateAccount = async () => {
		const isValid = await axios
			.post(url.validateAccount(), { accountName: account.accountName }, config)
			.then((res) => {
				console.log(res.data.data);
				return res.data.data;
			})
			.catch((e) => console.error('Check account valid has error', e));

		if (!isValid) {
			setValidAccountName(false);
			return;
		}

		setLoading(true);
		setSendDisabled(true);
		await axios
			.post(
				url.createCredentialAccount(),
				{
					account: account.account,
					password: account.password,
					accountName: account.accountName,
					type: account.type,
					weeklyQuota: account.weeklyQuota
				},
				config
			)
			.then((res) => {
				console.log(res.data.data);
				setLoading(false);
				setSendDisabled(false);
			})
			.catch((e) => console.error('Create account has error', e));

		handleClose();
		loadCredentialAccounts();
		loadLinkedinAccounts();
	};

	const handlePassword = (pwd) => {
		let password = '';
		if (pwd) {
			const len = pwd.length;
			for (let i = 0; i < len; i++) {
				password = password.concat('*');
			}
		}
		return password;
	};

	const handleDelete = async () => {
		console.log('delete accounts.....');

		const deleteList = [];
		for (const [id, value] of Object.entries(checkers)) {
			console.log('id', id, 'value', value);
			if (value !== undefined) {
				deleteList.push(parseInt(id));
			}
		}

		await axios
			.delete(url.deleteCredentialAccounts(), {
				withCredentials: true,
				data: { accountIds: deleteList }
			})
			.then((res) => {
				console.log(res.data.message);
				setDeleteOpen(false);
				setNumberOfSelected(0);
			})
			.catch((e) => console.error('Delete accounts has error', e));

		loadCredentialAccounts();
		loadLinkedinAccounts();
	};

	const renderRows = (data) => {
		return data
			? data.map((each, idx) => {
					const { id, account, accountName, company, password, type } = each;

					return (
						<>
							<tr key={id}>
								<td style={{ width: '6%' }} className="text-center">
									<Checkbox
										checked={checkers[id] !== undefined}
										onChange={(e) => {
											if (e.target.checked) {
												setCheckers((prevState) => ({ ...prevState, [id]: each }));
												setNumberOfSelected(numberOfSelected + 1);
											} else {
												setCheckers((prevState) => ({ ...prevState, [id]: undefined }));
												setNumberOfSelected(numberOfSelected - 1);
											}
										}}
									/>
								</td>
								<td className="text-left pl-2">{accountName}</td>
								<td className="text-left pl-2">{company}</td>
								<td className="text-left pl-2">{account}</td>
								<td className="text-left pl-2">
									{accountsPwdOpen[idx] ? password : handlePassword(password)}
									<IconButton
										onClick={() => {
											const accounts = [...accountsPwdOpen];
											accounts[idx] = !accounts[idx];
											setAccountsPwdOpen(accounts);
										}}
										edge="end">
										{accountsPwdOpen[idx] ? (
											<Visibility className="text-info" />
										) : (
											<VisibilityOff className="text-info" />
										)}
									</IconButton>
								</td>
								<td className="text-left pr-3">{type.name}</td>
							</tr>
						</>
					);
			  })
			: '';
	};

	const loadCredentialAccounts = () => {
		trackPromise(
			get(url.fetchCredentialAccounts()).then((res) => {
				console.log(res.data);
				setCheckers({});
				setMainData(res.data);
				setTotalNumber(res.data.length);

				const ary = [...Array(res.data.length)];
				ary.fill(false);
				setAccountsPwdOpen(ary);
				return res;
			})
		);
	};

	const loadLinkedinAccounts = () => {
		axios
			.post(url.fetchLinkedinAccounts(), {}, config)
			.then((res) => {
				console.log(res.data.data);
				const linkedins = res.data.data;
				const linkedinsArray = [];
				for (const [email, info] of Object.entries(linkedins)) {
					linkedinsArray.push({
						email: email,
						username: info.username,
						weeklyQuota: info.weeklyQuota,
						password: info.password
					});
				}
				setLinkedinInfo(linkedinsArray);
			})
			.catch((e) => console.error('loadLinkedinAccounts has error', e));
	};

	const handleChooseLinkedinAccount = (e, value) => {
		const account = value;
		if (account && typeof account !== 'undefined') {
			setLinkedinAccount(account);
			setAccount((prevState) => ({
				...prevState,
				account: account.email,
				accountName: account.username,
				weeklyQuota: account.weeklyQuota,
				password: account.password
			}));
		}
	};

	useEffect(() => {
		loadCredentialAccounts();
		loadLinkedinAccounts();
	}, []);

	// useEffect(() => {
	// 	if (account.account && account.accountName && account.password && account.type && termChecked) {
	// 		setSendDisabled(false);
	// 	} else {
	// 		setSendDisabled(true);
	// 	}
	// }, [account, termChecked]);

	const descriptionElementRef = useRef(null);
	useEffect(() => {
		if (isTermsOpen) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [isTermsOpen]);

	return (
		<>
			<Card className="card-box">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>Manage Linkedin Credentials</b>
					</div>
				</div>
			</Card>
			<Card className="shadow-xxl p-3">
				<CardContent className="p-0 mb-3">
					<div className={classes.root}>
						<Grid container spacing={1}>
							<Grid item xs={1}>
								<Button
									disabled={mainData.length > 2}
									className={classes.containedPrimary}
									size="small"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => setNewAccountOpen(true)}>
									Create
								</Button>
							</Grid>
							<Grid item xs={1}>
								<Button
									disabled={isCheckboxAllEmpty(checkers)}
									className={classes.containedPrimary}
									size="small"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => setDeleteOpen(true)}>
									Delete
								</Button>
							</Grid>
							<Grid container item xs={2} justify="flex-start" alignItems="center" direction="row">
								<div>
									{totalNumber} in total, {numberOfSelected} is selected
								</div>
							</Grid>
							<Grid item xs />
						</Grid>
					</div>
				</CardContent>
				<CardContent className="p-0">
					<div style={{ display: 'table', width: '100%' }} className="table-responsive-sm">
						<Table
							size="small"
							className="text-wrap mb-0 table-hover table-borderless font-size-sm">
							<thead className="thead">
								<tr className="text-left">
									<th className="pl-3 text-center">
										<Button
											onClick={() => {
												if (isEmpty(checkers)) {
													let local = {};
													mainData.map((each) => (local[each.id] = each));
													setCheckers(local);
													setNumberOfSelected(mainData.length);
												} else {
													let local = {};
													mainData.map((each) => (local[each.id] = undefined));
													setCheckers(local);
													setNumberOfSelected(0);
												}
											}}
											className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark">
											All
										</Button>
									</th>
									<th className="pl-2">Name</th>
									<th className="pl-2">Company</th>
									<th className="pl-2">Linkedin Sign in Email or Phone</th>
									<th className="pl-2">Password</th>
									<th className="pr-3">Type</th>
								</tr>
							</thead>
							{promiseInProgress ? <></> : <tbody>{renderRows(mainData)}</tbody>}
						</Table>
						{promiseInProgress && <LoadingIndicator />}
					</div>
				</CardContent>
			</Card>

			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				fullWidth
				open={isNewAccountOpen}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
				<DialogContent className={'p-4'}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card className="shadow-xxl p-3">
								<div className={clsx('tab-item-wrapper', { active: true })}>
									{/* <div className="mt-2 mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">User Name</span>
									</div>
									<div className="mb-3 d-flex align-items-center align-content-start">
										<TextField
											className="templatename-field"
											variant="outlined"
											onChange={(e) =>
												setAccount({
													...account,
													accountName: e.target.value.length > 0 ? e.target.value : null
												})
											}
											value={account.accountName}
										/>
										{!isValidAccountName && (
											<div className="font-size-sm text-danger font-italic ml-1">
												This account name is already used, please create an new one.
											</div>
										)}
									</div> */}
									<div className="mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">
											Linkedin Sign in Email or Phone
										</span>
									</div>
									<div className="mb-3">
										<Autocomplete
											disabled={linkedinInfo.length === 0}
											size="small"
											options={linkedinInfo}
											getOptionLabel={(option) =>
												option.username !== '' ? option.username + ' (' + option.email + ')' : ''
											}
											value={selectedLinkedinAccount}
											onChange={(event, value) => handleChooseLinkedinAccount(event, value)}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Linkedin Accounts"
													fullWidth
												/>
											)}
										/>
									</div>
									{/* <div className="mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">Password</span>
									</div>
									<div className="mb-2">
										<TextField

											className="templatename-field"
											type={isPasswordVisible ? 'text' : 'password'}
											fullWidth
											variant="outlined"
											value={account.password}
											onChange={(e) =>
												setAccount({
													...account,
													password: e.target.value.length > 0 ? e.target.value : null
												})
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={() => setPasswordVisible((prev) => !prev)}
															edge="end">
															{isPasswordVisible ? (
																<Visibility className="text-info" />
															) : (
																<VisibilityOff className="text-info" />
															)}
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</div> */}
									<div className="mb-3">
										<FormControlLabel
											control={
												<Checkbox
													checked={termChecked}
													onChange={(e) => {
														if (e.target.checked) {
															setTermChecked(true);
														} else {
															setTermChecked(false);
														}
													}}
												/>
											}
											label={
												<span>
													I agree to the{' '}
													<a className="text-info" onClick={() => setTermsOpen(true)}>
														Terms and Privacy
													</a>
												</span>
											}
										/>
									</div>
									<div className="mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">Type</span>
									</div>
									<div className="mb-2">
										<RadioGroup
											onChange={(e) => setAccount({ ...account, type: parseInt(e.target.value) })}
											value={account.type}
											name="type"
											row>
											<Grid container spacing={5}>
												<Grid item xs={12} md={6} lg={6}>
													<FormControlLabel value={1} control={<Radio />} label="Free" />
													<FormControlLabel
														value={2}
														control={<Radio />}
														label="Premium Business"
													/>
													<FormControlLabel value={3} control={<Radio />} label="Recruiter Lite" />
												</Grid>
												<Grid item xs={12} md={6} lg={6}>
													<FormControlLabel value={4} control={<Radio />} label="Premium Career" />
													<FormControlLabel value={5} control={<Radio />} label="Sales Navigator" />
													<FormControlLabel
														value={6}
														control={<Radio />}
														label="Recruiter Corporate"
													/>
												</Grid>
											</Grid>
										</RadioGroup>
									</div>
								</div>
							</Card>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="p-4">
					<Button color="primary" variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						disabled={isSendDisabled}
						className={classes.containedPrimary}
						onClick={handleCreateAccount}>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						Send
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isDeleteOpen}
				keepMounted
				onClose={() => setDeleteOpen(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete the selected account(s)?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteOpen(false)} color="primary" variant="outlined">
						Cancel
					</Button>
					<Button className={classes.containedPrimary} onClick={handleDelete}>
						Sure and continue
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isTermsOpen}
				fullWidth
				maxWidth="md"
				scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description">
				<DialogTitle id="scroll-dialog-title">Terms and Policy</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}>
						<Terms />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="outlined"
						onClick={() => {
							setTermChecked(false);
							setTermsOpen(false);
						}}>
						Decline
					</Button>
					<Button
						className={classes.containedPrimary}
						color="primary"
						variant="contained"
						onClick={() => {
							setTermChecked(true);
							setTermsOpen(false);
						}}>
						Accept
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
