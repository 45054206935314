import React from 'react';

const TestingPage = () => {
	return (
		<div>
			<h1>Testing Page</h1>
		</div>
	);
};

export default TestingPage;
