import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import {
	actions as projectsActions,
	getProjectsList
} from '../../../../redux/modules/projectsPage';

function ProjectViewRoute(props) {
	const { projectName, projects } = props;
	const { loadProjects } = props.projectsAction;

	const history = useHistory();

	if (projects && projects.length > 0) {
		history.push(`/savedprojects/projectedit/${encodeURIComponent(projectName)}`);
	}

	useEffect(() => {
		loadProjects();
	}, []);

	return <></>;
}

const mapStateToProps = (state, props) => {
	console.log(props);
	const projectName = props.match.params.projectname;
	const projectList = getProjectsList(state);
	return {
		projectName,
		projects: projectList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectViewRoute);
