import React, { useEffect } from 'react';
import { PageTitle } from '../../components';
import Projects from './components/Projects/index_1';
import { actions as recruitActions, getTalentsById } from '../../redux/modules/recruitPage';
import { actions as projectsActions, getProjectsList } from '../../redux/modules/projectsPage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function SavedProjects(props) {
	const { loadProjects, addProject } = props.projectsAction;

	useEffect(() => {
		//loadProjects();
	}, []);
	console.log(props);

	return <Projects />;
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state) => {
	return {
		getProjectsList: getProjectsList(state),
		getTalentsById: getTalentsById(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		recruitActions: bindActionCreators(recruitActions, dispatch),
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedProjects);
