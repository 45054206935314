import { makeStyles } from '@material-ui/core/styles';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from 'react-router-dom';
import memory from '../../utils/memory';
import storage from '../../utils/storage';
import Customers from './Customer/customers';
import EmailLog from './EmailLog/emaillog';
import Users from './User/users';

const { Content, Footer, Sider } = Layout;
const useStyles = makeStyles({
	container: {
		display: 'flex'
	}
});

const routes = [
	{
		path: '/',
		exact: true,
		sidebar: (props) => <Users logout={props.logout} redirectHome={props.redirectHome} />
	},
	{
		path: '/customers',
		exact: true,
		sidebar: (props) => <Customers logout={props.logout} redirectHome={props.redirectHome} />
	},
	{
		path: '/emaillog',
		exact: true,
		sidebar: (props) => <EmailLog logout={props.logout} redirectHome={props.redirectHome} />
	}
];

export default withCookies(function UserManagement(props) {
	// const classes = useStyles();
	const history = useHistory();

	const handleLogout = () => {
		storage.removeUser();
		memory.user = {};
		props.cookies.remove('token');
		history.push('/login');
	};

	const handleRedirectHome = () => {
		history.push('/Dashboard');
	};

	useEffect(() => {
		if (memory.user.role !== 'admin') {
			handleLogout();
		}
	}, []);

	return (
		<Router>
			<Layout style={{ padding: 24, height: '100vh' }}>
				<Sider breakpoint="lg" collapsedWidth="0">
					<div style={{ height: 32, backgroundColor: 'rgba(255, 255, 255, 0.2)', margin: 16 }} />
					<Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
						<Menu.Item key="1">
							<span className="nav-text">
								<Link to="/">User Management</Link>
							</span>
						</Menu.Item>
						<Menu.Item key="2">
							<span className="nav-text">
								<Link to="/customers">Customer Permission</Link>
							</span>
						</Menu.Item>
						<Menu.Item key="3">
							<span className="nav-text">
								<Link to="/emaillog">Email Log</Link>
							</span>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout>
					<Content style={{ marginLeft: 16, marginRight: 16 }}>
						<div style={{ padding: 24, background: '#fff', height: '100%' }}>
							<Switch>
								{routes.map((route, index) => (
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										children={
											<route.sidebar logout={handleLogout} redirectHome={handleRedirectHome} />
										}
									/>
								))}
							</Switch>
						</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}>OCBang ©2023</Footer>
				</Layout>
			</Layout>
		</Router>
	);
});
