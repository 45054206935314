import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as projectsActions, getProjectsList } from '../../redux/modules/projectsPage';
import {
	actions as recruitActions,
	getTalentsById,
	searchOptions
} from '../../redux/modules/recruitPage';
import RecruitTable from './components/RecruitTable/index_refactor';
import url from '../../utils/url';
import { get, post } from '../../utils/request';

function Recruit(props) {
	const { loadProjects } = props.projectsAction;

	const [positions, setPositions] = useState([]);

	useEffect(() => {
		loadProjects();
	}, []);

	useEffect(() => {
		get(url.fetchPositionList()).then((res) => {
			console.log('position data', res.data);
			setPositions(res.data);
		});
	}, []);

	return (
		<>
			<RecruitTable
				projects={props.getProjectsList}
				loadProjects={loadProjects}
				positions={positions}
			/>
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state, props) => {
	const talentData = getTalentsById(state);

	return {
		getProjectsList: getProjectsList(state),
		getTalentsById: talentData,
		searchOptions: searchOptions(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		recruitActions: bindActionCreators(recruitActions, dispatch),
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruit);
