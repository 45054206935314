export enum usageTraceLabels {
	search = 'search',
	viewProject = 'viewProject',
	traceReport = 'traceReport',
	searchTalents = 'searchTalents',
	talentAnalytics = 'talentAnalytics',
	dashboard = 'dashboard',
	searchContact = 'searchContact'
}

export enum ActionType {
	INITIAL = 'INITIAL',
	UNREAD = 'UNREAD',
	NOREPLY = 'NOREPLY'
}

export enum EmailSequenceType {
	ACTION_FIRST_EMAIL = 'ACTION_FIRST_EMAIL',
	ACTION_EXTRA_EMAIL = 'ACTION_EXTRA_EMAIL',
	ACTION_LAST_EMAIL = 'ACTION_LAST_EMAIL',
	EMAIL_INITIAL = 'Email_Initial',
	ACTION_SINGLE_EMAIL = 'ACTION_SINGLE_EMAIL'
}

export enum ActionLabel {
	ACTIVE = 'active',
	ACTION_TYPE = 'actionType'
}

export enum CustomizedtagsType {
	GENERAL = 'GENERAL',
	REC_CAN = 'REC_CAN',
	REC_FILTER = 'REC_FILTER',
	LINKEDIN_TRANSFER = 'LINKEDIN_TRANSFER',
	OCI_TRANSFER = 'OCI_TRANSFER'
}

export enum talentDuplicateProcessType {
	PENDING = 'PENDING',
	DONE = 'DONE'
}
