import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

const PageTitle = (props) => {
	const {
		pageTitleStyle,
		pageTitleBackground,
		pageTitleShadow,
		pageTitleIconBox,
		pageTitleDescription,
		titleHeading,
		titleDescription,
		children,
		logo
	} = props;

	return (
		<>
			<div
				className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
					'app-page-title--shadow': pageTitleShadow
				})}>
				<div>
					<div className="app-page-title--first">
						{/*{pageTitleIconBox && (*/}
						{/*  <div className="app-page-title--iconbox d-70">*/}
						{/*    <div className="d-70 d-flex align-items-center justify-content-center display-1">*/}
						{/*      {*/}
						{/*        logo?   <img*/}
						{/*          className="d-80"*/}
						{/*          alt="..."*/}
						{/*          src={require(`../../assets/images/stock-logos/${logo}`)}*/}
						{/*        /> : <HomeWorkTwoToneIcon className="text-primary" />*/}
						{/*      }*/}
						{/*    </div>*/}
						{/*  </div>*/}
						{/*)}*/}
						{pageTitleIconBox &&
							(logo ? (
								<div className="app-page-title--iconbox d-70">
									<div className="d-70 d-flex align-items-center justify-content-center display-1">
										{
											<img
												className="d-80"
												alt="..."
												src={require(`../../assets/images/stock-logos/${logo}`)}
											/>
										}
									</div>
								</div>
							) : (
								''
							))}
						<div className="app-page-title--heading">
							<h1>{titleHeading}</h1>
							{pageTitleDescription && (
								<div className="app-page-title--description">{titleDescription}</div>
							)}
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center">{children}</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	pageTitleStyle: state.ThemeOptions.pageTitleStyle,
	pageTitleBackground: state.ThemeOptions.pageTitleBackground,
	pageTitleShadow: state.ThemeOptions.pageTitleShadow,
	pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
	pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
