import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import url from '../../utils/url';
import { get, del } from '../../utils/request';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { CSVLink, CSVDownload } from 'react-csv';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headers = [
	{ label: 'Name', key: 'name' },
	{ label: 'Title', key: 'title' },
	{ label: 'Company', key: 'company' },
	{ label: 'Location', key: 'location' },
	{ label: 'Verified Email', key: 'verifiedEmails' },
	{ label: 'Phone', key: 'phone' },
	{ label: 'Unverified Email', key: 'unverifiedEmails' },
	{ label: 'Credit', key: 'credit' }
];

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

export default function Contact() {
	const classes = useStyles();
	const [mainData, setMainData] = useState([]); // for not show loading -> []
	const [resultData, setResultData] = useState([]);
	const [filterOptions, setFilterOptions] = useState({
		credit: ['0', '1']
	});
	const recordPerPage = 10;
	const [filteredData, setFilteredData] = useState(null); // for paging -> null
	const [page, setPage] = useState(1);
	const [checkers, setCheckers] = useState({});
	const [isDisabled, setDisabled] = useState(true);
	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const [checkersData, setCheckersData] = useState([]);
	const [anchorElFilter, setAnchorElFilter] = useState(null);
	const [searchWord, setSearchWord] = useState('');
	const [numberOfSelected, setNumberOfSelected] = useState(0);
	const [totalNumber, setTotalNumber] = useState(0);

	const [search, setSearch] = useState({
		credit: []
	});

	const handleChangePage = (e, value) => {
		setPage(value);
		const local = filteredData ? filteredData : resultData;
		console.log('page: ', value, 'resultData: ', resultData, 'filteredData: ', filteredData);
		setMainData(local.slice(value * recordPerPage - recordPerPage, value * recordPerPage));
	};

	const handleDelete = async () => {
		console.log('delete candidates.....');
		const deleteList = [];
		for (const [id, value] of Object.entries(checkers)) {
			console.log('id', id, 'value', value);
			if (value !== undefined) {
				deleteList.push(id);
			}
		}
		await del(url.deleteCandidates(), { candidates: deleteList })
			.then((res) => {
				console.log(`delete candidates successfully`, res);
				loadCandidatesData();
				setDeleteOpen(false);
				setCheckersData([]);
				setCheckers({});
			})
			.catch((error) => {
				console.log(`delete candidates fails!`, error);
			});

		setNumberOfSelected(numberOfSelected - deleteList.length);
	};

	const handleFilterClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElFilter(event.currentTarget);
	};
	const handleFilterClose = (event) => {
		setAnchorElFilter(null);
	};
	const filterOpen = Boolean(anchorElFilter);

	const handleAdvancedSearch = (e, value, name) => {
		setSearch({
			...search,
			[name]: value
		});
	};

	const goSearchTalents = () => {
		let filteredTalents = [];
		let validParams = {};
		for (const [key, value] of Object.entries(search)) {
			if (value.length !== 0) validParams[key] = value;
		}

		for (const [key, value] of Object.entries(resultData)) {
			//fetch all contact data
			const array = Object.keys(validParams).filter((param) => {
				//search parameter
				return !validParams[param].includes(value[param].toString());
			});

			if (array.length === 0) {
				filteredTalents.push(value);
			}
		}

		// if (Object.keys(validParams).length === 0) {
		//     filteredTalents = resultData;
		// }

		//search by word for filteredTalents
		const searchResultByWord = [];
		if (searchWord.length > 0) {
			for (const [key, value] of Object.entries(
				Object.keys(validParams).length > 0 ? filteredTalents : resultData
			)) {
				const name = value.name ? value.name.toLowerCase() : '';
				const title = value.title ? value.title.toLowerCase() : '';
				const company = value.company ? value.company.toLowerCase() : '';
				const location = value.location ? value.location.toLowerCase() : '';
				const verifiedEmails = value.verifiedEmails ? value.verifiedEmails.toLowerCase() : '';
				const unverifiedEmails = value.unverifiedEmails ? value.unverifiedEmails.toLowerCase() : '';
				const phones = value.phones ? value.phones.toLowerCase() : '';

				if (
					name.includes(searchWord.toLowerCase()) ||
					title.includes(searchWord.toLowerCase()) ||
					company.includes(searchWord.toLowerCase()) ||
					location.includes(searchWord.toLowerCase()) ||
					verifiedEmails.includes(searchWord.toLowerCase()) ||
					unverifiedEmails.includes(searchWord.toLowerCase()) ||
					phones.includes(searchWord.toLowerCase())
				) {
					searchResultByWord.push(value);
				}
			}
		}

		let finalFilterData = [];
		if (Object.keys(validParams).length === 0 && searchWord.length === 0) {
			setFilteredData(resultData);
			setMainData(resultData.slice(0, recordPerPage));
			finalFilterData = resultData;
		} else if (Object.keys(validParams).length > 0 && searchWord.length > 0) {
			setFilteredData(searchResultByWord);
			setMainData(searchResultByWord.slice(0, recordPerPage));
			finalFilterData = searchResultByWord;
		} else if (Object.keys(validParams).length > 0 && searchWord.length === 0) {
			setFilteredData(filteredTalents);
			setMainData(filteredTalents.slice(0, recordPerPage));
			finalFilterData = filteredTalents;
		} else if (Object.keys(validParams).length === 0 && searchWord.length > 0) {
			setFilteredData(searchResultByWord);
			setMainData(searchResultByWord.slice(0, recordPerPage));
			finalFilterData = searchResultByWord;
		}

		//handle with select problem,
		const newCheckers = {};
		const newCheckersData = [];
		setTotalNumber(finalFilterData.length);
		for (const record of finalFilterData) {
			if (checkers[record.id] !== undefined) {
				newCheckers[record.id] = record;
				newCheckersData.push(record);
			}
		}
		setCheckers(newCheckers);
		setCheckersData(newCheckersData);
		setNumberOfSelected(newCheckersData.length);

		setPage(1);
	};
	/******************************************** Multiple Selected Talent Start *************************/
	const isEmpty = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent === undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 || flag2;
	};

	const isSelected = (obj) => {
		let flag = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent !== undefined) {
				flag = true;
				break;
			}
		}
		return flag;
	};
	/******************************************** Multiple Selected Talent End *************************/
	const loadCandidatesData = () => {
		get(url.fetchContactInfo()).then((res) => {
			console.log('contact data', res.data);
			setResultData(res.data);
			setFilteredData(res.data);
			setTotalNumber(res.data.length);
			setMainData(res.data.slice(0, recordPerPage));
		});
	};

	useEffect(() => {
		loadCandidatesData();
	}, []);

	useEffect(() => {
		if (isSelected(checkers)) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [checkers]);

	useEffect(() => {
		console.log('search...');
		goSearchTalents();
	}, [search, searchWord]);

	useEffect(() => {
		if (totalNumber > 0 && numberOfSelected === totalNumber) {
			let newCheckersData = [];
			newCheckersData = [...newCheckersData, ...filteredData];
			setCheckersData(newCheckersData);
		} else if (numberOfSelected === 0) {
			setCheckersData([]);
		}
	}, [numberOfSelected]);

	const renderRows = (data) => {
		return data
			? data.map((each) => {
					const {
						id,
						name,
						title,
						company,
						location,
						linkedin,
						verifiedEmails,
						unverifiedEmails,
						phones,
						userId,
						credit
					} = each;

					return (
						<>
							<tr key={id}>
								<td style={{ width: '6%' }} className="text-center">
									<Checkbox
										checked={checkers[id] !== undefined}
										onChange={(e) => {
											if (e.target.checked) {
												setCheckers((prevState) => ({ ...prevState, [id]: each }));
												setNumberOfSelected(numberOfSelected + 1);
												setCheckersData([...checkersData, each]);
												//checkersData.push(each);
											} else {
												setCheckers((prevState) => ({ ...prevState, [id]: undefined }));
												setNumberOfSelected(numberOfSelected - 1);
												const index = checkersData.findIndex((item) => item === each);
												checkersData.splice(index, 1);
												setCheckersData(checkersData);
											}
										}}
									/>
								</td>
								<td>
									<div className="d-flex flex-column align-items-start">
										<div>
											<a
												className="font-weight-bold text-primary"
												href={`${linkedin}`}
												target="_blank">
												{name}
											</a>
										</div>
										<div>{title}</div>
									</div>
								</td>
								<td className="text-left pl-3">{company}</td>
								<td className="text-left text-break-wrap pr-3 pl-3">{location}</td>
								<td className="text-left">
									{verifiedEmails && verifiedEmails.length > 0 ? (
										<div>
											{verifiedEmails.split(',').map((email, index) => (
												<div key={index}>{email}</div>
											))}
										</div>
									) : (
										''
									)}
								</td>
								<td className="text-left pr-3 pl-3">
									{phones && phones.length > 0 ? (
										<div>
											{phones.split(',').map((phone, index) => (
												<div key={index}>{phone}</div>
											))}
										</div>
									) : (
										''
									)}
								</td>
								<td className="text-left text-break-wrap">
									{unverifiedEmails && unverifiedEmails.length > 0 ? (
										<div>
											{unverifiedEmails.split(',').map((email, index) => (
												<div key={index}>{email}</div>
											))}
										</div>
									) : (
										''
									)}
								</td>
								<td style={{ width: '6%' }} className="text-center pr-3">
									{credit}
								</td>
							</tr>
						</>
					);
			  })
			: '';
	};

	return (
		<>
			<Card className="card-box">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>My Contacts</b>
					</div>
					<div
						className={clsx('search-wrapper search-wrapper--grow text-right pr-2 mb-1', {
							'is-active': false
						})}>
						<TextField
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								e.persist();
								setSearchWord(e.target.value);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchTwoToneIcon style={{ color: '#0795fe' }} />
									</InputAdornment>
								),
								type: 'search'
							}}
						/>
					</div>
				</div>
			</Card>
			<Card className="shadow-xxl p-3">
				<CardContent className="p-0 mb-3">
					<div className={classes.root}>
						<Grid container spacing={1}>
							<Grid item xs={1}>
								<Button
									className={classes.containedPrimary}
									size="small"
									fullWidth
									variant="contained"
									color="primary"
									disabled={isDisabled}
									onClick={() => setDeleteOpen(true)}>
									Delete
								</Button>
							</Grid>
							<Grid item xs={1}>
								{isDisabled ? (
									<Button
										className={classes.containedPrimary}
										size="small"
										fullWidth
										variant="contained"
										color="primary"
										disabled={isDisabled}>
										Download
									</Button>
								) : (
									<CSVLink data={checkersData} headers={headers}>
										<Button
											className={classes.containedPrimary}
											size="small"
											fullWidth
											variant="contained"
											color="primary">
											Download
										</Button>
									</CSVLink>
								)}
							</Grid>
							<Grid container item xs={2} justify="flex-start" alignItems="center" direction="row">
								<div>
									{totalNumber} in total, {numberOfSelected} is selected
								</div>
							</Grid>
							<Grid item xs />
						</Grid>
					</div>
				</CardContent>
				<CardContent className="p-0">
					<div style={{ display: 'table', width: '100%' }} className="table-responsive-sm">
						<Table
							size={'small'}
							className="text-wrap mb-0 table-hover table-borderless font-size-sm">
							<thead className="thead">
								<tr className={'text-left'}>
									<th className="pl-3 pr-3 text-center">
										<Button
											onClick={() => {
												if (isEmpty(checkers)) {
													let local = {};
													filteredData.map((each) => (local[each.id] = each));
													setCheckers(local);
													setNumberOfSelected(filteredData.length);
												} else {
													let local = {};
													filteredData.map((each) => (local[each.id] = undefined));
													setCheckers(local);
													setNumberOfSelected(0);
												}
											}}
											className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark">
											All
										</Button>
									</th>
									<th>Name</th>
									<th className="pl-3">Company</th>
									<th className="pl-3">Location</th>
									<th>Verified Email</th>
									<th className="pl-3 pr-3">Phone</th>
									<th>Unverified Email</th>
									<th style={{ width: '6%' }} className="text-center">
										<div>
											Credit
											{search.credit.length > 0 ? (
												<IMG
													src={require('../../../src/assets/images/apps/filter_selected.png')}
													onClick={handleFilterClick}
												/>
											) : (
												<IMG
													src={require('../../../src/assets/images/apps/filter.png')}
													onClick={handleFilterClick}
												/>
											)}
										</div>
										<Popover
											open={filterOpen}
											anchorEl={anchorElFilter}
											onClose={handleFilterClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center'
											}}>
											<Autocomplete
												className={'filterHeader'}
												multiple
												size="small"
												options={filterOptions.credit}
												getOptionLabel={(option) => option}
												value={search.credit}
												onChange={(e, value) => handleAdvancedSearch(e, value, 'credit')}
												renderInput={(params) => <TextField {...params} variant="standard" />}
											/>
										</Popover>
									</th>
								</tr>
							</thead>
							<tbody>{renderRows(mainData.slice(0, recordPerPage))}</tbody>
						</Table>
					</div>
					<div className="divider" />
					<div className="divider" />
					<div className="p-3 d-flex justify-content-center">
						<Pagination
							page={page}
							onChange={handleChangePage}
							className="pagination-first"
							size="small"
							count={
								filteredData
									? Math.ceil(Object.keys(filteredData).length / recordPerPage)
									: Math.ceil(Object.keys(resultData).length / recordPerPage)
							}
						/>
					</div>
				</CardContent>
			</Card>
			<Dialog
				open={isDeleteOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setDeleteOpen(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogTitle id="alert-dialog-slide-title">{'Delete contact'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete these contacts?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.containedPrimary}
						onClick={handleDelete}
						color="primary"
						variant="contained">
						Delete
					</Button>
					<Button onClick={() => setDeleteOpen(false)} color="primary" variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
