import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Cascader,
	Select,
	Row,
	Col,
	Button,
	AutoComplete,
	Table,
	Modal,
	Divider,
	Checkbox,
	DatePicker,
	Label
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

export default function Editcustomer({
	isLoading,
	isVisible,
	handleCancel,
	customerData,
	handleFinish,
	companies
}) {
	const [form] = Form.useForm();
	const { Option } = Select;

	const formItemLayout = {
		labelCol: {
			span: 6
		},
		wrapperCol: {
			span: 14
		}
	};

	form.setFieldsValue({
		customername: customerData.customername,
		companyDetail: customerData.companyDetail,
		summary: customerData.summary,
		recruitment: customerData.recruitment,
		companies: customerData.dashboards,
		startDate: moment(customerData.startDate, 'YYYY-MM-DD'),
		endDate: moment(customerData.endDate, 'YYYY-MM-DD'),
		customerId: customerData.id
	});

	return (
		<Modal
			bodyStyle={{ height: '55vh' }}
			width={600}
			visible={isVisible}
			title="Edit Customer"
			footer={null}
			onCancel={handleCancel}>
			<Form {...formItemLayout} form={form} onFinish={() => handleFinish(form)}>
				<Form.Item
					name="customername"
					label="Customer"
					rules={[
						{
							required: true,
							message: 'Please input customer name!'
						}
					]}
					hasFeedback
					initialValue={customerData.customername}>
					<Input />
				</Form.Item>
				{/* <Form.Item
					label="Company Detail"
					name="companyDetail"
					valuePropName="checked"
					initialValue={customerData.companyDetail}>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label="Summary"
					name="summary"
					valuePropName="checked"
					initialValue={customerData.summary}>
					<Checkbox />
				</Form.Item> */}
				<Form.Item
					label="Recruitment"
					name="recruitment"
					valuePropName="checked"
					initialValue={customerData.recruitment}>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label="Start Date"
					name="startDate"
					rules={[
						{
							required: true,
							message: 'Please input start date!'
						}
					]}
					hasFeedback>
					<DatePicker />
				</Form.Item>
				<Form.Item
					label="End Date"
					name="endDate"
					rules={[
						{
							required: true,
							message: 'Please input end date!'
						}
					]}
					hasFeedback>
					<DatePicker />
				</Form.Item>
				<Form.Item
					name="companies"
					label="Unlock Companies"
					rules={[
						{ required: true, message: 'Please select your unlock companyies!', type: 'array' }
					]}
					initialValue={customerData.dashboards}>
					<Select mode="multiple" placeholder="Please select unlock companies">
						{companies &&
							companies.map((company) => {
								return (
									<Option key={company.companyId} value={company.companyId}>
										{company.name}
									</Option>
								);
							})}
					</Select>
				</Form.Item>
				<Form.Item name="customerId" initialValue={customerData.id} hidden>
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={isLoading}>
						Update
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
