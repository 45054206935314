import Switch from '@material-ui/core/Switch';
import React from 'react';

export default function CustomizedSwitch({ classProps, ...rest }) {
	return (
		<Switch
			focusVisibleClassName={classProps.focusVisible}
			disableRipple
			classes={{
				root: classProps.root,
				switchBase: classProps.switchBase,
				thumb: classProps.thumb,
				track: classProps.track,
				checked: classProps.checked
			}}
			{...rest}
		/>
	);
}
