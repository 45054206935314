import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import https from 'https';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useReducer,
	useRef,
	useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import sessionstorage from 'sessionstorage';
import styled from 'styled-components';
import { removeHtmlTag } from '../../../../utils/emailTemplateUtil';
import memory from '../../../../utils/memory';
import url from '../../../../utils/url';

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

function AutoConnectTemplate(
	{
		templateOptions,
		templates,
		classes,
		emailType,
		candidateEmailMapping,
		interval,
		scheduler,
		strategyId,
		sequenceId,
		handleSendDisabled,
		tagId,
		uploadFiles,
		uploadFileTags,
		defaultEmail
	},
	ref
) {
	const [letterSubject, setLetterSubject] = useState(
		typeof defaultEmail !== 'undefined' ? defaultEmail.subject : null
	);
	const [htmlBody, setHtmlBody] = useState(
		typeof defaultEmail !== 'undefined' ? defaultEmail.htmlBody : null
	);
	const [templateType, setTemplateType] = useState(null);
	const [templateId, setTemplateId] = useState(null);

	/****************************************************** File Upload Start  **********************************/

	const [isLoading, setLoading] = useState(false);
	const [myFiles, setMyFiles] = useState(uploadFiles !== undefined ? uploadFiles : []); //used to remove file
	const [file, setFile] = useState(
		uploadFileTags !== undefined ? uploadFileTags : { filename: [], path: [] }
	);

	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const fileRef = useRef(); //used to get updated file value in useCallback function
	fileRef.current = file;
	//sessionstorage.setItem(emailType, uploadFiles && uploadFiles.length > 0 ? uploadFiles[0].size : 0);

	const bytesToMegaBytes = (bytes, roundTo) =>
		roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);

	const removeFile = (f) => () => {
		const newFiles = [...myFiles];
		const index = newFiles.indexOf(f);

		newFiles.splice(index, 1);
		setMyFiles(newFiles);

		file.filename.splice(index, 1);
		file.path.splice(index, 1);

		const newSize = Number(sessionstorage.getItem(emailType)) - f.size;
		sessionstorage.setItem(emailType, newSize);

		setFile({
			//related send email
			filename: file.filename,
			path: file.path
		});

		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem(emailType)), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
		} else {
			fileRejections.splice(0, fileRejections.length);
		}
		forceUpdate();
	};

	const accumulate = (file) => {
		console.log('file size', file.size);
		const MBformat = bytesToMegaBytes(file.size, 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			return {
				message: `Single file size cannot be larger than ${limit} MB`
			};
		}

		if (!sessionstorage.getItem(emailType)) {
			sessionstorage.setItem(emailType, file.size);
		} else {
			const newSize = Number(sessionstorage.getItem(emailType)) + file.size;
			sessionstorage.setItem(emailType, newSize);
		}

		return null;
	};

	const onDropAccepted = useCallback(
		(acceptedFiles) => {
			console.log(acceptedFiles);
			setLoading(true);
			let attachment = new FormData();

			let email = memory.user.email.trim();

			let files = [];
			for (let i = 0; i < acceptedFiles.length; i++) {
				attachment.append('uploadFiles', acceptedFiles[i]);
				files.push(`${email}/${acceptedFiles[i].name}`);
			}

			const options = {
				params: {
					Keys: files,
					ContentType: 'application/x-www-form-urlencoded'
				},
				headers: {
					ContentType: 'application/x-www-form-urlencoded'
				}
			};

			axios
				.post(url.uploadAttachment(), attachment, options)
				.then(() => {
					let filenames = [];
					let paths = [];
					acceptedFiles.forEach((file) => {
						filenames.push(file.name);
						paths.push(`${email}/${file.name}`);
					});

					const newFileName = [...fileRef.current.filename, ...filenames];
					const newPath = [...fileRef.current.path, ...paths];

					setMyFiles((prevState) => [...prevState, ...acceptedFiles]);

					setFile({
						filename: newFileName,
						path: newPath
					});

					setLoading(false);
				})
				.catch((e) => console.log(e));
		},
		[myFiles, file]
	);

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		fileRejections,
		getRootProps,
		getInputProps
	} = useDropzone({
		onDropAccepted,
		accept: ['.pdf', '.docx', '.doc', '.rtf', '.pptx', '.ppt'],
		validator: accumulate
	});

	let fileRejectionItems = fileRejections.map(({ errors }) => (
		<>
			{errors.map((e, index) => (
				<Alert key={index} severity="warning" className="text-center mb-3">
					{e.message}
				</Alert>
			))}
		</>
	));

	let files = myFiles.map((file) => (
		<ListItem
			className="font-size-sm px-3 py-2 text-primary d-flex justify-content-start align-items-center"
			key={file.path}>
			<span>
				<span
					className="badge badge-pill bg-neutral-danger text-primary mr-2"
					onClick={removeFile(file)}>
					X
				</span>
				{file.path}
			</span>
			<span className="badge badge-pill bg-neutral-warning text-primary">
				{bytesToMegaBytes(file.size, 2)} MB
			</span>
		</ListItem>
	));

	useImperativeHandle(ref, () => ({
		validateFiles() {
			const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem(emailType)), 2);
			const limit = 5;
			if (Number(MBformat) > limit) {
				fileRejections.splice(0, fileRejections.length);
				fileRejections.push({
					errors: [{ message: `Total files size is larger than ${limit} MB` }]
				});
				forceUpdate();
				return false;
			}

			if (myFiles.length > 5) {
				fileRejections.splice(0, fileRejections.length);
				fileRejections.push({ errors: [{ message: `Maximum number of files upload: 5` }] });
				forceUpdate();
				return false;
			}

			return true;
		},
		sendEmail() {
			return axios
				.post(
					url.sendEmail(),
					{
						subject: letterSubject,
						candidateEmailMapping,
						htmlBody,
						approach: false,
						filename: file.filename,
						itemname: file.path,
						strategyId,
						templateId,
						opportunity: false,
						tagId
					},
					{
						withCredentials: true,
						httpsAgent: new https.Agent({ rejectUnauthorized: false }),
						headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
					}
				)
				.then((res) => {
					console.log('sendEmail', res.data.data);
					return res.data.data; //return findCandidates, maxStrategyId
				});
		},
		fetchGroupEmail() {
			return {
				subject: letterSubject,
				htmlBody,
				approach: false,
				filename: file.filename,
				itemname: file.path,
				strategyId,
				templateId,
				opportunity: false
			};
		},
		setSchedule() {
			return {
				subject: letterSubject,
				htmlBody,
				templateId,
				filename: file.filename.length > 0 ? `${file.filename}` : null,
				itemname: file.path.length > 0 ? `${file.path}` : null,
				interval,
				scheduler,
				strategyId,
				sequenceId,
				emailType
			};
		},
		handleClose() {
			setLetterSubject(null);
			setHtmlBody(null);
			setTemplateType(null);
			setTemplateId(null);
			setMyFiles([]);
			setFile({
				filename: [],
				path: []
			});
			sessionstorage.setItem(emailType, 0);
			fileRejections.splice(0, fileRejections.length);
		}
	}));

	/****************************************************** File Upload End  **********************************/

	const handleTemplate = (e) => {
		const selectedIndex = e.target.getAttribute('data-option-index');
		if (selectedIndex) {
			let selectedTemplate;
			if (sequenceId === 1) {
				const filterTemplates = templates.filter(
					(each) => each.irsType === 'autoemails_firstEmail_nonFirst' || each.irsType.length === 0
				);
				selectedTemplate = filterTemplates[selectedIndex];
			} else if (sequenceId === 2) {
				const filterTemplates = templates.filter(
					(each) => each.irsType === 'autoemails_followEmail_nonFirst' || each.irsType.length === 0
				);
				selectedTemplate = filterTemplates[selectedIndex];
			} else {
				const filterTemplates = templates.filter((each) => each.irsType != 'autonotes_newConn');
				selectedTemplate = filterTemplates[selectedIndex];
			}

			setHtmlBody(selectedTemplate.htmlBody);
			setLetterSubject(selectedTemplate.subject);
			setTemplateType(selectedTemplate.type);
			setTemplateId(selectedTemplate.templateId);
			handleSendDisabled(false);
			console.log('selectTemplate', selectedTemplate);
		} else {
			handleSendDisabled(true);
			setHtmlBody(null);
			setLetterSubject('');
			setTemplateType(null);
			setTemplateId(null);
		}
	};

	const handleLetterBody = (html) => {
		const filterHtmlBody = removeHtmlTag(html);

		if (filterHtmlBody.length > 0) {
			setHtmlBody(html);
		} else {
			setHtmlBody(null);
		}

		if (letterSubject && letterSubject.length > 0 && filterHtmlBody.length > 0) {
			handleSendDisabled(false);
		} else {
			handleSendDisabled(true);
		}
	};

	const handleLetterSubject = (e) => {
		if (e.target.value.length > 0) {
			setLetterSubject(e.target.value);
		} else {
			setLetterSubject('');
		}

		const filterHtmlBody = removeHtmlTag(htmlBody);

		if (filterHtmlBody.length > 0 && e.target.value.length > 0) {
			handleSendDisabled(false);
		} else {
			handleSendDisabled(true);
		}
	};

	useEffect(() => {
		const filterHtmlBody = removeHtmlTag(htmlBody);
		if (letterSubject && letterSubject.length > 0 && filterHtmlBody.length > 0) {
			handleSendDisabled(false);
		} else {
			handleSendDisabled(true);
		}
	}, [letterSubject, htmlBody]);

	useEffect(() => {
		sessionstorage.setItem(
			emailType,
			uploadFiles && uploadFiles.length > 0 ? uploadFiles[0].size : 0
		);
	}, []);

	const reactQuillRef = useRef(); // Initialize
	const handleCompanyButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{company}');
		quill.setSelection(position + '{company}'.length);
	};
	const handleTitleButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{title}');
		quill.setSelection(position + '{title}'.length);
	};

	return (
		<>
			<DialogContentText>
				<Autocomplete
					id="combo-box-demo"
					size="small"
					options={templateOptions}
					groupBy={(option) => option.type}
					getOptionLabel={(option) => option.name}
					filterOptions={(options, state) => {
						if (sequenceId === 1) {
							return options.filter(
								(each) =>
									each.irsType === 'autoemails_firstEmail_nonFirst' || each.irsType.length === 0
							);
						} else if (sequenceId == 2) {
							return options.filter(
								(each) =>
									each.irsType === 'autoemails_followEmail_nonFirst' || each.irsType.length === 0
							);
						}

						return options.filter((each) => each.irsType != 'autonotes_newConn');
					}}
					onChange={handleTemplate}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder="Search for a template"
							fullWidth
						/>
					)}
				/>
			</DialogContentText>
			<DialogContentText>
				<TextField
					onChange={handleLetterSubject}
					value={letterSubject}
					variant="outlined"
					fullWidth
					name={'subject'}
					id="standard-textarea"
					placeholder="Subject"
				/>
				<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
				<ReactQuill
					className={'quillContainer'}
					theme="snow"
					value={htmlBody}
					onChange={handleLetterBody}
					placeholder="Write about your opportunity..."
					ref={reactQuillRef}
				/>
			</DialogContentText>
			<DialogContentText className={'d-flex'}>
				<div className={'ml-auto'}>
					<span style={{ verticalAlign: 'middle', marginRight: '6px' }}>Insert</span>

					<div style={{ display: 'inline-block', marginRight: '0px' }}>
						<Button
							size="small"
							style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
							onClick={handleCompanyButtonClick}>{`{company}`}</Button>
					</div>

					<div
						style={{
							borderLeft: '1px solid #000',
							height: '15px',
							width: '1.5px',
							display: 'inline-block',
							verticalAlign: 'middle',
							margin: '0 5px'
						}}
					/>

					<div style={{ display: 'inline-block', marginLeft: '0px' }}>
						<Button
							size="small"
							style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
							onClick={handleTitleButtonClick}>
							{'{title}'}
						</Button>
					</div>
				</div>
			</DialogContentText>
			{templateType === 'Base' ? (
				<div className="font-size-xs text-dark">
					* If you use this template, please change the text in all square brackets before sending
				</div>
			) : (
				<div className="font-size-xs text-dark"></div>
			)}
			<hr />
			<Grid container direction={'row'}>
				<Grid item md={12}>
					<div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
						<input {...getInputProps()} />
						<div className="dropzone">
							{isDragAccept && (
								<div>
									<IMG src={require('../../../../assets/images/apps/attachment.png')} />
									<div className="font-size-sm text-success">All files will be uploaded!</div>
								</div>
							)}
							{isDragReject && (
								<div>
									<IMG src={require('../../../../assets/images/apps/attachment.png')} />
									<div className="font-size-sm text-danger">Some files will be rejected!</div>
								</div>
							)}
							{!isDragActive && (
								<IMG src={require('../../../../assets/images/apps/attachment.png')} />
							)}
							<div style={{ width: '2px' }} />
							<div className="font-weight-bold text-uppercase text-dark font-size-sm">
								Uploaded Files
							</div>
							<div style={{ width: '2px' }} />
							<div className="font-size-xs text-dark">* A file name cannot contain commas</div>
						</div>
					</div>
				</Grid>
				<Grid item md={12}>
					<div>
						{fileRejectionItems}
						{isLoading && files.length == 0 ? (
							<div className={classes.spinner}>
								<CircularProgress />
							</div>
						) : (
							<List component="div" className="font-size-sm">
								{files}
							</List>
						)}
					</div>
				</Grid>
			</Grid>
			<hr />
			<div className={'font-size-sm mt-4'}>
				<div>{memory.user.name}</div>
				<div>
					{memory.user.position} | {memory.user.customername}
				</div>
				<div>{memory.user.email}</div>
				<div>{memory.user.phone}</div>
				<div>{memory.user.location}</div>
				<a href={`${memory.user.linkedin}`}>LinkedIn</a>
			</div>
		</>
	);
}

export default forwardRef(AutoConnectTemplate);
