import createReducer from "../../../utils/createReducer";

/***********************************************************************************************************************
 * 													SCHEMA 														                                                         *
 * *********************************************************************************************************************/
export const schema = {
  name: "articles",
  id: "id"
}

/***********************************************************************************************************************
 * 													REDUCERS 														                                                       *
 * *********************************************************************************************************************/

const reducer = createReducer(schema.name);

/***********************************************************************************************************************
 * 													SELECT  														                                                       *
 * *********************************************************************************************************************/
export const articleById = (state, id) => {
  return state.entities.articles[id]

}
export default reducer;

