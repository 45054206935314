import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	AutoComplete,
	Table,
	Modal
} from 'antd';
import 'antd/dist/antd.css';

export default function Edituser({
	isLoading,
	isVisible,
	handleOK,
	handleCancel,
	userData,
	handleFinish,
	customers,
	userEmails
}) {
	const [form] = Form.useForm();
	const { Option } = Select;
	const name = userData.name;
	const firstname = name === undefined ? null : name.split(' ')[0];
	const lastname = name === undefined ? null : name.split(' ')[1];

	form.setFieldsValue({
		email: userData.email,
		firstname: firstname,
		country: userData.country,
		city: userData.city,
		linkedin: userData.linkedin,
		zipcode: userData.zipcode,
		password: userData.password,
		lastname: lastname,
		customerId: userData.customerId,
		state: userData.state,
		position: userData.position,
		phone: userData.phone,
		userId: userData.id,
		month360Credits: userData.month360Credits,
		monthSearchCredits: userData.monthSearchCredits,
		cronstatus: userData.cronstatus,
		remainingSearchCredits: userData.remainingSearchCredits,
		credits: userData.credits,
		usedSearchCredits: userData.usedSearchCredits,
		used360Credits: userData.used360Credits,
		totalSearchCredits: userData.totalSearchCredits,
		total360Credits: userData.total360Credits,
		userEmails: userData.sendingUserIds
	});

	return (
		<Modal
			bodyStyle={{ height: '100%' }}
			width={1000}
			visible={isVisible}
			title="Edit User"
			onCancel={handleCancel}
			footer={null}>
			<Form layout="vertical" form={form} onFinish={() => handleFinish(form)}>
				<Row>
					<Col flex={3}>
						<Form.Item
							style={{ width: '60%' }}
							label="Email"
							name="email"
							rules={[
								{
									type: 'email',
									message: 'The input is not valid E-mail!'
								},
								{
									required: true,
									message: 'Please input your E-mail!'
								}
							]}
							initialValue={userData.email}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="firstname"
							label="First Name"
							rules={[
								{
									required: true,
									message: 'Please input your first name!'
								}
							]}
							initialValue={firstname}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="country"
							label="Country"
							rules={[
								{
									required: true,
									message: 'Please input your country!'
								}
							]}
							initialValue={userData.country}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="city"
							label="City"
							rules={[
								{
									required: true,
									message: 'Please input your city!'
								}
							]}
							initialValue={userData.city}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="linkedin"
							label="Linkedin"
							rules={[
								{
									type: 'url',
									message: 'The input is not valid URL!'
								},
								{
									required: true,
									message: 'Please input your Linkedin!'
								}
							]}
							initialValue={userData.linkedin}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="zipcode"
							label="Zip/Post Code"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Please input your Zip/Post Code!'
							// 	}
							// ]}
							initialValue={userData.zipcode}>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="month360Credits"
							label="Monthly 360 Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="credits"
							label="Remaining 360 Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '30em' }}
							name="userEmails"
							label="Sending Emails"
							rules={[
								{ required: false, message: 'Please select your sending emails!', type: 'array' }
							]}
							initialValue={userData.sendingUserIds}>
							<Select mode="multiple" placeholder="Please select your sending emails!">
								{userEmails &&
									userEmails.map((user) => {
										return (
											<Option key={user.id} value={user.id}>
												<span>
													{user.name}: {user.email}
												</span>
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
					<Col flex={3}>
						<Form.Item
							style={{ width: '60%' }}
							name="password"
							label="Password"
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								}
							]}
							initialValue={userData.password}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="lastname"
							label="Last Name"
							rules={[
								{
									required: true,
									message: 'Please input your last name!'
								}
							]}
							initialValue={lastname}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="customerId"
							label="Customer"
							rules={[
								{
									required: true,
									message: 'Please choose a company!'
								}
							]}
							initialValue={userData.customerId}
							hasFeedback>
							<Select
								showSearch="true"
								style={{ width: 200 }}
								placeholder="Select a customer"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{customers &&
									customers.map((customer) => {
										return (
											<Option label={customer.customername} value={customer.id}>
												{customer.customername}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="state"
							label="State/Province"
							rules={[
								{
									required: true,
									message: 'Please input your state/province!'
								}
							]}
							initialValue={userData.state}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="position"
							label="Position"
							rules={[
								{
									required: true,
									message: 'Please input your position!'
								}
							]}
							initialValue={userData.position}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="phone"
							label="Phone"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Please input your phone number!'
							// 	}
							// ]}
							initialValue={userData.phone}>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="monthSearchCredits"
							label="Monthly Search Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="totalSearchCredits"
							label="Total Search Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item name="userId" initialValue={userData.id} hidden>
							<Input />
						</Form.Item>
						<Form.Item name="cronstatus" initialValue={userData.cronstatus} hidden>
							<Input />
						</Form.Item>
						<Form.Item
							name="remainingSearchCredits"
							initialValue={userData.remainingSearchCredits}
							hidden>
							<Input />
						</Form.Item>
						<Form.Item name="usedSearchCredits" initialValue={userData.usedSearchCredits} hidden>
							<Input />
						</Form.Item>
						<Form.Item name="used360Credits" initialValue={userData.used360Credits} hidden>
							<Input />
						</Form.Item>
						<Form.Item name="total360Credits" initialValue={userData.total360Credits} hidden>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{ textAlign: 'right' }}>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Update
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
