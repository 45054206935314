import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as React from 'react';

export default function MessageConfirmDialog(props: any) {
	const {
		handleDialogMessageConfirm,
		handleDialogMessageClose,
		dialogMessageOpen,
		children,
		confirmLoading
	} = props;

	return (
		<div>
			<Dialog open={dialogMessageOpen} onClose={handleDialogMessageClose}>
				<DialogContent>
					<DialogContentText>{children}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogMessageClose} color="primary" variant="outlined">
						Cancel
					</Button>
					<Button
						style={{ width: 110 }}
						className="btn action"
						disabled={confirmLoading}
						onClick={handleDialogMessageConfirm}
						autoFocus>
						{confirmLoading && <CircularProgress size={20} />}
						<span>Confirm</span>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
