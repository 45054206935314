import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../redux/modules/ThemeOptions';

import HeaderUserbox from '../HeaderUserbox';

import HeaderDots from '../HeaderDots';
import projectLogo from '../../assets/images/stock-logos/ocinsights.png';

const Header = (props) => {
	const {
		headerShadow,
		headerBgTransparent,
		sidebarToggleMobile,
		setSidebarToggleMobile,
		permission
	} = props;

	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};

	return (
		<>
			<div
				className={clsx('app-header', {
					'app-header--shadow': headerShadow,
					'app-header--opacity-bg': headerBgTransparent
				})}>
				<div className="app-header--pane">
					<button
						className={clsx(
							'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
							{ 'is-active': sidebarToggleMobile }
						)}
						onClick={toggleSidebarMobile}>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>
					<a href={'/'}>
						<img
							alt="logo"
							src={projectLogo}
							style={{ width: '40%', height: '40%', marginRight: '1rem' }}
						/>
					</a>
				</div>
				<div className="app-header--pane">
					<HeaderDots permission={permission} />
					<HeaderUserbox />
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	headerShadow: state.ThemeOptions.headerShadow,
	headerBgTransparent: state.ThemeOptions.headerBgTransparent,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
