import url from '../../utils/url';
import { FETCH_DATA, POST_DATA } from '../middlewares/api';
import { schema } from './entities/orgs';
import { combineReducers } from 'redux';

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
	FETCH_DASHBOARD_REQUEST: 'DASHBOARD/FETCH_DASHBOARD_REQUEST',
	FETCH_DASHBOARD_SUCCESS: 'DASHBOARD/FETCH_DASHBOARD_SUCCESS',
	FETCH_DASHBOARD_FAILURE: 'DASHBOARD/FETCH_DASHBOARD_FAILURE',

	SET_SUBSCRIBE_REQUEST: 'DASHBOARD/SET_SUBSCRIBE_REQUEST',
	SET_SUBSCRIBE_SUCCESS: 'DASHBOARD/SET_SUBSCRIBE_SUCCESS',
	SET_SUBSCRIBE_FAILURE: 'DASHBOARD/SET_SUBSCRIBE_FAILURE'
};

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
	orgs: {
		isFetching: false,
		isSubscribing: false,
		ids: []
	}
};

/***********************************************************************************************************************
 * 													ACTIONS 														                                                       *
 * *********************************************************************************************************************/
export const actions = {
	loadOrgs: () => {
		return (dispatch) => {
			const endpoint = url.getOrgsList();
			return dispatch(fetchOrgs(endpoint));
		};
	},

	setSubscribe: (data) => {
		return (dispatch, getState) => {
			const endpoint = url.followCompany();
			console.log('.....');
			if (getState().entities.orgs[data.companyId].subscribed === 1)
				getState().entities.orgs[data.companyId].subscribed = 0;
			else getState().entities.orgs[data.companyId].subscribed = 1;
			return dispatch(followCompany(endpoint, data));
		};
	}
};

const fetchOrgs = (endpoint) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_DASHBOARD_REQUEST,
			types.FETCH_DASHBOARD_SUCCESS,
			types.FETCH_DASHBOARD_FAILURE
		],
		endpoint,
		schema
	}
});

const followCompany = (endpoint, data) => ({
	[POST_DATA]: {
		types: [types.SET_SUBSCRIBE_REQUEST, types.SET_SUBSCRIBE_SUCCESS, types.SET_SUBSCRIBE_FAILURE],
		data,
		endpoint,
		schema
	}
});

/***********************************************************************************************************************
 * 													REDUCERS 														                                                       *
 * *********************************************************************************************************************/
const orgsList = (state = initialState.orgs, action) => {
	switch (action.type) {
		case types.FETCH_DASHBOARD_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_DASHBOARD_SUCCESS:
			return { ...state, isFetching: false, ids: action.response.ids };
		case types.FETCH_DASHBOARD_FAILURE:
			return { ...state, isFetching: false };
		case types.SET_SUBSCRIBE_REQUEST:
			return { ...state, isSubscribing: true };
		case types.SET_SUBSCRIBE_SUCCESS:
		case types.SET_SUBSCRIBE_FAILURE:
			return { ...state, isSubscribing: false };
		default:
			return state;
	}
};

const reducer = combineReducers({ orgsList });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/
export const getOrgs = (state) => {
	console.log(state);
	return state.dashboard.orgsList.ids.map((id) => {
		return state.entities.orgs[id];
	});
};
