import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import clsx from 'clsx';
import https from 'https';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Link as RouteLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { actions as projectsActions, getProjectsList } from '../../redux/modules/projectsPage';
import memory from '../../../src/utils/memory';
import { get, post } from '../../../src/utils/request';
import url from '../../../src/utils/url';
import { emailTraceLabels, usageTraceLabels, updateUsageActivity } from '../../utils/recruitUtil';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	},
	containedPrimary: {
		backgroundColor: '#4191ff'
	},
	uploadButtonProgress: {
		color: '#0795fe'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	}
}));

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
	margin: 0.25rem !important;
`;

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const OTO = [
	'Probably not open to new opportunities',
	'Probably open to new opportunities',
	'Considering new opportunities but not actively looking for',
	'Actively look for new opportunities'
];

function TraceReport(props) {
	const { projects } = props;
	const { loadProjects } = props.projectsAction;
	const classes = useStyles();

	const searchRef = useRef();
	const [talentFoundStatus, setTalentFoundStatus] = useState(0); //0 initial, 1 no found, 2 found
	const [selectedRow, setSelectedRow] = useState(null);
	const [compDeptOneMapping, setCompDeptOneMapping] = useState(null);
	const [deptLevelOneTwoMapping, setDeptLevelOneTwoMapping] = useState(null);
	const [searchInput, setSearchInput] = useState(null);
	const [htmlText, setHtmlText] = useState(null);
	const [folderSelection, setFolderSelection] = useState(null);
	const [folders, setFolders] = useState([]);
	const [isFolderUpdateLoading, setFolderUpdateLoading] = useState(false);
	const [isFolderUpdateDisabled, setFolderUpdateDisabled] = useState(true);
	const [insertArray, setInsertArray] = useState([]);
	const [subtractArray, setSubtractArray] = useState([]);
	const [open, setOpen] = useState(false);

	const [emailSug, setEmailSug] = useState(null);
	const [phoneSug, setPhoneSug] = useState(null);

	const [isReportOpen, setReportOpen] = useState({
		hardBounceOpen: false,
		notThisPersonOpen: false,
		reportSendOpen: false,
		noPersonOpen: false
	});
	const [isReportConfirmOpen, setReportConfirmOpen] = useState(false);

	const bytesToMegaBytes = (bytes, roundTo) =>
		roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);

	const [filterSelects, setFilterSelect] = useState({
		Area: [],
		Stack: [],
		Seniority: [],
		Company: [],
		Department: [], //c-2
		Degree: [],
		Attitude: [], //open to opportunities
		Department_1: [] //c-1
	});

	const [label, setLabel] = useState({
		area: null,
		stack: null,
		seniority: null,
		company: null,
		department_1: null,
		department_2: null,
		degree: null,
		openToOpportunities: null
	});

	const [uploadFile, setUploadFile] = useState({
		uploadFileName: null,
		uploadFilePath: null,
		uploadFileSize: 0,
		uploadFileFullName: null
	});

	const handleSearchCandidates = async () => {
		console.log('handle Search....');

		console.log(searchRef.current.value);
		const searchInput = searchRef.current.value;

		if (!searchInput || searchInput.length < 1) {
			return;
		}

		await axios
			.get(url.checkTalentExist(), {
				params: {
					searchInput: searchInput
				}
			})
			.then((res) => {
				console.log(res.data.message);

				const data = res.data.data;
				if (data) {
					setTalentFoundStatus(2);
					setSelectedRow(data);
					setSearchInput(searchInput);
					setFolders(data.tags);
				} else {
					setTalentFoundStatus(1);
					setSelectedRow(null);
					setSearchInput(searchInput);
					setFolders([]);
				}
			})
			.catch((err) => {
				console.log('checkTalentExist error catch', err.message);
			});
	};

	const handleLabel = (e, value, name) => {
		console.log('handle label....');

		if (name === 'company') {
			if (value) {
				//dynamic load department2, department1

				let depart1List = [];
				const company = value;
				const key = company.key;

				const list1 = compDeptOneMapping[key] ? compDeptOneMapping[key] : [];
				depart1List = [...depart1List, ...list1];

				setFilterSelect({ ...filterSelects, Department_1: depart1List });
				setLabel({ ...label, [name]: value });
			} else {
				setFilterSelect({ ...filterSelects, Department: [], Department_1: [] });
				setLabel({ ...label, department_1: null, Department_2: null });
			}
		} else if (name === 'department_1') {
			if (value) {
				let depart2List = [];
				const depart1 = value;
				const id = depart1.id;

				const list2 = deptLevelOneTwoMapping[id];
				depart2List = [...depart2List, ...list2];

				setFilterSelect({ ...filterSelects, Department: depart2List });
				setLabel({ ...label, [name]: value });
			} else {
				//setFilterSelect({ ...filterSelects, Department: [], Department_1: [] });
				setLabel({ ...label, department_1: null, Department_2: null });
			}
		} else {
			setLabel({ ...label, [name]: value });
		}
	};

	const handleHardBounce = () => {
		axios
			.post(
				url.sendTraceReportEmail(),
				{
					hardBounceFlag: true,
					searchInput: searchInput
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
			})
			.catch((e) => console.error('handleHardBounce has error', e));
	};

	const handleNoPerson = () => {
		axios
			.post(
				url.sendTraceReportEmail(),
				{
					noPersonFlag: true,
					searchInput: searchInput
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
			})
			.catch((e) => console.error('handleNoPerson has error', e));
	};

	const handleNotThisPerson = () => {
		axios
			.post(
				url.sendTraceReportEmail(),
				{
					notThisPersonFlag: true,
					searchInput: searchInput
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
			})
			.catch((e) => console.error('handleNotThisPerson has error', e));
	};

	const handleReport = () => {
		console.log('html text', htmlText);
		console.log('label', label);
		console.log('phone suggestion', phoneSug);
		console.log('email suggestion', emailSug);

		const copyLabel = JSON.parse(JSON.stringify(label));
		for (const [key, value] of Object.entries(copyLabel)) {
			switch (key) {
				case 'area':
					copyLabel[key] = selectedRow.location;
					break;
				case 'stack':
					copyLabel[key] = selectedRow.stacks;
					break;
				case 'seniority':
					copyLabel[key] = selectedRow.seniority;
					break;
				case 'company':
					if (selectedRow) {
						const companies = selectedRow.companies
							.filter((comp) => comp.isCurrent === 1 && comp.flagExp !== 0)
							.map((item) => item.company);
						copyLabel[key] = companies.length > 0 ? companies[0] : null;
					} else {
						copyLabel[key] = null;
					}
					break;
				case 'department_1':
					copyLabel[key] = selectedRow.department_1;
					break;
				case 'department_2':
					copyLabel[key] = selectedRow.department_2;
					break;
				case 'degree':
					if (selectedRow) {
						const degrees = selectedRow.institutions
							.filter((inst) => inst.isHighest === 1)
							.map((item) => item.degree);
						copyLabel[key] = degrees.length > 0 ? degrees[0] : null;
					} else {
						copyLabel[key] = null;
					}
					break;
				case 'openToOpportunities':
					if (selectedRow) {
						const symbol = parseInt(selectedRow.openToOpportunities);
						switch (symbol) {
							case 0:
								copyLabel[key] = OTO[0];
								break;
							case 1:
								copyLabel[key] = OTO[1];
								break;
							case 2:
								copyLabel[key] = OTO[2];
								break;
							case 3:
								copyLabel[key] = OTO[3];
								break;
							default:
								copyLabel[key] = null;
						}
					} else {
						copyLabel[key] = null;
					}
					break;
				default:
					copyLabel[key] = null;
			}
		}

		const labels = [];
		const labelPairs = emailTraceLabels();
		for (const [key, value] of Object.entries(label)) {
			if (label[key]) {
				if (key === 'department_1' || key === 'department_2') {
					const oldLabel = copyLabel[key];
					const newLabel = label[key].name;
					const code = labelPairs[key];
					labels.push([code, oldLabel, newLabel]);
				} else {
					const oldLabel = copyLabel[key];
					const newLabel = label[key].displayname;
					const code = labelPairs[key];
					labels.push([code, oldLabel, newLabel]);
				}
			}
		}

		if (emailSug) {
			labels.push(['B2', 'New Email Suggestion', emailSug]);
		}
		if (phoneSug) {
			labels.push(['B3', 'New Phone Suggestion', phoneSug]);
		}

		axios
			.post(
				url.sendTraceReportEmail(),
				{
					searchInput: searchInput,
					itemname: uploadFile.uploadFilePath ? [uploadFile.uploadFilePath] : [],
					filename: uploadFile.uploadFileName ? [uploadFile.uploadFileName] : [],
					labels: labels,
					desc: htmlText
				},
				config
			)
			.then((res) => {})
			.catch((e) => console.error('handleReport has error', e));
	};

	const handleComment = (html, delta, source, editor) => {
		console.log('text', editor.getText());
		console.log('length', editor.getLength()); //minimum is 1

		const length = editor.getLength() - 1;
		if (length > 0) {
			setHtmlText(html);
		} else {
			setHtmlText(null);
		}
	};

	const handleUpdateProjects = async () => {
		const talentId = selectedRow.id;
		setFolderUpdateLoading(true);

		if (subtractArray.length > 0) {
			await post(url.cancelAssignProjects(), subtractArray).then((res) => console.log(res));
		}

		if (insertArray.length > 0) {
			await axios
				.post(
					url.addProjectsInRecommendation(),
					{
						tagIds: insertArray,
						talentId: talentId
					},
					config
				)
				.then((res) => {
					console.log(res.data.message);
				})
				.catch((e) => console.error('Change folders for the given talent has error', e));
		}

		handleClose();
		setFolderUpdateLoading(false);
	};

	const handleFolderChange = async (event, values) => {
		console.log(selectedRow.tags); //new existing project
		const talentId = selectedRow.id;
		const selectedProjects = values;
		const assignedProjects = selectedRow.tags;
		const insert = [];
		const subtract = [];

		//Add project to the given talent
		//const set = new Set();
		let talentsProjectMapping = {};
		talentsProjectMapping[talentId] = [];
		for (const selectedProject of selectedProjects) {
			const assignedProjects = selectedRow.tags;
			const assignedProjectsIds = assignedProjects.map((item) => item.id);

			if (!assignedProjectsIds.includes(selectedProject.id)) {
				const project = folderSelection.find((project) => project.id === selectedProject.id);
				insert.push(project.id);
				talentsProjectMapping[talentId].push(selectedProject);
			}

			//set.add(selectedProject.id);
		}

		//Remove project from the given talent
		assignedProjects.forEach((each) => {
			if (!selectedProjects.includes(each)) {
				const project = folderSelection.find((project) => project.name === each.name);
				subtract.push({
					tagId: project.id,
					talentId: talentId
				});
			}
		});
		if (subtract.length > 0) {
			//post(url.cancelAssignProjects(), subtract).then((res) => console.log(res));
			setSubtractArray(subtract);
		}

		if (insert.length > 0) {
			setInsertArray(insert);
		}

		setFolders(values);
		setFolderUpdateDisabled(false);
		// if (activeTab === 1) {
		// 	setFolderUpdateDisabled(false);
		// 	setFolderUpdate(false);
		// } else if (activeTab === 0) {
		// 	setFolderUpdateDisabled(true);
		// 	setFolderUpdate(true);
		// }
	};

	const handleClose = () => {
		setOpen(false);
		setFolderUpdateDisabled(true);
	};

	const handleCloseReport = () => {
		setReportConfirmOpen(false);
		setReportOpen({
			...isReportOpen,
			hardBounceOpen: false,
			notThisPersonOpen: false,
			reportSendOpen: false
		});
	};

	const FileUploader = () => {
		const message =
			'Single file size cannot be larger than 5 MB. File type must be one of .pdf, .docx, .doc';
		const hiddenFileInput = useRef();
		const [isFileValid, setFileValid] = useState(true);
		const [isLoading, setLoading] = useState(false);
		const [isFileDeleteOpen, setFileDeleteOpen] = useState(false);
		//const [fileName, setFileName] = useState(null);

		const handleClick = () => {
			hiddenFileInput.current.click();
		};

		const handleChange = async (event) => {
			const fileUploaded = event.target.files[0];
			console.log('file size', fileUploaded.size);
			const MBformat = bytesToMegaBytes(fileUploaded.size, 2);
			const limit = 5;

			// validate file size
			if (MBformat > limit) {
				console.log('over file size 5 MB');
				setFileValid(false);
				setLoading(false);
			} else {
				setFileValid(true);
				setLoading(true);
				//upload to S3
				//pass file to backend parsing api, return filter json
				const email = memory.user.email.trim();
				const path = `${email}/${fileUploaded.name}`;
				const fileFullName = fileUploaded.name; //xxxx.pdf
				const idx = fileFullName.lastIndexOf('.');
				const fileName = fileUploaded.name.substring(0, idx);
				const fileType = fileUploaded.name.substring(idx + 1);

				const uploadConfig = {
					params: {
						path: path,
						contentType: fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data'
					},
					headers: {
						'Content-Type': fileType === 'pdf' ? 'application/pdf' : 'multipart/form-data',
						'Access-Control-Allow-Origin': '*'
					}
				};

				const attachment = new FormData();
				attachment.append('reportFile', fileUploaded);

				await axios
					.post(url.uploadReportFile(), attachment, uploadConfig)
					.then(() => {
						setUploadFile((prevState) => ({
							...prevState,
							uploadFileName: fileName,
							uploadFilePath: path,
							uploadFileSize: fileUploaded.size,
							uploadFileFullName: fileFullName
						}));
						setLoading(false);
					})
					.catch((e) => console.error('Upload report file has error', e));
			}
		};

		const handleDeleteReportFile = async () => {
			const path = uploadFile.uploadFilePath;
			await axios
				.delete(url.deleteReportFile(), {
					...config,
					params: {
						path: path
					}
				})
				.then(() => {
					setUploadFile({
						uploadFileName: null,
						uploadFilePath: null,
						uploadFileSize: 0,
						uploadFileFullName: null
					});
					setFileDeleteOpen(false);
				});
		};

		return (
			<>
				<div style={{ height: 160 }} className="w-100 d-flex flex-row">
					<div className="report d-flex justify-content-center flex-column align-items-center">
						<div className="mb-2">
							<span>Post a screenshot picture, or drag and drop a PDF/Word file into the box</span>
						</div>
						<div className="mt-2">
							<Button className="w-50 btn-neutral-dark-blue active" onClick={handleClick}>
								Choose Files
							</Button>
							<input
								type="file"
								ref={hiddenFileInput}
								onChange={handleChange}
								style={{ display: 'none' }}
								accept=".pdf,.docx,.doc"
							/>
						</div>
					</div>
					<div style={{ width: 10 }} />
					<div className="d-flex flex-column justify-content-between align-items-start">
						<div className="d-flex flex-row align-items-start align-items-center">
							{isLoading && (
								<div className="text-center">
									<CircularProgress size={26} className={classes.uploadButtonProgress} />
								</div>
							)}
							{!isFileValid && (
								<Alert severity="warning" className="text-center">
									{message}
								</Alert>
							)}
							{isFileValid && uploadFile.uploadFileFullName && (
								<>
									<div>{uploadFile.uploadFileFullName}</div>
									<div>
										<IMG
											src={require('../../assets/images/apps/remove_icon.png')}
											onClick={() => setFileDeleteOpen(true)}
										/>
									</div>
								</>
							)}
						</div>
						<div className="font-italic">
							<div>
								You are recommended to submit a proof except for reporting "Hard Bounce" or "Not
								This Person".
							</div>
							<div>
								The size of the file should not be exceed 5MB and the file name cannot contain
								commas
							</div>
						</div>
					</div>
				</div>
				<Dialog open={isFileDeleteOpen} keepMounted onClose={() => setFileDeleteOpen(false)}>
					<DialogContent>
						<DialogContentText>
							{`Are you sure you want to delete this file ${uploadFile.uploadFileName} ?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setFileDeleteOpen(false)} color="primary" variant="outlined">
							Cancel
						</Button>
						<Button
							className={classes.containedPrimary}
							color="primary"
							variant="contained"
							onClick={handleDeleteReportFile}>
							Sure
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	const renderStars = (number) => {
		let container = [];

		let idx = 0;
		for (let i = 0; i < number; i++) {
			container.push(<StarIcon key={idx++} />);
		}
		for (let i = 0; i < 3 - number; i++) {
			container.push(<StarOutlineIcon key={idx++} />);
		}

		return container.map((each) => each);
	};

	useEffect(() => {
		get(url.fetchFilters()).then((res) => {
			console.log('filters data', res.data);
			const filters = res.data;

			const others = { key: '13', displayname: 'Others', categorynum: 2 };
			const filterCompany = JSON.parse(JSON.stringify(filters.Company));
			filterCompany.push(others);
			filters.Company = filterCompany;
			setFilterSelect({ ...filterSelects, ...filters });
		});
		get(url.fetchCompDeptOneMapping()).then((res) => {
			setCompDeptOneMapping(res.data);
		});
		get(url.fetchDepartmentsLevelOneTwoMapping()).then((res) => {
			console.log(res.data);
			setDeptLevelOneTwoMapping(res.data);
		});
	}, []);

	useEffect(() => {
		setFolderSelection(projects);
	}, [projects]);

	useEffect(() => {
		console.log('initialize loadProjects.....');
		loadProjects();

		//usage activity
		const labels = usageTraceLabels();
		updateUsageActivity(labels.traceReport);
	}, []);

	return (
		<>
			<div className="font-weight-bold font-size-xxl">Trace and Report</div>
			<div style={{ marginTop: '1rem' }}>
				<Grid container>
					<Grid item xs={8}>
						<Paper component="form" className={classes.root}>
							<InputBase
								inputRef={searchRef}
								className={classes.input}
								placeholder="Input Linkedin URL or Email of The Talent Here"
							/>
							<IconButton
								onClick={handleSearchCandidates}
								className={classes.iconButton}
								aria-label="search">
								<SearchIcon />
							</IconButton>
						</Paper>
					</Grid>
					<Grid item />
				</Grid>
			</div>

			{talentFoundStatus === 2 && (
				<>
					<div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
						<Grid container>
							<Grid container item xs={8}>
								<Grid item xs={3}>
									<Button
										fullWidth
										style={{ height: '30px' }}
										color="primary"
										variant="contained"
										className={`text-nowrap ${classes.containedPrimary}`}
										onClick={() => {
											setReportConfirmOpen(true);
											setReportOpen((prevState) => ({ ...prevState, hardBounceOpen: true }));
										}}>
										Hard Bounce
									</Button>
								</Grid>
								<Grid className="pl-3" container item xs={5} justifyContent="center">
									<input
										className="w-100"
										placeholder="Need Email Suggestion"
										onChange={(e) => {
											if (e.target.value.length > 0) {
												setEmailSug(e.target.value);
											} else {
												setEmailSug(null);
											}
										}}
									/>
								</Grid>
								<Grid className="pl-3" container item xs={4} justifyContent="center">
									<input
										className="w-100"
										placeholder="Phone Number Suggestion"
										onChange={(e) => {
											if (e.target.value.length > 0) {
												setPhoneSug(e.target.value);
											} else {
												setPhoneSug(null);
											}
										}}
									/>
								</Grid>
							</Grid>
							<Grid item />
						</Grid>
					</div>
					<hr />
					<div style={{ marginTop: '1rem', marginBottom: '1rem' }} className="table-responsive-sm">
						<Table
							size="small"
							className="text-wrap mb-0 table-hover table-borderless font-size-md">
							<thead className="thead">
								<tr className="text-left">
									<th>Candidates</th>
									<th>Location</th>
									<th>Tech Stacks</th>
									<th>Seniority</th>
									<th>Company</th>
									<th>C-1 Team</th>
									<th>C-2 Team</th>
									<th>Degree</th>
									<th>O2O index</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height: 50 }}>
									<td>
										<a onClick={() => setOpen(true)} className="talent text-black">
											{selectedRow ? selectedRow.name : ''}
										</a>
									</td>
									<td>{selectedRow ? selectedRow.location : ''}</td>
									<td>{selectedRow ? selectedRow.stacks : ''}</td>
									<td>{selectedRow ? selectedRow.seniority.substring(4) : ''}</td>
									<td>
										{selectedRow && [
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1 && comp.flagExp !== 0)
													.map((item) => item.company)
											)
										]}
									</td>
									<td>{selectedRow ? selectedRow.department_1 : ''}</td>
									<td>{selectedRow ? selectedRow.department_2 : ''}</td>
									<td>
										{selectedRow && [
											...new Set(
												selectedRow.institutions
													.filter((inst) => inst.isHighest === 1)
													.map((item) => item.degree)
											)
										]}
									</td>
									<td>
										{selectedRow && (
											<div className="d-flex align-items-center justify-content-center ">
												<div>{renderStars(parseInt(selectedRow.openToOpportunities))}</div>
												<div style={{ width: 2 }}></div>
												<div>
													{parseInt(selectedRow.openToOpportunities) === 0 && <span>{OTO[0]}</span>}
													{parseInt(selectedRow.openToOpportunities) === 1 && <span>{OTO[1]}</span>}
													{parseInt(selectedRow.openToOpportunities) === 2 && <span>{OTO[2]}</span>}
													{parseInt(selectedRow.openToOpportunities) === 3 && <span>{OTO[3]}</span>}
												</div>
											</div>
										)}
									</td>
								</tr>
								<tr style={{ height: 50 }}>
									<td>
										<Button
											color="primary"
											variant="contained"
											style={{ height: 30 }}
											className={`w-75 text-nowrap ${classes.containedPrimary}`}
											onClick={() => {
												setReportConfirmOpen(true);
												setReportOpen((prevState) => ({ ...prevState, notThisPersonOpen: true }));
											}}>
											<span>Not This Person</span>
										</Button>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Area}
											getOptionLabel={(option) => option.displayname}
											value={label.area}
											onChange={(e, value) => handleLabel(e, value, 'area')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Stack}
											getOptionLabel={(option) => option.displayname}
											value={label.stack}
											onChange={(e, value) => handleLabel(e, value, 'stack')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Seniority}
											getOptionLabel={(option) => option.displayname.substring(4)}
											value={label.seniority}
											onChange={(e, value) => handleLabel(e, value, 'seniority')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Company}
											getOptionLabel={(option) => option.displayname}
											value={label.company}
											onChange={(e, value) => handleLabel(e, value, 'company')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Department_1}
											getOptionLabel={(option) => option.name}
											value={label.department_1}
											onChange={(e, value) => handleLabel(e, value, 'department_1')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Department}
											getOptionLabel={(option) => option.name}
											value={label.department_2}
											onChange={(e, value) => handleLabel(e, value, 'department_2')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Degree}
											getOptionLabel={(option) => option.displayname}
											value={label.degree}
											onChange={(e, value) => handleLabel(e, value, 'degree')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-75" />
											)}
										/>
									</td>
									<td>
										<Autocomplete
											size="small"
											options={filterSelects.Attitude}
											getOptionLabel={(option) => option.displayname}
											value={label.openToOpportunities}
											onChange={(e, value) => handleLabel(e, value, 'openToOpportunities')}
											renderInput={(params) => (
												<TextField {...params} variant="standard" className="w-100" />
											)}
										/>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
					<hr />
					<FileUploader />
					<div className="w-100 mt-3">
						<div className="w-40">
							<ReactQuill
								className="reportQuillContainer"
								theme="snow"
								placeholder="Please leave your comment."
								modules={{ toolbar: false }}
								onChange={handleComment}
							/>
						</div>
					</div>
					<div className="w-100 mt-3">
						<Button
							color="primary"
							variant="contained"
							className={classes.containedPrimary}
							onClick={() => {
								setReportConfirmOpen(true);
								setReportOpen((prevState) => ({ ...prevState, reportSendOpen: true }));
							}}>
							Report
						</Button>
					</div>
				</>
			)}

			{talentFoundStatus === 1 && (
				<div style={{ marginTop: '1rem' }}>
					<Grid container>
						<Grid
							style={{ marginBottom: '1rem' }}
							item
							container
							xs={8}
							direction="row"
							alignContent="center"
							alignItems="center"
							justifyContent="flex-start">
							<div style={{ marginRight: '1rem' }}>
								<IMG src={require('../../assets/images/apps/alert.png')} />
							</div>
							<div>The talent is not in OCInsight database</div>
						</Grid>
						<Grid style={{ marginBottom: '1rem' }} item xs={4} />
						<Grid
							item
							container
							xs={12}
							direction="row"
							alignContent="center"
							alignItems="center"
							justifyContent="flex-start">
							<div style={{ marginRight: '1rem' }}>
								<Button
									color="primary"
									variant="contained"
									className={classes.containedPrimary}
									onClick={() => {
										setReportConfirmOpen(true);
										setReportOpen((prevState) => ({ ...prevState, noPersonOpen: true }));
									}}>
									Report
								</Button>
							</div>
							<div className="text-break-wrap">
								If you believe this talent should be in our OCInsights Talent Pool, please report
								the missing.
							</div>
						</Grid>
						{/* <Grid item xs={4}/> */}
					</Grid>
				</div>
			)}

			{selectedRow && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					maxWidth={'md'}
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						<Grid container spacing={1}>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">{selectedRow.name}</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.position)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">Curr/Ex Company</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.company)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-1 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_1}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-2 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_2}
									</span>
								</Grid>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent className={'p-4'}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									<List component="div" className="nav-line d-flex nav-tabs-primary">
										<ListItem button disableRipple>
											<span>Information</span>
											<div className="divider" />
										</ListItem>
									</List>

									<div className={clsx('tab-item-wrapper', { active: true })} index={2}>
										<div className="p-3 bg-secondary rounded-sm">
											<span className="font-weight-bold text-black font-size-lg">Summary</span>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Location:</span>
													<span>{selectedRow.location}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Tech Stack:</span>
													<span>{selectedRow.stacks}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Seniority:</span>
													<span>{selectedRow.seniority.substring(4)}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Years of Experience:</span>
													<span>{selectedRow.yearsOfWorkingExperiences}</span>
													<span>&nbsp;yrs</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<span className="pr-1">
													O2O Index
													<Tooltip
														title={
															<>
																<div>★★★: Actively look for new opportunities.</div>
																<div>
																	★★: Considering new opportunities but not actively looking for.
																</div>
																<div>★: Probably open to new opportunities. </div>
																<div>☆: Probably not open to new opportunities. </div>
															</>
														}
														arrow
														placement="top">
														<HelpOutlineIcon fontSize={'small'} />
													</Tooltip>
												</span>
												<span>{renderStars(selectedRow.openToOpportunities)}</span>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Contact Info:</span>
													<span>
														<a target="_blank" href={`${selectedRow.linkedin}`}>
															LinkedIn{selectedRow.email && <>, &nbsp;</>}
														</a>
														{selectedRow.email && (
															<>
																<RouteLink
																	target="_blank"
																	to={{
																		pathname: '/contactsearch',
																		search: `linkedin=${selectedRow.linkedin}`
																	}}>
																	Email, &nbsp;
																</RouteLink>
																<RouteLink
																	target="_blank"
																	to={{
																		pathname: '/contactsearch',
																		search: `linkedin=${selectedRow.linkedin}`
																	}}>
																	Phone
																</RouteLink>
															</>
														)}
													</span>
												</div>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Experience</span>
											{selectedRow.companies.length > 0 &&
												selectedRow.companies.map((comp) => {
													const duration = comp.period ? ', ' + comp.period + 'yrs' : '';
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.position} at {comp.company}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.startDate && comp.endDate && (
																		<>
																			{comp.startDate + ' - ' + comp.endDate} {duration}{' '}
																		</>
																	)}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Education</span>
											{selectedRow.institutions.length > 0 &&
												selectedRow.institutions.map((ins) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ins.degree}'s Degree {ins.specialty && ' in ' + ins.specialty} at{' '}
																	{ins.institution}
																</span>
															</div>
															{ins.startYear && ins.endYear && (
																<div className="text-black font-weight-light font-size-sm">
																	<span>
																		{ins.startYear}-{ins.endYear}
																	</span>
																</div>
															)}
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Skills</span>
											<div className={classes.chips}>
												{selectedRow.skills.length > 0 &&
													selectedRow.skills.map((skill) => {
														return <span className={classes.chip}>{skill}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Language(s)</span>
											<div className={classes.chips}>
												{selectedRow.languages.length > 0 &&
													selectedRow.languages.map((lang) => {
														return <span className={classes.chip}>{lang.language}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Achievement</span>
											<div className={classes.chips}>
												<Tooltip
													title={
														selectedRow.honors.length > 0 &&
														selectedRow.honors.map((honor) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{honor.title}</div>
																	<div>Issuer:{honor.issuer}</div>
																	<div>IssueDate:{honor.issueDate}</div>
																	<div>
																		Description:
																		{honor.description && honor.description.length > 150
																			? honor.description.slice(0, 150) + '...'
																			: honor.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.honors.length} Honors</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.patents.length > 0 &&
														selectedRow.patents.map((patent) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{patent.title}</div>
																	<div>Issuer:{patent.patentNo}</div>
																	<div>IssueDate:{patent.issueDate}</div>
																	<div>
																		Description:
																		{patent.description && patent.description.length > 150
																			? patent.description.slice(0, 150) + '...'
																			: patent.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.patents.length} Patents</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.publications.length > 0 &&
														selectedRow.publications.map((publication) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{publication.title}</div>
																	<div>Publisher:{publication.publisher}</div>
																	<div>PublicDate:{publication.publicationDate}</div>
																	<div>
																		Description:
																		{publication.description && publication.description.length > 150
																			? publication.description.slice(0, 150) + '...'
																			: publication.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.publications.length} Publications</Button>
												</Tooltip>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">
												Entrepreneurship
											</span>
											{selectedRow.entrepreneurship.length > 0 &&
												selectedRow.entrepreneurship.map((ent) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.role} at {ent.company}{' '}
																	{ent.round && <>({ent.round.split('-')[1].trim()})</>}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.startDate}-{ent.endDate}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											{folderSelection && (
												<>
													<span className="font-weight-bold text-black font-size-lg">Projects</span>
													<div className="d-flex justify-content-between">
														<FormControl className={classes.formControl}>
															<Autocomplete
																multiple
																size="small"
																options={folderSelection}
																getOptionLabel={(option) => option.name}
																onChange={handleFolderChange}
																value={folders}
																getOptionSelected={(option, value) => option.id === value.id}
																renderInput={(params) => (
																	<TextField {...params} variant="standard" label="Projects" />
																)}
															/>
														</FormControl>
													</div>
												</>
											)}
										</div>
									</div>
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="p-4">
						<Button onClick={handleClose} color="primary" variant="outlined">
							Cancel
						</Button>
						<Button
							onClick={handleUpdateProjects}
							disabled={isFolderUpdateDisabled || isFolderUpdateLoading}
							className={classes.containedPrimary}>
							{isFolderUpdateLoading && (
								<CircularProgress size={24} className={classes.buttonProgress} />
							)}
							Update Projects
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{isReportConfirmOpen &&
				(isReportOpen.hardBounceOpen ||
					isReportOpen.notThisPersonOpen ||
					isReportOpen.reportSendOpen ||
					isReportOpen.noPersonOpen) && (
					<Dialog open={isReportConfirmOpen} keepMounted onClose={handleCloseReport}>
						<DialogContent>
							<DialogContentText>
								{isReportOpen.hardBounceOpen && (
									<span>Are you sure you want to send HardBounce message?</span>
								)}
								{isReportOpen.notThisPersonOpen && (
									<span>Do you confirm this is not the correct talent ?</span>
								)}
								{isReportOpen.reportSendOpen && (
									<span>Are you sure you want to send this report ?</span>
								)}
								{isReportOpen.noPersonOpen && (
									<span>Are you sure you want to send this report ?</span>
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseReport} color="primary" variant="outlined">
								Cancel
							</Button>
							<Button
								className={classes.containedPrimary}
								color="primary"
								variant="contained"
								onClick={() => {
									if (isReportOpen.hardBounceOpen) {
										handleHardBounce();
									} else if (isReportOpen.notThisPersonOpen) {
										handleNotThisPerson();
									} else if (isReportOpen.reportSendOpen) {
										handleReport();
									} else if (isReportOpen.noPersonOpen) {
										handleNoPerson();
									}
									handleCloseReport();
								}}>
								Sure
							</Button>
						</DialogActions>
					</Dialog>
				)}
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state, props) => {
	const projectList = getProjectsList(state);
	console.log('pages traceReport mapStateToProps projectList...', projectList);

	return {
		projects: projectList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TraceReport);
