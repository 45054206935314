import React, { useState } from 'react';





import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile }  from '../../redux/modules/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          {/*<SidebarWidget />*/}
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Dashboard">
                <span className="sidebar-icon">
                  <AccountBalanceTwoToneIcon />
                </span>
                Dashboard
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            {/*<li>*/}
            {/*  <NavLink*/}
            {/*    onClick={toggleSidebarMobile}*/}
            {/*    activeClassName="active"*/}
            {/*    className="nav-link-simple"*/}
            {/*    to="/Settings">*/}
            {/*    <span className="sidebar-icon">*/}
            {/*      <SettingsTwoToneIcon />*/}
            {/*    </span>*/}
            {/*    Settings*/}
            {/*    <span className="sidebar-icon-indicator sidebar-icon-indicator-right">*/}
            {/*      <ChevronRightTwoToneIcon />*/}
            {/*    </span>*/}
            {/*  </NavLink*/}
            {/* >*/}
            {/*</li>*/}
          </ul>
          {/*<div className="sidebar-header">*/}
          {/*  <span>Others</span>*/}
          {/*</div>*/}
          {/*<ul>*/}
          {/*  <li>*/}
          {/*    <a*/}
          {/*      href="#/"*/}
          {/*      onClick={togglePages}*/}
          {/*      className={clsx({ active: pagesOpen })}>*/}
          {/*      <span className="sidebar-icon">*/}
          {/*        <ViewColumnTwoToneIcon />*/}
          {/*      </span>*/}
          {/*      <span className="sidebar-item-label">User Pages</span>*/}
          {/*      <span className="sidebar-icon-indicator">*/}
          {/*        <ChevronRightTwoToneIcon />*/}
          {/*      </span>*/}
          {/*    </a>*/}
          {/*    <Collapse in={pagesOpen}>*/}
          {/*      <ul>*/}
          {/*        <li>*/}
          {/*          <NavLink*/}
          {/*            onClick={toggleSidebarMobile}*/}
          {/*            to="/PageLoginIllustration">*/}
          {/*            LoginForm*/}
          {/*          </NavLink>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <NavLink*/}
          {/*            onClick={toggleSidebarMobile}*/}
          {/*            to="/PageRegisterIllustration">*/}
          {/*            Register*/}
          {/*          </NavLink>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <NavLink*/}
          {/*            onClick={toggleSidebarMobile}*/}
          {/*            to="/PageRecoverIllustration">*/}
          {/*            Recover Password*/}
          {/*          </NavLink>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <NavLink onClick={toggleSidebarMobile} to="/PageError404">*/}
          {/*            Error 404*/}
          {/*          </NavLink>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </Collapse>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
