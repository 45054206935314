import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@material-ui/core/Card';
import Chart from 'react-apexcharts';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

export default function Overview1({ statistics, orgId }) {
	const { piChart, employees } = statistics;

	const useStyles = makeStyles({
		arrow: {
			'&:before': {
				border: '1px #046eb8 solid'
			},
			color: '#046eb8'
		},
		tooltip: {
			backgroundColor: 'white',
			border: '2px #046eb8 solid',
			textAlign: 'left',
			color: '#000'
		}
	});

	const options = {
		labels: piChart.departments
	};
	const series = piChart.ratio;
	const classes = useStyles();
	return (
		<>
			{employees && (
				<div className="mb-spacing-6">
					<Grid container justify={'flex-end'} className="mb-spacing-6" alignItems={'flex-end'}>
						<Grid item sm={2} className={'text-right'}>
							<Tooltip
								classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
								arrow
								placement="top"
								title={'Overview statistic .csv'}>
								<span>
									<a
										href={`https://ocinsights.s3-us-west-1.amazonaws.com/data/feb/${parseInt(
											orgId
										)}/data_2.xlsx`}
										download>
										<Button color={'#046eb8'} variant="contained" className="btn-first m-2">
											Download
										</Button>
									</a>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid container spacing={6} direction={'row'} justify={'space-evenly'}>
						<Grid item lg={4}>
							<Card className="card-box card-box-alt">
								<div className="card-content-overlay text-center pb-4">
									<div className="d-50 rounded border-0 mb-1 card-icon-wrapper bg-primary text-white btn-icon mx-auto text-center shadow-primary">
										<FontAwesomeIcon icon={['far', 'user']} className="display-4" />
									</div>
									<div className="font-weight-bold text-black display-3 mt-4 mb-1">
										{new Intl.NumberFormat('en-US').format(employees)}
									</div>
									<div className="font-size-lg text-dark opacity-8 my-4">Employees</div>
								</div>
							</Card>
						</Grid>
						<Grid item lg={5}>
							<Chart options={options} series={series} type="donut" width="470" />
						</Grid>
					</Grid>
				</div>
			)}
		</>
	);
}
