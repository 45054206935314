import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import https from 'https';
import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LoadingIndicator from '../../../../theme/loadingIndicator';
import url from '../../../../utils/url';
import { usageTraceLabels, updateUsageActivity } from '../../../../utils/recruitUtil';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { CustomizedtagsType } from '../../../../utils/message';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	tableHeader: {
		borderSpacing: '0 5px !important',
		borderCollapse: 'collapse'
	}
}));

const IMG = styled.img`
	width: 50px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const SMALLIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const labels = usageTraceLabels();

export default function Projects() {
	const classes = useStyles();

	//General
	const [checkers_general, setCheckersGeneral] = useState({});
	const [checkersData_general, setCheckersDataGeneral] = useState([]);
	const [numberOfSelected_general, setNumberOfSelectedGeneral] = useState(0);
	const [data_general, setDataGeneral] = useState([]);
	const [totalNumber_general, setTotalNumberGeneral] = useState(0);

	//Recommendation
	const [checkers_rec, setCheckersRec] = useState({});
	const [checkersData_rec, setCheckersDataRec] = useState([]);
	const [numberOfSelected_rec, setNumberOfSelectedRec] = useState(0);
	const [data_rec, setDataRec] = useState([]);
	const [totalNumber_rec, setTotalNumberRec] = useState(0);

	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const { promiseInProgress } = usePromiseTracker();

	const [activeTab, setActiveTab] = useState(0);

	/******************************************** Multiple Selected Project Start *************************/
	const isEmpty = (obj) => {
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		for (const [_, talent] of Object.entries(obj)) {
			if (talent === undefined) {
				flag2 = true;
				break;
			}
		}

		return flag1 || flag2;
	};

	const toggle = (activeTab) => {
		setActiveTab(activeTab);
	};
	/******************************************** Multiple Selected Project End *************************/

	const renderProjects = (projects) => {
		return projects.map((each, index) => {
			const {
				id,
				archived,
				top,
				name,
				updatedAt,
				numberOfTalents,
				numberOfRecommendations,
				searchValues,
				filename,
				position
			} = each;

			return (
				<>
					<TableRow key={id}>
						<TableCell className="text-center">
							<Checkbox
								disabled={archived}
								checked={checkers_general[id] !== undefined}
								onChange={(e) => {
									if (e.target.checked) {
										setCheckersGeneral((prevState) => ({ ...prevState, [id]: each }));
										setNumberOfSelectedGeneral(numberOfSelected_general + 1);
										setCheckersDataGeneral([...checkersData_general, each]);
									} else {
										setCheckersGeneral((prevState) => ({ ...prevState, [id]: undefined }));
										setNumberOfSelectedGeneral(numberOfSelected_general - 1);
										const index = checkersData_general.findIndex((item) => item === each);
										checkersData_general.splice(index, 1);
										setCheckersDataGeneral(checkersData_general);
									}
								}}
							/>
						</TableCell>
						<TableCell style={{ width: '18%' }}>
							{!archived ? (
								<NavLink
									to={`/savedprojects/projectview/${encodeURIComponent(name + '_' + id)}`}
									className="font-weight-bold text-black">
									<div>{name}</div>
									{filename && (
										<SMALLIMG src={require('../../../../assets/images/apps/attachment.png')} />
									)}
								</NavLink>
							) : (
								<>
									<span className="font-weight-bold text-black">{name}</span>
									{filename && (
										<SMALLIMG src={require('../../../../assets/images/apps/attachment.png')} />
									)}
								</>
							)}
							{position.length > 0 && <div>Position: {position}</div>}
						</TableCell>
						<TableCell style={{ width: '18%' }} className="text-left">
							<div className="d-flex justify-content-start flex-column">
								<span style={{ width: '10rem' }}>Last edited:</span>
								<span style={{ width: '10rem' }}>{updatedAt}</span>
							</div>
						</TableCell>
						<TableCell style={{ width: '18%' }} className="text-left text-break-wrap pr-3 pl-3">
							<div className="d-flex justify-content-start flex-column">
								<span>{numberOfTalents} talents</span>
								<span>{numberOfRecommendations} recommendations</span>
							</div>
						</TableCell>
						<TableCell style={{ width: '40%' }} className="text-left pr-3 pl-3">
							<div style={{ height: 113, overflowY: 'auto' }} className="text-break-wrap">
								{searchValues}
							</div>
						</TableCell>
						<TableCell style={{ width: '2%' }} className={'text-center'}>
							{!archived ? (
								<NavLink
									to={`/savedprojects/projectedit/${encodeURIComponent(name + '_' + id)}`}
									className="font-weight-bold text-black">
									<Tooltip title="Edit" placement="top" arrow>
										<IMG src={require('../../../../assets/images/apps/ProjectEdit.png')} />
									</Tooltip>
								</NavLink>
							) : (
								<Tooltip title="Project Edit" placement="top" arrow>
									<IMG src={require('../../../../assets/images/apps/ProjectEdit.png')} />
								</Tooltip>
							)}
						</TableCell>
						<TableCell style={{ width: '2%' }} className={'text-center'}>
							<Tooltip title="Archive" placement="top" arrow>
								<IMG
									onClick={() => handleProjectArchive(index, each)}
									src={require(`../../../../assets/images/apps/${
										archived ? 'Archived' : 'Archive'
									}.png`)}
								/>
							</Tooltip>
						</TableCell>
						<TableCell style={{ width: '2%' }} className={'text-center'}>
							<Tooltip title="Favorite" placement="top" arrow>
								<IMG
									onClick={() => handleProjectTop(index, each)}
									src={require(`../../../../assets/images/apps/${top ? 'Topped' : 'Top'}.png`)}
								/>
							</Tooltip>
						</TableCell>
					</TableRow>
				</>
			);
		});
	};

	const renderRecProjects = (projects) => {
		return projects.map((each, index) => {
			const { id, archived, name, updatedAt, numberOfTalents, position, type, searchValues } = each;

			return (
				<>
					<TableRow key={id}>
						<TableCell className="text-center">
							<Checkbox
								disabled={archived}
								checked={checkers_rec[id] !== undefined}
								onChange={(e) => {
									if (e.target.checked) {
										setCheckersRec((prevState) => ({ ...prevState, [id]: each }));
										setNumberOfSelectedRec(numberOfSelected_rec + 1);
										setCheckersDataRec([...checkersData_rec, each]);
									} else {
										setCheckersRec((prevState) => ({ ...prevState, [id]: undefined }));
										setNumberOfSelectedRec(numberOfSelected_rec - 1);
										const index = checkersData_rec.findIndex((item) => item === each);
										checkersData_rec.splice(index, 1);
										setCheckersDataRec(checkersData_rec);
									}
								}}
							/>
						</TableCell>
						<TableCell style={{ width: '18%' }}>
							<NavLink
								to={`/savedprojects/projectview/${encodeURIComponent(name + '_' + id)}`}
								className="font-weight-bold text-black">
								<div>{name}</div>
							</NavLink>
						</TableCell>
						<TableCell style={{ width: '18%' }} className="text-left">
							<div className="d-flex justify-content-start flex-column">
								<span style={{ width: '10rem' }}>Last edited:</span>
								<span style={{ width: '10rem' }}>{updatedAt}</span>
							</div>
						</TableCell>
						<TableCell style={{ width: '18%' }} className="text-left text-break-wrap pr-3 pl-3">
							<div className="d-flex justify-content-start flex-column">
								<span>{numberOfTalents} talents</span>
							</div>
						</TableCell>
						<TableCell style={{ width: '23%' }} className="text-left pr-3 pl-3">
							<div className="d-flex justify-content-start flex-column">
								{position.length > 0 && <div>{position}</div>}
							</div>
						</TableCell>
						<TableCell style={{ width: '40%' }} className="text-left pr-3 pl-3">
							{type === CustomizedtagsType.REC_FILTER && (
								<Tooltip title="Filter Recommendation" placement="top" arrow>
									<IMG src={require(`../../../../assets/images/apps/Rec_Candidates.png`)} />
								</Tooltip>
							)}
							{type === CustomizedtagsType.REC_CAN && (
								<Tooltip title="Similar Position Recommendation" placement="top" arrow>
									<IMG src={require(`../../../../assets/images/apps/Rec_Positions.png`)} />
								</Tooltip>
							)}
							{(type === CustomizedtagsType.OCI_TRANSFER ||
								type === CustomizedtagsType.LINKEDIN_TRANSFER) && (
								<Tooltip title="Position Transfer" placement="top" arrow>
									<IMG src={require(`../../../../assets/images/apps/Transfer.png`)} />
								</Tooltip>
							)}
						</TableCell>
					</TableRow>
				</>
			);
		});
	};

	const handleDuplicate = async () => {
		await axios
			.post(
				url.duplicateProjects(),
				{
					projects: checkersData_general
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				setNumberOfSelectedGeneral(0);
				loadProjects();
			})
			.catch((e) => console.error('Duplicate projects has error', e));
	};

	const handleDelete = async () => {
		console.log('delete projects.....');

		const deleteList = [];

		if (activeTab === 0) {
			for (const [id, value] of Object.entries(checkers_general)) {
				console.log('id', id, 'value', value);
				if (typeof value !== 'undefined') {
					deleteList.push(parseInt(id));
				}
			}
		}

		if (activeTab === 1) {
			for (const [id, value] of Object.entries(checkers_rec)) {
				console.log('id', id, 'value', value);
				if (typeof value !== 'undefined') {
					deleteList.push(parseInt(id));
				}
			}
		}

		await axios
			.delete(url.deleteProjects(), {
				withCredentials: true,
				data: { projectIds: deleteList }
			})
			.then((res) => {
				console.log(res.data.message);
				loadProjects();
				setDeleteOpen(false);
				setNumberOfSelectedGeneral(0);
				setNumberOfSelectedRec(0);
			})
			.catch((e) => console.error('Delete projects has error', e));
	};

	const handleProjectArchive = async (index, selectedRow) => {
		await axios
			.post(
				url.setProjectArchiveStatus(),
				{
					tagId: selectedRow.id
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				const copyProjects = data_general.slice();
				const selectedProject = copyProjects.find((_, idx) => idx === index);
				selectedProject.archived = !selectedProject.archived;
				copyProjects.splice(index, 1, selectedProject);
				setDataGeneral(copyProjects);
			})
			.catch((e) => {
				console.error('setProjectArchiveStatus has error', e);
				return Promise.reject(e.message);
			});
	};

	const handleProjectTop = async (index, selectedRow) => {
		await axios
			.post(
				url.setProjectTopStatus(),
				{
					tagId: selectedRow.id
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				const copyProjects = data_general.slice();
				const selectedProject = copyProjects.find((_, idx) => idx === index);
				selectedProject.top = !selectedProject.top;
				copyProjects.splice(index, 1, selectedProject);
				setDataGeneral(copyProjects);
			})
			.catch((e) => {
				console.error('setProjectArchiveStatus has error', e);
				return Promise.reject(e.message);
			});
	};

	const loadProjects = () => {
		trackPromise(
			axios
				.get(url.getProjectsListPage())
				.then((res) => {
					const projects = res.data.data;
					const projects_general = projects.filter(
						(each) => each.type === CustomizedtagsType.GENERAL
					);
					const projects_rec = projects.filter((each) => each.type !== CustomizedtagsType.GENERAL);

					//general
					setDataGeneral(projects_general);
					setCheckersGeneral({});
					setCheckersDataGeneral([]);
					setTotalNumberGeneral(projects_general.length);

					//recommendation
					setDataRec(projects_rec);
					setCheckersRec({});
					setCheckersDataRec([]);
					setTotalNumberRec(projects_rec.length);
				})
				.catch((e) => console.error('loadProjects error', e))
		);
	};

	useEffect(() => {
		loadProjects();
	}, []);

	return (
		<>
			<Card className="card-box">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>Projects</b>
					</div>
					<div className="card-header--actions mx-2">
						<NavLink activeClassName="active" className="nav-link-simple" to={`/recruit`}>
							<Button
								onClick={() => {
									updateUsageActivity(labels.searchTalents);
								}}
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue">
								<span className="btn-wrapper--icon">
									<SearchIcon />
								</span>
								<span className="btn-wrapper--label">Search Talents</span>
							</Button>
						</NavLink>
					</div>
					<div className="card-header--actions">
						<NavLink activeClassName="active" className="nav-link-simple" to={`/savedprojects`}>
							<Button
								onClick={() => {
									updateUsageActivity(labels.viewProject);
								}}
								fullWidth
								className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark-blue active">
								<span className="btn-wrapper--icon">
									<FolderIcon />
								</span>
								<span className="btn-wrapper--label">View Projects</span>
							</Button>
						</NavLink>
					</div>
				</div>
			</Card>
			<Card className="shadow-xxl p-3">
				<CardContent className="p-0 mb-3">
					<List
						component="div"
						className="nav-line d-flex nav-tabs-primary pb-0 justify-content-start">
						<ListItem
							className="ml-3"
							button
							disableRipple={false}
							style={{ backgroundColor: activeTab === 0 ? '#ffffff' : 'transparent' }}
							onClick={() => toggle(0)}
							selected={activeTab === 0}>
							<strong className="text-nowrap">OCI Projects</strong>
						</ListItem>
						<ListItem
							button
							disableRipple={false}
							style={{ backgroundColor: activeTab === 1 ? '#ffffff' : 'transparent' }}
							onClick={() => toggle(1)}
							selected={activeTab === 1}>
							<strong className="text-nowrap">Recommendations</strong>
						</ListItem>
					</List>
					{activeTab === 0 && (
						<div className={classes.root}>
							<Grid container spacing={1}>
								<Grid item xs={2} md={1} lg={1} container justifyContent="center">
									<Checkbox
										checked={
											checkers_general
												? Object.values(checkers_general).filter(
														(project) => typeof project !== 'undefined'
												  ).length === data_general.length
												: false
										}
										onChange={() => {
											if (isEmpty(checkers_general)) {
												let local = {};
												data_general.map((each) => (local[each.id] = each));
												setCheckersGeneral(local);
												setNumberOfSelectedGeneral(Object.keys(local).length);
												setCheckersDataGeneral(data_general);
											} else {
												let local = {};
												data_general.map((each) => (local[each.id] = undefined));
												setCheckersGeneral(local);
												setNumberOfSelectedGeneral(0);
												setCheckersDataGeneral([]);
											}
										}}
										disabled={!data_general || data_general.length === 0}
									/>
								</Grid>
								<Grid container item xs={2} md={1} lg={1} alignItems="center">
									<Button
										className={classes.containedPrimary}
										size="small"
										fullWidth
										disabled={false}
										onClick={handleDuplicate}>
										Duplicate
									</Button>
								</Grid>
								<Grid container item xs={2} md={1} lg={1} alignItems="center">
									<Button
										className={classes.containedPrimary}
										size="small"
										fullWidth
										disabled={false}
										onClick={() => setDeleteOpen(true)}>
										Delete
									</Button>
								</Grid>
								<Grid
									container
									item
									xs={4}
									md={2}
									lg={2}
									alignItems="center"
									justifyContent="flex-start">
									<div>
										{totalNumber_general} Total, {numberOfSelected_general} Selected
									</div>
								</Grid>
								<Grid item xs />
							</Grid>
						</div>
					)}
					{activeTab === 1 && (
						<div className={classes.root}>
							<Grid container spacing={1}>
								<Grid item xs={2} md={1} lg={1} container justifyContent="center">
									<Checkbox
										checked={
											checkers_rec
												? Object.values(checkers_rec).filter(
														(project) => typeof project !== 'undefined'
												  ).length === data_rec.length
												: false
										}
										onChange={() => {
											if (isEmpty(checkers_rec)) {
												let local = {};
												data_rec.map((each) => (local[each.id] = each));
												setCheckersRec(local);
												setNumberOfSelectedRec(Object.keys(local).length);
												setCheckersDataRec(data_rec);
											} else {
												let local = {};
												data_rec.map((each) => (local[each.id] = undefined));
												setCheckersRec(local);
												setNumberOfSelectedRec(0);
												setCheckersDataRec([]);
											}
										}}
										disabled={!data_rec || data_rec.length === 0}
									/>
								</Grid>
								<Grid container item xs={2} md={1} lg={1} alignItems="center">
									<Button
										className={classes.containedPrimary}
										size="small"
										fullWidth
										disabled={false}
										onClick={() => setDeleteOpen(true)}>
										Delete
									</Button>
								</Grid>
								<Grid
									container
									item
									xs={4}
									md={2}
									lg={2}
									alignItems="center"
									justifyContent="flex-start">
									<div>
										{totalNumber_rec} Total, {numberOfSelected_rec} Selected
									</div>
								</Grid>
								<Grid item xs />
							</Grid>
						</div>
					)}
				</CardContent>
				<CardContent className="p-0">
					{activeTab === 0 && (
						<Table
							size={'small'}
							className="text-wrap mb-0 table-hover table-spacing font-size-sm table-overflow-y">
							{promiseInProgress ? (
								<></>
							) : (
								<TableBody>{data_general && renderProjects(data_general)}</TableBody>
							)}
						</Table>
					)}
					{activeTab === 1 && (
						<Table
							size={'small'}
							className="text-wrap mb-0 table-hover table-spacing font-size-sm table-overflow-y">
							{promiseInProgress ? (
								<></>
							) : (
								<TableBody>{data_rec && renderRecProjects(data_rec)}</TableBody>
							)}
						</Table>
					)}
					{promiseInProgress && <LoadingIndicator />}
				</CardContent>
			</Card>
			<Dialog
				open={isDeleteOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setDeleteOpen(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete the selected project(s)?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteOpen(false)} color="primary" variant="outlined">
						Cancel
					</Button>
					<Button className={classes.containedPrimary} onClick={handleDelete}>
						Sure and continue
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
