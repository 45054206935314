import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import api, { postAPI } from './middlewares/api';
import rootReducer from './modules';

let store;

if (process.env.REACT_APP_ENVIRONMENT !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, api, postAPI)));
} else {
	store = createStore(rootReducer, applyMiddleware(thunk, api, postAPI));
}

export default store;
