import createReducer from '../../../utils/createReducer';

/***********************************************************************************************************************
 * 													SCHEMA 														                                                         *
 * *********************************************************************************************************************/
export const schema = {
	name: 'projects',
	id: 'id'
};

/***********************************************************************************************************************
 * 													REDUCERS 														                                                       *
 * *********************************************************************************************************************/

const reducer = createReducer(schema.name);

/***********************************************************************************************************************
 * 													SELECT  														                                                       *
 * *********************************************************************************************************************/

export default reducer;
