import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Header, Footer } from '../../components';
import axios from 'axios';
import url from '../../utils/url';
import { headers } from '../../utils/request';

const LeftSidebar = (props) => {
	const {
		children,
		sidebarToggle,
		sidebarToggleMobile,
		sidebarFixed,
		headerFixed,
		headerSearchHover,
		headerDrawerToggle,
		footerFixed,
		contentBackground
	} = props;

	const [permission, setPermission] = useState(0);

	const fetchPermissionByUser = async () => {
		const result = await axios
			.get(url.getPermissionByUser(), { headers, withCredentials: true })
			.catch((e) => console.error(e));
		if (result && result.data.status === 200) {
			console.log(typeof result.data.data.recruitment);
			setPermission(result.data.data.recruitment);
		}
	};

	useEffect(() => {
		fetchPermissionByUser();
	}, []);

	return (
		<>
			<div
				className={clsx('app-wrapper', contentBackground, {
					'header-drawer-open': headerDrawerToggle,
					'app-sidebar-collapsed': true,
					'app-sidebar-mobile-open': sidebarToggleMobile,
					'app-sidebar-fixed': true,
					'app-header-fixed': headerFixed,
					'app-footer-fixed': footerFixed,
					'search-wrapper-open': headerSearchHover
				})}>
				<div className="app-main">
					<Header permission={permission} />
					<div className="app-content">
						<div className="app-content--inner">
							<div className="app-content--inner__wrapper">{children}</div>
						</div>
						<Footer permission={permission} />
					</div>
				</div>
			</div>
		</>
	);
};

LeftSidebar.propTypes = {
	children: PropTypes.node
};

const mapStateToProps = (state) => ({
	sidebarToggle: state.ThemeOptions.sidebarToggle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
	sidebarFixed: state.ThemeOptions.sidebarFixed,
	headerFixed: state.ThemeOptions.headerFixed,
	headerSearchHover: state.ThemeOptions.headerSearchHover,
	headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

	footerFixed: state.ThemeOptions.footerFixed,

	contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
