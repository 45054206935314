import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import StarIcon from '@material-ui/icons/Star';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import * as Antd from 'antd';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ReactQuill from 'react-quill';
import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';
import LoadingIndicator from '../../../../theme/loadingIndicator';
import memory from '../../../../utils/memory';
import { searchOptions, transformDateToLocalTZ } from '../../../../utils/recruitUtil';
import { get } from '../../../../utils/request';
import url from '../../../../utils/url';

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const useStyles = makeStyles((theme) => ({
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	}
}));

export default function EmailHistory({ projects }) {
	const classes = useStyles();
	const { promiseInProgress } = usePromiseTracker();
	const [page, setPage] = useState(1);

	const [mainData, setMainData] = useState([]); // for not show loading -> []
	const [resultData, setResultData] = useState([]);
	const [filteredData, setFilteredData] = useState(null); // for paging -> null
	const [dateFilteredData, setDateFilteredData] = useState([]); //search data only by date based on all data(resultData)
	const [threeFilteredData, setThreeFilteredData] = useState([]); //search data only by three filter based on all data(resultData)

	//Filter Imporvement
	const [dataByFilters, setDataByFilters] = useState([]);
	const [dataBySearch, setDataBySearch] = useState([]);

	const [selectedRow, setSelectedRow] = useState(null);
	const [open, setOpen] = useState(false);
	const [activeActionTab, setActiveActionTab] = useState('0');
	const [folderSelection, SetFolderSelection] = useState(null);
	const [folders, setFolders] = useState([]);
	const { RangePicker } = Antd.DatePicker;
	const [searchWord, setSearchWord] = useState(null);

	/************************************************** Filter Functionality Start ***************************************/
	const [dateRange, setDateRange] = useState([]);
	const [anchorElCompany, setAnchorElCompany] = useState(null);
	const [anchorElSeniority, setAnchorElSeniority] = useState(null);
	const [anchorElProject, setAnchorElProject] = useState(null);

	const [filterSelected, setFilterSelected] = useState({
		//use for clearing filter value while searching
		companyFilter: false,
		seniority: false,
		project: false,
		time: false
	});

	const [search, setSearch] = useState({
		company: [],
		seniority: [],
		project: []
	});

	const [filterOptions, setFilterOptions] = useState({
		company: [],
		seniority: [],
		project: []
	});

	const recordPerPage = 25;

	const handleCompanyClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElCompany(event.currentTarget);
	};
	const handleCompanyClose = () => {
		setAnchorElCompany(null);
	};
	const open1 = Boolean(anchorElCompany);

	const handleSeniorityClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElSeniority(event.currentTarget);
	};
	const handleSeniorityClose = (event) => {
		setAnchorElSeniority(null);
	};
	const open2 = Boolean(anchorElSeniority);

	const handleProjectClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElProject(event.currentTarget);
	};
	const handleProjectClose = (event) => {
		setAnchorElProject(null);
	};
	const open3 = Boolean(anchorElProject);

	const handleRangePicker = (dates, dateStrings) => {
		console.log('dates', dates, 'dateStrings', dateStrings);
		if (!dates) {
			setDateRange([]);
			return;
		}

		setDateRange(dates);
	};

	const handleClickAction = async (e, data, tabIndex) => {
		e.preventDefault();
		console.log('talent select', data);

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then(
				(res) => {
					const result = res.data;
					console.log('fetchTalentDetail', result.message);
					const finalData = { ...result.data, ...data };
					setSelectedRow(finalData);
					setFolders(result.data.tags);
					setActiveActionTab(tabIndex);
					setOpen(true);
				},
				(error) => {
					console.error('fetchTalentDetail error', error);
				}
			)
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
			});
	};

	/************************************* Filter Functionality End ****************************************************/

	const renderRows = (data) => {
		return data
			? data.map((each) => {
					const {
						id,
						name,
						position,
						company,
						seniority,
						subject,
						project,
						sent_time,
						sent,
						open,
						bounce
					} = each;

					const sent_localTZ = transformDateToLocalTZ(sent_time);

					return (
						<>
							<tr key={id}>
								<td style={{ width: '12%' }}>
									<div className="d-flex align-items-center">
										<div>
											<a
												href="#/"
												onClick={(e) => handleClickAction(e, each, '2')}
												className="font-weight-bold text-black"
												title="...">
												{name}
											</a>
											<Tooltip title={position ? position : ''} arrow placement="top">
												<span className="text-black-50 d-block">
													{position && position.length > 17
														? position.slice(0, 17) + '...'
														: position}
												</span>
											</Tooltip>
										</div>
									</div>
								</td>
								<td className="text-left pl-3">{company}</td>
								<td className="text-left text-break-wrap pr-3 pl-3">{seniority.substr(4)}</td>
								<td className="text-left">{subject}</td>
								<td className="text-left pr-3 pl-3">{project ? project : 'None'}</td>
								<td className="text-left text-break-wrap">{sent_localTZ}</td>
								<td className="text-center pl-3 pr-3">
									{bounce === 0 && sent === 1 && <DoneIcon fontSize={'small'} color={'primary'} />}
									{bounce === 1 && sent === 1 && (
										<TIMERIMG src={require('../../../../assets/images/apps/timer.png')} />
									)}
									{bounce === 0 && sent === 0 && (
										<TIMERIMG src={require('../../../../assets/images/apps/timer.png')} />
									)}
								</td>
								<td className="text-center">
									{open === 1 && <DoneAllIcon fontSize={'small'} color={'primary'} />}
								</td>
							</tr>
						</>
					);
			  })
			: '';
	};

	const searchHandler = (event) => {
		let validParams = {};
		for (const [key, value] of Object.entries(search)) {
			if (value.length !== 0) validParams[key] = value;
		}
		const hasFiltersSelected = Object.keys(validParams).length > 0 || dateRange.length > 0;

		const searchResult = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(hasFiltersSelected ? dataByFilters : resultData)) {
				const name = value.name ? value.name.toLowerCase() : '';
				const company = value.company ? value.company.toLowerCase() : '';
				const seniority = value.seniority ? value.seniority.toLowerCase() : '';
				const subject = value.subject ? value.subject.toLowerCase() : '';
				const project = value.project ? value.project.toLowerCase() : 'none';

				const sent_localTZ = transformDateToLocalTZ(value.sent_time);

				if (
					name.includes(event.target.value.toLowerCase()) ||
					company.includes(event.target.value.toLowerCase()) ||
					seniority.includes(event.target.value.toLowerCase()) ||
					subject.includes(event.target.value.toLowerCase()) ||
					project.includes(event.target.value.toLowerCase()) ||
					sent_localTZ.includes(event.target.value.toLowerCase())
				) {
					searchResult.push(value);
				}
			}
			setSearchWord(event.target.value);
		} else {
			setSearchWord(null);
		}

		if (searchResult.length > 0) {
			console.log('search result: ', searchResult);
			setMainData(searchResult);
			setFilteredData(searchResult);
		} else {
			if (event.target.value.length === 0) {
				setMainData(hasFiltersSelected ? dataByFilters : resultData);
				setFilteredData(hasFiltersSelected ? dataByFilters : resultData);
			} else {
				setMainData([]);
				setFilteredData([]);
			}
		}
		setPage(1);

		// search data only by search field for filters use
		const searchResultByWord = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(resultData)) {
				const company = value.company ? value.company.toLowerCase() : '';
				const seniority = value.seniority ? value.seniority.toLowerCase() : '';
				const subject = value.subject ? value.subject.toLowerCase() : '';
				const project = value.project ? value.project.toLowerCase() : 'none';

				const sent_localTZ = transformDateToLocalTZ(value.sent_time);

				if (
					company.includes(event.target.value.toLowerCase()) ||
					seniority.includes(event.target.value.toLowerCase()) ||
					subject.includes(event.target.value.toLowerCase()) ||
					project.includes(event.target.value.toLowerCase()) ||
					sent_localTZ.includes(event.target.value.toLowerCase())
				) {
					searchResultByWord.push(value);
				}
			}
		}

		setDataBySearch(searchResultByWord);
	};

	const handleChangePage = (e, value) => {
		setPage(value);
		const local = filteredData ? filteredData : resultData;
		console.log('page: ', value, 'resultData: ', resultData, 'filteredData: ', filteredData);
		setMainData(local.slice(value * recordPerPage - recordPerPage, value * recordPerPage));
	};

	const handleAdvancedSearch = (e, value, name) => {
		setSearch({
			...search,
			[name]: value
		});
		//setSearchWord(null);
	};

	const renderStars = (number) => {
		let container = [];

		for (let i = 0; i < number; i++) {
			container.push(<StarIcon />);
		}

		return container.map((each) => each);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const toggleActionTab = (tab) => {
		if (activeActionTab !== tab) setActiveActionTab(tab);
	};

	const goSearchTalentsTest = () => {
		let validParams = {};
		for (const [key, value] of Object.entries(search)) {
			if (value.length !== 0) validParams[key] = value;
		}

		//search data by three filters
		let filteredTalentsAfterThree = [];
		if (Object.keys(validParams).length > 0) {
			for (const [key, value] of Object.entries(searchWord ? dataBySearch : resultData)) {
				//fetch all talents data
				const array = Object.keys(validParams).filter((param) => {
					if (param === 'project' && value[param] === null) {
						value[param] = 'None';
					}
					//search parameter
					if (!value[param]) return true; //if has null value, cannot match

					return !validParams[param].includes(value[param]);
				});

				if (array.length === 0) {
					filteredTalentsAfterThree.push(value);
				}
			}
		}

		//search data by dates
		let filteredTalentsAfterDates = [];
		if (Object.keys(validParams).length > 0) {
			if (filteredTalentsAfterThree.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of filteredTalentsAfterThree) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate) {
							filteredTalentsAfterDates.push(item);
						}
					}
				} else {
					filteredTalentsAfterDates = filteredTalentsAfterThree;
				}
			}
		} else {
			const data = searchWord ? dataBySearch : resultData;
			if (data.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of data) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate) {
							filteredTalentsAfterDates.push(item);
						}
					}
				} else {
					filteredTalentsAfterDates = data;
				}
			}
		}

		setMainData(filteredTalentsAfterDates);
		setFilteredData(filteredTalentsAfterDates);
		setPage(1);

		/************  Search data only by filters, use for search field ************/
		//search data by three filters
		const filteredResultDataAfterThree = [];
		if (Object.keys(validParams).length > 0) {
			for (const [key, value] of Object.entries(resultData)) {
				//fetch all talents data
				const array = Object.keys(validParams).filter((param) => {
					if (param === 'project' && value[param] === null) {
						value[param] = 'None';
					}
					//search parameter
					if (!value[param]) return true; //if has null value, cannot match

					return !validParams[param].includes(value[param]);
				});

				if (array.length === 0) {
					filteredResultDataAfterThree.push(value);
				}
			}
		}

		//search data by dates
		let filteredResultDataAfterDates = [];
		if (Object.keys(validParams).length > 0) {
			if (filteredResultDataAfterThree.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of filteredResultDataAfterThree) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate) {
							filteredResultDataAfterDates.push(item);
						}
					}
				} else {
					filteredResultDataAfterDates = filteredResultDataAfterThree;
				}
			}
		} else {
			//resultData
			if (resultData.length > 0) {
				if (dateRange.length > 0) {
					const startDate = new Date(moment(dateRange[0]).format('MM-DD-YYYY 00:00'));
					const endDate = new Date(moment(dateRange[1]).format('MM-DD-YYYY 23:59'));
					console.log('startDate', startDate);
					console.log('endDate', endDate);

					for (const item of resultData) {
						const date = new Date(item.sent_time);
						console.log(date);

						if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate) {
							filteredResultDataAfterDates.push(item);
						}
					}
				} else {
					filteredResultDataAfterDates = resultData;
				}
			}
		}

		setDataByFilters(filteredResultDataAfterDates);
	};

	/**************************************** React Life Cycle Start *************************************************************/

	useEffect(() => {
		SetFolderSelection(projects);
	}, [projects]);

	useEffect(() => {
		trackPromise(
			get(url.fetchEmailHistory()).then((res) => {
				console.log('history data', res.data);
				setFilterOptions(searchOptions(res.data));
				setResultData(res.data);
				setMainData(res.data.slice(0, recordPerPage));
				return res.data;
			})
		);
	}, []);

	useEffect(() => {
		console.log('after search', resultData);
		goSearchTalentsTest();
	}, [search, dateRange, resultData]);

	/**************************************** React Life Cycle End *************************************************************/

	return (
		<>
			<Card className="card-box mb-spacing-6-x2">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>Email History</b>
					</div>
					<div
						className={clsx('search-wrapper search-wrapper--grow text-right pr-2 mb-1', {
							'is-active': false
						})}>
						<TextField
							variant="outlined"
							margin="dense"
							onChange={(e) => searchHandler(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchTwoToneIcon style={{ color: '#0795fe' }} />
									</InputAdornment>
								),
								type: 'search'
							}}
						/>
					</div>
				</div>
				<Card className="shadow-xxl p-3">
					<CardContent className="p-0">
						<div className="table-responsive-sm">
							<Table
								size={'small'}
								className="text-wrap mb-0 table-hover table-borderless font-size-sm">
								<thead className="thead">
									<tr className={'text-left'}>
										<th>Candidates</th>
										<th className="pl-3 d-flex">
											<div>Company</div>
											{search.company.length > 0 ? (
												<IMG
													src={require('../../../../assets/images/apps/filter_selected.png')}
													onClick={handleCompanyClick}
												/>
											) : (
												<IMG
													src={require('../../../../assets/images/apps/filter.png')}
													onClick={handleCompanyClick}
												/>
											)}
											<Popover
												open={open1}
												anchorEl={anchorElCompany}
												onClose={handleCompanyClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center'
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center'
												}}>
												<Autocomplete
													className={'filterHeader'}
													multiple
													size="small"
													options={filterOptions.company}
													getOptionLabel={(option) => option}
													value={search.company}
													onChange={(e, value) => handleAdvancedSearch(e, value, 'company')}
													renderInput={(params) => <TextField {...params} variant="standard" />}
												/>
											</Popover>
										</th>
										<th className="pl-3">
											Seniority
											{search.seniority.length > 0 ? (
												<IMG
													src={require('../../../../assets/images/apps/filter_selected.png')}
													onClick={handleSeniorityClick}
												/>
											) : (
												<IMG
													src={require('../../../../assets/images/apps/filter.png')}
													onClick={handleSeniorityClick}
												/>
											)}
											<Popover
												open={open2}
												anchorEl={anchorElSeniority}
												onClose={handleSeniorityClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center'
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center'
												}}>
												<Autocomplete
													className={'filterHeader'}
													multiple
													size="small"
													options={filterOptions.seniority}
													getOptionLabel={(option) => option.substr(4)}
													value={search.seniority}
													onChange={(e, value) => handleAdvancedSearch(e, value, 'seniority')}
													renderInput={(params) => <TextField {...params} variant="standard" />}
												/>
											</Popover>
										</th>
										<th>Email Subject</th>
										<th className="pl-3 pr-3">
											Project
											{search.project.length > 0 ? (
												<IMG
													src={require('../../../../assets/images/apps/filter_selected.png')}
													onClick={handleProjectClick}
												/>
											) : (
												<IMG
													src={require('../../../../assets/images/apps/filter.png')}
													onClick={handleProjectClick}
												/>
											)}
											<Popover
												open={open3}
												anchorEl={anchorElProject}
												onClose={handleProjectClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center'
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center'
												}}>
												<Autocomplete
													className={'filterHeader'}
													multiple
													size="small"
													options={filterOptions.project}
													getOptionLabel={(option) => option}
													value={search.project}
													onChange={(e, value) => handleAdvancedSearch(e, value, 'project')}
													renderInput={(params) => <TextField {...params} variant="standard" />}
												/>
											</Popover>
										</th>
										<th>
											Time
											<Antd.Popover
												content={
													<RangePicker
														format="YYYY-MM-DD"
														value={dateRange}
														onChange={handleRangePicker}
													/>
												}
												trigger="click">
												{dateRange.length > 0 ? (
													<IMG
														src={require('../../../../assets/images/apps/filter_selected.png')}
													/>
												) : (
													<IMG src={require('../../../../assets/images/apps/filter.png')} />
												)}
											</Antd.Popover>
										</th>
										<th className="text-center pl-3 pr-3">Sent</th>
										<th className="text-center">Opened</th>
									</tr>
								</thead>
								{promiseInProgress ? (
									<></>
								) : (
									<tbody>{renderRows(mainData.slice(0, recordPerPage))}</tbody>
								)}
							</Table>
							{promiseInProgress && <LoadingIndicator />}
						</div>
						<div className="divider" />
						<div className="divider" />
						<div className="p-3 d-flex justify-content-center">
							<Pagination
								page={page}
								onChange={handleChangePage}
								className="pagination-first"
								size="small"
								count={
									filteredData
										? Math.ceil(Object.keys(filteredData).length / recordPerPage)
										: Math.ceil(Object.keys(resultData).length / recordPerPage)
								}
							/>
						</div>
					</CardContent>
				</Card>
			</Card>

			{selectedRow && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					maxWidth={'md'}
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						<Grid container spacing={1}>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">{selectedRow.name}</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.position)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">Curr/Ex Company</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{[
											...new Set(
												selectedRow.companies
													.filter((comp) => comp.isCurrent === 1)
													.map((item) => item.company)
											)
										]}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-1 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_1}
									</span>
								</Grid>
							</Grid>
							<Grid container item xs={3} direction={'column'}>
								<Grid item>
									<span className="text-black font-size-sm">C-2 DEPT</span>
								</Grid>
								<Grid item>
									<span className="text-black font-weight-light font-size-sm">
										{selectedRow.department_2}
									</span>
								</Grid>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent className={'p-4'}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									<List component="div" className="nav-line d-flex nav-tabs-primary">
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '2'}
											onClick={() => {
												toggleActionTab('2');
											}}>
											<span>Information</span>
											<div className="divider" />
										</ListItem>
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '0'}
											onClick={() => {
												toggleActionTab('0');
											}}>
											<span>Opportunity</span>
											<div className="divider" />
										</ListItem>
										<ListItem
											button
											disableRipple
											selected={activeActionTab === '1'}
											onClick={() => {
												toggleActionTab('1');
											}}>
											<span>History</span>
										</ListItem>
									</List>
									<hr />
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '0' })}
										index={0}>
										<DialogContentText>
											<TextField
												disabled="true"
												value={selectedRow.subject}
												variant="outlined"
												fullWidth
											/>
											<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
											<ReactQuill
												readOnly="true"
												className={'ql-view-container'}
												theme="snow"
												value={selectedRow.historybody}
											/>
										</DialogContentText>
										<hr />
										<Grid container direction={'row'}>
											<Grid item md={12}>
												<div>
													<div className="font-weight-bold text-uppercase text-dark font-size-sm text-left">
														Uploaded Files
													</div>
													<List component="div" className="font-size-sm">
														{selectedRow.filename ? (
															selectedRow.filename.length > 1 ? (
																selectedRow.filename.split(',').map((file) => {
																	return (
																		<ListItem
																			className="font-size-sm px-3 py-2 text-primary d-flex justify-content-start"
																			key={file}>
																			<span>{file}</span>
																		</ListItem>
																	);
																})
															) : (
																selectedRow.filename
															)
														) : (
															<span>{selectedRow.filename}</span>
														)}
													</List>
												</div>
											</Grid>
										</Grid>
										<hr />
										<div className={'font-size-sm mt-4'}>
											<div>{memory.user.name}</div>
											<div>
												{memory.user.position} | {memory.user.customername}
											</div>
											<div>{memory.user.email}</div>
											<div>{memory.user.phone}</div>
											<div>{memory.user.location}</div>
											<a href={`${memory.user.linkedin}`}>LinkedIn</a>
										</div>
									</div>

									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '1' })}
										index={1}>
										{selectedRow && (
											<Alert className="alerts-alternate mb-4" severity="#046eb8">
												<div className="d-flex align-items-center align-content-start">
													<span>
														<strong className="d-block">
															{transformDateToLocalTZ(selectedRow.sent_time)}
														</strong>
														{selectedRow.open === 1 && (
															<p>
																The candidate has checked your email already.
																<DoneAllIcon fontSize={'small'} color={'primary'} />
															</p>
														)}
														{selectedRow.open === 0 && selectedRow.sent === 1 && (
															<p>
																This email has been sent to the candidate successfully.
																<DoneIcon fontSize={'small'} color={'primary'} />
															</p>
														)}
														{selectedRow.open === 0 && selectedRow.sent === 0 && (
															<p>
																This email will be sent to the candidate.
																{/* <DoneIcon fontSize={'small'} color={'primary'} /> */}
															</p>
														)}
													</span>
												</div>
											</Alert>
										)}
									</div>
									<div
										className={clsx('tab-item-wrapper', { active: activeActionTab === '2' })}
										index={2}>
										<div className="mt-4 mb-5 p-3 bg-secondary rounded-sm">
											<span className="font-weight-bold text-black font-size-lg">Summary</span>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Location:</span>
													<span>{selectedRow.location}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Tech Stack:</span>
													<span>{selectedRow.stacks}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Seniority:</span>
													<span>{selectedRow.seniority.substring(4)}</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Years of Experience:</span>
													<span>{selectedRow.yearsOfWorkingExperiences}</span>
													<span>&nbsp;yrs</span>
												</div>
											</div>
											<div className="d-flex justify-content-start py-2">
												<span className="pr-1">
													O2O Index
													<Tooltip
														title={
															<>
																<div>★★★: Actively look for new opportunities.</div>
																<div>
																	★★: Considering new opportunities but not actively looking for.
																</div>
																<div>★: Probably open to new opportunities. </div>
																<div>☆: Probably not open to new opportunities. </div>
															</>
														}
														arrow
														placement="top">
														<HelpOutlineIcon fontSize={'small'} />
													</Tooltip>
												</span>
												<span>{renderStars(selectedRow.openToOpportunities)}</span>
											</div>
											<div className="d-flex justify-content-start py-2">
												<div className="text-black font-weight-light font-size-sm">
													<span className="pr-1">Contact Info:</span>
													<span>
														<a href={`${selectedRow.linkedin}`}>LinkedIn, &nbsp;</a>
														<RouteLink
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Email, &nbsp;
														</RouteLink>
														<RouteLink
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Phone
														</RouteLink>
													</span>
												</div>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Experience</span>
											{selectedRow.companies.length > 0 &&
												selectedRow.companies.map((comp) => {
													const duration = comp.period ? ', ' + comp.period + ' yrs' : '';
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.position} at {comp.company}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{comp.startDate && comp.endDate && (
																		<>
																			{comp.startDate + ' - ' + comp.endDate} {duration}{' '}
																		</>
																	)}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Education</span>
											{selectedRow.institutions.length > 0 &&
												selectedRow.institutions.map((ins) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ins.degree}'s Degree {ins.specialty && ' in ' + ins.specialty} at{' '}
																	{ins.institution}
																</span>
															</div>
															{ins.startYear && ins.endYear && (
																<div className="text-black font-weight-light font-size-sm">
																	<span>
																		{ins.startYear}-{ins.endYear}
																	</span>
																</div>
															)}
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Skills</span>
											<div className={classes.chips}>
												{selectedRow.skills.length > 0 &&
													selectedRow.skills.map((skill) => {
														return <span className={classes.chip}>{skill}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Language(s)</span>
											<div className={classes.chips}>
												{selectedRow.languages.length > 0 &&
													selectedRow.languages.map((lang) => {
														return <span className={classes.chip}>{lang.language}</span>;
													})}
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Achievement</span>
											<div className={classes.chips}>
												<Tooltip
													title={
														selectedRow.honors.length > 0 &&
														selectedRow.honors.map((honor) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{honor.title}</div>
																	<div>Issuer:{honor.issuer}</div>
																	<div>IssueDate:{honor.issueDate}</div>
																	<div>
																		Description:
																		{honor.description && honor.description.length > 150
																			? honor.description.slice(0, 150) + '...'
																			: honor.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.honors.length} Honors</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.patents.length > 0 &&
														selectedRow.patents.map((patent) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{patent.title}</div>
																	<div>Issuer:{patent.patentNo}</div>
																	<div>IssueDate:{patent.issueDate}</div>
																	<div>
																		Description:
																		{patent.description && patent.description.length > 150
																			? patent.description.slice(0, 150) + '...'
																			: patent.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.patents.length} Patents</Button>
												</Tooltip>
												<Tooltip
													title={
														selectedRow.publications.length > 0 &&
														selectedRow.publications.map((publication) => {
															return (
																<div className={'pb-2'}>
																	<div>Title:{publication.title}</div>
																	<div>Publisher:{publication.publisher}</div>
																	<div>PublicDate:{publication.publicationDate}</div>
																	<div>
																		Description:
																		{publication.description && publication.description.length > 150
																			? publication.description.slice(0, 150) + '...'
																			: publication.description}
																	</div>
																</div>
															);
														})
													}>
													<Button>{selectedRow.publications.length} Publications</Button>
												</Tooltip>
											</div>
											<hr />
											<span className="font-weight-bold text-black font-size-lg">
												Entrepreneurship
											</span>
											{selectedRow.entrepreneurship.length > 0 &&
												selectedRow.entrepreneurship.map((ent) => {
													return (
														<div className="d-flex justify-content-start flex-column p-2">
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.role} at {ent.company}{' '}
																	{ent.round && <>({ent.round.split('-')[1].trim()})</>}
																</span>
															</div>
															<div className="text-black font-weight-light font-size-sm">
																<span>
																	{ent.startDate} - {ent.endDate}
																</span>
															</div>
														</div>
													);
												})}
											<hr />
											<span className="font-weight-bold text-black font-size-lg">Projects</span>
											{folderSelection && (
												<div className="d-flex justify-content-between">
													<FormControl className={classes.formControl}>
														<Autocomplete
															disabled={true}
															multiple
															size="small"
															options={folderSelection}
															getOptionLabel={(option) => option.name}
															value={folders}
															getOptionSelected={(option, value) => option.id === value.id}
															renderInput={(params) => (
																<TextField {...params} variant="standard" label="Projects" />
															)}
														/>
													</FormControl>
												</div>
											)}
										</div>
									</div>
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}
