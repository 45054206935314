import React from 'react';

import PageError from './components/PageError';
export default function PageError404() {
  return (
    <>
      <PageError />
    </>
  );
}
