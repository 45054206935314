import { get, post, del, update } from '../../../utils/request';
import url from '../../../utils/url';
import React, { useState, useEffect, useReducer } from 'react';
import { transformDateToLocalTZ } from '../../../utils/recruitUtil';
import {
	Form,
	Input,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	AutoComplete,
	Table,
	Modal,
	Space,
	DatePicker
} from 'antd';
import moment from 'moment';

export default function EmailLog(props) {
	const [filteredData, setFilteredData] = useState([]);
	const [resultData, setResultData] = useState([]);
	const [isTimeFiliterActive, setTimeFilterActive] = useState(false);
	const { Column } = Table;
	const { RangePicker } = DatePicker;

	const datePicker = () => {
		return <RangePicker format="YYYY-MM-DD" onChange={handleRangePicker} />;
	};

	const handleRangePicker = (dates, dateStrings) => {
		console.log('dates', dates, 'dateStrings', dateStrings);
		if (!dates) {
			setTimeFilterActive(false);
			setFilteredData(resultData);
			return;
		}

		const startDate = new Date(moment(dates[0]).format('MM-DD-YYYY 00:00'));
		const endDate = new Date(moment(dates[1]).format('MM-DD-YYYY 23:59'));
		console.log('startDate', startDate);
		console.log('endDate', endDate);

		const filterDates = [];
		for (const item of filteredData.length > 0 ? filteredData : resultData) {
			const date = new Date(item.sent_time);
			console.log(date);

			if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate) {
				filterDates.push(item);
			}
		}

		setTimeFilterActive(true);
		setFilteredData(filterDates);
	};

	const handleFilterOptions = (col) => {
		const filterCol = [];
		switch (col) {
			case 'sender':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.sender)) {
						filterCol.push({ text: item.sender, value: item.sender });
					}
				}
				return filterCol;
			case 'senderemail':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.senderemail)) {
						filterCol.push({ text: item.senderemail, value: item.senderemail });
					}
				}
				return filterCol;
			case 'receiver':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.receiver)) {
						filterCol.push({ text: item.receiver, value: item.receiver });
					}
				}
				return filterCol;
			case 'receiveremail':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.receiveremail)) {
						filterCol.push({ text: item.receiveremail, value: item.receiveremail });
					}
				}
				return filterCol;
			case 'sent':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.sent)) {
						filterCol.push({ text: item.sent, value: item.sent });
					}
				}
				return filterCol;
			case 'open':
				for (const item of resultData) {
					if (!filterCol.some((data) => data.value === item.open)) {
						filterCol.push({ text: item.open, value: item.open });
					}
				}
				return filterCol;
		}
	};

	const handleFilterCols = (value, record, col) => {
		switch (col) {
			case 'sender':
				return record.sender.startsWith(value);
			case 'senderemail':
				return record.senderemail.startsWith(value);
			case 'receiver':
				return record.receiver.startsWith(value);
			case 'receiveremail':
				return record.receiveremail.startsWith(value);
			case 'sent':
				return record.sent === parseInt(value);
			case 'open':
				return record.open === parseInt(value);
		}
	};

	const handleFilterData = (pagination, filters, sorter, extra) => {
		console.log(extra.currentDataSource);
		//setFilteredData(extra.currentDataSource);
	};

	useEffect(() => {
		console.log('email logs loading.....');
		get(url.fetchEmailLog()).then((res) => {
			console.log(res.data);

			const finalData = [];
			for (const record of res.data) {
				const sent_localTZ = transformDateToLocalTZ(record.sent_time);
				record.sent_time = sent_localTZ;
				finalData.push(record);
			}

			setResultData(finalData);
			setFilteredData(finalData);
		});
	}, []);

	return (
		<>
			<div>
				<Button style={{ float: 'right', position: 'relative' }} onClick={props.redirectHome}>
					Home
				</Button>
				<Button
					style={{ float: 'right', position: 'relative', marginBottom: 16 }}
					onClick={props.logout}>
					Logout
				</Button>
				<Table
					dataSource={filteredData}
					pagination={{ pageSize: 10 }}
					onChange={(pagination, filters, sorter, extra) =>
						handleFilterData(pagination, filters, sorter, extra)
					}>
					<Column
						title="Sender"
						dataIndex="sender"
						filters={handleFilterOptions('sender')}
						onFilter={(value, record) => handleFilterCols(value, record, 'sender')}
						filterSearch
					/>
					<Column
						title="Sender Email"
						dataIndex="senderemail"
						filters={handleFilterOptions('senderemail')}
						onFilter={(value, record) => handleFilterCols(value, record, 'senderemail')}
						filterSearch
					/>
					<Column
						title="Receiver"
						dataIndex="receiver"
						filters={handleFilterOptions('receiver')}
						onFilter={(value, record) => handleFilterCols(value, record, 'receiver')}
						filterSearch
					/>
					<Column
						title="Receiver Email"
						dataIndex="receiveremail"
						filters={handleFilterOptions('receiveremail')}
						onFilter={(value, record) => handleFilterCols(value, record, 'receiveremail')}
						filterSearch
					/>
					<Column
						className={isTimeFiliterActive && 'time'}
						title="Time"
						dataIndex="sent_time"
						filterDropdown={datePicker}
					/>
					<Column
						title="Sent"
						dataIndex="sent"
						filters={handleFilterOptions('sent')}
						onFilter={(value, record) => handleFilterCols(value, record, 'sent')}
					/>
					<Column
						title="Open"
						dataIndex="open"
						filters={handleFilterOptions('open')}
						onFilter={(value, record) => handleFilterCols(value, record, 'open')}
					/>
				</Table>
			</div>
		</>
	);
}
