import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Cascader,
	Select,
	Row,
	Col,
	Button,
	AutoComplete,
	Table,
	Modal,
	Divider,
	Checkbox
} from 'antd';
import 'antd/dist/antd.css';

export default function Adduser({
	isLoading,
	isVisible,
	handleCancel,
	handleFinish,
	customers,
	userEmails
}) {
	const [form] = Form.useForm();
	const { Option } = Select;

	return (
		<Modal
			bodyStyle={{ height: '100%' }}
			width={1000}
			visible={isVisible}
			title="Add User"
			footer={null}
			onCancel={handleCancel}>
			<Form layout="vertical" form={form} onFinish={() => handleFinish(form)}>
				<Row>
					<Col flex={3}>
						<Form.Item
							style={{ width: '60%' }}
							name="email"
							label="Email"
							rules={[
								{
									type: 'email',
									message: 'The input is not valid E-mail!'
								},
								{
									required: true,
									message: 'Please input your E-mail!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="firstname"
							label="First Name"
							rules={[
								{
									required: true,
									message: 'Please input your first name!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="country"
							label="Country"
							rules={[
								{
									required: true,
									message: 'Please input your country!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="city"
							label="City"
							rules={[
								{
									required: true,
									message: 'Please input your city!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="linkedin"
							label="Linkedin"
							rules={[
								{
									type: 'url',
									message: 'The input is not valid URL!'
								},
								{
									required: true,
									message: 'Please input your Linkedin!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="zipcode"
							label="Zip/Post Code"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Please input your Zip/Post Code!'
							// 	}
							// ]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="month360Credits"
							label="Monthly 360 Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '30em' }}
							name="userEmails"
							label="Sending Emails"
							rules={[
								{ required: false, message: 'Please select your sending emails!', type: 'array' }
							]}>
							<Select mode="multiple" placeholder="Please select your sending emails!">
								{userEmails &&
									userEmails.map((user) => {
										return (
											<Option key={user.id} value={user.id}>
												<span>
													{user.name}: {user.email}
												</span>
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
					<Col flex={3}>
						<Form.Item
							style={{ width: '60%' }}
							name="password"
							label="Password"
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								}
							]}
							hasFeedback>
							<Input.Password />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="lastname"
							label="Last Name"
							rules={[
								{
									required: true,
									message: 'Please input your last name!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="customerId"
							label="Customer"
							rules={[
								{
									required: true,
									message: 'Please choose a company!'
								}
							]}
							hasFeedback>
							<Select
								showSearch="true"
								style={{ width: 200 }}
								placeholder="Select a customer"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{customers &&
									customers.map((customer) => {
										return (
											<Option label={customer.customername} value={customer.id}>
												{customer.customername}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="state"
							label="State/Province"
							rules={[
								{
									required: true,
									message: 'Please input your state/province!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="position"
							label="Position"
							rules={[
								{
									required: true,
									message: 'Please input your position!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="phone"
							label="Phone"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Please input your phone number!'
							// 	}
							// ]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ width: '60%' }}
							name="monthSearchCredits"
							label="Monthly Search Credit"
							rules={[
								{
									required: true,
									message: 'Please input your credits!'
								}
							]}
							hasFeedback>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{ textAlign: 'right' }}>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Add
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
