import React, { useEffect, useState, useRef } from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLevelNumber } from '../../../../utils/orgChartUtil';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import axios from 'axios';
import getBaseUrl from '../../../../utils/getBaseUrl';
import url from '../../../../utils/url';
import { headers } from '../../../../utils/request';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Overview1 from '../Overview1';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
	arrow: {
		'&:before': {
			border: '1px #046eb8 solid'
		},
		color: '#046eb8'
	},
	tooltip: {
		backgroundColor: 'white',
		border: '2px #046eb8 solid',
		textAlign: 'left',
		color: '#000'
	},
	locationPop: {
		width: '12%'
	}
});

export default function OrgChart({
	orgChart,
	orgChartInfo,
	getTopLeader,
	getLevel,
	followDepartment,
	orgId
}) {
	const [levels, setLevels] = useState([]);
	const [supervisorId, setSupervisorId] = useState('');

	const handleShowBelonging = (e, id, level) => {
		e.preventDefault();
		const currLevel = getLevelNumber(level); //index

		if (levels.length > currLevel + 1 && supervisorId.includes(id)) {
			setLevels((prevState) => prevState.slice(0, currLevel + 1));
			return;
		}

		setSupervisorId(id);
		//next level means what level you want to go
		const number = currLevel + 1;
		const nextLevel = getLevel(id, level.substring(0, 2) + number.toString());
		console.log('next level', nextLevel);

		// when it has available next level
		if (nextLevel.length > 0) {
			const levelsCopy = Object.assign([], levels);
			console.log(getLevelNumber(level));
			levelsCopy[getLevelNumber(level)].forEach((each) => {
				if (each.data.id === id) {
					each.active = true;
				} else {
					each.active = false;
				}
			});

			setLevels(levelsCopy);

			//only want to show the level data would be all false
			const ifAnotherBranchArray = levels.map((le) => {
				return getLevelNumber(le[0].data.level) > getLevelNumber(nextLevel[0].data.level) - 1;
			});

			// see if should change to another branch
			if (ifAnotherBranchArray.indexOf(true) > 0) {
				//copy from root to last expand level -1
				setLevels((prevState) => prevState.slice(0, ifAnotherBranchArray.indexOf(true)));
			}

			// show next level
			setLevels((prevState) => {
				return [...prevState, nextLevel];
			}); // nextLevel only has next level info
		}
	};
	const [openSnack, setOpenSnack] = useState(false);
	const [transition, setTransition] = useState(undefined);
	function TransitionUp(props) {
		return <Slide {...props} direction="up" style={{ backgroundColor: 'grey' }} />;
	}
	const handleCloseSnack = (e) => {
		e.preventDefault();
		setOpenSnack(false);
	};
	const handleSubscribe = (e, id) => {
		followDepartment({ departmentId: id });
		setTransition(() => TransitionUp);
		setOpenSnack(true);
	};

	const handleUnsubscribe = (e, id) => {
		e.preventDefault();
		console.log('Unfollow...', id);

		axios
			.post(
				url.postUnfollowDepartment(),
				{ departmentId: id },
				{
					withCredentials: true,
					headers
				}
			)
			.then((res) => {
				console.log(res);
				return res.data.status;
			})
			.catch((e) => console.log(e));
	};

	/***********************************************************************************************************************
	 * 												SEARCH HANDLER            														                                       *
	 * *********************************************************************************************************************/
	const [selected, setSelected] = useState(null);
	const [searchStatus1, setSearchStatus1] = useState(false);
	const [targetList, setTargetList] = useState([]);
	const classes = useStyles();
	const divRef = useRef();
	const scrollToRef = (ref) =>
		window.scrollTo({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' });

	const searchHandler = (event) => {
		const searchResult = [];
		if (event.target.value.length > 2) {
			for (const [key, value] of Object.entries(orgChart)) {
				// console.log(value.name, value.position, value.departmentName);
				const name = value.name ? value.name.toLowerCase() : '';
				const position = value.position ? value.position.toLowerCase() : '';
				const department = value.departmentName ? value.departmentName.toLowerCase() : '';
				if (
					name.includes(event.target.value.toLowerCase()) ||
					position.includes(event.target.value.toLowerCase()) ||
					department.includes(event.target.value.toLowerCase())
				) {
					searchResult.push({
						id: key,
						name: value.name,
						position: value.position,
						departmentName: value.departmentName
					});
				}
			}
		} else if (event.target.value.length === 0) {
			setTargetList([]);
			if (selected) {
				setSelected(null);
			}
		}

		if (searchResult.length > 0) {
			console.log('search result: ', searchResult);
			setTargetList(searchResult);
		}
	};

	const selectHandler = (e) => {
		e.preventDefault();
		const id = e.target.value.split(' ').pop();
		if (id !== '') {
			id !== selected && setSelected(id);
		}
	};

	const createLeadersIdCollection = (selected = 'fb-foa-fa-e-oq') => {
		const splitEach = selected.split('-');
		const leadersIdCollection = [];
		splitEach.forEach((id) => {
			if (leadersIdCollection.length > 0) {
				const idString = leadersIdCollection.slice(-1) + '-' + id;
				leadersIdCollection.push(idString);
			} else {
				leadersIdCollection.push(id);
			}
		});
		console.log(leadersIdCollection);
		return leadersIdCollection;
	};

	const locateSelection = () => {
		console.log('selected: ', selected);
		if (selected.includes('-')) {
			const leadersCollection = [];
			const leadersIdCollection = createLeadersIdCollection(selected);

			leadersIdCollection.map((each) => {
				const leader = orgChart[each];
				leadersCollection.push({
					data: leader,
					active: true,
					hasBelong: true
				});
			});

			const levels = [];
			leadersCollection.slice(0, leadersCollection.length - 1).map((leader) => {
				if (leader.data.level === 'c-0') levels.push([leader]);
				const number = getLevelNumber(leader.data.level) + 1;
				const nextLevel = getLevel(
					leader.data.id,
					leader.data.level.substring(0, 2) + number.toString()
				);
				// set if the leader is active
				levels
					.slice(-1)
					.pop()
					.map((each) => {
						if (each.data.id !== nextLevel[0].data.belongsTo) {
							each.active = false;
						}
					});
				levels.push(nextLevel);
			});
			console.log('levels:', levels);
			setLevels(levels);
		}
	};

	/***********************************************************************************************************************
	 * 													MONTHLY INFO & CONTACTS 														                                       *
	 * *********************************************************************************************************************/
	const [contacts, setContacts] = useState({ phone: '', email: '' });
	const [monthlyInfo, setMonthlyInfo] = useState({
		monthlyHiring: 0,
		monthlyLeaving: 0,
		openToOpportunities: 0,
		headCount: 0
	});
	const [location, setLocation] = useState('');

	const [anchorElUserIcon, setAnchorElUserIcon] = useState(null);
	const [anchorElContact, setAnchorElContact] = useState(null);
	const [anchorElLocation, setAnchorElLocation] = useState(null);
	const handleUserIcon = (e, id) => {
		e.preventDefault();
		const leader = orgChart[id];
		setMonthlyInfo({
			monthlyHiring: leader.monthlyHiring,
			monthlyLeaving: leader.monthlyLeaving,
			openToOpportunities: leader.openToOpportunities,
			headCount: leader.headCount
		});
		setAnchorElUserIcon(e.currentTarget);
	};
	const handleContactIcon = (e, id) => {
		e.preventDefault();
		const leader = orgChart[id];
		setContacts({
			phone: leader.phone,
			email: leader.email
		});

		setAnchorElContact(e.currentTarget);
	};
	const handleLocationIcon = (e, id) => {
		e.preventDefault();
		const leader = orgChart[id];
		setLocation(leader.location);
		setAnchorElLocation(e.currentTarget);
	};
	const handleCloseUserIcon = () => {
		setAnchorElUserIcon(null);
	};
	const handleCloseContactIcon = () => {
		setAnchorElContact(null);
	};
	const handleCloseLocation = () => {
		setAnchorElLocation(null);
	};
	const addDefaultImg = (e) => {
		e.target.src =
			'https://ocinsights.s3-us-west-1.amazonaws.com/pictures/avatars/defaultProfile.png';
	};
	const openUserIcon = Boolean(anchorElUserIcon);
	const openContact = Boolean(anchorElContact);
	const openLocation = Boolean(anchorElLocation);

	/***********************************************************************************************************************
	 * 												LIFE CYCLE                														                                       *
	 * *********************************************************************************************************************/
	useEffect(() => {
		const topLeader = getTopLeader;
		levels.length === 0 &&
			typeof topLeader !== 'undefined' &&
			setLevels((prevState) => [...prevState, topLeader]);
	}, [getTopLeader, orgChart]);

	useEffect(() => {
		if (selected) {
			locateSelection(selected);
		}
	}, [selected]);

	useEffect(() => {
		scrollToRef(divRef);
	}, [levels]);

	return (
		<>
			{levels.length > 0 && (
				<>
					<Alert className="alerts-alternate mb-4" severity="#046eb8">
						<div className="d-flex align-items-center align-content-start">
							<span>{orgChartInfo}</span>

							<a
								href={`https://ocinsights.s3-us-west-1.amazonaws.com/standard/${orgId}/data.xlsx`}
								download>
								<Button variant="contained" className="btn-first m-2">
									Download
								</Button>
							</a>
						</div>
					</Alert>

					<div
						className={clsx('search-wrapper search-wrapper--grow mb-4 text-right', {
							'is-active': searchStatus1
						})}>
						<Autocomplete
							freeSolo
							id="free-solo-2-demo"
							disableClearable
							autoHighlight
							options={targetList}
							onSelect={(e) => selectHandler(e)}
							getOptionLabel={(option) =>
								option.name + ' ' + option.departmentName + ' ' + option.position + ' ' + option.id
							}
							renderOption={(option) => (
								<>
									<Grid container>
										<Grid item>
											<b>{option.name}</b>
										</Grid>
										<Grid item>
											<div className={'font-size-xs secondary'}>
												{option.departmentName},
												<span className={'font-weight-lighter font-family-monospace'}>
													{' '}
													{option.position}
												</span>
											</div>
										</Grid>
									</Grid>
								</>
							)}
							// onSelect={e=>console.log(e.target)}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="dense"
									variant="outlined"
									onChange={(e) => searchHandler(e)}
									placeholder={'Search for a name'}
									InputProps={{
										...params.InputProps,
										startAdornment: (
											<InputAdornment position="start">
												<SearchTwoToneIcon style={{ color: '#0795fe' }} />
											</InputAdornment>
										),
										type: 'search'
									}}
								/>
							)}
						/>
					</div>

					<div className="divider mb-4" />
				</>
			)}

			{levels.map((level, i) => {
				return (
					<>
						{i !== 0 && <div className="chart-divider mx-auto my-3" />}
						<Grid
							container
							key={i}
							iner
							spacing={1}
							direction={'row'}
							alignItems={'center'}
							justify={'center'}>
							{level.length > 0 &&
								level.map((each) => {
									const {
										id,
										departmentName,
										position,
										name,
										description,
										link,
										level,
										photo
									} = each.data;

									const image_path = `https://ocinsights.s3-us-west-1.amazonaws.com/pictures/leaders/${photo}`;
									return (
										<Grid item md={6} xl={3} key={id}>
											<div className="p-4">
												<span className="text-black-70 d-block">
													{departmentName}
													<Tooltip
														classes={{
															tooltip: classes.tooltip,
															arrow: classes.arrow
														}}
														arrow
														placement="top"
														title={description}>
														<span>
															<FontAwesomeIcon
																className={'ml-2'}
																icon={['fas', 'info-circle']}
																color={'#046eb8'}
															/>
														</span>
													</Tooltip>
												</span>
												<div className="divider mx-auto my-3 w-100" />
												<div className="d-flex align-items-center justify-content-between py-2">
													<div className="d-flex align-items-center">
														<div className="avatar-icon-wrapper d-50 mr-3">
															<div className="avatar-icon rounded-circle d-50 shadow-sm">
																<img
																	alt="..."
																	className={'img-fit-container'}
																	src={image_path}
																	onError={addDefaultImg}
																	style={
																		!each.active
																			? {
																					opacity: 0.2
																			  }
																			: {
																					opacity: 1
																			  }
																	}
																/>
															</div>
														</div>
														<div>
															<a
																href={link}
																target="_blank"
																className="font-weight-bold"
																title="..."
																style={
																	!each.active
																		? {
																				opacity: 0.2
																		  }
																		: {
																				opacity: 1
																		  }
																}>
																{name}
															</a>
															<span className="text-black-50 d-block">{position}</span>
														</div>
													</div>
												</div>
												<div className="divider mx-auto my-3 w-100" />
												<div
													className="text-center"
													style={
														!each.active
															? {
																	opacity: 0.2
															  }
															: {
																	opacity: 1
															  }
													}>
													<Button
														className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none border-0 btn-outline-first"
														onClick={(e) => {
															if (each.data.phone || each.data.email) handleContactIcon(e, id);
														}}
														variant="text">
														<span className="btn-wrapper--icon">
															<FontAwesomeIcon
																icon={['fas', 'comment-dots']}
																style={
																	each.data.phone || each.data.email
																		? {
																				opacity: 1
																		  }
																		: {
																				opacity: 0.2
																		  }
																}
															/>
														</span>
													</Button>
													<Button
														id={id}
														onClick={(e) => handleShowBelonging(e, id, level)}
														size={'medium'}
														style={!each.hasBelong ? { opacity: 0.2 } : { opacity: 1 }}
														className="p-2 d-30 btn-icon rounded-sm mx-1 btn-transition-none border-0 btn-outline-first"
														variant={each.isOpen ? 'contained' : 'text'}>
														<span className="btn-wrapper--icon mx-1">
															<FontAwesomeIcon icon={['far', 'eye']} />
														</span>
													</Button>
													<Button
														style={
															each.active
																? {
																		opacity: 1
																  }
																: {
																		opacity: 0.2
																  }
														}
														className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none border-0 btn-outline-first"
														onClick={(e) => handleLocationIcon(e, id)}
														variant="text">
														<span className="btn-wrapper--icon">
															<FontAwesomeIcon icon={faMapMarkerAlt} />
														</span>
													</Button>
													{/* <Button
														onClick={(e) => {
															console.log(each.data.subscribed);
															if (each.data.subscribed === null) {
																handleSubscribe(e, id);
																each.data.subscribed = 1;
																console.log('handleSubscribe: ', each.data.subscribed);
															} else if (each.data.subscribed === 1) {
																handleUnsubscribe(e, id);
																each.data.subscribed = null;
																console.log('handleUnsubscribe: ', each.data.subscribed);
															}
														}}
														className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none border-0 btn-outline-first"
														variant="text">
														<span className="btn-wrapper--icon">
															{each.data.subscribed === null ? (
																<FontAwesomeIcon icon={['far', 'heart']} />
															) : (
																''
															)}
															{each.data.subscribed === 1 ? (
																<FontAwesomeIcon icon={['fas', 'heart']} color={'red'} />
															) : (
																''
															)}
														</span>
													</Button> */}
													<Button
														style={
															i < 3
																? {
																		opacity: 1
																  }
																: {
																		opacity: 0.2
																  }
														}
														className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none border-0 btn-outline-first"
														onClick={(e) => i < 3 && handleUserIcon(e, id)}
														variant="text">
														<span className="btn-wrapper--icon">
															<FontAwesomeIcon icon={['fas', 'user']} />
														</span>
													</Button>
												</div>
											</div>
										</Grid>
									);
								})}
						</Grid>
					</>
				);
			})}

			<div id={'divRef'} ref={divRef} />

			{/***********************************************************************************************************************
			 * 													DIALOG FOR CONTACT INFO  														                                       *
			 * *********************************************************************************************************************/}
			<>
				<Popover
					classes={{ paper: 'popover-custom-wrapper popover-custom-lg' }}
					open={openContact}
					anchorEl={anchorElContact}
					onClose={handleCloseContactIcon}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}>
					<List component="div" className="list-group-flush text-left bg-transparent">
						<ListItem className="d-block bg-transparent p-0">
							<div>
								<Grid container spacing={1}>
									<Grid item sm={12} style={{ border: 0 }}>
										<div className="text-center py-3">
											<div>
												<FontAwesomeIcon
													icon={['far', 'envelope']}
													className="font-size-xxl text-info"
												/>
											</div>
											<div className="m-2 line-height-sm">
												<p className="font-size-sm">{contacts.email ? contacts.email : ''}</p>
											</div>
										</div>
									</Grid>
									<Grid item sm={12}>
										<div className="text-center py-3">
											<div>
												<FontAwesomeIcon
													icon={['fas', 'mobile-alt']}
													className="font-size-xxl text-info"
												/>
											</div>
											<div className="mt-2 line-height-sm">
												<p className="font-size-sm">{contacts.phone ? contacts.phone : ''}</p>
											</div>
										</div>
									</Grid>
								</Grid>
							</div>
						</ListItem>
					</List>
				</Popover>
				{/***********************************************************************************************************************
				 * 													DIALOG FOR LOCATION  														                                           *
				 * *********************************************************************************************************************/}
				<Popover
					classes={{ paper: classes.locationPop }}
					open={openLocation}
					anchorEl={anchorElLocation}
					onClose={handleCloseLocation}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}>
					<List component="div" className="list-group-flush text-left bg-transparent">
						<ListItem className="d-block bg-transparent p-0">
							<Grid container>
								<Grid item container>
									<Grid item sm={12}>
										<div className="text-center py-2">
											<div>
												<FontAwesomeIcon
													icon={faMapMarkerAlt}
													className="font-size-xxl text-success"
												/>
											</div>
											<div className="mt-2 line-height-sm">
												<b className="font-size-lg">{location}</b>
												<span className="text-black-50 d-block font-size-sm">Location</span>
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</Popover>
				{/***********************************************************************************************************************
				 * 													DIALOG FOR USERiCON  														                                           *
				 * *********************************************************************************************************************/}

				<Popover
					classes={{ paper: 'popover-custom-wrapper popover-custom-lg' }}
					open={openUserIcon}
					anchorEl={anchorElUserIcon}
					onClose={handleCloseUserIcon}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}>
					<List component="div" className="list-group-flush text-left bg-transparent">
						<ListItem className="d-block bg-transparent p-0">
							<Grid container>
								<Grid item container>
									<Grid item sm={6} style={{ border: 0 }}>
										<div className="text-center py-2">
											<div>
												<OpenInNewIcon className="font-size-xxl text-success" />
											</div>
											<div className="mt-2 line-height-sm">
												<b className="font-size-lg">
													{monthlyInfo.monthlyHiring ? monthlyInfo.monthlyHiring : 0}
												</b>
												<span className="text-black-50 d-block font-size-sm">Monthly hiring</span>
											</div>
										</div>
									</Grid>
									<Grid item sm={6}>
										<div className="text-center py-2">
											<div>
												<FontAwesomeIcon
													icon={['far', 'chart-bar']}
													className="font-size-xxl text-info"
												/>
											</div>
											<div className="mt-2 line-height-sm">
												<b className="font-size-lg">
													{monthlyInfo.openToOpportunities
														? `${monthlyInfo.openToOpportunities * 100}`.slice(0, 4) + ' %'
														: 0}
												</b>
												<span className="text-black-50 d-block font-size-sm">
													Open to opportunities
												</span>
											</div>
										</div>
									</Grid>
								</Grid>

								<Grid item container>
									<Grid item sm={6} style={{ border: 0 }}>
										<div className="text-center py-2">
											<div>
												<ExitToAppIcon className="font-size-xxl text-warning" />
											</div>
											<div className="mt-2 line-height-sm">
												<b className="font-size-lg">
													{monthlyInfo.monthlyLeaving ? monthlyInfo.monthlyLeaving : 0}
												</b>
												<span className="text-black-50 d-block font-size-sm">Monthly leaving</span>
											</div>
										</div>
									</Grid>
									<Grid item sm={6}>
										<div className="text-center py-2">
											<div>
												<FontAwesomeIcon
													icon={['far', 'user']}
													className="font-size-xxl text-success"
												/>
											</div>
											<div className="mt-2 line-height-sm">
												<b className="font-size-lg">
													{monthlyInfo.headCount
														? monthlyInfo.headCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
														: 0}
												</b>
												<span className="text-black-50 d-block font-size-sm">Headcount</span>
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</Popover>
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<Snackbar
						open={openSnack}
						className="text-white"
						onClose={handleCloseSnack}
						TransitionComponent={transition}
						message="Subscribed successfully! You'll receive notifications on relevant change of headcount and leadership, structure reorg, and news and rumors."
					/>
				</div>
			</>
		</>
	);
}
