import React, { useEffect } from 'react';
import { actions as recruitActions, getTalentsById } from '../../redux/modules/recruitPage';
import { actions as projectsActions, getProjectsList } from '../../redux/modules/projectsPage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EmailHistory from './components/EmailHistory';

function MainEmailHistory(props) {
	const { loadProjects } = props.projectsAction;

	useEffect(() => {
		loadProjects();
	}, []);
	console.log(props);

	return <>{props.getProjectsList && <EmailHistory projects={props.getProjectsList} />}</>;
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state) => {
	return {
		getProjectsList: getProjectsList(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainEmailHistory);
