import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import FolderIcon from '@material-ui/icons/Folder';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
//import sessionstorage from 'sessionstorage';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import LoadingIndicator from '../../../theme/loadingIndicator';
import { usageTraceLabels } from '../../../utils/message';
import { updateUsageActivity } from '../../../utils/recruitUtil';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	uploadButtonProgress: {
		color: '#0795fe'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	achievement: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap'
	},
	premium: {
		backgroundColor: '#0795fe',
		borderRadius: '0.29rem !important'
	},
	radioGroup: {
		padding: '0.8px'
	},
	addSkillOperatorOn: {
		fontSize: '1.7em',
		color: '#3E96F6'
	},
	addSkillOperatorOff: {
		fontSize: '1.7em',
		color: '#D9D9D9'
	},
	orSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	orSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	}
}));

type RecruitTableType = {
	isCheckboxAllEmpty: any;
	checkers: any;
	newProject: any;
	updateNewProject: React.Dispatch<React.SetStateAction<any>>;
	updateExistingProject: React.Dispatch<React.SetStateAction<any>>;
	existingProject: any;
	resultData: any[];
	filterSearch: any;
	handleFilterCheckAllAction: () => void;
	handleCheckAllAction: () => void;
	totalCount: number;
	numberOfSelected: number;
	checkboxPerPage: any;
	handleCheckAllActionPage: () => void;
	page: number;
	mainData: any[];
	setMainData: (event: any) => void;
	handleStatusClick: (event: any) => void;
	openStatus: boolean;
	anchorElStatus: any;
	handleStatusClose: () => void;
	filterOptions: any;
	handleFilterSearch: (event: any, value: any, name: string) => void;
	handleIRSClick: (event: any) => void;
	openIRS: boolean;
	anchorElIRS: any;
	handleIRSClose: () => void;
	promiseInProgress: boolean;
	handleCheckTalent: (id: string | number, each: any) => void;
	handleUnCheckTalent: (id: string | number, each: any) => void;
	handleClickAction: (event: any, data: any, tabIndex: string, ith: number) => void;
	handleChangePage: (event: any, value: number) => void;
	handleSortExperienceYears: (event: any) => void;
	isSortActive: boolean;
	sortDirection: any;
	pageCount: number;
	toggleDrawer: (event: any, open: boolean) => void;
};

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

export default function RecruitTable(props: RecruitTableType) {
	const {
		isCheckboxAllEmpty,
		checkers,
		newProject,
		updateNewProject,
		updateExistingProject,
		existingProject,
		resultData,
		filterSearch,
		handleFilterCheckAllAction,
		handleCheckAllAction,
		totalCount,
		numberOfSelected,
		checkboxPerPage,
		handleCheckAllActionPage,
		page,
		mainData,
		setMainData,
		handleStatusClick,
		openStatus,
		anchorElStatus,
		handleStatusClose,
		filterOptions,
		handleFilterSearch,
		handleIRSClick,
		openIRS,
		anchorElIRS,
		handleIRSClose,
		promiseInProgress,
		handleCheckTalent,
		handleUnCheckTalent,
		handleClickAction,
		handleChangePage,
		handleSortExperienceYears,
		isSortActive,
		sortDirection,
		pageCount,
		toggleDrawer
	} = props;

	const classes = useStyles();
	const [inputPage, setInputPage] = useState(page);

	const renderRows = (data: any[]): any => {
		return data ? (
			data.map((each: any, ith: number) => {
				const {
					id,
					name,
					email,
					companies,
					yearsOfWorkingExperiences,
					location,
					stacks,
					institutions,
					department_2,
					irsstatus,
					history
				} = each;

				return (
					<>
						<tr key={id} tabIndex={id}>
							<td className="text-center">
								<Checkbox
									checked={checkers[id] !== undefined}
									onChange={(e) => {
										if (e.target.checked) {
											handleCheckTalent(id, each);
										} else {
											handleUnCheckTalent(id, each);
										}
									}}
								/>
							</td>
							<td style={{ width: '10%' }}>
								<div>
									<a
										onClick={(e) => handleClickAction(e, each, '0', ith)}
										className="talent font-weight-bold text-black">
										<div>{name}</div>
									</a>
								</div>
							</td>
							<td className="text-left">
								{[
									...new Set(
										companies
											.filter((comp: any) => comp.isCurrent === 1)
											.map((item: any) => item.position)
									)
								].map((position: any) => (
									<div className="text-break-wrap">{position}</div>
								))}
							</td>
							<td style={{ width: '12%' }} className="text-left text-break-wrap">
								{location.includes('United States')
									? location.split(',').length > 2
										? location.split(',')[0].concat(',', location.split(',')[1])
										: location
									: location}
							</td>
							<td style={{ width: '8%' }} className="text-left text-break-wrap">
								{stacks}
							</td>
							<td className="text-center">{yearsOfWorkingExperiences}</td>
							<td className="text-left pl-3">
								<div className="d-flex flex-column justify-content-center align-items-start">
									{[
										...new Set(
											companies
												.filter((comp: any) => comp.isCurrent === 1)
												.map((item: any) => item.company)
										)
									].map((company: any) => (
										<div>{company}</div>
									))}
								</div>
							</td>
							<td className="text-left pl-2 text-break-wrap">{department_2}</td>
							<td className="text-left pr-3 pl-2">
								{[
									...new Set(
										institutions
											.filter((inst: any) => inst.isHighest === 1)
											.map((item: any) => item.degree)
									)
								].map((each: any) => (
									<div>{each}</div>
								))}
							</td>
							<td className="text-center pr-4">
								{history.length > 0 ? (
									<img
										className="img-20"
										src={require(`../../../assets/images/apps/replied.png`)}
									/>
								) : (
									<img
										className="img-20"
										src={require(`../../../assets/images/apps/${
											email ? 'email_verified' : 'email_unverified'
										}.png`)}
										onClick={(e) => handleClickAction(e, each, '0', ith)}
									/>
								)}
							</td>
							<td className="text-left">{irsstatus}</td>
						</tr>
					</>
				);
			})
		) : (
			<></>
		);
	};

	// const [sortDirection, setSortDirection] = useState<"asc" | "desc">('asc');

	// const handleSort = () => {
	// 	const sortedData = [...mainData].sort((a, b) => {
	// 		if (sortDirection === 'asc') {
	// 			return a.yearsOfWorkingExperiences - b.yearsOfWorkingExperiences;
	// 		} else {
	// 			return b.yearsOfWorkingExperiences - a.yearsOfWorkingExperiences;
	// 		}
	// 	});

	// 	// 更新mainData和排序方向
	// 	setMainData(sortedData);
	// 	setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
	// };

	return (
		<Card className="shadow-xxl p-3">
			<div className={classes.root}>
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<Button
							disabled={isCheckboxAllEmpty(checkers)}
							className={classes.containedPrimary}
							size="small"
							fullWidth
							onClick={() => updateNewProject({ ...newProject, open: true })}>
							Save as a new project
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Button
							disabled={isCheckboxAllEmpty(checkers)}
							className={classes.containedPrimary}
							size="small"
							fullWidth
							onClick={() => updateExistingProject({ ...existingProject, open: true })}>
							Save to existing projects
						</Button>
					</Grid>
					<Grid item xs />
					<Grid item xs={2}>
						<NavLink activeClassName="active" className="nav-link-simple" to={`/savedprojects`}>
							<Button
								fullWidth
								size="small"
								className={classes.containedPrimary}
								onClick={() => {
									updateUsageActivity(usageTraceLabels.viewProject);
								}}>
								<span className="btn-wrapper--icon">
									<FolderIcon />
								</span>
								<span className="btn-wrapper--label">View Projects</span>
							</Button>
						</NavLink>
					</Grid>
				</Grid>
			</div>

			<div className="my-2">
				<Grid
					container
					item
					xs={2}
					alignItems="center"
					justifyContent="flex-start"
					className="py-1">
					<Button
						disabled={resultData.length === 0}
						className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark mr-2"
						onClick={
							filterSearch.status.length > 0 || filterSearch.irsstatus.length > 0
								? handleFilterCheckAllAction
								: handleCheckAllAction
						}>
						All
					</Button>
					<div>
						{totalCount ? totalCount : 0} Total, {numberOfSelected} Selected
					</div>
				</Grid>
				<CardContent className="p-0">
					<div className="table-responsive-sm">
						<Table
							size={'small'}
							className="text-wrap mb-0 table-hover table-borderless font-size-sm">
							<thead className="thead">
								<tr className={'text-left'}>
									<th className="text-center">
										<Checkbox
											checked={checkboxPerPage[page - 1] === true}
											onChange={handleCheckAllActionPage}
											disabled={!mainData || mainData.length === 0}
										/>
									</th>
									<th>Talents</th>
									<th style={{ width: 200 }}>Job Title</th>
									<th>Location</th>
									<th>Tech Stacks</th>
									{/* <th className="text-center">Experience(Yrs)</th> */}
									<TableCell>
										<TableSortLabel direction={sortDirection} active={isSortActive}>
											<th onClick={handleSortExperienceYears}>Experience(Yrs)</th>
										</TableSortLabel>
									</TableCell>

									{/* <th>
										<TableCell>
											<TableSortLabel
												// active={true} // 永远激活显示，因为我们手动处理排序逻辑
												// direction={sortDirection} // 使用我们的状态来显示方向
												onClick={handleSort} // 绑定你的排序处理函数
											>
												<th>Experience(Yrs)</th>
											</TableSortLabel>
										</TableCell>
									</th> */}
									{/* <TableCell>
										<TableSortLabel
											active={orderBy === 'yearsOfWorkingExperiences'}
											direction={orderBy === 'yearsOfWorkingExperiences' ? orderDirection : 'asc'}
											onClick={(event) => handleRequestSort(event, 'yearsOfWorkingExperiences')}
										>
											Experience(Yrs)
										</TableSortLabel>
									</TableCell> */}
									<th style={{ width: 100 }} className="pl-3">
										Company
									</th>
									<th style={{ width: 200 }} className="pl-2">
										C-2 Teams
									</th>
									<th className="pl-2">Degree</th>
									<th className="text-left">
										<span>Status</span>
										{filterSearch.status.length === 0 ? (
											<IMG
												src={require('../../../assets/images/apps/filter.png')}
												onClick={(e) => handleStatusClick(e)}
											/>
										) : (
											<IMG
												src={require('../../../assets/images/apps/filter_selected.png')}
												onClick={(e) => handleStatusClick(e)}
											/>
										)}
										<Popover
											open={openStatus}
											anchorEl={anchorElStatus}
											onClose={handleStatusClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center'
											}}>
											<Autocomplete
												className={'filterHeader'}
												multiple
												size="small"
												options={filterOptions.status}
												getOptionLabel={(option) => option}
												value={filterSearch.status}
												onChange={(e, value) => handleFilterSearch(e, value, 'status')}
												renderInput={(params) => <TextField {...params} variant="standard" />}
											/>
										</Popover>
									</th>
									<th className="pl-2">
										<span>IRS Status</span>
										{filterSearch.irsstatus.length === 0 ? (
											<IMG
												src={require('../../../assets/images/apps/filter.png')}
												onClick={(e) => handleIRSClick(e)}
											/>
										) : (
											<IMG
												src={require('../../../assets/images/apps/filter_selected.png')}
												onClick={(e) => handleIRSClick(e)}
											/>
										)}
										<Popover
											open={openIRS}
											anchorEl={anchorElIRS}
											onClose={handleIRSClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center'
											}}>
											<Autocomplete
												className={'filterHeader'}
												multiple
												size="small"
												options={filterOptions.irsstatus}
												getOptionLabel={(option) => option}
												value={filterSearch.irsstatus}
												onChange={(e, value) => handleFilterSearch(e, value, 'irsstatus')}
												renderInput={(params) => <TextField {...params} variant="standard" />}
											/>
										</Popover>
									</th>
								</tr>
							</thead>
							{promiseInProgress ? <></> : <tbody>{renderRows(mainData)}</tbody>}
						</Table>
						{promiseInProgress && <LoadingIndicator />}
					</div>
					<div className="divider" />
					<div className="divider" />
					<div className="p-3 d-flex justify-content-center">
						<Pagination
							page={page}
							onChange={handleChangePage}
							className="pagination-first"
							size="small"
							count={pageCount}
						/>
						<TextField
							type="number"
							value={inputPage}
							onChange={(e) => setInputPage(Number(e.target.value))}
							inputProps={{ min: 1, max: pageCount }}
							style={{ marginLeft: '10px', width: '70px' }}
							size="small"
						/>
						<Button
							variant="contained"
							onClick={() => handleChangePage(null, inputPage)}
							style={{ marginLeft: '10px' }}
							className={classes.containedPrimary}>
							go!
						</Button>
					</div>
				</CardContent>
			</div>
		</Card>
	);
}
