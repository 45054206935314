import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Locations({ statistics }) {
	const { locations } = statistics;
	return (
		<>
			<div className="mb-spacing-6">
				<Grid container spacing={3}>
					{locations.map((location) => (
						<Grid item xl={3} md={6}>
							<Card className="mb-5 card-box card-box-border-bottom border-primary">
								<CardContent>
									<div className="text-center">
										<div className="mt-1">
											<FontAwesomeIcon
												icon={['far', 'building']}
												className="font-size-xxl text-info"
											/>
											<span
												className="text-black-50"
												style={{ fontSize: '16px', marginLeft: '2px' }}>
												{location.location}
											</span>
										</div>
										<div className="mt-3 line-height-sm">
											<b className="font-size-lg pr-1">
												{/*{location.headCount*/}
												{/*	.toString()*/}
												{/*	.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}*/}
												{new Intl.NumberFormat('en-US').format(location.headCount)}
											</b>
											<span className="text-black-50">employees</span>
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</div>
		</>
	);
}
