import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Cascader,
	Select,
	Row,
	Col,
	Button,
	AutoComplete,
	Table,
	Modal,
	Divider,
	Checkbox,
	DatePicker
} from 'antd';
import 'antd/dist/antd.css';

export default function Addcustomer({
	isLoading,
	isVisible,
	handleCancel,
	handleFinish,
	companies
}) {
	const [form] = Form.useForm();
	const { Option } = Select;

	const formItemLayout = {
		labelCol: {
			span: 6
		},
		wrapperCol: {
			span: 14
		}
	};

	return (
		<Modal
			bodyStyle={{ height: '55vh' }}
			width={600}
			visible={isVisible}
			title="Add Customer"
			footer={null}
			onCancel={handleCancel}>
			<Form {...formItemLayout} form={form} onFinish={() => handleFinish(form)}>
				<Form.Item
					name="customername"
					label="Customer"
					rules={[
						{
							required: true,
							message: 'Please input customer name!'
						}
					]}
					hasFeedback>
					<Input />
				</Form.Item>
				{/* <Form.Item
					initialValue="1"
					label="Company Detail"
					name="companyDetail"
					valuePropName="checked">
					<Checkbox />
				</Form.Item>
				<Form.Item initialValue="1" label="Summary" name="summary" valuePropName="checked">
					<Checkbox />
				</Form.Item> */}
				<Form.Item initialValue="1" label="Recruitment" name="recruitment" valuePropName="checked">
					<Checkbox />
				</Form.Item>
				<Form.Item
					label="Start Date"
					name="startDate"
					rules={[
						{
							required: true,
							message: 'Please input start date!'
						}
					]}
					hasFeedback>
					<DatePicker />
				</Form.Item>
				<Form.Item
					label="End Date"
					name="endDate"
					rules={[
						{
							required: true,
							message: 'Please input end date!'
						}
					]}
					hasFeedback>
					<DatePicker />
				</Form.Item>
				<Form.Item
					name="companies"
					label="Unlock Companies"
					rules={[
						{ required: true, message: 'Please select your unlock companyies!', type: 'array' }
					]}>
					<Select mode="multiple" placeholder="Please select unlock companies">
						{companies &&
							companies.map((company) => {
								return (
									<Option key={company.companyId} value={company.companyId}>
										{company.name}
									</Option>
								);
							})}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={isLoading}>
						Add
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
