export default () => {
	let env = process.env.REACT_APP_ENVIRONMENT;

	let baseUrl = '';
	/* @ChunPingYang: remember to expose those vars or restrict to only one value */
	if (env === 'local') {
		// baseUrl = process.env.REACT_APP_DEV_BASE_URL;
		baseUrl = 'http://localhost:80';
	} else if (env === 'dev') {
		baseUrl = 'http://dev.ocinsights.ai';
		// baseUrl = 'http://54.183.130.157';
	} else if (env === 'production') {
		baseUrl = 'https://prod.ocinsights.ai:443';
	}

	console.log('BaseUrl: ', baseUrl, 'REACT_APP_ENVIRONMENT: ', env);
	return baseUrl;
};
