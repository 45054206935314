import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { ThemeProvider } from '@material-ui/styles';
import url from '../../utils/url';
import storage from '../../utils/storage';

import MuiTheme from '../../theme';
import { get } from '../../utils/request';

// Layout Blueprints

import {
	LeftSidebar,
	MinimalLayout
	// PresentationLayout
} from '../../layout-blueprints';

import {
	Dashboard,
	OrgDetail,
	Login,
	Reset,
	PageError404,
	SavedProjects,
	ListTalentsInProject,
	ExecutiveSummary,
	Search,
	UserManagement,
	EmailTemplate,
	EmailHistory,
	ContactSearch,
	Contact,
	ListRecommendTalents,
	LinkedinCredential,
	AutoNoteHistory,
	TraceReport,
	Notification,
	ProjectViewRoute,
	ProjectEditRoute,
	EmailHistoryChrome,
	TestingPage
} from '../index';
import Recruit from '../Recruit';
import PrivateRoute from '../../utils/PrivateRoute';
import store from '../../redux/stores';

const Routes = () => {
	const location = useLocation();

	const pageVariants = {
		initial: {
			opacity: 0
		},
		in: {
			opacity: 1
		},
		out: {
			opacity: 0
		}
	};

	const pageTransition = {
		type: 'tween',
		ease: 'linear',
		duration: 0.3
	};

	const SuspenseLoading = () => {
		const [show, setShow] = useState(false);

		useEffect(() => {
			let timeout = setTimeout(() => setShow(true), 300);
			return () => {
				clearTimeout(timeout);
			};
		}, []);

		return (
			<>
				<AnimatePresence>
					{show && (
						<motion.div
							key="loading"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.4 }}>
							<div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
								<div className="d-flex align-items-center flex-column px-4">
									<ClimbingBoxLoader color={'#3c44b1'} loading={true} />
								</div>
								<div className="text-muted font-size-xl text-center pt-3">Please wait ....</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</>
		);
	};

	useEffect(() => {
		get(url.fetchSkllMapping()).then((res) => {
			const skills = res.data;
			for (const each of skills) {
				const label = each['skill'];
				const special = each['specialCharacterSkill'];
				storage.saveSkillMapping(special, label);
			}
		});
	}, []);

	return (
		<ThemeProvider theme={MuiTheme}>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					<Switch>
						<Redirect exact from="/" to="/recruit" />

						<Route
							path={[
								'/Dashboard',
								'/OrgDetail/:orgId',
								'/recruit',
								'/testingPage',
								'/savedprojects',
								'/savedprojects/:projectName',
								'/executive',
								'/emailTemplate',
								'/emailHistory',
								'/emailHistoryChrome',
								'/contactsearch',
								'/contact',
								'/linkedinCredential',
								'/autoNoteHistory',
								'/traceReport',
								'/notification',
								'/projectviewroute/:projectname',
								'/projecteditroute/:projectname'
							]}>
							<LeftSidebar>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial="initial"
										animate="in"
										exit="out"
										variants={pageVariants}
										transition={pageTransition}>
										<PrivateRoute path="/recruit" component={Recruit} />
										<PrivateRoute exact path="/Dashboard" component={Dashboard} />
										<PrivateRoute
											path="/OrgDetail/:orgId"
											component={(props) => <OrgDetail tabIndex={0} {...props} />}
										/>
										<PrivateRoute exact path="/savedprojects" component={SavedProjects} />
										<PrivateRoute
											exact
											path="/savedprojects/projectedit/:projectName"
											component={ListTalentsInProject}
										/>
										<PrivateRoute
											exact
											path="/savedprojects/projectview/:projectName"
											component={ListRecommendTalents}
										/>
										<PrivateRoute exact path="/emailTemplate" component={EmailTemplate} />
										<PrivateRoute exact path="/emailHistory" component={EmailHistory} />
										<PrivateRoute exact path="/emailHistoryChrome" component={EmailHistoryChrome} />
										<PrivateRoute exact path="/executive" component={ExecutiveSummary} />
										<PrivateRoute
											exact
											path="/contactsearch"
											component={(props) => <ContactSearch {...props} />}
										/>
										<PrivateRoute exact path="/contact" component={Contact} />
										<PrivateRoute exact path="/linkedinCredential" component={LinkedinCredential} />
										<PrivateRoute exact path="/autoNoteHistory" component={AutoNoteHistory} />
										<PrivateRoute exact path="/traceReport" component={TraceReport} />
										<PrivateRoute exact path="/notification" component={Notification} />
										<PrivateRoute exact path="/testingPage" component={TestingPage} />

										<PrivateRoute
											exact
											path="/projectviewroute/:projectname"
											component={ProjectViewRoute}
										/>
										<PrivateRoute
											exact
											path="/projecteditroute/:projectname"
											component={ProjectEditRoute}
										/>
									</motion.div>
								</Switch>
							</LeftSidebar>
						</Route>

						<Route path={['/404', '/login', '/reset', '/search', '/admin']}>
							<MinimalLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial="initial"
										animate="in"
										exit="out"
										variants={pageVariants}
										transition={pageTransition}>
										<PrivateRoute exact path={'/reset'} component={Reset} />
										<Route exact path="/login" component={Login} />
										<Route exact path="/search" component={Search} />
										<PrivateRoute exact path="/admin" component={UserManagement} />
										<PrivateRoute exact path="/404" component={PageError404} />
									</motion.div>
								</Switch>
							</MinimalLayout>
						</Route>
					</Switch>
				</Suspense>
			</AnimatePresence>
		</ThemeProvider>
	);
};

export default Routes;
