import url from "../../utils/url";
import {FETCH_DATA} from "../middlewares/api";
import {schema} from "./entities/overview";
import {combineReducers} from "redux";

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
  FETCH_OVERVIEW_REQUEST: "DASHBOARD/FETCH_OVERVIEW_REQUEST",
  FETCH_OVERVIEW_SUCCESS: "DASHBOARD/FETCH_OVERVIEW_SUCCESS",
  FETCH_OVERVIEW_FAILURE: "DASHBOARD/FETCH_OVERVIEW_FAILURE",
}

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
  overview: {
    isFetching: false,
    id: null
  },
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadOverviewTab: (id) =>{
    return (dispatch, getState) =>{
      const endpoint = url.getOverview(id);
      return dispatch(fetchOverviewTab(endpoint, id));
    }
  }
}

const fetchOverviewTab = (endpoint, id) =>({
  [FETCH_DATA]: {
    types: [types.FETCH_OVERVIEW_REQUEST, types.FETCH_OVERVIEW_SUCCESS, types.FETCH_OVERVIEW_FAILURE],
    endpoint,
    schema
  },
  id
})

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const overview = (state = initialState.overview, action) =>{
  switch (action.type) {
    case types.FETCH_OVERVIEW_REQUEST:
      return {...state, isFetching: true};
    case types.FETCH_OVERVIEW_SUCCESS:
      return {...state, isFetching: false, id: action.response.ids};
    case types.FETCH_OVERVIEW_FAILURE:
      return {...state, isFetching: false};
    default:
      return state;
  }
}

const reducer = combineReducers({overview});
export default reducer;


/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/
export const getOverviewById = (state) =>{
  return state.overviewTab.overview.id && state.entities.overview[state.overviewTab.overview.id[0]];
}
