import { ControlPointSharp } from '@material-ui/icons';
import axios from 'axios';
import https from 'https';
import moment from 'moment';
import url from './url';

export const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

export function handleScheduleTime(unread_time) {
	let time;

	if (
		(unread_time.hour == null || unread_time.hour == '') &&
		(unread_time.min == null || unread_time.min == '')
	) {
		time = '00:00:00';
	} else if (unread_time.hour !== null && unread_time.min !== null) {
		time = `${unread_time.hour}:${unread_time.min}:00`;
	} else if (!(unread_time.hour === null || unread_time.hour === '')) {
		time = `${unread_time.hour}:00:00`;
	} else if (!(unread_time.min === null || unread_time.min === '')) {
		time = `00:${unread_time.min}:00`;
	}

	return time;
}

export function checkScheduleTime(unread_time) {
	if (
		unread_time.hour == null ||
		unread_time.hour == '' ||
		unread_time.min == null ||
		unread_time.min == ''
	) {
		return false;
	} else if (unread_time.hour !== null && unread_time.min !== null) {
		if (
			parseInt(unread_time.hour) < 24 ||
			parseInt(unread_time.min) < 0 ||
			parseInt(unread_time.min) >= 60
		) {
			return false;
		} else if (!is_numeric(unread_time.hour) || !is_numeric(unread_time.min)) {
			return false;
		}
	}

	return true;
}

export function checkAutoNoteParams(sleepTime, withDrawDays) {
	if (sleepTime === null || sleepTime === '' || withDrawDays === null || withDrawDays === '') {
		return false;
	} else if (sleepTime !== null && withDrawDays !== null) {
		if (!is_numeric(sleepTime) || !is_numeric(withDrawDays)) {
			return false;
		} else if (parseInt(sleepTime) < 2 || parseInt(withDrawDays) < 7) {
			return false;
		}
	}

	return true;
}

function is_numeric(number) {
	console.log(number);
	return /^\d+$/.test(number);
}

export function searchOptions(data) {
	const talents = data;

	let company = [];
	let seniority = [];
	let project = [];

	talents.forEach((talent) => {
		if (!company.includes(talent['company']) && talent['company']) {
			company.push(talent['company']);
		}
		if (!seniority.includes(talent['seniority']) && talent['seniority']) {
			seniority.push(talent['seniority']);
		}
		if (!project.includes(talent['project']) && talent['project']) {
			project.push(talent['project']);
		}
	});
	//project.push('None');

	return {
		company: company.sort(),
		seniority,
		project: project.sort()
	};
}

export function searchResultOptions(data) {
	// const talents = data;

	// let company = [];
	// let seniority = [];
	// let project = [];

	// talents.forEach((talent) => {
	// 	if (!company.includes(talent['company']) && talent['company']) {
	// 		company.push(talent['company']);
	// 	}
	// 	if (!seniority.includes(talent['seniority']) && talent['seniority']) {
	// 		seniority.push(talent['seniority']);
	// 	}
	// 	if (!project.includes(talent['project']) && talent['project']) {
	// 		project.push(talent['project']);
	// 	}
	// });
	// //project.push('None');

	// return {
	// 	company: company.sort(),
	// 	seniority,
	// 	project: project.sort()
	// };

	let status = [];
	let irs = [];

	// const email_verified = <img
	// className="img-20"
	// src={require(`../../../../assets/images/apps/email_verified.png`)}
	// />
}

export function transformDateToLocalTZ(sent_time) {
	return moment(new Date(sent_time)).format('MM/DD/YYYY HH:mm');
}

export function transformDateToYearMonthDate(sent_time) {
	return moment(new Date(sent_time)).format('MM/DD/YYYY');
}

export function checkWithdrawDateLaterThanToday(withdrawTime) {
	const today = new Date(moment().format('MM/DD/YYYY 00:00'));
	const withdrawDate = new Date(moment(withdrawTime).format('MM/DD/YYYY 00:00'));

	return withdrawDate.getTime() > today.getTime();
}

export function transformToMonthDay(time) {
	return moment(new Date(time)).format('MM/DD');
}

export const emailTraceLabels = () => {
	return {
		email: 'B2',
		phone: 'B3',
		area: 'B4',
		stack: 'B5',
		seniority: 'B6',
		company: 'B7',
		department_1: 'B8',
		department_2: 'B9',
		degree: 'B10',
		openToOpportunities: 'B11'
	};
};

export const usageTraceLabels = () => {
	return {
		search: 'search',
		viewProject: 'viewProject',
		traceReport: 'traceReport',
		searchTalents: 'searchTalents',
		talentAnalytics: 'talentAnalytics',
		dashboard: 'dashboard',
		searchContact: 'searchContact'
	};
};

export const updateUsageActivity = (activityType) => {
	const labels = usageTraceLabels();

	axios
		.post(
			url.updateUsageActivity(),
			{
				type: labels[activityType]
			},
			config
		)
		.then((res) => {
			console.log('updateUsageActivity', res.data);
		})
		.catch((e) => {
			console.error('updateUsageActivity error catch', e.message);
		});
};

export const replaceDoubleBraces = (str, result) => {
	//const quote = str.replace(/"/g,'');
	//const syntax = {result};
	return str.replace(/{{(.+?)}}/g, result);
};

export const skillInterpretation = (list) => {
	const listCopy = [...list];
	if (listCopy.length === 0) {
		return [listCopy, '+'];
	}
	let newList = [];
	while (listCopy.length > 0) {
		let front = listCopy.shift();
		if (typeof front === 'string') {
			newList.push([front, '+']);
		} else if (Array.isArray(front)) {
			for (let i = 0; i < front.length - 1; i++) {
				newList.push([front[i], '/']);
			}
			newList.push([front[front.length - 1], '*/']); // last element of OR group
		}
	}
	return newList;
};
/// jimmy testing begin
export const reverseSkillInterpretation = (newList) => {
	const newListCopy = [...newList];
	if (newListCopy.length === 0) {
		return [];
	}
	let list = [];
	let currentGroup = [];
	for (let i = 0; i < newListCopy.length; i++) {
		const [skill, operator] = newListCopy[i];
		if (operator === '+') {
			list.push(skill);
		} else if (operator === '/') {
			if (currentGroup.length > 0) {
				list.push(currentGroup);
				currentGroup = [];
			}
			currentGroup.push(skill);
		} else if (operator === '*/') {
			currentGroup.push(skill);
			list.push(currentGroup);
			currentGroup = [];
		}
	}
	if (currentGroup.length > 0) {
		list.push(currentGroup);
	}
	return list;
};

// export const toBooleanSkills = (skills) => {
// 	let booleanSkills = [];
// 	let currentGroup = [];

// 	for (let i = 0; i < skills.length; i++) {
// 		const [skill, operator] = skills[i];

// 		if (operator === '+') {
// 			currentGroup.push(skill);
// 		} else if (operator === '/') {
// 			if (i > 0 && skills[i - 1][1] !== '/') {
// 				booleanSkills.push(currentGroup.join(' AND '));
// 				currentGroup = [skill];
// 			} else {
// 				currentGroup.push(skill);
// 			}
// 		} else if (operator === '*/') {
// 			currentGroup.push(skill);
// 			booleanSkills.push(`(${currentGroup.join(' OR ')})`);
// 			currentGroup = [];
// 		}
// 	}

// 	if (currentGroup.length > 0) {
// 		booleanSkills.push(currentGroup.join(' AND '));
// 	}

// 	let result = booleanSkills.join(' AND ');
// 	if (skills[0][1] === '/') {
// 		result = `(${result})`;
// 	}

// 	// 检查是否需要移除最外层的括号
// 	if (result.startsWith('((') && result.endsWith(')')) {
// 		result = result.slice(1, -1);
// 	}
// 	// 移除直接相连的两个 AND 运算符
// 	result = result.replace(/(AND|OR)\s+\1/g, '$1');
// 	return [result];
// };
export const toBooleanSkills = (skills) => {
	let booleanSkills = [];
	let currentGroup = [];

	for (let i = 0; i < skills.length; i++) {
		const [skill, operator] = skills[i];

		if (operator === '+') {
			currentGroup.push(skill);
		} else if (operator === '/') {
			if (i > 0 && skills[i - 1][1] !== '/') {
				booleanSkills.push(currentGroup.join(' AND '));
				currentGroup = [skill];
			} else {
				currentGroup.push(skill);
			}
		} else if (operator === '*/') {
			currentGroup.push(skill);
			booleanSkills.push(`(${currentGroup.join(' OR ')})`);
			currentGroup = [];
		}
	}

	if (currentGroup.length > 0) {
		booleanSkills.push(currentGroup.join(' AND '));
	}

	let result = booleanSkills.join(' AND ');
	if (skills[0][1] === '/') {
		result = `(${result})`;
	}

	// 检查是否需要移除最外层的括号
	if (result.startsWith('((') && result.endsWith(')')) {
		result = result.slice(1, -1);
	}

	// 移除直接相连的两个 AND 运算符
	result = result.replace(/(AND|OR)\s+\1/g, '$1');

	// 删除结果中的所有单引号和双引号
	result = result.replace(/['"]/g, '');

	return [result];
};

//   export const fromBooleanSkills = (booleanSkills) => {
// 	// 准备输出的结果数组
// 	let result = [];

// 	// 遍历所有输入的项
// 	for (let i = 0; i < booleanSkills.length; i++) {
// 	  let item = booleanSkills[i];

// 	  // 移除前后空格并用AND或OR进行分割
// 	  let parts = item.replace(/\s+/g, '').split(/AND|OR/);

// 	  // 检查是否存在括号，如果存在，则创建嵌套数组
// 	  if (item.includes('(')) {
// 		let nested = [];

// 		// 遍历所有的部分
// 		for (let j = 0; j < parts.length; j++) {
// 		  // 如果包含括号的部分则添加到嵌套数组
// 		  if (parts[j].includes('(') || parts[j].includes(')')) {
// 			nested.push(parts[j].replace(/[()]/g, ''));
// 		  } else {
// 			result.push(parts[j]);
// 		  }
// 		}

// 		if (nested.length > 0) {
// 		  result.push(nested);
// 		}
// 	  } else {
// 		// 如果没有括号，则将部分直接添加到结果数组
// 		result = result.concat(parts);
// 	  }
// 	}

// 	return result;
//   };

// export const fromBooleanSkills = (booleanSkills) => {
// 	// 准备输出的结果数组
// 	let result = [];

// 	// 遍历所有输入的项
// 	for (let i = 0; i < booleanSkills.length; i++) {
// 		let item = booleanSkills[i];

// 		// 移除前后空格并用AND进行分割
// 		let andParts = item.replace(/\s+/g, '').split('AND');

// 		for (let j = 0; j < andParts.length; j++) {
// 			let part = andParts[j];

// 			// 如果包含括号的部分则删除括号并用OR分割
// 			if (part.includes('(')) {
// 				let orParts = part.replace(/[()]/g, '').split('OR');
// 				result.push(orParts);
// 			} else {
// 				result.push(part);
// 			}
// 		}
// 	}

// 	return result;
// };

// export const fromBooleanSkills = (booleanSkills) => {
// 	// 准备输出的结果数组
// 	let result = [];

// 	// 遍历所有输入的项
// 	for (let i = 0; i < booleanSkills.length; i++) {
// 		let item = booleanSkills[i];

// 		// 移除输入中的所有单引号和双引号
// 		item = item.replace(/['"]/g, '');

// 		// 移除前后空格并用AND进行分割
// 		let andParts = item.replace(/\s+/g, '').split('AND');

// 		for (let j = 0; j < andParts.length; j++) {
// 			let part = andParts[j];

// 			// 如果包含括号的部分则删除括号并用OR分割
// 			if (part.includes('(')) {
// 				let orParts = part.replace(/[()]/g, '').split('OR');
// 				result.push(orParts);
// 			} else {
// 				result.push(part);
// 			}
// 		}
// 	}

// 	return result;
// };

export const fromBooleanSkills = (booleanSkills) => {
	// 准备输出的结果数组
	let result = [];

	// 遍历所有输入的项
	for (let i = 0; i < booleanSkills.length; i++) {
		let item = booleanSkills[i];

		// 移除输入中的所有单引号和双引号
		item = item.replace(/['"]/g, '');

		// 使用正则表达式移除多余的空格并用AND进行分割
		let andParts = item.split(/\s*AND\s*/);

		for (let j = 0; j < andParts.length; j++) {
			let part = andParts[j];

			// 如果包含括号的部分则删除括号并用OR分割
			if (part.includes('(')) {
				let orParts = part.replace(/[()]/g, '').split(/\s*OR\s*/);
				result.push(orParts);
			} else {
				result.push(part);
			}
		}
	}

	return result;
};

/// jimmy testing end

export const addSkill = (list, result, operator) => {
	const listCopy = [...list];
	if (listCopy.length === 0) {
		listCopy[0] = result;
		return listCopy;
	}
	// check if it already exists
	for (let i = 0; i < listCopy.length; i++) {
		if (typeof listCopy[i] === 'string') {
			if (result === listCopy[i]) {
				return listCopy;
			}
		} else if (Array.isArray(listCopy[i])) {
			for (let j = 0; j < listCopy[i].length; j++) {
				if (result === listCopy[i][j]) {
					return listCopy;
				}
			}
		}
	}
	if (operator == 'OR') {
		let last = listCopy.pop();
		if (typeof last === 'string') {
			listCopy.push([last, result]);
		} else if (Array.isArray(last)) {
			last.push(result);
			listCopy.push(last);
		}
	} else {
		listCopy.push(result);
	}
	return listCopy;
};

export const removeSkill = (list, target) => {
	const listCopy = [...list];
	var index = listCopy.indexOf(target);
	if (index !== -1) {
		listCopy.splice(index, 1);
	}
	for (let i = 0; i < listCopy.length; i++) {
		if (Array.isArray(listCopy[i])) {
			var index = listCopy[i].indexOf(target);
			if (index !== -1) {
				listCopy[i].splice(index, 1);
				if (listCopy[i].length == 1) {
					listCopy[i] = listCopy[i][0];
				}
			}
		}
	}
	return listCopy;
};

export const skillGenerateSQL = (list) => {
	const listCopy = [...list];
	let skillSQL = '';
	let lastOperator = '+';
	while (listCopy.length > 0) {
		let front = listCopy.shift();
		if (front[1] === '+') {
			skillSQL += '+' + specialCharactersHelper(front[0]) + ' ';
		} else if (front[1] === '/' && lastOperator === '+') {
			skillSQL += '+(' + specialCharactersHelper(front[0]) + ' ';
		} else if (front[1] === '/' && lastOperator === '/') {
			skillSQL += specialCharactersHelper(front[0]) + ' ';
		} else if (front[1] === '*/') {
			skillSQL += specialCharactersHelper(front[0]) + ') ';
		} else if (front[1] === '/' && lastOperator === '*/') {
			skillSQL += '+(' + specialCharactersHelper(front[0]) + ' ';
		}
		lastOperator = front[1];
	}
	console.log('Generated SQL: ', skillSQL);
	return skillSQL;
};

export const reverseSkillGenerateSQL = (skillSQL) => {
	const list = [];
	let current = '';
	let lastOperator = '+';
	let isInQuotes = false;

	for (let i = 0; i < skillSQL.length; i++) {
		const char = skillSQL[i];

		if (char === '+' && !isInQuotes) {
			//console.log("first condition..");
			if (current && current.trim().length > 0) {
				//console.log("current....", i, "current: ", current, "lastOperator: ", lastOperator);
				list.push([current.trim(), lastOperator]);
				current = '';
			}
		} else if (char === '+' && isInQuotes) {
			//console.log("second condition..");
			current += char;
		} else if (char === ' ' && isInQuotes) {
			//console.log("third condition..");
			current += char;
		} else if (char === '(') {
			//console.log("fourth condition..");
			if (current && current.trim().length > 0) {
				//console.log("current....", i, "current: ", current, "lastOperator: ", lastOperator);
				list.push([current.trim(), lastOperator]);
				current = '';
			}
			isInQuotes = true;
		} else if (char === ')') {
			//console.log("fifth condition..");
			if (current && current.trim().length > 0) {
				//console.log("current....", i, "current: ", current, "lastOperator: ", lastOperator);
				list.push([current.trim(), lastOperator]);
				current = '';
			}
			//list.push([null, '*/']);
			isInQuotes = false;
		} else if (char === '"') {
			//console.log("sixth condition..");
			if (current && current.trim().length > 0) {
				//console.log("current....", i, "current: ", current, "lastOperator: ", lastOperator);
				list.push([current.trim(), lastOperator]);
				current = '';
			} else {
				//console.log("current....", i, "current: ", current, "lastOperator: ", lastOperator);
			}
			let closingQuoteIndex = skillSQL.indexOf('"', i + 1);
			let skillName = skillSQL.substring(i + 1, closingQuoteIndex);
			skillName = skillName.trim();

			if (lastOperator === '(') {
				lastOperator = '/';
			} else if (lastOperator === ' ') {
				const forwardIndex = closingQuoteIndex + 1;
				const character = skillSQL.substring(forwardIndex, forwardIndex + 1);
				if (character === ' ') {
					lastOperator = '/';
				} else if (character === ')') {
					lastOperator = '*/';
				}
			}
			//console.log("skillName: ", skillName, "lastOperator: ", lastOperator, "closingQuoteIndex: ", closingQuoteIndex)
			list.push([skillName, lastOperator]);
			i = closingQuoteIndex;
		} else {
			//console.log("last condition...")
			current += char;
		}

		//console.log("char: ", char, "isInQuotes: ", isInQuotes, "current: ", current)
		lastOperator = char;
		//console.log("lastOperator: ", lastOperator + "\n")
	}

	if (current && current.trim().length > 0) {
		list.push([current.trim(), lastOperator]);
	}

	for (let j = 0; j < list.length; j++) {
		const item = list[j];
		const newWord = reverseSpecialCharactersHelper(item[0]);
		item[0] = newWord;
		list[j] = item;
	}

	return list;
};

export const specialCharactersHelper = (inputStr) => {
	let res = inputStr;
	const characterMp = new Map();
	characterMp.set('+', 'plus');
	characterMp.set('-', 'minus');
	characterMp.set('@', 'at');
	characterMp.set('>', 'greaterthan');
	characterMp.set('<', 'smallerthan');
	characterMp.set('(', 'openparen');
	characterMp.set(')', 'closeparen');
	characterMp.set('~', 'tilde');
	characterMp.set('*', 'asterisk');
	characterMp.set('"', 'quote');
	characterMp.set('#', 'sharp');

	for (let [key, value] of characterMp) {
		while (res.includes(key)) {
			res = res.replace(key, value);
		}
	}
	//res = res.replace(/\s+/g, '');
	res = '"' + res + '"';
	return res;
};

const reverseSpecialCharactersHelper = (inputStr) => {
	let res = inputStr;
	const characterMp = new Map();

	characterMp.set('plus', '+');
	characterMp.set('minus', '-');
	characterMp.set('at', '@');
	characterMp.set('greaterthan', '>');
	characterMp.set('smallerthan', '<');
	characterMp.set('openparen', '(');
	characterMp.set('closeparen', ')');
	characterMp.set('tilde', '~');
	characterMp.set('asterisk', '*');
	characterMp.set('quote', '"');
	characterMp.set('sharp', '#');

	for (let [key, value] of characterMp) {
		while (res.includes(key)) {
			res = res.replace(key, value);
		}
	}

	return res;
};

export const regx = /[^a-zA-Z0-9,;\-.!/? ]/g;

export const specialCharactersHelperWordToLabel = (inputStr) => {
	let res = inputStr;
	const characterMp = new Map();
	characterMp.set('plus', '+');
	characterMp.set('minus', '-');
	characterMp.set('at', '@');
	characterMp.set('greaterthan', '>');
	characterMp.set('smallerthan', '<');
	characterMp.set('openparen', '(');
	characterMp.set('closeparen', ')');
	characterMp.set('tilde', '~');
	characterMp.set('asterisk', '*');
	characterMp.set('quote', '"');
	characterMp.set('sharp', '#');

	for (let [key, value] of characterMp) {
		while (res.includes(key)) {
			res = res.replace(key, value);
		}
	}

	return res;
};

export const enumEmailType = () => {
	return {
		HAS_EMAIL: 'Has Email',
		NO_EMAIL: 'No Email',
		NO_ACTION: 'Uncontacted',
		PENDING: 'Pending',
		SENT: 'Unopened',
		OPEN: 'Opened w/o reply',
		REPLIED: 'Replied'
	};
};

export const enumIRSType = () => {
	return {
		NO_DUPLICATE: 'No Duplicate'
	};
};

export const enumLinkedinDuplicateType = () => {
	return {
		YES: 'YES',
		NO: 'NO'
	};
};

export function checkValid(obj) {
	if (obj && typeof obj !== 'undefined') {
		if (Array.isArray(obj)) {
			return obj.length > 0;
		}

		return true;
	} else {
		return false;
	}
}

export function transformFilter(filter) {
	const yoe = filter.yearsOfWorkingExperiences;
	if (Array.isArray(yoe) && yoe.length > 0) {
		const item = yoe[0];
		if (typeof item === 'object' && typeof item !== 'number') {
			let finalMin = 100;
			let finalMax = 0;
			for (const each of yoe) {
				const key = each.key;
				const keyNumber = parseInt(key);
				const min = yoeMapping[keyNumber][0];
				const max = yoeMapping[keyNumber][1];

				finalMin = Math.min(finalMin, min);
				finalMax = Math.max(finalMax, max);
			}

			filter.yearsOfWorkingExperiences = [finalMin, finalMax];
		}
	}

	return filter;
}

const yoeMapping = {
	1: [0, 1],
	2: [1, 3],
	3: [4, 6],
	4: [7, 9],
	5: [10, 14],
	6: [15, 100]
};
