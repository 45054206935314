import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
export default function Search() {
	const [show, setShow] = useState(true);

	return (
		<>
			<div className="app-wrapper min-vh-100 bg-white">
				<div className="app-main min-vh-100">
					<div className="app-content p-0">
						<div className="app-inner-content-layout--main">
							<div className="flex-grow-1 w-100 d-flex align-items-center">
								<div className="bg-composed-wrapper--content">
									<Grid container spacing={0} className="min-vh-100">
										<Grid item lg={7} xl={6}>
											<div className="m-5">
												<div>
													<div className="my-4">
														<TextField
															fullWidth
															placeholder={'Search'}
															variant="outlined"
															id="textfield-search"
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<SearchTwoToneIcon style={{ color: '#0795fe' }} />
																	</InputAdornment>
																)
															}}
														/>
													</div>
												</div>
											</div>
											{show && (
												<div className={'m-5'}>
													<div className="scroll-area-xl shadow-overflow">
														<PerfectScrollbar options={{ wheelPropagation: false }}>
															<List component="div" className="list-group-flush">
																<ListItem className="py-4 d-block">
																	<div className="d-flex justify-content-between">
																		<div className="d-flex align-items-center">
																			<div>
																				<Card className="card-transparent mb-3 mb-sm-0">
																					<a
																						href="#/"
																						onClick={(e) => e.preventDefault()}
																						className="card-img-wrapper card-box-hover-rise rounded-sm">
																						<div className="card-badges card-badges-bottom">
																							<div className="badge badge-pill badge-warning">
																								Rumor
																							</div>
																						</div>
																					</a>
																				</Card>
																			</div>
																			<div className="pl-0 pl-sm-4">
																				<b className="font-weight-bold font-size-lg text-black">
																					Facebook is bringing ads to shorter videos and Stories
																				</b>
																				<p className="text-black-50 mb-0">
																					Facebook is expanding its monetization options for video
																					creators. For anyone watching videos posted by those
																					creators, that probably means you’ll see more ads.
																				</p>
																				<small className="text-black-50 pt-1 d-block">
																					Created on: <b className="text-first">23 Feb 2021</b>
																				</small>
																			</div>
																		</div>
																	</div>
																</ListItem>
																<ListItem className="py-4 d-block">
																	<div className="d-flex justify-content-between">
																		<div className="d-flex align-items-center">
																			<div>
																				<Card className="card-transparent mb-3 mb-sm-0">
																					<a
																						href="#/"
																						onClick={(e) => e.preventDefault()}
																						className="card-img-wrapper card-box-hover-rise rounded-sm">
																						<div className="card-badges card-badges-bottom">
																							<div className="badge badge-danger">News</div>
																						</div>
																					</a>
																				</Card>
																			</div>
																			<div className="pl-0 pl-sm-4">
																				<b className="font-weight-bold font-size-lg text-black">
																					Facebook faces US investigation for 'systemic' racial bias
																					in hiring
																				</b>
																				<p className="text-black-50 mb-0">
																					A US agency investigating Facebook for racial bias in
																					hiring and promotions has designated its inquiry as
																					“systemic”, meaning it suspects company policies may be
																					contributing to widespread discrimination.{' '}
																				</p>
																				<small className="text-black-50 pt-1 d-block">
																					Created on: <b className="text-first">24 Feb 2021</b>
																				</small>
																			</div>
																		</div>
																	</div>
																</ListItem>
																<ListItem className="py-4 d-block">
																	<div className="d-flex justify-content-between">
																		<div className="d-flex align-items-center">
																			<div>
																				<Card className="card-transparent mb-3 mb-sm-0">
																					<a
																						href="#/"
																						onClick={(e) => e.preventDefault()}
																						className="card-img-wrapper card-box-hover-rise rounded-sm">
																						<div className="card-badges card-badges-bottom">
																							<div className="badge badge-danger">News</div>
																						</div>
																					</a>
																				</Card>
																			</div>
																			<div className="pl-0 pl-sm-4">
																				<b className="font-weight-bold font-size-lg text-black">
																					Microsoft suspends new LinkedIn sign-ups in China
																				</b>
																				<p className="text-black-50 mb-0">
																					LinkedIn — one of the few Western social media networks
																					available in China — has suspended new sign-ups in the
																					country, but said the decision...
																				</p>
																				<small className="text-black-50 pt-1 d-block">
																					Created on: <b className="text-first">23 Feb 2021</b>
																				</small>
																			</div>
																		</div>
																	</div>
																</ListItem>
																<ListItem className="py-4 d-block">
																	<div className="d-flex justify-content-between">
																		<div className="d-flex align-items-center">
																			<div>
																				<Card className="card-transparent mb-3 mb-sm-0">
																					<a
																						href="#/"
																						onClick={(e) => e.preventDefault()}
																						className="card-img-wrapper card-box-hover-rise rounded-sm">
																						<div className="card-badges card-badges-bottom">
																							<div className="badge badge-pill badge-warning">
																								Rumor
																							</div>
																						</div>
																					</a>
																				</Card>
																			</div>
																			<div className="pl-0 pl-sm-4">
																				<b className="font-weight-bold font-size-lg text-black">
																					Uber Freight’s Market Access solution helps shippers
																					manage supply chain risks
																				</b>
																				<p className="text-black-50 mb-0">
																					In February, Uber Technologies Inc. (NYSE:UBER) released
																					its fourth-quarter earnings, showcasing its subsidiary,
																					Uber Freight, which experienced momentous 43% growth...{' '}
																				</p>
																				<small className="text-black-50 pt-1 d-block">
																					Created on: <b className="text-first">24 Feb 2021</b>
																				</small>
																			</div>
																		</div>
																	</div>
																</ListItem>
															</List>
														</PerfectScrollbar>
													</div>
												</div>
											)}
										</Grid>
										<Grid item lg={5} xl={6} className="d-flex">
											<div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
												<Card square={true} style={{ height: '100%' }}>
													<div className="card-badges">
														<div className="badge badge-pill badge-danger h-auto px-3 py-1">
															News
														</div>
													</div>
													<a
														href="#/"
														onClick={(e) => e.preventDefault()}
														className="image-title-overlay d-block h-280px"
														title="...">
														<img
															alt="..."
															className="card-img-top img-fit-container"
															src={require(`../../../../src/assets/images/stock-logos/banner/uber.jpg`)}
														/>
													</a>
													<div className="p-5">
														<div className="d-flex justify-content-between mb-4">
															<div>
																<Button className="btn-neutral-primary py-1 px-2 d-block">
																	<span className="d-block opacity-6">
																		Wednesday, March 10th 2021
																	</span>
																</Button>
															</div>
														</div>
														<p className="text-black font-size-xl">
															Uber Freight’s Market Access solution helps shippers manage supply
															chain risks
														</p>
														<p className="card-text font-size-lg">
															In February, Uber Technologies Inc. (NYSE:UBER) released its
															fourth-quarter earnings, showcasing its subsidiary, Uber Freight,
															which experienced momentous 43% growth in gross bookings, rising from
															$219 million in the fourth quarter last year to $313 million in the
															fourth quarter this year. In a prepared statement presented with the
															earnings report, Uber explained the improvement of bookings was
															related to the release of the Uber Freight Enterprise offering in
															September that attracted the growing number of shippers looking for
															technology to de-risk their supply chains. The company’s technology
															offering, combined with its API capabilities, gives shippers access to
															Uber Freight’s marketplace of more than 70,000 carriers that can be
															matched with their shipments using AI-powered pricing algorithms.
														</p>
													</div>
												</Card>
											</div>
										</Grid>
									</Grid>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
