import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import { green } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import ArrowForwardIosTwoToneIcon from '@material-ui/icons/ArrowForwardIosTwoTone';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import ReactQuill from 'react-quill';
import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';
import memory from '../../utils/memory';
import { transformDateToLocalTZ, checkValid } from '../../utils/recruitUtil';
import AutoConnectTemplate from '../../pages/Recruit/components/AutoConnectTemplate';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import DetailLoadingIndicator from '../../theme/talentDetail/detailLoadingIndicator';
import HeaderLoadingIndicator from '../../theme/talentDetail/headerLoadingIndicator';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { ActionType, EmailSequenceType, ActionLabel } from '../../utils/message';
import {
	ActionState,
	ActionOptionsType,
	ActionOptionType,
	ActionTypeValuesState,
	_ActionTypeValuesState,
	IRSEmailState
} from '../../types/common';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			'& > *': {
				margin: theme.spacing(1),
				width: theme.spacing(16),
				height: theme.spacing(16)
			}
		},
		root_cardheader: {
			flex: '0 0 auto',
			alignSelf: 'flex-start',
			marginRight: -8,
			height: 60
		},
		action_cardheader: {
			marginTop: -6,
			width: '50%'
		},
		pageAction: {
			color: '#808080',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		slashDegree: {
			transform: 'rotate(330deg)'
		},
		pageNumber: {
			color: '#808080',
			fontSize: '1.2rem',
			fontWeight: 'bold'
		},
		chips: {
			display: 'flex',
			justifyContent: 'start',
			flexWrap: 'wrap',
			'& > *': {
				margin: theme.spacing(0.5)
			}
		},
		chip: {
			fontSize: '0.875rem !important',
			color: '#808080',
			backgroundColor: '#E5E4E2',
			borderRadius: '0.29rem !important',
			padding: '5px 10px 5px 10px'
		},
		button: {
			color: '#4191ff',
			textAlign: 'right'
		},
		containedPrimary: {
			backgroundColor: '#0795fe',
			'&:hover': {
				backgroundColor: '#0795fe'
			},
			color: '#FFFFFF'
		},
		spinner: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		emailButton: {},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 180,
			maxWidth: '100%'
		},
		buttonProgress: {
			color: green[500],
			position: 'absolute'
		},
		alertColor: {
			backgroundColor: 'transparent'
		},
		positionColor: {
			color: '#046eb8'
		}
	})
);

const IMG = styled.img`
	width: 40px;
	height: auto;
	alt: icon;
	cursor: pointer;
	margin: 0.25rem !important;
`;

const IMG_ATTACH = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
	margin: 0.25rem !important;
`;

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

type TalentDialogType = {
	handleClose: () => void;
	selectedRow: any;
	activeActionTab: string;
	toggleActionTab: (prop: string) => void;
	templateOptions: any[];
	handleTemplate: (prop: any) => void;
	handleLetterSubject: (prop: any) => void;
	letterSubject: string | undefined;
	letter: any;
	handleLetterBody: (prop: string | undefined) => void;
	templateType: string | undefined;
	updateTemplateExpand: React.Dispatch<React.SetStateAction<boolean>>;
	isTemplateExpand: boolean;
	handleNameFieldInput: (prop: any) => void;
	templateName: string | undefined;
	isContextEmpty: boolean;
	handleSubmit: () => void;
	updateTemplateName: React.Dispatch<React.SetStateAction<string | undefined>>;
	updateTemplateConfirm: React.Dispatch<React.SetStateAction<boolean>>;
	updateTemplateDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
	isTemplateConfirm: boolean;
	templateNameMsg: string | undefined;
	isTemplateDuplicate: boolean;
	getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
	getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
	isDragAccept: boolean;
	isDragReject: boolean;
	isDragActive: boolean;
	fileRejectionItems: JSX.Element[];
	isLoading: boolean;
	files: any[];
	state: any;
	handleChange: (event: any) => void;
	templates: any;
	ref_initial: React.MutableRefObject<undefined>; //initial email
	strategyId: number; //start strategyId
	updateAutoDisabled_initial: React.Dispatch<React.SetStateAction<boolean>>; //send button disabled by initial
	action_firstEmail: boolean; //action for first email
	updateActionFirstEmail: React.Dispatch<React.SetStateAction<boolean>>; //action for first email
	action_firstEmail_time: any; //action for first email
	updateAction_firstEmail_time: React.Dispatch<React.SetStateAction<any>>; //action for first email
	error_action_firstEmail_time: boolean; //action for first email
	ref_action_firstEmail: React.MutableRefObject<undefined>; //action for first email
	timestamp_action_firstEmail: string | undefined; //action for first email
	updateAutoDisabled_action_firstEmail: React.Dispatch<React.SetStateAction<any>>; // control button first email disabled
	isExtraEmail: boolean; // extra email
	extraEmail_time: any; // extra email
	updateExtraEmail_time: React.Dispatch<React.SetStateAction<any>>; // extra email
	ref_extraEmail: React.MutableRefObject<undefined>; // extra email
	error_extraEmail_time: boolean; // extra email
	timestamp_extraEmail: string | undefined; // extra email
	updateAutoDisabled_extraEmail: React.Dispatch<React.SetStateAction<any>>; //control button extra email disabled
	updateExtraEmail: React.Dispatch<React.SetStateAction<boolean>>; // extra email
	action_lastEmail: boolean; //action for last email
	updateActionLastEmail: React.Dispatch<React.SetStateAction<boolean>>; //action for last email
	action_lastEmail_time: any; //action for last email
	updateAction_lastEmail_time: React.Dispatch<React.SetStateAction<any>>; //action for last email
	error_action_lastEmail_time: boolean; //action for last email
	updateAutoDisabled_last_email: React.Dispatch<React.SetStateAction<any>>; //control button last email disabled
	action_singleEmail: boolean; //action for single email
	updateActionSingleEmail: React.Dispatch<React.SetStateAction<boolean>>; //action for single email
	action_singleEmail_time: any; //action for single email
	updateAction_singleEmail_time: React.Dispatch<React.SetStateAction<any>>; //action for single email
	error_action_singleEmail_time: boolean; //action for single email
	updateAutoDisabled_single_email: React.Dispatch<React.SetStateAction<any>>; //control button single email disabled
	activeTab: number;
	folderSelection: any[];
	handleFolderChange: (event: any, values: any) => void;
	folders: any[];
	isOppSendDisabled: boolean;
	handleSendEmail: () => Promise<void>;
	isAutoDisabled: boolean;
	handleAutoConnect: () => Promise<void>;
	autoLoading: boolean;
	isFolderUpdate: boolean;
	handleUpdateProjects: () => Promise<void>;
	checkers: any;
	handleCheckTalent: (id: string | number, each: any) => void;
	handleUnCheckTalent: (id: string | number, each: any) => void;
	resultData: any[];
	ithRow: number;
	handleClickAction: (event: any, data: any, tabIndex: string, ith: number) => Promise<void>;
	toggleDrawer: (event: any, open: boolean) => void;
	autoConnectUploadFile?: any[];
	autoUploadFile?: { filename: any[]; path: any[] };
	tagId?: number;
	updateAction: React.Dispatch<React.SetStateAction<ActionState>>;
	action: ActionState;
	initialEmail?: IRSEmailState;
	followEmail?: IRSEmailState;
};

export default function TalentDialog(props: TalentDialogType) {
	const {
		handleClose,
		selectedRow,
		activeActionTab,
		toggleActionTab,
		templateOptions,
		handleTemplate,
		handleLetterSubject,
		letterSubject,
		letter,
		handleLetterBody,
		templateType,
		updateTemplateExpand,
		isTemplateExpand,
		handleNameFieldInput,
		templateName,
		isContextEmpty,
		handleSubmit,
		updateTemplateName,
		updateTemplateConfirm,
		updateTemplateDuplicate,
		isTemplateConfirm,
		templateNameMsg,
		isTemplateDuplicate,
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		isDragActive,
		fileRejectionItems,
		isLoading,
		files,
		state,
		handleChange,
		templates,
		ref_initial, //initial email
		strategyId, //start strategyId
		updateAutoDisabled_initial, //send button disabled by initial
		action_firstEmail, //action for first email
		action_firstEmail_time: unread_1_time, //action for first email
		updateAction_firstEmail_time: updateUnread_1_time, //action for first email
		error_action_firstEmail_time: error_unread_1_time, //action for first email
		ref_action_firstEmail: ref_unread_1, //action for first email
		timestamp_action_firstEmail: timestamp1, //action for first email
		updateAutoDisabled_action_firstEmail: updateAutoDisabled_unread_1, //send button disabled by action first email
		updateActionFirstEmail, //action for first email
		isExtraEmail, // extra email
		extraEmail_time: unread_2_time, // extra email
		updateExtraEmail_time: updateUnread_2_time, // extra email
		ref_extraEmail: ref_unread_2, // extra email
		error_extraEmail_time: error_unread_2_time, // extra email
		timestamp_extraEmail: timestamp2, // extra email
		updateAutoDisabled_extraEmail: updateAutoDisabled_unread_2, //send button disabed by extra email
		updateExtraEmail, // extra email
		action_lastEmail, //action for last email
		action_lastEmail_time: unread_last_email_time, //action for last email
		updateAction_lastEmail_time: updateUnread_last_email_time, //action for last email
		updateActionLastEmail, //action for last email,
		updateAutoDisabled_last_email, //action for last email,
		error_action_lastEmail_time: error_last_email_time, //action for last email
		action_singleEmail, //action for single email
		updateActionSingleEmail, //action for single email
		action_singleEmail_time, //action for single email
		updateAction_singleEmail_time, //action for single email
		error_action_singleEmail_time, //action for single email
		updateAutoDisabled_single_email, //control button single email disable
		activeTab,
		folderSelection,
		handleFolderChange,
		folders,
		isOppSendDisabled,
		handleSendEmail,
		isAutoDisabled,
		handleAutoConnect,
		autoLoading,
		isFolderUpdate,
		handleUpdateProjects,
		checkers,
		handleCheckTalent,
		handleUnCheckTalent,
		resultData,
		ithRow,
		handleClickAction,
		toggleDrawer,
		autoConnectUploadFile,
		autoUploadFile,
		tagId,
		updateAction,
		action,
		initialEmail,
		followEmail
	} = props;

	const classes = useStyles();

	const [collapseOpen, setCollapseOpen] = useState({
		honor: false,
		patent: false,
		publication: false
	});

	// const actionTypeMapping = {
	// 	[ActionType.NOREPLY]: 'Opened w/o reply',
	// 	[ActionType.UNREAD]: 'Unopened',
	// 	[ActionType.INITIAL]: 'Initial'
	// };
	// const actionTypeValueMapping = {
	// 	[EmailSequenceType.ACTION_FIRST_EMAIL]:
	// 		actionTypeMapping[action[EmailSequenceType.ACTION_FIRST_EMAIL].actionType],
	// 	[EmailSequenceType.ACTION_EXTRA_EMAIL]:
	// 		actionTypeMapping[action[EmailSequenceType.ACTION_EXTRA_EMAIL].actionType],
	// 	[EmailSequenceType.ACTION_LAST_EMAIL]:
	// 		actionTypeMapping[action[EmailSequenceType.ACTION_LAST_EMAIL].actionType],
	// 	[EmailSequenceType.ACTION_SINGLE_EMAIL]:
	// 		actionTypeMapping[action[EmailSequenceType.ACTION_SINGLE_EMAIL].actionType]
	// };
	const actionTypeOptions: ActionOptionsType = [
		{ type: ActionType.NOREPLY, value: 'Opened w/o reply' },
		{ type: ActionType.UNREAD, value: 'Unopened' }
	];
	const [actionTypeValues, setActionTypeValues] = useState<_ActionTypeValuesState>({
		[EmailSequenceType.ACTION_FIRST_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_EXTRA_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_LAST_EMAIL]: [actionTypeOptions[0]],
		[EmailSequenceType.ACTION_SINGLE_EMAIL]: [actionTypeOptions[0]]
	});
	// const actionTypeValues: ActionTypeValuesState = {
	// 	[EmailSequenceType.ACTION_FIRST_EMAIL]: {
	// 		type: action.ACTION_FIRST_EMAIL.actionType,
	// 		value: actionTypeValueMapping[EmailSequenceType.ACTION_FIRST_EMAIL]
	// 	},
	// 	[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
	// 		type: action.ACTION_EXTRA_EMAIL.actionType,
	// 		value: actionTypeValueMapping[EmailSequenceType.ACTION_EXTRA_EMAIL]
	// 	},
	// 	[EmailSequenceType.ACTION_LAST_EMAIL]: {
	// 		type: action.ACTION_LAST_EMAIL.actionType,
	// 		value: actionTypeValueMapping[EmailSequenceType.ACTION_LAST_EMAIL]
	// 	},
	// 	[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
	// 		type: action.ACTION_SINGLE_EMAIL.actionType,
	// 		value: actionTypeValueMapping[EmailSequenceType.ACTION_SINGLE_EMAIL]
	// 	}
	// };

	const [isFollowUpEmailActionValid, setFollowUpEmailActionDisabled] = useState(true);
	const [isExtraEmailActionValid, setExtraEmailActionDisabled] = useState(true);
	const [isLastEmailActionValid, setLastEmailActionDisabled] = useState(true);
	const [isSingleEmailActionValid, setSingleEmailActionDisabled] = useState(true);

	const handleFollowUpEmailAction = () => {
		setFollowUpEmailActionDisabled(!isFollowUpEmailActionValid);
		updateActionFirstEmail(!action_firstEmail);

		if (!action_firstEmail) {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_FIRST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_FIRST_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_FIRST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_FIRST_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleExtraEmailAction = () => {
		setExtraEmailActionDisabled(!isExtraEmailActionValid);
		updateExtraEmail(!isExtraEmail);

		if (!isExtraEmail) {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_EXTRA_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_EXTRA_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleLastEmailAction = () => {
		setLastEmailActionDisabled(!isLastEmailActionValid);
		updateActionLastEmail(!action_lastEmail);
		//updateAutoDisabled_last_email(action_lastEmail)

		if (!action_lastEmail) {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_LAST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_LAST_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_LAST_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_LAST_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleSingleEmailAction = () => {
		setSingleEmailActionDisabled(!isSingleEmailActionValid);
		updateActionSingleEmail(!action_singleEmail);

		if (!action_singleEmail) {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_SINGLE_EMAIL],
					[ActionLabel.ACTIVE]: true
				}
			}));
		} else {
			updateAction((prevState: ActionState) => ({
				...prevState,
				[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
					...prevState[EmailSequenceType.ACTION_SINGLE_EMAIL],
					[ActionLabel.ACTIVE]: false
				}
			}));
		}
	};

	const handleChangeActionType = (emailSequence: EmailSequenceType, values: ActionOptionsType) => {
		updateAction((prevState: ActionState) => ({
			...prevState,
			[emailSequence]: {
				...prevState[emailSequence],
				[ActionLabel.ACTION_TYPE]: values.map((each) => each.type)
			}
		}));
		setActionTypeValues((prevState: _ActionTypeValuesState) => ({
			...prevState,
			[emailSequence]: values
		}));
	};

	const { promiseInProgress } = usePromiseTracker();
	const [ithTalent, setIthTalent] = useState(ithRow);

	const clickLastTalent = () => {
		if (ithTalent - 1 < 0) return;

		for (let i = 0; i < resultData.length; i++) {
			if (selectedRow.id === resultData[i].id) {
				trackPromise(handleClickAction(null, resultData[i - 1], '0', i));
				setIthTalent((prevIth) => prevIth - 1);
				break;
			}
		}
	};

	const clickNextTalent = () => {
		if (ithTalent + 1 >= resultData.length) return;

		for (let i = 0; i < resultData.length; i++) {
			if (selectedRow.id === resultData[i].id) {
				trackPromise(handleClickAction(null, resultData[i + 1], '0', i));
				setIthTalent((prevIth) => prevIth + 1);
				break;
			}
		}
	};

	const renderStars = (num: number) => {
		let container = [];

		let idx = 0;
		for (let i = 0; i < num; i++) {
			container.push(<StarIcon key={idx++} />);
		}
		for (let i = 0; i < 3 - num; i++) {
			container.push(<StarOutlineIcon key={idx++} />);
		}

		return container.map((each) => each);
	};

	useEffect(() => {
		//setSelectedTalent(selectedRow);
	}, []);

	return (
		<Card className="h-100">
			<CardHeader
				classes={{ root: classes.root_cardheader, action: classes.action_cardheader }}
				action={
					<div className="d-flex align-items-center justify-content-end">
						<ArrowBackIosTwoToneIcon className={classes.pageAction} onClick={clickLastTalent} />
						{/* <FontAwesomeIcon icon={["fas","slash-forward"]} className={classes.colorAction}/>
						<FontAwesomeIcon icon={['fas', 'heart']} color={'red'} /> */}
						<div className="d-flex align-items-center justify-content-center">
							<div className={`${classes.pageNumber} mr-n2 ml-2`}>{ithTalent + 1}</div>
							<IMG
								className={classes.slashDegree}
								src={require('../../assets/images/apps/slash.svg')}
							/>
							<div className={`${classes.pageNumber} ml-n2 mr-2`}>{resultData.length}</div>
						</div>
						<ArrowForwardIosTwoToneIcon className={classes.pageAction} onClick={clickNextTalent} />
						<IMG
							src={require('../../assets/images/apps/close.png')}
							onClick={(e) => toggleDrawer(e, false)}
						/>
					</div>
				}
			/>
			<CardContent className="bg-gray-200 h-100">
				<Grid
					container
					direction="row"
					className="h-100"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}>
					<Grid
						item
						container
						xs={12}
						style={{ maxWidth: '100%', maxHeight: 100 }}
						direction="row"
						spacing={2}>
						<Grid item xs={7}>
							<Paper className="w-100 m-2 pr-3 pl-n3" elevation={0}>
								{promiseInProgress ? (
									<HeaderLoadingIndicator />
								) : (
									<Grid
										className="pt-4"
										container
										direction="row"
										justifyContent="center"
										alignItems="center">
										<Grid
											item
											container
											direction="row"
											justifyContent="space-around"
											alignItems="center"
											xs={3}>
											<Grid container item xs={12} justifyContent="center">
												<div className="font-weight-bold text-black">{selectedRow.name}</div>
											</Grid>
											<Grid xs={12} style={{ height: 10 }} />
											<Grid container item xs={12} justifyContent="center">
												<div className="text-nowrap">{selectedRow.seniority.substring(4)}</div>
											</Grid>
										</Grid>
										<Grid
											item
											xs={3}
											container
											direction="row"
											justifyContent="space-around"
											alignItems="center">
											<Grid container item xs={12} justifyContent="center">
												<div className="font-weight-bold text-black">Curr/Ex Company</div>
											</Grid>
											<Grid xs={12} style={{ height: 10 }} />
											<Grid container item xs={12} justifyContent="center">
												{[
													...new Set(
														selectedRow.companies
															.filter((comp: any) => comp.isCurrent === 1)
															.map((item: any) => item.company)
													)
												].map((company: any) => (
													<div className="text-nowrap">{company}</div>
												))}
											</Grid>
										</Grid>
										<Grid
											item
											xs={3}
											container
											direction="row"
											justifyContent="space-around"
											alignItems="center">
											<Grid container item xs={12} justifyContent="center">
												<div className="font-weight-bold text-black">C-1 DEPT</div>
											</Grid>
											<Grid xs={12} style={{ height: 10 }} />
											<Grid container item xs={12} justifyContent="center">
												<div className="text-nowrap">{selectedRow.department_1}</div>
											</Grid>
										</Grid>
										<Grid
											item
											xs={3}
											container
											direction="row"
											justifyContent="space-around"
											alignItems="center">
											<Grid container item xs={12} justifyContent="center">
												<div className="font-weight-bold text-black">C-2 DEPT</div>
											</Grid>
											<Grid xs={12} style={{ height: 10 }} />
											<Grid container item xs={12} justifyContent="center">
												<div className="text-nowrap">{selectedRow.department_2}</div>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Paper>
						</Grid>
						<Grid item xs={5}>
							<Paper className="h-100 w-100 m-2" elevation={0}>
								{promiseInProgress ? (
									<HeaderLoadingIndicator />
								) : (
									<Grid
										className="pt-4"
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center">
										<Grid item xs={7} container alignItems="center" justifyContent="space-around">
											{folderSelection && (
												<FormControl className={classes.formControl}>
													<Autocomplete
														style={{ zIndex: 1 }}
														limitTags={1}
														multiple
														size="small"
														options={folderSelection}
														getOptionLabel={(option) => option.name}
														onChange={handleFolderChange}
														value={folders}
														getOptionSelected={(option, value) => option.id === value.id}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="standard"
																label="Projects"
																fullWidth
															/>
														)}
													/>
												</FormControl>
											)}
										</Grid>
										<Grid item container xs={5} alignItems="center" justifyContent="space-around">
											{isFolderUpdate ? (
												<Button
													onClick={handleUpdateProjects}
													className={classes.containedPrimary}
													disabled={autoLoading}>
													{autoLoading && (
														<CircularProgress size={24} className={classes.buttonProgress} />
													)}
													Update Projects
												</Button>
											) : !checkers[selectedRow.id] || checkers[selectedRow.id] === undefined ? (
												<Button
													onClick={() => handleCheckTalent(selectedRow.id, selectedRow)}
													className={classes.containedPrimary}>
													Select
												</Button>
											) : (
												<Button
													onClick={() => handleUnCheckTalent(selectedRow.id, selectedRow)}
													className={classes.containedPrimary}>
													Deselect
												</Button>
											)}
										</Grid>
									</Grid>
								)}
							</Paper>
						</Grid>
					</Grid>
					<Grid
						item
						container
						xs={12}
						style={{ height: 56 }}
						direction="row"
						alignItems="center"
						justifyContent="center">
						<Grid item xs={7}>
							<List
								component="div"
								className="nav-line d-flex nav-tabs-primary ml-3 justify-content-start flex-row MuiGrid-grid-xs-5 mb-n5">
								<ListItem
									className="pl-3 pr-3"
									style={{ backgroundColor: activeActionTab === '0' ? '#ffffff' : 'transparent' }}
									button
									disableRipple={false}
									selected={activeActionTab === '0'}
									onClick={() => toggleActionTab('0')}>
									<ListItemText className="mb-n2 text-nowrap pr-2">Information</ListItemText>
								</ListItem>
								{selectedRow.email && (
									<>
										<ListItem
											className="pl-3 pr-3"
											style={{
												backgroundColor: activeActionTab === '1' ? '#ffffff' : 'transparent'
											}}
											button
											disableRipple={false}
											selected={activeActionTab === '1'}
											onClick={() => toggleActionTab('1')}>
											<ListItemText className="mb-n2 text-nowrap pr-1">Email</ListItemText>
										</ListItem>
										<ListItem
											className="pl-3 pr-3"
											style={{
												backgroundColor: activeActionTab === '2' ? '#ffffff' : 'transparent'
											}}
											button
											disableRipple={false}
											selected={activeActionTab === '2'}
											onClick={() => toggleActionTab('2')}>
											<ListItemText className="mb-n2 text-nowrap pr-2">AutoEmail</ListItemText>
										</ListItem>
										<ListItem
											className="pl-3 pr-3"
											style={{
												backgroundColor: activeActionTab === '3' ? '#ffffff' : 'transparent'
											}}
											button
											disableRipple={false}
											selected={activeActionTab === '3'}
											onClick={() => toggleActionTab('3')}>
											<ListItemText className="mb-n2 text-nowrap pr-2">History</ListItemText>
										</ListItem>
									</>
								)}
							</List>
						</Grid>
						<Grid item xs={5}>
							<div className="MuiGrid-grid-xs-5" />
						</Grid>
					</Grid>
					<Grid item container xs={12} style={{ maxWidth: '100%' }} direction="row" spacing={2}>
						<Grid item xs={7}>
							<Paper className="w-100 h-100" elevation={0}>
								{promiseInProgress ? (
									<DetailLoadingIndicator />
								) : (
									<>
										<div className={clsx('tab-item-wrapper', { active: activeActionTab === '0' })}>
											<Grid
												container
												style={{ maxHeight: 665, overflowY: 'auto' }}
												direction="row"
												className="w-100 p-4"
												justifyContent="flex-start"
												alignItems="center">
												<Grid item container xs={12} direction="column" justifyContent="flex-start">
													{checkValid(selectedRow.headline) && (
														<>
															<div className="font-weight-bold text-black font-size-lg">
																Headline
															</div>
															<div className="text-black font-weight-light font-size-sm mt-1 mb-2">
																<span style={{ whiteSpace: 'pre-line' }}>
																	{checkValid(selectedRow.headline) && <>{selectedRow.headline}</>}
																</span>
															</div>
															<div className="divider" />
														</>
													)}
													<div className="font-weight-bold text-black font-size-lg mt-2">
														Summary
													</div>
													{checkValid(selectedRow.summary) && (
														<>
															<div className="text-black font-weight-light font-size-sm mt-1 mb-1">
																<span style={{ whiteSpace: 'pre-line' }}>
																	{checkValid(selectedRow.summary) && <>{selectedRow.summary}</>}
																</span>
															</div>
															<div className="divider" />
														</>
													)}
													<div className="d-flex justify-content-start py-2">
														<div className="text-black font-weight-light font-size-sm">
															<span className="pr-1">Location:</span>
															<span>{selectedRow.location}</span>
														</div>
													</div>
													<div className="d-flex justify-content-start py-2">
														<div className="text-black font-weight-light font-size-sm">
															<span className="pr-1">Tech Stack:</span>
															<span>{selectedRow.stacks}</span>
														</div>
													</div>
													<div className="d-flex justify-content-start py-2">
														<div className="text-black font-weight-light font-size-sm">
															<span className="pr-1">Seniority:</span>
															<span>{selectedRow.seniority.substring(4)}</span>
														</div>
													</div>
													<div className="d-flex justify-content-start py-2">
														<div className="text-black font-weight-light font-size-sm">
															<span className="pr-1">Years of Experience:</span>
															<span>{selectedRow.yearsOfWorkingExperiences}</span>
															<span>&nbsp;yrs</span>
														</div>
													</div>
													<div className="d-flex justify-content-start py-2">
														<span className="pr-1">
															O2O Index
															<Tooltip
																title={
																	<>
																		<div>★★★: Actively look for new opportunities.</div>
																		<div>
																			★★: Considering new opportunities but not actively looking
																			for.
																		</div>
																		<div>★: Probably open to new opportunities. </div>
																		<div>☆: Probably not open to new opportunities. </div>
																	</>
																}
																arrow
																placement="top">
																<HelpOutlineIcon fontSize={'small'} />
															</Tooltip>
														</span>
														<span>{renderStars(selectedRow.openToOpportunities)}</span>
													</div>
													<div className="d-flex justify-content-start py-2">
														<div className="text-black font-weight-light font-size-sm">
															<span className="pr-1">Contact Info:</span>
															<span>
																<a target="_blank" href={`${selectedRow.linkedin}`}>
																	LinkedIn{selectedRow.email && <>, &nbsp;</>}
																</a>
																{selectedRow.email && (
																	<>
																		<RouteLink
																			target="_blank"
																			to={{
																				pathname: '/contactsearch',
																				search: `linkedin=${selectedRow.linkedin}`
																			}}>
																			Email, &nbsp;
																		</RouteLink>
																		<RouteLink
																			target="_blank"
																			to={{
																				pathname: '/contactsearch',
																				search: `linkedin=${selectedRow.linkedin}`
																			}}>
																			Phone
																		</RouteLink>
																	</>
																)}
															</span>
														</div>
													</div>
													<div className="divider" />
												</Grid>
												<Grid
													item
													container
													xs={12}
													direction="column"
													justifyContent="flex-start"
													className="pt-2">
													<div className="font-weight-bold text-black font-size-lg">Experience</div>
													{selectedRow.companies.length > 0 &&
														selectedRow.companies.map((comp: any) => {
															const duration = comp.period ? ', ' + comp.period + ' yrs' : '';
															return (
																<div className="d-flex justify-content-start flex-column pt-2 pb-2 pr-2">
																	<div className="text-black font-weight-light font-size-sm">
																		<span className={classes.positionColor}>
																			<span className="font-weight-bold">{comp.position}</span> at{' '}
																			<span className="font-weight-bold">{comp.company}</span>
																		</span>
																	</div>
																	<div className="text-black font-weight-light font-size-sm">
																		<span>
																			{comp.startDate && comp.endDate && (
																				<>
																					{comp.startDate + ' - ' + comp.endDate} {duration}{' '}
																				</>
																			)}
																		</span>
																	</div>
																	{checkValid(comp.description) && (
																		<div className="text-black font-weight-light font-size-sm mt-1">
																			<span style={{ whiteSpace: 'pre-line' }}>
																				{comp.description}
																			</span>
																		</div>
																	)}
																	{checkValid(comp.skills) && (
																		<div className="text-black font-weight-light font-size-sm mt-2">
																			<span className="font-weight-bold">Skills: </span>
																			<span>{comp.skills}</span>
																		</div>
																	)}
																</div>
															);
														})}
													<div className="divider" />
												</Grid>
												<Grid
													item
													container
													xs={12}
													direction="column"
													justifyContent="flex-start"
													className="pt-2">
													<div className="font-weight-bold text-black font-size-lg">
														Entrepreneurship
													</div>
													{selectedRow.entrepreneurship.length > 0 &&
														selectedRow.entrepreneurship.map((ent: any) => {
															return (
																<div className="d-flex justify-content-start flex-column pt-2 pb-2 pr-2">
																	<div className="text-black font-weight-light font-size-sm">
																		<span>
																			{ent.role} at {ent.company}{' '}
																			{ent.round && <>({ent.round.split('-')[1].trim()})</>}
																		</span>
																	</div>
																	<div className="text-black font-weight-light font-size-sm">
																		<span>
																			{ent.startDate} - {ent.endDate}
																		</span>
																	</div>
																</div>
															);
														})}
												</Grid>
											</Grid>
											<div style={{ height: 74.5 }} />
										</div>
										<div
											className={`${clsx('tab-item-wrapper', {
												active: activeActionTab === '1'
											})}`}>
											<div style={{ maxHeight: 665, overflowY: 'auto' }} className="p-4">
												<DialogContentText>
													<Autocomplete
														id="combo-box-demo"
														size="small"
														options={templateOptions}
														groupBy={(option) => option.type}
														getOptionLabel={(option) => option.name}
														filterOptions={(options, state) =>
															options.filter(
																(each) =>
																	each.irsType === 'autoemails_firstEmail_nonFirst' ||
																	each.irsType.length === 0
															)
														}
														onChange={handleTemplate}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																placeholder="Search for a template"
																fullWidth
															/>
														)}
													/>
												</DialogContentText>
												<DialogContentText>
													<TextField
														onChange={handleLetterSubject}
														value={letterSubject}
														variant="outlined"
														fullWidth
														name={'subject'}
														id="standard-textarea"
														label="Subject"
														placeholder="[Company] [Opportunity] [Date]"
													/>

													<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
													<ReactQuill
														className={'quillContainer'}
														theme="snow"
														value={letter.htmlBody}
														onChange={handleLetterBody}
														placeholder="Write about your opportunity..."
													/>
												</DialogContentText>
												<DialogContentText className={'d-flex justify-content-between'}>
													{templateType === 'Base' ? (
														<div className="font-size-xs text-dark">
															* If you use this template, please change the text in all square
															brackets before sending
														</div>
													) : (
														<div className="font-size-xs text-dark"></div>
													)}
													<Link
														className={classes.button}
														component="button"
														underline="none"
														onClick={() => {
															updateTemplateExpand((v: any) => !v);
														}}>
														Save as new template
													</Link>
												</DialogContentText>
												{isTemplateExpand && (
													<>
														<hr />
														<DialogContentText className="mb-0 textField-btn">
															<div style={{ flexGrow: 1 }}>
																<Grid container spacing={8}>
																	<Grid item xs={8}>
																		<TextField
																			className={'templatename-field'}
																			fullWidth
																			variant="outlined"
																			placeholder="Name of the template"
																			onChange={handleNameFieldInput}
																			value={templateName}
																		/>
																	</Grid>
																	<Grid item xs>
																		{!isContextEmpty ? (
																			<Button
																				className={classes.containedPrimary}
																				size="small"
																				onClick={handleSubmit}>
																				Save
																			</Button>
																		) : (
																			<Button size="small" variant="outlined" disabled>
																				Save
																			</Button>
																		)}
																		<Button
																			style={{ left: '10px' }}
																			size="small"
																			variant="outlined"
																			onClick={() => {
																				updateTemplateExpand(false);
																				updateTemplateName('');
																				updateTemplateConfirm(false);
																				updateTemplateDuplicate(false);
																			}}>
																			Cancel
																		</Button>
																	</Grid>
																</Grid>
															</div>
														</DialogContentText>
														{isTemplateConfirm && (
															<div className="font-size-sm text-success mt-3">
																Save template <strong>{templateNameMsg}</strong> successfully
															</div>
														)}
														{isTemplateDuplicate && (
															<div className="font-size-sm text-danger mt-3">
																There was an error saving your template. Please try another name.
															</div>
														)}
													</>
												)}
												<hr />
												<Grid container direction={'row'}>
													<Grid item md={12}>
														<div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
															<input {...getInputProps()} />
															<div className="dropzone">
																{isDragAccept && (
																	<div>
																		<IMG_ATTACH
																			src={require('../../assets/images/apps/attachment.png')}
																		/>
																		<div className="font-size-sm text-success">
																			All files will be uploaded!
																		</div>
																	</div>
																)}
																{isDragReject && (
																	<div>
																		<IMG_ATTACH
																			src={require('../../assets/images/apps/attachment.png')}
																		/>
																		<div className="font-size-sm text-danger">
																			Some files will be rejected!
																		</div>
																	</div>
																)}
																{!isDragActive && (
																	<IMG_ATTACH
																		src={require('../../assets/images/apps/attachment.png')}
																	/>
																)}
																<div style={{ width: '2px' }} />
																<div className="font-weight-bold text-uppercase text-dark font-size-sm">
																	Uploaded Files
																</div>
																<div style={{ width: '2px' }} />
																<div className="font-size-xs text-dark">
																	* A file name cannot contain commas
																</div>
															</div>
														</div>
													</Grid>
													<Grid item md={12}>
														<div>
															{fileRejectionItems}
															{isLoading && files.length == 0 ? (
																<div className={classes.spinner}>
																	<CircularProgress />
																</div>
															) : (
																<List component="div" className="font-size-sm">
																	{files}
																</List>
															)}
														</div>
													</Grid>
												</Grid>
												<hr />
												<div className={'font-size-sm mt-4'}>
													<div>{memory.user.name}</div>
													<div>
														{memory.user.position} | {memory.user.customername}
													</div>
													<div>{memory.user.email}</div>
													<div>{memory.user.phone}</div>
													<div>{memory.user.location}</div>
													<a href={`${memory.user.linkedin}`}>LinkedIn</a>
												</div>
												<hr />
												{tagId && typeof tagId !== 'undefined' && (
													<>
														<div className="d-flex flex-row justify-content-between">
															<div style={{ width: '90%' }}>
																<b>Actions for email</b>
															</div>
															<div style={{ width: '10%' }}>
																<div className="d-flex flex-row justify-content-end">
																	{!isSingleEmailActionValid && (
																		<Link
																			component="button"
																			underline="none"
																			onClick={handleSingleEmailAction}>
																			<AddIcon />
																		</Link>
																	)}
																	{isSingleEmailActionValid && (
																		<Link
																			component="button"
																			underline="none"
																			onClick={handleSingleEmailAction}>
																			<RemoveIcon />
																		</Link>
																	)}
																</div>
															</div>
														</div>
														{action_singleEmail && (
															<>
																<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
																	<div>
																		<Autocomplete
																			size="small"
																			multiple
																			options={actionTypeOptions}
																			getOptionLabel={(option: ActionOptionType) => option.value}
																			style={{ width: 220 }}
																			value={
																				actionTypeValues[EmailSequenceType.ACTION_SINGLE_EMAIL]
																			}
																			getOptionSelected={(option, value) =>
																				option.type === value.type
																			}
																			onChange={(
																				e: React.ChangeEvent<{}>,
																				values: ActionOptionsType
																			) =>
																				handleChangeActionType(
																					EmailSequenceType.ACTION_SINGLE_EMAIL,
																					values
																				)
																			}
																			renderInput={(params) => (
																				<TextField {...params} variant="outlined" />
																			)}
																		/>
																	</div>
																	<div className="ml-1 mr-1">in</div>
																	<div>
																		<input
																			style={{ width: '30px' }}
																			value={action_singleEmail_time.hour}
																			onChange={(e) => {
																				e.persist();
																				updateAction_singleEmail_time((prevState: any) => ({
																					...prevState,
																					hour: e.target.value
																				}));
																			}}
																		/>
																	</div>
																	<div className="ml-1 mr-1">hours </div>
																	<div>
																		<input
																			style={{ width: '30px' }}
																			value={action_singleEmail_time.min}
																			onChange={(e) => {
																				e.persist();
																				updateAction_singleEmail_time((prevState: any) => ({
																					...prevState,
																					min: e.target.value
																				}));
																			}}
																		/>
																	</div>
																	<div>minutes,</div>
																</div>
																<div>then a lark notification will be sent for AutoNotes</div>
																{error_action_singleEmail_time && (
																	<>
																		<div className={'d-flex'}>
																			<div className="font-size-sm text-danger font-italic">
																				X Error:&nbsp;
																			</div>
																			<div className="font-size-sm font-italic">
																				Can not be blank and can not be less than 24
																			</div>
																		</div>
																		<div className={'d-flex mb-2'}>
																			<div className="font-size-sm text-danger font-italic">
																				X Error:&nbsp;
																			</div>
																			<div className="font-size-sm font-italic">
																				Can not be blank and can not be a number outside 00 - 59
																			</div>
																		</div>
																	</>
																)}
															</>
														)}
													</>
												)}
												{state.approach === 'true' && (
													<DialogContentText className="mb-0">
														<hr />
														<Alert className="mb-4">
															<div className="d-flex align-items-center align-content-start">
																<span>
																	Our recruiters will reach out to candidates via all approaches
																	(Email, Phone, LinkedIn and social medias) and notify you about
																	their status.
																</span>
															</div>
															<div>Credits Left: {memory.user.credits}</div>
														</Alert>
														<FormGroup row>
															<FormControlLabel
																control={<Checkbox checked name="email" />}
																label="Email"
															/>
															<FormControlLabel
																control={<Checkbox checked name="inmail" />}
																label="InMail"
															/>
															<FormControlLabel
																disabled={!selectedRow.twitter}
																control={<Checkbox checked name="twitter" />}
																label="Twitter"
															/>
															<FormControlLabel
																disabled={!selectedRow.github}
																control={<Checkbox checked name="github" />}
																label="Github"
															/>
															<FormControlLabel
																disabled={selectedRow.phone === '0'}
																control={<Checkbox checked name="text" />}
																label="Text"
															/>
															<FormControlLabel
																disabled={selectedRow.phone === '0'}
																control={<Checkbox checked name="call" />}
																label="Call"
															/>
															<FormControlLabel
																disabled={!selectedRow.facebook}
																control={<Checkbox checked name="facebook" />}
																label="Facebook"
															/>
															<FormControlLabel
																disabled={!selectedRow.instagram}
																control={<Checkbox checked name="instagram" />}
																label="Instagram"
															/>
														</FormGroup>
													</DialogContentText>
												)}
											</div>
											<DialogActions className="p-3">
												<Button onClick={handleClose} color="primary" variant="outlined">
													Cancel
												</Button>
												<Button
													variant="contained"
													disabled={isOppSendDisabled}
													onClick={handleSendEmail}
													className={classes.containedPrimary}>
													Send
												</Button>
											</DialogActions>
										</div>
										<div className={clsx('tab-item-wrapper', { active: activeActionTab === '2' })}>
											<div style={{ maxHeight: 665, overflowY: 'auto' }} className="p-4">
												<AutoConnectTemplate
													templateOptions={templateOptions}
													templates={templates}
													classes={classes}
													emailType={EmailSequenceType.EMAIL_INITIAL}
													ref={ref_initial}
													candidateEmailMapping={letter.candidateEmailMapping}
													interval={undefined}
													scheduler={undefined}
													tagId={tagId}
													uploadFiles={autoConnectUploadFile}
													uploadFileTags={autoUploadFile}
													strategyId={strategyId}
													sequenceId={1}
													handleSendDisabled={updateAutoDisabled_initial}
													defaultEmail={initialEmail}
												/>
												<hr />
												<div className="d-flex flex-row justify-content-between">
													<div style={{ width: '90%' }}>
														<b>Actions for the first email</b>
													</div>
													<div style={{ width: '10%' }}>
														<div className="d-flex flex-row justify-content-end">
															{!isFollowUpEmailActionValid && (
																<Link
																	component="button"
																	underline="none"
																	onClick={handleFollowUpEmailAction}>
																	<AddIcon />
																</Link>
															)}
															{isFollowUpEmailActionValid && (
																<Link
																	component="button"
																	underline="none"
																	onClick={handleFollowUpEmailAction}>
																	<RemoveIcon />
																</Link>
															)}
														</div>
													</div>
												</div>
												{action_firstEmail && (
													<>
														<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
															<div>
																<Autocomplete
																	size="small"
																	multiple
																	options={actionTypeOptions}
																	getOptionLabel={(option: ActionOptionType) => option.value}
																	style={{ width: 220 }}
																	value={actionTypeValues[EmailSequenceType.ACTION_FIRST_EMAIL]}
																	getOptionSelected={(option, value) => option.type === value.type}
																	onChange={(e: React.ChangeEvent<{}>, values: ActionOptionsType) =>
																		handleChangeActionType(
																			EmailSequenceType.ACTION_FIRST_EMAIL,
																			values
																		)
																	}
																	renderInput={(params) => (
																		<TextField {...params} variant="outlined" />
																	)}
																/>
															</div>
															<div className="ml-1 mr-1">in</div>
															<div>
																<input
																	style={{ width: '30px' }}
																	value={unread_1_time.hour}
																	onChange={(e) => {
																		e.persist();
																		updateUnread_1_time((prevState: any) => ({
																			...prevState,
																			hour: e.target.value
																		}));
																	}}
																/>
															</div>
															<div className="ml-1 mr-1">hours </div>
															<div>
																<input
																	style={{ width: '30px' }}
																	value={unread_1_time.min}
																	onChange={(e) => {
																		e.persist();
																		updateUnread_1_time((prevState: any) => ({
																			...prevState,
																			min: e.target.value
																		}));
																	}}
																/>
															</div>
															<div className="ml-1">minutes, then send the next email</div>
														</div>
														{error_unread_1_time && (
															<>
																<div className={'d-flex'}>
																	<div className="font-size-sm text-danger font-italic">
																		X Error:&nbsp;
																	</div>
																	<div className="font-size-sm font-italic">
																		Can not be blank and can not be less than 24
																	</div>
																</div>
																<div className={'d-flex mb-2'}>
																	<div className="font-size-sm text-danger font-italic">
																		X Error:&nbsp;
																	</div>
																	<div className="font-size-sm font-italic">
																		Can not be blank and can not be a number outside 00 - 59
																	</div>
																</div>
															</>
														)}
														<AutoConnectTemplate
															templateOptions={templateOptions}
															templates={templates}
															classes={classes}
															emailType={EmailSequenceType.ACTION_FIRST_EMAIL}
															ref={ref_unread_1}
															candidateEmailMapping={letter.candidateEmailMapping}
															tagId={tagId}
															uploadFiles={autoConnectUploadFile}
															uploadFileTags={autoUploadFile}
															strategyId={strategyId}
															sequenceId={2}
															interval={timestamp1}
															scheduler={unread_1_time}
															handleSendDisabled={updateAutoDisabled_unread_1}
															defaultEmail={followEmail}
														/>
													</>
												)}
												<hr />
												<div className="d-flex flex-row justify-content-between">
													<div style={{ width: '90%' }}>
														<b>Actions for the second email (optional)</b>
													</div>
													<div style={{ width: '10%' }}>
														<div className="d-flex flex-row justify-content-end">
															{!isExtraEmailActionValid && (
																<Link
																	component="button"
																	underline="none"
																	onClick={handleExtraEmailAction}>
																	<AddIcon />
																</Link>
															)}
															{isExtraEmailActionValid && (
																<Link
																	component="button"
																	underline="none"
																	onClick={handleExtraEmailAction}>
																	<RemoveIcon />
																</Link>
															)}
														</div>
													</div>
												</div>
												{isExtraEmail && (
													<>
														<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
															<div>
																<Autocomplete
																	size="small"
																	multiple
																	options={actionTypeOptions}
																	getOptionLabel={(option: ActionOptionType) => option.value}
																	style={{ width: 220 }}
																	value={actionTypeValues[EmailSequenceType.ACTION_EXTRA_EMAIL]}
																	getOptionSelected={(option, value) => option.type === value.type}
																	onChange={(e: React.ChangeEvent<{}>, values: ActionOptionsType) =>
																		handleChangeActionType(
																			EmailSequenceType.ACTION_EXTRA_EMAIL,
																			values
																		)
																	}
																	renderInput={(params) => (
																		<TextField {...params} variant="outlined" />
																	)}
																/>
															</div>
															<div className="ml-1 mr-1">in</div>
															<div>
																<input
																	style={{ width: '30px' }}
																	value={unread_2_time.hour}
																	onChange={(e) => {
																		e.persist();
																		updateUnread_2_time((prevState: any) => ({
																			...prevState,
																			hour: e.target.value
																		}));
																	}}
																/>
															</div>
															<div className="ml-1 mr-1">hours </div>
															<div>
																<input
																	style={{ width: '30px' }}
																	value={unread_2_time.min}
																	onChange={(e) => {
																		e.persist();
																		updateUnread_2_time((prevState: any) => ({
																			...prevState,
																			min: e.target.value
																		}));
																	}}
																/>
															</div>
															<div className="ml-1">minutes, then send the next email</div>
														</div>
														{error_unread_2_time && (
															<>
																<div className={'d-flex'}>
																	<div className="font-size-sm text-danger font-italic">
																		X Error:&nbsp;
																	</div>
																	<div className="font-size-sm font-italic">
																		Can not be blank and can not be less than 24
																	</div>
																</div>
																<div className={'d-flex mb-2'}>
																	<div className="font-size-sm text-danger font-italic">
																		X Error:&nbsp;
																	</div>
																	<div className="font-size-sm font-italic">
																		Can not be blank and can not be a number outside 00 - 59
																	</div>
																</div>
															</>
														)}
														<AutoConnectTemplate
															templateOptions={templateOptions}
															templates={templates}
															classes={classes}
															emailType={EmailSequenceType.ACTION_EXTRA_EMAIL}
															ref={ref_unread_2}
															candidateEmailMapping={letter.candidateEmailMapping}
															tagId={tagId}
															uploadFiles={autoConnectUploadFile}
															uploadFileTags={autoUploadFile}
															strategyId={strategyId}
															sequenceId={3}
															interval={timestamp2}
															scheduler={unread_2_time}
															handleSendDisabled={updateAutoDisabled_unread_2}
															defaultEmail={undefined}
														/>
													</>
												)}
												{tagId && typeof tagId !== 'undefined' && (
													<>
														<hr />
														<div className="d-flex flex-row justify-content-between">
															<div style={{ width: '90%' }}>
																<b>Actions for the last email</b>
															</div>
															<div style={{ width: '10%' }}>
																<div className="d-flex flex-row justify-content-end">
																	{/* {!isLastEmailActionValid && (
																		<Link
																			component="button"
																			underline="none"
																			onClick={handleLastEmailAction}>
																			<AddIcon />
																		</Link>
																	)}
																	{isLastEmailActionValid && (
																		<Link
																			component="button"
																			underline="none"
																			onClick={handleLastEmailAction}>
																			<RemoveIcon />
																		</Link>
																	)} */}
																</div>
															</div>
														</div>
														{action_lastEmail && (
															<>
																<div className="mb-1 d-flex flex-row justify-content-start align-items-center">
																	<div>
																		<Autocomplete
																			size="small"
																			multiple
																			options={actionTypeOptions}
																			getOptionLabel={(option: ActionOptionType) => option.value}
																			style={{ width: 220 }}
																			value={actionTypeValues[EmailSequenceType.ACTION_LAST_EMAIL]}
																			getOptionSelected={(option, value) =>
																				option.type === value.type
																			}
																			onChange={(
																				e: React.ChangeEvent<{}>,
																				values: ActionOptionsType
																			) =>
																				handleChangeActionType(
																					EmailSequenceType.ACTION_LAST_EMAIL,
																					values
																				)
																			}
																			renderInput={(params) => (
																				<TextField {...params} variant="outlined" />
																			)}
																		/>
																	</div>
																	<div className="ml-1 mr-1">in</div>
																	<div>
																		<input
																			style={{ width: '30px' }}
																			value={unread_last_email_time.hour}
																			onChange={(e) => {
																				e.persist();
																				updateUnread_last_email_time((prevState: any) => ({
																					...prevState,
																					hour: e.target.value
																				}));
																			}}
																		/>
																	</div>
																	<div className="ml-1 mr-1">hours </div>
																	<div>
																		<input
																			style={{ width: '30px' }}
																			value={unread_last_email_time.min}
																			onChange={(e) => {
																				e.persist();
																				updateUnread_last_email_time((prevState: any) => ({
																					...prevState,
																					min: e.target.value
																				}));
																			}}
																		/>
																	</div>
																	<div>minutes,</div>
																</div>
																<div>then a lark notification will be sent for AutoNotes</div>
																{error_last_email_time && (
																	<>
																		<div className={'d-flex'}>
																			<div className="font-size-sm text-danger font-italic">
																				X Error:&nbsp;
																			</div>
																			<div className="font-size-sm font-italic">
																				Can not be blank and can not be less than 24
																			</div>
																		</div>
																		<div className={'d-flex mb-2'}>
																			<div className="font-size-sm text-danger font-italic">
																				X Error:&nbsp;
																			</div>
																			<div className="font-size-sm font-italic">
																				Can not be blank and can not be a number outside 00 - 59
																			</div>
																		</div>
																	</>
																)}
															</>
														)}
													</>
												)}
											</div>
											<DialogActions className="p-3">
												<Button onClick={handleClose} color="primary" variant="outlined">
													Cancel
												</Button>
												<Button
													variant="contained"
													onClick={handleAutoConnect}
													className={classes.containedPrimary}
													disabled={autoLoading || isAutoDisabled}>
													{autoLoading && (
														<CircularProgress size={24} className={classes.buttonProgress} />
													)}
													Send
												</Button>
											</DialogActions>
										</div>
										<div className={clsx('tab-item-wrapper', { active: activeActionTab === '3' })}>
											<div style={{ height: 665, overflowY: 'auto' }} className="p-4">
												{selectedRow &&
													(activeTab === 0 || activeTab === 2) &&
													selectedRow.history.length === 0 && (
														<div className="text-center my-5">
															<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
																<FontAwesomeIcon
																	icon={['far', 'user']}
																	className="d-flex align-self-center font-size-xxl"
																/>
															</div>
															<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
																History
															</h6>
															<p className="text-black-50 font-size-lg mb-0">
																You have not offered a job opportunity to this candidate.
															</p>
														</div>
													)}
												{selectedRow &&
													(activeTab === 0 || activeTab === 2) &&
													selectedRow.history.length > 0 &&
													selectedRow.history.map((each: any, i: number) => (
														<Alert
															classes={{ standardSuccess: classes.alertColor }}
															className="mb-4 MuiAlert-standardSuccess"
															variant="standard"
															color="success">
															<div
																key={i}
																className="d-flex align-items-center align-content-start">
																<span>
																	<strong className="d-block">
																		{transformDateToLocalTZ(each.sent_time)}
																	</strong>
																	{each.bounce === 0 && each.open === 1 && (
																		<p>
																			The candidate has checked your email already.
																			<DoneAllIcon fontSize={'small'} color={'primary'} />
																		</p>
																	)}
																	{each.bounce === 0 && each.open === 0 && each.sent === 1 && (
																		<p>
																			This email has been sent to the candidate successfully.
																			<DoneIcon fontSize={'small'} color={'primary'} />
																		</p>
																	)}
																	{each.bounce === 0 && each.open === 0 && each.sent === 0 && (
																		<p>
																			This email will be sent to the candidate.
																			<TIMERIMG
																				src={require('../../assets/images/apps/timer.png')}
																			/>
																		</p>
																	)}
																	{each.bounce === 1 && each.open === 0 && each.sent === 1 && (
																		<p>
																			This email will be sent to the candidate.
																			<TIMERIMG
																				src={require('../../assets/images/apps/timer.png')}
																			/>
																		</p>
																	)}
																</span>
															</div>
														</Alert>
													))}
												{selectedRow && activeTab === 3 && selectedRow.talentStatus.length === 0 && (
													<div className="text-center my-5">
														<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
															<FontAwesomeIcon
																icon={['far', 'user']}
																className="d-flex align-self-center font-size-xxl"
															/>
														</div>
														<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
															History
														</h6>
														<p className="text-black-50 font-size-lg mb-0">
															The application is in progress
														</p>
													</div>
												)}
												{selectedRow &&
													activeTab === 3 &&
													selectedRow.talentStatus.length > 0 &&
													selectedRow.talentStatus.reverse().map((each: any, i: number) => {
														return each.status === '1' ? (
															<div className="text-center my-5">
																<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
																	<FontAwesomeIcon
																		icon={['far', 'user']}
																		className="d-flex align-self-center font-size-xxl"
																	/>
																</div>
																<h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
																	History
																</h6>
																<p className="text-black-50 font-size-lg mb-0">
																	Candidate has not responded yet.
																</p>
															</div>
														) : (
															<Alert className="alerts-alternate mb-4">
																<div
																	key={i}
																	className="d-flex align-items-center align-content-start">
																	<span>
																		<strong className="d-block">
																			{moment(each.createdAt).format('dddd, MMMM Do YYYY, h:mm a')}
																		</strong>
																		{each.status === '3' && (
																			<p>
																				This candidate is <b>interested</b> in the opportunity.
																			</p>
																		)}
																		{each.status === '2' && (
																			<p>
																				This candidate is <b>not interested</b> in the opportunity.
																			</p>
																		)}
																	</span>
																</div>
															</Alert>
														);
													})}
											</div>
										</div>
									</>
								)}
								<div style={{ height: 74.5 }} />
							</Paper>
						</Grid>
						<Grid item xs={5}>
							<Paper className="h-100 w-100" elevation={0}>
								{promiseInProgress ? (
									<DetailLoadingIndicator />
								) : (
									<>
										<Grid
											container
											style={{ maxHeight: 665, overflowY: 'auto' }}
											direction="row"
											className="w-100 p-4"
											justifyContent="flex-start"
											alignItems="center">
											<Grid item container xs={12} direction="column" justifyContent="flex-start">
												<div className="font-weight-bold text-black font-size-lg">Education</div>
												{selectedRow.institutions.length > 0 &&
													selectedRow.institutions.map((ins: any) => {
														return (
															<div className="d-flex justify-content-start flex-column pt-2 pb-2 pr-2">
																<div className="text-black font-weight-light font-size-sm">
																	<span>
																		{ins.degree}'s Degree {ins.specialty && ' in ' + ins.specialty}{' '}
																		at {ins.institution}
																	</span>
																</div>
																{ins.startYear && ins.endYear && (
																	<div className="text-black font-weight-light font-size-sm">
																		<span>
																			{ins.startYear}-{ins.endYear}
																		</span>
																	</div>
																)}
															</div>
														);
													})}
												<div className="divider" />
											</Grid>
											<Grid
												item
												container
												xs={12}
												direction="column"
												justifyContent="flex-start"
												className="pt-2">
												<div className="font-weight-bold text-black font-size-lg mb-1">Skills</div>
												<div className={classes.chips}>
													{selectedRow.skills.length > 0 &&
														selectedRow.skills.map((skill: any) => {
															return <span className={classes.chip}>{skill}</span>;
														})}
												</div>
												<div className="divider mt-1" />
											</Grid>
											<Grid
												item
												container
												xs={12}
												direction="column"
												justifyContent="flex-start"
												className="pt-2">
												<div className="font-weight-bold text-black font-size-lg mb-1">
													Language(s)
												</div>
												<div className={classes.chips}>
													{selectedRow.languages.length > 0 &&
														selectedRow.languages.map((lang: any) => {
															return <span className={classes.chip}>{lang.language}</span>;
														})}
												</div>
												<div className="divider mt-1" />
											</Grid>
											<Grid
												item
												container
												xs={12}
												direction="column"
												justifyContent="flex-start"
												className="pt-2">
												<div className="font-weight-bold text-black font-size-lg mb-1">
													Achievement(s)
												</div>
												<div className="d-flex justify-content-start flex-row">
													<IconButton
														size="small"
														onClick={() =>
															setCollapseOpen({ ...collapseOpen, honor: !collapseOpen.honor })
														}>
														{collapseOpen.honor ? (
															<KeyboardArrowDownIcon />
														) : (
															<KeyboardArrowRightIcon />
														)}
													</IconButton>
													<div className="text-black font-weight-bold font-size-sm center title">
														Honors ({selectedRow.honors.length})
													</div>
												</div>
												<Collapse in={collapseOpen.honor} timeout="auto" unmountOnExit>
													{selectedRow.honors.length > 0 &&
														selectedRow.honors.map((honor: any) => {
															return (
																<div className="pb-2 pl-4 text-black font-weight-light font-size-sm">
																	<div>Title:{honor.title}</div>
																	<div>Issuer:{honor.issuer}</div>
																	<div>IssueDate:{honor.issueDate}</div>
																	<div>
																		Description:
																		{honor.description && honor.description.length > 150
																			? honor.description.slice(0, 150) + '...'
																			: honor.description}
																	</div>
																</div>
															);
														})}
												</Collapse>
												<div className="d-flex justify-content-start flex-row">
													<IconButton
														size="small"
														onClick={() =>
															setCollapseOpen({ ...collapseOpen, patent: !collapseOpen.patent })
														}>
														{collapseOpen.patent ? (
															<KeyboardArrowDownIcon />
														) : (
															<KeyboardArrowRightIcon />
														)}
													</IconButton>
													<div className="text-black font-weight-bold font-size-sm center title">
														Patents ({selectedRow.patents.length})
													</div>
												</div>
												<Collapse in={collapseOpen.patent} timeout="auto" unmountOnExit>
													{selectedRow.patents.length > 0 &&
														selectedRow.patents.map((patent: any) => {
															return (
																<div className="pb-2 pl-4 text-black font-weight-light font-size-sm">
																	<div>Title:{patent.title}</div>
																	<div>Issuer:{patent.patentNo}</div>
																	<div>IssueDate:{patent.issueDate}</div>
																	<div>
																		Description:
																		{patent.description && patent.description.length > 150
																			? patent.description.slice(0, 150) + '...'
																			: patent.description}
																	</div>
																</div>
															);
														})}
												</Collapse>
												<div className="d-flex justify-content-start flex-row">
													<IconButton
														size="small"
														onClick={() =>
															setCollapseOpen({
																...collapseOpen,
																publication: !collapseOpen.publication
															})
														}>
														{collapseOpen.publication ? (
															<KeyboardArrowDownIcon />
														) : (
															<KeyboardArrowRightIcon />
														)}
													</IconButton>
													<div className="text-black font-weight-bold font-size-sm center title">
														Publications ({selectedRow.publications.length})
													</div>
												</div>
												<Collapse in={collapseOpen.publication} timeout="auto" unmountOnExit>
													{selectedRow.publications.length > 0 &&
														selectedRow.publications.map((publication: any) => {
															return (
																<div className="pb-2 pl-4 text-black font-weight-light font-size-sm">
																	<div>Title:{publication.title}</div>
																	<div>Publisher:{publication.publisher}</div>
																	<div>PublicDate:{publication.publicationDate}</div>
																	<div>
																		Description:
																		{publication.description && publication.description.length > 150
																			? publication.description.slice(0, 150) + '...'
																			: publication.description}
																	</div>
																</div>
															);
														})}
												</Collapse>
											</Grid>
										</Grid>
									</>
								)}
							</Paper>
						</Grid>
					</Grid>
					<Grid item xs={12} />
				</Grid>
			</CardContent>
		</Card>
	);
}
