import React, { useEffect, useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/lab/Alert';
import ReactQuill from 'react-quill';
import TextField from '@material-ui/core/TextField';
import { post, get, update, del } from '../../utils/request';
import url from '../../utils/url';
import storage from '../../utils/storage';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';
import Slide from '@material-ui/core/Slide';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { removeHtmlTag } from '../../utils/emailTemplateUtil';
import memory from '../../utils/memory';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	label: {
		fontWeight: 'bold'
	},
	root: {
		backgroundColor: '#fff'
	},
	input: {
		height: '1em'
	},
	containedPrimary: {
		backgroundColor: '#4191ff'
	}
});

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

export default function EmailTemplate() {
	const classes = useStyles();

	const [resultData, setResultData] = useState([]);
	const [mainData, setMainData] = useState([]);
	const [firstLoadResultData, setFirstLoadResultData] = useState([]);
	const [open, setOpen] = useState(false);
	const [viewOpen, setViewOpen] = useState(false);
	const [isContextEmpty, setContextEmpty] = useState(true);
	const [page, setPage] = useState(1);
	const [templateNames, setTemplateNames] = useState([]);
	const [snackOpen, setSnackOpen] = useState(false);
	const [action, setAction] = useState('');
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	//Template
	const [templateId, setTemplateId] = useState('');
	const [letterSubject, setLetterSubject] = useState('');
	const [htmlBody, setHtmlBody] = useState('');
	const [templateName, setTemplateName] = useState('');
	const [type, setType] = useState('');
	const [isTemplateDuplicate, setTemplateDuplicate] = useState(false);
	const recordPerPage = 10;

	const handleChangePage = (e, value) => {
		setPage(value);
		console.log('page: ', value, 'resultData: ', resultData);
		setMainData(resultData.slice(value * recordPerPage - recordPerPage, value * recordPerPage));
	};

	const handleNameFieldInput = (e) => {
		if (e.target.value.length > 0) {
			setTemplateName(e.target.value);
		} else {
			setTemplateName('');
		}

		const filterHtmlBody = removeHtmlTag(htmlBody);

		if (letterSubject.length > 0 && filterHtmlBody.length > 0 && e.target.value.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	const handleLetterSubject = (e) => {
		if (e.target.value.length > 0) {
			setLetterSubject(e.target.value);
		} else {
			setLetterSubject('');
		}

		const filterHtmlBody = removeHtmlTag(htmlBody);

		if (templateName.length > 0 && filterHtmlBody.length > 0 && e.target.value.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	const handleLetterBody = (html, delta, source, editor) => {
		const filterHtmlBody = removeHtmlTag(html);

		if (filterHtmlBody.length > 0) {
			setHtmlBody(html);
		} else {
			setHtmlBody('');
		}

		if (letterSubject.length > 0 && templateName.length > 0 && filterHtmlBody.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	// const handleSubmit = () => {
	// 	if (templateNames.includes(templateName)) {
	// 		//setSnackOpen(true);
	// 		setTemplateDuplicate(true);
	// 	} else {
	// 		//setSnackOpen(false);

	// 		if (action === 'create') {
	// 			post(url.addEmailTemplate(), {
	// 				subject: letterSubject,
	// 				htmlBody: htmlBody,
	// 				name: templateName
	// 			}).then((res) => {
	// 				console.log('result data', res.data);
	// 				handleDialogClose();
	// 				loadTemplatesData();
	// 			});
	// 		} else if (action === 'edit') {
	// 			update(url.updateEmailTemplate(templateId), {
	// 				subject: letterSubject,
	// 				htmlBody: htmlBody,
	// 				name: templateName
	// 			}).then(() => {
	// 				handleDialogClose();
	// 				loadTemplatesData();
	// 			});
	// 		}
	// 	}
	// };
	const handleSubmit = () => {
		if (templateNames.includes(templateName)) {
			setTemplateDuplicate(true);
		} else {
			const updateTemplatesDataAndPagination = () => {
				get(url.fetchEmailTemplate()).then((res) => {
					const updatedTemplates = res.data;
					setResultData(updatedTemplates);

					// 计算新模板应该在的页码
					const newPage =
						action === 'create' ? Math.ceil(updatedTemplates.length / recordPerPage) : page;
					setPage(newPage);

					// 更新当前页面的数据
					const newMainData = updatedTemplates.slice(
						(newPage - 1) * recordPerPage,
						newPage * recordPerPage
					);
					setMainData(newMainData);
				});
			};

			if (action === 'create') {
				post(url.addEmailTemplate(), {
					subject: letterSubject,
					htmlBody: htmlBody,
					name: templateName
				}).then(() => {
					handleDialogClose();
					updateTemplatesDataAndPagination();
				});
			} else if (action === 'edit') {
				update(url.updateEmailTemplate(templateId), {
					subject: letterSubject,
					htmlBody: htmlBody,
					name: templateName
				}).then(() => {
					handleDialogClose();
					updateTemplatesDataAndPagination();
				});
			}
		}
	};

	// const handleDelete = () => {
	// 	console.log('Delete id', templateId, 'Type', type);
	// 	del(url.deleteTemplate(templateId), { type: type })
	// 		.then((res) => {
	// 			console.log(`delete template ${templateName} successfully`, res);
	// 			loadTemplatesData();
	// 			handleDialogClose();
	// 			setDeleteOpen(false);
	// 		})
	// 		.catch((error) => {
	// 			console.log(`delete template ${templateName} fails!`, error);
	// 		});
	// };

	const handleDelete = () => {
		console.log('Delete id', templateId, 'Type', type);
		del(url.deleteTemplate(templateId), { type: type })
			.then((res) => {
				console.log(`delete template ${templateName} successfully`, res);

				// reload template data
				const updatedTemplates = resultData.filter(
					(template) => template.templateId !== templateId
				);
				setResultData(updatedTemplates);

				// refresh current page data
				let newPage = page;
				const totalPages = Math.ceil(updatedTemplates.length / recordPerPage);
				if (page > totalPages) {
					newPage = totalPages; // if excceed current page number, adjust total page number
				}
				setPage(newPage);
				const newMainData = updatedTemplates.slice(
					newPage * recordPerPage - recordPerPage,
					newPage * recordPerPage
				);
				setMainData(newMainData);

				handleDialogClose();
				setDeleteOpen(false);
			})
			.catch((error) => {
				console.log(`delete template ${templateName} fails!`, error);
			});
	};

	const loadTemplatesData = () => {
		get(url.fetchEmailTemplate()).then((res) => {
			console.log(res.data);
			setResultData(res.data);
			setFirstLoadResultData(res.data);
			setMainData(res.data.slice(0, recordPerPage));
			setPage(1);

			const templates = [];
			res.data.forEach((item) => {
				templates.push(item.name);
			});
			setTemplateNames(templates);
		});
	};

	//TODO refresh load data
	const refreshTemplateData = (action, data) => {
		switch (action) {
			case 'create':
				resultData.push(data);
				setResultData(data);

				break;
			case 'edit':
				break;
			case 'delete':
				break;
		}
	};

	const handleDialogClose = () => {
		setTemplateId(null);
		setLetterSubject('');
		setHtmlBody('');
		setTemplateName('');
		setType('');
		setOpen(false);
		setViewOpen(false);
		setTemplateDuplicate(false);
	};

	// const handleDuplicate = (template) => {
	// 	let duplicateTemplateName = '';
	// 	const templateNamesMap = storage.getEmailTemplate();

	// 	if (templateNamesMap[template.name]) {
	// 		const index = templateNamesMap[template.name].lastIndexOf('_');
	// 		const basename = templateNamesMap[template.name].substring(0, index);
	// 		const numberString = parseInt(templateNamesMap[template.name].substring(index + 1)) + 1;
	// 		duplicateTemplateName = basename + '_' + numberString;
	// 	} else {
	// 		duplicateTemplateName = template.name + '_2';
	// 	}

	// 	post(url.addEmailTemplate(), {
	// 		subject: template.subject,
	// 		htmlBody: template.htmlBody,
	// 		name: duplicateTemplateName
	// 	}).then(() => {
	// 		templateNamesMap[template.name] = duplicateTemplateName;
	// 		storage.saveEmailTemplate(templateNamesMap);
	// 		loadTemplatesData();
	// 	});
	// };
	const handleDuplicate = (template) => {
		let duplicateTemplateName = '';
		const templateNamesMap = storage.getEmailTemplate();

		if (templateNamesMap[template.name]) {
			const index = templateNamesMap[template.name].lastIndexOf('_');
			const basename = templateNamesMap[template.name].substring(0, index);
			const numberString = parseInt(templateNamesMap[template.name].substring(index + 1)) + 1;
			duplicateTemplateName = basename + '_' + numberString;
		} else {
			duplicateTemplateName = template.name + '_2';
		}

		post(url.addEmailTemplate(), {
			subject: template.subject,
			htmlBody: template.htmlBody,
			name: duplicateTemplateName
		}).then(() => {
			get(url.fetchEmailTemplate()).then((res) => {
				const updatedTemplates = res.data;
				setResultData(updatedTemplates);

				// 计算新模板应该在的页码
				const newPage = Math.ceil(updatedTemplates.length / recordPerPage);
				setPage(newPage);

				// 更新当前页面的数据
				const newMainData = updatedTemplates.slice(
					(newPage - 1) * recordPerPage,
					newPage * recordPerPage
				);
				setMainData(newMainData);
			});
		});
	};

	const handleEditTemplate = (template) => {
		setLetterSubject(template.subject);
		setTemplateName(template.name);
		setHtmlBody(template.htmlBody);
		setTemplateId(template.templateId);
		setOpen(true);
		setContextEmpty(false);
		setAction('edit');
	};

	const handleDeleteTemplate = (template) => {
		setLetterSubject(template.subject);
		setTemplateName(template.name);
		setHtmlBody(template.htmlBody);
		setTemplateId(template.templateId);
		setType(template.type);
		setDeleteOpen(true);
	};

	const handleViewTemplate = (template) => {
		setLetterSubject(template.subject);
		setTemplateName(template.name);
		setHtmlBody(template.htmlBody);
		setViewOpen(true);
	};

	const searchHandler = (event) => {
		const searchResult = [];
		if (event.target.value.length > 0) {
			for (const [key, value] of Object.entries(firstLoadResultData)) {
				const name = value.name ? value.name.toLowerCase() : '';
				const subject = value.subject ? value.subject.toLowerCase() : '';
				const type = value.type ? value.type.toLowerCase() : '';

				if (
					name.includes(event.target.value.toLowerCase()) ||
					subject.includes(event.target.value.toLowerCase()) ||
					type.includes(event.target.value.toLowerCase())
				) {
					searchResult.push(value);
				}
			}
		}

		if (searchResult.length > 0) {
			console.log('search result: ', searchResult);
			setResultData(searchResult);
			setMainData(searchResult.slice(0, recordPerPage));
		} else {
			if (event.target.value.length === 0) {
				setResultData(firstLoadResultData);
				setMainData(firstLoadResultData.slice(0, recordPerPage));
			} else {
				setResultData([]);
				setMainData([]);
			}
		}
	};

	useEffect(() => {
		console.log('templates loading.....');
		loadTemplatesData();
	}, []);
	const reactQuillRef = useRef(); // Initialize
	const handleCompanyButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{company}');
		quill.setSelection(position + '{company}'.length);
	};
	const handleTitleButtonClick = () => {
		const quill = reactQuillRef.current.getEditor();
		const range = quill.getSelection();
		let position = range ? range.index : 0;
		quill.insertText(position, '{title}');
		quill.setSelection(position + '{title}'.length);
	};
	return (
		<>
			<Card className="card-box mb-spacing-6-x2 w-80 left-10">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>Email Template</b>
					</div>
					<div className="card-header--actions">
						<Button
							className={classes.label}
							variant="outlined"
							color="primary"
							onClick={() => {
								setAction('create');
								setOpen(true);
							}}>
							Create new template
						</Button>
					</div>
				</div>
				<div
					className={clsx('search-wrapper search-wrapper--grow text-right pr-2 mb-1', {
						'is-active': false
					})}>
					<TextField
						variant="outlined"
						margin="dense"
						onChange={(e) => searchHandler(e)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchTwoToneIcon style={{ color: '#0795fe' }} />
								</InputAdornment>
							),
							type: 'search'
						}}
					/>
				</div>
				<Card className="shadow-xxl p-3">
					<CardContent className="p-0">
						<div className="table-responsive-sm">
							<Table
								size={'small'}
								className="text-wrap mb-0 table-hover table-spacing font-size-sm">
								<thead className="thead">
									<tr>
										<th style={{ width: '20%' }} className={'text-left'}>
											Name
										</th>
										<th className={'text-left'} style={{ width: '30%' }}>
											Subject
										</th>
										<th className={'text-left'}>Type</th>
										<th style={{ width: '15%' }}></th>
										<th style={{ width: '15%' }}></th>
										<th style={{ width: '15%' }}></th>
									</tr>
								</thead>
								<tbody>
									{mainData &&
										mainData.map((item, index) => {
											return (
												<tr key={index}>
													<td
														style={{ fontWeight: 'bold', cursor: 'pointer' }}
														onClick={() => handleViewTemplate(item)}>
														{item.name}
													</td>
													<td>{item.subject}</td>
													<td>{item.type}</td>
													<td className={'text-center'}>
														{item.type === 'Private' && (
															<IMG
																src={require('../../assets/images/apps/Edit.png')}
																onClick={() => handleEditTemplate(item)}
															/>
														)}
													</td>
													<td className={'text-center'}>
														<IMG
															src={require('../../assets/images/apps/Duplicate.png')}
															onClick={() => handleDuplicate(item)}
														/>
													</td>
													<td className={'text-center'}>
														<IMG
															src={require('../../assets/images/apps/Delete.png')}
															onClick={() => handleDeleteTemplate(item)}
														/>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</div>
						<div className="divider" />
						<div className="divider" />
						<div className="p-3 d-flex justify-content-center">
							<Pagination
								page={page}
								onChange={handleChangePage}
								className="pagination-first"
								size="small"
								count={resultData && Math.ceil(Object.keys(resultData).length / recordPerPage)}
							/>
						</div>
					</CardContent>
				</Card>
			</Card>
			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				maxWidth={'md'}
				fullWidth
				open={open}
				onClose={handleDialogClose}
				aria-labelledby="form-dialog-title">
				<DialogContent className={'p-4'}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card className="shadow-xxl p-3">
								<DialogContentText>
									<TextField
										onChange={handleLetterSubject}
										value={letterSubject}
										variant="outlined"
										fullWidth
										name={'subject'}
										id="standard-textarea"
										label="Subject"
										placeholder="[Company] [Opportunity] [Date]"
									/>

									<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
									<ReactQuill
										className={'quillContainer'}
										theme="snow"
										value={htmlBody}
										onChange={handleLetterBody}
										placeholder="Compose your email template..."
										ref={reactQuillRef}
									/>
								</DialogContentText>
								<DialogContentText className={'d-flex'}>
									<div className={'ml-auto'}>
										<span style={{ verticalAlign: 'middle', marginRight: '6px' }}>Insert</span>

										<div style={{ display: 'inline-block', marginRight: '0px' }}>
											<Button
												size="small"
												style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
												onClick={handleCompanyButtonClick}>{`{company}`}</Button>
										</div>

										<div
											style={{
												borderLeft: '1px solid #000',
												height: '15px',
												width: '1.5px',
												display: 'inline-block',
												verticalAlign: 'middle',
												margin: '0 5px'
											}}
										/>

										<div style={{ display: 'inline-block', marginLeft: '0px' }}>
											<Button
												size="small"
												style={{ minWidth: '0', padding: '8px 0', color: 'rgb(96, 144, 251)' }}
												onClick={handleTitleButtonClick}>
												{'{title}'}
											</Button>
										</div>
									</div>
								</DialogContentText>
								{/* <DialogContentText className={'d-flex'}>
									<div className={'ml-auto'}>
										<Button onClick={handleCompanyButtonClick}>Insert {`{company}`}</Button>
										<span style={{ margin: '0 10px', borderLeft: '1px solid #000', height: '20px' }} />
										<Button onClick={handleTitleButtonClick}>Insert {'{title}'}</Button>
									</div>
								</DialogContentText> */}
								<hr />
								<div className={'font-size-sm mt-4'}>
									<div>{memory.user.name}</div>
									<div>
										{memory.user.position} | {memory.user.customername}
									</div>
									<div>{memory.user.email}</div>
									<div>{memory.user.phone}</div>
									<div>{memory.user.location}</div>
									<a href={`${memory.user.linkedin}`}>LinkedIn</a>
								</div>
								<hr />
								<DialogContentText className="mb-0 textField-btn">
									<div style={{ flexGrow: 1 }}>
										<Grid container spacing={7}>
											<Grid item xs={8}>
												<TextField
													className={'templatename-field'}
													fullWidth
													variant="outlined"
													placeholder="Name of the template"
													onChange={handleNameFieldInput}
													value={templateName}
												/>
											</Grid>
											<Grid item xs>
												{!isContextEmpty ? (
													<Button
														className={classes.containedPrimary}
														size="small"
														variant="contained"
														color="primary"
														onClick={handleSubmit}>
														{action === 'create' ? 'Save' : 'Update'}
													</Button>
												) : (
													<Button size="small" variant="outlined" disabled>
														{action === 'create' ? 'Save' : 'Update'}
													</Button>
												)}
												<Button
													style={{ left: '10px' }}
													size="small"
													color="primary"
													variant="outlined"
													onClick={handleDialogClose}>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</div>
								</DialogContentText>
								{isTemplateDuplicate && (
									<div className="font-size-sm text-danger mt-3">
										There was an error saving your template. Please try another name.
									</div>
								)}
							</Card>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				maxWidth={'md'}
				fullWidth
				open={viewOpen}
				onClose={handleDialogClose}
				aria-labelledby="form-dialog-title">
				<DialogContent className={'p-4'}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card className="shadow-xxl p-3">
								<DialogContentText>
									<TextField
										disabled="true"
										value={letterSubject}
										variant="outlined"
										fullWidth
										label="Subject"
									/>

									<div className={'font-size-sm mt-4 mb-4'}>Hi {'{firstName}'},</div>
									<ReactQuill
										readOnly="true"
										className={'ql-view-container'}
										theme="snow"
										value={htmlBody}
										placeholder="Compose your email template..."
									/>
								</DialogContentText>

								<hr />
								<div className={'font-size-sm mt-4'}>
									<div>{memory.user.name}</div>
									<div>
										{memory.user.position} | {memory.user.customername}
									</div>
									<div>{memory.user.email}</div>
									<div>{memory.user.phone}</div>
									<div>{memory.user.location}</div>
									<a href={`${memory.user.linkedin}`}>LinkedIn</a>
								</div>
								<hr />
								<DialogContentText className="mb-0 textField-btn">
									<div style={{ flexGrow: 1 }}>
										<Grid container>
											<Grid item xs>
												<TextField
													disabled="true"
													className={'templatename-field'}
													fullWidth
													variant="outlined"
													value={templateName}
													label="Name of the template"
												/>
											</Grid>
										</Grid>
									</div>
								</DialogContentText>
							</Card>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Snackbar
				open={snackOpen}
				autoHideDuration={3000}
				onClose={() => {
					setSnackOpen(false);
				}}>
				<Alert
					onClose={() => {
						setSnackOpen(false);
					}}
					severity="error">
					There was an error saving your template. Please try another name.
				</Alert>
			</Snackbar>
			<Dialog
				open={isDeleteOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setDeleteOpen(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogTitle id="alert-dialog-slide-title">{'Delete template'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete this email template?
					</DialogContentText>
					<DialogContentText id="alert-dialog-slide-description">
						<strong>{templateName}</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.containedPrimary}
						onClick={handleDelete}
						color="primary"
						variant="contained">
						Delete
					</Button>
					<Button onClick={() => setDeleteOpen(false)} color="primary" variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
