import getBaseUrl from './getBaseUrl';

const url = getBaseUrl();
export default {
	login: () => `${url}/api/auth/login`,
	updateUsageActivity: () => `${url}/api/auth/updateUsageActivity`,
	getPermissionByUser: () => `${url}/api/recruit/permissionByUser`,
	getProjectsList: () => `${url}/api/recruit/projects`,
	postProject: () => `${url}/api/recruit/project`,
	deleteProject: (projectId) => `${url}/api/recruit/projects/${projectId}`,
	assignProjects: () => `${url}/api/recruit/projectAssignment`,
	cancelAssignProjects: () => `${url}/api/recruit/projectAssignmentCanceller`,
	getOrgsList: () => `${url}/api/Dashboard`,
	followCompany: () => `${url}/api/Dashboard`,
	getNotification: () => `${url}/api/Dashboard/notification`,
	getArticlesList: (orgId) => `${url}/api/orgArticles/${orgId}`,
	getOverview: (orgId) => `${url}/api/orgOverview/${orgId}`,
	getOrgChart: (orgId) => `${url}/api/orgCharts/${orgId}`,
	getCustOrgChart: (orgId) => `${url}/api/custOrgCharts/${orgId}`,
	getRecruitAvailable: () => `${url}/api/recruit/available`,
	getRecruitProcessing: () => '',
	getRecruitConnected: () => `${url}/api/recruit/connected`,
	generateGetUrl: () => `${url}/api/recruit/generateGetUrl`,
	generatePutUrl: () => `${url}/api/recruit/generatePutUrl`,
	uploadAttachment: () => `${url}/api/recruit/uploadAttachment`,
	postFollowDepartment: () => `${url}/api/orgChart/department`,
	postUnfollowDepartment: () => `${url}/api/orgChart/unfollowDepartment`,
	sendEmail: () => `${url}/api/recruit/email`,
	handleAutoConnect: () => `${url}/api/recruit/autoConnect`,
	fetchMaxStrategyByUser: () => `${url}/api/recruit/maxStrategyByUser`,
	fetchEmailHistory: () => `${url}/api/recruit/emailHistory`,
	fetchEmailHistoryProject: () => `${url}/api/recruit/emailHistoryProject`,
	fetchEmailHistoryProjectDetail: () => `${url}/api/recruit/emailHistoryProjectDetail`,
	fetchStartMonth: () => `${url}/api/recruit/fetchStartMonth`,
	searchContact: () => `${url}/api/recruit/candidateInfo`,
	fetchContactInfo: () => `${url}/api/recruit/contactInfo`,
	deleteCandidates: () => `${url}/api/recruit/candidates`,

	/************************** Admin *******************************/
	getAdminUsers: () => `${url}/api/admin/users`,
	postAdminUser: () => `${url}/api/admin/user`,
	getAdminCustomers: () => `${url}/api/admin/customers`,
	deleteAdminUser: () => `${url}/api/admin/user`,
	sendInvitationEmail: () => `${url}/api/admin/email`,
	updateAdminUser: (userId) => `${url}/api/admin/user/${userId}`,
	getAdminPermissions: () => `${url}/api/admin/permissions`,
	getAdminCompanies: () => `${url}/api/admin/companies`,
	postAdminCustomer: () => `${url}/api/admin/customer`,
	updateAdminCustomer: (customerId) => `${url}/api/admin/customer/${customerId}`,
	getAdminDashboardByCustomer: (customerId) => `${url}/api/admin/dashboard/${customerId}`,
	fetchEmailLog: () => `${url}/api/admin/emailLog`,
	fetchUserEmails: () => `${url}/api/admin/useremails`,

	/************************** Email Template *******************************/
	fetchEmailTemplate: () => `${url}/api/recruit/emailTemplates`,
	addEmailTemplate: () => `${url}/api/recruit/emailTemplate`,
	updateEmailTemplate: (templateId) => `${url}/api/recruit/emailTemplate/${templateId}`,
	deleteTemplate: (templateId) => `${url}/api/recruit/emailTemplate/${templateId}`,

	/************************** Recruit *******************************/
	recruitAvailable: () => `${url}/api/recruit/recruitAvailable`,
	fetchFilters: () => `${url}/api/recruit/filters`,
	fetchSkills: () => `${url}/api/recruit/skills`,
	saveNewProject: () => `${url}/api/recruit/saveNewProject`,
	saveExistingProjects: () => `${url}/api/recruit/saveExistingProjects`,
	duplicateProjects: () => `${url}/api/recruit/duplicateProjects`,
	deleteProjects: () => `${url}/api/recruit/projects`,
	fetchInstitutes: () => `${url}/api/recruit/institutes`,
	fetchProjectTalentPools: () => `${url}/api/recruit/projectTalentPools`,
	fetchFiltersByProject: (tagId) => `${url}/api/recruit/filtersByProject/${tagId}`,
	fetchTalentsSelectedByProject: (tagId) => `${url}/api/recruit/talentsSelectedByProject/${tagId}`,
	fetchRecommendationsForProjectEdit: () => `${url}/api/recruit/recommendationsForProjectEdit`,
	updateTalentsToCurrentProject: () => `${url}/api/recruit/updateTalentsToCurrentProject`,
	fetchTalentsByProject: () => `${url}/api/recruit/talentsByProject`,
	fetchRecommendationsForProjectView: (tagId) =>
		`${url}/api/recruit/recommendationsForProjectView/${tagId}`,
	refreshRecommendationsForProjectView: (tagId) =>
		`${url}/api/recruit/refreshRecommendationsForProjectView/${tagId}`,
	addTalentToTalentPools: () => `${url}/api/recruit/addTalentToTalentPools`,
	setTalentGoodFit: () => `${url}/api/recruit/setTalentGoodFit`,
	setTalentBadFit: () => `${url}/api/recruit/setTalentBadFit`,
	fetchTalentDetail: () => `${url}/api/recruit/fetchTalentDetail`,
	deleteProjectTab: () => `${url}/api/recruit/deleteProjectTab`,
	addNewTagPool: () => `${url}/api/recruit/addNewTagPool`,
	updateNewTagPool: () => `${url}/api/recruit/updateNewTagPool`,
	addProjectsInRecommendation: () => `${url}/api/recruit/addProjectsInRecommendation`,
	setProjectArchiveStatus: () => `${url}/api/recruit/setProjectArchiveStatus`,
	setProjectTopStatus: () => `${url}/api/recruit/setProjectTopStatus`,
	addAllTalentsToTalentPools: () => `${url}/api/recruit/addTalentsToTalentPools`,
	checkRecommendationStandard: (tagId) => `${url}/api/recruit/checkRecommendationStandard/${tagId}`,
	fetchCompDeptMapping: () => `${url}/api/recruit/fetchCompDeptMapping`,
	fetchSkllMapping: () => `${url}/api/recruit/fetchSkillMapping`,
	fetchPositionTypeStackMapping: () => `${url}/api/recruit/fetchPositionTypeStackMapping`,

	/************************** Recruit Test *******************************/
	deleteJDFile: () => `${url}/api/recruit/jdfile`,
	parsingJDandUploadFile: () => `${url}/api/recruit/uploadAvailableSearchJD`,
	getProjectsListPage: () => `${url}/api/recruit/projectsList`,
	fetchJDFile: () => `${url}/api/recruit/fetchJD`,
	uploadProjectViewJD: () => `${url}/api/recruit/uploadProjectViewJD`,
	copyJDFileToAttachment: () => `${url}/api/recruit/copyJDFileToAttachment`,
	fetchCredentialAccounts: () => `${url}/api/recruit/accounts`,
	validateAccount: () => `${url}/api/recruit/validateAccount`,
	createCredentialAccount: () => `${url}/api/recruit/createCredentialAccount`,
	deleteCredentialAccounts: () => `${url}/api/recruit/deleteCredentialAccounts`,
	loginLinkedin: () => `${url}/api/recruit/loginLinkedin`,
	sendAutoNote: () => `${url}/api/recruit/sendAutoNote`,
	fetchAutoNoteHistory: () => `${url}/api/recruit/autoNoteHistory`,
	fetchNoteTalentsList: () => `${url}/api/recruit/noteTalentsList`,
	fetchDeptFilters: () => `${url}/api/recruit/fetchDeptFilters`,
	verify2FA: () => `${url}/api/recruit/verify2FA`,
	withdrawAutoNote: () => `${url}/api/recruit/withdrawAutoNote`,
	fetchAccount: (accountId) => `${url}/api/recruit/fetchAccount/${accountId}`,
	fetchTalentsNumberWithdrawProcessing: () =>
		`${url}/api/recruit/fetchTalentsNumberWithdrawProcessing`,
	fetchTalentsNumberAutoNoteProcessing: () =>
		`${url}/api/recruit/fetchTalentsNumberAutoNoteProcessing`,
	checkTalentExist: () => `${url}/api/recruit/checkTalentExist`,
	fetchCompDeptOneMapping: () => `${url}/api/recruit/fetchCompDeptOneMapping`,
	sendTraceReportEmail: () => `${url}/api/recruit/sendTraceReportEmail`,
	fetchNumberOfWindows: () => `${url}/api/recruit/fetchNumberOfWindows`,
	fetchDepartmentsLevelOneTwoMapping: () => `${url}/api/recruit/fetchDepartmentsLevelOneTwoMapping`,
	uploadReportFile: () => `${url}/api/recruit/uploadReportFile`,
	deleteReportFile: () => `${url}/api/recruit/deleteReportFile`,

	saveExistingProject_test: () => `${url}/api/recruit/saveExistingProject_test`,
	fetchPresignedURL: () => `${url}/api/recruit/presigned`,
	testQueryTalents: () => `${url}/api/recruit/testQueryTalents`,

	/************************* Notification **********************************/

	toggleSwitch: () => `${url}/api/notification/toggleSwitch`,
	fetchNotifications: () => `${url}/api/notification/fetchNotifications`,
	fetchSwitchSetting: () => `${url}/api/notification/fetchSwitchSetting`,
	deleteNotifications: () => `${url}/api/notification/deleteNotifications`,
	updateReadStatus: () => `${url}/api/notification/updateReadStatus`,
	fetchNewTalents: () => `${url}/api/notification/fetchNewTalents`,

	/************************* Header **********************************/
	fetchNotificationCount: () => `${url}/api/header/fetchNotificationCount`,

	/************************* Extension **********************************/
	fetchEmailHistoryProjectExtension: () => `${url}/api/ext/emailHistoryProjectExtension`,
	fetchEmailHistoryProjectDetailExtension: () =>
		`${url}/api/ext/emailHistoryProjectDetailExtension`,
	fetchLinkedinProfile: () => `${url}/api/ext/fetchLinkedinProfile`,

	/************************** Admin Test *******************************/
	testCornParser: () => `${url}/api/admin/testcornparser`,
	testNodeCorn: () => `${url}/api/admin/testnodecron`,

	/************************** Project View *****************************/
	duplicateCheckTalents: () => `${url}/api/recruit/duplicateCheckTalents`,
	duplicateAutoCheck: () => `${url}/api/recruit/duplicateAutoCheck`,
	fetchTalentsDuplicateStatus: () => `${url}/api/recruit/fetchTalentsDuplicateStatus`,
	deleteProjectCandidates: () => `${url}/api/recruit/deleteProjectCandidates`,
	fetchPositionEmailTemplate: () => `${url}/api/recruit/fetchPositionEmailTemplate`,

	/************************** Main Search ******************************/
	fetchPositionList: () => `${url}/api/recruit/fetchPositionList`,
	checkDupTag: () => `${url}/api/recruit/checkDupTags`,

	/************************** Linkedin Credential *********************/
	fetchLinkedinAccounts: () => `${url}/api/recruit/fetchLinkedinAccounts`,
	fetchAutoNoteQuotaBalance: () => `${url}/api/recruit/fetchAutoNoteQuotaBalance`
};
