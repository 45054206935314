import store from 'store';
const USER_KEY = 'user_key';
const CUSTOMER_KEY = 'customer_key';
const EMAILTEMPLATE_KEY = 'emailTemplateKey';

export default {
	saveUser(user) {
		return store.set(USER_KEY, user);
	},

	getUser() {
		return store.get(USER_KEY) || {};
	},

	removeUser() {
		return store.remove(USER_KEY);
	},

	///////////////////////////// Template ///////////////////////
	saveEmailTemplate(templateNamesMap) {
		store.set(EMAILTEMPLATE_KEY, templateNamesMap);
	},

	initialEmailTemplate() {
		store.set(EMAILTEMPLATE_KEY, {});
	},

	getEmailTemplate() {
		return store.get(EMAILTEMPLATE_KEY);
	},

	removeEmailTemplate() {
		store.remove(EMAILTEMPLATE_KEY);
	},

	////////////////////////// Premium ///////////////////
	saveCustomer(customer) {
		return store.set(CUSTOMER_KEY, customer);
	},

	getCustomer() {
		return store.get(CUSTOMER_KEY) || {};
	},

	////////////////// SkillMapping //////////////////////
	saveSkillMapping(specialCharacter, label) {
		return store.set(specialCharacter, label);
	},

	getSkillMapping(specialCharacter) {
		return store.get(specialCharacter);
	}
};
