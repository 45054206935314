import React, { useEffect, useState } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import client from '../../assets/images/Audrey.jpeg';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import { NavLink } from 'react-router-dom';
import memory from '../../utils/memory';
import storage from '../../utils/storage';
import sessionstorage from 'sessionstorage';
import { useHistory } from 'react-router';
import item from '../../utils/sessionStorageUtil';

const HeaderUserbox = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [user, setUser] = useState({});
	const history = useHistory();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		storage.removeUser();
		storage.removeEmailTemplate();
		sessionstorage.removeItem(item.EMAIL_INITIAL);
		sessionstorage.removeItem(item.FOLDER_SELECTION);
		sessionstorage.removeItem(item.TOTAL_SIZE);
		sessionstorage.removeItem(item.EMAIL_UNREAD_1);
		sessionstorage.removeItem(item.EMAIL_UNREAD_2);
		memory.user = {};
		props.cookies.remove('token');
		history.push('/login');
	};

	useEffect(() => {
		setUser(memory.user);
	}, []);

	return (
		<>
			<div className="user-box position-relative mr-2">
				<Button onClick={handleClick} className="btn-link p-0 text-left d-flex align-items-center">
					<div className="d-block p-0 avatar-icon-wrapper">
						<div className="badge badge-success badge-circle p-top-a">Online</div>
						<div className="avatar-icon rounded-sm">
							{user.profileImage ? (
								<img
									src={`https://ocinsights.s3-us-west-1.amazonaws.com/pictures/avatars/${user.profileImage}`}
									alt="..."
								/>
							) : (
								<img
									src={`https://ocinsights.s3-us-west-1.amazonaws.com/pictures/avatars/defaultProfile.png`}
									alt="..."
								/>
							)}
						</div>
					</div>
					<div className="d-none d-xl-block pl-2">
						<span className="text-text">
							<small>{user.customername}</small>
						</span>
						<div className="text-text">{user.name}</div>
					</div>
					<span className="pl-1 pl-xl-3">
						<FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
					</span>
				</Button>
				<Menu
					anchorEl={anchorEl}
					keepMounted
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={Boolean(anchorEl)}
					classes={{ list: 'p-0' }}
					onClose={handleClose}>
					<div className="dropdown-menu-lg overflow-hidden p-0">
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/reset'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Reset Password</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/emailTemplate'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Email Templates</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/emailHistoryChrome'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Email History</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/linkedinCredential'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Linkedin Credentials</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/autoNoteHistory'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>AutoNotes History</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/contact'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>My Contacts</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/traceReport'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Trace and Report</span>
							</ListItem>
						</List>
						<List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem onClick={handleLogout} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>Log out</span>
							</ListItem>
						</List>
						{/* <List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
							<ListItem component={NavLink} to={'/404'} button>
								<div className="mr-2">
									<ExitToAppTwoToneIcon />
								</div>
								<span>404</span>
							</ListItem>
						</List> */}
					</div>
				</Menu>
			</div>
		</>
	);
};

export default withCookies(HeaderUserbox);
