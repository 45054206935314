import url from "../../utils/url";
import {FETCH_DATA} from "../middlewares/api";
import { articleById, schema } from "./entities/articles";
import {combineReducers} from "redux";

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
  FETCH_ARTICLES_REQUEST: "ARTICLE _TAB/FETCH_ARTICLES_REQUEST",
  FETCH_ARTICLES_SUCCESS: "ARTICLE _TAB/FETCH_ARTICLES_SUCCESS",
  FETCH_ARTICLES_FAILURE: "ARTICLE _TA/FETCH_ARTICLES_FAILURE",

  SET_ACTIVE_ARTICLE: "ARTICLE_TAB/SET_ACTIVE_ARTICLE",
  CANCEL_ACTIVE_ARTICLE: "ARTICLE_TAB/CANCEL_ACTIVE_ARTICLE"
}

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
  /*
  *  articlesList: {
  * 		'XXX':{
  * 			isFetching: false,
  * 			ids: []
  * 		}
  *  }
  * */
  articlesList: {},
  activeArticle: null
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadArticlesList: (id) =>{
    return (dispatch, getState) => {
      const state = getState();
      if(state.entities.articles.length > 0){
        return null;
      }
      const endpoint = url.getArticlesList(id);
      return dispatch(fetchArticlesList(endpoint, id));
    };
  },
  setActiveArticle: (id) =>{
    return (dispatch, getState) => {
      return dispatch(activeArticleHandler(id));
    }
  },
  cancelActiveArticle: ()=>{
    return (dispatch, getState) =>{
      return dispatch(activeArticleHandler());
    }
  }
}

const activeArticleHandler = (id) =>{
  if(id){
    return {
      type: types.SET_ACTIVE_ARTICLE,
      id
    }
  }else {
    return {
      type: types.CANCEL_ACTIVE_ARTICLE,
    }
  }
}

const fetchArticlesList = (endpoint, id) => ({
  [FETCH_DATA]: {
    types: [types.FETCH_ARTICLES_REQUEST, types.FETCH_ARTICLES_SUCCESS, types.FETCH_ARTICLES_FAILURE],
    endpoint,
    schema,
  },
  id
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const activeArticle = (state = initialState.activeArticle, action) =>{
  switch (action.type) {
    case types.SET_ACTIVE_ARTICLE:
      console.log("action.response:", action)
      return action.id;
    case types.CANCEL_ACTIVE_ARTICLE:
      return null;
    default:
      return state;
  }
}
const articlesList = (state = initialState.articlesList, action) =>{
  switch (action.type) {
    case types.FETCH_ARTICLES_REQUEST:
    case types.FETCH_ARTICLES_SUCCESS:
    case types.FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        [action.id]: articlesListByOrgId(state[action.id], action),
      };
    default:
      return state;
  }
}

const articlesListByOrgId = (state= {isFetching: false, ids:[]}, action) =>{
  switch (action.type) {
    case types.FETCH_ARTICLES_REQUEST:
      return {...state, isFetching: true};
    case types.FETCH_ARTICLES_SUCCESS:
      return {...state, isFetching: false, ids: state.ids.concat(action.response.ids)};
    case types.FETCH_ARTICLES_FAILURE:
      return {...state, isFetching: false};
    default:
      return state;
  }
}

const reducer = combineReducers({articlesList, activeArticle});
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/

export const getArticlesListByOrgId = (state, id) =>{
  return state.articleTab.articlesList[id] && state.articleTab.articlesList[id].ids.map((id) => {
    return state.entities.articles[id];
  })
}

export const getArticle = (state) =>{
  return articleById(state, state.articleTab.activeArticle)
}
