import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { categoryBodyMapping, categoryType, switchType } from '../../utils/notificationUtil';
import { config, transformToMonthDay } from '../../utils/recruitUtil';
import { get } from '../../utils/request';
import url from '../../utils/url';
import CustomizedSwitch from './components/Switch';
import TalentDetail from './components/TalentDetail';

const hyperLink = '#0795fe';
const useStyles = makeStyles((theme) => ({
	containedPrimary: {
		backgroundColor: hyperLink,
		'&:hover': {
			backgroundColor: hyperLink
		},
		color: '#FFFFFF'
	},
	tableRow: {
		backgroundColor: '#0b9aff',
		hover: {
			cursor: 'pointer',
			backgroundColor: '#FFFFFF'
		}
	},
	clearButton: {
		borderRadius: 5,
		backgroundColor: theme.palette.grey[700],
		color: '#FFFFFF',
		width: 90,
		borderColor: theme.palette.grey[700]
	}
}));

const useStyles_talents_match = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#07e4fe',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#07e4fe',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

const useStyles_task_execution = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#00aeef',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#00aeef',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

const useStyles_o2o = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#5161b3',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

const useStyles_report = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#0795fe',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

const useStyles_emails = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#1bc943',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#5161b3',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

export default function Notification() {
	const [resultData, setResultData] = useState([]);
	const [mainData, setMainData] = useState(null);

	const format = 'HH:mm';
	const [timeString, setTimeString] = useState('00:00');

	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const [selectedRow, setSelectedRow] = useState(null);
	const [open, setOpen] = useState(false);

	const [checkers, setCheckers] = useState({});
	const [checkersPerPage, setCheckersPerPage] = useState([]);
	const [checkboxPerPage, setCheckboxPerPage] = useState([]);
	const [numberOfSelected, setNumberOfSelected] = useState(0);

	const classes = useStyles();
	const classes_talents_match = useStyles_talents_match();
	const classes_task_execution = useStyles_task_execution();
	const classes_o2o = useStyles_o2o();
	const classes_report = useStyles_report();
	const classes_email = useStyles_emails();

	const [checked, setChecked] = useState({
		talents_match: true,
		task_execution: true,
		o2o: true,
		report: true,
		email: true
	});

	const rowCount = 10;
	const [page, setPage] = useState(1);
	const handleChangePage = (e, value) => {
		setPage(value);
		const local = resultData;
		setMainData(local.slice(value * rowCount - rowCount, value * rowCount));
	};

	const handleSwitch = (event) => {
		const type = event.target.name;
		const isChecked = event.target.checked;
		setChecked({ ...checked, [type]: event.target.checked });

		axios
			.post(
				url.toggleSwitch(),
				{
					switchType: type?.toUpperCase(),
					active: isChecked ? 1 : 0,
					timePicker: timeString
				},
				config
			)
			.then((res) => {
				console.log('toggleSwitch', res.data);
			})
			.catch((e) => {
				console.error('toggleSwitch error catch', e.message);
			});
	};

	const handleTimePicker = (time, timeText) => {
		console.log(time, timeText);
		if (time && timeText.length > 0) {
			setTimeString(timeText);

			axios
				.post(
					url.toggleSwitch(),
					{
						switchType: switchType.REPORT,
						active: checked.report ? 1 : 0,
						timePicker: timeText
					},
					config
				)
				.then((res) => {
					console.log('toggleSwitch', res.data);
				})
				.catch((e) => {
					console.error('toggleSwitch error catch', e.message);
				});
		}
	};

	const isEmpty = (checkersPerPage) => {
		const obj = checkersPerPage[page - 1];
		const flag1 = Object.keys(obj).length === 0;
		let flag2 = false;
		let cnt = 0;
		for (const [_, notification] of Object.entries(obj)) {
			if (notification && notification !== undefined) {
				cnt++;
			}
		}
		flag2 = cnt < mainData.length ? true : false;

		return flag1 || flag2;
	};

	const handleCheckAllActionPage = () => {
		const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));

		const nIds = Object.entries(checkers)
			.filter(([_, value]) => value !== undefined)
			.map(([nId, _]) => parseInt(nId));

		const set = new Set(nIds);
		let cnt = 0;
		for (const each of resultData) {
			if (set.has(each.id)) {
				cnt++;
			}
		}

		const otherPageNumberOfSelected = numberOfSelected - cnt < 0 ? 0 : numberOfSelected - cnt;

		if (isEmpty(checkersPerPage)) {
			let local = {};
			mainData.map((each) => (local[each.id] = each));
			setCheckers({ ...checkers, ...local });

			//checkers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = each));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = otherPageNumberOfSelected + mainData.length;
			setNumberOfSelected(totalNumberOfSelected);

			copyCheckboxPerPage[page - 1] = true;
			setCheckboxPerPage(copyCheckboxPerPage);
		} else {
			let local = {};
			mainData.map((each) => (local[each.id] = undefined));
			setCheckers({ ...checkers, ...local });

			//checkers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = undefined));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = numberOfSelected - mainData.length;
			setNumberOfSelected(totalNumberOfSelected);

			copyCheckboxPerPage[page - 1] = false;
			setCheckboxPerPage(copyCheckboxPerPage);
		}
	};

	const handleCheckNotification = (id, each) => {
		setCheckers((prevState) => ({ ...prevState, [id]: each }));

		//checkers per page
		const currCheckers = checkersPerPage[page - 1];
		const copyCurrCheckers = { ...currCheckers };
		copyCurrCheckers[id] = each;
		checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
		setCheckersPerPage(checkersPerPage);

		setNumberOfSelected(numberOfSelected + 1);

		//check current page all selected
		let count = 0;
		for (const each of mainData) {
			if (copyCurrCheckers[each.id] !== undefined) {
				count++;
			}
		}
		if (count === mainData.length) {
			const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));
			copyCheckboxPerPage[page - 1] = true;
			setCheckboxPerPage(copyCheckboxPerPage);
		}
	};

	const handleUnCheckNotification = (id, each) => {
		setCheckers((prevState) => ({ ...prevState, [id]: undefined }));

		//checkers per page
		const currCheckers = checkersPerPage[page - 1];
		const copyCurrCheckers = { ...currCheckers };
		copyCurrCheckers[id] = undefined;
		checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
		setCheckersPerPage(checkersPerPage);

		setNumberOfSelected(numberOfSelected - 1);

		const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));
		copyCheckboxPerPage[page - 1] = false;
		setCheckboxPerPage(copyCheckboxPerPage);
	};

	const handleDelete = async () => {
		console.log('delete projects.....');

		const deleteList = [];
		for (const [id, value] of Object.entries(checkers)) {
			console.log('id', id, 'value', value);
			if (value !== undefined) {
				deleteList.push(parseInt(id));
			}
		}

		await axios
			.delete(url.deleteNotifications(), {
				withCredentials: true,
				data: { notifications: deleteList }
			})
			.then((res) => {
				console.log(res.data.message);
				loadNotifications();
				setDeleteOpen(false);
				setNumberOfSelected(0);
			})
			.catch((e) => console.error('Delete projects has error', e));
	};

	const handleClickAction = async (e, data) => {
		console.log('talent select', data);
		// const row = e.target.parentNode.parentNode.parentNode.parentNode;
		// row.focus();

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then((res) => {
				const result = res.data;
				console.log('fetchTalentDetail', result.message);
				setSelectedRow(result.data);
				setOpen(true);
			})
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
			});
	};

	const handleFocus = (e, selectedRow) => {
		// const row = e.target.parentNode;
		// row.style.backgroundColor = "#FFFFFF";
		axios
			.post(
				url.updateReadStatus(),
				{
					id: selectedRow.id
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);

				//const copyResultData = JSON.parse(JSON.stringify(resultData));
				for (const each of resultData) {
					if (each.id === selectedRow.id) {
						each.read = 1;
						break;
					}
				}

				const value = page;
				const local = resultData;
				setMainData(local.slice(value * rowCount - rowCount, value * rowCount));
				setResultData(resultData);
			})
			.catch((e) => {
				console.error('updateReadStatus has error', e);
			});
	};

	const loadNotifications = () => {
		get(url.fetchNotifications()).then((res) => {
			//console.log(res.data);

			const result = res.data.map((each) => {
				each.context = JSON.parse(each.context);
				return each;
			});

			console.log(result);

			const finalData = result.map((each) => {
				const nId = each.id;
				const project = each.tag;
				const category = each.category;
				const context = each.context;
				const releaseTime = each.releaseTime;
				const read = each.read;
				const keywords = context.keywords;

				const body = categoryBodyMapping[category];
				let desc = [];
				switch (category) {
					case categoryType.EMAIL_REPLIED:
						const talents_replied = keywords.talents;
						const talentsLink_replied = talents_replied.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_replied.length - 1 ? '' : ', '
						]);
						desc = [...talentsLink_replied, ' ', body];
						break;
					case categoryType.EMAIL_OPENED:
						const talents_opened = keywords.talents;
						const talentsLink_opened = talents_opened.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_opened.length - 1 ? '' : ', '
						]);
						desc = [...talentsLink_opened, ' ', body];
						break;
					case categoryType.LINKEDIN_MESSAGED:
						const talents_messaged = keywords.talents;
						const talentsLink_messaged = talents_messaged.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_messaged.length - 1 ? '' : ', '
						]);
						desc = [...talentsLink_messaged, ' ', body];
						break;
					case categoryType.LINKEDIN_CONNECTED:
						const talents_connected = keywords.talents;
						const talentsLink_connected = talents_connected.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_connected.length - 1 ? '' : ', '
						]);
						desc = [...talentsLink_connected, ' ', body];
						break;
					case categoryType.NEW_TALENTS:
						const talents_newTalents = keywords.talents;
						const project = keywords.project;
						const talentsLink_newTalents = talents_newTalents.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_newTalents.length - 1 ? '' : ', '
						]);

						const name = project.tag_name;
						const id = project.tag_id;
						const projectLink = (
							<NavLink
								to={`/savedprojects/projectedit/${encodeURIComponent(name + '_' + id)}`}
								className="font-weight-bold text-black">
								<span style={{ color: hyperLink }}>{name}</span>
							</NavLink>
						);

						desc = [...talentsLink_newTalents, ' ', body, ' ', projectLink, '.'];
						break;
					case categoryType.TALENTS_RECOMMENDED:
						return '';
					case categoryType.AUTONOTES_SEND_STATUS:
						const talents_success = keywords.success_talents;
						const talents_failed = keywords.fail_talents;

						const talentsLink_failTalents = talents_failed.map((each, index) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							index === talents_failed.length - 1 ? '.' : ', '
						]);

						desc = [
							body[0],
							' ',
							talents_success.length,
							' ',
							body[1],
							' ',
							talents_failed.length,
							' ',
							body[2],
							' ',
							...talentsLink_failTalents
						];
						break;
					case categoryType.AUTONOTES_WITHDRAW_STATUS:
						const talents_withdraw = keywords.talents;

						const autoHistoryLink = (
							<NavLink to={'/autoNoteHistory'} className="font-weight-bold text-black">
								<span style={{ color: hyperLink }}>AutoNotes History</span>
							</NavLink>
						);

						desc = [talents_withdraw.length, ' ', body, ' ', autoHistoryLink];
						break;
					case 'o2o':
						const talents_o2o = keywords.talents;
						const talentsLink_o2o = talents_o2o.map((each) => [
							<a
								style={{ color: hyperLink }}
								onClick={(e) => handleClickAction(e, each)}
								className="talent font-weight-bold text-black">
								{each.name}
							</a>,
							', '
						]);
						desc = [...talentsLink_o2o, ' ', body];
						break;
				}

				if (desc.length > 0) {
					const projectLink = (
						<NavLink
							to={`/savedprojects/projectview/${encodeURIComponent(
								project.name + '_' + project.id
							)}`}
							className="font-weight-bold text-black">
							<span style={{ color: hyperLink }}>{project.name}</span>
						</NavLink>
						// <div>XXX</div>
					);

					return {
						id: nId,
						projectLink: projectLink,
						desc: desc,
						releaseTime: releaseTime,
						read: read,
						category: category
					};
				}
			});

			console.log('end....');

			setResultData(finalData);
			setPage(1);
			const local = finalData;
			setMainData(local.slice(0, rowCount));

			const pageCount = Math.ceil(finalData.length / rowCount);
			const arrayInit = [...Array(pageCount)];
			arrayInit.fill({});
			setCheckersPerPage(arrayInit);

			const checkboxInit = [...Array(pageCount)];
			checkboxInit.fill(false);
			setCheckboxPerPage(checkboxInit);

			console.log(finalData.length);
			const count = Math.ceil(finalData.length / rowCount);
			console.log(count);
		});
	};

	useEffect(() => {
		console.log('template loading.....');
		loadNotifications();
	}, []);

	useEffect(() => {
		get(url.fetchSwitchSetting()).then((res) => {
			const setting = res.data.setting;
			// const timeSetting = res.data.timeSetting;
			// setTimeString(timeSetting.reportTime);

			const copyChecked = JSON.parse(JSON.stringify(checked));
			for (const [type, value] of Object.entries(setting)) {
				copyChecked[type] = value === 1 ? true : false;
			}
			setChecked(copyChecked);
		});
	}, []);

	return (
		<>
			<Card className="notification card-box p-2">
				<CardContent className="flowGrow">
					<Grid container spacing={1}>
						<Grid item xs={4} md={4} lg={4}>
							<FormControlLabel
								control={
									<CustomizedSwitch
										checked={checked.talents_match}
										onChange={handleSwitch}
										name="talents_match"
										classProps={classes_talents_match}
									/>
								}
								label="Notify newly matched talents and pools"
							/>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							<FormControlLabel
								control={
									<CustomizedSwitch
										checked={checked.task_execution}
										onChange={handleSwitch}
										name="task_execution"
										classProps={classes_task_execution}
									/>
								}
								label="Notify task execution status of your projects"
							/>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							<FormControlLabel
								control={
									<CustomizedSwitch
										checked={checked.o2o}
										onChange={handleSwitch}
										name="o2o"
										classProps={classes_o2o}
									/>
								}
								label="Notify new O2O candidates in your projects"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={4} md={4} lg={4}>
							<FormControlLabel
								control={
									<CustomizedSwitch
										checked={checked.report}
										onChange={handleSwitch}
										name="report"
										classProps={classes_report}
									/>
								}
								label="Receive Daily briefs on your projects' AutoEmails and AutoNotes"
							/>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							<FormControlLabel
								control={
									<CustomizedSwitch
										checked={checked.email}
										onChange={handleSwitch}
										name="email"
										classProps={classes_email}
									/>
								}
								label="Send emails for all your selected notifications"
							/>
						</Grid>
						<Grid container item xs={4} md={4} lg={4} alignItems="center" direction="row">
							{/* <TimePicker
								className={'mr-3'}
								format={format}
								onChange={handleTimePicker}
								showNow={false}
								minuteStep={30}
								value={moment(timeString, format)}
							/>
							<div>Set time for daily briefs</div> */}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<div className="card-header w-100 d-flex flex-column">
				<div style={{ paddingLeft: '1.7rem' }}>
					<Checkbox
						checked={checkboxPerPage[page - 1] === true}
						onChange={handleCheckAllActionPage}
						disabled={!mainData || mainData.length === 0}
					/>
					<button className={classes.clearButton} onClick={() => setDeleteOpen(true)}>
						Clear
					</button>
				</div>
				<TableContainer component={Paper}>
					<Table>
						<TableBody>
							{mainData &&
								mainData.map((row, index) => (
									<TableRow
										onClick={(e) => handleFocus(e, row)}
										style={{ backgroundColor: row.read ? '#FFFFFF' : '#e8f5fe', cursor: 'pointer' }}
										key={index}
										className={
											categoryType.EMAIL_REPLIED === row.category ||
											categoryType.EMAIL_OPENED === row.category ||
											categoryType.LINKEDIN_MESSAGED === row.category ||
											categoryType.LINKEDIN_CONNECTED === row.category
												? 'notification table_row_report'
												: categoryType.AUTONOTES_SEND_STATUS === row.category ||
												  categoryType.AUTONOTES_WITHDRAW_STATUS === row.category
												? 'notification table_row_task_execution'
												: categoryType.NEW_TALENTS === row.category ||
												  categoryType.TALENTS_RECOMMENDED === row.category
												? 'notification table_row_task_match'
												: 'notification table_row_o2o'
										}>
										<TableCell style={{ width: 20 }}>
											{/* <div className="pt-2 pr-2">
												<span className="dot"/>
											</div> */}
											<div>
												<Checkbox
													checked={checkers[row.id] !== undefined}
													onChange={(e) => {
														if (e.target.checked) {
															handleCheckNotification(row.id, row);
														} else {
															handleUnCheckNotification(row.id, row);
														}
													}}
												/>
											</div>
										</TableCell>
										<TableCell>{row.projectLink}</TableCell>
										<TableCell align="left">{row.desc}</TableCell>
										<TableCell align="right">{transformToMonthDay(row.releaseTime)}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<div className="p-3 d-flex justify-content-center">
					<Pagination
						page={page}
						className="pagination-first"
						size="small"
						onChange={handleChangePage}
						count={Math.ceil(resultData.length / rowCount)}
					/>
				</div>
			</div>

			<Dialog open={isDeleteOpen} onClose={() => setDeleteOpen(false)}>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to clear the selected notification(s)?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteOpen(false)} color="primary" variant="outlined">
						Cancel
					</Button>
					<Button className={classes.containedPrimary} onClick={handleDelete}>
						Sure and continue
					</Button>
				</DialogActions>
			</Dialog>

			{selectedRow && <TalentDetail selectedRow={selectedRow} open={open} setOpen={setOpen} />}
		</>
	);
}
