import React from 'react';

export default function Terms() {
	return (
		<div>
			<p>
				These terms and privacy constitute a legally binding agreement made between you, whether
				personally or on behalf of an entity and OCInsights concerning your access to and use of
				OCInsights AutoNotes services.
			</p>
			<p>
				You agree that by accessing the AutoNotes, you have read, understood, and agree to be bound
				by all of these terms and privacy. If you do not agree with all of these terms and privacy,
				then you are expressly prohibited from using OCInsights AutoNotes services and you must
				discontinue use immediately.
			</p>
			<h5>
				<strong>1. DATA WE COLLECT</strong>
			</h5>
			<p>
				OCInsights AutoNotes is a web services that automates certain connecting tasks on LinkedIn.
				Our service imitates human behavior and makes it easy for recruiters to connect talents
				efficiently.
			</p>
			<p>
				To use AutoNotes, you need to provide your Linkedin login credentials. We retain your
				LinkedIn data while your account is in existence or as needed to provide you Services. This
				includes data you have provided to us and data generated or inferred from your use of
				AutoNotes. We will retain your information and keep your data until you decide to close your
				account. Once you close your account, we delete all your data from our systems.
			</p>
			<h5>
				<strong>2. SECURITY</strong>
			</h5>
			<p>
				We implement security safeguards designed to protect your data. We regularly monitor our
				systems for possible vulnerabilities and attacks. Our applications pseudonymize data to
				ensure the privacy of data subjects. Any attributes that don’t need to remain in its
				original form is truncated to remove any possibility to be linked back to a specific data
				subject. However, we cannot warrant the security of any information that you send us.
			</p>
			<h5>
				<strong>3. LAWFUL BASES FOR PROCESSING</strong>
			</h5>
			<p>
				We will only collect and process personal data about you where we have lawful bases. Lawful
				bases include consent (where you have given consent), contract (where processing is
				necessary for the performance of a contract with you) and “legitimate interests”.
			</p>
			<p>
				Where we rely on your consent to process personal data, you have the right to withdraw or
				decline your consent at any time and where we rely on legitimate interests, you have the
				right to object. If you have any questions about the lawful bases upon which we collect and
				use your personal data, please contact us at info@ocinsights.ai
			</p>
			<h5>
				<strong>4. CONNECTING TO LINKEDIN</strong>
			</h5>
			<p>
				OCInsights has no control over, and assumes no responsibility for, the content, privacy
				policies, terms of use, or practices of LinkedIn. You further acknowledge and agree that
				OCInsights shall not be responsible or liable, directly or indirectly, for any damage or
				loss caused or alleged to be caused by or in connection with use of or reliance on any such
				content, goods or services available on or through OCInsights website and it services.
			</p>
			<h5>
				<strong>5. USER REPRESENTATIONS</strong>
			</h5>
			<p>
				By using the OCInsights AutoNotes, you represent and warrant that:
				<br />
				(1) all registration information you submit will be true, accurate, current, and complete;
				<br />
				(2) you will maintain the accuracy of such information and promptly update such registration
				information as necessary;
				<br />
				(3) you have the legal capacity and you agree to comply with these terms and privacy;
				<br />
				(4) you will not use the service for any illegal or unauthorized purpose.
				<br />
			</p>
			<h5>
				<strong>6. LIMITATION OF LIABILITY</strong>
			</h5>
			<p>
				OCInsights is in no way affiliated or connected with LinkedIn. By using OCInsights, you
				agree that OCInsights holds no responsibility for any losses or liabilities incurred,
				including the imposition of restrictions, penalties by LinkedIn.
			</p>
			<p>
				In no event shall OCInsights, nor its principals, directors, employees, partners, or
				affiliates, be liable for any indirect, incidental, special, consequential or punitive
				damages, including without limitation, loss of profits, data, use, goodwill, or other
				intangible losses, resulting from (i) your access to or use of or inability to access or use
				the AutoNotes service; (ii) any conduct or content of any third party on the AutoNotes
				service; (iii) any content obtained from the AutoNotes service; and (iv) unauthorized
				access, use or alteration of your transmissions or content, whether based on warranty,
				contract, tort (including negligence) or any other legal theory, whether or not we have been
				informed of the possibility of such damage, and even if a remedy set forth herein is found
				to have failed of its essential purpose.
			</p>
			<p>
				If you provide any information that is untrue, inaccurate, not current, or incomplete, we
				have the right to suspend or terminate your account and refuse any and all current or future
				use of the AutoNotes.
			</p>
			<h5>
				<strong>7. MISCELLANEOUS</strong>
			</h5>
			<p>
				The Terms and privacy and any policies or operating rules on using OCInsights and OCInsights
				AutoNotes constitute the entire agreement and understanding between you and us. Our failure
				to exercise or enforce any right or provision of these Terms and privacy shall not operate
				as a waiver of such right or provision. These Terms and privacy operate to the fullest
				extent permissible by law. We may assign any or all of our rights and obligations to others
				at any time. We shall not be responsible or liable for any loss, damage, delay, or failure
				to act caused by any cause beyond our reasonable control.
			</p>
			<p>
				If any provision or part of a provision of these Terms and privacy on OCInsights and
				OCInsights AutoNotes is determined to be unlawful, void, or unenforceable, that provision or
				part of the provision is deemed severable from these Terms and privacy and does not affect
				the validity and enforceability of any remaining provisions.
			</p>
		</div>
	);
}
