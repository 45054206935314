import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { get } from '../../../src/utils/request';
import url from '../../../src/utils/url';

function TransitionUp(props) {
	return <Slide {...props} direction="up" style={{ backgroundColor: 'grey' }} />;
}

const HeaderDots = ({ permission }) => {
	const [open, setOpen] = useState(false);
	const [transition, setTransition] = useState(undefined);

	const [findTalents, setFindTalents] = useState(true);
	const [statistc, setStatistics] = useState(true);
	const [dashboard, setDashboard] = useState(true);
	const [contactSearch, setContactSearch] = useState(true);
	const [notificationCount, setNotificationCount] = useState(0);

	const handleClickStatistic = () => {
		setStatistics(false);
	};

	const handleClickDashboard = () => {
		setDashboard(false);
	};

	const handleClickTalent = () => {
		setFindTalents(false);
	};

	const handleClickContactSearch = () => {
		setContactSearch(false);
	};

	// const handleClickNotification = () => {
	// 	setNotification(false);
	// };

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		get(url.fetchNotificationCount()).then((res) => {
			setNotificationCount(res.data);
		});
	}, []);

	return (
		<>
			<div className="d-flex align-items-center popover-header-wrapper">
				<span className="d-inline-block pr-3">
					<NavLink to={'/executive'} onClick={handleClickStatistic}>
						<Tooltip title="Talent Analytics" placement="bottom-end" arrow>
							<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
								<span>
									{statistc && (
										<div className="badge badge-danger badge-circle">New notifications</div>
									)}
									<img
										className={'img-fit-container rounded-sm'}
										src={require('../../assets/images/apps/statisticChart.png')}
										alt={'icon'}
									/>
								</span>
							</Button>
						</Tooltip>
					</NavLink>
				</span>
				<span className="d-inline-block pr-3">
					<NavLink to={'/Dashboard'} onClick={handleClickDashboard}>
						<Tooltip title="Dashboard" placement="bottom-end" arrow>
							<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
								<span>
									{dashboard && (
										<div className="badge badge-danger badge-circle">New notifications</div>
									)}
									<img
										className={'img-fit-container rounded-sm'}
										src={require('../../assets/images/apps/dashboard.png')}
										alt={'icon'}
									/>
								</span>
							</Button>
						</Tooltip>
					</NavLink>
				</span>
				<span className="d-inline-block pr-3">
					{permission === 1 ? (
						<NavLink to={'/recruit'} onClick={handleClickTalent}>
							<Tooltip title="Recruiting Tool" placement="bottom-end" arrow>
								<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
									<span>
										{findTalents && (
											<div className="badge badge-danger badge-circle">New notifications</div>
										)}
										<img
											className={'img-fit-container rounded-sm'}
											src={require('../../assets/images/apps/searchTalents.png')}
											alt={'icon'}
										/>
									</span>
								</Button>
							</Tooltip>
						</NavLink>
					) : (
						<div
							onClick={() => {
								setTransition(() => TransitionUp);
								setOpen(true);
							}}>
							<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
								<span>
									{findTalents && (
										<div className="badge badge-danger badge-circle">New notifications</div>
									)}
									<img
										className={'img-fit-container rounded-sm'}
										src={require('../../assets/images/apps/searchTalents.png')}
										alt={'icon'}
									/>
								</span>
							</Button>
						</div>
					)}
				</span>
				<span className="d-inline-block pr-3">
					<NavLink to={'/contactsearch'} onClick={handleClickContactSearch}>
						<Tooltip title="Contact Infos" placement="bottom-end" arrow>
							<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
								<span>
									{contactSearch && (
										<div className="badge badge-danger badge-circle">New notifications</div>
									)}
									<img
										className={'img-fit-container rounded-sm'}
										src={require('../../assets/images/apps/contactInfo.png')}
										alt={'icon'}
									/>
								</span>
							</Button>
						</Tooltip>
					</NavLink>
				</span>
				{/* <span className="d-inline-block pr-3">
					<NavLink to={'/notification'}>
						<Tooltip title="Search Contacts" placement="bottom-end" arrow>
							<Button className="btn-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded-sm position-relative">
								<span>
									<Badge badgeContent={notificationCount} color="error">
										<img
											className={'img-fit-container rounded-sm'}
											src={require('../../assets/images/apps/notification.png')}
											alt={'icon'}
										/>
									</Badge>
								</span>
							</Button>
						</Tooltip>
					</NavLink>
				</span> */}
			</div>
			<div className="d-flex align-items-center justify-content-center flex-wrap">
				<Snackbar
					open={open}
					className="text-white text-center"
					onClose={handleClose}
					TransitionComponent={transition}
					message="You are unable to access this talent recruitment function. If you are interested, please contact our sales at audrey@ocinsights.ai. Thank you."
				/>
			</div>
		</>
	);
};

export default HeaderDots;
