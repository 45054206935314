import url from '../../utils/url';
import { FETCH_DATA, POST_DATA } from '../middlewares/api';
import { schema } from './entities/projects';
import { combineReducers } from 'redux';

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
	FETCH_PROJECTS_REQUEST: 'RECRUIT/FETCH_PROJECTS_REQUEST',
	FETCH_PROJECTS_SUCCESS: 'RECRUIT/FETCH_PROJECTS_SUCCESS',
	FETCH_PROJECTS_FAILURE: 'RECRUIT/FETCH_PROJECTS_FAILURE',

	POST_PROJECT_REQUEST: 'RECRUIT/POST_PROJECT_REQUEST',
	POST_PROJECT_SUCCESS: 'RECRUIT/POST_PROJECT_SUCCESS',
	POST_PROJECT_FAILURE: 'RECRUIT/POST_PROJECT_FAILURE'
};

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
	projects: {
		isPosting: false,
		isFetching: false,
		ids: null
	}
};

/***********************************************************************************************************************
 * 													ACTIONS 														                                                       *
 * *********************************************************************************************************************/

export const actions = {
	loadProjects: () => {
		return (dispatch) => {
			const endpoint = url.getProjectsList();
			return dispatch(fetchProjects(endpoint));
		};
	},
	addProject: (data) => {
		return (dispatch) => {
			const endpoint = url.postProject();
			return dispatch(postProject(endpoint, data));
		};
	}
};

const fetchProjects = (endpoint) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_PROJECTS_REQUEST,
			types.FETCH_PROJECTS_SUCCESS,
			types.FETCH_PROJECTS_FAILURE
		],
		endpoint,
		schema
	}
});

const postProject = (endpoint, data) => ({
	[POST_DATA]: {
		types: [types.POST_PROJECT_REQUEST, types.POST_PROJECT_SUCCESS, types.POST_PROJECT_SUCCESS],
		data,
		endpoint,
		schema
	}
});

/***********************************************************************************************************************
 * 													REDUCERS 														                                                       *
 * *********************************************************************************************************************/
const projects = (state = initialState.projects, action) => {
	switch (action.type) {
		case types.FETCH_PROJECTS_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_PROJECTS_SUCCESS:
			return { ...state, isFetching: false, ids: action.response.ids };
		case types.FETCH_PROJECTS_FAILURE:
			return { ...state, isFetching: false };

		case types.POST_PROJECT_REQUEST:
			return { ...state, isPosting: true };
		case types.POST_PROJECT_SUCCESS:
		case types.POST_PROJECT_FAILURE:
			return { ...state, isPosting: false };
		default:
			return state;
	}
};

const reducer = combineReducers({ projects });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/

export const getProjectsList = (state) => {
	console.log('getProjectsList: ', state);
	return (
		state.projectsPage.projects.ids &&
		state.projectsPage.projects.ids.map((id) => {
			return state.entities.projects[id];
		})
	);
};
