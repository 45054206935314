import { Button, Table } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { get, post, update } from '../../../utils/request';
import url from '../../../utils/url';
import Addcustomer from './addcustomer';
import Editcustomer from './editcustomer';

export default function Customers(props) {
	const [permissionsData, setPermissionsData] = useState([]);
	const [isEditLoading, setEditLoading] = useState(false);
	const [isEditVisible, setEditVisible] = useState(false);
	const [isAddLoading, setAddLoading] = useState(false);
	const [isAddVisible, setAddVisible] = useState(false);
	const [companies, setCompanies] = useState(false);
	const [customerData, setCustomerData] = useState({});
	const [dashboard, setDashboard] = useState([]);
	const { Column, ColumnGroup } = Table;

	//////////////// Edit View ////////////////
	const showEditModal = (customer) => {
		setCustomerData(customer);
		setEditVisible(true);
	};

	const handleEditCancel = () => {
		setEditVisible(false);
	};

	const handleEditFinish = async (form) => {
		console.log('Received Edit form: ', form);
		console.log('values', form.getFieldsValue());

		try {
			const values = form.getFieldsValue();
			const availableList = values.companies;
			const companiesstatus = companies.map((company) => {
				if (availableList.includes(company.companyId)) {
					company.available = 1;
				} else {
					company.available = 0;
				}
				return { ...company, updatedAt: new Date() };
			});
			console.log(companiesstatus);
			setEditLoading(true);

			// get(url.getAdminDashboardByCustomer(values.customerId)).then((res) => {
			// 	console.log(res.data);

			// });

			update(url.updateAdminCustomer(values.customerId), {
				...values,
				companies: companiesstatus
			}).then((res) => {
				console.log('update customer successfully', res);
				setTimeout(() => {
					setEditLoading(false);
					setEditVisible(false);
				}, 3000);
			});
		} catch (errorInfo) {
			return Promise.reject({
				error: {
					message: errorInfo
				}
			});
		}
	};

	//////////////// Add View ////////////////
	const showAddModal = () => {
		console.log('show add modal....');
		setAddVisible(true);
	};

	const handleAddCancel = () => {
		setAddVisible(false);
	};

	const handleAddFinish = async (form) => {
		console.log('Received Add form: ', form);
		console.log('values', form.getFieldsValue());

		try {
			const values = form.getFieldsValue();
			const availableList = values.companies;
			const companiesstatus = companies.map((company) => {
				availableList.forEach((itemId) => {
					if (itemId === company.companyId) {
						company.available = 1;
						return;
					}
				});
				return company;
			});
			console.log(companiesstatus);
			setAddLoading(true);
			post(url.postAdminCustomer(), { ...values, companies: companiesstatus }).then((res) => {
				console.log('post customer successfully', res);
				setTimeout(() => {
					setAddLoading(false);
					setAddVisible(false);
					form.resetFields();
				}, 3000);
			});
		} catch (errorInfo) {
			return Promise.reject({
				error: {
					message: errorInfo
				}
			});
		}
	};

	////////////////////////// REACT LIFE CYCLE ////////////////////////////////
	useEffect(() => {
		console.log('users loading.....');
		get(url.getAdminPermissions()).then((res) => {
			console.log(res.data);
			setPermissionsData(res.data);
		});
	}, [isEditLoading, isAddLoading]);

	useEffect(() => {
		console.log('companies loading.....');
		get(url.getAdminCompanies()).then((res) => {
			console.log(res.data);
			setCompanies(res.data);
		});
	}, []);

	return (
		<>
			<div>
				<Button style={{ marginBottom: 16 }} type="primary" onClick={showAddModal}>
					Add
				</Button>
				<Button style={{ float: 'right', position: 'relative' }} onClick={props.redirectHome}>
					Home
				</Button>
				<Button style={{ float: 'right', position: 'relative' }} onClick={props.logout}>
					Logout
				</Button>
				<Table
					style={{ width: '98%' }}
					dataSource={permissionsData}
					pagination={{ pageSize: 5 }}
					scroll={{ y: 450, x: 700 }}>
					<Column title="Customer" dataIndex="customername" />
					{/* <Column title="Company Detail" dataIndex="companyDetail" />
					<Column title="Summary" dataIndex="summary" /> */}
					<Column title="Recruitment" dataIndex="recruitment" />
					<Column title="Start Date" dataIndex="startDate" />
					<Column title="End Date" dataIndex="endDate" />
					<Column
						title="Company"
						render={(_, record) => {
							return (
								record.dashboards &&
								record.dashboards.map((company) => {
									if (company.available === 1) {
										return <p>{company.companyname}</p>;
									}
								})
							);
						}}
					/>
					<Column
						render={(_, record) => {
							const companiesSelected = [];
							record.dashboards.forEach((company) => {
								if (company.available === 1) {
									companiesSelected.push(company.companyId);
								}
							});
							return (
								<Button onClick={() => showEditModal({ ...record, dashboards: companiesSelected })}>
									Edit
								</Button>
							);
						}}
					/>
				</Table>
			</div>
			<Addcustomer
				isLoading={isAddLoading}
				isVisible={isAddVisible}
				handleCancel={handleAddCancel}
				handleFinish={handleAddFinish}
				companies={companies}
			/>
			<Editcustomer
				isLoading={isEditLoading}
				isVisible={isEditVisible}
				handleCancel={handleEditCancel}
				customerData={customerData}
				handleFinish={handleEditFinish}
				companies={companies}
			/>
		</>
	);
}
