import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blueGrey, green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import clsx from 'clsx';
import https from 'https';
import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../theme/loadingIndicator';
import {
	checkWithdrawDateLaterThanToday,
	transformDateToLocalTZ,
	transformDateToYearMonthDate
} from '../../utils/recruitUtil';
import { get } from '../../utils/request';
import url from '../../utils/url';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	withdrawPrimary: {
		backgroundColor: '#22bd45',
		'&:hover': {
			backgroundColor: '#22bd45'
		},
		color: '#FFFFFF',
		cursor: 'default'
	},
	withdrawBtnProgress: {
		color: blueGrey[500],
		position: 'absolute'
	}
}));

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

export default function AutoNoteHistory() {
	const classes = useStyles();
	const recordPerPage = 10;
	const { promiseInProgress } = usePromiseTracker();
	const [isOpen, setOpen] = useState(false);
	const [talentsLoading, setTalentsLoading] = useState(false);
	const [buttonFailDisabled, setButtonFailDisabled] = useState(false);
	const [sendFailStatus, setSendFailStatus] = useState({
		index: -1,
		autoNote: null,
		failedTalents: []
	});
	const [action, setAction] = useState(null);

	const [talentsData, setTalentsData] = useState([]);
	const [mainData, setMainData] = useState([]); //for not show loading -> []
	const [resultData, setResultData] = useState([]);
	const [filteredData, setFilteredData] = useState(null); // for paging -> null
	const [page, setPage] = useState(1);

	const [currentIndex, setCurrentIndex] = useState(0);

	const [loginStatus, setLoginStatus] = useState([]);
	const [isWithdrawOpen, setWithdrawOpen] = useState(false);
	const [isLoginLoading, setLoginLoading] = useState([]);
	const [vfLoading, setVfLoading] = useState([]);
	const [confirmLoading, setConfirmLoading] = useState([]);
	const [windowsIds, setWindowsIds] = useState();
	const [isWindowAlertOpen, setWindowAlertOpen] = useState(false);

	const [numberOfTalentsProcessing, setNumberOfTalentsProcessing] = useState(0);
	const [sleepTime, setSleepTime] = useState(0);

	const [searchWord, setSearchWord] = useState('');

	//timeout
	const alarm = {
		remind: function () {
			this.timeoutID = undefined;

			initialize(resultData);
		},

		setup: function () {
			if (typeof this.timeoutID === 'number') {
				this.cancel();
			}

			this.timeoutID = setTimeout(
				function (msg) {
					this.remind();
				}.bind(this),
				1 * 60 * 1000
			);
		},

		cancel: function () {
			clearTimeout(this.timeoutID);
		}
	};

	const handleChangePage = (e, value) => {
		setPage(value);
		const local = filteredData ? filteredData : resultData;
		console.log('page: ', value, 'resultData: ', resultData, 'filteredData: ', filteredData);
		setMainData(local.slice(value * recordPerPage - recordPerPage, value * recordPerPage));
	};

	const handleClickAction = async (e, index, selectedNote) => {
		console.log('note select', selectedNote);
		setTalentsLoading(true);
		//setButtonFailDisabled(true);
		setOpen(true);

		await axios
			.get(url.fetchNoteTalentsList(), {
				params: {
					autoProjectId: selectedNote.id
				}
			})
			.then((res) => {
				const result = res.data;
				console.log('fetchNoteTalentsList', result.message);
				setTalentsData(result.data);

				goOverTalentsStatus(result.data, index, selectedNote);
			})
			.catch((e) => {
				console.error('fetchNoteTalentsList error catch', e.message);
			});

		setTalentsLoading(false);
	};

	/********************************************************* Send To Failed Talents Start ************************************/

	const goOverTalentsStatus = (talents, index, selectedNote) => {
		const noteStatus = selectedNote.status;
		if (
			noteStatus.id === 1 ||
			noteStatus.id === 5 ||
			noteStatus.id === 6 ||
			noteStatus.id === 4 ||
			noteStatus.id === 3
		) {
			setButtonFailDisabled(true);
			return;
		}

		for (const each of talents) {
			const status = each.autoNoteStatus;
			if (status.id === 4) {
				setButtonFailDisabled(true);
				return;
			}
		}

		const failedTalents = [];
		for (const each of talents) {
			const status = each.autoNoteStatus;
			if (status.id === 3 || status.id === 6 || status.id === 9) {
				failedTalents.push(each);
			}
		}

		if (failedTalents.length > 0) {
			setButtonFailDisabled(false);
			setSendFailStatus({
				...sendFailStatus,
				index: index,
				autoNote: selectedNote,
				failedTalents: failedTalents
			});
			//setNumberOfTalentsProcessing(failedTalents.length);
		} else {
			setButtonFailDisabled(true);
			setSendFailStatus({ ...sendFailStatus, index: -1, autoNote: null, failedTalents: [] });
			//setNumberOfTalentsProcessing(0);
		}
	};

	const handleSendToFail = () => {
		const autoNote = sendFailStatus.autoNote;
		const index = sendFailStatus.index;
		const numberOfFailedTalents = sendFailStatus.failedTalents.length;

		const accountId = autoNote.accountId;
		const sleepTime = autoNote.sleepTime;
		const autoProjectId = autoNote.id;
		const numberOfTalents = autoNote.numberOfTalents;
		const tagId = autoNote.tagId;

		const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
		const status = copyLoginStatus[index];

		axios
			.get(url.fetchTalentsNumberAutoNoteProcessing())
			.then((res) => {
				console.log(res.data.message);

				const count = res.data.data + numberOfFailedTalents;

				setNumberOfTalentsProcessing(count);
				setSleepTime(sleepTime);
			})
			.catch((e) => {
				console.log('fetchTalentsNumberAutoNoteProcessing error catch', e.message);
			});

		if (status.isLogin) {
			setCurrentIndex(index);
			setWithdrawOpen(true);
			setOpen(false);
			setAction('SEND');
			return;
		}

		axios
			.get(url.fetchAccount(accountId))
			.then((res) => {
				console.log(res.data.message);
				let info = res.data.data;
				info = { ...info, autoProjectId: autoProjectId, tagId: tagId };

				status.account = info;
				status.numberOfTalents = numberOfTalents;
				setCurrentIndex(index);
				setWithdrawOpen(true);
				setOpen(false);
				setAction('SEND');

				setLoginStatus(copyLoginStatus);
			})
			.catch((e) => {
				console.log('fetchAccount error catch', e.message);
			});
	};

	const confirmSendToFail = async () => {
		const numberOfWindows = await axios
			.get(url.fetchNumberOfWindows())
			.then((res) => {
				console.log(res.data.message);
				const result = res.data.data;
				return result;
			})
			.catch((e) => {
				console.log('fetchNumberOfWindows error catch', e.message);
			});

		if (numberOfWindows && numberOfWindows > 14) {
			setWindowAlertOpen(true);
			return;
		}

		const info = loginStatus[currentIndex].account;
		const email = info.account;
		const accountLogin = { ...info, windowsId: windowsIds[email] };
		const autoNote = sendFailStatus.autoNote;
		const talents = sendFailStatus.failedTalents.map((each) => each.talent);

		const project_note_mapping = {
			account: accountLogin.id,
			tagId: autoNote.tagId,
			sleepTime: autoNote.sleepTime,
			withDrawDays: autoNote.withDrawDays,
			note: autoNote.note,
			accountName: accountLogin.accountName,
			company: accountLogin.company,
			windowsId: accountLogin.windowsId
		};

		axios
			.post(
				url.sendAutoNote(),
				{
					project_note_mapping,
					talents
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				alarm.setup();
			})
			.catch((e) => {
				console.log('handleSendAutoNote error catch', e.message);
			});

		const copyConfirmLoading = JSON.parse(JSON.stringify(confirmLoading));
		copyConfirmLoading[currentIndex] = true;
		setConfirmLoading(copyConfirmLoading);

		setTimeout(() => {
			setWithdrawOpen(false);

			const copyConfirmLoading = JSON.parse(JSON.stringify(confirmLoading));
			copyConfirmLoading[currentIndex] = false;
			setConfirmLoading(copyConfirmLoading);

			const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
			copyLoginStatus[currentIndex].send = true;
			setLoginStatus(copyLoginStatus);
		}, 3000);
	};

	/********************************************************* Send To Failed Talents End ************************************/

	/*
					account: null,
					isLogin: false,
					isLoginLoading: false,
					
					is2FAShow: false,
					is2FASendBtnDisabled: false,
					vfCode: null,
					errorMsgShow: false,

					confirm:false
	*/

	const handleWithdraw = async (e, index, each) => {
		const accountId = each.accountId;
		const autoProjectId = each.id;
		const numberOfTalents = each.numberOfTalents;
		const tagId = each.tagId;

		const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
		const status = copyLoginStatus[index];

		await axios
			.get(url.fetchTalentsNumberWithdrawProcessing())
			.then((res) => {
				console.log(res.data.message);

				const count = res.data.data + numberOfTalents;

				setNumberOfTalentsProcessing(count);
			})
			.catch((e) => {
				console.log('fetchTalentsNumberWithdrawProcessing error catch', e.message);
			});

		if (status.isLogin) {
			setCurrentIndex(index);
			setWithdrawOpen(true);
			setAction('WITHDRAW');
			return;
		}

		await axios
			.get(url.fetchAccount(accountId))
			.then((res) => {
				console.log(res.data.message);
				let info = res.data.data;
				info = { ...info, autoProjectId: autoProjectId, tagId: tagId };

				status.account = info;
				status.numberOfTalents = numberOfTalents;
				setCurrentIndex(index);
				setAction('WITHDRAW');
				setWithdrawOpen(true);

				setLoginStatus(copyLoginStatus);
			})
			.catch((e) => {
				console.log('fetchAccount error catch', e.message);
			});
	};

	const handleLogin = async () => {
		const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));

		const windowsId = Date.now().toString();
		const info = { ...copyLoginStatus[currentIndex].account, windowsId: windowsId };

		const copyLoginLoading_beforeLogin = JSON.parse(JSON.stringify(isLoginLoading));
		copyLoginLoading_beforeLogin[currentIndex] = true;
		setLoginLoading(copyLoginLoading_beforeLogin);

		await axios
			.post(url.loginLinkedin(), info, config)
			.then((res) => {
				console.log(res.data);
				if (res.status === 200) {
					const data = res.data.data;
					if (data.status === 'SUCCESS') {
						for (let i = 0; i < mainData.length; i++) {
							const accountId = mainData[i].accountId;

							if (accountId === info.id) {
								const autoProjectId = mainData[i].id;
								const copyInfo = { ...info, autoProjectId: autoProjectId };

								const status = copyLoginStatus[i];
								status.account = copyInfo;
								status.isLogin = true;

								const email = info.account;

								if (!windowsIds[email]) {
									setWindowsIds({ ...windowsIds, [email]: windowsId });
								}
							}
						}

						alarm.setup();
					} else if (data.status === '2FA') {
						for (let i = 0; i < mainData.length; i++) {
							const accountId = mainData[i].accountId;

							if (accountId === info.id) {
								copyLoginStatus[i].is2FAShow = true;

								const email = info.account;

								if (!windowsIds[email]) {
									setWindowsIds({ ...windowsIds, [email]: windowsId });
								}
							}
						}
					}

					setLoginStatus(copyLoginStatus);
				}
			})
			.catch((e) => {
				console.log('handleLogin error catch', e.message);
			});

		const copyLoginLoading_afterLogin = JSON.parse(JSON.stringify(isLoginLoading));
		copyLoginLoading_afterLogin[currentIndex] = false;
		setLoginLoading(copyLoginLoading_afterLogin);
	};

	const handleSend2FA = async () => {
		const copyVfLoading_beforeVerify = JSON.parse(JSON.stringify(vfLoading));
		copyVfLoading_beforeVerify[currentIndex] = true;
		setVfLoading(copyVfLoading_beforeVerify);

		const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
		const info = copyLoginStatus[currentIndex].account;
		const vfCode = copyLoginStatus[currentIndex].vfCode.trim();
		const email = info.account;
		const windowsId = windowsIds[email];
		const copyAccount = { ...info, vfCode: vfCode, windowsId: windowsId };

		await axios
			.post(url.verify2FA(), copyAccount, config)
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					const data = res.data.data;
					if (data.status === 'SUCCESS') {
						for (let i = 0; i < mainData.length; i++) {
							const accountId = mainData[i].accountId;

							if (accountId === info.id) {
								const autoProjectId = mainData[i].id;
								const copyInfo = { ...info, autoProjectId: autoProjectId };

								const status = copyLoginStatus[i];
								status.is2FASendBtnDisabled = true;
								status.vfCode = vfCode;
								status.errorMsgShow = false;
								status.account = copyInfo;
								status.isLogin = true;

								if (!windowsIds[email]) {
									setWindowsIds({ ...windowsIds, [email]: windowsId });
								}
							}
						}

						alarm.setup();
					} else if (data.status === 'FAILED') {
						for (let i = 0; i < mainData.length; i++) {
							const accountId = mainData[i].accountId;

							if (accountId === info.id) {
								const status = copyLoginStatus[i];
								status.errorMsgShow = true;
								status.is2FASendBtnDisabled = true;
								status.vfCode = vfCode;
							}
						}
					}

					setLoginStatus(copyLoginStatus);
				}
			})
			.catch((e) => {
				console.log('handleSend2FA error catch', e.message);
			});

		const copyVfLoading_afterVerify = JSON.parse(JSON.stringify(vfLoading));
		copyVfLoading_afterVerify[currentIndex] = false;
		setVfLoading(copyVfLoading_afterVerify);
	};

	const confirmWithdraw = async () => {
		const numberOfWindows = await axios
			.get(url.fetchNumberOfWindows())
			.then(
				(res) => {
					console.log(res.data.message);
					const result = res.data.data;
					return result;
				},
				(error) => {
					console.log('fetchNumberOfWindows error', error);
				}
			)
			.catch((e) => {
				console.log('fetchNumberOfWindows error catch', e.message);
			});

		if (numberOfWindows && numberOfWindows > 14) {
			setWindowAlertOpen(true);
			return;
		}

		const info = loginStatus[currentIndex].account;
		const email = info.account;
		const copyInfo = { ...info, windowsId: windowsIds[email] };

		const copyConfirmLoading = JSON.parse(JSON.stringify(confirmLoading));
		copyConfirmLoading[currentIndex] = true;
		setConfirmLoading(copyConfirmLoading);

		setTimeout(() => {
			setWithdrawOpen(false);

			const copyConfirmLoading = JSON.parse(JSON.stringify(confirmLoading));
			copyConfirmLoading[currentIndex] = false;
			setConfirmLoading(copyConfirmLoading);

			const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
			copyLoginStatus[currentIndex].confirm = true;
			setLoginStatus(copyLoginStatus);
		}, 3000);

		axios
			.post(url.withdrawAutoNote(), copyInfo, config)
			.then((res) => {
				console.log(res.data.message);
				if (res.status === 200) {
					console.log(res.data.data);
					alarm.setup();
				}
			})
			.catch((e) => {
				console.log('confirmWithdraw error catch', e.message);
			});
	};

	const handleCloseWithdraw = () => {
		setWithdrawOpen(false);
	};

	const handleInput2FA = (e) => {
		if (e.target.value.length > 0) {
			const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
			copyLoginStatus[currentIndex].vfCode = e.target.value.trim();
			copyLoginStatus[currentIndex].is2FASendBtnDisabled = false;
			setLoginStatus(copyLoginStatus);
		} else {
			const copyLoginStatus = JSON.parse(JSON.stringify(loginStatus));
			copyLoginStatus[currentIndex].vfCode = null;
			copyLoginStatus[currentIndex].is2FASendBtnDisabled = true;
			setLoginStatus(copyLoginStatus);
		}
	};

	const goSearchTalents = () => {
		//search by word for filteredTalents
		const searchResultByWord = [];
		if (searchWord.trim().length > 0) {
			for (const [key, value] of Object.entries(resultData)) {
				const projectName = value.projectName ? value.projectName.toLowerCase() : '';
				const note = value.note ? value.note.toLowerCase() : '';
				const status = value.status ? value.status.name.toLowerCase() : '';
				const startTime = transformDateToLocalTZ(value.startTime);
				const withdrawTime = transformDateToLocalTZ(value.withdrawTime);

				if (
					projectName.includes(searchWord.trim().toLowerCase()) ||
					note.includes(searchWord.trim().toLowerCase()) ||
					status.includes(searchWord.trim().toLowerCase()) ||
					startTime.includes(searchWord.trim().toLowerCase()) ||
					withdrawTime.includes(searchWord.trim().toLowerCase())
				) {
					searchResultByWord.push(value);
				}
			}

			setFilteredData(searchResultByWord);
			setMainData(searchResultByWord.slice(0, recordPerPage));
		} else {
			setFilteredData(null);
			setMainData(resultData.slice(0, recordPerPage));
		}

		setPage(1);
	};

	const renderRows = (data) => {
		return data
			? data.map((each, idx) => {
					const {
						id,
						accountId,
						projectName,
						numberOfTalents,
						note,
						startTime,
						status,
						withdrawTime
					} = each;

					const start = transformDateToLocalTZ(startTime);
					const withdraw = transformDateToYearMonthDate(withdrawTime);
					const isWithdrawLater = checkWithdrawDateLaterThanToday(withdrawTime);

					return (
						<>
							<tr key={id}>
								<td style={{ width: '10%' }} className="text-left pl-3">
									{projectName}
								</td>
								<td className="text-left pl-5">
									<a
										onClick={(e) => handleClickAction(e, idx, each)}
										className="font-weight-bold text-info">
										{numberOfTalents}
									</a>
								</td>
								<td style={{ width: '40%' }} className="text-left pl-2 pr-2">
									<div
										style={{ height: 113, overflowY: 'auto' }}
										className="text-break d-flex align-items-center">
										<span>{note}</span>
									</div>
								</td>
								<td className="text-left pl-2">{start}</td>
								<td className="text-left pl-2">{status && status.name}</td>
								{/* <td className="text-left pl-2">{withdraw}</td>
								<td className="text-left pr-3">
									{status && status.id !== 4 && (
										<Button
											disabled={isWithdrawLater || !(status.id === 2)} //isWithdrawLater
											className={classes.containedPrimary}
											color="primary"
											variant="contained"
											onClick={(e) => handleWithdraw(e, idx, each)}>
											<span>Withdraw</span>
										</Button>
									)}
									{status && status.id === 4 && (
										<Box className={`MuiButtonBase-root MuiButton-root ${classes.withdrawPrimary}`}>
											<span>Withdrawing</span>
										</Box>
									)}
								</td> */}
							</tr>
						</>
					);
			  })
			: '';
	};

	const renderTalentRows = (data) => {
		return data
			? data.map((each, idx) => {
					const {
						id,
						name,
						companies,
						seniority,
						ociMsgStatusId,
						autoNoteStatus,
						withDrawStatus,
						talent
					} = each;

					return (
						<>
							<tr key={id} className="p-4">
								<td style={{ width: '10%' }} className="text-center pl-3">
									{withDrawStatus === 0 && autoNoteStatus.id === 1 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/NeedEmail.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 2 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/Name2Long.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 3 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/LimitReached.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 4 && (
										<Tooltip title={<div>{autoNoteStatus.name}</div>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/Pending.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 5 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/NoteSent.png`)}
											/>
										</Tooltip>
									)}
									{/* {withDrawStatus === 0 && autoNoteStatus.id === 6 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/Awaiting.png`)}
											/>
										</Tooltip>
									)} */}
									{withDrawStatus === 0 && autoNoteStatus.id === 7 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/WithdrawDone.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 8 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/invalid_url.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 9 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/timed_out.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 0 && autoNoteStatus.id === 10 && (
										<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
											<img
												className="autoNote img-20"
												src={require(`../../assets/images/apps/autoNote/InConnection.png`)}
											/>
										</Tooltip>
									)}
									{withDrawStatus === 1 &&
										(autoNoteStatus.id === 6 ? (
											<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
												<img
													className="autoNote img-20"
													src={require(`../../assets/images/apps/autoNote/Awaiting.png`)}
												/>
											</Tooltip>
										) : autoNoteStatus.id === 5 ? (
											<Tooltip title={<span>{autoNoteStatus.name}</span>} arrow placement="top">
												<img
													className="autoNote img-20"
													src={require(`../../assets/images/apps/autoNote/NoteSent.png`)}
												/>
											</Tooltip>
										) : (
											<Tooltip
												title={<span>Unconnected note is ready to be withdrawed</span>}
												arrow
												placement="top">
												<img
													className="autoNote img-20"
													src={require(`../../assets/images/apps/autoNote/Withdraw.png`)}
												/>
											</Tooltip>
										))}
								</td>
								<td className="text-left pl-2">{talent.name}</td>
								<td className="text-left pl-2">
									<div className="d-flex justify-content-end flex-column">
										{[...new Set(companies.map((each) => each.company))]}
									</div>
								</td>
								<td className="text-left pl-2 pr-3">{seniority.substring(4)}</td>
							</tr>
						</>
					);
			  })
			: '';
	};

	const loadCredentialAccounts = () => {
		trackPromise(
			get(url.fetchAutoNoteHistory()).then((res) => {
				console.log(res.data);

				setResultData(res.data);
				setMainData(res.data.slice(0, recordPerPage));

				initialize(res.data);
			})
		);
	};

	const initialize = (data) => {
		const init = [...Array(data.length)];
		init.fill({
			account: null,
			isLogin: false,

			is2FAShow: false,
			is2FASendBtnDisabled: true,
			vfCode: null,
			errorMsgShow: false,
			vfLoading: false,

			confirm: false,
			send: false,
			numberOfTalents: 0
		});
		setLoginStatus(init);

		const initLoginLoading = [...Array(data.length)];
		initLoginLoading.fill(false);
		setLoginLoading(initLoginLoading);

		const initVfLoading = [...Array(data.length)];
		initVfLoading.fill(false);
		setVfLoading(initVfLoading);

		const initConfirmLoading = [...Array(data.length)];
		initConfirmLoading.fill(false);
		setConfirmLoading(initConfirmLoading);

		const windows = {};
		for (const each of data) {
			const email = each.account;
			windows[email] = null;
		}
		setWindowsIds(windows);
	};

	useEffect(() => {
		loadCredentialAccounts();
	}, []);

	useEffect(() => {
		console.log('search...');
		goSearchTalents();
	}, [searchWord]);

	return (
		<>
			<Card className="card-box">
				<div className="card-header pr-2">
					<div className="card-header--title">
						<b>AutoNotes History</b>
					</div>
					<div
						className={clsx('search-wrapper search-wrapper--grow text-right pr-2 mb-1', {
							'is-active': false
						})}>
						<TextField
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								e.persist();
								setSearchWord(e.target.value);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchTwoToneIcon style={{ color: '#0795fe' }} />
									</InputAdornment>
								),
								type: 'search'
							}}
						/>
					</div>
				</div>
			</Card>
			<Card className="shadow-xxl p-3">
				<CardContent className="p-0">
					<div style={{ display: 'table', width: '100%' }} className="table-responsive-sm">
						<Table
							size="small"
							className="text-wrap mb-0 table-hover table-borderless font-size-sm">
							<thead className="thead">
								<tr>
									<th style={{ width: '10%' }} className="text-left pl-3">
										Project
									</th>
									<th className="text-left pl-2">Sent to List</th>
									<th style={{ width: '40%' }} className="pl-2">
										Note
									</th>
									<th className="text-left pl-2">Start Time</th>
									<th className="text-left pl-2">Sending Status</th>
									{/* <th className="text-left pl-2">Withdraw Alert</th>
									<th className="text-left pl-2 pr-3">Withdraw</th> */}
								</tr>
							</thead>
							{promiseInProgress ? (
								<></>
							) : (
								<tbody>{renderRows(mainData.slice(0, recordPerPage))}</tbody>
							)}
						</Table>
						{promiseInProgress && <LoadingIndicator />}
					</div>
					<div className="divider" />
					<div className="divider" />
					<div className="p-3 d-flex justify-content-center">
						<Pagination
							page={page}
							onChange={handleChangePage}
							className="pagination-first"
							size="small"
							count={
								filteredData
									? Math.ceil(Object.keys(filteredData).length / recordPerPage)
									: Math.ceil(Object.keys(resultData).length / recordPerPage)
							}
						/>
					</div>
				</CardContent>
			</Card>

			<Dialog
				classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
				fullWidth
				maxWidth="md"
				open={isOpen}
				onClose={() => setOpen(false)}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					<span>Sent to List</span>
				</DialogTitle>
				<DialogContent className={'p-4'}>
					<Card className="shadow-xxl p-3">
						<Table
							size="small"
							className="text-wrap mb-0 table-hover table-borderless font-size-md">
							<thead className="thead">
								<tr>
									<th className="text-left pl-3">Status</th>
									<th className="text-left pl-2">Name</th>
									<th className="text-left pl-2">Company</th>
									<th className="text-left pl-2 pr-3">Seniority</th>
								</tr>
							</thead>
							{talentsLoading ? <></> : <tbody>{renderTalentRows(talentsData)}</tbody>}
						</Table>
						{talentsLoading && <LoadingIndicator />}
					</Card>
				</DialogContent>
				<DialogActions className="p-4">
					<Button
						disabled={buttonFailDisabled}
						className={classes.containedPrimary}
						color="primary"
						variant="contained"
						onClick={handleSendToFail}>
						Send to Failed
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={isWindowAlertOpen} onClose={() => setWindowAlertOpen(false)}>
				<DialogContent>
					<DialogContentText>The system is busy. Please try again later.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className="btn-neutral-dark-blue active"
						onClick={() => setWindowAlertOpen(false)}
						color="primary"
						variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			{loginStatus[currentIndex] && loginStatus[currentIndex].account && (
				<Dialog
					classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
					fullWidth
					maxWidth="sm"
					open={isWithdrawOpen}
					onClose={handleCloseWithdraw}
					aria-labelledby="form-dialog-title">
					<DialogContent className={'p-4'}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card className="shadow-xxl p-3">
									<div className="mt-2 mb-2 rounded-sm">
										<span className="font-weight-bold text-black font-size-md">
											Linkedin sign in email or phone
										</span>
									</div>
									<div className="mb-3">
										<Grid container alignItems="center">
											<Grid item xs={4}>
												<span className="text-black font-weight-light font-size-md">
													{loginStatus[currentIndex].account.account}
												</span>
											</Grid>
											<Grid item xs={5} />
											<Grid item xs={3}>
												{/* <CircularProgress size={24} /> */}
												<Button
													fullWidth
													style={{ height: '30px' }}
													disabled={loginStatus[currentIndex].isLogin}
													className={classes.containedPrimary}
													onClick={handleLogin}>
													{isLoginLoading[currentIndex] && (
														<CircularProgress size={24} className={classes.withdrawBtnProgress} />
													)}
													Log in
												</Button>
											</Grid>
										</Grid>
									</div>
									{loginStatus[currentIndex].is2FAShow && (
										<>
											<div className="font-weight-bold text-black font-size-md mb-1">
												Enter the code you see on your phone, email or authenticator app
											</div>
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={4}>
													<input
														className="w-100"
														value={loginStatus[currentIndex].vfCode}
														onChange={(e) => {
															e.persist();
															handleInput2FA(e);
														}}
													/>
												</Grid>
												<Grid item xs={4}>
													<Button
														disabled={loginStatus[currentIndex].is2FASendBtnDisabled}
														style={{ height: '30px' }}
														className={classes.containedPrimary}
														onClick={handleSend2FA}>
														{vfLoading[currentIndex] && (
															<CircularProgress size={24} className={classes.withdrawBtnProgress} />
														)}
														Send
													</Button>
												</Grid>
												<Grid item xs={2} />
											</Grid>
										</>
									)}
									{loginStatus[currentIndex].errorMsgShow && (
										<div className="font-size-sm text-danger mt-2">
											Please enter the verification code again.
										</div>
									)}
									{loginStatus[currentIndex].isLogin && (
										<div className="mt-2">
											{action && action === 'WITHDRAW' && (
												<span className="text-primary">
													All SET! Your AutoNotes is ready to withdraw.
												</span>
											)}
											{action && action === 'SEND' && (
												<span className="text-primary">
													All SET! Your AutoNotes is ready to implement.
												</span>
											)}
										</div>
									)}
									<div className="mt-2">
										{action && action === 'WITHDRAW' && (
											<span className="text-black font-size-md">
												It takes about {Math.ceil((5 * numberOfTalentsProcessing) / 60)} minute(s)
												to run all withdraw tasks. Please check status of these tasks later.
											</span>
										)}
										{action && action === 'SEND' && (
											<span className="text-black font-size-md">
												It takes about{' '}
												{Math.ceil(
													(40 * numberOfTalentsProcessing +
														sleepTime * (numberOfTalentsProcessing - 1)) /
														60
												)}{' '}
												minute(s) to run all autonote tasks. Please check status of these tasks
												later.
											</span>
										)}
									</div>
									{loginStatus[currentIndex].isLogin && (
										<div className="mt-2">
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={4} />
												<Grid item xs={5} />
												<Grid item xs={3}>
													<Button
														fullWidth
														style={{ height: '30px' }}
														disabled={
															action && action === 'WITHDRAW'
																? loginStatus[currentIndex].confirm
																: loginStatus[currentIndex].send
														}
														className={classes.containedPrimary}
														onClick={() => {
															if (action === 'WITHDRAW') {
																confirmWithdraw();
															} else if (action === 'SEND') {
																confirmSendToFail();
															}
														}}>
														{confirmLoading[currentIndex] && (
															<CircularProgress size={24} className={classes.withdrawBtnProgress} />
														)}
														{action && action === 'WITHDRAW' && <span>Confirm</span>}
														{action && action === 'SEND' && <span>SEND</span>}
													</Button>
												</Grid>
											</Grid>
										</div>
									)}
								</Card>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}
