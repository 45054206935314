import { combineReducers } from 'redux';
import entities from './entities';
import dashboard from './dashboard';
import app from './app';
import ThemeOptions from './ThemeOptions';
import articleTab from './articleTab';
import overviewTab from './overviewTab';
import orgChartTab from './orgChartTab';
import recruitPage from './recruitPage';
import projectsPage from './projectsPage';
import custOrgChartTab from './custOrgChartTab';

// combine all reducers
const rootReducer = combineReducers({
	entities,
	dashboard,
	articleTab,
	app,
	ThemeOptions,
	overviewTab,
	orgChartTab,
	recruitPage,
	projectsPage,
	custOrgChartTab
});

export default rootReducer;
