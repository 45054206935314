import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as PropTypes from 'prop-types';
import Overview1 from '../Overview1';
import Overview2 from '../Overview2';
import Locations from '../Locations';
import OrgChart from '../OrgChart/index';
import CustOrgChart from '../CustOrgChart';
import NewsRumors from '../News&Numors';
import Articles from '../Articles';
import ExecutiveSummary from '../ExecutiveSummary';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

export default function OrgTabs({
	jump,
	orgChartInfo,
	custOrgChartInfo,
	tabIndex,
	articlesList,
	article,
	setActiveArticle,
	cancelActiveArticle,
	orgChart,
	orgCustOrgChart,
	statistics,
	subscribe,
	subscribeCust,
	getTopLeader,
	getCustTopLeader,
	getLevel,
	getCustLevel,
	followDepartment,
	followCustDepartment,
	getFollowStatus,
	getCustFollowStatus,
	orgId
}) {
	const [value, setValue] = useState(jump ? jump : tabIndex);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	console.log('orgtabs: ');
	useEffect(() => {
		if (jump) {
			setValue(jump);
		}
	}, [jump]);

	useEffect(() => {
		if (value !== 2) {
			cancelActiveArticle();
		}
	}, [value]);

	return (
		<>
			<Grid container spacing={6} direction={'column'}>
				<Card className="card-box">
					<div className="p-4">
						<Tabs className="nav-tabs-primary" value={value} onChange={handleChange}>
							<Tab label="Overview" />
							<Tab label="Org Chart" />
							{/* <Tab label="News & Rumors" /> */}
							<Tab label="Customized Content" />
						</Tabs>
					</div>

					<div className="p-4 pt-0">
						<TabPanel value={value} index={0}>
							<Overview1 statistics={statistics} orgId={orgId} />
							<Overview2 statistics={statistics} />
							<Locations statistics={statistics} />
						</TabPanel>
						<TabPanel value={value} index={1}>
							{/*Org Chart*/}
							<OrgChart
								orgId={orgId}
								orgChartInfo={orgChartInfo}
								orgChart={orgChart}
								subscribe={subscribe}
								getLevel={getLevel}
								getTopLeader={getTopLeader}
								followDepartment={followDepartment}
								getFollowStatus={getFollowStatus}
							/>
						</TabPanel>
						{/* <TabPanel value={value} index={2}>
							{article ? (
								<Articles article={article} cancelActiveArticle={cancelActiveArticle} />
							) : (
								<NewsRumors articlesList={articlesList} setActiveArticle={setActiveArticle} />
							)}
						</TabPanel> */}
						<TabPanel value={value} index={2}>
							<CustOrgChart
								orgId={orgId}
								custOrgChartInfo={custOrgChartInfo}
								orgCustOrgChart={orgCustOrgChart}
								subscribeCust={subscribeCust}
								getCustLevel={getCustLevel}
								getCustTopLeader={getCustTopLeader}
								followCustDepartment={followCustDepartment}
								getCustFollowStatus={getCustFollowStatus}
							/>
						</TabPanel>
					</div>
				</Card>
			</Grid>
		</>
	);
}
