import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { green, grey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import Portal from '@material-ui/core/Portal';
import Slide from '@material-ui/core/Slide';
import Slider from '@material-ui/core/Slider';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import https from 'https';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import sessionstorage from 'sessionstorage';
import styled from 'styled-components';
import { removeHtmlTag } from '../../../../utils/emailTemplateUtil';
import memory from '../../../../utils/memory';
import {
	checkScheduleTime,
	enumEmailType,
	handleScheduleTime,
	updateUsageActivity,
	usageTraceLabels,
	skillInterpretation,
	toBooleanSkills,
	fromBooleanSkills,
	skillGenerateSQL,
	addSkill,
	removeSkill,
	reverseSkillGenerateSQL,
	reverseSkillInterpretation
} from '../../../../utils/recruitUtil';
import { get, post } from '../../../../utils/request';
import storage from '../../../../utils/storage';
import url from '../../../../utils/url';
import Drawer from '@material-ui/core/Drawer';

import RecruitFilter from '../RecruitFilter';
import RecruitHeader from '../RecruitHeader';
import RecruitList from '../RecruitTable';
import TalentDialog from '../../../Common/TalentDialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import { BsSlashCircleFill } from 'react-icons/bs';
import { IoMdCreate } from 'react-icons/io';
import { IoMdAddCircle } from 'react-icons/io';
import Chip from '@material-ui/core/Chip';
import { ActionLabel, ActionType, EmailSequenceType } from '../../../../utils/message';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	containedPrimary: {
		backgroundColor: '#0795fe',
		'&:hover': {
			backgroundColor: '#0795fe'
		},
		color: '#FFFFFF'
	},
	exclusivePrimary: {
		backgroundColor: '#dc004e',
		'&:hover': {
			backgroundColor: '#dc004e'
		},
		color: '#FFFFFF'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		color: '#4191ff',
		textAlign: 'right'
	},
	emailButton: {
		color: '#4191ff',
		textAlign: 'left'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	},
	uploadButtonProgress: {
		color: '#0795fe'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	achievement: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap'
	},
	premium: {
		backgroundColor: '#0795fe',
		borderRadius: '0.29rem !important'
	},
	radioGroup: {
		padding: '0.8px'
	},
	addSkillOperatorOn: {
		fontSize: '1.7em',
		color: '#3E96F6'
	},
	addSkillOperatorOff: {
		fontSize: '1.7em',
		color: '#D9D9D9'
	},
	orSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	orSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	},
	editSkillOperatorOn: {
		fontSize: '1.38em',
		color: '#3E96F6'
	},
	editSkillOperatorOff: {
		fontSize: '1.38em',
		color: '#D9D9D9'
	},
	chipRoot: {
		'& .MuiChip-icon': {
			order: 1, // the label has a default order of 0, so this icon goes after the label
			marginRight: '10px', // add some space between icon and delete icon
			cursor: 'pointer'
		},
		'& .MuiChip-deleteIcon': {
			order: 2 // since this is greater than an order of 1, it goes after the icon
		}
	}
}));

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const TIMERIMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
`;

const TITLEIMG = styled.img`
	width: 30px;
	height: auto;
`;

const config = {
	withCredentials: true,
	httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const labels = usageTraceLabels();
const _status = enumEmailType();

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RecruitTable({ projects, loadProjects, positions }) {
	const classes = useStyles();

	const [search, setSearch] = useState({
		currentCompany: [],
		location: [],
		openToOpportunities: [],
		achievement: [],
		stacks: [],
		yearsOfWorkingExperiences: [0, 100],
		entrepreneurship: [],
		seniority: [],
		currTenure: [],
		avgTenure: [],
		companies: { type: 'OR', options: [] },
		degree: [],
		preferredLocation: [],
		department_2: [],
		institute: [],
		diversity: [],
		language: [],
		skill: '',
		industry: { type: 'OR', options: [] },
		positionType: []
	});

	const [state, setState] = React.useState({
		company: '',
		location: '',
		approach: 'false',
		department_1: '',
		department_2: ''
	});

	const [resultData, setResultData] = useState([]);
	const [mainData, setMainData] = useState(null);

	const [filteredData, setFilteredData] = useState(null);

	const [filterSelects, setFilterSelect] = useState({
		Area: [],
		Company: [],
		Department: [],
		Seniority: [],
		Stack: [],
		YOE: [],
		Language: [],
		Attitude: [],
		Achievement: [],
		Entrepreneurship: [],
		AvgTenure: [],
		CurrentTenure: [],
		PreferredLocation: [],
		Diversity: [],
		Skill: [],
		Institute: [],
		Degree: [],
		Industry: [],
		PositionType: []
	});
	const [skills, setSkills] = useState([]);
	const [booleanSkills, setBooleanSkills] = useState([]);

	const [compDepMapping, setCompDepartMapping] = useState(null);
	const [positionTypeStackMapping, setPositionTypeStackMapping] = useState(null);
	const [isPremiumOpen, setPremiumOpen] = useState(false);
	const { promiseInProgress } = usePromiseTracker();
	const premium = storage.getCustomer().premium;
	const history = useHistory();
	const [uploadFile, setUploadFile] = useState({
		uploadFileName: null,
		uploadFilePath: null,
		uploadFileSize: 0,
		uploadFileFullName: null
	});

	const [activeTab, setActiveTab] = useState(0);
	const [activeActionTab, setActiveActionTab] = useState('4');
	const [open, setOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [ithRow, setIthRow] = useState(0);

	const [checkers, setCheckers] = useState({});
	const [checkersPerPage, setCheckersPerPage] = useState([]);
	const [numberOfSelected, setNumberOfSelected] = useState(0);
	const [checkersData, setCheckersData] = useState([]);
	const [checkboxPerPage, setCheckboxPerPage] = useState([]);

	const [folders, setFolders] = useState([]);
	const [folderSelection, SetFolderSelection] = useState(null);
	const [isFolderUpdate, setFolderUpdate] = useState(false);
	const [insertArray, setInsertArray] = useState([]);
	const [subtractArray, setSubtractArray] = useState([]);

	const [advancedSearchOpen, setAdvancedSearchOpen] = useState(true);
	const [isLoading, setLoading] = useState(false);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const fileRef = useRef(); //used to get updated file value in useCallback function
	const [file, setFile] = useState({
		filename: [],
		path: []
	});
	fileRef.current = file;

	const [isOppSendDisabled, setOppSendDisabled] = useState(true);
	const [isFilterSearchDisabled, setFilterSearchDisabled] = useState(true);

	//Filter
	const [companyFilterType, setCompanyFilterType] = useState('OR');
	const [skillFilterType, setSkillFilterType] = useState('AND');
	const [skillEditType, setSkillEditType] = useState('OFF');

	const [industryFilterType, setIndustryFilterType] = useState('OR');
	const [searchOpen, setSearchOpen] = useState(false);

	//Exclusive
	const [stackItemExclusive, setStackItemExclusive] = useState([]);
	const [stackExclusive, setStackExclusive] = useState(false);
	const [companyExclusive, setCompanyExclusive] = useState(false);

	//Pagination
	const [pageCount, setPageCount] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const rowCount = 25;

	//Template
	const [templates, setTemplates] = useState(null);
	const [templateId, setTemplateId] = useState(null);
	const [isTemplateExpand, setTemplateExpand] = useState(false);
	const [isContextEmpty, setContextEmpty] = useState(true);
	const [templateName, setTemplateName] = useState('');
	const [templateNameMsg, setTemplateNameMsg] = useState('');
	const [snackOpen, setSnackOpen] = useState(false);
	const [isTemplateConfirm, setTemplateConfirm] = useState(false);
	const [isTemplateDuplicate, setTemplateDuplicate] = useState(false);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [templateType, setTemplateType] = useState('');

	//save new & existing project
	const [newProject, setNewProject] = useState({ open: false, projectName: null });
	const [existingProject, setExistingProject] = useState({ open: false, projectName: null });
	//Existing project
	const [folderTalents, setFolderTalents] = useState([]);
	const [existingTags, setExistingTags] = useState([]);
	const [talentsProjectMapping, setTalentsProjectMapping] = useState([]);
	//redirect to projects page
	const [isRedirect, setRedirect] = useState(false);

	// upload file
	const [myFiles, setMyFiles] = useState([]); //used to remove file

	// set boolean seach text input
	const [isKwEditing, setIsKwEditing] = useState(false);
	// useEffect(() => {
	// 	if (isKwEditing) {
	// 		let interpretedSkills = skillInterpretation(skills);
	// 		let updatedBooleanSkills = toBooleanSkills(interpretedSkills);
	// 		setBooleanSkills(updatedBooleanSkills);
	// 	}
	// }, [isKwEditing, skills]);

	const handleEditButtonClick = () => {
		setIsKwEditing(!isKwEditing);
	};

	const bytesToMegaBytes = (bytes, roundTo) =>
		roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);

	const removeFile = (f) => () => {
		const newFiles = [...myFiles];
		const index = newFiles.indexOf(f);

		newFiles.splice(index, 1);
		setMyFiles(newFiles);

		file.filename.splice(index, 1);
		file.path.splice(index, 1);

		const newSize = Number(sessionstorage.getItem('totalSize')) - f.size;
		sessionstorage.setItem('totalSize', newSize);

		setFile({
			//related send email
			filename: file.filename,
			path: file.path
		});

		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
		} else {
			fileRejections.splice(0, fileRejections.length);
		}
		forceUpdate();
	};

	const accumulate = (file) => {
		console.log('file size', file.size);
		const MBformat = bytesToMegaBytes(file.size, 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			return {
				message: `Single file size cannot be larger than ${limit} MB`
			};
		}

		if (!sessionstorage.getItem('totalSize')) {
			sessionstorage.setItem('totalSize', file.size);
		} else {
			const newSize = Number(sessionstorage.getItem('totalSize')) + file.size;
			sessionstorage.setItem('totalSize', newSize);
		}

		return null;
	};

	const onDropAccepted = useCallback(
		(acceptedFiles) => {
			console.log(acceptedFiles);
			setLoading(true);

			let attachment = new FormData();

			console.log('user', memory.user);
			let email = memory.user.email.trim();

			let files = [];
			for (let i = 0; i < acceptedFiles.length; i++) {
				attachment.append('uploadFiles', acceptedFiles[i]);
				files.push(`${email}/${acceptedFiles[i].name}`);
			}

			const options = {
				params: {
					Keys: files,
					ContentType: 'application/x-www-form-urlencoded'
				},
				headers: {
					ContentType: 'application/x-www-form-urlencoded'
				}
			};

			//get preSign URL
			// const urls = await axios
			// 	.post(url.fetchPresignedURL(), attachment, options)
			// 	.then((res, rej) => {
			// 		console.log('fetch Presigned url', res);
			// 		return res.data.data;
			// 	})
			// 	.catch((err) => {
			// 		console.log('fetchPresignedURL error', err);
			// 	});
			// const urls = await axios
			// 	.post(
			// 		url.fetchPresignedURL(),
			// 		{
			// 			filesName: files
			// 		},
			// 		{ withCredentials: true, contentType: 'application/json' }
			// 	)
			// 	.then((res) => {
			// 		console.log('fetch Presigned url', res);
			// 		return res.data.data;
			// 	})
			// 	.catch((e) => console.log('fetchPresignedURL error', e));

			// await axios
			// 	.put(urls[0], acceptedFiles[0], options)
			// 	.then(() => {
			// 		let filenames = [];
			// 		let paths = [];
			// 		acceptedFiles.forEach((file) => {
			// 			filenames.push(file.name);
			// 			paths.push(`${email}/${file.name}`);
			// 		});

			// 		const newFileName = [...fileRef.current.filename, filenames];
			// 		const newPath = [...fileRef.current.path, paths];

			// 		acceptedFiles.forEach((file) => {
			// 			myFiles.push(file);
			// 		});
			// 		setMyFiles(myFiles);

			// 		setFile({
			// 			filename: newFileName.flat(),
			// 			path: newPath.flat()
			// 		});

			// 		setLoading(false);
			// 	})
			// 	.catch((e) => console.log(e));

			axios //TODO original way to upload files
				.post(url.uploadAttachment(), attachment, options)
				.then(() => {
					let filenames = [];
					let paths = [];
					acceptedFiles.forEach((file) => {
						filenames.push(file.name);
						paths.push(`${email}/${file.name}`);
					});

					const newFileName = [...fileRef.current.filename, filenames];
					const newPath = [...fileRef.current.path, paths];

					acceptedFiles.forEach((file) => {
						myFiles.push(file);
					});
					setMyFiles(myFiles);

					setFile({
						filename: newFileName.flat(),
						path: newPath.flat()
					});

					setLoading(false);
				})
				.catch((e) => console.log(e));
		},
		[myFiles, file]
	);

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		fileRejections,
		acceptedFiles,
		getRootProps,
		getInputProps
	} = useDropzone({
		onDropAccepted,
		accept: ['.pdf', '.docx', '.doc', '.rtf', '.pptx', '.ppt'],
		validator: accumulate
	});

	let fileRejectionItems = fileRejections.map(({ errors }) => (
		<>
			{errors.map((e) => (
				<Alert severity="warning" className="text-center mb-3">
					{e.message}
				</Alert>
			))}
		</>
	));

	const validateFiles = () => {
		const MBformat = bytesToMegaBytes(Number(sessionstorage.getItem('totalSize')), 2);
		const limit = 5;
		if (Number(MBformat) > limit) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Total files size is larger than ${limit} MB` }] });
			forceUpdate();
			return false;
		}

		if (myFiles.length > 5) {
			fileRejections.splice(0, fileRejections.length);
			fileRejections.push({ errors: [{ message: `Maximum number of files upload: 5` }] });
			forceUpdate();
			return false;
		}

		return true;
	};

	let files = myFiles.map((file) => (
		<ListItem
			className="font-size-sm px-3 py-2 text-primary d-flex justify-content-start align-items-center"
			key={file.path}>
			<span>
				<span
					className="badge badge-pill bg-neutral-danger text-primary mr-2"
					onClick={removeFile(file)}>
					X
				</span>
				{file.path}
			</span>
			<span className="badge badge-pill bg-neutral-warning text-primary">
				{bytesToMegaBytes(file.size, 2)} MB
			</span>
		</ListItem>
	));

	//Save new project
	const handleSaveNewProject = async (redirect) => {
		let talentCheckedIds = [];
		let selectedTalents = [];

		if (filteredData && filteredData !== undefined) {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.filter(([_, value]) => filteredData.includes(value))
				.map(([talentId, _]) => parseInt(talentId));

			selectedTalents = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.filter(([_, value]) => filteredData.includes(value))
				.map(([_, value]) => value);
		} else {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.map(([talentId, _]) => parseInt(talentId));

			selectedTalents = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.map(([_, value]) => value);
		}

		axios
			.post(
				url.saveNewProject(),
				{
					projectName: newProject.projectName,
					talentCheckedIds: talentCheckedIds,
					selectedTalentsData: selectedTalents,
					filters: search,
					position: selectedPosition,
					uploadFile: uploadFile.uploadFileName
						? {
								uploadFilePath: uploadFile.uploadFilePath,
								uploadFileName: uploadFile.uploadFileName,
								uploadFileSize: uploadFile.uploadFileSize
						  }
						: null
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);
				const tag = res.data.data;

				for (const talent of resultData) {
					for (const talentSelected of checkersData) {
						if (talent.id === talentSelected.id) {
							talent['tags'].push(tag);
							break;
						}
					}
				}

				const local = resultData;
				setMainData(local.slice(page * rowCount - rowCount, page * rowCount));
				loadProjects();
			})
			.catch((e) => console.error('Save new project has error', e));

		setNewProject({ open: false, projectName: null });
		//setCheckers({});
		//setCheckersData([]);
		//setNumberOfSelected(0);

		if (redirect) history.push('/savedprojects');
	};

	const handleNewProjectTextInput = (e) => {
		setNewProject({ ...newProject, projectName: e.target.value });
	};

	//Save existing project
	const handleFolderSelect = (event, values) => {
		const selectedProjects = values;
		const insert = [];

		//Save project const
		let talentsProjectMapping = {};
		for (const selectedTalent of checkersData) {
			talentsProjectMapping[selectedTalent.id] = [];

			for (const selectedProject of selectedProjects) {
				const assignedProjects = selectedTalent.tags;
				const talentId = selectedTalent.id;
				const assignedProjectsIds = assignedProjects.map((item) => item.id);

				if (!assignedProjectsIds.includes(selectedProject.id)) {
					const project = folderSelection.find((project) => project.id === selectedProject.id);
					insert.push({
						tagId: project.id,
						talentId
					});
					talentsProjectMapping[selectedTalent.id].push(selectedProject);
				}
			}
		}

		setFolderTalents(insert);

		//update selected project
		setExistingTags(selectedProjects);

		//update selected project which those talents did not have before
		setTalentsProjectMapping(talentsProjectMapping);
	};

	const handleSaveExistingProjects = async (redirect) => {
		let talentCheckedIds = [];

		if (filteredData && filteredData !== undefined) {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.filter(([_, value]) => filteredData.includes(value))
				.map(([talentId, _]) => parseInt(talentId));
		} else {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.map(([talentId, _]) => parseInt(talentId));
		}

		axios
			.post(
				url.saveExistingProjects(),
				{
					tagIds: existingTags.map((tag) => tag.id),
					talentIds: talentCheckedIds,
					currFilter: search
				},
				config
			)
			.then((res) => {
				console.log(res.data.message);

				for (const talent of resultData) {
					for (const talentSelected of checkersData) {
						if (talent.id === talentSelected.id) {
							for (const tag of talentsProjectMapping[talent.id]) {
								talent['tags'].push(tag);
							}
							break;
						}
					}
				}

				const local = resultData;
				setMainData(local.slice(page * rowCount - rowCount, page * rowCount));
			})
			.catch((e) => console.error('Save existing project has error', e));

		setExistingProject({ open: false, projectName: null });
		setExistingTags([]);

		if (redirect) history.push('/savedprojects');
	};

	const handleUpdateProjects = async () => {
		const talentId = selectedRow.id;
		setAutoLoading(true);

		if (subtractArray.length > 0) {
			post(url.cancelAssignProjects(), subtractArray).then((res) => console.log(res));
		}

		if (insertArray.length > 0) {
			await axios
				.post(
					url.addTalentToTalentPools(),
					{
						tagIds: insertArray,
						talentId: talentId,
						currFilter: search
					},
					config
				)
				.then((res) => {
					console.log(res.data.message);
				})
				.catch((e) => console.error('Change folders for the given talent has error', e));
		}

		handleClose();
		setAutoLoading(false);
	};

	//TODO status, irsstatus filter check all
	const handleCheckAllAction = () => {
		const talentCheckedTalents = Object.entries(checkers).filter(
			([_, value]) => typeof value !== 'undefined'
		);

		if (talentCheckedTalents.length < resultData.length) {
			checkAllTalents();
			return;
		}

		unCheckAllTalents();
	};

	const handleFilterCheckAllAction = () => {
		const talentCheckedTalents = Object.values(checkers).filter(
			(each) => typeof each !== 'undefined'
		);
		const linkedins = talentCheckedTalents.map((each) => each.linkedin);
		const checkedCount = filteredData.filter((each) => linkedins.includes(each.linkedin)).length;

		if (checkedCount < filteredData.length) {
			checkAllFilteredTalents();
			return;
		}

		uncheckAllFilteredTalents();
	};

	const checkAllTalents = () => {
		const arrayInit = [...Array(pageCount)];
		arrayInit.fill({});
		let finalLocal = {};
		for (let i = 1; i <= pageCount; i++) {
			const talents = resultData.slice(i * rowCount - rowCount, i * rowCount);
			let local = arrayInit[i - 1];
			talents.map((each) => {
				local[each.id] = each;
				finalLocal[each.id] = each;
			});
		}
		setCheckersPerPage(arrayInit);
		setCheckers({ ...checkers, ...finalLocal });

		const checkboxInit = [...Array(pageCount)];
		checkboxInit.fill(true);
		setCheckboxPerPage(checkboxInit);

		setNumberOfSelected(resultData.length);
	};

	const unCheckAllTalents = () => {
		const arrayInit = [...Array(pageCount)];
		arrayInit.fill({});
		setCheckersPerPage(arrayInit);

		const checkboxInit = [...Array(pageCount)];
		checkboxInit.fill(false);
		setCheckboxPerPage(checkboxInit);

		setCheckers({});
		setNumberOfSelected(0);
	};

	const checkAllFilteredTalents = () => {
		let finalLocal = {};
		for (let i = 1; i <= pageCount; i++) {
			const talentsCurrPage = filteredData.slice(i * rowCount - rowCount, i * rowCount);
			talentsCurrPage.map((each) => {
				finalLocal[each.id] = each;
			});

			//filterChechers per page
			const currCheckers = checkersPerPage[i - 1];
			const copyCurrCheckers = { ...currCheckers };
			talentsCurrPage.map((each) => (copyCurrCheckers[each.id] = each)); //possibly the current talent is not current page before filter
			checkersPerPage.splice(i - 1, 1, copyCurrCheckers);
		}
		setCheckersPerPage(checkersPerPage);

		setCheckers({ ...checkers, ...finalLocal });

		const checkboxInit = [...Array(pageCount)];
		checkboxInit.fill(true);
		setCheckboxPerPage(checkboxInit);

		setNumberOfSelected(filteredData.length);
	};

	const uncheckAllFilteredTalents = () => {
		let finalLocal = {};
		for (let i = 1; i <= pageCount; i++) {
			const talentsCurrPage = filteredData.slice(i * rowCount - rowCount, i * rowCount);
			talentsCurrPage.map((each) => {
				finalLocal[each.id] = undefined;
			});

			//filterChechers per page
			const currCheckers = checkersPerPage[i - 1];
			const copyCurrCheckers = { ...currCheckers };
			talentsCurrPage.map((each) => (copyCurrCheckers[each.id] = undefined)); //possibly the current talent is not current page before filter
			checkersPerPage.splice(i - 1, 1, copyCurrCheckers);
		}
		setCheckersPerPage(checkersPerPage);

		setCheckers({ ...checkers, ...finalLocal });

		const checkboxInit = [...Array(pageCount)];
		checkboxInit.fill(false);
		setCheckboxPerPage(checkboxInit);

		const filteredCheckers = {};
		filteredData.map((each) => (filteredCheckers[each.id] = undefined));
		setCheckers({ ...checkers, ...filteredCheckers });
		setNumberOfSelected(0);
	};

	const handleCheckAllActionPage = () => {
		const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));

		let talentCheckedIds = [];
		if (filteredData && filteredData !== undefined) {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.filter(([_, value]) => filteredData.includes(value))
				.map(([talentId, _]) => parseInt(talentId));
		} else {
			talentCheckedIds = Object.entries(checkers)
				.filter(([_, value]) => value !== undefined)
				.map(([talentId, _]) => parseInt(talentId));
		}

		const set = new Set(talentCheckedIds);
		let cnt = 0;
		for (const talent of mainData) {
			if (set.has(talent.id)) {
				cnt++;
			}
		}
		const otherPageNumberOfSelected = numberOfSelected - cnt < 0 ? 0 : numberOfSelected - cnt;

		if (isEmpty(checkersPerPage)) {
			let local = {};
			mainData.map((each) => (local[each.id] = each));
			setCheckers({ ...checkers, ...local });

			//checkers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = each));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = otherPageNumberOfSelected + mainData.length;
			setNumberOfSelected(totalNumberOfSelected);

			copyCheckboxPerPage[page - 1] = true;
			setCheckboxPerPage(copyCheckboxPerPage);
		} else {
			let local = {};
			mainData.map((each) => (local[each.id] = undefined));
			setCheckers({ ...checkers, ...local });

			//checkers per page
			const currCheckers = checkersPerPage[page - 1];
			const copyCurrCheckers = { ...currCheckers };
			mainData.map((each) => (copyCurrCheckers[each.id] = undefined));
			checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
			setCheckersPerPage(checkersPerPage);

			const totalNumberOfSelected = numberOfSelected - mainData.length;
			setNumberOfSelected(totalNumberOfSelected);

			copyCheckboxPerPage[page - 1] = false;
			setCheckboxPerPage(copyCheckboxPerPage);
		}
	};

	const handleCheckTalent = (id, each) => {
		setCheckers((prevState) => ({ ...prevState, [id]: each }));

		//checkers per page
		const currCheckers = checkersPerPage[page - 1];
		const copyCurrCheckers = { ...currCheckers };
		copyCurrCheckers[id] = each;
		checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
		setCheckersPerPage(checkersPerPage);

		setNumberOfSelected(numberOfSelected + 1);
		setCheckersData([...checkersData, each]);

		//check current page all selected
		let count = 0;
		for (const each of mainData) {
			if (copyCurrCheckers[each.id] !== undefined) {
				count++;
			}
		}
		if (count === mainData.length) {
			const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));
			copyCheckboxPerPage[page - 1] = true;
			setCheckboxPerPage(copyCheckboxPerPage);
		}
	};

	const handleUnCheckTalent = (id, each) => {
		setCheckers((prevState) => ({ ...prevState, [id]: undefined }));

		//checkers per page
		const currCheckers = checkersPerPage[page - 1];
		const copyCurrCheckers = { ...currCheckers };
		copyCurrCheckers[id] = undefined;
		checkersPerPage.splice(page - 1, 1, copyCurrCheckers);
		setCheckersPerPage(checkersPerPage);

		setNumberOfSelected(numberOfSelected - 1);
		const index = checkersData.findIndex((item) => item === each);
		checkersData.splice(index, 1);
		setCheckersData(checkersData);

		const copyCheckboxPerPage = JSON.parse(JSON.stringify(checkboxPerPage));
		copyCheckboxPerPage[page - 1] = false;
		setCheckboxPerPage(copyCheckboxPerPage);
	};
	/******************************************************* Search Start *********************************************/

	const goSearchTalents = async (filters, page) => {
		console.log('enter go search talents test....');
		trackPromise(
			axios
				.post(
					url.recruitAvailable(),
					{
						data: {
							offset: page * rowCount - rowCount,
							rowCount: rowCount,
							nickQuery: memory.user.email === 'nick.bieler@ocbang.com' ? true : false,
							...filters
						},
						projectEdit: false
					},
					config
				)
				.then(
					(res) => {
						console.log('fetch recruit data', res.data);
						const result = res.data.data.result;
						const pageCount = res.data.data.pageCount;
						const totalCount = res.data.data.totalCount;
						setResultData(result);
						setPage(1);
						const local = result;
						setMainData(local.slice(0, rowCount));
						setTotalCount(totalCount);
						setPageCount(pageCount);

						const arrayInit = [...Array(pageCount)];
						arrayInit.fill({});
						setCheckersPerPage(arrayInit);

						const checkboxInit = [...Array(pageCount)];
						checkboxInit.fill(false);
						setCheckboxPerPage(checkboxInit);

						setFilterOptions(searchResultOptions(result));

						return res;
					},
					(error) => {
						console.error('fetch recruit data error', error);
					}
				)
				.catch((e) => {
					console.error('fetch recruit data error catch', e.message);
					return Promise.reject(e.message);
				})
		);
	};

	useEffect(() => {
		console.log('来了来了');
		const savedFilters = localStorage.getItem('savedFilters');
		console.log('来看看filters ', savedFilters);

		if (savedFilters) {
			const filters = JSON.parse(savedFilters);
			setSearch(filters);
			setFilterSearchDisabled(false);
			let tagValue = filters.skill || '';
			if (tagValue !== '') {
				console.log(tagValue);
				let interpretedSkills = reverseSkillGenerateSQL(tagValue);
				let updatedBooleanSkills = toBooleanSkills(interpretedSkills);

				let localSkill = reverseSkillInterpretation(interpretedSkills);

				setSkills(localSkill);
				setBooleanSkills(updatedBooleanSkills);
			}
		}
	}, []);

	const handleClearFilters = () => {
		// reset filters status
		setSearch({
			currentCompany: [],
			location: [],
			openToOpportunities: [],
			achievement: [],
			stacks: [],
			yearsOfWorkingExperiences: [0, 100],
			entrepreneurship: [],
			seniority: [],
			currTenure: [],
			avgTenure: [],
			companies: { type: 'OR', options: [] },
			degree: [],
			preferredLocation: [],
			department_2: [],
			institute: [],
			diversity: [],
			language: [],
			skill: '',
			industry: { type: 'OR', options: [] },
			positionType: []
		});

		setSkills([]);
		setBooleanSkills(['']);

		// remove filters from local storage
		localStorage.removeItem('savedFilters');

		setFilterSearchDisabled(true);
	};

	const handleAdvancedSearch = (e, value, name) => {
		console.log('handle search....');

		let filters;
		if (name === 'companies') {
			setSearch({
				...search,
				[name]: { type: companyFilterType, options: value },
				department_2: []
			});
			filters = {
				...search,
				[name]: { type: companyFilterType, options: value },
				department_2: []
			};

			//dynamic load department
			let departmentList = [];
			for (const company of value) {
				const key = company.key;

				if (company.exclusive) {
					continue;
				}

				if (compDepMapping[key] && typeof compDepMapping[key] !== 'undefined') {
					const list = compDepMapping[key];
					departmentList = [...departmentList, ...list];
				}
			}
			setFilterSelect({ ...filterSelects, Department: departmentList });
		} else if (name === 'skill') {
			setSearch({
				...search,
				// Give SQL query string here
				[name]: value && typeof value !== 'undefined' ? value.trim() : ''
			});
			filters = { ...search, [name]: value && value !== undefined ? value.trim() : '' };
		} else if (name === 'industry') {
			setSearch({
				...search,
				[name]: { type: industryFilterType, options: value }
			});
			filters = { ...search, [name]: { type: industryFilterType, options: value } };
		} else if (name === 'positionType') {
			setSearch({
				...search,
				[name]: value
			});
			filters = { ...search, [name]: value };

			//dynamic load stack
			let stackList = [];
			for (const position of value) {
				const key = position.key;
				const list = positionTypeStackMapping[key];
				stackList = [...stackList, ...list];
			}
			console.log('--->', stackList);

			setFilterSelect({ ...filterSelects, Stack: stackList });

			const selectedStacks = search.stacks;

			console.log('--->', selectedStacks);

			const validStacks = selectedStacks.filter((stack) =>
				stackList.some((option) => option.key === stack.key)
			);

			// // 清除不再属于剩余选中的Position Types的Stacks
			// const remainingStacks = search.stacks.filter(stack => newStackList.includes(stack.key));

			// 更新搜索条件，保留有效的Stacks
			setSearch((prev) => ({
				...prev,
				stacks: validStacks
			}));
		} else if (name === 'stacks') {
			// 更新Stacks的选择
			setSearch({
				...search,
				[name]: value
			});
			filters = { ...search, [name]: value };
		} else {
			setSearch({
				...search,
				[name]: value
			});
			filters = { ...search, [name]: value };
		}

		let cnt = 0;
		for (const [field, values] of Object.entries(filters)) {
			if (field === 'companies' || field === 'industry') {
				if (values.options.length > 0) cnt++;
			} else {
				if (values.length > 0) cnt++;
			}
		}
		if (cnt > 0) {
			setFilterSearchDisabled(false);
		} else {
			setFilterSearchDisabled(true);
		}

		localStorage.setItem('savedFilters', JSON.stringify(search));
	};

	useEffect(() => {
		// 注意：这里使用JSON.stringify来序列化search状态，因为localStorage只能存储字符串
		localStorage.setItem('savedFilters', JSON.stringify(search));
	}, [search]); // 这个依赖数组确保了只有当search状态变化时，才执行这个effect

	const handleSearch = () => {
		let cnt = 0;
		for (const [field, values] of Object.entries(search)) {
			if (field === 'companies' || field === 'industry') {
				if (values.options.length > 0) cnt++;
			} else {
				if (values.length > 0) cnt++;
			}
		}

		setResultData([]);
		setMainData([]);
		setPage(1);
		setTotalCount(0);
		setPageCount(0);
		setCheckers({});
		setCheckersData([]);
		setNumberOfSelected(0);

		if (cnt > 0) {
			setSearchOpen(false);

			updateUsageActivity(labels.search);

			goSearchTalents(search, 1);
		} else {
			setSearchOpen(true);
		}
	};

	const handleExclusive = (name, item) => {
		if (name === 'stacks') {
			const selectedItems = JSON.parse(JSON.stringify(search[name]));
			for (let i = 0; i < selectedItems.length; i++) {
				const selectedItem = selectedItems[i];
				if (selectedItem.id === item.id) {
					if (typeof selectedItem.exclusive === 'undefined' || !selectedItem.exclusive) {
						selectedItem.exclusive = true;
					} else {
						selectedItem.exclusive = false;
					}
				}
			}

			setSearch({
				...search,
				[name]: selectedItems
			});
		} else if (name === 'companies') {
			const inclusiveItems = [];
			const selectedItems = JSON.parse(JSON.stringify(search[name].options));
			for (let i = 0; i < selectedItems.length; i++) {
				const selectedItem = selectedItems[i];
				if (selectedItem.id === item.id) {
					if (typeof selectedItem.exclusive === 'undefined' || !selectedItem.exclusive) {
						selectedItem.exclusive = true;
					} else {
						selectedItem.exclusive = false;
						inclusiveItems.push(selectedItem);
					}
				} else {
					if (!selectedItem.exclusive) {
						inclusiveItems.push(selectedItem);
					}
				}
			}

			setSearch({
				...search,
				[name]: { type: companyFilterType, options: selectedItems },
				department_2: []
			});

			//dynamic load department
			let departmentList = [];
			for (const company of inclusiveItems) {
				const key = company.key;

				if (compDepMapping[key] && typeof compDepMapping[key] !== 'undefined') {
					const list = compDepMapping[key];
					departmentList = [...departmentList, ...list];
				}
			}
			setFilterSelect({ ...filterSelects, Department: departmentList });
		}
	};
	useEffect(() => {
		console.log('booleanSkills:', booleanSkills);
	}, [booleanSkills]);

	useEffect(() => {
		console.log('skills:', skills);
	}, [skills]);

	const handleKwInputChange = (event) => {
		if (event.key === 'Enter') {
			let localSkill = [];
			localSkill = [event.target.value];
			console.log('localSkill:', localSkill);

			let booleanSkills = fromBooleanSkills(localSkill);
			setBooleanSkills(localSkill);

			// booleanSkills = ['Python', ['SQL', 'Java']]
			console.log('booleanSkills:', booleanSkills);
			setSkills(booleanSkills);
			console.log(skills);
			let interpretedSkills = skillInterpretation(booleanSkills);
			let skillSQL = skillGenerateSQL(interpretedSkills);
			handleAdvancedSearch(event, skillSQL, 'skill');
			setIsKwEditing(false);
		}
	};

	const handleFullTextSearch = (e, value, filter) => {
		console.log('handle full text', value);

		let path;
		if (filter === 'skill') {
			path = url.fetchSkills();
		} else if (filter === 'institute') {
			path = url.fetchInstitutes();
		}

		axios
			.get(path, {
				params: {
					word: value
				}
			})
			.then(
				(res) => {
					console.log('handleFullTextSearch', res.data);
					const name = filter === 'skill' ? 'Skill' : 'Institute';
					setFilterSelect({ ...filterSelects, [name]: res.data.data });
				},
				(error) => {
					console.error('handleFullTextSearch error', error);
				}
			)
			.catch((e) => {
				console.error('handleFullTextSearch error catch', e.message);
			});
	};

	/******************************************************* Search End *********************************************/

	const handleChange = (event) => {
		const name = event.target.name;
		console.log(event.target);
		setState({
			...state,
			[name]: event.target.value
		});
	};
	const [page, setPage] = useState(1);

	// const handleChangePage = (e, value) => {
	// 	setPage(value);
	// 	const local = !filteredData ? resultData : filteredData;
	// 	setMainData(local.slice(value * rowCount - rowCount, value * rowCount));
	// };
	const handleChangePage = (e, newPage) => {
		setPage(newPage);
		updateCurrentPageData(newPage, resultData);
	};

	const [sortDirection, setSortDirection] = useState('desc');
	const [isSortActive, setIsSortActive] = useState(false);

	const handleSortExperienceYears = () => {
		const sortedData = [...resultData].sort((a, b) => {
			return sortDirection === 'desc'
				? a.yearsOfWorkingExperiences - b.yearsOfWorkingExperiences
				: b.yearsOfWorkingExperiences - a.yearsOfWorkingExperiences;
		});

		setResultData(sortedData); // update all data with sorted data
		setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'); // switching sortign direction
		setIsSortActive(true);
		updateCurrentPageData(page, sortedData); // update current page data
	};
	const updateCurrentPageData = (page, sortedData) => {
		const startIndex = (page - 1) * rowCount;
		const endIndex = page * rowCount;
		// slice data after sorting
		const newPageData = sortedData.slice(startIndex, endIndex);
		setMainData(newPageData); // set current page data
	};
	// const handleSort = () => {
	// 	const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
	// 	const sortedData = [...mainData].sort((a, b) => {
	// 		if (newDirection === 'asc') {
	// 			return a.yearsOfWorkingExperiences - b.yearsOfWorkingExperiences;
	// 		} else {
	// 			return b.yearsOfWorkingExperiences - a.yearsOfWorkingExperiences;
	// 		}
	// 	});

	// 	setSortDirection(newDirection);
	// 	setMainData(sortedData);
	// };

	const handleFolderChange = async (event, values) => {
		console.log(selectedRow.tags); //new existing project
		const talentId = selectedRow.id;
		const selectedProjects = values;
		const assignedProjects = selectedRow.tags;
		const insert = [];
		const subtract = [];

		//Add project to the given talent
		let talentsProjectMapping = {};
		talentsProjectMapping[talentId] = [];
		for (const selectedProject of selectedProjects) {
			const assignedProjects = selectedRow.tags;
			const assignedProjectsIds = assignedProjects.map((item) => item.id);

			if (!assignedProjectsIds.includes(selectedProject.id)) {
				const project = folderSelection.find((project) => project.id === selectedProject.id);
				insert.push(project.id);
				talentsProjectMapping[talentId].push(selectedProject);
			}
		}

		//Remove project from the given talent
		assignedProjects.forEach((each) => {
			if (!selectedProjects.includes(each)) {
				const project = folderSelection.find((project) => project.name === each.name);
				subtract.push({
					tagId: project.id,
					talentId: talentId
				});
			}
		});
		if (subtract.length > 0) {
			setSubtractArray(subtract);
		}

		if (insert.length > 0) {
			setInsertArray(insert);
		}

		setFolders(values);
		setFolderUpdate(true);

		const local = resultData;
		setMainData(local.slice(page * rowCount - rowCount, page * rowCount));
		setResultData(resultData);
	};

	const handleClickAction = async (e, data, tabIndex, ith) => {
		console.log('talent select', data);

		if (e && typeof e !== 'undefined') {
			const row = e.target.parentNode.parentNode.parentNode.parentNode;
			row.focus();
		}

		await axios
			.get(url.fetchTalentDetail(), {
				params: {
					linkedin: data.linkedin
				}
			})
			.then(
				(res) => {
					const result = res.data;
					console.log('fetchTalentDetail', result.message);

					//calculate ith talent overall
					const ithTalent = (page - 1) * rowCount + ith;
					setIthRow(ithTalent);

					setSelectedRow(result.data);
					setFolders(result.data.tags);
					setActiveActionTab(tabIndex);
					setLetter((prevState) => ({
						...prevState,
						candidateEmailMapping: [data.id]
					}));
					toggleDrawer(e, true);
				},
				(error) => {
					console.error('fetchTalentDetail error', error);
				}
			)
			.catch((e) => {
				console.error('fetchTalentDetail error catch', e.message);
			});
	};

	const toggleDrawer = (event, open) => {
		if (
			event &&
			typeof event !== 'undefined' &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setOpen(open);
	};

	const handleClose = () => {
		setOpen(false); //dialog open

		setLetterSubject('');
		setLetter({
			candidateEmailMapping: [],
			htmlBody: ''
		});
		setTemplateType('');
		setTemplateId(null);

		setTemplateName('');
		setContextEmpty(true);
		setTemplateExpand(false);
		setTemplateConfirm(false);

		setMyFiles([]);
		setFile({
			filename: [],
			path: []
		});

		sessionstorage.setItem('totalSize', 0);
		setState({
			...state,
			approach: 'false'
		});

		fileRejections.splice(0, fileRejections.length);

		//AutoConnect
		console.log('ref_initial: ', ref_initial.current);
		if (ref_initial.current) {
			ref_initial.current.handleClose();
		}

		//first email
		console.log('ref_action_firstEmail: ', ref_action_firstEmail.current);
		if (ref_action_firstEmail.current) {
			ref_action_firstEmail.current.handleClose();
		}
		setAction_firstEmail_time(initial_action_firstEmail_time);
		setTimestamp_action_firstEmail(initial_action_firstEmail_timestamp);
		setError_action_firstEmail_time(false);
		setActionFirstEmail(true);

		//extra email
		console.log('ref_extraEmail: ', ref_extraEmail.current);
		if (ref_extraEmail.current) {
			ref_extraEmail.current.handleClose();
		}
		setExtraEmail_time(initial_extraEmail_time);
		setTimestamp_extraEmail(initial_extraEmail_timestamp);
		setError_extraEmail_time(false);
		setExtraEmail(true);

		//reminder autonote
		setAction_lastEmail_time(initial_action_lastEmail_time);
		setTimestamp_lastEmail(initial_action_lastEmail_timestamp);
		setError_action_lastEmail_time(false);
		setActionLastEmail(true);

		setAction(initial_action);

		//Update Project
		setFolderUpdate(false);
	};

	const handleCheckBox = (e) => {
		console.log('target name', e.target.name);
		console.log('target checked', e.target.checked);
		// setState({ ...state, [e.target.name]: e.target.checked });
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const toggleActionTab = (tab) => {
		if (activeActionTab !== tab) setActiveActionTab(tab);
	};

	const renderStars = (number) => {
		let container = [];

		let idx = 0;
		for (let i = 0; i < number; i++) {
			container.push(<StarIcon key={idx++} />);
		}
		for (let i = 0; i < 3 - number; i++) {
			container.push(<StarOutlineIcon key={idx++} />);
		}

		return container.map((each) => each);
	};

	const handleCompanyType = (e) => {
		setCompanyFilterType(e.target.value);
		setSearch({
			...search,
			companies: { ...search.companies, type: e.target.value }
		});
	};

	function handleSkillType(operator) {
		setSkillFilterType(operator);
	}
	function handleSkillEditType(operator) {
		setSkillEditType(operator);
	}
	const handleIndustryType = (e) => {
		setIndustryFilterType(e.target.value);
		setSearch({
			...search,
			industry: { ...search.industry, type: e.target.value }
		});
	};

	/****************************************** Functionality Template Start *******************************************/

	const handleTemplate = (e) => {
		const selectedIndex = e.target.getAttribute('data-option-index');
		if (selectedIndex) {
			const selectedTemplate = templates[selectedIndex];
			handleLetterBody(selectedTemplate.htmlBody);
			setLetterSubject(selectedTemplate.subject);
			setTemplateType(selectedTemplate.type);
			setTemplateId(selectedTemplate.templateId);
			console.log('selectTemplate', selectedTemplate);
		} else {
			handleLetterBody('');
			setLetterSubject('');
			setTemplateType('');
			setTemplateId(null);
		}
		setTemplateDuplicate(false);
		setTemplateConfirm(false);
	};

	const handleNameFieldInput = (e) => {
		if (e.target.value.length > 0) {
			setTemplateName(e.target.value);
		} else {
			setTemplateName('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (letterSubject.length > 0 && filterHtmlBody.length > 0 && e.target.value.length > 0) {
			setContextEmpty(false);
		} else {
			setContextEmpty(true);
		}
	};

	const handleLetterSubject = (e) => {
		if (e.target.value.length > 0) {
			setLetterSubject(e.target.value);
		} else {
			setLetterSubject('');
		}

		const filterHtmlBody = removeHtmlTag(letter.htmlBody);

		if (filterHtmlBody.length > 0 && e.target.value.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSubmit = () => {
		if (templateOptions.find((option) => option.name === templateName) !== undefined) {
			setTemplateConfirm(false);
			setTemplateDuplicate(true);
		} else {
			post(url.addEmailTemplate(), {
				subject: letterSubject,
				htmlBody: letter.htmlBody,
				name: templateName
			}).then((res) => {
				console.log('after save template ', res.data);

				templates.push(res.data);
				setTemplates(templates);

				templateOptions.push({ type: res.data.type, name: res.data.name });
				setTemplateOptions(templateOptions);

				setTemplateConfirm(true);
				setTemplateDuplicate(false);
			});
		}
	};

	/****************************************** Functionality Template End *******************************************/

	/*************************************************** AutoConnect Start *******************************************/
	const [isAutoDisabled_initial, setAutoDisabled_initial] = useState(true);
	const [isAutoDisabled_action_firstEmail, setAutoDisabled_action_firstEmail] = useState(true);
	const [isAutoDisabled_extraEmail, setAutoDisabled_extraEmail] = useState(true);
	const [isAutoDisabled_last_email, setAutoDisabled_last_email] = useState(false);
	const [isAutoDisabled, setAutoDisabled] = useState(true);

	const [autoLoading, setAutoLoading] = useState(false);
	const timer = useRef();

	const ref_initial = useRef();
	const ref_action_firstEmail = useRef();
	const ref_extraEmail = useRef();
	const initial_time = { hour: 24, min: 37 };
	const initial_timestamp = '24:37:00';
	const initial_action = {
		[EmailSequenceType.ACTION_FIRST_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_EXTRA_EMAIL]: {
			[ActionLabel.ACTIVE]: true,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_LAST_EMAIL]: {
			[ActionLabel.ACTIVE]: false,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		},
		[EmailSequenceType.ACTION_SINGLE_EMAIL]: {
			[ActionLabel.ACTIVE]: false,
			[ActionLabel.ACTION_TYPE]: [ActionType.UNREAD]
		}
	};
	const [action, setAction] = useState(initial_action);

	const initial_action_firstEmail_time = initial_time;
	const initial_action_firstEmail_timestamp = initial_timestamp;
	const [action_firstEmail_time, setAction_firstEmail_time] = useState(
		initial_action_firstEmail_time
	);
	const [timestamp_action_firstEmail, setTimestamp_action_firstEmail] = useState(
		initial_action_firstEmail_timestamp
	);
	const [error_action_firstEmail_time, setError_action_firstEmail_time] = useState(false);
	const [action_firstEmail, setActionFirstEmail] = useState(true);

	const initial_extraEmail_time = initial_time;
	const initial_extraEmail_timestamp = initial_timestamp;
	const [extraEmail_time, setExtraEmail_time] = useState(initial_extraEmail_time);
	const [timestamp_extraEmail, setTimestamp_extraEmail] = useState(initial_extraEmail_timestamp);
	const [error_extraEmail_time, setError_extraEmail_time] = useState(false);
	const [isExtraEmail, setExtraEmail] = useState(true);

	const initial_action_lastEmail_time = initial_time;
	const initial_action_lastEmail_timestamp = initial_timestamp;
	const [action_lastEmail_time, setAction_lastEmail_time] = useState(initial_action_lastEmail_time);
	const [timestamp_lastEmail, setTimestamp_lastEmail] = useState(
		initial_action_lastEmail_timestamp
	);
	const [error_action_lastEmail_time, setError_action_lastEmail_time] = useState(false);
	const [action_lastEmail, setActionLastEmail] = useState(true);

	const [strategyId, setStrategyId] = useState(1);

	const handleAutoConnect = async () => {
		console.log('handle auto connect....');
		console.log('action ', action);

		//Check File size
		const flag1 = ref_initial.current.validateFiles();
		let flag2 = true;
		if (action_firstEmail && ref_action_firstEmail.current) {
			flag2 = ref_action_firstEmail.current.validateFiles();
		}
		let flag3 = true;
		if (isExtraEmail && ref_extraEmail.current) {
			flag3 = ref_extraEmail.current.validateFiles();
		}
		if (!(flag1 && flag2 && flag3)) return;

		//Check scheduler settings
		if (action_firstEmail && !checkScheduleTime(action_firstEmail_time)) {
			setError_action_firstEmail_time(true);
			return;
		} else {
			setError_action_firstEmail_time(false);
		}
		if (isExtraEmail && !checkScheduleTime(extraEmail_time)) {
			setError_extraEmail_time(true);
			return;
		} else {
			setError_extraEmail_time(false);
		}
		if (action_lastEmail && !checkScheduleTime(action_lastEmail_time)) {
			setError_action_lastEmail_time(true);
			return;
		} else {
			setError_action_lastEmail_time(false);
		}

		if (!autoLoading) {
			setAutoLoading(true);
			timer.current = window.setTimeout(() => {
				setAutoLoading(false);
			}, 5000);
		}

		//Send Email
		const groupInfo = ref_initial.current.fetchGroupEmail();
		// include findCandidates, maxStrategyId, maxBatchSeqId, (findCandidates have strategyId, taskId Info)
		//const info = await ref_initial.current.sendEmail();
		const info = await post(url.sendEmail(), {
			...groupInfo,
			candidateEmailMapping: letter.candidateEmailMapping,
			action: action,
			emailMethod: 'EMAIL_SEQUENCE'
		})
			.then((res) => {
				return res.data;
			})
			.then((res) => {
				console.log('after handleAutoConnect sendemail', res);
				return res;
			})
			.catch((e) => console.error('Send email has error', e));

		//Scheduler
		const connectActions = [];
		if (action.ACTION_FIRST_EMAIL.active && ref_action_firstEmail.current) {
			const data1 = ref_action_firstEmail.current.setSchedule();
			connectActions.push(data1);
		}
		if (action.ACTION_EXTRA_EMAIL.active && ref_extraEmail.current) {
			const data2 = ref_extraEmail.current.setSchedule();
			connectActions.push(data2);
		}
		let actionLastEmail = {};
		if (action.ACTION_LAST_EMAIL.active) {
			actionLastEmail = {
				scheduler: action_lastEmail_time,
				interval: timestamp_lastEmail,
				emailType: EmailSequenceType.ACTION_LAST_EMAIL
			};
		}

		if (
			action.ACTION_FIRST_EMAIL.active ||
			action.ACTION_EXTRA_EMAIL.active ||
			action.ACTION_LAST_EMAIL.active
		) {
			await post(url.handleAutoConnect(), {
				connectActions,
				findCandidates: info.findCandidates,
				actionLastEmail: actionLastEmail,
				action: action,
				batchSeqId: info.maxBatchSeqId
			})
				.then((res) => {
					console.log('after single handleAutoConnect autoConnect', res);
					setStrategyId(strategyId + 1);
				})
				.catch((e) => {
					console.error('Send Auto Connection fail', e);
				});
		}

		handleClose();
	};

	/*************************************************** AutoConnect End *******************************************/

	/******************************************** Multiple Selected Talent Start *************************/
	const isEmpty = (checkersPerPage) => {
		const _checkers = checkersPerPage[page - 1];
		const flag1 = Object.keys(_checkers).length === 0;

		const filteredCheckedTalents = Object.entries(_checkers)
			.filter(([_, value]) => value !== undefined)
			.filter(([_, value]) =>
				filteredData && filteredData !== undefined
					? filteredData.includes(value)
					: resultData.includes(value)
			);
		let cnt = 0;
		for (const [_, talent] of Object.entries(filteredCheckedTalents)) {
			if (talent && talent !== undefined) {
				cnt++;
			}
		}
		const flag2 = cnt < mainData.length ? true : false;

		return flag1 || flag2;
	};

	const isCheckboxAllEmpty = (_checkers) => {
		const flag1 = Object.keys(_checkers).length === 0;

		const filteredCheckedTalents = Object.entries(_checkers)
			.filter(([_, value]) => value !== undefined)
			.filter(([_, value]) =>
				filteredData && filteredData !== undefined
					? filteredData.includes(value)
					: resultData.includes(value)
			);
		const flag2 = filteredCheckedTalents.length === 0;

		return flag1 || flag2;
	};

	/******************************************** Multiple Selected Talent End *************************/

	/******************************************** Filter Option Start ****************************************/
	function valuetext(value) {
		return `${value}°C`;
	}
	const [anchorElStatus, setAnchorElStatus] = useState(null);
	const [anchorElIRS, setAnchorElIRS] = useState(null);
	const [filterOptions, setFilterOptions] = useState({
		status: [],
		irsstatus: []
	});
	const [filterSearch, setFilterSearch] = useState({
		status: [],
		irsstatus: []
	});

	//Status
	const handleStatusClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElStatus(event.currentTarget);
	};
	const handleStatusClose = () => {
		setAnchorElStatus(null);
	};
	const openStatus = Boolean(anchorElStatus);

	//IRS Status
	const handleIRSClick = (event) => {
		console.log(event.currentTarget);
		setAnchorElIRS(event.currentTarget);
	};
	const handleIRSClose = () => {
		setAnchorElIRS(null);
	};
	const openIRS = Boolean(anchorElIRS);

	function searchResultOptions(resultData) {
		let status = [];
		let irsstatus = [];

		status.push(_status.NO_ACTION);
		status.push(_status.NO_EMAIL);

		for (const talent of resultData) {
			if (!irsstatus.includes(talent['irsstatus']) && talent['irsstatus']) {
				irsstatus.push(talent['irsstatus']);
			}
		}

		return {
			status,
			irsstatus
		};
	}

	function handleFilterSearch(e, value, name) {
		setFilterSearch({
			...filterSearch,
			[name]: value
		});
	}

	function handleFilterTalents() {
		const validParams = {};
		for (const [key, value] of Object.entries(filterSearch)) {
			if (value.length !== 0) validParams[key] = value;
		}

		const filteredTalents = [];
		if (Object.keys(validParams).length > 0) {
			for (const [key, value] of Object.entries(resultData)) {
				//fetch all talents data keys include
				const array = Object.keys(validParams).filter((param) => {
					if (param === 'status' && value['email'] && value['email'] !== undefined) {
						if (validParams[param].length === 2) {
							return false;
						} else {
							const criterias = validParams[param];
							if (criterias[0] === _status.NO_ACTION) {
								if (value.history.some((each) => each.reply === 1)) {
									return true;
								} else {
									return false;
								}
							}
							return true;
						}
					} else if (param === 'status' && (!value['email'] || value['email'] === undefined)) {
						if (validParams[param].length === 2) {
							return false;
						} else {
							const criterias = validParams[param];
							if (criterias[0] === _status.NO_EMAIL) {
								return false;
							}
							return true;
						}
					}

					if (!value[param]) return true; //if has null value, cannot match
					return !validParams[param].includes(value[param]);
				});

				if (array.length === 0) {
					filteredTalents.push(value);
				}
			}

			if (filteredTalents.length > 0) {
				const local = filteredTalents;
				setMainData(local.slice(0, rowCount));
				setFilteredData(filteredTalents);
				setPage(1);
				const _pageCount = Math.ceil(filteredTalents.length / rowCount);
				setPageCount(_pageCount);

				//number of selected
				const filteredCheckedTalents = Object.entries(checkers)
					.filter(([_, value]) => value !== undefined)
					.filter(([_, value]) => filteredTalents.includes(value));
				setNumberOfSelected(filteredCheckedTalents.length);

				//checkboxPerPage
				let pIndex = 0;
				let copyCheckboxPerPage = checkboxPerPage.slice();
				for (let i = 0; i < filteredTalents.length; i += rowCount) {
					const talentsPerPage = filteredTalents.slice(i, i + rowCount);
					const linkedins = talentsPerPage.map((each) => each.linkedin);
					const filteredCheckers = Object.values(checkers).filter((each) =>
						linkedins.includes(each.linkedin)
					);
					if (filteredCheckers.length < talentsPerPage.length) {
						copyCheckboxPerPage[pIndex] = false;
					} else {
						copyCheckboxPerPage[pIndex] = true;
					}
					pIndex++;
				}
				setCheckboxPerPage(copyCheckboxPerPage);
			} else {
				setMainData([]);
				setFilteredData([]);
				setPage(1);
				const _pageCount = Math.ceil(filteredTalents.length / rowCount);
				setPageCount(_pageCount);

				//number of selected
				setNumberOfSelected(0);
			}
		} else {
			const local = resultData;
			setMainData(local.slice(0, rowCount));
			setFilteredData(null);
			setPage(1);
			const _pageCount = Math.ceil(resultData.length / rowCount);
			setPageCount(_pageCount);

			//number of selected
			const filteredCheckedTalents = Object.entries(checkers).filter(
				([_, value]) => value !== undefined
			);
			setNumberOfSelected(filteredCheckedTalents.length);
		}
	}

	/******************************************** Filter Option End ****************************************/

	/******************************************** Position *******************************************************/
	const [selectedPosition, setSelectedPosition] = useState({});

	const handleChangePosition = (event, value) => {
		const selectedPosition = value;
		setSelectedPosition();
	};

	/**************************************** React Life Cycle Start *************************************************************/

	useEffect(() => {
		SetFolderSelection(projects);
		console.log('user', memory.user);
	}, [projects]);

	useEffect(() => {
		if (!isTemplateConfirm) {
			setTemplateNameMsg(templateName);
		}
	}, [templateName]);

	useEffect(() => {
		console.log('template loading.....');
		get(url.fetchEmailTemplate()).then((res) => {
			console.log(res.data);
			setTemplates(res.data);

			const templateOptions = res.data.map((item) => {
				const type = item.type;
				return {
					type,
					name: item.name
				};
			});
			setTemplateOptions(templateOptions);
		});
	}, []);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(action_firstEmail_time);
		console.log('time: ', time);
		setTimestamp_action_firstEmail(time);
	}, [action_firstEmail_time.hour, action_firstEmail_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(extraEmail_time);
		console.log('time: ', time);
		setTimestamp_extraEmail(time);
	}, [extraEmail_time.hour, extraEmail_time.min]);

	useEffect(() => {
		console.log('transform schedule time....');
		const time = handleScheduleTime(action_lastEmail_time);
		console.log('time: ', time);
		setTimestamp_lastEmail(time);
	}, [action_lastEmail_time.hour, action_lastEmail_time.min]);

	useEffect(() => {
		get(url.fetchMaxStrategyByUser()).then((res) => {
			console.log('Max Strategy', res.data.maxStrategyId);
			if (res.data.maxStrategyId !== null) {
				setStrategyId(res.data.maxStrategyId + 1);
				//setStrategyId((prevState) => (prevState+1));
			}
		});
	}, []);

	useEffect(() => {
		if (!isAutoDisabled_initial) {
			if (action_firstEmail && isAutoDisabled_action_firstEmail) {
				setAutoDisabled(true);
				return;
			}

			if (isExtraEmail && isAutoDisabled_extraEmail) {
				setAutoDisabled(true);
				return;
			}

			if (action_lastEmail && isAutoDisabled_last_email) {
				setAutoDisabled(true);
				return;
			}

			setAutoDisabled(false);
		} else {
			setAutoDisabled(true);
		}
	}, [
		isAutoDisabled_initial,
		isAutoDisabled_action_firstEmail,
		isAutoDisabled_extraEmail,
		isAutoDisabled_last_email,
		action_firstEmail,
		isExtraEmail,
		action_lastEmail
	]);

	useEffect(() => {
		get(url.fetchFilters()).then((res) => {
			console.log('filters data', res.data);
			setFilterSelect(res.data);
		});
		get(url.fetchCompDeptMapping()).then((res) => {
			setCompDepartMapping(res.data);
		});
		get(url.fetchPositionTypeStackMapping()).then((res) => {
			setPositionTypeStackMapping(res.data);
		});
	}, []);

	useEffect(() => {
		//goSearchTalents(search, 1);
		handleFilterTalents();
	}, [filterSearch]);
	/**************************************** React Life Cycle End *************************************************************/

	/***********************************************************************************************************************
	 * 												SEND EMAIL            														                                       *
	 * *********************************************************************************************************************/

	const [letterSubject, setLetterSubject] = useState('');
	const [creditOpen, setCreditOpen] = useState(false);
	const [emailCreditOpen, setEmailCreditOpen] = useState({
		noEmailCreditOpen: false,
		closeEmailCreditOpen: false,
		shortageEmailCreditOpen: false
	});
	const [letter, setLetter] = useState({
		candidateEmailMapping: [],
		htmlBody: ''
	});

	const handleLetterBody = (html) => {
		const filterHtmlBody = removeHtmlTag(html);

		if (filterHtmlBody.length > 0) {
			setLetter((prevState) => ({ ...prevState, htmlBody: html }));
		} else {
			setLetter((prevState) => ({ ...prevState, htmlBody: '' }));
		}

		if (letterSubject.length > 0 && filterHtmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	};

	const handleSendEmail = async () => {
		if (!validateFiles()) {
			return;
		}

		await axios
			.post(
				url.sendEmail(),
				{
					subject: letterSubject,
					...letter,
					approach: state.approach === 'true',
					filename: file.filename,
					itemname: file.path,
					strategyId,
					templateId,
					opportunity: true,
					action,
					emailMethod: 'SINGLE_EMAIL'
				},
				config
			)
			.then((res) => {
				console.log('handleSendEmail: ', res);
				setStrategyId(strategyId + 1);
			})
			.catch((e) => console.error('Send email has error', e));

		handleClose();
	};

	useEffect(() => {
		if (letterSubject.length > 0 && letter.htmlBody.length > 0) {
			if (isTemplateExpand) {
				if (templateName.length > 0) {
					setContextEmpty(false);
				} else {
					setContextEmpty(true);
				}
			}
			setOppSendDisabled(false);
		} else {
			setContextEmpty(true);
			setOppSendDisabled(true);
		}
	}, [letterSubject, letter.htmlBody]);

	return (
		<>
			<Card className="card-box mb-spacing-6-x2">
				<RecruitHeader
					updateSearch={setSearch}
					updateFilterSearchDisabled={setFilterSearchDisabled}
					search={search}
					compDepMapping={compDepMapping}
					updateFilterSelect={setFilterSelect}
					filterSelects={filterSelects}
					updateUploadFile={setUploadFile}
					uploadFile={uploadFile}
					updateAdvancedSearchOpen={setAdvancedSearchOpen}
					advancedSearchOpen={advancedSearchOpen}
				/>
				{advancedSearchOpen && (
					// <RecruitFilter
					// 	filterSelects={filterSelects}
					// 	search={search}
					// 	handleAdvancedSearch={handleAdvancedSearch}
					// 	premium={premium}
					// 	updatePremiumOpen={setPremiumOpen}
					// 	industryFilterType={industryFilterType}
					// 	handleIndustryType={handleIndustryType}
					// 	companyFilterType={companyFilterType}
					// 	handleCompanyType={handleCompanyType}
					// 	handleFullTextSearch={handleFullTextSearch}
					// 	skillFilterType={skillFilterType}
					// 	handleSkillType={handleSkillType}
					// 	skills={skills}
					// 	updateSkills={setSkills}
					// 	isFilterSearchDisabled={isFilterSearchDisabled}
					// 	handleSearch={handleSearch}
					// />
					<div className="card-header w-100">
						<Card className="shadow-xxl px-4 py-5 w-100">
							<Grid container spacing={5}>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Locations</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Area}
											getOptionLabel={(option) => option.displayname}
											value={search.location}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'location')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talent current geographic locations"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Seniority</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Seniority}
											getOptionLabel={(option) => option.displayname.substring(4)}
											value={search.seniority}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'seniority')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Title levels and team management responsibility"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Achievement</b>
											<Box
												onClick={() => {
													if (!premium) setPremiumOpen(true);
												}}
												className={classes.premium}
												bgcolor={!premium ? 'text.secondary' : 'primary.main'}
												color="background.paper"
												py={0}
												px={2}>
												Premium
											</Box>
										</div>
										<Autocomplete
											disabled={!premium}
											multiple
											size="small"
											options={filterSelects.Achievement}
											getOptionLabel={(option) => option.displayname}
											value={search.achievement}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'achievement')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Awards, patents or publication authors"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Position Types</b>
										<Tooltip title="Learn More" arrow>
											<IconButton
												size="small"
												onClick={() =>
													window.open(
														'https://ocbang.larksuite.com/docx/Jd5NdzxE2o2CgtxQwreuvYr8sDd?from=from_copylink',
														'_blank'
													)
												}
												style={{ marginLeft: 4 }}>
												<HelpOutlineIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.PositionType}
											getOptionLabel={(option) => option.displayname}
											value={search.positionType}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'positionType')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Business divisions of the position"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Years of Experience</b>
										<Slider
											className="mt-4"
											getAriaLabel={() => 'Years of Working Experience'}
											value={search.yearsOfWorkingExperiences}
											onChange={(e, value) =>
												handleAdvancedSearch(e, value, 'yearsOfWorkingExperiences')
											}
											valueLabelDisplay="on"
											color="secondary"
											getAriaValueText={valuetext}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Entrepreneurship</b>
											<Box
												onClick={() => {
													if (!premium) setPremiumOpen(true);
												}}
												className={classes.premium}
												bgcolor={!premium ? 'text.secondary' : 'primary.main'}
												color="background.paper"
												py={0}
												px={2}>
												Premium
											</Box>
										</div>
										<Autocomplete
											disabled={!premium}
											multiple
											size="small"
											options={filterSelects.Entrepreneurship.filter((each) => each.key !== 'EM')}
											getOptionLabel={(option) => option.displayname}
											value={search.entrepreneurship}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'entrepreneurship')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talents who have cofounded or worked at startups"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Stacks</b>
										<Tooltip title="Learn More" arrow>
											<IconButton
												size="small"
												onClick={() =>
													window.open(
														'https://ocbang.larksuite.com/docx/Jd5NdzxE2o2CgtxQwreuvYr8sDd?from=from_copylink',
														'_blank'
													)
												}
												style={{ marginLeft: 4 }}>
												<HelpOutlineIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Stack}
											filterSelectedOptions
											getOptionSelected={(option, value) => option.key === value.key}
											getOptionLabel={(option) => option.displayname}
											value={search.stacks}
											onChange={(e, value, reason) => {
												console.log('stack value: ', value);
												console.log('reason: ', reason);

												handleAdvancedSearch(e, value, 'stacks');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talent technical divisions"
													fullWidth
												/>
											)}
											renderTags={(tagValue, getTagProps) => {
												return tagValue.map((option, index) => (
													<div key={index}>
														<Chip
															style={{ backgroundColor: option.exclusive ? '#dc004e' : '#0795fe' }}
															classes={{
																root: classes.chipRoot
															}}
															label={option.displayname}
															size="small"
															{...getTagProps({ index })}
															icon={
																<NotInterestedRoundedIcon
																	onClick={() => handleExclusive('stacks', option)}
																	style={{ color: grey[50] }}
																/>
															}
														/>
													</div>
												));
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Current Tenure</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.CurrentTenure}
											getOptionLabel={(option) => option.displayname}
											value={search.currTenure}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'currTenure')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Months at current company"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Average Tenure</b>
											<Box
												onClick={() => {
													if (!premium) setPremiumOpen(true);
												}}
												className={classes.premium}
												bgcolor={!premium ? 'text.secondary' : 'primary.main'}
												color="background.paper"
												py={0}
												px={2}>
												Premium
											</Box>
										</div>
										<Autocomplete
											disabled={!premium}
											multiple
											size="small"
											options={filterSelects.AvgTenure}
											getOptionLabel={(option) => option.displayname}
											value={search.avgTenure}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'avgTenure')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="How often do talents switch companies"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Industries</b>
											<RadioGroup
												aria-label="gender"
												name="gender"
												value={industryFilterType}
												onChange={handleIndustryType}
												row>
												<FormControlLabel
													value="PAST"
													control={<Radio className={classes.radioGroup} />}
													label="PAST"
												/>
												<FormControlLabel
													value="OR"
													control={<Radio className={classes.radioGroup} />}
													label="OR"
												/>
												<FormControlLabel
													value="PRESENT"
													control={<Radio className={classes.radioGroup} />}
													label="PRESENT"
												/>
											</RadioGroup>
										</div>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Industry}
											getOptionLabel={(option) => option.displayname}
											value={search.industry.options}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'industry')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talent current or past Industries"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Highest Degree</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Degree}
											getOptionLabel={(option) => option.displayname}
											value={search.degree}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'degree')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="The highest degree of talents"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Preferred Locations</b>
											<Box
												onClick={() => {
													if (!premium) setPremiumOpen(true);
												}}
												className={classes.premium}
												bgcolor={!premium ? 'text.secondary' : 'primary.main'}
												color="background.paper"
												py={0}
												px={2}>
												Premium
											</Box>
										</div>
										<Autocomplete
											disabled={!premium}
											multiple
											size="small"
											options={filterSelects.PreferredLocation}
											getOptionLabel={(option) => option.displayname}
											value={search.preferredLocation}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'preferredLocation')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="The locations talents prefer to work at"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Companies</b>
											<RadioGroup
												aria-label="gender"
												name="gender"
												value={companyFilterType}
												onChange={handleCompanyType}
												row>
												<FormControlLabel
													value="PAST"
													control={<Radio className={classes.radioGroup} />}
													label="PAST"
												/>
												<FormControlLabel
													value="OR"
													control={<Radio className={classes.radioGroup} />}
													label="OR"
												/>
												<FormControlLabel
													value="PRESENT"
													control={<Radio className={classes.radioGroup} />}
													label="PRESENT"
												/>
											</RadioGroup>
										</div>
										<Autocomplete
											multiple
											filterSelectedOptions
											size="small"
											options={filterSelects.Company}
											getOptionLabel={(option) => option.displayname}
											getOptionSelected={(option, value) => option.key === value.key}
											value={search.companies.options}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'companies')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talent current or past companies"
													fullWidth
												/>
											)}
											renderTags={(tagValue, getTagProps) => {
												return tagValue.map((option, index) => (
													<div key={index}>
														<Chip
															style={{ backgroundColor: option.exclusive ? '#dc004e' : '#0795fe' }}
															classes={{
																root: classes.chipRoot
															}}
															label={option.displayname}
															size="small"
															{...getTagProps({ index })}
															icon={
																<NotInterestedRoundedIcon
																	onClick={() => handleExclusive('companies', option)}
																	style={{ color: grey[50] }}
																/>
															}
														/>
													</div>
												));
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Schools</b>
										<Autocomplete
											freeSolo
											multiple
											size="small"
											options={filterSelects.Institute}
											getOptionLabel={(option) => option.institute}
											value={search.institute}
											onInputChange={(e, value) => handleFullTextSearch(e, value, 'institute')}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'institute')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Schools attended"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>O2O Index</b>
											<Box
												onClick={() => {
													if (!premium) setPremiumOpen(true);
												}}
												className={classes.premium}
												bgcolor={!premium ? 'text.secondary' : 'primary.main'}
												color="background.paper"
												py={0}
												px={2}>
												Premium
											</Box>
										</div>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Attitude}
											getOptionLabel={(option) => option.displayname}
											value={search.openToOpportunities}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'openToOpportunities')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Talents who are open to opportunities"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>C-2 Teams</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Department}
											getOptionLabel={(option) => option.displayname}
											value={search.department_2}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'department_2')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="C-2 Team in current company"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<div className="d-flex justify-content-between">
											<b>Keywords</b>
											<div>
												<>
													<IoMdAddCircle
														className={
															skillFilterType === 'AND'
																? classes.addSkillOperatorOn
																: classes.addSkillOperatorOff
														}
														onClick={(e) => {
															handleSkillType('AND');
														}}
													/>
													<BsSlashCircleFill
														className={
															skillFilterType === 'OR'
																? classes.orSkillOperatorOn
																: classes.orSkillOperatorOff
														}
														onClick={(e) => {
															handleSkillType('OR');
														}}
													/>
													<IoMdCreate
														className={
															isKwEditing
																? classes.editSkillOperatorOn
																: classes.editSkillOperatorOff
														}
														onClick={handleEditButtonClick}
													/>
												</>
											</div>
										</div>
										{/* <div>
											{isKwEditing && (
												<TextField fullWidth id="outlined-basic" label="Outlined" variant="outlined" onChange={(e, value) => {
													handleKwInputChange(e, value);
												}} />
											)}
										</div> */}
										<div>
											{isKwEditing && (
												<TextField
													fullWidth
													id="outlined-basic"
													label="Enter a Boolean"
													variant="outlined"
													defaultValue={booleanSkills}
													onKeyDown={handleKwInputChange}
												/>
											)}
										</div>
										<Autocomplete
											freeSolo
											multiple
											size="small"
											options={filterSelects.Skill}
											getOptionLabel={(option) => option.skill}
											// value={search.skill.options}
											value={skills}
											filterSelectedOptions
											getOptionDisabled={(option) => true}
											onInputChange={(e, value) => {
												console.log('on keywords input change: ', value);
											}}
											onChange={(e, value, reason, details) => {
												// console.log('value: ', value, 'reason: ', reason, 'details: ', details);

												let localSkill = [];
												//add
												if (reason === 'create-option') {
													localSkill = addSkill(skills, details['option'], skillFilterType);
													console.log('!!!!!!', localSkill);
													setSkills(localSkill);
												}
												//delete
												if (reason === 'remove-option') {
													localSkill = removeSkill(skills, details['option']);
													setSkills(localSkill);
												}
												if (reason === 'clear') {
													localSkill = [];
													setSkills(localSkill);
												}
												// if (isKwEditing) {
												// 	localSkill = [];
												// 	setSkills(localSkill);
												// }

												let interpretedSkills = skillInterpretation(localSkill);
												console.log('看看 interpretedSkills: ', interpretedSkills);
												let updatedBooleanSkills;

												updatedBooleanSkills = toBooleanSkills(interpretedSkills);
												console.log('看看 updatedBooleanSkills: ', updatedBooleanSkills);
												setBooleanSkills(updatedBooleanSkills);
												let skillSQL = skillGenerateSQL(interpretedSkills);
												console.log('看看 skillSQL: ', skillSQL);

												handleAdvancedSearch(e, skillSQL, 'skill');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Profile keywords or boolean"
													fullWidth
												/>
											)}
											renderTags={(tagValue, getTagProps) => {
												console.log(skills);
												let interpretedSkills;

												// if (!isKwEditing) {
												// 	console.log('nnn')
												// 	interpretedSkills = skillInterpretation(skills);
												// } else {
												// 	console.log('aaa')
												// 	interpretedSkills = skillInterpretation(skills);

												// }
												interpretedSkills = skillInterpretation(skills);

												// setBooleanSkills(updatedBooleanSkills);
												return interpretedSkills.map((option, index) => (
													<div key={index}>
														{option[1] === '+' ? (
															<Chip
																label={option[0]}
																size="small"
																{...getTagProps({ index })}
																style={{ backgroundColor: '#8674F4' }}
															/>
														) : (
															<Chip
																label={option[0]}
																size="small"
																{...getTagProps({ index })}
																style={{ backgroundColor: '#3E96F6' }}
															/>
														)}

														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '+' && <h7>+</h7>}
														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '*/' && <h7>+</h7>}
														{index != Object.keys(interpretedSkills).length - 1 &&
															option[1] === '/' && <h7>/</h7>}
													</div>
												));
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										<b>Languages</b>
										<Autocomplete
											multiple
											size="small"
											options={filterSelects.Language}
											getOptionLabel={(option) => option.displayname}
											value={search.language}
											onChange={(e, value) => handleAdvancedSearch(e, value, 'language')}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Languages of talents"
													fullWidth
												/>
											)}
										/>
									</Grid>
								</Grid>

								<Grid item xs={12} md={4} lg={4}>
									<Grid item xs={12}>
										{isFilterSearchDisabled ? (
											<img
												className={'img-search'}
												src={require('../../../../assets/images/apps/Search_Button.png')}
											/>
										) : (
											<img
												className={'img-search'}
												src={require('../../../../assets/images/apps/Search_Button_Active.png')}
												onClick={handleSearch}
											/>
										)}
									</Grid>
								</Grid>
								<Grid item xs={12} md={4} lg={4}></Grid>
								<Grid
									item
									xs={12}
									md={4}
									lg={4}
									style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button className={classes.containedPrimary} onClick={handleClearFilters}>
										Reset Filters
									</Button>
								</Grid>
							</Grid>
						</Card>
					</div>
				)}

				<RecruitList
					isCheckboxAllEmpty={isCheckboxAllEmpty}
					checkers={checkers}
					newProject={newProject}
					updateNewProject={setNewProject}
					updateExistingProject={setExistingProject}
					existingProject={existingProject}
					resultData={resultData}
					filterSearch={filterSearch}
					handleFilterCheckAllAction={handleFilterCheckAllAction}
					handleCheckAllAction={handleCheckAllAction}
					totalCount={totalCount}
					numberOfSelected={numberOfSelected}
					checkboxPerPage={checkboxPerPage}
					handleCheckAllActionPage={handleCheckAllActionPage}
					page={page}
					setMainData={setMainData}
					mainData={mainData}
					handleSortExperienceYears={handleSortExperienceYears}
					isSortActive={isSortActive}
					sortDirection={sortDirection}
					handleStatusClick={handleStatusClick}
					openStatus={openStatus}
					anchorElStatus={anchorElStatus}
					handleStatusClose={handleStatusClose}
					filterOptions={filterOptions}
					handleFilterSearch={handleFilterSearch}
					handleIRSClick={handleIRSClick}
					openIRS={openIRS}
					anchorElIRS={anchorElIRS}
					handleIRSClose={handleIRSClose}
					promiseInProgress={promiseInProgress}
					handleCheckTalent={handleCheckTalent}
					handleUnCheckTalent={handleUnCheckTalent}
					handleClickAction={handleClickAction}
					handleChangePage={handleChangePage}
					// handleSort={handleSort}
					pageCount={pageCount}
					toggleDrawer={toggleDrawer}
				/>
			</Card>

			{selectedRow && (
				<Drawer anchor="right" open={open} onClose={(e) => toggleDrawer(e, false)}>
					<div style={{ width: 1400 }} className="h-100">
						<TalentDialog
							handleClose={handleClose}
							selectedRow={selectedRow}
							activeActionTab={activeActionTab}
							toggleActionTab={toggleActionTab}
							templateOptions={templateOptions}
							handleTemplate={handleTemplate}
							handleLetterSubject={handleLetterSubject}
							letterSubject={letterSubject}
							letter={letter}
							handleLetterBody={handleLetterBody}
							templateType={templateType}
							updateTemplateExpand={setTemplateExpand}
							isTemplateExpand={isTemplateExpand}
							handleNameFieldInput={handleNameFieldInput}
							templateName={templateName}
							isContextEmpty={isContextEmpty}
							handleSubmit={handleSubmit}
							updateTemplateName={setTemplateName}
							updateTemplateConfirm={setTemplateConfirm}
							updateTemplateDuplicate={setTemplateDuplicate}
							isTemplateConfirm={isTemplateConfirm}
							templateNameMsg={templateNameMsg}
							isTemplateDuplicate={isTemplateDuplicate}
							getRootProps={getRootProps}
							getInputProps={getInputProps}
							isDragAccept={isDragAccept}
							isDragReject={isDragReject}
							isDragActive={isDragActive}
							fileRejectionItems={fileRejectionItems}
							isLoading={isLoading}
							files={files}
							state={state}
							handleChange={handleChange}
							templates={templates}
							ref_initial={ref_initial} //initial email
							strategyId={strategyId} //start strategyId
							updateAutoDisabled_initial={setAutoDisabled_initial} //send button disabled by initial
							action_firstEmail={action_firstEmail}
							action_firstEmail_time={action_firstEmail_time} //action for first email
							updateAction_firstEmail_time={setAction_firstEmail_time} //action for first email
							error_action_firstEmail_time={error_action_firstEmail_time} //action for first email
							ref_action_firstEmail={ref_action_firstEmail} //action for first email
							timestamp_action_firstEmail={timestamp_action_firstEmail} //action for first email
							updateAutoDisabled_action_firstEmail={setAutoDisabled_action_firstEmail} //send button disabled by action first email
							updateActionFirstEmail={setActionFirstEmail} //action for first email
							isExtraEmail={isExtraEmail} // extra email
							extraEmail_time={extraEmail_time} // extra email
							updateExtraEmail_time={setExtraEmail_time} //extra email
							ref_extraEmail={ref_extraEmail} //extra email
							error_extraEmail_time={error_extraEmail_time} //extra email
							timestamp_extraEmail={timestamp_extraEmail} //extra email
							updateAutoDisabled_extraEmail={setAutoDisabled_extraEmail} //send button disabed by extra email
							updateExtraEmail={setExtraEmail} //extra email
							action_lastEmail={action_lastEmail} //action for last email
							action_lastEmail_time={action_lastEmail_time} //action for last email
							updateAction_lastEmail_time={setAction_lastEmail_time} //action for last email
							updateActionLastEmail={setActionLastEmail} //action for last email
							error_action_lastEmail_time={error_action_lastEmail_time} //action for last email
							updateAutoDisabled_last_email={setAutoDisabled_last_email} //send button last email disabled
							activeTab={activeTab}
							folderSelection={folderSelection}
							handleFolderChange={handleFolderChange}
							folders={folders}
							isOppSendDisabled={isOppSendDisabled}
							handleSendEmail={handleSendEmail}
							isAutoDisabled={isAutoDisabled}
							handleAutoConnect={handleAutoConnect}
							autoLoading={autoLoading}
							isFolderUpdate={isFolderUpdate}
							handleUpdateProjects={handleUpdateProjects}
							checkers={checkers}
							handleCheckTalent={handleCheckTalent}
							handleUnCheckTalent={handleUnCheckTalent}
							resultData={resultData}
							ithRow={ithRow}
							handleClickAction={handleClickAction}
							toggleDrawer={toggleDrawer}
							updateAction={setAction}
							action={action}
						/>
					</div>
				</Drawer>
			)}

			<Dialog
				open={creditOpen}
				onClose={() => setCreditOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You do not have enough credits, please contact OCBang Representative to refill credits.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCreditOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog fullWidth maxWidth="sm" open={newProject.open} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Project Name</DialogTitle>
				<DialogContent>
					<TextField
						margin="dense"
						fullWidth
						variant="outlined"
						placeholder="Create a project"
						value={newProject.projectName}
						onChange={handleNewProjectTextInput}
					/>
					<div style={{ height: 8 }} />
					<Autocomplete
						size="small"
						options={positions}
						groupBy={(position) => position.clientCompany}
						getOptionLabel={(position) => position.name}
						getOptionSelected={(option, value) => option.positionId === value.positionId}
						onChange={(event, value) => setSelectedPosition(value)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label="Choose a position on IRS"
								fullWidth
							/>
						)}
					/>
					<div style={{ height: 8 }} />
					<Button
						disabled={
							!(
								newProject.projectName &&
								typeof newProject.projectName !== 'undefined' &&
								newProject.projectName.length > 0 &&
								selectedPosition &&
								typeof selectedPosition !== 'undefined' &&
								Object.keys(selectedPosition).length > 0
							)
						}
						fullWidth
						className={classes.containedPrimary}
						onClick={() => handleSaveNewProject(true)}>
						Save and view projects
					</Button>
					<div style={{ height: 8 }} />
					<Button
						disabled={
							!(
								newProject.projectName &&
								typeof newProject.projectName !== 'undefined' &&
								newProject.projectName.length > 0 &&
								selectedPosition &&
								typeof selectedPosition !== 'undefined' &&
								Object.keys(selectedPosition).length > 0
							)
						}
						fullWidth
						className={classes.containedPrimary}
						onClick={() => handleSaveNewProject(false)}>
						Save and continue sourcing
					</Button>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setNewProject({ open: false, projectName: null })}
						color="primary"
						variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth
				maxWidth="sm"
				open={existingProject.open}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Project Name</DialogTitle>
				<DialogContent>
					{folderSelection && (
						<Autocomplete
							multiple
							size="medium"
							// groupBy={(each) => each.position.name}
							groupBy={(each) => each.position?.name || 'Unknown Position'}
							fullWidth
							options={folderSelection}
							getOptionLabel={(option) => option.name}
							value={existingTags}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={handleFolderSelect}
							renderInput={(params) => (
								<TextField {...params} variant="standard" label="Choose projects" />
							)}
						/>
					)}
					<div style={{ height: 8 }} />
					<Button
						fullWidth
						className={classes.containedPrimary}
						onClick={() => handleSaveExistingProjects(true)}>
						Save and view projects
					</Button>
					<div style={{ height: 8 }} />
					<Button
						fullWidth
						className={classes.containedPrimary}
						onClick={() => handleSaveExistingProjects(false)}>
						Save and continue sourcing
					</Button>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							setExistingProject({ open: false, projectName: null });
							setExistingTags([]);
						}}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Please choose at least one filter for search
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setSearchOpen(false)} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={isPremiumOpen}
				onClose={() => setPremiumOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="form-dialog-title">Upgrade to Premium</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<li>5 Premium Filters for Talent Sourcing</li>
						<li>360 Sourcing Partner (8 Credits per month)</li>
						<li>7x24 Account Manager Support</li>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.containedPrimary}
						href="https://www.ocinsights.ai/asp-products/premium"
						target="_blank">
						Yes
					</Button>
					<Button
						className={classes.containedPrimary}
						href="https://www.ocinsights.ai/request-a-demo"
						target="_blank">
						Request a Demo
					</Button>
					<Button color="primary" variant="outlined" onClick={() => setPremiumOpen(false)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={snackOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)}>
				<Alert onClose={() => setSnackOpen(false)} severity="error">
					There was an error saving your template. Please try another name.
				</Alert>
			</Snackbar>

			<Portal>
				<Snackbar
					className="Snackbar-center"
					open={
						emailCreditOpen.noEmailCreditOpen ||
						emailCreditOpen.closeEmailCreditOpen ||
						emailCreditOpen.shortageEmailCreditOpen
					}
					onClose={() =>
						setEmailCreditOpen({
							noEmailCreditOpen: false,
							closeEmailCreditOpen: false,
							shortageEmailCreditOpen: false
						})
					}>
					<Alert
						onClose={() =>
							setEmailCreditOpen({
								noEmailCreditOpen: false,
								closeEmailCreditOpen: false,
								shortageEmailCreditOpen: false
							})
						}
						severity="warning">
						{emailCreditOpen.noEmailCreditOpen && (
							<span>
								All your email sending quote is used out. If you need more daily quota, please
								contact our account manager to upgrade your service.
							</span>
						)}
						{emailCreditOpen.closeEmailCreditOpen && (
							<span>
								The email sending limit is close. You have only {memory.user.remainingEmailCredits}{' '}
								email(s) quota left for today. Please pay attention to your email usage.
							</span>
						)}
						{emailCreditOpen.shortageEmailCreditOpen && (
							<span>
								You have only {memory.user.remainingEmailCredits} email(s) quota left for today.
								Please adjust or remove the talents you are going to connect with in this email.
							</span>
						)}
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}
