import { combineReducers } from 'redux';
import orgs from './orgs';
import articles from './articles';
import overview from './overview';
import orgChart from './orgChart';
import recruit from './recruit';
import projects from './projects';
import custOrgChart from './custOrgChart';

const rootReducer = combineReducers({
	orgs,
	articles,
	overview,
	orgChart,
	custOrgChart,
	recruit,
	projects
});

export default rootReducer;
