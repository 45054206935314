import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { post, get } from '../../utils/request';
import url from '../../utils/url';
import './style.css';
import { usageTraceLabels, updateUsageActivity } from '../../../src/utils/recruitUtil';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary
	}
}));

const monthDict = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December'
};

function ExecutiveSummary() {
	const classes = useStyles();

	const [months, setMonths] = useState([]);

	function FormRow({ month, index }) {
		const generateGrids = (month, index) => {
			const grids = [];

			for (let i = index; i < index + 3; i++) {
				let originalPath;
				let thumbnailPath;
				try {
					originalPath = `https://ocinsights.s3-us-west-1.amazonaws.com/executiveSummary/${month}/original/0${i}.jpeg`;
					thumbnailPath = `https://ocinsights.s3-us-west-1.amazonaws.com/executiveSummary/${month}/thumbnail/0${i}.jpeg`;
				} catch (e) {
					continue;
				}

				if (i < 9) {
					grids.push(
						<Grid item xs={4}>
							<Paper className={classes.paper}>
								<Item original={originalPath} thumbnail={thumbnailPath} width="6666" height="3750">
									{({ ref, open }) => (
										<img width="100%" ref={ref} onClick={open} src={thumbnailPath} />
									)}
								</Item>
							</Paper>
						</Grid>
					);
				}
			}

			return grids;
		};

		return <>{generateGrids(month, index)}</>;
	}

	useEffect(() => {
		get(url.fetchStartMonth()).then((res) => {
			console.log('Executive Summary data', res.data);
			setMonths(res.data);
		});

		//usage activity
		const labels = usageTraceLabels();
		updateUsageActivity(labels.talentAnalytics);
	}, []);

	return (
		<>
			<h2>Executive Summary of Tech Talents in the U.S.</h2>
			{months.length > 0 &&
				months.map((item, idx) => {
					return (
						<div key={idx}>
							<hr />
							<div class="row">
								<div class="display-inline-block float-left">
									<h3>
										{monthDict[item.month]} {item.year}
									</h3>
								</div>
							</div>
							<Gallery>
								<Grid container spacing={1}>
									<Grid container item xs={12} spacing={3}>
										<FormRow index={1} month={item.month} />
									</Grid>
									<Grid container item xs={12} spacing={3}>
										<FormRow index={4} month={item.month} />
									</Grid>
									<Grid container item xs={12} spacing={3}>
										<FormRow index={7} month={item.month} />
									</Grid>
								</Grid>
							</Gallery>
						</div>
					);
				})}
		</>
	);
}

export default ExecutiveSummary;
