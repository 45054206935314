import axios from 'axios';
import Cookies from 'universal-cookie';
import https from 'https';

axios.defaults.withCredentials = true;
export const headers = {
	'Access-Control-Allow-Credentials': true,
	'Content-Type': 'application/json',
	'X-Version': '1.0'
};

function get(url) {
	return axios
		.get(url, {
			withCredentials: true,
			headers
		})
		.then((res) => {
			console.log(' response: ' + res);
			return handleResponse(res, url);
		})
		.catch((err) => {
			console.log(`Request failed. URL = ${url}`, err);
			return Promise.reject({
				error: {
					message: 'Request failed due to your network error, please try later.'
				}
			});
		});
}

const post = (url, data) => {
	console.log(url, data);

	return axios
		.post(url, data, {
			withCredentials: true,
			httpsAgent: new https.Agent({ rejectUnauthorized: false }),
			headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
		})
		.then((res) => {
			return handleResponse(res, url);
		})
		.catch((err) => {
			console.log(`Request failed. URL = ${url}`, err);
			return Promise.reject({
				error: {
					message: 'Request failed due to your network error, please try later.'
				}
			});
		});
};

const handleResponse = (response, url) => {
	if (response.status === 200) {
		return response.data;
	} else {
		console.error(`Request failed. URL = ${url}`);
		return Promise.reject({
			error: {
				message: response.message
			}
		});
	}
};

const del = (url, data) => {
	return axios
		.delete(url, {
			headers: {
				withCredentials: true,
				headers
			},
			data: data
		})
		.then((res) => {
			console.log(' response: ' + res);
			return handleResponse(res, url);
		})
		.catch((err) => {
			console.log(`Request failed. URL = ${url}`, err);
			return Promise.reject({
				error: {
					message: 'Request failed due to your network error, please try later.'
				}
			});
		});
};

const update = (url, data) => {
	return axios
		.put(url, data)
		.then((res) => {
			console.log(' response: ' + res);
			return handleResponse(res, url);
		})
		.catch((err) => {
			console.log(`Request failed. URL = ${url}`, err);
			return Promise.reject({
				error: {
					message: 'Request failed due to your network error, please try later.'
				}
			});
		});
};

export { get, post, del, update };
