import React, { useState } from 'react';
import hero8 from '../../../../assets/images/hero-bg/contact.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { headers } from '../../../../utils/request';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import storage from '../../../../utils/storage';
import memory from '../../../../utils/memory';
import getBaseUrl from '../../../../utils/getBaseUrl';
import https from 'https';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	containedPrimary: {
		backgroundColor: '#096eb6',
		'&:hover': {
			backgroundColor: '#096eb6'
		},
		color: '#FFFFFF'
	}
}));

export default function LoginForm() {
	const [checked1, setChecked1] = useState(true);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [cookies, setCookie] = useCookies(['token']);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const history = useHistory();
	const classes = useStyles();

	const handleChange1 = (event) => {
		setChecked1(event.target.checked);
	};

	const handleSubmit = async () => {
		console.log(email, password);
		await axios
			.post(
				`${getBaseUrl()}/api/auth/login`,
				{ email, password },
				{ headers, httpsAgent: new https.Agent({ rejectUnauthorized: false }) }
			)
			.then((res) => {
				console.log('token : ' + res.data.data.token);
				setCookie('token', res.data.data.token, {
					path: '/',
					maxAge: 60 * 60 * 24 * 30,
					sameSite: 'none',
					secure: true
				});
				//setCookie('token', res.data.data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 });
				memory.user = res.data.data.user;
				memory.customer = res.data.data.customer;
				storage.saveUser(res.data.data.user);
				storage.saveCustomer(res.data.data.customer);
				storage.initialEmailTemplate();
				if (res.data.data.user.role === 'admin') {
					history.push('/admin');
				}
				setSuccess(true);
			})
			.catch((e) => setError(e.response.data.message));
	};

	return (
		<>
			{success && history.push('/recruit')}
			<div className="app-wrapper min-vh-100 bg-white">
				<div className="app-main min-vh-100">
					<div className="app-content p-0">
						<div className="app-inner-content-layout--main">
							<div className="flex-grow-1 w-100 d-flex align-items-center">
								<div className="bg-composed-wrapper--content">
									<Grid container spacing={0} className="min-vh-100">
										<Grid item lg={7} xl={6} className="d-flex align-items-center">
											<Grid item md={10} lg={8} xl={7} className="mx-auto">
												<div className="py-4">
													<div className="text-center">
														<h1 className="display-4 mb-1 font-weight-bold text-primary">Login</h1>
													</div>
													{error && (
														<Alert
															action={
																<IconButton
																	aria-label="close"
																	color="inherit"
																	size="small"></IconButton>
															}
															className="mb-4"
															severity="error">
															{error}
														</Alert>
													)}

													<div>
														<div className="my-4">
															<TextField
																fullWidth
																placeholder={'email'}
																variant="outlined"
																onChange={(e) => setEmail(e.target.value)}
																id="textfield-email"
																label="Email address"
																value={email}
																InputProps={{
																	startAdornment: (
																		<InputAdornment position="start">
																			<FontAwesomeIcon
																				icon={['far', 'envelope']}
																				className="font-size-xxl text-info"
																			/>
																		</InputAdornment>
																	)
																}}
															/>
														</div>
														<div className="mb-3">
															<TextField
																fullWidth
																placeholder={'password'}
																variant="outlined"
																id="textfield-password"
																label="Password"
																type="password"
																value={password}
																onChange={(e) => setPassword(e.target.value)}
																InputProps={{
																	startAdornment: (
																		<InputAdornment position="start">
																			<FontAwesomeIcon
																				icon={['fas', 'lock']}
																				className="font-size-xxl text-info"
																			/>
																		</InputAdornment>
																	)
																}}
															/>
														</div>
														<div className="text-center py-4">
															<Button
																className={`font-weight-bold w-50 my-2 ${classes.containedPrimary}`}
																onClick={handleSubmit}>
																Sign in
															</Button>
														</div>
													</div>
												</div>
											</Grid>
										</Grid>
										<Grid item lg={5} xl={6} className="d-flex">
											<div className="hero-wrapper w-100 bg-composed-wrapper bg-premium-blue min-vh-lg-100">
												<div className="flex-grow-1 w-100 d-flex align-items-center">
													<div
														className="bg-composed-wrapper--image opacity-2"
														style={{ backgroundImage: 'url(' + hero8 + ')' }}
													/>
													<div className="bg-composed-wrapper--content p-5">
														<div className="text-white px-0 px-lg-2 px-xl-4">
															<h1 className="text-white display-3 mb-4 font-weight-bold">
																OCInsights
															</h1>
															<p className="font-size-lg mb-0 opacity-8">
																Welcome to OCInsights! We are a team of recruiters, data scientists,
																engineers, and market analysts dedicated to providing best-in-class
																human resource services for high-tech companies seeking global
																expansion.
																<br />
																<br />
																OCInsights combines its exclusive open to opportunity candidate
																database, which contains thousands of highly technical L5 and above
																candidates, with advanced search capabilities, AI recommendations,
																rich 360-degree profiles, messaging campaigns, contact information,
																talent mapping, and much more.
																<br />
																<br />
																Our team's decades of total experience, together with advanced
																filters and amazing data accuracy, ensures that you are able to
																source, engage, and hire candidates faster than ever before.
															</p>
														</div>
													</div>
												</div>
											</div>
										</Grid>
									</Grid>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
