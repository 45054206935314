import createReducer from "../../../utils/createReducer";

/***********************************************************************************************************************
 * 													SCHEMA 														                                                         *
 * *********************************************************************************************************************/
export const schema = {
  name: "overview",
  id: "id"
}

/***********************************************************************************************************************
 * 													REDUCERS 														                                                       *
 * *********************************************************************************************************************/

const reducer = createReducer(schema.name);

/***********************************************************************************************************************
 * 													SELECT  														                                                       *
 * *********************************************************************************************************************/

export default reducer;

