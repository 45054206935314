import url from '../../utils/url';
import { FETCH_DATA, POST_DATA } from '../middlewares/api';
import { schema } from './entities/custOrgChart';
import { combineReducers } from 'redux';

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
	FETCH_CUSTORGCHART_REQUEST: 'CUSTORGCHART_TAB/FETCH_CUSTORGCHART_REQUEST',
	FETCH_CUSTORGCHART_SUCCESS: 'CUSTORGCHART_TAB/FETCH_CUSTORGCHART_SUCCESS',
	FETCH_CUSTORGCHART_FAILURE: 'CUSTORGCHART_TAB/FETCH_CUSTORGCHART_FAILURE',

	POST_FOLLOW_REQUEST: 'CUSTORGCHART_TAB/POST_FOLLOW_REQUEST',
	POST_FOLLOW_SUCCESS: 'CUSTORGCHART_TAB/POST_FOLLOW_SUCCESS',
	POST_FOLLOW_FAILURE: 'CUSTORGCHART_TAB/POST_FOLLOW_FAILURE'
};

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
	custOrgChart: {
		isFetching: false,
		isPosting: false,
		followStatus: 100,
		id: null
	}
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
	loadCustOrgChartTab: (id) => {
		console.log(id);
		return (dispatch, getState) => {
			const endpoint = url.getCustOrgChart(id);
			const temp = fetchCustOrgChartTab(endpoint, id);
			return dispatch(temp);
		};
	},
	followCustDepartment: (data) => {
		return (dispatch, getState) => {
			const endpoint = url.postFollowDepartment();
			return dispatch(postFollowDepartment(endpoint, data));
		};
	}
};

const fetchCustOrgChartTab = (endpoint, id) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_CUSTORGCHART_REQUEST,
			types.FETCH_CUSTORGCHART_SUCCESS,
			types.FETCH_CUSTORGCHART_FAILURE
		],
		endpoint,
		schema
	},
	id
});

const postFollowDepartment = (endpoint, data) => ({
	[POST_DATA]: {
		types: [types.POST_FOLLOW_REQUEST, types.POST_FOLLOW_SUCCESS, types.POST_FOLLOW_FAILURE],
		endpoint,
		schema,
		data
	}
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const custOrgChart = (state = initialState.custOrgChart, action) => {
	switch (action.type) {
		case types.FETCH_CUSTORGCHART_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_CUSTORGCHART_SUCCESS:
			return { ...state, isFetching: false, id: action.response.ids };
		case types.FETCH_CUSTORGCHART_FAILURE:
			return { ...state, isFetching: false };
		case types.POST_FOLLOW_REQUEST:
			return { ...state, isPosting: true, followStatus: 100 };
		case types.POST_FOLLOW_SUCCESS:
		case types.POST_FOLLOW_FAILURE:
			return { ...state, isPosting: false, followStatus: action.response.status };
		default:
			return state;
	}
};

const reducer = combineReducers({ custOrgChart });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/

export const getCustOrgChart = (state) => {
	return state.entities.custOrgChart;
};

export const getCustFollowStatus = (state) => {
	return state.custOrgChartTab.custOrgChart.followStatus;
};

export const getCustTopLeader = (state) => {
	for (const [key, value] of Object.entries(state.entities.custOrgChart)) {
		if (value.level === 'c-0' && state.custOrgChartTab.custOrgChart.id.includes(value.id)) {
			return [
				{
					data: value,
					active: true,
					hasBelong: true
				}
			];
		}
	}
};

export const getCustLevel = (state, id, levelStringNumber) => {
	let level = [];
	console.log('levelStringNumber: ', levelStringNumber);
	for (const [key, value] of Object.entries(state.entities.custOrgChart)) {
		let hasBelong = false;
		const getValue = value;
		for (const [key, value] of Object.entries(state.entities.custOrgChart)) {
			if (value.belongsTo === getValue.id) hasBelong = true;
		}
		if (value.belongsTo === id && value.level === levelStringNumber) {
			level.push({
				data: value,
				active: true,
				hasBelong
			});
		}
	}
	return level;
};

export const subscribeCustOrgChart = (state, id) => {
	console.log('subscribeCustOrgChart : ', id);
	state.entities.custOrgChart[id].subscribed = true;
	// console.log(  state.entities.orgChart)
	return state.entities.custOrgChart;
};
