import React from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { List, ListItem } from '@material-ui/core';

import { connect } from 'react-redux';

const Footer = (props) => {
	const { footerShadow, footerBgTransparent, permission } = props;

	return (
		<>
			<div
				className={clsx('app-footer text-black-50', {
					'app-footer--shadow': footerShadow,
					'app-footer--opacity-bg': footerBgTransparent
				})}>
				<div className="app-footer--first">
					<List component="div" className="d-flex align-items-center">
						<ListItem button component={Link} to="/executive" className="rounded-sm">
							Talent Analytics
						</ListItem>
						<ListItem button component={Link} to="/Dashboard" className="rounded-sm">
							Dashboard
						</ListItem>
						{permission === 1 && (
							<ListItem button component={Link} to="/recruit" className="rounded-sm">
								Recruiting Tool
							</ListItem>
						)}
						<ListItem button component={Link} to="/contactsearch" className="rounded-sm">
							Search Contacts
						</ListItem>
					</List>
				</div>
				<div className="app-footer--second">
					<span>&copy; 2022 OCInsights Inc</span>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	footerShadow: state.ThemeOptions.footerShadow,
	footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
