import url from '../../utils/url';
import { FETCH_DATA } from '../middlewares/api';
import { schema } from './entities/recruit';
import { combineReducers } from 'redux';

/***********************************************************************************************************************
 * 													CONSTANTS 														                                                     *
 * *********************************************************************************************************************/
export const types = {
	FETCH_AVAILABLE_REQUEST: 'RECRUIT/FETCH_AVAILABLE_REQUEST',
	FETCH_AVAILABLE_SUCCESS: 'RECRUIT/FETCH_AVAILABLE_SUCCESS',
	FETCH_AVAILABLE_FAILURE: 'RECRUIT/FETCH_AVAILABLE_SUCCESS',

	FETCH_PROCESSING_REQUEST: 'RECRUIT/FETCH_PROCESSING_REQUEST',
	FETCH_PROCESSING_SUCCESS: 'RECRUIT/FETCH_PROCESSING_SUCCESS',
	FETCH_PROCESSING_FAILURE: 'RECRUIT/FETCH_PROCESSING_SUCCESS',

	FETCH_CONNECTED_REQUEST: 'RECRUIT/FETCH_CONNECTED_REQUEST',
	FETCH_CONNECTED_SUCCESS: 'RECRUIT/FETCH_CONNECTED_SUCCESS',
	FETCH_CONNECTED_FAILURE: 'RECRUIT/FETCH_CONNECTED_SUCCESS',

	FETCH_PROJECTS_REQUEST: 'RECRUIT/FETCH_PROJECTS_REQUEST',
	FETCH_PROJECTS_SUCCESS: 'RECRUIT/FETCH_PROJECTS_SUCCESS',
	FETCH_PROJECTS_FAILURE: 'RECRUIT/FETCH_PROJECTS_FAILURE'
};

/***********************************************************************************************************************
 * 													STATE   														                                                       *
 * *********************************************************************************************************************/
const initialState = {
	recruit: {
		isFetching: false,
		ids: null
	}
};

/***********************************************************************************************************************
 * 													ACTIONS 														                                                       *
 * *********************************************************************************************************************/

export const actions = {
	loadRecruitAvailable: () => {
		return (dispatch, getState) => {
			const endpoint = url.getRecruitAvailable();
			//const endpoint = url.recruitAvailable();
			return dispatch(fetchRecruitAvailable(endpoint));
		};
	},
	loadRecruitProcessing: () => {
		return (dispatch, getState) => {
			const endpoint = url.getRecruitProcessing();
			return dispatch(fetchRecruitProcessing(endpoint));
		};
	},
	loadRecruitConnected: () => {
		return (dispatch, getState) => {
			const endpoint = url.getRecruitConnected();
			return dispatch(fetchRecruitConnected(endpoint));
		};
	}
};

const fetchRecruitAvailable = (endpoint) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_AVAILABLE_REQUEST,
			types.FETCH_AVAILABLE_SUCCESS,
			types.FETCH_AVAILABLE_FAILURE
		],
		endpoint,
		schema
	}
});

const fetchRecruitProcessing = (endpoint) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_PROCESSING_REQUEST,
			types.FETCH_PROCESSING_SUCCESS,
			types.FETCH_PROCESSING_FAILURE
		],
		endpoint,
		schema
	}
});

const fetchRecruitConnected = (endpoint) => ({
	[FETCH_DATA]: {
		types: [
			types.FETCH_CONNECTED_REQUEST,
			types.FETCH_CONNECTED_SUCCESS,
			types.FETCH_CONNECTED_FAILURE
		],
		endpoint,
		schema
	}
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const recruit = (state = initialState.recruit, action) => {
	switch (action.type) {
		case types.FETCH_AVAILABLE_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_PROCESSING_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_CONNECTED_REQUEST:
			return { ...state, isFetching: true };
		case types.FETCH_AVAILABLE_SUCCESS:
			return { ...state, isFetching: false, ids: action.response.ids };
		case types.FETCH_PROCESSING_SUCCESS:
			return { ...state, isFetching: false, ids: action.response.ids };
		case types.FETCH_CONNECTED_SUCCESS:
			return { ...state, isFetching: false, ids: action.response.ids };
		case types.FETCH_AVAILABLE_FAILURE:
			return { ...state, isFetching: false };
		case types.FETCH_CONNECTED_FAILURE:
			return { ...state, isFetching: false };
		case types.FETCH_PROCESSING_FAILURE:
			return { ...state, isFetching: false };
		default:
			return state;
	}
};

const reducer = combineReducers({ recruit });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														                                                     *
 * *********************************************************************************************************************/
export const getTalentsById = (state) => {
	let talents = [];
	if (state.recruitPage.recruit.ids) {
		talents = state.recruitPage.recruit.ids.map((id) => {
			return state.entities.recruit[id];
		});
	}
	return talents;
};

//state: all available talents userId
export const listTalentsByTag = (state, tag) => {
	let talents = [];
	if (state.recruitPage.recruit.ids) {
		const filterIds = state.recruitPage.recruit.ids.filter((id) => {
			const talent = state.entities.recruit[id];
			const tagsname = talent.tags.map((tag) => tag.name);
			return tagsname.includes(tag);
		});
		talents = filterIds.map((id) => {
			return state.entities.recruit[id];
		});
	}

	for (const talent of talents) {
		const histories = talent.history;
		const currentTagHistory = [];
		for (const history of histories) {
			if (history.tagname && history.tagname === tag) {
				currentTagHistory.push(history);
			}
		}
		talent.history = currentTagHistory;
	}

	talents.sort((p1, p2) => {
		const tags1 = p1.tags;
		const tags2 = p2.tags;

		let tag1time;
		for (const tag1 of tags1) {
			if (tag1.name === tag) {
				tag1time = new Date(tag1.addedtime);
				break;
			}
		}

		let tag2time;
		for (const tag2 of tags2) {
			if (tag2.name === tag) {
				tag2time = new Date(tag2.addedtime);
				break;
			}
		}

		if (tag1time.getTime() < tag2time.getTime()) {
			return 1;
		} else if (tag1time.getTime() > tag2time.getTime()) {
			return -1;
		}

		return 0;
	});

	console.log('listTalentsByTag: ', talents, 'tag: ', tag);
	return talents;
};

// state: all connected talents by userId
export const listConnectedTalentsByTag = (state, tag) => {
	let talents = [];
	if (state.recruitPage.recruit.ids) {
		const filterIds = state.recruitPage.recruit.ids.filter((id) => {
			const talent = state.entities.recruit[id];
			const tagsname = talent.tags.map((tag) => tag.name);
			return tagsname.includes(tag);
		});
		talents = filterIds.map((id) => {
			return state.entities.recruit[id];
		});
	}

	talents.sort((p1, p2) => {
		const tags1 = p1.tags;
		const tags2 = p2.tags;

		let tag1time;
		for (const tag1 of tags1) {
			if (tag1.name === tag) {
				tag1time = new Date(tag1.addedtime);
				break;
			}
		}

		let tag2time;
		for (const tag2 of tags2) {
			if (tag2.name === tag) {
				tag2time = new Date(tag2.addedtime);
				break;
			}
		}

		if (tag1time.getTime() < tag2time.getTime()) {
			return 1;
		} else if (tag1time.getTime() > tag2time.getTime()) {
			return -1;
		}

		return 0;
	});

	console.log('listConnectedTalentsByTag: ', talents, 'tag: ', tag);
	return talents;
};

export const searchOptions = (state) => {
	const talents = getTalentsById(state);

	let currentCompany = [];
	let location = [];
	let stacks = [];
	let department_1 = [];
	let department_2 = [];
	let seniority = [];
	let area = [];

	talents.forEach((talent) => {
		if (!currentCompany.includes(talent['currentCompany']) && talent['currentCompany']) {
			currentCompany.push(talent['currentCompany']);
		}
		if (!location.includes(talent['location']) && talent['location']) {
			location.push(talent['location']);
		}
		if (!seniority.includes(talent['seniority']) && talent['seniority']) {
			seniority.push(talent['seniority']);
		}
		if (!department_1.includes(talent['department_1']) && talent['department_1']) {
			department_1.push(talent['department_1']);
		}
		if (!department_2.includes(talent['department_2']) && talent['department_2']) {
			department_2.push(talent['department_2']);
		}
		if (!stacks.includes(talent['stacks']) && talent['stacks']) {
			stacks.push(talent['stacks']);
		}
		if (!area.includes(talent['area']) && talent['area']) {
			area.push(talent['area']);
		}
	});

	return {
		currentCompany: currentCompany.sort(),
		location: location.sort(),
		seniority,
		department_1: department_1.sort(),
		department_2: department_2.sort(),
		stacks: stacks.sort(),
		area: area.sort()
	};
};
