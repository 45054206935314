import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouteLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
	actions as projectsActions,
	getProjectsList
} from '../../../../redux/modules/projectsPage';
import { config } from '../../../../utils/recruitUtil';
import { post } from '../../../../utils/request';
import url from '../../../../utils/url';

const useStyles = makeStyles((theme) => ({
	containedPrimary: {
		backgroundColor: '#4191ff'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	chips: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	chip: {
		fontSize: '0.875rem !important',
		color: '#808080',
		backgroundColor: '#E5E4E2',
		borderRadius: '0.29rem !important',
		padding: '5px 10px 5px 10px'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute'
	}
}));

function TalentDetail(props) {
	const { projects, selectedRow, setOpen, open } = props;
	const { loadProjects } = props.projectsAction;

	const classes = useStyles();

	const [isFolderUpdateDisabled, setFolderUpdateDisabled] = useState(true);
	const [isFolderUpdateLoading, setFolderUpdateLoading] = useState(false);
	const [insertArray, setInsertArray] = useState([]);
	const [subtractArray, setSubtractArray] = useState([]);
	const [folderSelection, setFolderSelection] = useState(null);
	const [folders, setFolders] = useState([]);

	const handleClose = () => {
		setOpen(false);
		setFolderUpdateDisabled(true);
	};

	const handleFolderChange = async (event, values) => {
		console.log(selectedRow.tags); //new existing project
		const talentId = selectedRow.id;
		const selectedProjects = values;
		const assignedProjects = selectedRow.tags;
		const insert = [];
		const subtract = [];

		//Add project to the given talent
		//const set = new Set();
		let talentsProjectMapping = {};
		talentsProjectMapping[talentId] = [];
		for (const selectedProject of selectedProjects) {
			const assignedProjects = selectedRow.tags;
			const assignedProjectsIds = assignedProjects.map((item) => item.id);

			if (!assignedProjectsIds.includes(selectedProject.id)) {
				const project = folderSelection.find((project) => project.id === selectedProject.id);
				insert.push(project.id);
				talentsProjectMapping[talentId].push(selectedProject);
			}

			//set.add(selectedProject.id);
		}

		//Remove project from the given talent
		assignedProjects.forEach((each) => {
			if (!selectedProjects.includes(each)) {
				const project = folderSelection.find((project) => project.name === each.name);
				subtract.push({
					tagId: project.id,
					talentId: talentId
				});
			}
		});
		if (subtract.length > 0) {
			//post(url.cancelAssignProjects(), subtract).then((res) => console.log(res));
			setSubtractArray(subtract);
		}

		if (insert.length > 0) {
			setInsertArray(insert);
		}

		setFolders(values);
		setFolderUpdateDisabled(false);
	};

	const handleUpdateProjects = async () => {
		const talentId = selectedRow.id;
		setFolderUpdateLoading(true);

		if (subtractArray.length > 0) {
			await post(url.cancelAssignProjects(), subtractArray).then((res) => console.log(res));
		}

		if (insertArray.length > 0) {
			await axios
				.post(
					url.addProjectsInRecommendation(),
					{
						tagIds: insertArray,
						talentId: talentId
					},
					config
				)
				.then((res) => {
					console.log(res.data.message);
				})
				.catch((e) => console.error('Change folders for the given talent has error', e));
		}

		handleClose();
		setFolderUpdateLoading(false);
	};

	const renderStars = (number) => {
		let container = [];

		let idx = 0;
		for (let i = 0; i < number; i++) {
			container.push(<StarIcon key={idx++} />);
		}
		for (let i = 0; i < 3 - number; i++) {
			container.push(<StarOutlineIcon key={idx++} />);
		}

		return container.map((each) => each);
	};

	useEffect(() => {
		setFolderSelection(projects);
		setFolders(selectedRow.tags);
	}, [projects]);

	useEffect(() => {
		console.log('initialize loadProjects.....');
		loadProjects();
	}, []);

	return (
		<Dialog
			classes={{ paper: 'modal-content bg-secondary rounded-lg modal-light' }}
			maxWidth={'md'}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">
				<Grid container spacing={1}>
					<Grid container item xs={3} direction={'column'}>
						<Grid item>
							<span className="text-black font-size-sm">{selectedRow.name}</span>
						</Grid>
						<Grid item>
							<span className="text-black font-weight-light font-size-sm">
								{[
									...new Set(
										selectedRow.companies
											.filter((comp) => comp.isCurrent === 1)
											.map((item) => item.position)
									)
								]}
							</span>
						</Grid>
					</Grid>
					<Grid container item xs={3} direction={'column'}>
						<Grid item>
							<span className="text-black font-size-sm">Curr/Ex Company</span>
						</Grid>
						<Grid item>
							<span className="text-black font-weight-light font-size-sm">
								{[
									...new Set(
										selectedRow.companies
											.filter((comp) => comp.isCurrent === 1)
											.map((item) => item.company)
									)
								]}
							</span>
						</Grid>
					</Grid>
					<Grid container item xs={3} direction={'column'}>
						<Grid item>
							<span className="text-black font-size-sm">C-1 DEPT</span>
						</Grid>
						<Grid item>
							<span className="text-black font-weight-light font-size-sm">
								{selectedRow.department_1}
							</span>
						</Grid>
					</Grid>
					<Grid container item xs={3} direction={'column'}>
						<Grid item>
							<span className="text-black font-size-sm">C-2 DEPT</span>
						</Grid>
						<Grid item>
							<span className="text-black font-weight-light font-size-sm">
								{selectedRow.department_2}
							</span>
						</Grid>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent className={'p-4'}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card className="shadow-xxl p-3">
							<List component="div" className="nav-line d-flex nav-tabs-primary">
								<ListItem button disableRipple>
									<span>Information</span>
									<div className="divider" />
								</ListItem>
							</List>

							<div className={clsx('tab-item-wrapper', { active: true })} index={2}>
								<div className="p-3 bg-secondary rounded-sm">
									<span className="font-weight-bold text-black font-size-lg">Summary</span>
									<div className="d-flex justify-content-start py-2">
										<div className="text-black font-weight-light font-size-sm">
											<span className="pr-1">Location:</span>
											<span>{selectedRow.location}</span>
										</div>
									</div>
									<div className="d-flex justify-content-start py-2">
										<div className="text-black font-weight-light font-size-sm">
											<span className="pr-1">Tech Stack:</span>
											<span>{selectedRow.stacks}</span>
										</div>
									</div>
									<div className="d-flex justify-content-start py-2">
										<div className="text-black font-weight-light font-size-sm">
											<span className="pr-1">Seniority:</span>
											<span>{selectedRow.seniority.substring(4)}</span>
										</div>
									</div>
									<div className="d-flex justify-content-start py-2">
										<div className="text-black font-weight-light font-size-sm">
											<span className="pr-1">Years of Experience:</span>
											<span>{selectedRow.yearsOfWorkingExperiences}</span>
											<span>&nbsp;yrs</span>
										</div>
									</div>
									<div className="d-flex justify-content-start py-2">
										<span className="pr-1">
											O2O Index
											<Tooltip
												title={
													<>
														<div>★★★: Actively look for new opportunities.</div>
														<div>
															★★: Considering new opportunities but not actively looking for.
														</div>
														<div>★: Probably open to new opportunities. </div>
														<div>☆: Probably not open to new opportunities. </div>
													</>
												}
												arrow
												placement="top">
												<HelpOutlineIcon fontSize={'small'} />
											</Tooltip>
										</span>
										<span>{renderStars(selectedRow.openToOpportunities)}</span>
									</div>
									<div className="d-flex justify-content-start py-2">
										<div className="text-black font-weight-light font-size-sm">
											<span className="pr-1">Contact Info:</span>
											<span>
												<a target="_blank" href={`${selectedRow.linkedin}`}>
													LinkedIn{selectedRow.email && <>, &nbsp;</>}
												</a>
												{selectedRow.email && (
													<>
														<RouteLink
															target="_blank"
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Email, &nbsp;
														</RouteLink>
														<RouteLink
															target="_blank"
															to={{
																pathname: '/contactsearch',
																search: `linkedin=${selectedRow.linkedin}`
															}}>
															Phone
														</RouteLink>
													</>
												)}
											</span>
										</div>
									</div>
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Experience</span>
									{selectedRow.companies.length > 0 &&
										selectedRow.companies.map((comp) => {
											const duration = comp.period ? ', ' + comp.period + 'yrs' : '';
											return (
												<div className="d-flex justify-content-start flex-column p-2">
													<div className="text-black font-weight-light font-size-sm">
														<span>
															{comp.position} at {comp.company}
														</span>
													</div>
													<div className="text-black font-weight-light font-size-sm">
														<span>
															{comp.startDate && comp.endDate && (
																<>
																	{comp.startDate + ' - ' + comp.endDate} {duration}{' '}
																</>
															)}
														</span>
													</div>
												</div>
											);
										})}
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Education</span>
									{selectedRow.institutions.length > 0 &&
										selectedRow.institutions.map((ins) => {
											return (
												<div className="d-flex justify-content-start flex-column p-2">
													<div className="text-black font-weight-light font-size-sm">
														<span>
															{ins.degree}'s Degree {ins.specialty && ' in ' + ins.specialty} at{' '}
															{ins.institution}
														</span>
													</div>
													{ins.startYear && ins.endYear && (
														<div className="text-black font-weight-light font-size-sm">
															<span>
																{ins.startYear}-{ins.endYear}
															</span>
														</div>
													)}
												</div>
											);
										})}
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Skills</span>
									<div className={classes.chips}>
										{selectedRow.skills.length > 0 &&
											selectedRow.skills.map((skill) => {
												return <span className={classes.chip}>{skill}</span>;
											})}
									</div>
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Language(s)</span>
									<div className={classes.chips}>
										{selectedRow.languages.length > 0 &&
											selectedRow.languages.map((lang) => {
												return <span className={classes.chip}>{lang.language}</span>;
											})}
									</div>
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Achievement</span>
									<div className={classes.chips}>
										<Tooltip
											title={
												selectedRow.honors.length > 0 &&
												selectedRow.honors.map((honor) => {
													return (
														<div className={'pb-2'}>
															<div>Title:{honor.title}</div>
															<div>Issuer:{honor.issuer}</div>
															<div>IssueDate:{honor.issueDate}</div>
															<div>
																Description:
																{honor.description && honor.description.length > 150
																	? honor.description.slice(0, 150) + '...'
																	: honor.description}
															</div>
														</div>
													);
												})
											}>
											<Button>{selectedRow.honors.length} Honors</Button>
										</Tooltip>
										<Tooltip
											title={
												selectedRow.patents.length > 0 &&
												selectedRow.patents.map((patent) => {
													return (
														<div className={'pb-2'}>
															<div>Title:{patent.title}</div>
															<div>Issuer:{patent.patentNo}</div>
															<div>IssueDate:{patent.issueDate}</div>
															<div>
																Description:
																{patent.description && patent.description.length > 150
																	? patent.description.slice(0, 150) + '...'
																	: patent.description}
															</div>
														</div>
													);
												})
											}>
											<Button>{selectedRow.patents.length} Patents</Button>
										</Tooltip>
										<Tooltip
											title={
												selectedRow.publications.length > 0 &&
												selectedRow.publications.map((publication) => {
													return (
														<div className={'pb-2'}>
															<div>Title:{publication.title}</div>
															<div>Publisher:{publication.publisher}</div>
															<div>PublicDate:{publication.publicationDate}</div>
															<div>
																Description:
																{publication.description && publication.description.length > 150
																	? publication.description.slice(0, 150) + '...'
																	: publication.description}
															</div>
														</div>
													);
												})
											}>
											<Button>{selectedRow.publications.length} Publications</Button>
										</Tooltip>
									</div>
									<hr />
									<span className="font-weight-bold text-black font-size-lg">Entrepreneurship</span>
									{selectedRow.entrepreneurship.length > 0 &&
										selectedRow.entrepreneurship.map((ent) => {
											return (
												<div className="d-flex justify-content-start flex-column p-2">
													<div className="text-black font-weight-light font-size-sm">
														<span>
															{ent.role} at {ent.company}{' '}
															{ent.round && <>({ent.round.split('-')[1].trim()})</>}
														</span>
													</div>
													<div className="text-black font-weight-light font-size-sm">
														<span>
															{ent.startDate}-{ent.endDate}
														</span>
													</div>
												</div>
											);
										})}
									<hr />
									{folderSelection && (
										<>
											<span className="font-weight-bold text-black font-size-lg">Projects</span>
											<div className="d-flex justify-content-between">
												<FormControl className={classes.formControl}>
													<Autocomplete
														multiple
														size="small"
														options={folderSelection}
														getOptionLabel={(option) => option.name}
														onChange={handleFolderChange}
														value={folders}
														getOptionSelected={(option, value) => option.id === value.id}
														renderInput={(params) => (
															<TextField {...params} variant="standard" label="Projects" />
														)}
													/>
												</FormControl>
											</div>
										</>
									)}
								</div>
							</div>
						</Card>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="p-4">
				<Button onClick={handleClose} color="primary" variant="outlined">
					Cancel
				</Button>
				<Button
					onClick={handleUpdateProjects}
					disabled={isFolderUpdateDisabled || isFolderUpdateLoading}
					className={classes.containedPrimary}>
					{isFolderUpdateLoading && (
						<CircularProgress size={24} className={classes.buttonProgress} />
					)}
					Update Projects
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const mapStateToProps = (state, props) => {
	const projectList = getProjectsList(state);
	console.log('pages Notification TalentDetail mapStateToProps projectList...', projectList);

	return {
		projects: projectList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		projectsAction: bindActionCreators(projectsActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentDetail);
