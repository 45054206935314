import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		width: '100%'
	}
});

export default function DetailLoadingIndicator() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Skeleton />
			<Skeleton animation={false} />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
		</div>
	);
}
