import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { del, get, post, update } from '../../../utils/request';
import url from '../../../utils/url';
import Adduser from './adduser';
import Edituser from './edituser';

const IMG = styled.img`
	width: 20px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

export default function Users(props) {
	const { confirm } = Modal;
	const [usersData, setUsersData] = useState([]);
	const [userData, setUserData] = useState({});
	const [customers, setCustomers] = useState([]);
	const [isEditLoading, setEditLoading] = useState(false);
	const [isEditVisible, setEditVisible] = useState(false);
	const [isAddLoading, setAddLoading] = useState(false);
	const [isAddVisible, setAddVisible] = useState(false);
	const [isDeleteLoading, setDeleteLoading] = useState(false);
	const [isSendEmailLoading, setSendEmailLoading] = useState(false);
	const [userEmails, setUserEmails] = useState([]);
	const { Column } = Table;

	/************************ Edit View **********************/
	const showEditModal = (user) => {
		setUserData(user);
		setEditVisible(true);
	};

	const handleEditCancel = () => {
		setEditVisible(false);
	};

	const handleEditFinish = async (form) => {
		console.log('Received Edit form: ', form);
		console.log('values', form.getFieldsValue());

		try {
			let values = form.getFieldsValue();
			const validates = await form.validateFields();
			console.log(validates);
			setEditLoading(true);
			update(url.updateAdminUser(values.userId), values).then((res) => {
				console.log('update user successfully', res);
				setTimeout(() => {
					setEditLoading(false);
					setEditVisible(false);
				}, 3000);
			});
		} catch (errorInfo) {
			return Promise.reject({
				error: {
					message: errorInfo
				}
			});
		}
	};

	/**************** Add View ******************/
	const showAddModal = () => {
		console.log('show add modal....');
		setAddVisible(true);
	};

	const handleAddFinish = async (form) => {
		console.log('Received Add form: ', form);
		console.log('values', form.getFieldsValue());

		try {
			const values = form.getFieldsValue();
			const validates = await form.validateFields();
			console.log(validates);
			setAddLoading(true);
			post(url.postAdminUser(), values).then((res) => {
				console.log('post user successfully', res);
				setTimeout(() => {
					setAddLoading(false);
					setAddVisible(false);
					form.resetFields();
				}, 3000);
			});
		} catch (errorInfo) {
			return Promise.reject({
				error: {
					message: errorInfo
				}
			});
		}
	};

	const handleAddCancel = () => {
		setAddVisible(false);
	};

	const handleDelete = (user) => {
		console.log('delete', user);
		setDeleteLoading(true);
		del(url.deleteAdminUser(), user)
			.then((res) => {
				console.log('delete user successfully', res);
				setDeleteLoading(false);
			})
			.catch((error) => {
				console.log('Delete user fails!', error);
			});
	};

	const handleSendEmail = (user) => {
		setSendEmailLoading(true);
		axios
			.post(
				url.sendInvitationEmail(),
				{
					customername: user.customername,
					email: user.email,
					password: user.password,
					firstname: user.name.split(' ')[0]
				},
				{ withCredentials: true, contentType: 'application/json' }
			)
			.then((res) => {
				console.log('send email successfully !!', res);
				setTimeout(() => {
					setSendEmailLoading(false);
				}, 3000);
			})
			.catch((e) => {
				return Promise.reject({
					error: {
						message: e.message
					}
				});
			});
	};

	const showDeleteConfirm = (user) => {
		confirm({
			title: `Do you confirm to delete user ${user.name}?`,
			icon: <ExclamationCircleOutlined />,
			onOk() {
				handleDelete(user);
				return new Promise((resolve, reject) => {
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log('showDeleteConfirm timesout errors!'));
			},
			onCancel() {}
		});
	};

	const showSendEmailConfirm = (user) => {
		confirm({
			title: `Do you confirm to send the user ${user.name} email?`,
			icon: <ExclamationCircleOutlined />,
			onOk() {
				handleSendEmail(user);
				return new Promise((resolve, reject) => {
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log('showSendEmailConfirm timesout error'));
			},
			onCancel() {}
		});
	};

	////////////////////////// REACT LIFE CYCLE ////////////////////////////////
	useEffect(() => {
		console.log('users loading.....');
		get(url.getAdminUsers()).then((res) => {
			console.log(res.data);
			setUsersData(res.data);
		});
	}, [isEditLoading, isAddLoading, isDeleteLoading]);

	useEffect(() => {
		console.log('customers loading.....');
		get(url.getAdminCustomers()).then((res) => {
			console.log(res.data);
			setCustomers(res.data);
		});
	}, []);

	useEffect(() => {
		get(url.fetchUserEmails()).then((res) => {
			console.log(res.data);
			setUserEmails(res.data);
		});
	}, []);

	return (
		<>
			<div>
				<Button style={{ marginBottom: 16 }} type="primary" onClick={showAddModal}>
					Add
				</Button>
				<Button style={{ float: 'right', position: 'relative' }} onClick={props.redirectHome}>
					Home
				</Button>
				<Button style={{ float: 'right', position: 'relative' }} onClick={props.logout}>
					Logout
				</Button>
				<Table dataSource={usersData} pagination={{ pageSize: 10 }}>
					<Column title="Name" dataIndex="name" />
					<Column title="Email" dataIndex="email" />
					<Column title="Customer" dataIndex="customername" />
					<Column title="Login Count" dataIndex="signinCount" />
					<Column title="Monthly Search" dataIndex="monthSearchCredits" />
					<Column title="Search Usage" dataIndex="usedSearchCredits" />
					<Column title="Search Total" dataIndex="totalSearchCredits" />
					<Column
						title="Sending Emails"
						render={(_, record) => {
							const emailUsersId = record.emailUsersId;
							if (!emailUsersId) return <p></p>;

							const userIds = emailUsersId.split(',').map((each) => parseInt(each));
							const selectedUserEmails = userIds.map((userId) => {
								return userEmails.find((each) => each.id === userId);
							});

							return (
								selectedUserEmails.length > 0 &&
								typeof selectedUserEmails[0] !== 'undefined' &&
								selectedUserEmails.map((each) => (
									<p>
										{each.name}: {each.email}
									</p>
								))
							);
						}}
					/>
					<Column
						fixed="right"
						render={(_, record) => {
							const emailUserIds = record.emailUsersId;
							if (typeof emailUserIds === 'string') {
								const userIds = emailUserIds.split(',').map((each) => parseInt(each));

								return (
									<Space size="middle">
										<IMG
											src={require('../../../assets/images/apps/Edit.png')}
											onClick={() => showEditModal({ ...record, sendingUserIds: userIds })}
										/>
										<IMG
											src={require('../../../assets/images/apps/Delete.png')}
											onClick={() => showDeleteConfirm(record)}
										/>
										<IMG
											src={require('../../../assets/images/apps/Email.png')}
											onClick={() => showSendEmailConfirm(record)}
										/>
									</Space>
								);
							} else {
								return (
									<Space size="middle">
										<IMG
											src={require('../../../assets/images/apps/Edit.png')}
											onClick={() => showEditModal({ ...record, sendingUserIds: [] })}
										/>
										<IMG
											src={require('../../../assets/images/apps/Delete.png')}
											onClick={() => showDeleteConfirm(record)}
										/>
										<IMG
											src={require('../../../assets/images/apps/Email.png')}
											onClick={() => showSendEmailConfirm(record)}
										/>
									</Space>
								);
							}
						}}
					/>
				</Table>
			</div>
			<Edituser
				isLoading={isEditLoading}
				isVisible={isEditVisible}
				handleCancel={handleEditCancel}
				userData={userData}
				handleFinish={handleEditFinish}
				customers={customers}
				userEmails={userEmails}
			/>
			<Adduser
				isLoading={isAddLoading}
				isVisible={isAddVisible}
				handleCancel={handleAddCancel}
				handleFinish={handleAddFinish}
				customers={customers}
				userEmails={userEmails}
			/>
		</>
	);
}
