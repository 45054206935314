import React, { useEffect } from 'react';
import { PageTitle } from '../../components';
import Companies from './components/Companies';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actions as dashboardActions, getOrgs } from '../../redux/modules/dashboard';
import { bindActionCreators } from 'redux';
import { usageTraceLabels, updateUsageActivity } from '../../../src/utils/recruitUtil';

function Dashboard(props) {
	useEffect(() => {
		props.dashboardActions.loadOrgs();
		console.log('dashboard....');

		//usage activity
		const labels = usageTraceLabels();
		updateUsageActivity(labels.dashboard);
	}, []);

	return (
		<>
			<PageTitle titleHeading="OCInsights Dashboard" />
			<Companies
				orgs={props.orgs}
				getOrgs={props.dashboardActions.loadOrgs}
				setSubscribe={props.dashboardActions.setSubscribe}
			/>
		</>
	);
}

/***********************************************************************************************************************
 * 													MAPPING  														   *
 * *********************************************************************************************************************/

const mapStateToProps = (state, props) => {
	return {
		orgs: getOrgs(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		dashboardActions: bindActionCreators(dashboardActions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
