import { get, post } from '../../utils/request';

export const FETCH_DATA = 'FETCH_DATA';
export const POST_DATA = 'POST_DATA';

const validateCallAPI = ({ endpoint, schema, types }) => {
	if (typeof endpoint !== 'string') {
		throw new Error('endpoint must is a string type URL.');
	}

	if (!schema) {
		throw new Error("An entity's schema is required.");
	}

	if (!Array.isArray(types) && types.length !== 3) {
		throw new Error('An array that has three action types is required.');
	}

	if (!types.every((type) => typeof type === 'string')) {
		throw new Error('Action type must is a string type.');
	}
};

export const postAPI = (store) => (next) => (action) => {
	const callAPI = action[POST_DATA];
	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	const { endpoint, schema, types, data } = callAPI;

	validateCallAPI({ endpoint, schema, types });

	// reduce executed action
	const actionWith = (data) => {
		const finalAction = { ...action, ...data };
		delete finalAction[POST_DATA];
		return finalAction;
	};

	const [requestType, successType, failureType] = types;
	next(actionWith({ type: requestType }));

	return postData(endpoint, data).then(
		(response) => {
			console.log(response);
			next(actionWith({ type: successType, response }));
		},
		(error) => {
			next(
				actionWith({
					type: failureType,
					error: error.message || 'failed to post data.'
				})
			);
		}
	);
};

//the entrance for action
export default (store) => (next) => (action) => {
	let callAPI = action[FETCH_DATA];

	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	const { endpoint, schema, types } = callAPI;

	validateCallAPI({ endpoint, schema, types });
	// reduce executed action
	const actionWith = (data) => {
		const finalAction = { ...action, ...data };
		delete finalAction[FETCH_DATA];
		return finalAction;
	};

	const [requestType, successType, failureType] = types;

	next(actionWith({ type: requestType }));

	return fetchData(endpoint, schema).then(
		(response) => {
			next(actionWith({ type: successType, response }));
		},
		(error) => {
			next(
				actionWith({
					type: failureType,
					error: error.message || 'failed to fetch data.'
				})
			);
		}
	);
};

//the entrance to fetch data through endpoint
const fetchData = (endpoint, schema) => {
	return get(endpoint).then((data) => {
		return normalizeData(data, schema);
	});
};

const postData = (endpoint, data) => {
	return post(endpoint, data);
};

const normalizeData = (originData, schema) => {
	console.log(originData);
	let data = originData;
	if (originData.data) {
		data = originData.data;
	}

	const { id, name } = schema;
	let keyObj = {};
	let ids = [];
	if (Array.isArray(data)) {
		data.forEach((item) => {
			keyObj[item[id]] = item;
			ids.push(item[id]);
		});
	} else {
		keyObj[data[id]] = data;
		ids.push(data[id]);
	}
	return {
		[name]: keyObj,
		ids
	};
};
