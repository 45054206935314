import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import memory from '../../utils/memory';
import storage from '../../utils/storage';
import url from '../../utils/url';
import { usageTraceLabels, updateUsageActivity } from '../../utils/recruitUtil';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	},
	containedPrimary: {
		backgroundColor: '#4191ff'
	}
}));

const IMG = styled.img`
	width: 15px;
	height: auto;
	alt: icon;
	cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactSearch(props) {
	const search = props.location.search;
	const classes = useStyles();
	const textRef = useRef();
	const [resultData, setResultData] = useState({});
	const [validEmails, setValidEmails] = useState([]);
	const [inValidEmails, setInValidEmails] = useState([]);
	const [usedCredits, setUsedCredits] = useState(0);
	const [totalCredits, setTotalCredits] = useState(0);
	const [status, setStatus] = useState(1);
	const [isConfirmOpen, setConfirmOpen] = useState(false);
	//const [query, setQuery] = useState(search && search.length > 0 ? search.split("=")[1] : null);

	const handleSearchCandidates = async () => {
		console.log('handle Search....');

		if (memory.user.usedSearchCredits === memory.user.totalSearchCredits) {
			setConfirmOpen(true);
			return;
		}
		//
		console.log(textRef.current.value);
		const linkedin = textRef.current.value;
		const response = await axios
			.get(url.searchContact(), {
				params: {
					linkedin: linkedin
				}
			})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				console.log(`Request failed. URL = ${url}`, err);
				const msg = err.response.statusText;
				console.error(msg);
			});

		console.log('response', response);
		if (!response || response === undefined) {
			setStatus(2);
			return;
		}

		const result = response.resultData;
		const creditDeduction = response.creditDeduction;
		const info = !result || result === undefined ? {} : result;

		//Handle with valid emails and invalid emails
		if (Object.keys(info).length > 0) {
			const valids = [],
				invalids = [];
			const items = info.emails;

			for (const item of items) {
				if (item.smtp_valid === 'valid') {
					valids.push(item.email);
				} else if (item.smtp_valid === 'invalid') {
					invalids.push(item.email);
				}
			}

			setValidEmails(valids);
			setInValidEmails(invalids);
		}

		setResultData(info);
		setStatus(3);

		memory.user.usedSearchCredits = memory.user.usedSearchCredits + creditDeduction;
		storage.saveUser({ ...storage.getUser(), usedSearchCredits: memory.user.usedSearchCredits });
		setUsedCredits(memory.user.usedSearchCredits);
	};

	useEffect(() => {
		setTotalCredits(memory.user.totalSearchCredits);
		setUsedCredits(memory.user.usedSearchCredits);
	}, [usedCredits]);

	useEffect(() => {
		if (search && search.length > 0) {
			textRef.current.value = search.split('=')[1];
			handleSearchCandidates();
		}

		//usage activity
		const labels = usageTraceLabels();
		updateUsageActivity(labels.searchContact);
	}, []);

	return (
		<div>
			<div className="font-weight-bold font-size-xxl">Search Contacts</div>
			<div style={{ marginTop: '10rem' }}>
				<Grid container spacing={3}>
					<Grid item xs />
					<Grid item xs={6}>
						<div className="mb-2">
							Credit Usage: {usedCredits} / {totalCredits}
						</div>
						<Paper component="form" className={classes.root}>
							<InputBase
								className={classes.input}
								inputProps={{ 'aria-label': 'search google maps' }}
								inputRef={textRef}
								placeholder="https://www.linkedin.com/in/..."
							/>
							<IconButton
								onClick={handleSearchCandidates}
								className={classes.iconButton}
								aria-label="search">
								<SearchIcon />
							</IconButton>
						</Paper>
						{status === 3 && resultData.name && resultData.name !== undefined && (
							<ListItem className="font-size-sm">
								<strong>{resultData.name}</strong>
							</ListItem>
						)}
						{status === 3 && resultData.current_title && resultData.current_title !== undefined && (
							<ListItem className="font-size-sm">
								<strong>{resultData.current_title}</strong>
							</ListItem>
						)}
						{status === 3 && validEmails.length > 0 && (
							<>
								<ListItem className="font-size-sm d-flex flex-column align-items-start">
									<strong>Verified Email(s):</strong>
									{/* <span>{validEmails.join(',\n')}</span> */}
									<ul>
										{validEmails.map((email, index) => (
											<li key={index}>
												<span className="mr-2">{email}</span>
												<Tooltip title="Copy" placement="right-end" arrow>
													<CopyToClipboard text={email}>
														<IMG src={require('../../assets/images/apps/Duplicate.png')} />
													</CopyToClipboard>
												</Tooltip>
											</li>
										))}
									</ul>
								</ListItem>
							</>
						)}
						{status === 3 && resultData.phones && resultData.phones !== undefined && (
							<>
								<ListItem className="font-size-sm d-flex flex-column align-items-start">
									<strong>Phone(s):</strong>
									{/* <span>{resultData.phones.join(',\n')}</span> */}
									<ul>
										{resultData.phones.map((phone, index) => (
											<li key={index}>
												<span className="mr-2">{phone}</span>
												<Tooltip title="Copy" placement="right-end" arrow>
													<CopyToClipboard text={phone}>
														<IMG src={require('../../assets/images/apps/Duplicate.png')} />
													</CopyToClipboard>
												</Tooltip>
											</li>
										))}
									</ul>
								</ListItem>
							</>
						)}
						{status === 3 && inValidEmails.length > 0 && (
							<>
								<ListItem className="font-size-sm d-flex flex-column align-items-start">
									<strong>Unverified Email(s):</strong>
									{/* <span>{inValidEmails.join(',\n')}</span> */}
									<ul>
										{inValidEmails.map((email, index) => (
											<div>
												<li key={index}>
													<span className="mr-2">{email}</span>
													<Tooltip title="Copy" placement="right-end" arrow>
														<CopyToClipboard text={email}>
															<IMG src={require('../../assets/images/apps/Duplicate.png')} />
														</CopyToClipboard>
													</Tooltip>
												</li>
											</div>
										))}
									</ul>
								</ListItem>
							</>
						)}
						{status === 2 && <div className="mt-2">There is no data found</div>}
					</Grid>
					<Grid item xs />
				</Grid>
				<Dialog
					open={isConfirmOpen}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => setConfirmOpen(false)}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description">
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Your credits have been used out. Please contact customer success manager
							(alice.lee@ocinsights.ai) to charge more. Thanks!
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							className={classes.containedPrimary}
							onClick={() => setConfirmOpen(false)}
							color="primary"
							variant="contained">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
}
